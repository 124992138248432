import React from 'react'
import { Modal, Form, Row, Col, Select, InputNumber } from 'antd'

function EditPakan(props) {
    const { Option } = Select
    const { visible, handleSubmit, data, handleCancel, stokPakan, profil, t } = props
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.recording.feed.edit')}
                okText={t('general.edit')}
                width={500}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        _id: data._id,
                        pakan: data.feedStock,
                        jumlah: data.number,
                        price: data.priceFeeding
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content">{t('pages.recording.feed.title')}</span>
                            <Form.Item
                                name="pakan" rules={[{ required: true, message: `${t('pages.recording.feed.msgFeed')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.recording.feed.phFeed')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                >
                                    {
                                        stokPakan.map(data =>
                                            <Option
                                                value={data._id}
                                            >{data.feed.name} - {data.nameStock}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.feed.qty')}</span>
                            <Form.Item
                                name="jumlah" rules={[{ required: true, message: `${t('pages.recording.feed.msgQty')}` }]}
                            >
                                <InputNumber
                                    placeholder="100..."
                                    className="input-number mt-5"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} hidden={!profil.permission.nominalRecording}>
                            <span className="font-semi-bold black font-title-medium">Harga (Rp)</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `Mohon masukkan harga` }]}
                            >
                                <InputNumber
                                    placeholder="2500..."
                                    className="input-number mt-5"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

export default EditPakan