import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input, Layout, Breadcrumb, Row, Col, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import ModalEditCustomer from '../../../../app/dashboard-peternak/master-page/customer-page/customer-aksi-page/edit-customer-page'
import ModalCreateCustomer from '../../../../app/dashboard-peternak/master-page/customer-page/customer-aksi-page/create-customer-page'
import { getCustomer, updateCustomer, deleteCustomer, detailCustomer } from './customer-aksi-page/query-customer'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import Loading from '../../../../common/component/loading/loading-container'
import { useTranslation } from 'react-i18next'
import { isMobileOnly } from 'react-device-detect'

function ExamplePage(props) {
    const { profil } = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('');
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [object, setObject] = useState([])
    const [detail, setDetail] = useState('')
    const [loading, setLoading] = useState(false)
    const [idData, setIdData] = useState(null)
    const { Content } = Layout

    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = () => {
        setLoading(true)
        API.get(getCustomer('', 0, 0))
            .then(res => {
                setObject(res.data.data.customers.customers)
                setLoading(false)
            }).catch((error) => {
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteCustomer(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteCustomer.deleted === true) {
                    validationMessage('success', `${t('pages.master.customer.resDelSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.master.customer.resDelFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.master.customer.delConfirm')} ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(detailCustomer(idData))
                    .then(res => {
                        setDetail(res.data.data.customer)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])


    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = (values) => {
        API.post(updateCustomer(idData, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.master.customer.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.updateCustomer._id) {
                    validationMessage('success', `${t('pages.master.customer.resEditSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.master.customer.resEditFailed')}`, error.message)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setLoading(true)
        setIdData(null)
    }

    const columns = [
        {
            title: `${t('pages.master.customer.name')}`,
            dataIndex: 'nama',
            key: 'nama',
            ...getColumnSearchProps('nama', `${t('pages.master.customer.name')}`),
        },
        {
            title: `${t('pages.master.customer.address')}`,
            dataIndex: 'alamat',
            key: 'alamat',
            ...getColumnSearchProps('alamat', `${t('pages.master.customer.address')}`),
        },
        {
            title: `${t('pages.master.customer.contact')}`,
            dataIndex: 'kontak',
            key: 'kontak',
            ...getColumnSearchProps('kontak', `${t('pages.master.customer.contact')}`),
        },
        {
            title: `${t('pages.master.customer.info')}`,
            dataIndex: 'informasi',
            key: 'informasi',
            ...getColumnSearchProps('informasi', `${t('pages.master.customer.info')}`),
        },
        !profil.permission.writeMaster ? { width: 1 } :
            {
                title: `${t('general.action')}`,
                dataIndex: 'pilihan',
                key: 'pilihan',
                align: 'center',
                width: 130,
                render: (value, row, index) => (
                    <Space size="middle">
                        <Tooltip placement="top" title='Edit'>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FFA903',
                                borderRadius: '5px'
                            }} onClick={() => showModal(row.id)}><FontAwesomeIcon icon={faEdit} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('general.del')}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FF0303',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.id, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </Tooltip>
                    </Space>
                )
            }
    ]

    const data = object.map(({ _id, name, address, contact, otherInformation }, index) => ({
        key: _id,
        id: _id,
        alamat: address,
        nama: name,
        kontak: contact,
        informasi: otherInformation,
    }))

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col lg={12} sm={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.customer.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={12} sm={24}>
                        {!profil.permission.writeMaster ? <></> :
                            <ModalCreateCustomer fetchData={fetchData} setLoading={setLoading} t={t} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                    <ModalEditCustomer visible={visible} detail={detail}
                        handleCancel={handleCancel} handleSubmit={handleSubmit} t={t} />
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    role: state.profil.dataProfil.type,
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(ExamplePage)
export default page