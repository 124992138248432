export const sidebar = {
    dashboard: "Dashboard",
    rearing: "Rearing Period",
    recording: "Recording",
    stock: "Stock",
    subMenustock: {
        feed: "Feed",
        ovk: "Medicine",
        material: "Material"
    },
    monitor: "Monitor",
    subMenuMonitor: {
        device: 'Device',
        ews: 'EWS'
    },
    generalUse: "General Use",
    stockflow: "Stock Flow",
    subMenuStockflow: {
        period: "Period",
        livebird: "Live Bird",
        feed: "Feed",
        ovk: "Medicine",
        material: "Material",
        resume: "Recapitulation"
    },
    master: "Master",
    subMenuMaster: {
        house: "House",
        strain: "Strain",
        doc: "DOC",
        environment: "Environment",
        feedHouse: "Warehouse",
        feed: "Feed",
        ovkHouse: "Medicine Warehouse",
        ovk: "Medicine",
        materialHouse: "Material Warehouse",
        material: "Material",
        overhead: "Overhead",
        disease: "Disease",
        customer: "Customer",
        supplier: "Supplier Contact",
        user: "Staff"
    },
    adPremium: 'Upgrade Premium',
    adIot: 'Get BroilerX IoT',
    profile: 'Profile',
    subscription: 'Subscription',
    logout: 'Logout',
}