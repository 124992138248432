import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Input, Select } from 'antd'
import Autocomplete from "react-google-autocomplete"

function EditPetugasPage(props) {
    const { visible, handleCancel, detail, handleSubmit, kandang, t } = props
    const { Option } = Select
    const [hidden, setHidden] = useState(true)
    const [form] = Form.useForm()
    const [data, setData] = useState(detail)

    const onChangeType = (val) => {
        if (val.value === 'staff')
            setHidden(false)
        else setHidden(true)
    }

    useEffect(() => {
        function setDetailData() {
            if (detail.value === 'staff') {
                setHidden(false)
            }
            else {
                setHidden(true)
            }
        }
        setDetailData()
    }, [detail])

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()

        form.setFieldsValue({
            _id: data._id,
            namaPetugas: data.name,
            username: data.username,
            alamat: data.address,
            email: data.email,
            nomorTelefon: data.phone,
            house: data.accessshouse?.map(d => d._id),
            type: data
        })
    }, [data, detail, form])

    return (
        <Modal
            visible={visible}
            title={t('pages.master.staff.edit')}
            okText={t('general.edit')}
            width={800}
            cancelText={t('general.cancel')}
            onCancel={() => handleCancel()}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields()
                        handleSubmit(values)
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info)
                    })
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Row gutter={[16, 8]}>
                    <Col lg={12} sm={24}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.staff.name')}</span>
                        <Form.Item
                            name="namaPetugas" rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Input
                                placeholder={t('pages.master.staff.phName')}
                                className="input-form mt-5"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={12} sm={24}>
                        <span className="font-semi-bold black font-title-medium">Username</span>
                        <Form.Item
                            name="username" rules={[{ required: true, message: `${t('pages.master.staff.phUsername')}`, min: 4 }]}
                        >
                            <Input
                                placeholder={t('pages.master.staff.phUsername')}
                                className="input-form mt-5"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={12} sm={24}>
                        <span className="font-semi-bold black font-title-medium">Email</span>
                        <Form.Item
                            name="email" rules={[{ required: true, message: `${t('pages.master.staff.msgEmail')}`, type: 'email' }]}
                        >
                            <Input
                                placeholder={t('pages.master.staff.phEmail')}
                                className="input-form mt-5"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={12} sm={24}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.staff.phone')}</span>
                        <Form.Item
                            name="nomorTelefon" rules={[{ required: true, message: `${t('pages.master.staff.msgPhone')}` }]}
                        >
                            <Input
                                placeholder="+62..."
                                className="input-form mt-5"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.staff.type')}</span>
                        <Form.Item
                            name="type" rules={[{ required: true, message: `${t('pages.master.staff.msgType')}` }]}
                        >
                            <Select
                                showSearch
                                labelInValue
                                placeholder={t('pages.master.staff.phType')}
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                className="select-kategori black font-regular"
                                onChange={(value) => onChangeType(value)}
                            >
                                <Option value="staff">{t('pages.master.staff.staff')}</Option>
                                <Option value="management">{t('pages.master.staff.management')}</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} hidden={hidden}>
                        <span className="black font-semi-bold font-title-content" >{t('pages.master.staff.house')}</span>
                        <Form.Item
                            name="house"
                        >
                            <Select
                                showSearch
                                allowClear
                                mode="multiple"
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                placeholder={t('pages.master.staff.phHouse')}
                                className="select-kategori black font-regular"
                            >
                                {
                                    kandang.map(d =>
                                        <Option
                                            key={d.name.toString()}
                                            value={d._id}
                                        >{d.name}</Option>

                                    )
                                }

                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <span className="black font-semi-bold font-title-content" >{t('pages.master.staff.address')}</span>
                        <Form.Item
                            name="alamat" rules={[{ required: true, message: `${t('pages.master.staff.msgAddress')}` }]}
                        >
                            <Autocomplete
                                placeholder={t('pages.master.staff.phAddress')}
                                className='ant-input input-register'
                                apiKey={process.env.REACT_APP_GOOGLE_TOKEN}
                                onPlaceSelected={place => {
                                    form.setFieldsValue({ idAddr: place.place_id, alamat: place.formatted_address })
                                }}
                                options={{
                                    types: ["geocode", "establishment"],
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col hidden>
                        <Form.Item name="idAddr" >
                            <Input className="input-register" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    kandang: state.layout.dataKandang,
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPetugasPage)
export default page