import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Form, Modal } from 'antd'
import { getListProfil, updateProfil, updatePassword } from '../../../modules/dashboard-peternak/profil-component/store/profil-action'
import ProfilComponent from '../../../modules/dashboard-peternak/profil-component/profil-component'
import { getProfile, updateProfile, changePassword, deleteAccount } from './query-profil'
import { useTranslation } from "react-i18next"
import { API } from '../../../common/api'
import CONSTANTS from '../../../common/utils/Constants'
import { navigate } from '../../../common/store/action'
import { errMessage } from '../../../common/component/notification/notification'
import Cookies from 'js-cookie'
import { DeleteOutlined } from '@ant-design/icons'
const { confirm } = Modal

function ProfilPage(props) {
    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const { getListProfil, updateProfil, updatePassword } = props
    const [disabled, setDisabled] = useState(true)
    const [passChange, setPassChange] = useState(false)
    const [feedback, setFeedback] = useState('')
    const [openFeedback, setOpenFeedback] = useState(false)
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        getListProfil(getProfile())
    }, [getListProfil])

    const handleSubmit = (values) => {
        updateProfil(updateProfile(values))
        setDisabled(true)
    }

    const handleSubmitPassword = (values) => {
        updatePassword(changePassword(values), setPassChange)
        form.resetFields()
    }

    const deleteData = async (values) => {
        setLoading(true)
        await API.delete(deleteAccount(props.profil._id, values.feedback))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `Gagal menghapus akun`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteUser.deleted === true) {
                    errMessage('success', `Berhasil menghapus akun`)
                    Cookies.remove('token', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                    Cookies.remove('loginTimes', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                    props.navigate(CONSTANTS.HOME_MENU_KEY)
                }
            }).catch((error) => {
                errMessage('error', `Gagal menghapus akun`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = () => {
        confirm({
            title: `Anda yakin akan menghapus akun Anda secara permanen?`,
            onOk: () => {
                setOpenFeedback(true)
            },
            centered: true,
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    return (
        <ProfilComponent
            navigate={props.navigate}
            disabled={disabled}
            setDisabled={setDisabled}
            handleSubmit={handleSubmit}
            handleSubmitPassword={handleSubmitPassword}
            profil={props.profil} getListProfil={getListProfil}
            loading={props.loading} getProfile={getProfile}
            passChange={passChange}
            setPassChange={setPassChange}
            form={form} t={t}
            deleteData={deleteData}
            feedback={feedback}
            setFeedback={setFeedback}
            hapusConfirm={hapusConfirm}
            openFeedback={openFeedback}
            setOpenFeedback={setOpenFeedback}
            form2={form2}
            loadingDelete={loading}
        />
    )
}

const mapStateToProps = state => ({
    profil: state.profil.dataProfil,
    loading: state.profil.loading,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListProfil,
    updateProfil,
    updatePassword,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(ProfilPage)
export default page