import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Input, Layout, Breadcrumb, Row, Col, Table, Modal, Drawer, Form } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faEdit, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getDataStrain, getIdStrain, getNameStrain } from './store/strain-action'
import { getStrain, deleteStrain, updateStrain } from './strain-aksi-page/query-strain'
import CreateStrain from './strain-aksi-page/create-strain-page'
import { validationMessage } from '../../../../common/component/notification/notification'
import { API } from '../../../../common/api'
import CONSTANTS from '../../../../common/utils/Constants'
import Loading from '../../../../common/component/loading/loading-container'
import { useTranslation } from 'react-i18next'

function StrainPage(props) {
    const { getDataStrain, getIdStrain, getNameStrain, role } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [object, setObject] = useState([])
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [name, setName] = useState('')
    const [idData, setIdData] = useState(null)
    const { Content } = Layout
    const { confirm } = Modal
    const [form] = Form.useForm()
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = async () => {
        setLoading(true)
        await API.get(getStrain())
            .then(res => {
                setObject(res.data.data.strains.strains)
                getDataStrain(res.data.data.strains.strains)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                getDataStrain([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line 
    }, [])

    const showDetail = useCallback((value, strain) => {
        getIdStrain(value)
        getNameStrain(strain)
        props.navigate(CONSTANTS.STANDAR_STRAIN_MENU_KEY)
    }, [getIdStrain, getNameStrain, props])


    const data = object.map(({ _id, name, type }, index) => ({
        key: _id,
        id: _id,
        strain: name,
        type: type,
    }))

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteStrain(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `Gagal menghapus strain`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteStrain.deleted === true) {
                    validationMessage('success', `Berhasil menghapus strain`)
                    fetchData()
                }
            }).catch((error) => {
                validationMessage('error', `Gagal menghapus strain`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `Apakah Anda yakin ingin menghapus ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const onOpen = useCallback((id, name) => {
        setVisible(true)
        setIdData(id)
        setName(name)
        form.setFieldsValue({ name: name })
    }, [form])

    const updatedData = (values) => {
        API.post(updateStrain(idData, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `Gagal mengubah strain`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.updateStrain._id) {
                    validationMessage('success', `Berhasil mengubah strain`)
                    fetchData()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `Gagal mengubah strain`, error.message)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setLoading(true)
        setIdData(null)
    }
    const columns = [
        {
            title: `${t('pages.master.strain.name')}`,
            dataIndex: 'strain',
            key: 'strain',
            ...getColumnSearchProps('strain', `${t('pages.master.strain.name')}`),
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 180,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('general.detail')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }} onClick={() => showDetail(row.id, row.strain)}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button
                            disabled={row.type === 'primary' && role !== 'superadmin'}
                            style={{
                                color: 'white',
                                backgroundColor: row.type === 'primary' && role !== 'superadmin' ? 'whitesmoke' : '#FFA903',
                                borderRadius: '5px'
                            }}
                            onClick={() => onOpen(row.id, row.strain)}
                        ><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button
                            style={{
                                color: 'white',
                                backgroundColor: '#FF0303',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.id, row.strain)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.strain.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12}>
                        <CreateStrain setLoading={setLoading} fetchData={fetchData} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{ y: '70vh' }}
                                className="table-dashboard"
                            />
                        </Loading>
                        <Drawer
                            title={false} width={500}
                            placement="right"
                            visible={visible} hidden={!visible}
                            closable={false}
                            onClose={() => setVisible(false)}
                            getContainer={false}
                            style={{ position: 'absolute', opacity: visible ? 100 : 0 }}
                        >
                            <Form onFinish={handleSubmit} form={form}
                                initialValues={{ name: name }}
                            >
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            name="name" rules={[{ required: true, message: `Mohon masukkan nama strain!` }]}
                                        >
                                            <Input
                                                placeholder='Nama Strain'
                                                className="input-form"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <ButtonDashboard
                                            text='Batal'
                                            height={20}
                                            borderRadius="5px" marginLeft={5}
                                            className="font-semi-medium font-content-title button-add"
                                            onClick={() => setVisible(false)}
                                        />
                                    </Col>
                                    <Col>
                                        <ButtonDashboard
                                            htmlType="submit"
                                            text='Edit'
                                            height={20}
                                            backgroundColor="#008ad4"
                                            borderRadius="5px" marginLeft={10}
                                            className="font-semi-medium font-content-title button-add"
                                            textColor="white"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </Drawer>
                    </Col>
                </Row>
            </Content>
        </Layout >
    )
}

const mapStateToProps = state => ({
    role: state.profil.dataProfil.type,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getDataStrain,
    getIdStrain, getNameStrain
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(StrainPage)
export default page