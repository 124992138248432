import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input, Layout, Breadcrumb, Row, Col, Table, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteTwoTone } from '@ant-design/icons'
import { faTrash, faEdit, faUnlock, faLock } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next"

import ModalCreateKandang from './kandang-aksi-page/create-kandang-page'
import ModalEditKandang from './kandang-aksi-page/edit-kandang-page'

import { getListGudangOvk } from '../../../../modules/dashboard-peternak/master-component/gudang-ovk-component/store/gudang-ovk-action'
import { getListGudangPakan } from '../../../../modules/dashboard-peternak/master-component/gudang-pakan-component/store/gudang-pakan-action'
import { queryKandang, deleteKandang, updateKandang, detailKandang, activateHouse, deactivateHouse } from './kandang-aksi-page/query-kandang'
import { queryGudangPakan } from '../gudang-pakan-page/gudang-pakan-aksi-page/query-gudang-pakan'
import { getGudangOvk } from '../gudang-ovk-page/gudang-ovk-aksi-page/query-gudang-ovk'

import { API } from '../../../../common/api'
import { errMessage } from '../../../../common/component/notification/notification'
import Loading from '../../../../common/component/loading/loading-container'

function KandangPage(props) {
    const { confirm } = Modal
    const { getListGudangOvk, getListGudangPakan, profil } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState('')
    const [idData, setIdData] = useState(null)
    const [object, setObject] = useState([])
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(null)
    const [, setLimit] = useState(null)
    const [, setActive] = useState(null)
    const { Content } = Layout
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = async () => {
        setLoading(true)
        await API.get(queryKandang('', 0, 0))
            .then(res => {
                setTotal(res.data.data.houses.totalCount)
                setActive(res.data.data.houses.totalActive)
                setObject(res.data.data.houses.houses)
                setLimit(res.data.data.houses.houses[0].company.house_limit)
                setLoading(false)
            }).catch((error) => {
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        getListGudangOvk(getGudangOvk('', 0, 0))
        getListGudangPakan(queryGudangPakan('', 0, 0))
        fetchData()
    }, [getListGudangOvk, getListGudangPakan])

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deleteKandang(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.house.resDelFailed')}`, res.data.errors[0].message)
                }
                else if (res.data.data.deleteHouse.deleted === true) {
                    errMessage('success', `${t('pages.master.house.resDelSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                if (error.message === "Cannot read property 'deleted' of null") {
                    errMessage('error', 'Kandang tidak dapat dihapus karena sedang digunakan pada periode pemeliharaan')
                    setLoading(false)
                } else {
                    errMessage('error', `${t('pages.master.house.resDelFailed')}`, error.message)
                    setLoading(false)
                }
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.master.house.delConfirm')} ${nama}?`,
            icon: <DeleteTwoTone twoToneColor="red" />,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            maskClosable: true,
            centered: true,
            content: (<b>{t('pages.master.house.delConfirmContent')}</b>),
            okButtonProps: { danger: true },
            onOk: () => {
                deleteData(_id)
            },
        })
    }

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(detailKandang(idData))
                    .then(res => {
                        setDetail(res.data.data.house)
                        setVisible(true)
                    }).catch((error) => {
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const activate = async (id) => {
        setLoading(true)
        await API.get(activateHouse(id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.house.resActivateFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.activateHouse._id) {
                    errMessage('success', `${t('pages.master.house.resActivateSuccess')}`)
                    setLoading(false)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.master.house.resActivateFailed')}`, error.message)
                setLoading(false)
            })
    }

    const deactivate = async (id) => {
        setLoading(true)
        await API.get(deactivateHouse(id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.house.resDeactivateFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deactivateHouse._id) {
                    errMessage('success', `${t('pages.master.house.resDeactivateSuccess')}`)
                    setLoading(false)
                    fetchData()
                } else {
                    errMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                errMessage('error', `${t('pages.master.house.resDeactivateFailed')}`, error.message)
                setLoading(false)
            })
    }

    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])
    
    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = async (values) => {
        await API.post(updateKandang(idData, values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.house.resEditFailed')}`, res.data.errors[0].message)
                }
                else if (res.data.data.updateHouse._id) {
                    errMessage('success', `${t('pages.master.house.resEditSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.master.house.resEditFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const columns = [
        {
            title: `${t('pages.master.house.name')}`,
            dataIndex: 'kandang',
            key: 'kandang',
            width: 200,
            ...getColumnSearchProps('kandang', `${t('pages.master.house.name')}`),
            render: (value, row, index) => (
                <>{value} {row.type && <Tag color='green'>{row.type.toUpperCase()}</Tag>}</>
            )
        },
        {
            title: `${t('pages.master.house.capacity')}`,
            dataIndex: 'kapasitas',
            key: 'kapasitas',
            width: 130,
        },
        {
            title: `${t('pages.master.warehouse.feed')}`,
            dataIndex: 'feedWarehouse',
            ...getColumnSearchProps('feedWarehouse', 'Gudang Pakan'),
            key: 'feedWarehouse',
            width: 100,
        },
        {
            title: `${t('pages.master.warehouse.ovk')}`,
            dataIndex: 'ovkWarehouse',
            ...getColumnSearchProps('ovkWarehouse', 'Gudang OVK'),
            width: 100,
            key: 'ovkWarehouse',
        },
        {
            title: `${t('pages.master.house.address')}`,
            dataIndex: 'alamat',
            width: 250,
            key: 'alamat',
            ...getColumnSearchProps('alamat', `${t('pages.master.house.address')}`),
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            key: 'isActive',
            align: 'center',
            width: 130,
            render: (value, row, index) => (
                <Tag color={value === true ? '#87d068' : '#f50'}>{value === true ? 'Aktif' : 'Nonaktif'}</Tag>
            )
        },
        profil.permission.writeMaster ? {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 180,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={row.isActive ? 'Nonaktifkan' : 'Aktifkan'}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: row.isActive ? '#ff4a3d' : '#03a5fc',
                            borderRadius: '5px'
                        }}
                            onClick={row.isActive ? () => deactivate(row.key) : () => activate(row.key)}
                        ><FontAwesomeIcon icon={row.isActive ? faLock : faUnlock} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key, row.kandang)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        } : { width: 1 }
    ]

    const data = object.map(({ _id, address, type, isActive, name, capacity, feedWarehouse, ovkWarehouse }, index) => ({
        key: _id,
        alamat: address,
        kandang: name,
        type: type,
        isActive: isActive,
        feedWarehouse: feedWarehouse.name,
        ovkWarehouse: ovkWarehouse.name,
        kapasitas: <NumberFormat value={capacity} displayType={'text'} thousandSeparator={true} />,
    }))

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col lg={12} sm={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.house.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={12} sm={24} hidden={!profil.permission.writeMaster}>
                        <ModalCreateKandang fetchData={fetchData} total={total} setLoading={setLoading} t={t} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{ y: '70vh' }}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                    <ModalEditKandang visible={visible} idData={idData}
                        handleCancel={handleCancel} detail={detail} handleSubmit={handleSubmit} t={t} />
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
    getListGudangOvk,
    getListGudangPakan,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(KandangPage)
export default page
