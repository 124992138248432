import * as actionType from './ovk-action-type';

const initialState = {
    dataOvk: [],
    dataOvkType: [],
    dataOvkShape: [],
    dataOvkUnit: [],
    dataOvkMedia: [],
    dataOvkUnitRecording : [],
    detailOvk : {},
    idOvk : null,
    loading: false,
}

const handler = (currentState) => {
    const setDataOvk = {
        startGetDataOvks: () => ({
            ...currentState,
            loading: true,
        }),
        finishGetDataOvks: () => ({
            ...currentState,
            loading: false,
        }),
        getDataDetailOvk: data => ({
            ...currentState,
            detailOvk: data
        }),
        getIdOvk: data => ({
            ...currentState,
            idOvk: data
        }),
        getDataOvk: data => ({
            ...currentState,
            dataOvk: data,
        }),
        getDataOvkTypes: data => ({
            ...currentState,
            dataOvkType: data
        }),

        getDataOvkShape: data => ({
            ...currentState,
            dataOvkShape: data
        }),

        getDataOvkUnit: data => ({
            ...currentState,
            dataOvkUnit: data
        }),

        getDataOvkMedia: data => ({
            ...currentState,
            dataOvkMedia: data
        }),

        getDataOvkUnitRecording: data => ({
            ...currentState,
            dataOvkUnitRecording: data
        }),

    }

    return {
        ...setDataOvk
    };
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case actionType.START_GET_DATA:
            return handler(state).startGetDataOvks();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataOvks();
        case actionType.SET_DETAIL_OVK:
            return handler(state).getDataDetailOvk(payload);
        case actionType.SET_ID_OVK:
            return handler(state).getIdOvk(payload);
        case actionType.SET_DATA_OVK:
            return handler(state).getDataOvk(payload);
        case actionType.SET_DATA_OVK_TYPE:
            return handler(state).getDataOvkTypes(payload);
        case actionType.SET_DATA_OVK_MEDIA:
            return handler(state).getDataOvkMedia(payload);
        case actionType.SET_DATA_OVK_SHAPE:
            return handler(state).getDataOvkShape(payload);
        case actionType.SET_DATA_OVK_UNIT:
            return handler(state).getDataOvkUnit(payload);
        case actionType.SET_DATA_OVK_UNIT_RECORDING:
            return handler(state).getDataOvkUnitRecording(payload);

        default:
            return state;
    }
}