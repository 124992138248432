import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Input, Select, DatePicker } from 'antd'
import moment from 'moment'

function EditOvk(props) {
    const { Option } = Select;
    const { visible, handleSubmit, handleCancel, detail,
        ovkUnit, suffix, setSuffix, ovkUnitRecording, t } = props
    const [data, setData] = useState([])
    const [form] = Form.useForm()

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    useEffect(() => {
        form.setFieldsValue({
            ovkShape: data.ovkShape,
            ovkType: data.ovkType,
            ovkUnit: data.ovkUnit,
            ovkUnitRecording: data.ovkUnitRecording ? data.ovkUnitRecording : '',
            ovkMedia: data.ovkMedia,
            kodeOvk: data.code,
            namaOvk: data.name,
            dosisOvk: data.dosis?.replace(/\D+$/g, ''),
            dosisUnit: data.dosis?.replace(/[0-9.\s+,]/g, '') === '' ? '%' : data.dosis?.replace(/[0-9.\s+,]/g, ''),
            penggunaan: data.recommendedUse,
            kandunganOvk: data.ingredient,
            distributor: data.producer,
            qtyUnit: data.qtyUnit,
            date_picker: moment(new Date(parseInt(data.expired))),
        })
        setSuffix(data.ovkUnitRecording?.name ? data.ovkUnitRecording.name : '')
    }, [data, form, setSuffix])

    return (
        <Modal
            visible={visible}
            title={t('pages.master.ovk.edit')}
            okText={t('general.edit')}
            width={1000}
            centered
            cancelText={t('general.cancel')}
            onCancel={() => handleCancel()}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields()
                        handleSubmit(values)
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info)
                    })
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    ovkShape: data.ovkShape,
                    ovkType: data.ovkType,
                    ovkUnit: data.ovkUnit,
                    ovkUnitRecording: data.ovkUnitRecording ? data.ovkUnitRecording : '',
                    ovkMedia: data.ovkMedia,
                    kodeOvk: data.code,
                    namaOvk: data.name,
                    dosisOvk: data.dosis?.replace(/\D+$/g, ''),
                    dosisUnit: data.dosis?.replace(/[0-9.\s+,]/g, ''),
                    penggunaan: data.recommendedUse,
                    kandunganOvk: data.ingredient,
                    distributor: data.producer,
                    qtyUnit: data.qtyUnit,
                    date_picker: moment(new Date(parseInt(data.expired))),
                }}
            >
                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.name')}</span>
                        <Form.Item
                            name="namaOvk" rules={[{ required: true, message: `${t('pages.master.ovk.msgName')}` }]}
                        >
                            <Input
                                name='namaOvk'
                                placeholder="Nupros..."
                                className="input-form mt-5"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    <Col lg={8} sm={24}>
                        <span className="black font-semi-bold font-title-content">{t('pages.master.ovk.shape')}</span>
                        <Form.Item
                            name="ovkShape" rules={[{ required: true, message: `${t('pages.master.ovk.msgShape')}` }]}
                        >
                            <Select
                                showSearch
                                placeholder={t('pages.master.ovk.phShape')}
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                size="large"
                                className="select-kategori black font-regular"
                            >
                                <Option value='Botol'>{t('pages.master.ovk.botol')}</Option>
                                <Option value='Karung'>{t('pages.master.ovk.karung')}</Option>
                                <Option value='Vial'>{t('pages.master.ovk.vial')}</Option>
                                <Option value='Pil'>{t('pages.master.ovk.pil')}</Option>
                                <Option value='Sachet'>{t('pages.master.ovk.sachet')}</Option>
                                <Option value='Jerigen'>{t('pages.master.ovk.jerigen')}</Option>
                                <Option value='Jar'>{t('pages.master.ovk.jar')}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={8} sm={24}>
                        <span className="black font-semi-bold font-title-content">{t('pages.master.ovk.type')}</span>
                        <Form.Item
                            name="ovkType" rules={[{ required: true, message: `${t('pages.master.ovk.msgType')}` }]}
                        >
                            <Select
                                showSearch
                                placeholder={t('pages.master.ovk.phType')}
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                className="select-kategori black font-regular"
                            >
                                <Option value='Antibiotik'>Antibiotik</Option>
                                <Option value='Probiotik'>Probiotik</Option>
                                <Option value='Acidifier'>Acidifier</Option>
                                <Option value='Anti Cocci'>Anti Cocci</Option>
                                <Option value='Anti Protozoa'>Anti Protozoa</Option>
                                <Option value='Vitamin'>Vitamin</Option>
                                <Option value='Anti Stress'>Anti Stress</Option>
                                <Option value='Dekomposer'>Dekomposer</Option>
                                <Option value='Desinfektan'>Desinfektan</Option>
                                <Option value='Desinfektan Air'>Desinfektan Air</Option>
                                <Option value='Diuretik'>Diuretik</Option>
                                <Option value='Ekspektoran'>Ekspektoran</Option>
                                <Option value='Feed Additive'>Feed Additive</Option>
                                <Option value='Hepatoprotektor'>Hepatoprotektor</Option>
                                <Option value='Insektisida'>Insektisida</Option>
                                <Option value='Paracetamol'>Paracetamol</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={8} sm={24}>
                        <span className="black font-semi-bold font-title-content">{t('pages.master.ovk.media')}</span>
                        <Form.Item
                            name="ovkMedia" rules={[{ required: true, message: `${t('pages.master.ovk.msgMedia')}` }]}
                        >
                            <Select
                                showSearch mode='tags'
                                tokenSeparators={[',']}
                                placeholder={t('pages.master.ovk.phMedia')}
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                className="select-kategori black font-regular"
                            >
                                <Option value='Spray'>{t('pages.master.ovk.spray')}</Option>
                                <Option value='Pakan'>{t('pages.master.ovk.pakan')}</Option>
                                <Option value='Air Minum'>{t('pages.master.ovk.air')}</Option>
                                <Option value='Tebar'>{t('pages.master.ovk.tebar')}</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[16, 8]}>
                    <Col lg={8} sm={24}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.dose')}</span>
                        <Form.Item
                            name="dosisOvk" rules={[{ required: true, message: `${t('pages.master.ovk.msgDose')}` }]}
                        >
                            <Input
                                placeholder="0.01"
                                className="input-suffix mt-5"
                                addonAfter={
                                    <Form.Item name='dosisUnit' noStyle>
                                        <Select style={{ width: 150 }}>
                                            <Option value="%">%</Option>
                                            <Option value="gram/liter">gram/liter</Option>
                                            <Option value="gram/kg">gram/kg</Option>
                                        </Select>
                                    </Form.Item>}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={8} sm={24}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.usage')}</span>
                        <Form.Item
                            name="penggunaan" rules={[{ required: true, message: `${t('pages.master.ovk.msgUsage')}` }]}
                        >
                            <Input
                                placeholder="3 hari..."
                                className="input-form mt-5"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={8} sm={24}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.supplier')}</span>
                        <Form.Item
                            name="distributor"
                        >
                            <Input
                                placeholder="kimia farma..."
                                className="input-form mt-5"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    <Col lg={8} sm={24}>
                        <span className="black font-semi-bold font-title-content">{t('pages.master.ovk.procuUnit')}</span>
                        <Form.Item
                            name="ovkUnit" rules={[{ required: true, message: `${t('pages.master.ovk.msgProcuUnit')}` }]}
                        >
                            <Select
                                showSearch
                                labelInValue
                                placeholder={t('pages.master.ovk.phProcuUnit')}
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                className="select-kategori black font-regular"
                            >
                                {
                                    ovkUnit.map(data =>
                                        <Option
                                            value={data._id}
                                        >{data.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={8} sm={24}>
                        <span className="black font-semi-bold font-title-content">{t('pages.master.ovk.usageUnit')}</span>
                        <Form.Item
                            name="ovkUnitRecording" rules={[{ required: true, message: `${t('pages.master.ovk.msgUsageUnit')}` }]}
                        >
                            <Select
                                showSearch
                                labelInValue
                                placeholder={t('pages.master.ovk.phUsageUnit')}
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                className="select-kategori black font-regular"
                                onChange={(value) => setSuffix(value.label)}
                            >
                                {
                                    ovkUnitRecording.map(data =>
                                        <Option
                                            value={data._id}
                                        >{data.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={8} sm={24}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.qtyUsage')}</span>
                        <Form.Item name="qtyUnit" rules={[{ required: true, message: '' }]}>
                            <Input
                                placeholder="50..."
                                className="input-form"
                                type='number'
                                suffix={suffix}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[16, 8]}>
                    <Col lg={12} sm={24}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.ovk.ingredient')}</span>
                        <Form.Item
                            name="kandunganOvk" rules={[{ required: false, message: `${t('pages.master.ovk.msgIngredient')}` }]}
                        >
                            <Input.TextArea
                                placeholder={t('pages.master.ovk.phIngredient')}
                                className="input-form mt-5"
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={12} sm={24}>
                        <span className="black font-semi-bold font-title-content">{t('pages.master.ovk.expired')}</span>
                        <Form.Item
                            name="date_picker" rules={[{ type: 'object', required: false, message: `${t('pages.master.ovk.msgExpired')}` }]}
                        >
                            <DatePicker
                                placeholder={t('pages.master.ovk.phExpired')}
                                className="select-input-date"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

export default EditOvk