import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Button, Input, Tooltip, Modal, Layout, Row, Col, Table } from 'antd'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { getDetailRecordPenyakitEdit, deleteRecordPenyakit } from '../rearing-record-aksi-page/query-rearing-record'
import { getIdDiseaseRecord, getDetailDiseaseRecord } from '../../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action'
import { API } from '../../../../common/api'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faImage } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next"
import Loading from '../../../../common/component/loading/loading-container'
import { useParams, useHistory } from 'react-router-dom'

function DetailPenyakitPage(props) {
    const { penyakit, fetchDataPenyakit, idRearingRecord, setLoading, loading, getDetailDiseaseRecord, getIdDiseaseRecord, houseActive, profil } = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const { Content } = Layout
    const { t } = useTranslation()
    const params = useParams()
    const history = useHistory()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t("general.search")} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t("general.reset")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deleteRecordPenyakit(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.recording.bw.resDelFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.deleteDiseaseRecord.deleted === true) {
                    validationMessage('success', `${t("pages.recording.disease.resDelSuccess")}`)
                    fetchDataPenyakit(idRearingRecord)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.disease.resDelFailed")}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t("pages.recording.disease.delConfirm")}`,
            onOk: () => { deleteData(_id) },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const showEdit = useCallback((value) => {
        getDetailDiseaseRecord(getDetailRecordPenyakitEdit(value))
        history.push(`/peternak/recording/${params.rearingId}/${params.recordingId}/edit-penyakit`)
    }, [getDetailDiseaseRecord, history, params])

    const showPicture = useCallback((value) => {
        getIdDiseaseRecord(value)
        history.push(`/peternak/recording/${params.rearingId}/${params.recordingId}/foto-penyakit`)
    }, [getIdDiseaseRecord, history, params])


    const columns = [
        {
            title: `${t("pages.recording.disease.qty")}`,
            dataIndex: 'ayam',
            key: 'ayam',
            width: 80
        },
        {
            title: `${t("pages.recording.disease.title")}`,
            dataIndex: 'penyakit',
            key: 'penyakit',
            width: 150,
            ...getColumnSearchProps('penyakit', `${t("pages.recording.disease.title")}`),
        },
        {
            title: `${t("general.action")}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 100,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("pages.recording.disease.pic")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#30c758',
                            borderRadius: '5px'
                        }} onClick={() => showPicture(row.key)}><FontAwesomeIcon icon={faImage} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("general.edit")}>
                        <Button disabled={houseActive ? false : true}
                            hidden={profil.permission.editRecording ? false : true} style={{
                                color: 'white',
                                backgroundColor: houseActive ? '#FFA903' : 'whitesmoke',
                                borderRadius: '5px'
                            }} onClick={() => showEdit(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("general.del")}>
                        <Button disabled={houseActive ? false : true}
                            hidden={profil.permission.writeRecording ? false : true} style={{
                                color: 'white',
                                backgroundColor: houseActive ? '#FF0303' : 'whitesmoke',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = penyakit.map(({ _id, number, disease }, index) => ({
        key: _id,
        ayam: <NumberFormat value={number} displayType={'text'} thousandSeparator={true} />,
        penyakit: disease.name
    }))

    return (
        <Layout>
            <Content className="dashboard-section-content background-white">
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <div className="dashboard-section-table-dashboard">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    bordered
                                    pagination={false}
                                    scroll={{ y: '70vh' }}
                                    className="table-dashboard"
                                />
                            </div>
                        </Loading>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdDiseaseRecord,
    getDetailDiseaseRecord
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailPenyakitPage)
export default page