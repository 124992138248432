import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import { Modal, Form, Row, Col, Input } from 'antd'

function EditPenyakitPage(props) {
    const { visible, handleCancel, detail, handleSubmit, t } = props
    const [data, setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.master.disease.edit')}
                okText={t('general.edit')}
                width={400}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        namaPenyakit: data.name,
                        symptom: data.symptom
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.disease.name')}</span>
                            <Form.Item
                                name="namaPenyakit" rules={[{ required: true, message: `${t('pages.master.disease.msgName')}` }]}
                            >
                                <Input
                                    name='namaPenyakit'
                                    placeholder="Flu..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.disease.symptom')}</span>
                            <Form.Item
                                name="symptom"
                            >
                                <Input.TextArea
                                    placeholder={t('pages.master.disease.phSymptom')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <CollectionCreateForm
            visible={visible}
            handleSubmit={handleSubmit}
            onCancel={() => handleCancel()}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPenyakitPage)
export default page