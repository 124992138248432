import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Tooltip, Button, Input, Modal, Layout, Row, Col, Table, Typography, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'

import { detailTransaksi, updateTransaksiPenggunaan, deleteTransaksiStokPakan } from '../stok-pakan-aksi-page/query-stok-pakan'
import ModalEditPenggunaanStokPakan from './edit-penggunaan-stok-pakan'
import { API } from '../../../../../common/api'
import Loading from '../../../../../common/component/loading/loading-container'
import { validationMessage } from '../../../../../common/component/notification/notification'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
import { isMobileOnly } from 'react-device-detect'
/*End Import Moment Js */


function PenggunaanStokPakanPage(props) {
    const { confirm } = Modal
    const { idStock, penggunaan, fetchDataPenggunaan, loading, setLoading, profil, t } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [idData, setIdData] = useState(null)
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState('')

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(detailTransaksi(idData))
                    .then(res => {
                        setDetail(res.data.data.feeding)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = async (values, year, feedStock) => {
        await API.post(updateTransaksiPenggunaan(idData, values, year, feedStock))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.feedStock.resEditTrxFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.updateFeeding._id) {
                    validationMessage('success', `${t('pages.feedStock.resEditTrxSuccess')}`)
                    fetchDataPenggunaan(idStock)
                }
            }).catch((error) => {
                setLoading(false)
                validationMessage('error', `${t('pages.feedStock.resEditTrxFailed')}`, error.message)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values, values['date_picker'].format('MM/DD/YYYY'), detail.feedStock)
        setVisible(false)
        setIdData(null)
    }

    const deleteData = async (_id) => {
        setLoading(true);
        await API.delete(deleteTransaksiStokPakan(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.feedStock.resDelTrxFailed')}`, res.data.errors[0].message)
                    setLoading(false);
                }
                else if (res.data.data.deleteFeeding.deleted === true) {
                    validationMessage('success', `${t('pages.feedStock.resDelTrxSuccess')}`)
                    fetchDataPenggunaan(idStock)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.feedStock.resDelTrxFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t('pages.feedStock.delTrxConfirm')}`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const columns = [
        {
            title: `${t('pages.feedStock.trxDate')}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            ...getColumnSearchProps('tanggal'),
            render: (value, row, index) => (
                <>{value} {row.type === 'Mutation' && <Tag color='green'>{t('pages.feedStock.mutation')}</Tag>}</>
            )
        },
        {
            title: `${t('pages.feedStock.qty')}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            align: 'right',
            ...getColumnSearchProps('jumlah'),
        },
        {
            title: `${t('pages.feedStock.qtyWeight')}`,
            dataIndex: 'jumlahWeight',
            key: 'jumlahWeight',
            align: 'right',
            ...getColumnSearchProps('jumlahWeight'),
        },
        {
            title: `Kandang`,
            dataIndex: 'house',
            key: 'house',
            align: 'center',
            ...getColumnSearchProps('house'),
        },
        profil.permission.writeStock ? {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 200,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        } : { width: 1 }
    ]

    const data = penggunaan.map(({ _id, number, numberWeight, storeTime, typeFeeding, feedStock, mutationFeedStock, house }, index) => ({
        key: _id,
        total: number,
        weight: numberWeight,
        jumlah: <NumberFormat value={number} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.feedStock.sack')}`} />,
        jumlahWeight: <NumberFormat value={numberWeight} displayType={'text'} thousandSeparator={true} suffix={' kg'} />,
        tanggal: moment(new Date(parseInt(storeTime))).format("DD MMMM YYYY"),
        type: typeFeeding,
        house: typeFeeding === 'Mutation' ? 
        <span>{house?.name}
        <br/>Destinasi: {mutationFeedStock?.feedWarehouse?.name}</span>
         : house?.name,
    }))
    return (
        <Layout style={{ backgroundColor: "white" }}>
            <Row>
                <Col lg={24} md={24} sm={24}>
                    <Loading loading={loading}>
                        <Table
                            columns={columns}
                            dataSource={data}
                            bordered
                            pagination={false}
                            scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                            className="table-dashboard"
                            summary={pageData => {
                                let totalNilai = 0
                                let totalWeight = 0
                                pageData.forEach(({
                                    total, weight
                                }) => {
                                    totalNilai += parseFloat(total)
                                    totalWeight += parseFloat(weight)
                                });
                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell className="right">
                                                <Typography.Text><NumberFormat value={parseFloat(totalNilai.toFixed(2))} suffix={` ${t('pages.feedStock.sack')}`}
                                                    displayType={'text'} thousandSeparator={true} /></Typography.Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="right">
                                                <Typography.Text><NumberFormat value={parseFloat(totalWeight.toFixed(2))} suffix={' kg'}
                                                    displayType={'text'} thousandSeparator={true} /></Typography.Text>
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell className="right">
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                )
                            }}
                        />
                    </Loading>
                </Col>
                <ModalEditPenggunaanStokPakan visible={visible} handleSubmit={handleSubmit}
                    idData={idData} handleCancel={handleCancel} detail={detail} t={t} />
            </Row>
        </Layout>
    )
}

const mapStateToProps = state => ({
    role: state.profil.dataProfil.type,
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(PenggunaanStokPakanPage)
export default page