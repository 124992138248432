
export const blobToDataURL = (blob, fileName) => {
    return new Promise(r => {
        let a = new FileReader();
        a.onload = r;
        a.readAsDataURL(blob)
    }).then(e => {
        const link = document.createElement("a")
        link.href = e.target.result
        link.download = fileName
        return link.click()
    }
    );
}