export const rearing = {
    title: "Periode Budidaya",
    rearingActive: "Periode Berjalan",
    date: "Tanggal Chick In",
    name: "Periode",
    house: "Kandang",
    doc: "DOC",
    ppl: "PPL",
    popHpp: "Populasi Awal HPP",
    population: "Populasi Awal",
    weight: "Bobot DOC",
    price: "Harga DOC (Rp/ekor)",
    age: "Umur",
    depletion: "Deplesi",
    chicken: "ekor",
    add: "Tambah Periode",
    delConfirm: "Apakah Anda yakin akan menghapus",
    resAddSuccess: "Berhasil Menambahkan Periode",
    resDelSuccess: "Berhasil Menghapus Periode",
    resEditSuccess: "Berhasil Mengubah Periode",
    resCloseSuccess: "Berhasil Menutup Periode",
    resAddFailed: "Gagal Menambahkan Periode",
    resDelFailed: "Gagal Menghapus Periode",
    resEditFailed: "Gagal Mengubah Periode",
    resCloseFailed: "Gagal Menutup Periode",
    rearingClose: "Periode Selesai",
    edit: "Edit Periode",
    info: "Keterangan",
    phName: "Periode Juni...",
    phDate: "Pilih tanggal periode",
    phHouse: "Pilih kandang",
    phDoc: "Pilih DOC",
    phInfo: "Masukkan keterangan",
    msgName: "Mohon masukkan nama periode!",
    msgDate: "Mohon masukkan tanggal periode!",
    msgWeight: "Mohon masukkan bobot ayam DOC!",
    msgPopulationHpp: "Mohon masukkan populasi awal untuk HPP!",
    msgPopulation: "Mohon masukkan populasi awal untuk Performa!",
    msgHouse: "Mohon pilih kandang!",
    msgDoc: "Mohon pilih DOC!",
    msgPpl: "Mohon masukkan ppl kandang!",
    msgPrice: "Mohon masukkan harga ayam DOC!",
    close: "Tutup Periode Budidaya",
    closeDate: "Tanggal Penutupan",
    phCloseDate: "Pilih tanggal penutupan",
    msgCloseDate: "Mohon pilih tanggal penutupan!",
    detail: "Detail Periode Budidaya ",
    chart: "Grafik Performa Kandang",
    performaTable: "Tabel Performa Kandang",
    week: "Minggu ke",
    openConfirm: "Apakah anda yakin ingin membuka periode?",
    resOpenSuccess: "Berhasil membuka kembali periode",
    resOpenFailed: "Gagal membuka kembali periode"
}