import React, { useState } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import CreatePertumbuhanComponent from '../../../../modules/dashboard-peternak/rearing-record-component/create-detail-rearing-component/create-pertumbuhan-component'
import { createRecordPertumbuhan } from '../rearing-record-aksi-page/query-rearing-record'

function CreatePertumbuhanPage(props) {
    const { idRearingRecord, setLoading, fetchDataPertumbuhan, houseActive, t } = props
    const [visible, setVisible] = useState(false)

    const postData = async (values) => {
        await API.post(createRecordPertumbuhan(values, idRearingRecord))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.recording.bw.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.createGrowing._id) {
                    validationMessage('success', `${t('pages.recording.bw.resAddSuccess')}`)
                    fetchDataPertumbuhan(idRearingRecord)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.recording.bw.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreatePertumbuhanComponent
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            growingParam={props.growingParam}
            t={t} houseActive={houseActive}
        />
    )
}

const mapStateToProps = state => ({
    growingParam: state.rearingRecord.dataGrowingParams,
    dateRearing: state.rearingRecord.dateRearingRecord,
    idRearingRecord: state.rearingRecord.idRearingRecord,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePertumbuhanPage)
export default page