export const getPakan = (keyword, limit, skip) => {
    return (
        `query{
            feeds(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              feeds {
                  _id
                  name
                  type
                  variety
                  producer
                  otherInformation
                  company{
                    name
                  }
              }
            }
          }
      `
    );
}

export const createPakan = (value) => {
  return {
    query: `mutation{
              createFeed(feedInput: {
                name : "${value.namaPakan}"
                producer : "${value.perusahaan}"
                type : "${value.jenisPakan.value}"
                otherInformation : "${value.keterangan}"
              }){
                _id
                producer
              }
            }
        `
    }
}

export const detailPakan = (_id) => {
  return (
    `query{
          feed(_id : "${_id}"){
            _id
            name
            producer
            variety
            value : type
            otherInformation
            company{
              name
            }
          }
        }
    `
  );
}

export const updatePakan = (_id, value) => {
    return {
      query: `mutation{
              updateFeed(_id : "${_id}", updateFeedInput : {
                name : "${value.namaPakan}"
                type : "${value.jenisPakan.value}",
                producer : "${value.perusahaan}",
                otherInformation : "${value.keterangan}"
              }){
                _id
                producer
                type
                otherInformation
              }
            }
      `
    }   
}

export const deletePakan = (_id) => {
  return (
    `mutation{
        deleteFeed(_id : "${_id}"){
          deleted
        }
      }
    `
  );
}