import React, { useState } from 'react'
import { Layout, Breadcrumb, Row, Col, Table, DatePicker, Typography, Button } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import NumberFormat from 'react-number-format'
import { FileExcelOutlined } from '@ant-design/icons'
import { validationMessage } from '../../../../common/component/notification/notification'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { blobToDataURL } from '../../../../common/utils/Download'

function ResumeComponent(props) {
  const { Content } = Layout
  const { Text } = Typography
  const { columns, data, loading, handleTanggalRange, month, year, disabledDate, t } = props
  const [load, setLoad] = useState(false)
  const cookies = new Cookies()

  const exportData = async () => {
    const params = new FormData()
    params.set('month', month)
    params.set('year', year)
    setLoad(true)
    let token = cookies.get('token')
    await axios
      .post(`${process.env.REACT_APP_API_URL}/excel-resume-stockflow`, params, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        responseType: 'blob'
      })
      .then(res => {
        if (typeof (res.data === 'blob')) {
          setLoad(false)
          const fileName = `Rekapitulasi Stockflow ${month} ${year}.xlsx`
          blobToDataURL(res.data, fileName)
          validationMessage('success', `Berhasil export rekapitulasi stockflow ke file Excel`)
        }
      }).catch((error) => {
        console.log('err', error)
        validationMessage('error', `Gagal export rekapitulasi stockflow ke file Excel`)
        setLoad(false)
      })
  }

  const handleExport = () => {
    exportData()
  }

  return (
    <Layout>
      <Content className="dashboard-container">
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item><span>{t('pages.stockflow.resume')} {month} {year}</span></Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col lg={{ span: 4, offset: 4 }} xs={24}>
            <Button style={{
              float: 'right', backgroundColor: '#00a266', color: 'white',
              borderRadius: 5, boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
            }}
              loading={load} onClick={() => handleExport()}>
              <FileExcelOutlined />Export Excel</Button>
          </Col>
          <Col span={4} lg={4} md={4} xs={24}>
            <DatePicker
              allowClear={false}
              picker='month'
              format={'MMMM'}
              onChange={handleTanggalRange}
              disabledDate={disabledDate}
            />
          </Col>
          <Col lg={24} md={24} sm={24}>
            <Loading loading={loading}>
              <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                className="table-stock-flow"
                scroll={{ x: 1000, y: 500 }}
                summary={pageData => {
                  let totalStokAwal = 0
                  let nilaiAwal = 0
                  let totalIn = 0
                  let totalNilaiIn = 0
                  let totalQtyOut = 0
                  let totalNilaiOut = 0
                  let totalPenyesuaian = 0
                  let totalNilaiPenyesuaian = 0
                  let totalSaldoAkhir = 0
                  let totalNilaiSaldoAkhir = 0
                  pageData.forEach(({
                    bgnQty, bgnTotal, inTotal, totalPrice, outTotal, totalOut,
                    qtyAdj, totalAdj, sisaStok, nilaiSisaStok
                  }) => {
                    totalStokAwal += parseFloat(bgnQty)
                    nilaiAwal += parseFloat(bgnTotal)

                    totalIn += parseFloat(inTotal)
                    totalNilaiIn += parseFloat(totalPrice)

                    totalQtyOut += parseFloat(outTotal)
                    totalNilaiOut += parseFloat(totalOut)

                    totalPenyesuaian += parseFloat(qtyAdj)
                    totalNilaiPenyesuaian += parseFloat(totalAdj)

                    totalSaldoAkhir += parseFloat(sisaStok)
                    totalNilaiSaldoAkhir += parseFloat(nilaiSisaStok)
                  })
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text>{totalStokAwal < 0 ? <NumberFormat value={Math.abs(totalStokAwal)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                            <NumberFormat value={totalStokAwal} displayType={'text'} thousandSeparator={true} />}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={(nilaiAwal / totalStokAwal) < 0 ? Math.abs((nilaiAwal / totalStokAwal).toFixed(0)) : parseFloat((nilaiAwal / totalStokAwal).toFixed(0))}
                            displayType={'text'} thousandSeparator={true} prefix={(nilaiAwal / totalStokAwal) < 0 ? '(' : ''} suffix={(nilaiAwal / totalStokAwal) < 0 ? ')' : ''} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={nilaiAwal < 0 ? Math.abs((nilaiAwal).toFixed(0)) : nilaiAwal.toFixed(0)} displayType={'text'} thousandSeparator={true}
                            prefix={(nilaiAwal) < 0 ? '(' : ''} suffix={(nilaiAwal) < 0 ? ')' : ''} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalIn} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={(totalNilaiIn / totalIn) < 0 ? Math.abs((totalNilaiIn / totalIn).toFixed(0)) : (totalNilaiIn / totalIn).toFixed(0)}
                            displayType={'text'} thousandSeparator={true} prefix={(totalNilaiIn / totalIn) < 0 ? '(' : ''} suffix={(totalNilaiIn / totalIn) < 0 ? ')' : ''} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right" >
                          <Text><NumberFormat value={totalNilaiIn < 0 ? Math.abs(totalNilaiIn.toFixed(0)) : totalNilaiIn.toFixed(0)} displayType={'text'} thousandSeparator={true}
                            prefix={(totalNilaiIn) < 0 ? '(' : ''} suffix={(totalNilaiIn) < 0 ? ')' : ''} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalQtyOut} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={(totalNilaiOut / totalQtyOut) < 0 ? Math.abs((totalNilaiOut / totalQtyOut).toFixed(0)) : parseFloat((totalNilaiOut / totalQtyOut).toFixed(0))}
                            displayType={'text'} thousandSeparator={true} prefix={(totalNilaiOut / totalQtyOut) < 0 ? '(' : ''} suffix={(totalNilaiOut / totalQtyOut) < 0 ? ')' : ''} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalNilaiOut < 0 ? Math.abs(totalNilaiOut.toFixed(0)) : totalNilaiOut.toFixed(0)} displayType={'text'} thousandSeparator={true}
                            prefix={(totalNilaiOut) < 0 ? '(' : ''} suffix={(totalNilaiOut) < 0 ? ')' : ''} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text>{totalPenyesuaian < 0 ? <NumberFormat value={Math.abs(totalPenyesuaian)} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                            <NumberFormat value={totalPenyesuaian} displayType={'text'} thousandSeparator={true} />}</Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={isNaN(totalNilaiPenyesuaian / totalPenyesuaian) ? 0 : parseFloat((totalNilaiPenyesuaian / totalPenyesuaian).toFixed(0))} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text>{totalNilaiPenyesuaian < 0 ? <NumberFormat value={Math.abs(totalNilaiPenyesuaian.toFixed(0))} displayType={'text'} thousandSeparator={true} prefix={'('} suffix={')'} /> :
                            <NumberFormat value={totalNilaiPenyesuaian.toFixed(0)} displayType={'text'} thousandSeparator={true} />}</Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalSaldoAkhir < 0 ? Math.abs(totalSaldoAkhir) : totalSaldoAkhir} prefix={totalSaldoAkhir < 0 ? '(' : ''} suffix={totalSaldoAkhir < 0 ? ')' : ''}
                            displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={(totalNilaiSaldoAkhir / totalSaldoAkhir) < 0 ? Math.abs((totalNilaiSaldoAkhir / totalSaldoAkhir).toFixed(0)) : parseFloat((totalNilaiSaldoAkhir / totalSaldoAkhir).toFixed(0))}
                            displayType={'text'} thousandSeparator={true} prefix={(totalNilaiSaldoAkhir / totalSaldoAkhir) < 0 ? '(' : ''} suffix={(totalNilaiSaldoAkhir / totalSaldoAkhir) < 0 ? ')' : ''} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalNilaiSaldoAkhir < 0 ? Math.abs(totalNilaiSaldoAkhir.toFixed(0)) : totalNilaiSaldoAkhir.toFixed(0)}
                            displayType={'text'} thousandSeparator={true} prefix={(totalNilaiSaldoAkhir) < 0 ? '(' : ''} suffix={(totalNilaiSaldoAkhir) < 0 ? ')' : ''} /></Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}
              />
            </Loading>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default ResumeComponent