export const tour = {
    welcome: 'Selamat datang di BroilerX',
    step1: 'Ini merupakan tampilan pertama dari aplikasi BroilerX.',
    step2: 'Di sebelah atas dapat dilihat menu untuk memilih kandang serta periode.',
    step3: 'Di sebelah kiri dapat dilihat beberapa menu yang tersedia di BroilerX.',
    step4: 'Untuk memulai menggunakan, masuk pada menu Master. Menu master berisi semua data pokok seperti gudang, kandang, pakan, OVK, dan lainnya.',
    step5: 'Selanjutnya ada menu Stok. Menu ini berisi manajemen stok pakan, OVK, serta material dan kegiatan pengadaan.',
    step6: 'Selanjutnya ada menu Monitor. Di sini alat monitor kandang bisa memantau keadaan kandang terkini.',
    step7: 'Selanjutnya ada menu Periode. Di sini peternak bisa membuat periode pemeliharaan/budidaya.',
    step8: 'Kemudian ada menu Recording. Di sini peternak bisa membuat pencatatan pemeliharaan per periode setiap harinya.',
    step9: 'Yang terakhir ada menu Dashboard. Di sini peternak bisa melihat rekapan data yang ada mulai dari total ayam yang hidup, ayam mati, performa setiap kandang, dan sebagainya.',
    step10: 'Sudah siap menggunakan BroilerX? Mulai budidaya!',
    cta: 'Buat Periode'
}