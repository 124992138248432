export const getProfile = () => {
    return (
        `query{
            getProfile{
                _id
                name
                email
                username
                foto
                address
                phone
                type
                cities
                receivedNotification
                accessshouse{
                    _id
                    name
                }
                isVoucher
                voucherDuration
                voucherHouse
                voucherExpiry
                company{
                    _id
                    name
                    type
                    typeUser
                    subsType
                    totalPayment
                    lastPayment
                    lastInvoice
                    expiryDate
                    house_limit
                    createdAt
                }
                permission{
                    readUsers
                    writeUsers
                    readMaster
                    writeMaster
                    readStock
                    writeStock
                    readStockflow
                    writeStockflow
                    readRearing
                    writeRearing
                    readRecording
                    writeRecording
                    nominalRecording
                    editRecording
                    readDashboard
                    staffMode
                    readIot
                    writeIot
                    createSubscription
                }
            }
        }`
    )
}

export const updateProfile = (value) => {
    return {
        query: `
            mutation {
                updateUser(updateUserInput: {
                    name: "${value.namaLengkap}"
                    username: "${value.username}"
                    email: "${value.email}"
                    phone: "${value.phone}"
                    address: "${value.alamat}"
                    idAddr: "${value.idAddr}"
                    company: "${value.perusahaan}"
                }){
                _id
                name
                username
                email
                phone
                address
                type
                foto
                receivedNotification
                company{
                    name
                }
                }
            }
        `
    }
}

export const updateNotifReceive = (value) => {
    return {
        query: `
            mutation {
                updateNotifReceive(updateUserInput: {
                    receivedNotification: ${value}
                }){
                    _id
                    name
                    email
                    username
                    foto
                    address
                    phone
                    type
                    receivedNotification
                    accessshouse{
                        _id
                        name
                    }
                    isVoucher
                    company{
                        _id
                        name
                        type
                        typeUser
                        subsType
                        totalPayment
                        lastPayment
                        lastInvoice
                        expiryDate
                        house_limit
                        createdAt
                    }
                }
            }
        `
    }
}

export const changePassword = (value) => {
    return {
        query: `
            mutation {
                changePassword(updatePasswordInput:{
                oldPassword: "${value.oldPass}"
                newPassword: "${value.newPass}"
            })
                {
                    name
                    password
                }
            }
        `
    }
}

export const deleteAccount = (_id, feedback) => {
    return (
        `mutation{
          deleteUser(_id : "${_id}", feedbackInput: "${feedback}"){
            deleted
          }
        }
      `
    )
}