export const rearing = {
    title: "Maintenance Period",
    rearingActive: "Current period",
    date: "Chick In Date",
    name: "Period",
    house: "House",
    doc: "DOC",
    ppl: "PPL",
    popHpp: "HPP Population",
    population: "Population",
    weight: "DOC Weight",
    price: "DOC Price (Rp/chicken)",
    age: "Age",
    depletion: "Depletion",
    chicken: "chickens",
    add: "Add Period",
    delConfirm: "Are you sure want to delete",
    resAddSuccess: "Successfully Adding Period",
    resDelSuccess: "Successfully Deleting Period",
    resEditSuccess: "Successfully Changed Period",
    resCloseSuccess: "Successfully Closing Period",
    resAddFailed: "Failed to Add Period",
    resDelFailed: "Failed to Delete Period",
    resEditFailed: "Failed to Change Period",
    resCloseFailed: "Failed to Close Period",
    rearingClose: "Closed period",
    edit: "Edit Period",
    info: "Description",
    phName: "June Period...",
    phDate: "Select a period date",
    phHouse: "Select house",
    phDoc: "Select DOC",
    phInfo: "Input description",
    msgName: "Please input period name!",
    msgDate: "Please select a period date!",
    msgWeight: "Please input weight of DOC chicken!",
    msgPopulationHpp: "Please input initial population for HPP!",
    msgPopulation: "Please input initial population for Performance!",
    msgHouse: "Please select cage!",
    msgDoc: "Please select DOC!",
    msgPpl: "Please input cage ppl!",
    msgPrice: "Please input DOC chicken price!",
    close: "Close Maintenance Period",
    closeDate: "Closing Date",
    phCloseDate: "Select closing date",
    msgCloseDate: "Please select closing date!",
    detail: "Detail Current Period ",
    chart: "House Performance Chart",
    performaTable: "House Performance Table",
    week: "Week",
    openConfirm: "Are you sure you want to open the period?",
    resOpenSuccess: "Successfully opened period",
    resOpenFailed: "Failed to open period"
}