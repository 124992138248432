export const monitor = {
    title: "Devices",
    serial: "Serial Number",
    house: "House",
    tool: "Device",
    position: "Position",
    add: "Add Device",
    battery: "Battery",
    type: "Device Type",
    phHouse: "Select house",
    phType: "Select device type",
    phPosition: 'Center..',
    ews: 'Early Warning System',
    envName: 'Environtment Standard Name',
    std: 'Environtment Standard',
    calibrate: 'Calibrate',
    day: 'Day',
    maxTemp: 'Max Temperature',
    minTemp: 'Min Temperature',
    maxHumidity: 'Max Humidity',
    minHumidity: 'Min Humidity',
    maxCo2: 'Max CO₂',
    msgDay: 'Please input day!',
    msgEnvName: 'Please input environtment standard name!',
    msgSerial: "Please input serial number!",
    msgHouse: "Please select house!",
    msgType: "Please select device type!",
    msgPosition: "Please input position!",
    delConfirm: "Are you sure want to delete device?",
    delStdConfirm: "Are you sure want to delete environmtment standard",
    delStdDayConfirm: "Are you sure want to delete environmtment standard day",
    edit: "Edit Device",
    addStandardEnv: 'Add Environtment Standard',
    editStandardEnv: 'Edit Environtment Standard',
    addTare: 'Add Tare Device',
    latest: 'Latest',
    manual: 'Manual',
    tareTime: 'Calibration time',
    current: 'Current',
    tare: 'Tare',
    temp: 'Temperature',
    humidity: 'Humidity',
    windSpeed: 'Wind Speed',
    weight: 'Weight',
    desc: 'Description',
    phDesc: 'Input description..',
    resAddSuccess: "Successfully added device",
    resEditSuccess: "Successfully changed device",
    resDelSuccess: "Successfully deleted device",
    resAddStdSuccess: "Successfully added environtment standard",
    resEditStdSuccess: "Successfully changed environtment standard",
    resDelStdSuccess: "Successfully deleted environtment standard",
    resAddTareSuccess: "Successfully added tare and calibrate device",
    resAddFailed: "Failed to add device",
    resEditFailed: "Failed to change device",
    resDelFailed: "Failed to delete device",
    resAddStdFailed: "Failed to add environtment standard",
    resEditStdFailed: "Failed to change environtment standard",
    resDelStdFailed: "Failed to delete environtment standard",
    resAddTareFailed: "Failed to add tare and device calibration",
}