import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../common/api'
import { errMessage } from '../../../common/component/notification/notification'
import { navigate } from '../../../common/store/action'
import { Modal, Form, Row, Col, Button, DatePicker, InputNumber } from 'antd'
import NumberFormat from 'react-number-format'
import Loading from '../../../common/component/loading/loading-container'
import { getIdInvoice } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'
import CONSTANTS from '../../../common/utils/Constants'
import { upgradeHouse } from './query-subscription'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

function UpgradeHouse(props) {
    const { company, getIdInvoice, expiry, role, t } = props
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [item, setItem] = useState(1)
    const [range, setDate] = useState([moment(), expiry])
    const [total, setTotal] = useState(0)

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    useEffect(() => {
        const dif = expiry.diff(range[0], 'days') + 1
        const a = dif / 30
        const duration = a > parseInt(a) ? parseInt(a) + 1 : parseInt(a)

        let price = 0
        if (company.subsType === 'monthly') {
            price = 300000
        } else {
            price = company.subsType === 'annual' ? 200000 : 150000
        }

        const total = duration * price * 1

        setTotal(total)
    }, [range, expiry, company])


    const setItemNumber = (e) => {
        setItem(e)

        const dif = expiry.diff(range[0], 'days') + 1
        const a = dif / 30
        const duration = a > parseInt(a) ? parseInt(a) + 1 : parseInt(a)

        let price = 0
        if (company.subsType === 'monthly') {
            price = 300000
        } else {
            price = company.subsType === 'annual' ? 200000 : 150000
        }

        const total = duration * price * e
        setTotal(total)
    }

    const setRange = e => {
        setDate(e)

        const dif = expiry.diff(e[0], 'days') + 1
        const a = dif / 30
        const duration = a > parseInt(a) ? parseInt(a) + 1 : parseInt(a)

        let price = 0
        if (company.subsType === 'monthly') {
            price = 300000
        } else {
            price = company.subsType === 'annual' ? 200000 : 150000
        }

        const total = duration * price * item
        setTotal(total)
    }

    const postData = async (values, date) => {
        setLoading(true)
        await API.post(upgradeHouse(values, date))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t("pages.subscription.resUpgradeFailed")}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.upgradeHouse !== null) {
                    getIdInvoice(res.data.data.upgradeHouse._id)
                    props.navigate(CONSTANTS.INVOICE_KEY)
                    errMessage('success', `${t("pages.subscription.resUpgradeSuccess")}`)
                    setLoading(false)
                    setVisible(false)
                }
            }).catch((error) => {
                errMessage('error', `${t("pages.subscription.resUpgradeFailed")}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, values['date'][0].format('MM/DD/YYYY'))
    }

    return (
        <>
            <Button className='mr-10' hidden={company.house_limit >= 5 || role !== 'owner' ? true : false} style={{
                color: 'white', backgroundColor: '#008ad4',
            }}
                onClick={() => showModal()}
            ><FontAwesomeIcon icon={faPlus} className='mr-10' />{t("pages.subscription.addHouse")}</Button>
            <Modal
                visible={visible}
                title={t("pages.subscription.addHouseTitle")} centered
                okText={t('general.next')}
                width={500}
                cancelText={t('general.cancel')}
                onCancel={() => setVisible(false)}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        itemNumber: 1,
                        date: [moment(), expiry]
                    }}
                >
                    <Loading loading={loading}>
                        <Row gutter={[16, 8]}>
                            <Col span={24}>
                                <span className="font-semi-bold black font-title-medium">{t("pages.subscription.houseTotal")}</span>
                                <Form.Item
                                    name="itemNumber" rules={[{ required: true, message: `${t('pages.subscription.msgItem')}` }]}
                                >
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        placeholder="3..."
                                        className="input-suffix"
                                        addonAfter={t('pages.subscription.house')}
                                        min={1} max={5 - company.house_limit}
                                        onChange={(value) => setItemNumber(value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <span className="font-semi-bold black font-title-medium">{t('pages.subscription.startDate')}</span>
                                <Form.Item
                                    name="date"
                                    rules={[
                                        {
                                            validator: (_, value) =>
                                                value[0] !== null ? Promise.resolve() : Promise.reject(new Error(`${t('pages.subscription.msgStartDate')}`)),
                                        },
                                    ]}

                                >
                                    <DatePicker.RangePicker style={{ width: '100%' }}
                                        onChange={e => setRange(e)}
                                        allowClear={false}
                                        disabled={[false, true]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="black font-semi-bold font-title-content">Total: <NumberFormat
                                    value={total} prefix='Rp' displayType={'text'} thousandSeparator={true} /></span>
                            </Col>
                        </Row>
                    </Loading>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate, getIdInvoice
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(UpgradeHouse)
export default page