import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input, Table, Tooltip, Modal, Typography, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faIdCard } from '@fortawesome/free-solid-svg-icons'
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import Loading from '../../../../common/component/loading/loading-container'
import moment from 'moment'
import { validateMembership } from '../../subscription-page/query-subscription'
import { errMessage } from '../../../../common/component/notification/notification'
import { API } from '../../../../common/api'

function TablePendingInvoices(props) {
    const { data, setLoading, loading, fetchInvoices, t } = props
    const [searchText, setSearchText] = useState('')
    const { confirm } = Modal
    const [seacrhedColumn, setSeacrhedColumn] = useState('')

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const columns = [
        {
            title: `Created at`,
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: `Expired at`,
            dataIndex: 'expiry_date',
            key: 'expiry_date',
        },
        {
            title: `Company`,
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: `Invoice ID`,
            dataIndex: 'externalId',
            key: 'externalId',
            align: 'center',
            render: (value, row, index) => (
                <>
                    <Typography.Link href={row.link} target="_blank">{value}</Typography.Link>
                    {row.pic && <FontAwesomeIcon className='ml-10'
                        onClick={() => window.open(row.pic)}
                        icon={faIdCard} color='SteelBlue' style={{ cursor: 'pointer' }} />}
                </>
            )
        },
        {
            title: `Status`,
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            sorter: (a, b) => a.status.length - b.status.length,
            render: (value, row, index) => (
                <Tag color='orange'>{value.toUpperCase()}
                </Tag>
            )
        },
        {
            title: `Type`,
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            sorter: (a, b) => a.type.length - b.type.length,
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 70,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button hidden={row.status === 'validating' ? false : true}
                            style={{
                                color: 'white',
                                backgroundColor: 'teal',
                                borderRadius: '5px'
                            }} onClick={() => validateConfirm(row.key, row.externalId)}><FontAwesomeIcon icon={faCheck} /></Button>
                    </Tooltip>
                </Space>
            ),
        }
    ]

    const validate = async (_id) => {
        setLoading(true)
        await API.post(validateMembership(_id))
            .then(res => {
                if (res.data.data.validateMembership._id) {
                    errMessage('success', `${t('pages.rearing.resDelSuccess')}`)
                    fetchInvoices()
                    setLoading(false)
                } else {
                    errMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                errMessage('error', `${t('pages.rearing.resDelFailed')}`)
                setLoading(false)
            })
    }

    const datas = data.map(({ _id, type, externalId, status, company, link, expiry_date, createdAt }, index) => ({
        key: _id,
        id: _id,
        type: type,
        externalId: externalId,
        status: status,
        name: company.name,
        pic: company?.picMembership,
        link: link,
        expiry_date: moment(expiry_date).format('lll'),
        createdAt: moment(createdAt).format('lll'),
    }))

    const validateConfirm = (_id, nama) => {
        confirm({
            title: `Are you sure want to validate membership with invoice ${nama}?`,
            centered: true,
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                validate(_id)
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    return (
        <Loading loading={loading}>
            <Table
                columns={columns} bordered
                dataSource={datas}
                pagination={false}
                className="table-dashboard"
                scroll={{ y: '50vh' }}
            />
        </Loading>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TablePendingInvoices)
export default page