export const getOvkList = (keyword, limit, skip) => {
    return (
        `query{
            ovks(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              ovks {
                  _id
                  name
                  qtyUnit
                  variety
                  ovkUnit {
                    _id
                    name
               }
                ovkUnitRecording {
                    _id
                    name
                }
              }
            }
          }
      `
    )
}

export const getOvk = (keyword, limit, skip) => {
    return (
        `query{
            ovks(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              ovks {
                  _id
                  name
                  dosis
                  ingredient
                  expired
                  producer
                  variety
                  recommendedUse
                  qtyUnit
                  ovkType
                  ovkShape
                  ovkMedia
                  ovkUnit {
                       _id
                       name
                  }
                  ovkUnitRecording {
                    _id
                    name
               }
              }
            }
          }
      `
    )
}

export const createOvk = (value, expired, unit) => {
    return {
        query:
            `mutation{
                createOvk(ovkInput: {
                name : "${value.namaOvk}"
                dosis : "${value.dosisOvk + ' ' + value.dosisUnit}"
                producer : "${value.distributor}"
                recommendedUse :"${value.penggunaan}"
                ingredient : "${value.kandunganOvk}"
                expired : "${expired}"
                ovkType : "${value.ovkType.value}"
                ovkShape: "${value.ovkShape.value}"
                ovkMedia : "${value.ovkMedia}"
                ovkUnit : "${value.ovkUnit.value}"
                ovkUnitRecording : "${unit ? value.unitRecording.value : value.unitRecording}"
                qtyUnit : ${parseInt(value.qtyUnit)}
                }){
                _id
                }
            }
        `
    }
}

export const detailOvk = (_id) => {
    return (
        `query{
            ovk(_id : "${_id}"){
                _id
                name
                dosis
                ingredient
                qtyUnit
                expired
                producer
                variety
                recommendedUse
                ovkType
                ovkShape
                ovkMedia
                ovkUnit{
                    value : _id
                    name
                }
                ovkUnitRecording{
                    value : _id
                    name
                }
              }
            }
    `
    )
}

export const udpateOvk = (_id, value, year) => {
    return {
        query: `mutation{
                    updateOvk(_id : "${_id}", updateOvkInput : {
                        name : "${value.namaOvk}"
                        dosis : "${value.dosisOvk + ' ' + value.dosisUnit}"
                        producer : "${value.distributor}"
                        recommendedUse :"${value.penggunaan}"
                        ingredient : "${value.kandunganOvk}"
                        expired : "${year}"
                        ovkType : "${value.ovkType}"
                        ovkShape: "${value.ovkShape}"
                        ovkMedia : "${value.ovkMedia}"
                        ovkUnit : "${value.ovkUnit.value}"
                        ovkUnitRecording : "${value.ovkUnitRecording.value}"
                        qtyUnit : ${parseInt(value.qtyUnit)}
                    }){
                        _id
                    }
                }
            `
    }
}

export const deleteOvk = (_id) => {
    return (
        `mutation{
            deleteOvk(_id : "${_id}"){
                deleted
              }
            }
        `
    )
}

export const getOvkUnit = (keyword, limit, skip) => {
    return (
        `query{
            ovkUnits(keyword:"${keyword}",limit:0,skip:0){
                ovkUnits{
                    _id
                    name
                }
            }
        }
    `)
}
export const getOvkUnitRecording = () => {
    return (
        `query{
            ovkUnitRecordings(keyword:"",limit:0,skip:0){
                ovkUnitRecordings{
                    _id
                    name
                }
            }
        }
    `)
}
