import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { navigate } from '../../../../../common/store/action'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { Modal, Form, Row, Col, Input } from 'antd'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { createCustomer } from './query-customer'


function CreateCustomer(props) {
    const { fetchData, setLoading, t } = props
    const [visible, setVisible] = useState(false)
    const { TextArea } = Input
    const [form] = Form.useForm()

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values) => {
        API.post(createCustomer(values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.master.customer.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createCustomer._id) {
                    validationMessage('success', `${t('pages.master.customer.resAddSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.master.customer.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <>
            <ButtonDashboard
                text={t('pages.master.customer.add')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <Modal
                visible={visible}
                title={t('pages.master.customer.add')}
                okText={t('general.add')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={() => {
                    setVisible(false)
                }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{ otherInformation: '' }}
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.customer.name')}</span>
                            <Form.Item
                                name="name" rules={[{ required: true, message: `${t('pages.master.customer.msgName')}` }]}
                            >
                                <Input
                                    name='name'
                                    placeholder={t('pages.master.customer.phName')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.customer.address')}</span>
                            <Form.Item
                                name="address" rules={[{ required: true, message: `${t('pages.master.customer.msgAddress')}` }]}
                            >
                                <Input
                                    name='address'
                                    placeholder={t('pages.master.customer.phAddress')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.customer.contact')}</span>
                            <Form.Item
                                name="contact" rules={[{ required: true, message: `${t('pages.master.customer.msgContact')}` }]}
                            >
                                <Input
                                    name='contact'
                                    placeholder="0856754..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.customer.info')}</span>
                            <Form.Item
                                name="otherInformation"
                            >
                                <TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={t('pages.master.customer.phInfo')}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateCustomer)
export default page