export const stockflow = {
    feed: 'Feed',
    ovk: 'OVK',
    livebird: 'Livebird',
    period: 'Period',
    resume: 'Resume',
    qty: 'Qty',
    qtyLivebird: 'Qty (chickens)',
    hs: 'HS (Rp)',
    hsLivebird: 'HS (Rp/chicken)',
    total: 'Total (Rp)',
    saw: 'Initial Balance',
    in: 'In',
    out: 'Out',
    adj: 'Adjustment',
    sak: 'Ending Balance',
    feedInitialBalance: 'Feed Initial Balance',
    ovkInitialBalance: 'OVK Initial Balance',
    livebirdInitialBalance: 'Livebird Initial Balance',
    adjFeed: 'Feed Adjustment',
    adjOvk: 'OVK Adjustment',
    addFeed: 'Add Period',
    addOvk: 'Add OVK Period',
    addLivebird: 'Add Livebird Period',
    editSawFeed: 'Edit Feed Initial Balance',
    editSawOvk: 'Edit OVK Initial Balance',
    editSawLivebird: 'Edit Livebird Initial Balance',
    addFeedAdj: 'Add Feed Adjustment',
    addOvkAdj: 'Add OVK Adjustment',
    editFeedAdj: 'Edit Feed Adjustment',
    editOvkAdj: 'Edit OVK Adjustment',
    dateFeedPeriod: 'Month Period',
    dateOvkPeriod: 'Month Period of OVK',
    dateLivebirdPeriod: 'Month Period of Livebird',
    phDatePeriod: 'Select period month',
    phFeed: 'Select feed',
    phOvk: 'Select OVK',
    phPeriod: 'Select Period',
    msgQty: "Please input quantity!",
    msgPrice: "Please input price!",
    house: 'House',
    quantity: 'Quantity',
    price: 'Price (Rp)',
    difference: 'Difference',
    all: 'All',
    status: 'Status',
    locked: 'Locked',
    unlocked: 'Unlocked',
    depletion: 'Depletion',
    culling: 'Culling',
    harvest: 'Harvest',
    addFeedConfirm: 'Are you sure want to create next period?',
    addOvkConfirm: 'Are you sure want to create next OVK period?',
    addLivebirdConfirm: 'Are you sure want to create next live bird period?',
    lockFeedConfirm: 'Are you sure want to close period in',
    lockOvkConfirm: 'Are you sure want to close OVK period in',
    lockLivebirdConfirm: 'Are you sure want to close live bird period in',
    unlockFeedConfirm: 'Are you sure want to unlock period in',
    unlockOvkConfirm: 'Are you sure want to open OVK period in',
    unlockLivebirdConfirm: 'Are you sure want to open live bird period in',
    delFeedConfirm: 'Are you sure want to delete period in',
    delOvkConfirm: 'Are you sure want to delete OVK period in',
    delAdjFeedConfirm: "Are you sure want to delete this feed adjustment?",
    delAdjOvkConfirm: "Are you sure want to delete this OVK adjustment?",
    delLivebirdConfirm: 'Are you sure want to delete live bird period in',
    resAddFeedSuccess: "Successfully added period",
    resLockFeedSuccess: "Successfully closed period",
    resRelockFeedSuccess: "Successfully closed back period",
    resUnlockFeedSuccess: "Successfully opened period",
    resEditFeedSuccess: "Successfully updated feed initial balance",
    resDelFeedSuccess: "uccessfully deleted feed period",
    resAddAdjFeedSuccess: "Successfully added feed adjustment",
    resEditAdjFeedSuccess: "Successfully changed feed adjustment",
    resDelAdjFeedSuccess: "Successfully deleted feed adjustment",
    resAddFeedFailed: "Failed to add period",
    resLockFeedFailed: "Failed to close period",
    resUnlockFeedFailed: "Failed to open period",
    resEditFeedFailed: "Failed to update feed initial balance",
    resDelFeedFailed: "Failed to delete feed period",
    resAddAdjFeedFailed: "Failed to add feed adjustment",
    resEditAdjFeedFailed: "Failed to change feed adjustment",
    resDelAdjFeedFailed: "Failed to delete feed adjustment",
    resAddOvkSuccess: "Successfully added OVK period",
    resLockOvkSuccess: "Successfully closed OVK period",
    resRelockOvkSuccess: "Successfully closed back OVK period",
    resUnlockOvkSuccess: "Successfully opened OVK period",
    resEditOvkSuccess: "Successfully updated OVK initial balance",
    resDelOvkSuccess: "Successfully deleted OVK period",
    resAddAdjOvkSuccess: "Successfully added OVK adjustment",
    resEditAdjOvkSuccess: "Successfully changed OVK adjustment",
    resDelAdjOvkSuccess: "Successfully deleted OVK adjustment",
    resAddOvkFailed: "Failed to add OVK period",
    resLockOvkFailed: "Failed to close OVK period",
    resUnlockOvkFailed: "Failed to open OVK period",
    resEditOvkFailed: "Failed to update OVK initial balance",
    resDelOvkFailed: "Failed to delete OVK period",
    resAddAdjOvkFailed: "Failed to add OVK adjustment",
    resEditAdjOvkFailed: "Failed to change OVK adjustment",
    resDelAdjOvkFailed: "Failed to delete OVK adjustment",
    resAddLivebirdSuccess: "Successfully added live bird period",
    resLockLivebirdSuccess: "Successfully closed live bird period",
    resRelockLivebirdSuccess: "Successfully closed back live bird period",
    resUnlockLivebirdSuccess: "uccessfully opened live bird period",
    resEditLivebirdSuccess: "Successfully updated live bird initial balance",
    resDelLivebirdSuccess: "Successfully deleted live bird period",
    resAddLivebirdFailed: "Failed to add live bird period",
    resLockLivebirdFailed: "Failed to close live bird period",
    resUnlockLivebirdFailed: "Failed to open live bird period",
    resEditLivebirdFailed: "Failed to update live bird initial balance",
    resDelLivebirdFailed: "Failed to delete live bird period"
}