import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Breadcrumb, Button, Card, Col, Layout, Row } from 'antd'
import { useTranslation } from "react-i18next"
import { getInvoices } from './query-subscription'
import { getProfile } from '../profil-page/query-profil'
import TableInvoice from './table-invoice'
import UpgradeHouse from './upgrade-house'
import ChangePlan from './change-plan'
import NumberFormat from 'react-number-format'
import moment from 'moment'

import { API } from '../../../common/api'
import { navigate } from '../../../common/store/action'
import Loading from '../../../common/component/loading/loading-container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { getIdInvoice } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'
import CONSTANTS from '../../../common/utils/Constants'

function SubscriptionPage(props) {
    const { getIdInvoice, navigate } = props
    const { t } = useTranslation()
    const { Content } = Layout
    const [invoices, setInvoices] = useState([])
    const [company, setCompany] = useState({})
    const [role, setRole] = useState(null)
    const [loading, setLoading] = useState(false)

    const fetchData = () => {
        setLoading(true)

        API.get(getInvoices())
            .then(res => {
                setInvoices(res.data.data.invoices.invoices)
                setLoading(false)
            }).catch((error) => {
                setInvoices([])
                setLoading(false)
            })

        API.get(getProfile())
            .then(res => {
                setCompany(res.data.data.getProfile.company)
                setRole(res.data.data.getProfile.type)
                setLoading(false)
            }).catch((error) => {
                setCompany({})
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <Layout>
            <Content className="dashboard-container" style={{ minHeight: '100vh' }}>
                <Breadcrumb className='mb-10'>
                    <Breadcrumb.Item><span>{t('pages.subscription.title')}</span></Breadcrumb.Item>
                </Breadcrumb>
                <Row gutter={[16, 16]}>
                    <Col lg={8} xs={24}>
                        <Card bordered={false}
                        >
                            <p className='font-semi-bold font-title-medium'>Plan</p>
                            <p>{company.typeUser?.charAt(0).toUpperCase() + company.typeUser?.slice(1)} Account {company.subsType ? '• ' + company.subsType?.charAt(0).toUpperCase() + company.subsType?.slice(1) + ' Plan' : ''}</p>
                            {company.typeUser !== 'free' &&
                                <>
                                    <p>{t('pages.subscription.subsDesc', {house: company.house_limit, date: moment(company?.expiryDate, 'DD/MM/YYYY hh.mm.ss').format('LL') })}</p>
                                    <UpgradeHouse company={company} role={role} t={t} expiry={moment(company?.expiryDate, 'DD/MM/YYYY hh.mm.ss')} />
                                    <ChangePlan t={t} role={role}
                                        type={company.subsType === 'monthly' ? 'monthly' : 'annual'} company={company} />
                                </>}
                        </Card>
                    </Col>
                    <Col lg={16} sm={24}>
                        <Card bordered={false}>
                            <p className='font-semi-bold font-title-medium'>{t('pages.subscription.payment')}</p>
                            {company.totalPayment ?
                                <>
                                    <p>{t("pages.subscription.lastPayDesc", {
                                        total: (company.totalPayment).toLocaleString('id-ID', {
                                            style: 'currency',
                                            currency: 'IDR',
                                        }),
                                        date: moment(company.lastPayment, 'DD/MM/YYYY hh.mm.ss').format('LL')
                                    })}</p>
                                    <p>{t("pages.subscription.nextPayDesc", { date: moment(company?.expiryDate, 'DD/MM/YYYY hh.mm.ss').format('LL') })}</p>
                                    <Button ghost type="primary" hidden={company.lastInvoice ? false : true}
                                        onClick={() => {
                                            getIdInvoice(company.lastInvoice)
                                            navigate(CONSTANTS.INVOICE_KEY)
                                        }}
                                    ><FontAwesomeIcon icon={faFileInvoice} className='mr-10' />{t('pages.subscription.seeInv')}</Button>
                                </> :
                                <p>{t('pages.subscription.noPayment')}</p>}
                        </Card>
                    </Col>
                </Row>
                <Card title={t('pages.subscription.table')} bordered={false} className='mt-20'>
                    <TableInvoice t={t} data={invoices} Loading={Loading} loading={loading} NumberFormat={NumberFormat} moment={moment} />
                </Card>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate, getIdInvoice
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage)
export default page