import 'react-app-polyfill/ie9' // For IE 9-11 support
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './assets/scss/index.scss'
import App from './app/App'
import * as serviceWorker from './serviceWorker'
import Loading from './common/component/loading/loading-container'

ReactDOM.render(
    <React.StrictMode>
        <Suspense fallback={<Loading loading={true} style={{ height: "100vh" }} />}>
            <App />
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root'))

serviceWorker.unregister()
serviceWorker.register()