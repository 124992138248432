import * as actionType from './rearing-record-action-type';

const initialState = {
    monthPeriode: null,
    yearPeriode: null,
    startPeriode: null,
    endPeriode: null,
    idRearingRecord: null,
    dateRearingRecord: null,
    dataGrowingParams: [],
    loading: false,
    idDiseaseRecord: null,
    detailDiseaseRecord: null,
    age: 0,
    liveBirdPrice: 0,
}

const handler = (currentState) => {
    const setDataRearingRecord = {
        startGetDataRearingRecords: () => ({
            ...currentState,
            loading: true,
        }),
        finishGetDataRearingRecords: () => ({
            ...currentState,
            loading: false,
        }),
        setMonthPeriode: data => ({
            ...currentState,
            monthPeriode: data
        }),
        setYearPeriode: data => ({
            ...currentState,
            yearPeriode: data
        }),
        setStartPeriode: data => ({
            ...currentState,
            startPeriode: data
        }),
        setEndPeriode: data => ({
            ...currentState,
            endPeriode: data
        }),
        setIdRearingRecord: data => ({
            ...currentState,
            idRearingRecord: data
        }),
        setDateRearingRecord: data => ({
            ...currentState,
            dateRearingRecord: data
        }),
        setAgeRearingRecord: data => ({
            ...currentState,
            age: data
        }),
        setDataGrowingParam: data => ({
            ...currentState,
            dataGrowingParams: data
        }),
        setIdDiseaseRecord: data => ({
            ...currentState,
            idDiseaseRecord: data
        }),
        setDetailDiseaseRecord: data => ({
            ...currentState,
            detailDiseaseRecord: data
        })
    }

    return {
        ...setDataRearingRecord
    }
}

export default (state = initialState, action) => {
    const { payload, type } = action
    switch (type) {
        case actionType.START_GET_DATA:
            return handler(state).startGetDataRearingRecords()
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataRearingRecords()
        case actionType.SET_MONTH_PERIODE:
            return handler(state).setMonthPeriode(payload)
        case actionType.SET_YEAR_PERIODE:
            return handler(state).setYearPeriode(payload)
        case actionType.SET_START_PERIODE:
            return handler(state).setStartPeriode(payload)
        case actionType.SET_END_PERIODE:
            return handler(state).setEndPeriode(payload)
        case actionType.SET_ID_REARING_RECORD:
            return handler(state).setIdRearingRecord(payload)
        case actionType.SET_DATE_REARING_RECORD:
            return handler(state).setDateRearingRecord(payload)
        case actionType.SET_GROWING_PARAMS:
            return handler(state).setDataGrowingParam(payload)
        case actionType.SET_ID_DISEASE:
            return handler(state).setIdDiseaseRecord(payload)
        case actionType.SET_DETAIL_DISEASE:
            return handler(state).setDetailDiseaseRecord(payload)
        case actionType.SET_AGE_REARING_RECORD:
            return handler(state).setAgeRearingRecord(payload)

        default:
            return state
    }
}