import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import { API } from '../../../../common/api'
import { useTranslation } from "react-i18next"
import { Layout, Breadcrumb, Row, Col, Card } from 'antd'
import { getRearingRecord } from '../../rearing-record-page/rearing-record-aksi-page/query-rearing-record'
import PerformanceChart from '../../../../common/component/chart/chart-performance'
import TableRecording from '../../../../app/dashboard-peternak/rearing-record-page/rearing-record-page'
import { Link } from 'react-router-dom'

function DetailRearingClosePage(props) {
    const { idPeriode, period } = props
    const { Content } = Layout
    const [object, setObject] = useState([])
    const { t } = useTranslation()

    const fetchDataRearingRecord = async (idPeriode) => {
        await API.get(getRearingRecord('', 0, 0, idPeriode))
            .then(res => {
                if (res.data.data.calculatedRearingRecords !== null) {
                    setObject(res.data.data.calculatedRearingRecords.calculatedArr)
                }
                else {
                    setObject([])
                }
            })
    }

    useEffect(() => {
        fetchDataRearingRecord(idPeriode)
    }, [idPeriode])

    const performance = object.map(({ bw, fcr, feedIntake, saldo, persenKematian, umur, standardBw, standardDp, standardFcr, standardFi }, index) => ({
        key: umur,
        umur: umur,
        deplesi: persenKematian,
        saldo: saldo,
        bw: bw,
        fcr: fcr,
        feedIntake: feedIntake,

        standardBw: standardBw,
        standardDp: standardDp,
        standardFcr: standardFcr,
        standardFi: standardFi,
    }))
    
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to="/peternak/rearing-closed"><span>{t('pages.rearing.title')}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{t('pages.rearing.detail')} {period}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Row className="chart-die-section" gutter={[16, 16]} style={{ maxHeight: "100vh" }}>
                    <Col span={24}>
                        <Card title={t('pages.rearing.chart')} bordered={false}>
                            <PerformanceChart
                                data={performance}
                                chartId="berat-chart"
                                params='all'
                            />
                            <TableRecording />
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    idPeriode: state.layout.idPeriode,
    period: state.layout.periode,
})

const mapDispatchToProps = (dispatch => ({
    navigate
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailRearingClosePage)
export default page