import React from 'react'
import { connect } from 'react-redux'
import { Form, Row, Col, Input } from 'antd'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import { createStandar } from './query-standar-page'

function CreateStandarPage(props) {
    const { fetchData, setLoading, visible, setVisible, strain, form, t } = props

    const postData = (values) => {
        API.post(createStandar(values, strain))
            .then(res => {
                try {
                    if (res.data.errors) {
                        validationMessage('error', 'Gagal Menambahkan Standar', res.data.errors[0].message)
                        setLoading(false)
                    } else if (res.data.data.createStandar._id) {
                        validationMessage('success', 'Berhasil Menambahkan Standar')
                        fetchData()
                        form.resetFields()
                    }
                } catch (error) {
                    validationMessage('error', 'Gagal Menambahkan Standar', error.message)
                    setLoading(false)
                }
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <Form
            hidden={!visible}
            form={form} onFinish={handleSubmit} initialValues={{
                wi: null, fi: null, bw: null, dg: null, adg: null, fcr: null, deplesi: null
            }}
        >
            <Row gutter={[8, 8]}>
                <Col span={3}>
                    <Form.Item
                        name="hari" rules={[{ required: true, message: 'Mohon masukkan hari ke-!' }]}
                    >
                        <Input type='number' size="small"
                            placeholder="Hari ke-"
                            className="input-suffix mt-5"
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        name="bw"
                    >
                        <Input type='number' size="small"
                            placeholder="BW"
                            className="input-suffix mt-5"
                        />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item
                        name="dg"
                    >
                        <Input type='number' size="small"
                            placeholder="DG"
                            className="input-suffix mt-5"
                        />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item
                        name="adg"
                    >
                        <Input type='number' size="small"
                            placeholder="ADG"
                            className="input-suffix mt-5"
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        name="fcr"
                    >
                        <Input type='number' size="small"
                            placeholder="FCR"
                            className="input-suffix mt-5"
                        />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item
                        name="deplesi"
                    >
                        <Input type='number' size="small"
                            placeholder="Deplesi"
                            className="input-suffix mt-5"
                        />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item
                        name="fi"
                    >
                        <Input type='number' size="small"
                            placeholder="FI"
                            className="input-suffix mt-5"
                        />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item
                        name="wi"
                    >
                        <Input type='number' size="small"
                            placeholder="WI"
                            className="input-suffix mt-5"
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <ButtonDashboard
                        text={t('general.cancel')} size='small'
                        height={20}
                        borderRadius="5px" marginLeft={5} marginTop={10}
                        className="font-semi-medium font-content-title button-add"
                        onClick={() => setVisible(false)}
                    />
                    <ButtonDashboard
                        htmlType="submit"
                        text={t('general.add')} size='small'
                        height={20} marginTop={10}
                        backgroundColor="#008ad4"
                        borderRadius="5px"
                        className="font-semi-medium font-content-title button-add"
                        textColor="white"
                    />
                </Col>
            </Row>
        </Form>
    )
}

const mapStateToProps = state => ({
    strain: state.strain.idStrain,
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStandarPage)
export default page