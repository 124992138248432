import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Input, DatePicker, InputNumber } from 'antd'
import moment from 'moment'

function EditPenambahanStokPage(props) {
    const { visible, handleCancel, detail, handleSubmit, satuanOvk, t } = props
    const [data, setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm();
        return (
            <Modal
                visible={visible}
                title={t('pages.ovkStock.titleEditAdd')}
                okText={t('general.edit')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        jumlahStok: data.number,
                        date_picker: moment(new Date(parseInt(data.storeTime))),
                        harga: data.priceOvkTransaction,
                        ttb: data.ttb,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.ovkStock.qtyStock')}</span>
                            <Form.Item
                                name="jumlahStok" rules={[{ required: true, message: `${t('pages.ovkStock.msgOvkStock')}` }]}
                            >
                                <Input
                                    placeholder="50000..."
                                    className="input-suffix mt-5"
                                    suffix={satuanOvk}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.ovkStock.dateStock')}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, type: 'object', message: `${t('pages.ovkStock.msgDateStock')}` }]}
                            >
                                <DatePicker
                                    placeholder="Pilih tanggal periode"
                                    className="select-input-date mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.ovkStock.price')}</span>
                            <Form.Item
                                name="harga" rules={[{ required: true, type: 'number', message: `${t('pages.ovkStock.msgPrice')}` }]}
                            >
                                <InputNumber
                                    placeholder="3000..."
                                    className="input-number mt-5"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.feedStock.docNumber')}</span>
                            <Form.Item
                                name="ttb"
                            >
                                <Input
                                    placeholder="TTB..."
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }

    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPenambahanStokPage)
export default page