export const subscription = {
    title: 'Langganan Saya',
    table: 'Transaksi',
    pay: 'Bayar',
    print: 'Cetak Invoice',
    date: 'Tanggal Transaksi',
    totalTrx: 'Total Transaksi (Rp)',
    number: 'No. Invoice',
    qty: 'Jumlah',
    price: 'Harga',
    customer: 'Pembeli',
    status: 'Status',
    house: 'kandang',
    desc: 'Deskripsi',
    updated: 'Terakhir diupdate',
    valid: 'Invoice ini sah dan diproses oleh komputer',
    payment: 'Pembayaran',
    lastPayDesc: 'Pembayaran terakhir Anda sejumlah {{total}} pada tanggal {{date}}.',
    nextPayDesc: 'Pembayaran Anda selanjutnya adalah pada tanggal {{date}}.',
    seeInv: 'Lihat Invoice',
    noPayment: 'Anda tidak mempunyai histori pembayaran apapun.',
    subsDesc: '{{house}} kandang sampai {{date}}',
    resUpgradeSuccess: 'Berhasil meningkatkan kapasitas kandang Premium',
    resUpgradeFailed: 'Gagal meningkatkan kapasitas kandang Premium',
    addHouse: 'Tambah Kandang',
    addHouseTitle: 'Tambah Kandang Premium',
    houseTotal: 'Jumlah Kandang',
    msgItem: 'Mohon masukkan jumlah kandang',
    startDate: 'Tanggal Mulai',
    msgStartDate: 'Mohon masukkan tanggal mulai',
    unpaid: 'Menunggu Pembayaran',
    validating: 'Sedang Divalidasi',
    expired: 'Kedaluwarsa',
    paid: 'Lunas',
    confirmPayment: 'Konfirmasi Pembayaran',
    waitingPayment: 'Kami masih menunggu pembayaran Anda',
    successPayment: 'Terima kasih, pembayaran Anda terkonfirmasi',
    finishPayment: 'Segera selesaikan pembayaran sebelum {{date}}.',
    expiredPayment: 'Invoice Anda sudah kedaluwarsa.',
    amount: 'sejumlah',
    pricingTitle: 'Pilih Premium Terbaik Anda',
    pricingDesc: 'Kelola kandang Anda lebih leluasa dengan tambahan berbagai fitur.',
    free: 'Gratis',
    freeDesc: 'Gratis Selamanya',
    free1: 'Catat Recording harian.',
    free2: 'Buka Budidaya 1 kandang.',
    free3: 'Monitor Stok dan penggunaan pakan dan OVK.',
    free4: 'Support IoT Connect.',
    free5: 'Informasi Harga Livebird dan Karkas.',
    free6: 'Monitor ADG ,Deplesi, FCR, IP, dan HPP.',
    free7: 'Laporan Hasil Budidaya.',
    free8: 'Satu Akun Admin.',
    k: 'ribu',
    perHouse: 'per kandang',
    monthly: 'Bulanan',
    monthly1: 'Semua fitur pada akun gratis.',
    monthly2: 'Custom jumlah kandang dan gudang sesuai kebutuhan*',
    monthly3: 'Manajemen Stockflow.',
    monthly4: 'Ekspor Excel recording.',
    monthly5: 'Analisis perbandingan performa produksi dengan kandang lain dan periode sebelumnya.',
    monthly6: 'Akun admin tidak terbatas.',
    maxHouse: '* maksimal 5 kandang',
    annual: 'Tahunan',
    annualMember: 'Tahunan dengan Keanggotaan',
    annual1: 'Semua fitur pada akun gratis.',
    annual2: 'Custom jumlah kandang dan gudang sesuai kebutuhan*',
    annual3: 'Manajemen Stockflow.',
    annual4: 'Ekspor Excel recording.',
    annual5: 'Analisis perbandingan performa produksi dengan kandang lain dan periode sebelumnya.',
    annual6: 'Akun admin tidak terbatas.',
    annual7: 'Diskon bagi peternak rakyat* anggota Pinsar dengan menunjukkan kartu anggota Pinsar, Himpuli, atau Gopan sebesar:',
    annual8: 'Diskon bagi peternak rakyat* anggota Pinsar dengan menunjukkan kartu anggota Pinsar, Himpuli, atau Gopan.',
    platinum: 'Kustomisasi aplikasi Anda',
    contact: 'Hubungi Kami',
    specialPrice: 'Harga Spesial',
    platinum1: 'Semua fitur pada akun gratis.',
    platinum2: 'Semua fitur pada akun premium.',
    platinum3: 'Subdomain spesial.',
    platinum4: 'Support Extra Protection Cloudflare.',
    platinum5: 'Penawaran harga khusus aplikasi.',
    platinum6: 'Penawaran Harga khusus perangkat IoT.',
    platinum7: 'Bisa menggunakan server sendiri.',
    getStarted: 'Mulai Sekarang',
    resAddInvSuccess: 'Berhasil membuat invoice pembayaran',
    resAddInvFailed: 'Gagal membuat invoice pembayaran',
    subsTitle: 'Langganan Premium',
    duration: 'Durasi Langganan',
    msgDuration: 'Mohon masukkan durasi langganan',
    month: 'bulan',
    year: 'tahun',
    isMember: 'Anda termasuk anggota Peternak Rakyat?',
    membership: 'Jenis Keanggotaan',
    msgMembership: 'Mohon pilih keanggotaan Anda',
    upload: 'Upload Foto Kartu Anggota',
    msgUpload: 'Mohon upload foto kartu anggota',
    pickPlan: 'Pilih Jenis Langgananmu',
    changePlan: 'Ganti Langganan'
}