import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../common/store/action';
import EditPanenComponent from '../../../../modules/dashboard-peternak/rearing-record-component/edit-detail-rearing-component/edit-panen-component';
import { getCustomer } from '../../master-page/customer-page/customer-aksi-page/query-customer'
import { API } from '../../../../common/api'

function EditPanenPage(props) {
    const { visible, handleCancel, detail, handleSubmit, t } = props
    const [data, setData] = useState(detail)
    const [customer, setCustomer] = useState([])
    
    const fetchData = () => {
        API.get(getCustomer('', 0, 0))
            .then(res => {
                setCustomer(res.data.data.customers.customers)
            }).catch((error) => {
                setCustomer([])
            })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        function setDetailData() {
            if (detail === "") {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    return (
        <EditPanenComponent
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            detail={detail}
            data={data}
            t={t} customer={customer}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPanenPage)
export default page