/**
 * @author spindyzel
 * @since 28 Desember 2019
 */

export const BASE_URL = process.env.REACT_APP_API_URL
export const FAKE_URL = process.env.REACT_APP_API_URL
export const IOT_URL = process.env.REACT_APP_IOT_URL
export const PRICE_URL = process.env.REACT_APP_PRICE_URL
export const CSRF_SERVICE_NAME = 'user/csrf-token'
export const AUTHENTICATION_KEY = ''
export const AUTHORIZATION_KEY = ''