export const error = {
    email: "Your email or username is not registered. Please login with registered account!",
    password: "Your password is wrong. Please input correct password!",
    verified: "Your account is not verified yet.",
    verifiedDesc: "Please click verification link on your inbox/spam or send verification link again!",
    verifiedLink: "Resend verification link",
    google: "Your Google account is not registered yet. Please register first with your Google account!",
    facebook: "Your Facebook account is not registered yet. Please register first with your Facebook account!",
    oauthEmail: 'Sorry, this account was registered to login using email/manual!',
    oauthFb: 'Sorry, this account was registered to login using Facebook!',
    oauthGoogle: 'Sorry, this account was registered to login using Google!',
    authenticate: "Sorry, your account is unauthenticated!",
    authorize: "Sorry, your account is unauthorized!",
    user: "Sorry, this user is not found!",
    company: "Sorry, this company is not found!",
    captcha: "Captcha is wrong. Please try again!",
    registeredEmail: 'Email {{email}} already registered. Please input other email address!',
    registeredUsername: 'Username {{username}} already taken and registered. Please input other username!',
    connection: 'Failed to connect. Please check your internet connection!',
    errId: 'Sorry, we cannot find the data',
    dateRecording: 'Sorry, this date has been entered in this recording',
    addCity: 'Sorry, this city has been added',
    inactiveHouse: 'This house is inactive',
    outStock: 'Out of stock! Please add stock first or input fewer quantity'
}