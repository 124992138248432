import * as actionType from './layout-action-type';

const initialState = {
    kandang: null,
    dataKandang: [],
    dataPeriode: [],
    dataPeriodeAktif: [],
    loadPeriodeAktif: false,
    periode: '',
    allPeriode: [],
    idDevice: null,
    deviceType: null,
    idPeriode: null,
    idKandang: null,
    datePeriode: null,
    totalKandang: null,
    isTourOpen: false,
    loading: false,
    imageLoad: false,
    invoiceId: null,
    idHarvest: null,
    indexHarvest: null,
    isCreateRecording: false,
    loadingRearing: false,
    loadingFeedWh: false,
    loadingOvkWh: false,
}

const handler = (currentState) => {
    const setDataLayout = {
        startGetDataLayouts: () => ({
            ...currentState,
            loading: true,
        }),

        finishGetDataLayouts: () => ({
            ...currentState,
            loading: false,
        }),

        getDataKandangs: data => ({
            ...currentState,
            dataKandang: data
        }),

        getDataPeriode: data => ({
            ...currentState,
            dataPeriode: data
        }),
        getAllPeriode: data => ({
            ...currentState,
            allPeriode: data
        }),
        setPeriodeAktif: data => ({
            ...currentState,
            dataPeriodeAktif: data
        }),
        successSetPeriodeAktif: () => ({
            ...currentState,
            loadPeriodeAktif: true
        }),

        setIdPeriode: data => ({
            ...currentState,
            idPeriode: data
        }),
        setPeriode: data => ({
            ...currentState,
            periode: data
        }),

        setKandang: data => ({
            ...currentState,
            kandang: data
        }),
        setIdKandang: data => ({
            ...currentState,
            idKandang: data
        }),

        setDatePeriode: data => ({
            ...currentState,
            datePeriode: data
        }),

        setTotalKandang: data => ({
            ...currentState,
            totalKandang: data
        }),

        setIsTourOpen: data => ({
            ...currentState,
            isTourOpen: data
        }),
        setIdDevice: data => ({
            ...currentState,
            idDevice: data
        }),
        setDeviceType: data => ({
            ...currentState,
            deviceType: data
        }),
        setImageLoad: data => ({
            ...currentState,
            imageLoad: data
        }),
        setIdInvoice: data => ({
            ...currentState,
            invoiceId: data
        }),
        setIdHarvest: data => ({
            ...currentState,
            idHarvest: data
        }),
        setIndexHarvest: data => ({
            ...currentState,
            indexHarvest: data
        }),
        setCreateRecording: data => ({
            ...currentState,
            isCreateRecording: data
        }),
        setLoadingRearing: data => ({
            ...currentState,
            loadingRearing: data
        }),
        setLoadingFeedWh: data => ({
            ...currentState,
            loadingFeedWh: data
        }),
        setLoadingOvkWh: data => ({
            ...currentState,
            loadingOvkWh: data
        }),
    }

    return {
        ...setDataLayout
    }
}

export default (state = initialState, action) => {
    const { payload, type } = action
    switch (type) {
        case actionType.START_GET_DATA:
            return handler(state).startGetDataLayouts()
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataLayouts()
        case actionType.SET_DATA_KANDANG:
            return handler(state).getDataKandangs(payload)
        case actionType.SET_KANDANG:
            return handler(state).setKandang(payload)
        case actionType.SET_DATA_PERIODE:
            return handler(state).getDataPeriode(payload)
        case actionType.SET_ALL_PERIODE:
            return handler(state).getAllPeriode(payload)
        case actionType.SET_PERIODE_AKTIF:
            return handler(state).setPeriodeAktif(payload)
        case actionType.SUCCESS_SET_PERIODE_AKTIF:
            return handler(state).successSetPeriodeAktif()
        case actionType.SET_ID_PERIODE:
            return handler(state).setIdPeriode(payload)
        case actionType.SET_PERIODE:
            return handler(state).setPeriode(payload)
        case actionType.SET_ID_KANDANG:
            return handler(state).setIdKandang(payload)
        case actionType.SET_DATE_PERIODE:
            return handler(state).setDatePeriode(payload)
        case actionType.SET_TOTAL_KANDANG:
            return handler(state).setTotalKandang(payload)
        case actionType.SET_IS_TOUR:
            return handler(state).setIsTourOpen(payload)
        case actionType.SET_ID_DEVICE:
            return handler(state).setIdDevice(payload)
        case actionType.SET_DEVICE_TYPE:
            return handler(state).setDeviceType(payload)
        case actionType.SET_IMAGE_LOAD:
            return handler(state).setImageLoad(payload)
        case actionType.SET_ID_INVOICE:
            return handler(state).setIdInvoice(payload)
        case actionType.SET_ID_HARVEST:
            return handler(state).setIdHarvest(payload)
        case actionType.SET_INDEX_HARVEST:
            return handler(state).setIndexHarvest(payload)
        case actionType.SET_CREATE_RECORDING:
            return handler(state).setCreateRecording(payload)
        case actionType.SET_LOADING_REARING:
            return handler(state).setLoadingRearing(payload)
        case actionType.SET_LOADING_FEED_WH:
            return handler(state).setLoadingFeedWh(payload)
        case actionType.SET_LOADING_OVK_WH:
            return handler(state).setLoadingOvkWh(payload)

        default:
            return state
    }
}