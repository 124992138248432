import * as actionType from './gudang-pakan-action-type';

const initialState = {
    dataGudangPakan: [],
    idGudangPakan: null,
    namaGudangPakan: null,
    loading: false,
}

const handler = (currentState) => {
    const setDataGudangPakan = {
        startGetDataGudangPakans: () => ({
            ...currentState,
            loading: true,
        }),

        finishGetDataGudangPakans: () => ({
            ...currentState,
            loading: false,
        }),

        getDataGudangPakans: data => ({
            ...currentState,
            dataGudangPakan: data
        }),

        setIdGudangPakan: data => ({
            ...currentState,
            idGudangPakan: data
        }),

        setNamaGudangPakan: data => ({
            ...currentState,
            namaGudangPakan: data
        }),
    }

    return {
        ...setDataGudangPakan
    };
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case actionType.START_GET_DATA:
            return handler(state).startGetDataGudangPakans();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataGudangPakans();
        case actionType.SET_DATA_GUDANG_PAKAN:
            return handler(state).getDataGudangPakans(payload);
        case actionType.SET_ID_GUDANG_PAKAN:
            return handler(state).setIdGudangPakan(payload);
        case actionType.SET_NAMA_GUDANG_PAKAN:
            return handler(state).setNamaGudangPakan(payload);


        default:
            return state;
    }
}