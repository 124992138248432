import React, { useState } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import { Form, Row, Col, InputNumber, Select, Button, Upload, Card, Breadcrumb, Layout } from 'antd'
import { navigate } from '../../../../common/store/action'
import CONSTANTS from '../../../../common/utils/Constants'
import { useTranslation } from "react-i18next"
import Loading from '../../../../common/component/loading/loading-container'
import { useHistory, useParams, Link } from 'react-router-dom'

function CreatePenyakitPage(props) {
    const { age, penyakit, navigate } = props
    const [fileList, setFileList] = useState([])
    const [fileUpload, setFileUpload] = useState([])
    const [loading, setLoading] = useState(false)
    const { Option } = Select
    const { Content } = Layout
    const { t } = useTranslation()
    const history = useHistory()
    const params = useParams()
    const idRearingRecord = params.recordingId

    const postData = (values, fileUpload) => {
        const param = new FormData()
        param.set('disease', values.penyakit.value)
        param.set('number', values.jumlahAyam)
        param.set('rearingRecord', idRearingRecord)
        if (fileUpload) {
            for (let i = 0; i < fileUpload.length; i++) {
                param.append(`file[${i}]`, fileUpload[i])
            }
        }
        setLoading(true)
        API.postRest('/upload-penyakit', param)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.success === true) {
                        history.push(`/peternak/recording/${params.rearingId}/${idRearingRecord}`)
                        validationMessage('success', `${t("pages.recording.disease.resAddSuccess")}`)
                        setLoading(false)
                    } else {
                        validationMessage('error', `${t("pages.recording.disease.resAddFailed")}`)
                        setLoading(false)
                    }
                } else {
                    validationMessage('error', `${t("pages.recording.disease.resAddFailed")}`)
                    setLoading(false)
                }
                setLoading(false)
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.disease.resAddFailed")}`)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, fileUpload)
    }

    const propsPicture = {
        name: 'file',
        onChange(info) {
            if (info.file.size > 3000000) {
                errMessage('error', 'Ukuran file harus lebih kecil dari 3 MB!')
            } else
                if (info.file.status === 'done') {
                    let arrayPicture = []
                    for (let i = 0; i < info.fileList.length; i++) {
                        arrayPicture.push(info.fileList[i].originFileObj)
                    }
                    setFileList(info.fileList)
                    setFileUpload(arrayPicture)
                } else if (info.file.status === 'error') {
                    console.log('err', info.file, info.fileList)
                }
        }
    }

    const onPreview = async file => {
        let src = file.url
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }
        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow.document.write(image.outerHTML)
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok")
        }, 0)
    }

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to={`/peternak/recording/${params.rearingId}`}><span>{t("pages.recording.title")}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={`/peternak/recording/${params.rearingId}/${idRearingRecord}?section=disease`}><span>{t("pages.recording.detail")}{age}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{t("pages.recording.disease.add")}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Loading loading={loading}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title={t("pages.recording.disease.add")} bordered={false}>
                                <Form onFinish={handleSubmit}>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content" >{t("pages.recording.disease.title")}</span>
                                            <Form.Item
                                                name="penyakit" rules={[{ required: true, message: `${t("pages.recording.disease.msgDisease")}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={t("pages.recording.disease.phDisease")}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular mt-5"
                                                >
                                                    {
                                                        penyakit.map(data =>
                                                            <Option
                                                                key={data._id}
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content" >{t("pages.recording.disease.qty")}</span>
                                            <Form.Item
                                                name="jumlahAyam" rules={[{ required: true, message: `${t("pages.recording.disease.msgQty")}` }]}
                                            >
                                                <InputNumber
                                                    name='jumlahAyam'
                                                    placeholder="1000..."
                                                    className="input-number mt-5"
                                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 8]}>
                                        <Col span={24}>
                                            <span className="font-semi-bold black font-title-medium">{t("pages.recording.disease.pic")}</span>
                                            <Form.Item name="fotoPenyakit">
                                                <Upload {...propsPicture}
                                                    customRequest={dummyRequest}
                                                    listType="picture-card" multiple
                                                    onPreview={onPreview}
                                                >
                                                    {fileList.length < 5 && '+ Upload'}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button
                                        onClick={() => navigate(CONSTANTS.RECORDING_MENU_KEY)}
                                    >
                                        {t('general.cancel')}
                                    </Button>
                                    <Button
                                        style={{ marginLeft: 8, marginTop: 0 }}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {t('general.add')}
                                    </Button>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    penyakit: state.penyakit.dataPenyakit,
    age: state.rearingRecord.age
})

const mapDispatchToProps = (dispatch => ({
    navigate
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePenyakitPage)
export default page