import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input, Table, Row, Col, Drawer, Form, Select, Tooltip, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { SearchOutlined } from '@ant-design/icons'
import Loading from '../../../../common/component/loading/loading-container'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'
import moment from 'moment'

function TableRetentionPage(props) {
    const { data, loading, showEdit, typeUser, handleSubmit, form, visible, setVisible, companyName, t } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const columns = [
        {
            title: `Last Activity`,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
        },
        {
            title: `Company`,
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: `Number of Activities`,
            dataIndex: 'retention',
            key: 'retention',
            align: 'center'
        },
        {
            title: `# Rank`,
            dataIndex: 'rank',
            key: 'rank',
            sorter: (a, b) => a.rank - b.rank,
            align: 'center',
        },
        {
            title: `Company Type`,
            dataIndex: 'type',
            key: 'type',
            filters: [
                {
                  text: 'Mandiri',
                  value: 'mandiri',
                },
                {
                  text: 'Kemitraan',
                  value: 'kemitraan',
                },
                {
                    text: 'Riset',
                    value: 'Riset',
                  },
              ],
              onFilter: (value, record) => record.type.toLowerCase().indexOf(value) === 0,
            width: 140,
            align: 'center'
        },
        {
            title: `Subscription`,
            dataIndex: 'typeUser',
            key: 'typeUser',
            align: 'center',
            sorter: (a, b) => a.typeUser.length - b.typeUser.length,
            render: typeUser => (
                <Tag color={typeUser === 'premium' ? 'geekblue' : typeUser === 'Platinum' ? 'indianred' : 'green'}>
                    {typeUser.toUpperCase()}
                </Tag>
            )
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 70,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showEdit(row.key, row.typeUser, row.name)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                </Space>
            ),
        }
    ]

    const datas = data.map(({ _id, type, typeUser, name, retention, updatedAt }, index) => ({
        key: _id,
        id: _id,
        type: type,
        typeUser: typeUser,
        name: name,
        retention: retention,
        rank: index + 1,
        updatedAt: moment(updatedAt, 'DD/MM/YYYY hh.mm.ss').format('LLL'),
    }))

    return (
        <Loading loading={loading}>
            <Table
                columns={columns} bordered
                dataSource={datas}
                pagination={false}
                className="table-dashboard"
                scroll={{ y: '50vh' }}
            />
            <Drawer
                title={false} width={500}
                placement="right"
                onClose={() => setVisible(false)}
                visible={visible}
                getContainer={false}
                style={{ position: 'absolute', opacity: visible ? 100 : 0 }}
            >
                <Form onFinish={handleSubmit} form={form}
                    initialValues={{ typeUser: typeUser }}
                >
                    <Row>
                        <Col span={24} className='mb-10'>{companyName}</Col>
                        <Col span={24}>
                            <Form.Item name="typeUser">
                                <Select
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    <Select.Option value='free'>Free</Select.Option>
                                    <Select.Option value='premium'>Premium</Select.Option>
                                    <Select.Option value='Platinum'>Platinum</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <ButtonDashboard
                                htmlType="submit"
                                text='Edit'
                                height={20}
                                backgroundColor="#008ad4"
                                borderRadius="5px" marginLeft={10}
                                className="font-semi-medium font-content-title button-add"
                                textColor="white"
                            />
                        </Col>
                        <Col>
                            <ButtonDashboard
                                text='Batal'
                                height={20}
                                borderRadius="5px" marginLeft={5}
                                className="font-semi-medium font-content-title button-add"
                                onClick={() => setVisible(false)}
                            />
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </Loading>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TableRetentionPage)
export default page