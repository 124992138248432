import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import EditOvkComponent from '../../../../modules/dashboard-peternak/rearing-record-component/edit-detail-rearing-component/edit-ovk-component'
import { getListOvk } from '../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/store/stok-ovk-action'
import { getStokGudangOvk } from '../../stok-page/stok-ovk-page/stok-ovk-aksi-page/query-stok-ovk'

function EditOvkPage(props) {
    const { visible, handleCancel, detail, handleSubmit, suffix, getListOvk, idGudangOvk, profil, t } = props
    const [data, setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if (detail === "") {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    useEffect(() => {
        getListOvk(getStokGudangOvk(idGudangOvk))
    }, [getListOvk, idGudangOvk])

    return (
        <EditOvkComponent
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            detail={detail}
            data={data}
            suffix={suffix}
            stokOvk={props.stokOvk}
            t={t} profil={profil}
        />
    );
}

const mapStateToProps = state => ({
    stokOvk: state.stokOvk.dataStokOvk,
    idGudangOvk: state.gudangOvk.idGudangOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate, getListOvk
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditOvkPage)
export default page