export const getStandar = (keyword,limit,skip,strain) => {
    return (`
        query{
            standards(keyword:"${keyword}",limit:${limit},skip:${skip}, strain:"${strain}"){
              totalCount,
              strain {
                _id
                type
              }
              standards{
                  _id
                  day
                  bw
                  dg
                  adg
                  fi
                  wi
                  fcr
                  dep
              }
            }
        }
    `)
}

export const createStandar = (value,strain) => {
    return {  
        query : 
        `mutation{
            createStandar(standarInput:{
              day: ${parseInt(value.hari)}
              bw :  ${value.bw}
              dg :  ${value.dg}
              adg :  ${value.adg}
              fcr :  ${value.fcr}
              fi :  ${value.fi}
              wi :  ${value.wi}
              dep :  ${value.deplesi}
              strain : "${strain}"
            }){
              _id
            }
          }
          `
    }
}

export const detailStandar = (_id) => {
    return (`
    query{
        standard(_id:"${_id}"){
          _id
          day
          bw
          fcr
          adg
          dg
          dep
          fi
          wi
        }
      }
    `)
}

export const updateStandar = (_id,value) => {
    return {
        query : `
        mutation{
            updateStandar(_id: "${_id}",updateStandarInput:{
              day: ${parseInt(value.hari)}
              bw :  ${value.bw}
              dg :  ${value.dg}
              adg :  ${value.adg}
              fcr :  ${value.fcr}
              dep :  ${value.deplesi}
              fi :  ${value.fi}
              wi :  ${value.wi}
            }){
              _id
            }
          }
          `
    }
}

export const deleteStandar = (_id) => {
    return (`
        mutation{
            deleteStandar(_id:"${_id}"){
            deleted
            }
        }
    `)
}