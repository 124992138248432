import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Input } from 'antd'

function EditCustomerPage(props) {
    const { visible, handleCancel, detail, handleSubmit, t } = props
    const [data, setData] = useState(detail)
    const { TextArea } = Input

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.master.customer.edit')}
                okText={t('general.edit')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            handleSubmit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        name: data.name,
                        address: data.address,
                        contact: data.contact,
                        otherInformation: data.otherInformation,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.customer.name')}</span>
                            <Form.Item
                                name="name" rules={[{ required: true, message: `${t('pages.master.customer.msgName')}` }]}
                            >
                                <Input
                                    name='name'
                                    placeholder={t('pages.master.customer.phName')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.customer.address')}</span>
                            <Form.Item
                                name="address" rules={[{ required: true, message: `${t('pages.master.customer.msgAddress')}` }]}
                            >
                                <Input
                                    name='address'
                                    placeholder={t('pages.master.customer.phAddress')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.customer.contact')}</span>
                            <Form.Item
                                name="contact" rules={[{ required: true, message: `${t('pages.master.customer.msgContact')}` }]}
                            >
                                <Input
                                    name='contact'
                                    placeholder="0877656..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.customer.info')}</span>
                            <Form.Item
                                name="otherInformation" rules={[{ required: true, message: `${t('pages.master.customer.msgInfo')}` }]}
                            >
                                <TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={t('pages.master.customer.phInfo')}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <CollectionCreateForm
            visible={visible}
            handleSubmit={handleSubmit}
            onCancel={() => handleCancel()}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditCustomerPage)
export default page