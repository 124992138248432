import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../common/api'
import { navigate } from '../../../common/store/action'
import CONSTANTS from '../../../common/utils/Constants'
import { validationMessage, errMessage } from '../../../common/component/notification/notification'
import DashboardComponent from '../../../modules/dashboard-peternak/dashboard-component/dashboard-component'
import { Form } from 'antd'
import {
    getDashboardRecord, getSummaryReport, getFeedUsage, getThiDevice,
    getRetention, updateSubs, addCity, removeCity, getCities, getUsers, sendVoucher, getTaredDevice,
} from './query-dashboard-page'
import { getIotHouse } from '../Iot-page/iot-aksi-page/query-iot'
import { getIdDevice, getDeviceType, getImageLoad, setLoadingRearing } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'

import { useTranslation } from "react-i18next"
import NumberFormat from 'react-number-format'
import Cookies from 'universal-cookie'
import axios from 'axios'
import socketIOClient from 'socket.io-client'
import { useParams, useHistory } from 'react-router-dom'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
import { getPendingInvoices } from '../subscription-page/query-subscription'
import { getListProfil } from '../../../modules/dashboard-peternak/profil-component/store/profil-action'
import { getProfile } from '../profil-page/query-profil'
/*End Import Moment Js */

function DashboardPage(props) {
    const { idKandang, idRearing, idDevice, getIdDevice, getDeviceType, loading,
        getImageLoad, imageLoad, typeDevice, role, getListProfil, setLoadingRearing } = props
    const [now] = useState(moment().format("YYYY-MM-DD"))
    const [retention, setRetention] = useState([])
    const [invoices, setInvoices] = useState([])

    const [data, setData] = useState([])
    const [envReverse, setEnvReverse] = useState([])
    const [performa, setPerforma] = useState([])
    const [report, setReport] = useState({})
    const [feedUsages, setFeedUsages] = useState([])
    const [ages, setAges] = useState([])
    const [range, setRange] = useState([moment().add(-2, 'hours'), moment()])
    const [chartData, setChartData] = useState([])
    const [devices, setDevices] = useState([])
    const [thiData, setThiData] = useState([])
    const [active, setActive] = useState(false)
    const timenow = moment().format('DDMMYYYY hhmmss')
    const [load, setLoad] = useState(false)
    const [visible, setVisible] = useState(false)
    const [typeUser, setTypeUser] = useState('')
    const [idData, setIdData] = useState(null)
    const [companyName, setCompany] = useState('')
    const [isHumidity, setHumidity] = useState(false)

    const [users, setUsers] = useState([])
    const [isVoucher, setIsVoucher] = useState(false)

    const [cities, setCities] = useState([])
    const [savedCity, setSavedCity] = useState([])
    const [isSearch, setSearch] = useState(false)
    const [isDate, setIsDate] = useState(true)
    const [city, setCity] = useState(null)
    const [cityString, setCityString] = useState(null)
    const [prov, setProv] = useState(null)
    const [rangePrice, setRangePrice] = useState([moment().add(-10, 'days'), moment().add(-3, 'days')])
    const [lastDatePrice, setLastDatePrice] = useState(moment().add(-3, 'days'))
    const [lastPrice, setLastPrice] = useState({})
    const [priceRange, setPriceRange] = useState([])
    const [nearby, setNearby] = useState([])

    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [form3] = Form.useForm()
    const { t } = useTranslation()
    const cookies = new Cookies()
    const [env, setEnv] = useState({ temperature: 0, humidity: 0, amonia: 0, co2: 0, voc: 0, kph: 0, ms: 0 })

    const staffMode = props.profil.permission?.staffMode
    const endpoint = process.env.REACT_APP_SOCKET_HOST
    const [isDeviceReady] = useState(false)
    const [isPriceReady] = useState(false)

    const history = useHistory()
    const params = useParams()
    const idPeriode = params.rearingId

    const fetchDashboardRecord = async (idPeriode) => {
        setLoadingRearing(true)
        await API.get(getDashboardRecord('', 0, 0, idPeriode))
            .then(res => {
                if (res.data.errors) {
                    setPerforma([])
                    setLoadingRearing(false)
                } else if (res.data.data.calculateDashboardRecords !== null) {
                    const performaChart = res.data.data.calculateDashboardRecords.calculatedArr
                        .map(({ bw, fcr, saldo, depletion, feedIntake,
                            day, standardBw, standardDp, standardFcr, standardFi }, index) => ({
                                umur: day,
                                deplesi: depletion,
                                saldo: saldo,
                                bw: bw,
                                fcr: fcr,
                                feedIntake: feedIntake,
                                standardBw: standardBw,
                                standardDp: standardDp,
                                standardFcr: standardFcr,
                                standardFi: standardFi
                            }))
                    setPerforma(performaChart)
                    setLoadingRearing(false)
                }
            }).catch((error) => {
                setPerforma([])
                setLoadingRearing(false)
            })
    }

    useEffect(() => {
        if (idPeriode && !staffMode) {
            fetchDashboardRecord(idPeriode)
            fetchDataSumaryReport(idPeriode)
            fetchDataFeedUsage(idPeriode)
        }
        // eslint-disable-next-line 
    }, [idPeriode, staffMode])

    useEffect(() => {
        if (!idPeriode && idRearing) {
            history.replace(`/peternak/dashboard/${idRearing}`)
        }
        // eslint-disable-next-line 
    }, [idPeriode, idRearing])

    useEffect(() => {
        if (idKandang && isDeviceReady) {
            fetchDevices(idKandang)
        }
        // eslint-disable-next-line
    }, [idKandang, isDeviceReady])

    useEffect(() => {
        if (idDevice && isDeviceReady) {
            fetchDataThi(idDevice, range)
            fetchDataEnv(idDevice, range)
        }
        // eslint-disable-next-line
    }, [idDevice, range, isDeviceReady])

    useEffect(() => {
        if (isDeviceReady) {

            const socket = socketIOClient(endpoint)
            const emitData = {}
            emitData.deviceId = idDevice
            emitData.deviceType = typeDevice
            socket.emit('device_id', emitData)
            socket.on("environment_device", data => {
                setEnv(data)
            })
        }
    }, [endpoint, idDevice, typeDevice, isDeviceReady])

    useEffect(() => {
        if (isPriceReady) {
            fetchAllCity()
            fetchCities()
            if (city) {
                fetchRangePrice(city, moment(rangePrice[0]).format('YYYY-MM-DD'), moment(rangePrice[1]).format('YYYY-MM-DD'))
                fetchLastPrice(city, moment().add(-3, 'days').format('YYYY-MM-DD'))
            }
            if (prov) {
                fetchNearby(prov, moment(lastDatePrice).format('YYYY-MM-DD'))
            }
        }
        // eslint-disable-next-line 
    }, [rangePrice, lastDatePrice, prov, city, isPriceReady])

    const fetchRetention = async () => {
        setLoadingRearing(true)
        await API.get(getRetention('', 0, 0))
            .then(res => {
                if (res.data.data.retention !== null) {
                    setRetention(res.data.data.retention.companies)
                    setLoadingRearing(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setRetention([])
                setLoadingRearing(false)
            })
    }

    const fetchInvoices = async () => {
        setLoadingRearing(true)
        await API.get(getPendingInvoices('', 0, 0))
            .then(res => {
                if (res.data.data.pendingInvoices !== null) {
                    setInvoices(res.data.data.pendingInvoices.invoices)
                    setLoadingRearing(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setInvoices([])
                setLoadingRearing(false)
            })
    }

    const fetchUsers = async () => {
        setLoadingRearing(true)
        await API.get(getUsers('', 0, 0))
            .then(res => {
                if (res.data.data.users !== null) {
                    setUsers(res.data.data.users.users)
                    setLoadingRearing(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setUsers([])
                setLoadingRearing(false)
            })
    }

    const giveVoucher = async () => {
        form3
            .validateFields()
            .then((values) => {
                submitVoucher(values)
            })
            .catch((info) => {
                console.log('Validate Failed:', info)
            })
    }
    const submitVoucher = async (values) => {
        await API.post(sendVoucher(values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', 'Voucher gagal dikirimkan', res.data.errors[0].message)
                } else if (res.data.data.users !== null) {
                    form3.resetFields()
                    errMessage('success', 'Voucher berhasil dikirimkan')
                }
            }).catch((error) => {
                errMessage('error', 'Voucher gagal dikirimkan', error.message)
            })
    }

    const onClaim = () => {
        getListProfil(getProfile())
        window.open('/peternak/voucher')
    }

    useEffect(() => {
        if (role === 'superadmin') {
            fetchRetention()
            fetchUsers()
            fetchInvoices()
        }
        // eslint-disable-next-line 
    }, [role])

    const showEdit = useCallback((id, typeUser, name) => {
        setVisible(true)
        setIdData(id)
        setTypeUser(typeUser)
        setCompany(name)
        form.setFieldsValue({ typeUser: typeUser })
    }, [form])

    const updatedData = (values) => {
        API.post(updateSubs(idData, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoadingRearing(false)
                }
                else if (res.data.data.updateSubscription._id) {
                    validationMessage('success', `Berhasil mengubah subscription`)
                    fetchRetention()
                }
            }).catch((error) => {
                console.log(error.message)
                validationMessage('error', `Gagal mengubah subscription`, error.message)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
    }

    const fetchDataSumaryReport = async (idPeriode) => {
        setActive(true)
        await API.get(getSummaryReport(idPeriode))
            .then(res => {
                if (!idPeriode || res.data.errors) {
                    setReport({ liveBird: 0, culling: 0, mati: 0, totalPakan: 0 })
                    setActive(false)
                }
                else if (res.data.data.dashboardCalculate !== null) {
                    setReport(res.data.data.dashboardCalculate)
                    setActive(false)
                }
            }).catch((error) => {
                setReport({ liveBird: 0, culling: 0, mati: 0, totalPakan: 0 })
                setActive(false)
            })
    }

    const fetchDataFeedUsage = async (idPeriode) => {
        setLoadingRearing(true)
        await API.get(getFeedUsage(idPeriode))
            .then(res => {
                if (res.data.data.feedUsage !== null) {
                    setFeedUsages(res.data.data.feedUsage.feedUsage)
                    setAges(res.data.data.feedUsage.ages)
                    setChartData(res.data.data.feedUsage.chart)
                    setLoadingRearing(false)
                }
            }).catch((error) => {
                setFeedUsages([])
                setAges([])
                setChartData([])
                setLoadingRearing(false)
            })
    }

    const fetchDataThi = async (deviceId, range) => {
        setLoadingRearing(true)
        await API.get(getThiDevice(deviceId, range[0], range[1]))
            .then(res => {
                if (res.data.data.thiDevice !== null) {
                    setThiData(res.data.data.thiDevice.thiDevice)
                    setLoadingRearing(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setThiData([])
                setLoadingRearing(false)
            })
    }

    const fetchDataEnv = async (deviceId, range) => {
        setLoadingRearing(true)
        await API.get(getTaredDevice(deviceId, range[0], range[1]))
            .then(res => {
                if (res.data.data.taredDevice !== null) {
                    setData(res.data.data.taredDevice.envDevice)
                    setEnvReverse(res.data.data.taredDevice.envReverse)
                    setLoadingRearing(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setData([])
                setLoadingRearing(false)
            })
    }

    const fetchDevices = async (idKandang) => {
        setLoadingRearing(true)
        await API.get(getIotHouse('', 0, 0, idKandang))
            .then(res => {
                if (res.data.data.deviceHouses !== null) {
                    setDevices(res.data.data.deviceHouses.devices)
                    setLoadingRearing(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setDevices([])
                setLoadingRearing(false)
            })
    }

    const onDevice = (id, type) => {
        getDeviceType(type)
        getIdDevice(id)
    }

    const suhu = data.map(({ voc, amonia, humidity, temperature, co2, kph, ms, ts, timestamp }, index) => ({
        ts: new Date(ts),
        timestamp: timestamp,
        voc: voc.toFixed(0),
        co2: co2.toFixed(0),
        amonia: amonia.toFixed(2),
        humidity: humidity.toFixed(2),
        temperature: temperature.toFixed(2),
        kph: kph.toFixed(2),
        ms: ms.toFixed(2),
    }))

    const suhuReverse = envReverse.map(({ voc, amonia, humidity, co2, temperature, kph, ms, ts, timestamp }, index) => ({
        ts: new Date(ts),
        timestamp: timestamp,
        voc: voc.toFixed(0),
        co2: co2.toFixed(0),
        amonia: amonia.toFixed(2),
        humidity: humidity.toFixed(2),
        temperature: temperature.toFixed(2),
        kph: kph.toFixed(2),
        ms: ms.toFixed(2),
    }))

    const feedUsage = feedUsages.map(({ _id, feed, firstAge, lastAge, total }, index) => ({
        key: _id,
        id: _id,
        feed: feed,
        umur: firstAge + '-' + lastAge + `  ${t('pages.dashboard.feed.days')}`,
        total: <NumberFormat value={total} displayType={'text'} thousandSeparator={true} suffix={` kg`} />,
    }))

    const thiChart = thiData.map(({ status, timestamp, ts, value }, index) => ({
        value: value,
        status: status,
        ts: new Date(ts),
        timestamp: timestamp
    }))

    const exportData = async () => {
        const params = new FormData()
        params.set('deviceId', idDevice)
        params.set('start', range[0])
        params.set('end', range[1])
        setLoad(true)
        let token = cookies.get('token')
        await axios
            .post(`${process.env.REACT_APP_API_URL}/excel-env-device`, params, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                responseType: 'blob'
            })
            .then(res => {
                if (typeof (res.data === 'blob')) {
                    setLoad(false)
                    const link = document.createElement("a")
                    link.href = window.URL.createObjectURL(res.data)
                    link.download = `Data logging ${timenow}.xlsx`
                    link.click()
                    validationMessage('success', `Berhasil export data lingkungan device ke file Excel`)
                }
            }).catch((error) => {
                validationMessage('error', `Gagal export data lingkungan device ke file Excel`, error.message)
                console.log(error.message)
                setLoad(false)
            })
    }

    const handleExport = () => {
        exportData()
    }

    //PRICE
    const fetchAllCity = async () => {
        await API.getPrice('/city')
            .then(res => {
                setCities(res.data)
            }).catch((error) => {
                setCities([])
            })
    }

    const fetchCities = async () => {
        await API.get(getCities())
            .then(res => {
                setSavedCity(res.data.data.getProfile.cities)
                setIsVoucher(res.data.data.getProfile.isVoucher)
            }).catch((error) => {
                console.log(error.message)
                setSavedCity(savedCity)
                setIsVoucher(false)
            })
    }

    const fetchRangePrice = async (city, start, end) => {
        await API.getPrice(`/price?city_id=${city}&start=${start}&end=${end}`)
            .then(res => {
                setPriceRange(res.data)
            }).catch((error) => {
                console.log(error.message)
                setPriceRange([])
            })
    }

    const fetchLastPrice = async (city, date) => {
        await API.getPrice(`/price?city_id=${city}&start=${date}&end=${date}`)
            .then(res => {
                if (res.data.length < 1) {
                    setLastPrice({ pinsar: 0, hargapangan: 0 })
                } else setLastPrice(res.data[0])
            }).catch((error) => {
                console.log(error.message)
                setLastPrice({ pinsar: 0, hargapangan: 0 })
            })
    }

    const fetchNearby = async (prov, date) => {
        await API.getPrice(`/nearby?province_id=${prov}&date=${date}`)
            .then(res => {
                setNearby(res.data)
            }).catch((error) => {
                console.log(error.message)
                setNearby([])
            })
    }

    const onAddCity = async (city) => {
        await API.post(addCity(city))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', res.data.errors[0].message)
                } else if (res.data.data.addCity !== null) {
                    setSavedCity(res.data.data.addCity.cities)
                }
            }).catch((err) => {
                errMessage('error', err.message)
            })

    }
    const onRemoveCity = async (e, city) => {
        e.preventDefault()
        await API.post(removeCity(city))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', res.data.errors[0].message)
                } else if (res.data.data.removeCity !== null) {
                    setSavedCity(res.data.data.removeCity.cities)
                }
            }).catch((error) => {
                errMessage('error', error.message)
            })
    }
    const onCity = (e) => {
        setSearch(false)
        setCityString(e)
        let city = cities.find(c => c.type + ' ' + c.name === e)
        form2.resetFields()
        setCity(city.city_id)
        setProv(city.province_id)
    }
    const onSelectCity = (v) => {
        setCity(v.value)
        setCityString(v.label.join(''))
        let province = cities.filter(c => c.city_id === v.value)
        setProv(province[0].province_id)
    }

    return (
        <DashboardComponent
            loading={loading} t={t} retention={retention} setSearch={setSearch} imageLoad={imageLoad} setImageLoad={getImageLoad}
            data={data} fetchDataThi={fetchDataThi} fetchDataEnv={fetchDataEnv} onSelectCity={onSelectCity} users={users}
            suhu={suhu} load={load} idDevice={idDevice} devices={devices} setCity={setCity} cityString={cityString}
            setData={setData} ages={ages} env={env} suhuReverse={suhuReverse} setProv={setProv} deviceType={typeDevice}
            active={active} chartData={chartData} role={role} setVisible={setVisible} lastPrice={lastPrice}
            report={report} feedUsage={feedUsage} typeUser={typeUser} city={city} onRemoveCity={onRemoveCity}
            setHumidity={setHumidity}
            navigate={props.navigate} CONSTANTS={CONSTANTS}

            isVoucher={isVoucher}
            giveVoucher={giveVoucher}
            onClaim={onClaim} form3={form3}

            thi={thiChart} handleExport={handleExport} form={form} companyName={companyName} NumberFormat={NumberFormat}
            performaChart={performa}
            isHumidity={isHumidity}

            onCity={onCity} arrCity={cities}
            setRangePrice={setRangePrice}
            showEdit={showEdit}
            onAddCity={onAddCity}
            savedCity={savedCity}
            form2={form2} nearby={nearby} setLastDatePrice={setLastDatePrice}
            range={range} visible={visible}

            rangePrice={rangePrice} priceRange={priceRange}
            now={now} setRange={setRange} handleSubmit={handleSubmit} isSearch={isSearch} lastDatePrice={lastDatePrice}
            fetchRangePrice={fetchRangePrice} setIsDate={setIsDate} isDate={isDate}

            onDevice={onDevice} profil={props.profil}
            invoices={invoices} fetchInvoices={fetchInvoices} setLoading={setLoadingRearing}
        />
    )
}

const mapStateToProps = state => ({
    idRearing: state.layout.idPeriode,
    serialNumber: state.layout.serialNumber,
    idKandang: state.layout.idKandang,
    idDevice: state.layout.idDevice,
    typeDevice: state.layout.deviceType,
    imageLoad: state.layout.imageLoad,
    role: state.profil.dataProfil.type,
    profil: state.profil.dataProfil,
    userCities: state.profil.dataProfil.cities,
    loading: state.layout.loadingRearing,
})

const mapDispatchToProps = (dispatch => ({
    navigate, getIdDevice, getDeviceType, getImageLoad, getListProfil, setLoadingRearing
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
export default page