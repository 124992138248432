/**
 * @author spindyzel
 * @since 28 Desember 2019
*/

/**
 * @modified vickyhermawan
 * @since 25 Juli 2020
 */

/**
 * @modified adinegara
 * @since September 2021
 */

import axios from 'axios'
import Cookies from 'universal-cookie'
import { BASE_URL, IOT_URL, PRICE_URL } from './initOptions.properties'
import { navigate } from './store/action'
import CONSTANTS from './utils/Constants'

const cookies = new Cookies()

export const API = {
    post: function (input) {
        let headers = {
            "Content-Type": "application/json",
        }
        let token = cookies.get('token')

        if (token != null)
            headers.Authorization = `Bearer ${token}`

        return axios.post(BASE_URL + '/graphql', JSON.stringify(input), { headers: headers })
            .then((response) => {
                if (typeof (response.data.errors) == 'undefined') {
                    return response
                } else {
                    if (response.data.errors[0].message === 'Unauthenticated') {
                        cookies.remove('token', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                        cookies.remove('loginTimes', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                        navigate(CONSTANTS.HOME_MENU_KEY)
                    } else {
                        return response
                    }
                }
            })
            .catch((error) => {
                return error.response
            })
    },

    get: function (query) {
        const q = {
            query: query
        }
        let headers = {
            "Content-Type": "application/json",
        }
        let token = cookies.get('token')

        if (token != null)
            headers.Authorization = `Bearer ${token}`

        return axios.post(BASE_URL + '/graphql', JSON.stringify(q), { headers: headers })
            .then(response => {
                if (typeof (response.data.errors) == 'undefined') {
                    return response
                } else {
                    if (response.data.errors[0].message === 'Unauthenticated') {
                        cookies.remove('token', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                        cookies.remove('loginTimes', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                        navigate(CONSTANTS.HOME_MENU_KEY)
                    } else {
                        return response
                    }
                }
            }).catch(error => {
                return error.response
            })
    },

    delete: function (query) {
        const q = {
            query: query
        };
        let headers = {
            "Content-Type": "application/json",
        }
        let token = cookies.get('token')

        if (token != null)
            headers.Authorization = `Bearer ${token}`

        return axios.post(BASE_URL + '/graphql', JSON.stringify(q), { headers: headers })
            .then(response => {
                if (typeof (response.data.errors) == 'undefined') {
                    return response
                } else {
                    if (response.data.errors[0].message === 'Unauthenticated') {
                        cookies.remove('token', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                        cookies.remove('loginTimes', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                        navigate(CONSTANTS.HOME_MENU_KEY)
                    } else {
                        return response
                    }
                }
            }).catch(error => {
                return error.response
            })
    },

    getRecord: function (endPoint, input) {
        let headers = {}
        let token = cookies.get('token')

        if (token != null)
            headers.Authorization = `Bearer ${token}`

        return axios.post(IOT_URL + endPoint, input, { headers: headers })
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            });
    },

    getRest: function (endPoint, input) {
        let headers = {}
        let token = cookies.get('token')

        if (token != null)
            headers.Authorization = `Bearer ${token}`

        return axios.get(BASE_URL + endPoint, input, { headers: headers })
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            })
    },

    postRest: function (endPoint, input) {
        let headers = {}
        let token = cookies.get('token')

        if (token != null)
            headers.Authorization = `Bearer ${token}`

        return axios.post(BASE_URL + endPoint, input, { headers: headers })
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            })
    },

    updateRest: function (endPoint, input) {
        let headers = {}
        let token = cookies.get('token')

        if (token != null)
            headers.Authorization = `Bearer ${token}`

        return axios.put(BASE_URL + endPoint, input, { headers: headers })
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            })
    },

    deleteRest: function (endPoint, input) {
        let headers = {}
        let token = cookies.get('token')
        if (token != null)
            headers.Authorization = `Bearer ${token}`

        let config = {
            headers: headers,
            params: input
        }

        return axios.delete(BASE_URL + endPoint, config)
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            })
    },
    getPrice: function (endPoint) {
        let token = cookies.get('token')
        let headers = {  Authorization: `Bearer ${token}`}

        return axios.get(PRICE_URL + endPoint, { headers: headers })
            .then((response) => {
                return response
            })
            .catch((error) => {
                return error.response
            })
    },
}