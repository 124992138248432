import React, { useState } from 'react'
import { Layout, Row, Col, Avatar, Form, Input, Card, Badge, Button, Upload, Modal } from 'antd'
import { API } from '../../../common/api'
import Loading from '../../../common/component/loading/loading-container'
import { UserOutlined, EditOutlined } from '@ant-design/icons'
import { faCheckCircle, faDoorClosed, faLockOpen, faUpload, faUserEdit, faUserTimes } from '@fortawesome/free-solid-svg-icons'
import ButtonDashboard from '../../../common/component/button/button-dashboard'
import { errMessage } from '../../../common/component/notification/notification'
import Autocomplete from "react-google-autocomplete"

function ProfilComponent(props) {
    const { Content } = Layout
    const { handleSubmit, handleFailed, loading, disabled, setDisabled, profil, getListProfil, getProfile,
        handleSubmitPassword, passChange, setPassChange, form, openFeedback, setOpenFeedback, hapusConfirm,
        deleteData, form2, loadingDelete, t } = props
    const [fileList, setFileList] = useState([])
    const [fileUpload, setFileUpload] = useState([])
    const [load, setLoad] = useState(false)
    const title = passChange === false ? `${t('pages.profile.changeProfile')}` : `${t('pages.profile.changePass')}`

    const propsPicture = {
        name: 'file',
        onChange(info) {
            if (info.file.size > 3000000) {
                errMessage('error', 'Ukuran file harus lebih kecil dari 3 MB!')
            } else if (info.file.status === 'done') {
                let arrayPicture = []
                for (let i = 0; i < info.fileList.length; i++) {
                    arrayPicture.push(info.fileList[i].originFileObj)
                }
                setFileList(info.fileList)
                setFileUpload(arrayPicture)
            } else if (info.file.status === 'error') {
                console.log('err', info.file, info.fileList)
            }
        }
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok")
        }, 0)
    }


    const postData = (fileUpload) => {
        const params = new FormData()
        if (fileUpload) {
            for (let i = 0; i < fileUpload.length; i++) {
                params.append(`file[${i}]`, fileUpload[i])
            }
        }
        API.postRest(`/upload-profile`, params)
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', res.data.errors[0].message)
                    setLoad(false)
                } else if (res.data.success === true) {
                    errMessage('success', `${t("pages.profile.resEditPicSuccess")}`)
                    setFileList([])
                    setFileUpload([])
                    getListProfil(getProfile())
                    setLoad(false)
                }
            }).catch((error) => {
                console.log(error)
                errMessage('error', error)
                setLoad(false)
            })
    }
    const submitPic = () => {
        postData(fileUpload)
        setLoad(true)
    }

    const onCancel = () => {
        setDisabled(true)
        form.setFieldsValue({
            namaLengkap: profil.name,
            email: profil.email,
            username: profil.username,
            phone: profil.phone,
            perusahaan: profil.company.name,
            alamat: profil.address,
            oldPass: '',
            newPass: ''
        })
    }

    return (
        <Layout>
            <Loading loading={loading}>
                <Content className="dashboard-container" style={{ minHeight: '100vh' }}>
                    <Card title={title} bordered={false}>
                        {passChange === false ? (
                            <Form onFinish={handleSubmit} onFinishFailed={handleFailed} name="profil" form={form}
                                initialValues={{
                                    remember: true,
                                    namaLengkap: profil.name,
                                    email: profil.email,
                                    username: profil.username,
                                    phone: profil.phone,
                                    perusahaan: profil.company.name,
                                    alamat: profil.address
                                }}
                            >
                                <Row className="dashboard-section" gutter={[16, 16]}>
                                    <Col lg={6} md={24} sm={24}>
                                        <Loading loading={load}>
                                            <Upload
                                                {...propsPicture} maxCount={1}
                                                customRequest={dummyRequest}
                                                onRemove={() => setFileList([])}
                                                showUploadList={fileList.length < 1 ? false : true}
                                                listType={fileList.length > 0 ? "picture-card" : false}
                                            >
                                                {fileList.length > 0 ? '' :
                                                    <Badge count={<Button shape="circle" size='small' icon={<EditOutlined />} />}
                                                        hidden={fileList.length < 1 ? false : true}>
                                                        <Avatar shape="square" src={profil.foto ? profil.foto : ''}
                                                            icon={profil.foto ? '' : <UserOutlined />} size={200} />
                                                    </Badge>
                                                }
                                            </Upload>
                                            {fileList.length > 0 &&
                                                <ButtonDashboard
                                                    text='Upload'
                                                    height={20} size='small'
                                                    backgroundColor="#008ad4"
                                                    borderRadius="5px"
                                                    className="font-semi-medium font-content-title button-edit"
                                                    textColor="white"
                                                    icon={faUpload}
                                                    onClick={submitPic}
                                                />
                                            }
                                        </Loading>
                                    </Col>
                                    <Col lg={18} md={24} sm={24} style={{ marginTop: "15px" }}>
                                        <div className="dashboard-section-profil-guskom-display-konten">
                                            <Row gutter={8}>
                                                <Col lg={12} md={24} sm={24}>
                                                    <span className="font-title-content black font-semi-bold">{t('pages.profile.name')}</span>
                                                    <Form.Item
                                                        name="namaLengkap" rules={[{ required: true, message: `${t('pages.profile.msgName')}` }]}
                                                    >
                                                        <Input
                                                            placeholder={t('pages.profile.phName')}
                                                            className="input-rearing mt-5"
                                                            disabled={disabled}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={12} md={24} sm={24}>
                                                    <span className="font-title-content black font-semi-bold">Email</span>
                                                    <Form.Item
                                                        name="email" rules={[{ required: true, message: `${t('pages.profile.msgEmail')}` }]}
                                                    >
                                                        <Input
                                                            placeholder={t('pages.profile.phEmail')}
                                                            className="input-rearing mt-5"
                                                            disabled={disabled}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={8}>
                                                <Col lg={12} md={24} sm={24}>
                                                    <span className="font-title-content black font-semi-bold">Username</span>
                                                    <Form.Item
                                                        name="username" rules={[{ required: true, message: `${t('pages.profile.msgUsername')}` }]}
                                                    >
                                                        <Input
                                                            placeholder={t('pages.profile.phUsername')}
                                                            className="input-rearing mt-5"
                                                            disabled={disabled}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={12} md={24} sm={24}>
                                                    <span className="font-title-content black font-semi-bold">{t('pages.profile.phone')}</span>
                                                    <Form.Item
                                                        name="phone" rules={[{ required: true, message: `${t('pages.profile.msgPhone')}` }]}
                                                    >
                                                        <Input
                                                            placeholder="085721433322.."
                                                            className="input-rearing mt-5"
                                                            disabled={disabled}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="dashboard-section" gutter={16}>
                                    <Col lg={24} md={24} sm={24}>
                                        <Row>
                                            <Col lg={24} md={24} sm={24}>
                                                <span className="font-title-content black font-semi-bold">{t('pages.profile.company')}</span>
                                                <Form.Item
                                                    name="perusahaan" rules={[{ required: true, message: `${t('pages.profile.msgCompany')}` }]}
                                                >
                                                    <Input
                                                        placeholder={t('pages.profile.phCompany')}
                                                        className="input-rearing mt-5"
                                                        disabled={profil.type === 'staff' || profil.type === 'management' ? true : disabled}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={24} md={24} sm={24}>
                                                <span className="font-title-content black font-semi-bold">{t('pages.profile.address')}</span>
                                                <Form.Item
                                                    name="alamat" rules={[{ required: true, message: `${t('pages.profile.msgAddress')}` }]}
                                                >
                                                    <Autocomplete
                                                        placeholder={t('pages.profile.phAddress')}
                                                        disabled={disabled}
                                                        className='ant-input input-register'
                                                        apiKey={process.env.REACT_APP_GOOGLE_TOKEN}
                                                        onPlaceSelected={place => {
                                                            form.setFieldsValue({ idAddr: place.place_id, alamat: place.formatted_address })
                                                        }}
                                                        options={{
                                                            types: ["geocode", "establishment"],
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col hidden>
                                                <Form.Item name="idAddr" >
                                                    <Input className="input-register" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                {disabled === true && passChange === false ? (
                                    <Row className="dashboard-section" gutter={[16, 16]} >
                                        <Col lg={4} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('pages.profile.changeProfile')}
                                                height={20}
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faUserEdit}
                                                onClick={() => setDisabled(false)}
                                            />
                                        </Col>
                                        <Col lg={4} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('pages.profile.changePass')}
                                                height={20}
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faLockOpen}
                                                onClick={() => setPassChange(true)}
                                            />
                                        </Col>
                                        <Col lg={4} md={24} sm={24}>
                                            <ButtonDashboard
                                                text='Hapus Akun'
                                                height={20}
                                                backgroundColor="red"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faUserTimes}
                                                onClick={() => hapusConfirm()}
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row className="dashboard-section" gutter={[16, 16]}>
                                        <Col lg={3} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('general.cancel')}
                                                height={20}
                                                backgroundColor="#bdc2c9"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faDoorClosed}
                                                onClick={passChange === false ? () => onCancel() : () => setPassChange(false)}
                                            />
                                        </Col>
                                        <Col lg={4} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('general.save')}
                                                height={20}
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faCheckCircle}
                                                htmlType="submit"
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Form>
                        ) : (
                            <Form onFinish={handleSubmitPassword} onFinishFailed={handleFailed} name="pass" form={form}>
                                <Row className="dashboard-section" gutter={[16, 16]}>
                                    <Col lg={12} md={24} sm={24}>
                                        <span className="font-title-content black font-semi-bold">{t('pages.profile.oldPass')}</span>
                                        <Form.Item
                                            name="oldPass" rules={[{ required: true, message: `${t('pages.profile.msgOldPass')}` }]}
                                        >
                                            <Input.Password
                                                placeholder={t('pages.profile.phOldPass')}
                                                className="input-password mt-5"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={12} md={24} sm={24}>
                                        <span className="font-title-content black font-semi-bold">{t('pages.profile.newPass')}</span>
                                        <Form.Item
                                            name="newPass" rules={[{ required: true, min: 6, message: `${t('pages.profile.msgNewPass')}` }]}
                                        >
                                            <Input.Password
                                                placeholder={t('pages.profile.phNewPass')}
                                                className="input-password mt-5"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {disabled === true && passChange === false ? (
                                    <Row className="dashboard-section" gutter={[16, 16]}>
                                        <Col lg={4} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('pages.profile.changeProfile')}
                                                height={20}
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faUserEdit}
                                                onClick={() => setDisabled(false)}
                                            />
                                        </Col>
                                        <Col lg={4} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('pages.profile.changePass')}
                                                height={20}
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faLockOpen}
                                                onClick={() => setPassChange(true)}
                                            />
                                        </Col>
                                        <Col lg={4} md={24} sm={24}>
                                            <ButtonDashboard
                                                text='Hapus Akun'
                                                height={20}
                                                backgroundColor="red"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faUserTimes}
                                                onClick={() => hapusConfirm()}
                                            />
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row className="dashboard-section" gutter={[16, 16]}>
                                        <Col lg={3} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('general.cancel')}
                                                height={20}
                                                backgroundColor="#bdc2c9"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faDoorClosed}
                                                onClick={passChange === false ? () => onCancel() : () => setPassChange(false)}
                                            />
                                        </Col>
                                        <Col lg={4} md={24} sm={24}>
                                            <ButtonDashboard
                                                text={t('general.save')}
                                                height={20}
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faCheckCircle}
                                                htmlType="submit"
                                            />
                                        </Col>
                                    </Row>
                                )}
                            </Form>
                        )}
                    </Card>
                </Content>

                <Modal
                    visible={openFeedback}
                    title='Kenapa Anda mau menghapus akun Anda?'
                    okText='Kirim dan Hapus Akun Saya'
                    width={500} centered
                    cancelText={t('general.cancel')}
                    onCancel={() => setOpenFeedback(false)}
                    onOk={() => {
                        form2
                            .validateFields()
                            .then((values) => {
                                form2.resetFields()
                                deleteData(values)
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info)
                            })
                    }}
                >
                    <Loading loading={loadingDelete}>

                        <Form
                            form={form2}
                            layout="vertical"
                            name="form_in_modal"
                        >
                            <Row gutter={[16, 8]}>
                                <Col span={24}>
                                    <span className="black font-semi-bold font-title-content" >Tulis alasan</span>
                                    <Form.Item
                                        name="feedback" rules={[{ required: true }]}
                                    >
                                        <Input.TextArea
                                            placeholder="Saya ingin menghapus akun karena ..."
                                            style={{ borderColor: "#BE375F" }}
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Loading>

                </Modal>
            </Loading>
        </Layout>
    )
}

export default ProfilComponent