import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Row, Col, Input, Select, Layout, Button, Breadcrumb, Card } from 'antd'
import { useParams } from 'react-router-dom'

import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import CONSTANTS from '../../../../../common/utils/Constants'
import Loading from '../../../../../common/component/loading/loading-container'

import { createStokOvk } from './query-stok-ovk'
import { useTranslation } from 'react-i18next'
import { getIdStokOvk } from '../../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/store/stok-ovk-action'

function CreateStokOvkPage(props) {
    const { ovk, navigate, getIdStokOvk } = props
    const { Option } = Select
    const { Content } = Layout
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const params = useParams()

    const postData = async (values) => {
        await API.post(createStokOvk(params.ovkWarehouseId, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.ovkStock.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createOvkStock._id) {
                    validationMessage('success', `${t('pages.ovkStock.resAddSuccess')}`)
                    getIdStokOvk(res.data.data.createOvkStock._id)
                    props.navigate(CONSTANTS.STOK_OVK_MENU_KEY, `${params.ovkWarehouseId}/transaksi/${res.data.data.createOvkStock._id}`)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.ovkStock.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        setLoading(true)
        postData(values)
    }

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to={`/peternak/stok-ovk/${params.ovkWarehouseId}`}> <span>{t('pages.ovkStock.title')}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{t('pages.ovkStock.add')}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Loading loading={loading}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card bordered={false}>
                                <Form onFinish={handleSubmit} initialValues={{ namaPengadaan: '' }}>
                                    <Row gutter={[16, 8]}>
                                        <Col lg={12} sm={24}>
                                            <span className="black font-semi-bold font-title-content">{t('pages.ovkStock.ovk')}</span>
                                            <Form.Item
                                                name="ovk" rules={[{ required: true, message: `${t('pages.ovkStock.msgOvk')}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={t('pages.ovkStock.phOvk')}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular"
                                                >
                                                    {
                                                        ovk.map(data =>
                                                            <Option
                                                                key={data._id}
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col lg={12} sm={24}>
                                            <span className="font-semi-bold black font-title-medium">{t('pages.ovkStock.name')}</span>
                                            <Form.Item
                                                name="namaPengadaan" rules={[{ required: false, message: `${t('pages.ovkStock.msgName')}` }]}
                                            >
                                                <Input
                                                    placeholder={t('pages.ovkStock.phName')}
                                                    className="input-form"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button
                                        onClick={() => navigate(CONSTANTS.STOK_OVK_MENU_KEY, params.ovkWarehouseId)}
                                    >
                                        {t('general.back')}
                                    </Button>
                                    <Button
                                        style={{ marginLeft: 8, marginTop: 0 }}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {t('general.add')}
                                    </Button>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    ovk: state.ovk.dataOvk,
    gudang: state.gudangOvk.dataGudangOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdStokOvk
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStokOvkPage)
export default page