import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Tooltip, Button, Modal, Input, Layout, Breadcrumb, Row, Col, Table, Tabs, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faTrash, faInfoCircle, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import ModalCreateStokPakan from './stok-pakan-aksi-page/create-stok-pakan-page'
import ModalTambahStokPakan from './stok-pakan-aksi-page/tambah-stok-pakan-page'
import ModalEditStokPakan from './stok-pakan-aksi-page/edit-stok-pakan-page'

import { getListPakan } from '../../../../modules/dashboard-peternak/master-component/pakan-component/store/pakan-action'
import { getNamaGudangPakan } from '../../../../modules/dashboard-peternak/master-component/gudang-pakan-component/store/gudang-pakan-action'
import { getPakan } from '../../../../app/dashboard-peternak/master-page/pakan-page/pakan-aksi-page/query-pakan'
import { getIdStokPakan, getBeratPakan } from '../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/store/stok-pakan-action'
import { getStokGudangPakan, getStokPakan, deleteStokPakan, addStokPakan, detailStokPakan, updateStokPakan, getHistoryGudangPakan } from './stok-pakan-aksi-page/query-stok-pakan'

import { navigate } from '../../../../common/store/action'
import { API } from '../../../../common/api'
import Loading from '../../../../common/component/loading/loading-container'
import { validationMessage } from '../../../../common/component/notification/notification'
import { isMobileOnly } from 'react-device-detect'
import { useParams } from 'react-router-dom'
import { setLoadingFeedWh } from '../../../../modules/dashboard-peternak/layout-component/store/layout-action'

function StokPakanPage(props) {
    const { getListPakan, getIdStokPakan, getBeratPakan, getNamaGudangPakan, profil, loading, setLoadingFeedWh } = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [visibleEdit, setVisibleEdit] = useState(false)
    const [object, setObject] = useState([])
    const [histories, setHistories] = useState([])
    const [idData, setIdData] = useState(null)
    const [stockId, setStockId] = useState(null)
    const [detail, setDetail] = useState(null)
    const [activeKey, setActiveKey] = useState("1")
    const params = useParams()

    const { t } = useTranslation()
    const { TabPane } = Tabs

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = async (idGudangPakan) => {
        setLoadingFeedWh(true)
        await API.get(getStokGudangPakan(idGudangPakan))
            .then(res => {
                setObject(res.data.data.feedStocksWarehouse)
                setLoadingFeedWh(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoadingFeedWh(false)
            })
    }

    const fetchDataAll = async () => {
        setLoadingFeedWh(true)
        await API.get(getStokPakan('', 0, 0))
            .then(res => {
                setObject(res.data.data.feedStocks.feedStocks)
                setLoadingFeedWh(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoadingFeedWh(false)
            })
    }

    const fetchDataHistory = async (idGudangPakan, type) => {
        if (idGudangPakan === 'Semua') {
            setHistories([])
        }
        setLoadingFeedWh(true)
        await API.get(getHistoryGudangPakan(idGudangPakan, type))
            .then(res => {
                setHistories(res.data.data.feedingsWarehouse.feedings)
                setLoadingFeedWh(false)
            }).catch((error) => {
                console.log(error)
                setHistories([])
                setLoadingFeedWh(false)
            })
    }

    useEffect(() => {
        if (params.feedWarehouseId === 'Semua' && activeKey === "1") {
            fetchDataAll()
        }
         // eslint-disable-next-line
    }, [activeKey, params.feedWarehouseId])

    const changeKey = useCallback((value) => {
        setActiveKey(value)
    }, [])

    useEffect(() => {
        if (params.feedWarehouseId) {
            if (activeKey === "1") {
                fetchData(params.feedWarehouseId)
            } else if (activeKey === '2') {
                fetchDataHistory(params.feedWarehouseId, 'In')
            } else {
                fetchDataHistory(params.feedWarehouseId, 'Out')
            }
        }
         // eslint-disable-next-line
    }, [activeKey, params.feedWarehouseId])

    useEffect(() => {
        getListPakan(getPakan('', 0, 0))
    }, [activeKey, getListPakan])

    const deleteData = async (_id) => {
        setLoadingFeedWh(true)
        await API.delete(deleteStokPakan(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.feedStock.resDelFailed')}`, res.data.errors[0].message)
                    setLoadingFeedWh(false)
                }
                else if (res.data.data.deleteFeedStock.deleted === true) {
                    validationMessage('success', `${t('pages.feedStock.resDelSuccess')}`)
                    fetchData(params.feedWarehouseId)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.feedStock.resDelFailed')}`, error.message)
                setLoadingFeedWh(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.feedStock.delConfirm')} ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const showModal = useCallback((stockId) => {
        setStockId(stockId)
        setVisible(true)
    }, [])

    const postData = async (values, year) => {
        await API.post(addStokPakan(values, year, stockId))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.feedStock.resAddFailed')}`, res.data.errors[0].message)
                    setLoadingFeedWh(false)
                }
                else if (res.data.data.createFeeding._id) {
                    validationMessage('success', `${t('pages.feedStock.resAddSuccess')}`)
                    fetchData(params.feedWarehouseId)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.feedStock.resAddFailed')}`, error.message)
                setLoadingFeedWh(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, values['date_picker'].format('MM/DD/YYYY'))
        setVisible(false)
        setLoadingFeedWh(true)
    }

    const handleCancel = useCallback((value) => {
        setVisible(false)
    }, [])

    const detailTranskasi = useCallback((value, berat, gudang) => {
        getIdStokPakan(value)
        getBeratPakan(berat)
        getNamaGudangPakan(gudang)
        props.history.replace(`/peternak/stok-pakan/${params.feedWarehouseId}/transaksi/${value}`)
    }, [props.history, getIdStokPakan, getBeratPakan, getNamaGudangPakan, params.feedWarehouseId])

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(detailStokPakan(idData))
                    .then(res => {
                        setDetail(res.data.data.feedStock)
                        setVisibleEdit(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setLoadingFeedWh(false)
                    })
            }
        }
        getDetailData()
         // eslint-disable-next-line
    }, [idData])

    const showEditStok = useCallback((value) => {
        if (value === idData)
            setVisibleEdit(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancelEdit = useCallback(() => {
        setVisibleEdit(false)
    }, [])

    const updatedData = async (values) => {
        await API.post(updateStokPakan(idData, params.feedWarehouseId, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.feedStock.resEditFailed')}`, res.data.errors[0].message)
                    setLoadingFeedWh(false)
                }
                else if (res.data.data.updateFeedStock._id) {
                    validationMessage('success', `${t('pages.feedStock.resEditSuccess')}`)
                    fetchData(params.feedWarehouseId)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.feedStock.resEditFailed')}`, error.message)
                setLoadingFeedWh(false)
            })
    }

    const handleSubmitEdit = (values) => {
        updatedData(values)
        setVisibleEdit(false)
        setIdData(null)
        setLoadingFeedWh(true)
    }

    const columns = [

        {
            title: `${t('pages.feedStock.feed')}`,
            dataIndex: 'pakan',
            key: 'pakan',
            width: 150,
            ...getColumnSearchProps('pakan'),
        },
        {
            title: `${t('pages.feedStock.weight')}`,
            dataIndex: 'berat',
            key: 'berat',
            width: 80,
            align: 'right'
        },
        {
            title: `${t('pages.feedStock.balance')} (kg)`,
            dataIndex: 'total',
            key: 'total',
            width: 90,
            align: 'right'
        },
        {
            title: `${t('pages.feedStock.balance')} (${t('pages.feedStock.sack')})`,
            dataIndex: 'karung',
            key: 'karung',
            width: 90,
            align: 'right'
        },
        {
            title: `${t('pages.feedStock.name')}`,
            dataIndex: 'nama',
            key: 'nama',
            width: 120,
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: '15rem',
            fixed: !isMobileOnly && 'right',
            align: 'center',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('pages.feedStock.addStock')}>
                        <Button
                            hidden={profil.permission.writeStock ? false : true}
                            style={{
                                color: 'white',
                                backgroundColor: '#0f6cff',
                                borderRadius: '5px'
                            }} onClick={() => showModal(row.key, row.feedingId, row.feedId)}><FontAwesomeIcon icon={faPlus} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button
                            hidden={profil.permission.writeStock ? false : true}
                            style={{
                                color: 'white',
                                backgroundColor: '#FFA903',
                                borderRadius: '5px'
                            }} onClick={() => showEditStok(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.detail')}>
                        <Button
                            style={{
                                color: 'white',
                                backgroundColor: '#00923F',
                                borderRadius: '5px'
                            }} onClick={() => detailTranskasi(row.key, row.weight, row.gudang)}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button
                            hidden={profil.permission.writeStock ? false : true}
                            style={{
                                color: 'white',
                                backgroundColor: '#FF0303',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = object.map(({ _id, nameStock, feedWarehouse, feed, feeding, weight, sisaStok }, index) => ({
        key: _id,
        pakan: params.feedWarehouseId === 'Semua' ? `${feedWarehouse.name} - ${feed.name}` : feed.name,
        nama: nameStock,
        berat: <NumberFormat value={weight} displayType={'text'} thousandSeparator={true} suffix={' kg'} />,
        perusahaan: feed.producer,
        gudang: feedWarehouse.name,
        total: <NumberFormat value={sisaStok?.kg.toFixed(0)} displayType={'text'} thousandSeparator={true} suffix={' kg'} />,
        karung: <NumberFormat value={sisaStok?.karung.toFixed(0)} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.feedStock.sack')}`} />,
        feedingId: feeding[0]?._id,
        feedId: feed._id,
        weight: weight,
    }))

    const columnsHistory = [
        {
            title: `${t('pages.feedStock.trxDate')}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            width: 300,
            ...getColumnSearchProps('tanggal'),
            render: (value, row, index) => (
                <>{value} {row.type === 'Mutation' && <Tag color='green'>{t('pages.feedStock.mutation')}</Tag>}</>
            )
        },
        {
            title: `${t('pages.feedStock.feed')}`,
            dataIndex: 'pakan',
            key: 'pakan',
            width: 150,
            align: 'center',
            ...getColumnSearchProps('pakan'),
        },
        {
            title: `${t('pages.feedStock.priceFeed')}`,
            dataIndex: 'harga',
            key: 'harga',
            align: 'right',
            ...getColumnSearchProps('price'),
        },
        {
            title: `${t('pages.feedStock.qty')}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            align: 'right',
            ...getColumnSearchProps('total'),
        },
        {
            title: `${t('pages.feedStock.qtyWeight')}`,
            dataIndex: 'jumlahWeight',
            key: 'jumlahWeight',
            align: 'right',
            ...getColumnSearchProps('weight'),
        },
        {
            title: `${t('pages.feedStock.docNumber')}`,
            dataIndex: 'ttb',
            key: 'ttb',
            align: 'left',
            ...getColumnSearchProps('ttb'),
        },
        {
            title: `Kandang`,
            dataIndex: 'house',
            key: 'house',
            width: 250,
            ...getColumnSearchProps('house'),
        },
    ]

    const dataHistory = histories.map(({ _id, number, numberWeight, storeTime, priceFeeding, ttb, typeFeeding, feedStock, mutationFeedStock }, index) => ({
        key: _id,
        pakan: feedStock.feed.name,
        price: priceFeeding,
        total: number,
        weight: numberWeight,
        jumlah: <NumberFormat value={number} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.feedStock.sack')}`} />,
        jumlahWeight: <NumberFormat value={numberWeight} displayType={'text'} thousandSeparator={true} suffix={' kg'} />,
        tanggal: moment(new Date(parseInt(storeTime))).format("DD MMMM YYYY"),
        harga: <NumberFormat value={priceFeeding} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />,
        ttb: ttb,
        type: typeFeeding,
        house: typeFeeding === 'Mutation' ?
            <span>{mutationFeedStock?.feedWarehouse?.houses[0]?.name}
                <br />Asal: {feedStock?.feedWarehouse?.name}</span>
            :
            feedStock?.feedWarehouse?.houses[0]?.name,
    }))
    const columnsOut = [
        {
            title: `${t('pages.feedStock.trxDate')}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            ...getColumnSearchProps('tanggal'),
            render: (value, row, index) => (
                <>{value} {row.type === 'Mutation' && <Tag color='green'>{t('pages.feedStock.mutation')}</Tag>}</>
            )
        },
        {
            title: `${t('pages.feedStock.feed')}`,
            dataIndex: 'pakan',
            key: 'pakan',
            width: 150,
            align: 'center',
            ...getColumnSearchProps('pakan'),
        },
        {
            title: `${t('pages.feedStock.qty')}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            align: 'right',
            ...getColumnSearchProps('jumlah'),
        },
        {
            title: `${t('pages.feedStock.qtyWeight')}`,
            dataIndex: 'jumlahWeight',
            key: 'jumlahWeight',
            align: 'right',
            ...getColumnSearchProps('jumlahWeight'),
        },
        {
            title: `Kandang`,
            dataIndex: 'house',
            key: 'house',
            align: 'center',
            ...getColumnSearchProps('house'),
        },
    ]

    const dataOut = histories.map(({ _id, number, numberWeight, storeTime, typeFeeding, feedStock, mutationFeedStock, house }, index) => ({
        key: _id,
        pakan: feedStock.feed.name,
        total: number,
        weight: numberWeight,
        jumlah: <NumberFormat value={number} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.feedStock.sack')}`} />,
        jumlahWeight: <NumberFormat value={numberWeight} displayType={'text'} thousandSeparator={true} suffix={' kg'} />,
        tanggal: moment(new Date(parseInt(storeTime))).format("DD MMMM YYYY"),
        type: typeFeeding,
        house: typeFeeding === 'Mutation' ?
            <span>{house?.name}
                <br />Destinasi: {mutationFeedStock?.feedWarehouse?.name}</span>
            : house?.name,
    }))

    return (
        <Layout>
            <Layout.Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col lg={12} sm={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.feedStock.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={12} sm={24}>
                        {profil.permission.writeStock ?
                            <ModalCreateStokPakan fetchData={fetchData} idGudangPakan={params.feedWarehouseId}
                                setLoading={setLoadingFeedWh} t={t} /> : <></>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                <TabPane tab={'Jenis Pakan'} key="1">
                                    <Loading loading={loading}>
                                        <Table
                                            columns={columns}
                                            dataSource={data}
                                            bordered
                                            pagination={false}
                                            className="table-dashboard"
                                            scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                                        />
                                    </Loading>
                                </TabPane>
                                <TabPane tab={'History Penambahan'} key="2">
                                    <Loading loading={loading}>
                                        <Table
                                            columns={columnsHistory}
                                            dataSource={dataHistory}
                                            bordered
                                            pagination={false}
                                            className="table-dashboard"
                                            scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                                        />
                                    </Loading>
                                </TabPane>
                                <TabPane tab={'History Penggunaan'} key="3">
                                    <Loading loading={loading}>
                                        <Table
                                            columns={columnsOut}
                                            dataSource={dataOut}
                                            bordered
                                            pagination={false}
                                            className="table-dashboard"
                                            scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                                        />
                                    </Loading>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                    <ModalTambahStokPakan visible={visible} handleSubmit={handleSubmit}
                        handleCancel={handleCancel} t={t} />
                    <ModalEditStokPakan visibleEdit={visibleEdit} handleSubmit={handleSubmitEdit}
                        handleCancel={handleCancelEdit} detail={detail} t={t} />
                </Row>
            </Layout.Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    beratPakan: state.stokPakan.beratPakan,
    role: state.profil.dataProfil.type,
    profil: state.profil.dataProfil,
    loading: state.layout.loadingFeedWh,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListPakan,
    getIdStokPakan,
    getBeratPakan,
    getNamaGudangPakan,
    setLoadingFeedWh,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(StokPakanPage)
export default page