export const stockflow = {
        feed: 'Pakan',
        ovk: 'OVK',
        livebird: 'Livebird',
        period: 'Periode',
        resume: 'Resume',
        qty: 'Qty',
        qtyLivebird: 'Qty (ekor)',
        hs: 'HS (Rp)',
        hsLivebird: 'HS (Rp/ekor)',
        total: 'Nilai (Rp)',
        saw: 'Saldo Awal',
        in: 'Masuk',
        out: 'Keluar',
        adj: 'Penyesuaian',
        sak: 'Saldo Akhir',
        feedInitialBalance: 'Saldo Awal Pakan',
        ovkInitialBalance: 'Saldo Awal OVK',
        livebirdInitialBalance: 'Saldo Awal Livebird',
        adjFeed: 'Penyesuaian Pakan',
        adjOvk: 'Penyesuaian OVK',
        addFeed: 'Tambah Periode',
        addOvk: 'Tambah Periode OVK',
        addLivebird: 'Tambah Periode Livebird',
        editSawFeed: 'Edit Saldo Awal Pakan',
        editSawOvk: 'Edit Saldo Awal OVK',
        editSawLivebird: 'Edit Saldo Awal Livebird',
        addFeedAdj: 'Tambah Penyesuaian Pakan',
        addOvkAdj: 'Tambah Penyesuaian OVK',
        editFeedAdj: 'Edit Penyesuaian Pakan',
        editOvkAdj: 'Edit Penyesuaian OVK',
        dateFeedPeriod: 'Bulan Periode',
        dateOvkPeriod: 'Bulan Periode OVK',
        dateLivebirdPeriod: 'Bulan Periode Livebird',
        phDatePeriod: 'Pilih bulan periode',
        phFeed: 'Pilih pakan',
        phOvk: 'Pilih OVK',
        phPeriod: 'Pilih Periode',
        msgQty: "Mohon masukkan jumlah!",
        msgPrice: "Mohon masukkan harga!",
        house: 'Kandang',
        quantity: 'Jumlah',
        price: 'Harga (Rp)',
        difference: 'Selisih',
        all: 'Semua',
        status: 'Status',
        locked: 'Terkunci',
        unlocked: 'Belum Dikunci',
        depletion: 'Deplesi',
        culling: 'Culling',
        harvest: 'Panen',
        addFeedConfirm: 'Apakah Anda yakin akan menambahkan periode otomatis?',
        addOvkConfirm: 'Apakah Anda yakin akan menambahkan periode OVK otomatis?',
        addLivebirdConfirm: 'Apakah Anda yakin akan menambahkan periode livebird otomatis?',
        lockFeedConfirm: 'Apakah Anda yakin akan mengunci periode',
        lockOvkConfirm: 'Apakah Anda yakin akan mengunci periode OVK',
        lockLivebirdConfirm: 'Apakah Anda yakin akan mengunci periode livebird pada',
        unlockFeedConfirm: 'Apakah Anda yakin akan membuka periode',
        unlockOvkConfirm: 'Apakah Anda yakin akan membuka periode OVK pada',
        unlockLivebirdConfirm: 'Apakah Anda yakin akan membuka periode livebird pada',
        delFeedConfirm: 'Apakah Anda yakin akan menghapus periode',
        delOvkConfirm: 'Apakah Anda yakin akan menghapus periode OVK pada',
        delAdjFeedConfirm: "Apakah anda yakin ingin menghapus penyesuaian pakan ini?",
        delAdjOvkConfirm: "Apakah anda yakin ingin menghapus penyesuaian OVK ini?",
        delLivebirdConfirm: 'Apakah Anda yakin akan menghapus periode livebird pada',
        resAddFeedSuccess: "Berhasil menambahkan periode",
        resLockFeedSuccess: "Berhasil menutup periode",
        resRelockFeedSuccess: "Berhasil menutup periode kembali",
        resUnlockFeedSuccess: "Berhasil membuka periode",
        resEditFeedSuccess: "Berhasil memperbarui saldo awal pakan",
        resDelFeedSuccess: "Berhasil menghapus periode",
        resAddAdjFeedSuccess: "Berhasil menambahkan penyesuaian pakan",
        resEditAdjFeedSuccess: "Berhasil mengubah penyesuaian pakan",
        resDelAdjFeedSuccess: "Berhasil menghapus penyesuaian pakan",
        resAddFeedFailed: "Gagal menambahkan periode",
        resLockFeedFailed: "Gagal menutup periode",
        resUnlockFeedFailed: "Gagal membuka periode",
        resEditFeedFailed: "Gagal memperbarui saldo awal pakan",
        resDelFeedFailed: "Gagal menghapus periode",
        resAddAdjFeedFailed: "Gagal menambahkan penyesuaian pakan",
        resEditAdjFeedFailed: "Gagal mengubah penyesuaian pakan",
        resDelAdjFeedFailed: "Gagal menghapus penyesuaian pakan",
        resAddOvkSuccess: "Berhasil menambahkan periode OVK",
        resLockOvkSuccess: "Berhasil menutup periode OVK",
        resRelockOvkSuccess: "Berhasil menutup periode OVK kembali",
        resUnlockOvkSuccess: "Berhasil membuka periode OVK",
        resEditOvkSuccess: "Berhasil memperbarui saldo awal OVK",
        resDelOvkSuccess: "Berhasil menghapus periode OVK",
        resAddAdjOvkSuccess: "Berhasil menambahkan penyesuaian OVK",
        resEditAdjOvkSuccess: "Berhasil mengubah penyesuaian OVK",
        resDelAdjOvkSuccess: "Berhasil menghapus penyesuaian OVK",
        resAddOvkFailed: "Gagal menambahkan periode OVK",
        resLockOvkFailed: "Gagal menutup periode OVK",
        resUnlockOvkFailed: "Gagal membuka periode OVK",
        resEditOvkFailed: "Gagal memperbarui saldo awal OVK",
        resDelOvkFailed: "Gagal menghapus periode OVK",
        resAddAdjOvkFailed: "Gagal menambahkan penyesuaian OVK",
        resEditAdjOvkFailed: "Gagal mengubah penyesuaian OVK",
        resDelAdjOvkFailed: "Gagal menghapus penyesuaian OVK",
        resAddLivebirdSuccess: "Berhasil menambahkan periode live bird",
        resLockLivebirdSuccess: "Berhasil menutup periode live bird",
        resRelockLivebirdSuccess: "Berhasil menutup periode live bird kembali",
        resUnlockLivebirdSuccess: "Berhasil membuka periode live bird",
        resEditLivebirdSuccess: "Berhasil memperbarui saldo awal live bird",
        resDelLivebirdSuccess: "Berhasil menghapus periode live bird",
        resAddLivebirdFailed: "Gagal menambahkan periode live bird",
        resLockLivebirdFailed: "Gagal menutup periode live bird",
        resUnlockLivebirdFailed: "Gagal membuka periode live bird",
        resEditLivebirdFailed: "Gagal memperbarui saldo awal live bird",
        resDelLivebirdFailed: "Gagal menghapus periode live bird"
}