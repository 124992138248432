import {sidebar} from "./en/sidebar"
import {general} from "./en/general"
import {error} from "./en/error"
import {subscription} from "./en/subscription"
import {auth} from "./en/auth"
import {tour} from "./en/tour"
import {dashboard} from "./en/dashboard";
import {master} from "./en/master";
import {rearing} from "./en/rearing";
import {recording} from "./en/recording";
import {feedStock} from "./en/feedStock"
import {ovkStock} from "./en/ovkStock";
import {materialStock} from "./en/materialStock";
import {monitor} from "./en/monitor";
import {stockflow} from "./en/stockflow";
import {profile} from "./en/profile";

export const TRANSLATIONS_EN = {
    welcome: "Welcome",
    general: general,
    error: error,
    sidebar: sidebar,
    pages: {
        auth: auth,
        tour: tour,
        dashboard: dashboard,
        master: master,
        rearing: rearing,
        recording: recording,
        feedStock: feedStock,
        ovkStock: ovkStock,
        materialStock: materialStock,
        monitor: monitor,
        stockflow: stockflow,
        profile: profile,
        subscription: subscription
    }
}