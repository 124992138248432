export const getStokPakan = (keyword, limit, skip) => {
    return (
        `query{
            feedStocks(keyword: "${keyword}", limit:${limit}, skip:${skip}){
                totalCount
                feedStocks{
                _id
                nameStock :name
                weight
                sisaStok{
                    kg
                    karung
                }
                company{
                    name
                }
                    feed{
                        _id
                        name
                        type
                        producer
                    }
                    feedWarehouse{
                        _id
                        name
                    }
                    feeding {
                        _id
                        number
                    }
                }
                
            }
        }
    `
    )
}

export const getStokGudangPakan = (idGudangPakan) => {
    return (
        `query{
            feedStocksWarehouse(warehouseId: "${idGudangPakan}"){
            _id
            nameStock :name
            sisaStok{
                kg
                karung
            }
            weight
            feed{
                _id
                name
                type
                producer
            }
            feedWarehouse{
                _id
                name
            }
            feeding {
                _id
                number
            }
        }    
    }
    `
    )
}

export const getHistoryGudangPakan = (idGudangPakan, type) => {
    return (
        `query{
            feedingsWarehouse(feedWarehouse: "${idGudangPakan}", type: "${type}"){
                totalCount
                feedings{
                    _id
                    typeFeeding
                    priceFeeding
                    ttb
                    number
                    numberWeight
                    storeTime
                    house{
                        _id
                        name
                    }
                    feedStock{
                        _id
                        feed{
                            _id
                            name
                        }
                        feedWarehouse{
                            name
                            houses{
                                _id
                                name
                            }
                        }
                    }
                    mutationFeedStock{
                        name
                        feedWarehouse{
                            name
                            houses{
                                _id
                                name
                            }
                        }
                    }
                }
            }
        }
    `
    )
}

export const detailStokPakan = (_id) => {
    return (
        `query{
            feedStock(_id: "${_id}"){
              _id
              name
              weight
              countFeeding
              feed{
                value:_id
                type
                producer
              }
              feedWarehouse{
                value : _id
                name
              }
            }
          }`
    );
}

export const feeding = (_id) => {
    return (
        `query{
            feedStock(_id: "${_id}"){
              _id
              feeding{
                  _id
              }
            }
          }`
    );
}

export const updateStokPakan = (_id, idWarehouse, value) => {
    return {
        query:
            `mutation{
            updateFeedStock(_id: "${_id}",updateFeedStockInput:{
                name: "${value.namaPengadaan}"
                feed: "${value.pakan.value}"
                weight : ${value.beratPakan}
                feedWarehouse: "${idWarehouse}"
            }){
                _id
            }
        }
        `
    }
}

export const deleteStokPakan = (_id) => {
    return (
        `mutation{
            deleteFeedStock(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const createStokPakan = (idWarehouse, value) => {
    console.log(value)
    return {
        query: `mutation{
              createFeedStock(feedStockInput: {
                weight : ${value.berat}
                feed: "${value.pakan.value}"
                feedWarehouse: "${idWarehouse}"
                name : "${value.namaPengadaan}"
              }){
                _id
                weight
              }
            }

            `
    }
}

export const addStokPakan = (value, year, stockId) => {
    return {
        query: `mutation{
                    createFeeding(feedingInput:{
                        number: ${value.jumlahStok}
                        ttb:  "${value.ttb}"
                        storeTime: "${year}"
                        feedStock : "${stockId}"
                        priceFeeding : ${value.harga}
                        typeFeeding : "In"
                    }){
                        _id
                    }
                }
            `
    }
}

export const addMutationPakan = (value, year, stockId) => {
    return {
        query: `mutation{
                    createFeeding(feedingInput: {
                        number: ${value.jumlahStok}
                        priceFeeding: ${value.harga}
                        storeTime:"${year}"
                        feedStock : "${stockId}"
                        feedWarehouse: "${value.warehouse.value}"
                        typeFeeding: "Mutation",
                    }){
                        _id
                        number
                        priceFeeding
                        storeTime
                        typeFeeding
                        feedStock{
                            _id
                            feedWarehouse{
                                name
                            }
                        }
                        mutationFeedStock{
                            _id
                            feedWarehouse{
                                name
                            }
                        }
                    }
                }
            `
    }
}

export const deleteTransaksiStokPakan = (_id) => {
    return (
        `mutation{
            deleteFeeding(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const getDetailTransaksiStok = (keyword, limit, skip, feedStok, type) => {
    return (
        `query{
            feedings(keyword: "${keyword}", limit:${limit}, skip:${skip}, feedStock : "${feedStok}", typeFeeding : "${type}"){
              totalCount
              feed{
                  _id
                  name
              }
              feedings{
                  _id
                  typeFeeding
                  priceFeeding
                  ttb
                  number
                  numberWeight
                  storeTime
                  house{
                    _id
                    name
                  }
                  feedStock{
                    _id
                      feed{
                          _id
                          name
                      }
                      feedWarehouse{
                        name
                        houses{
                            _id
                            name
                        }
                    }
                  }
                  mutationFeedStock{
                    name
                    feedWarehouse{
                        name
                        houses{
                            _id
                            name
                        }
                    }
                }
              }
            }
          }`
    );
}

export const detailTransaksi = (_id) => {
    return (
        `query{
            feeding(_id: "${_id}"){
               _id
               number
               ttb
               numberWeight
               storeTime
               value : typeFeeding
               priceFeeding
               feedStock {
                   _id
               }
             }
           }`
    );
}

export const updateTransaksiPenggunaan = (_id, value, year, feedStock) => {
    return {
        query:
            `mutation{
            updateFeeding( _id:"${_id}" ,updateFeedingInput: {
                number: ${value.jumlahStok},
                storeTime: "${year}",
                feedStock : "${feedStock._id}",
                typeFeeding: "Out",
                }){
                _id
            }
        }`
    }
}

export const updateTransaksiPenambahan = (_id, value, year, feedStock) => {
    return {
        query:
            `mutation{
            updateFeeding( _id:"${_id}" ,updateFeedingInput: {
                ttb: "${value.ttb}"
                number: ${value.jumlahStok}
                storeTime: "${year}"
                feedStock : "${feedStock._id}"
                typeFeeding: "In"
                priceFeeding : ${value.harga}
                }){
                _id
            }
        }`
    }
}
