export const getRearingRecord = (keyword, limit, skip, periode) => {
  return (
    `query{
            calculatedRearingRecords(rearing:"${periode}", keyword:"${keyword}",limit:${limit},skip:${skip}){
              totalCount
              periode
              house
              isActiveHouse
              calculatedArr{
                _id
                tanggal
                umur
                mati
                culling
                deplesiPerHari
                total
                persenKematian
                persenDeplesiHarian

                panen
                saldo
                totalBeratPanen
                
                pakanPerHari
                pakanTotal
                
                fcr
                bw
                totalBerat
                abw
                adg  
                feedIntake
                fiCum
                saldoPerHari  
                indeksPerformance
                difFcr
                
                standardFi
                standardFcr
                standardDp
                standardBw
                
                cumFeed
                ovkTotal
                cumOverhead
                totalDoc
                
                totalBiaya
                hppPerEkor
                hppPerKg
                hppStok
          
              }
            }
          }
        `
  )
}

export const createRearingRecord = (idPeriode, date) => {
  return {
    query:
      `mutation{
        createRearingRecord(rearingRecordInput:{
            date: "${date}"
            rearing: "${idPeriode}"
          }){
            _id
            age
            date
          }
        }
    `
  }
}

export const createRearingRecordBatch = (value, growings, feeds, ovks) => {
  return {
    query:
      `mutation{
        createRearingRecordBatch(rearingRecordInput:{
          house: "${value.house.value}"
          date: "${value.date}"
          culling: ${value.culling}
          mati: ${value.mati}
          growings: ${growings}
          feeds: ${feeds}
          ovks: ${ovks}
          }){
            _id
            age
            date
          }
        }
    `
  }
}

export const autoRearingRecord = (idPeriode) => {
  return {
    query:
      `mutation{
        autoRearingRecord(rearing: "${idPeriode}"){
            _id
            age
            date
          }
        }
    `
  }
}

export const deleteRearingRecord = (_id) => {
  return (
    `mutation{
        deleteRearingRecord(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}

export const updateRearingRecord = (_id, date) => {
  return {
    query:
      `
      mutation{
        updateRearingRecord(_id:"${_id}",updateRearingRecordInput: {
          date : "${date}"
        })
        {
          _id
        }
      }
      `
  }
}

/**Record Deplesi */
export const createRecordDeplesi = (value, rearing) => {
  return {
    query: `mutation{
                createMutation(mutationInput: {
                  type: "${value.jenis.value}",
                  number: ${parseInt(value.jumlah)}
                  rearingRecord: "${rearing}"
                  otherInformation: "${value.keterangan}"
                }){
                  _id
                }
              }
          `
  }
}

export const getRecordDeplesi = (keyword, limit, skip, record) => {
  return (
    `query{
        mutations(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
          totalCount
          houseActive
          mutations {
              _id
              type
              number
              rearingRecord {
                  _id
              }
              otherInformation
          }
        }
      }`
  )
}

export const getDetailRecordDeplesi = (_id) => {
  return (
    `query{
      mutation(_id: "${_id}"){
        _id
        value :type
        number
        rearingRecord {
            _id
        }
        otherInformation
      }
    }`
  )
}

export const updateRecordDeplesi = (_id, value) => {
  return {
    query: `mutation{
        updateMutation(_id:"${_id}",updateMutationInput: {
          type : "${value.jenis.value}"
          number : ${value.jumlah}
          otherInformation : "${value.keterangan}"
        })
        {
          _id
        }
      }`
  }
}

export const deleteRecordDeplesi = (_id) => {
  return (
    `mutation{
      deleteMutation(_id:"${_id}"){
        deleted
      }
    }`
  )
}

/**Record Panen*/
export const createRecordPanen = (value, rearing) => {
  return {
    query: `mutation{
              createHarvest(mutationInput: {
                type: "Harvest"
                number: ${value.ayam}
                rearingRecord: "${rearing}"
                averageWeight : ${isNaN(value.berat / value.ayam) ? null : value.berat / value.ayam}
                totalWeight : ${value.berat}
                price : ${value.price}
                total: ${value.total}
                customer : "${value.customer.value}"
                otherInformation : "${value.keterangan}"
                plat : "${value.plat}"
                tonase : ${value.tonase}
              }){
                _id
              }
            }
        `
  }
}

export const getRecordPanen = (keyword, limit, skip, record) => {
  return (
    `query{
      harvests(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
        totalCount
        houseActive
        rearing{
          _id
          name
          house{
            name
          }
        }
        mutations {
            _id
            number
            price
            total
            averageWeight
            totalWeight
            rearingRecord {
                _id
                rearing{
                  name
                  house{
                    name
                  }
                }
            }
            customer{
              value: _id
              name
            }
            otherInformation

            plat
            tonase
            photo
            qtySwap
            weightSwap
            qtySwapper
            weightSwapper
            driver
            weigher
            isLocked
        }
      }
    }`
  )
}

export const getRecordPanenDay = () => {
  return (
    `query{
      harvestDay{
        totalCount
        mutations {
            _id
            date
            house
            pelanggan
            plat
            tonase
            isLocked
        }
      }
    }`
  )
}

export const getDetailRecordPanen = (_id) => {
  return (
    `query{
      mutation(_id: "${_id}"){
        _id
       number 
        price
        total
        plat
        tonase
        averageWeight
        totalWeight
        startTime
        endTime
        driver
        weigher
        farmer
        qtySwap
        weightSwap
        qtySwapper
        weightSwapper
        photo
        signDriver
        signWeigher
        signFarmer
        isLocked
        rearingRecord {
            _id
            date
            rearing{
              name
              house{
                name
              }
            }
        }
        customer{
          value: _id
          name
        }
        otherInformation
      }
    }`
  )
}

export const updateRecordPanen = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
         number: ${value.ayam}
          averageWeight : ${isNaN(value.berat / value.ayam) ? null : value.berat / value.ayam}
          totalWeight : ${value.berat}
          price : ${value.price}
          total: ${value.total}
          otherInformation : "${value.keterangan}"
          tonase: ${value.tonase}
          plat: "${value.plat}"
          customer : "${value.customer.value}"
        })
        {
          _id
        }
      }`
  }
}
export const lockingPanen = (_id, value) => {
  return {
    query: `mutation{
        lockingHarvest(_id:"${_id}",isLocked: ${value})
        {
          _id
          isLocked
        }
      }`
  }
}

export const updatePanenStartTime = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          startTime: "${value}"
        })
        {
          _id
          startTime
        }
      }`
  }
}
export const updatePanenEndTime = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          endTime: "${value}"
        })
        {
          _id
          endTime
        }
      }`
  }
}
export const updatePanenDriver = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          driver: "${value}"
        })
        {
          _id
          driver
        }
      }`
  }
}
export const updatePanenWeigher = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          weigher: "${value}"
        })
        {
          _id
          weigher
        }
      }`
  }
}
export const updatePanenFarmer = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          farmer: "${value}"
        })
        {
          _id
          farmer
        }
      }`
  }
}
export const updatePanenQtySwap = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          qtySwap: ${value}
        })
        {
          _id
          qtySwap
        }
      }`
  }
}
export const updatePanenWeightSwap = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          weightSwap: ${value}
        })
        {
          _id
          weightSwap
        }
      }`
  }
}
export const updatePanenQtySwapper = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          qtySwapper: ${value}
        })
        {
          _id
          qtySwapper
        }
      }`
  }
}
export const updatePanenWeightSwapper = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          weightSwapper: ${value}
        })
        {
          _id
          weightSwapper
        }
      }`
  }
}

export const updatePanenSignDriver = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          signDriver: "${value}"
        })
        {
          _id
          signDriver
        }
      }`
  }
}

export const updatePanenSignWeigher = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          signWeigher: "${value}"
        })
        {
          _id
          signWeigher
        }
      }`
  }
}

export const updatePanenSignFarmer = (_id, value) => {
  return {
    query: `mutation{
        updateHarvest(_id:"${_id}",updateMutationInput: {
          signFarmer: "${value}"
        })
        {
          _id
          signFarmer
        }
      }`
  }
}

export const deleteRecordPanen = (_id) => {
  return (
    `mutation{
      deleteMutation(_id:"${_id}"){
        deleted
      }
    }`
  )
}

export const getPanenDetails = (id) => {
  return (
    `query{
      harvestDetails(keyword: "", limit:0, skip:0, mutation : "${id}"){
        totalCount
        totalQty
        totalWeight
        avg
        harvests {
            _id
            index
            qty
            weight
        }
      }
    }`
  )
}

export const updatePanenDetail = (id, value) => {
  return {
    query: `mutation{
      updateHarvestDetail(_id:"${id}", harvestInput: {
        qty: ${value.qty}
        weight: ${value.weight}
      })
        {
          _id
          index
          qty
          weight
        }
      }`
  }
}

export const addPanenDetail = (id, value) => {
  return {
    query: `mutation{
      changeHarvestDetail(harvestInput: {
        index: ${value.index}
        mutation: "${id}"
        qty: ${parseInt(value.qty)}
        weight: ${parseFloat(value.weight)} 
      })
        {
          _id
          index
          qty
          weight
        }
      }`
  }
}

export const updatePanenDetailQty = (id, index, value) => {
  return {
    query: `mutation{
      changeHarvestDetail(harvestInput: {
        index: ${index}
        mutation: "${id}"
        qty: ${parseInt(value)}
        })
        {
          _id
          index
          qty
        }
      }`
  }
}

export const updatePanenDetailWeight = (id, index, value) => {
  return {
    query: `mutation{
      changeHarvestDetail(harvestInput: {
        index: ${index}
        mutation: "${id}"
        weight: ${parseFloat(value)} 
        })
        {
          _id
          index
          weight
        }
      }`
  }
}

export const deleteHarvestDetail = (_id) => {
  return (
    `mutation{
      deleteHarvestDetail(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}

/**Record Pertumbuhan */
export const getGrowingParams = (keyword, limit, skip) => {
  return (
    `query{
        growingParams(keyword:"${keyword}", limit:${limit}, skip:${skip}){
          totalCount
          growingParams{
              _id
              name
              abbr
              unit
          }
        }
      }`
  )
}

export const createRecordPertumbuhan = (value, rearingRecord) => {
  return {
    query: `
        mutation{
          createGrowing(growingInput:{
              value : ${value.nilai}
              procentage : ${value.procentage}
              type : "${value.type}"
              rearingRecord : "${rearingRecord}"
          })
          {
              _id
          }
      }`
  }
}

export const getRecordPertumbuhan = (keyword, limit, skip, rearingRecord) => {
  return (`
    query{
      growings(keyword:"${keyword}",limit:${limit},skip:${skip}, rearingRecord : "${rearingRecord}"){
        totalCount
        houseActive
        growings{
          _id
          value
          type
          procentage
        }
      }
    }
  `)
}

export const getDetailRecordPertumbuhan = (_id) => {
  return (`
    query{
      growing(_id : "${_id}"){
          _id
          growingParam{
              value : _id
              name
              unit
              abbr
          }
          value
          type
          procentage
      }
    }
  `)
}

export const updateRecordPertumbuhan = (_id, value) => {
  return {
    query:
      `
      mutation{
        updateGrowing(_id:"${_id}",updateGrowingInput: {
          value : ${value.nilai}
          type : "${value.type}"
          procentage : ${value.procentage}
        })
        {
          _id
        }
      }
      `
  }
}

export const deleteRecordPertumbuhan = (_id) => {
  return (`
    mutation{
      deleteGrowing(_id:"${_id}"){
        deleted
      }
    }
    `)
}


/**Record Pakan */
export const createRecordPakan = (value, dateRearing, idRearingRecord) => {
  return {
    query: `mutation{
              createFeeding(feedingInput: {
                  number: ${value.jumlah},
                  priceFeeding: ${value.price}
                  storeTime: "${dateRearing}"
                  feedStock : "${value.pakan.value}"
                  typeFeeding: "Out"
                  rearingRecord: "${idRearingRecord}"
              }){
                  _id
              }
          }
        `
  }
}

export const getRecordPakan = (keyword, limit, skip, record) => {
  return (
    `query{
      feedingRecords(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
        totalCount
        houseActive
        feedings{
            _id
            typeFeeding
            number
            numberWeight
            priceFeeding
            total
            storeTime
            feedStock {
              _id
              name
              weight
              feed{
                  _id
                  name
              }
            }
        }
      }
    }`
  )
}

export const getDetailRecordPakan = (_id) => {
  return (
    `query{
      feeding(_id: "${_id}"){
         _id
         number
         numberWeight
         storeTime
         typeFeeding
         priceFeeding
         feedStock {
              value : _id
              weight
         }
       }
     }`
  )
}

export const updateRecordPakan = (_id, value, dateRearing) => {
  return {
    query: `mutation{
        updateFeeding( _id:"${_id}" ,updateFeedingInput: {
            number: ${value.jumlah},
            storeTime: "${dateRearing}",
            feedStock : "${value.pakan.value}"
            priceFeeding: ${value.price}
            typeFeeding: "Out",
            }){
            _id
        }
    }`
  }
}

export const deleteRecordPakan = (_id) => {
  return (
    `mutation{
        deleteFeeding(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}

export const getPriceFeeds = (keyword, limit, skip, month, year, warehouse) => {
  return (
    `query{
          monthlyStockFeeds(keyword:"${keyword}",limit:${limit},skip:${skip}, month:"${month}", year:"${year}", feedWarehouse: "${warehouse}"){
          locked
          calculateMonthlyStocks{
              idFeed
              hargaPerKarung
            }
        }
      }
    `
  )
}

/**Record Ovk */
export const createRecordOvk = (value, dateRearing, idRearingRecord) => {
  return {
    query: `
      mutation{
          createOvkTransaction(ovkTransactionInput: {
              number: ${value.jumlah},
              priceOvkTransaction: ${parseFloat(value.price)},
              storeTime: "${dateRearing}",
              ovkStock : "${value.ovk.value}"
              typeTransaction: "Out",
              rearingRecord: "${idRearingRecord}"
          }){
              _id
          }
      }
    `
  }
}

export const getRecordOvk = (keyword, limit, skip, record) => {
  return (`
    query{
      ovkTransactionsRecord(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
        totalCount
        houseActive
        ovkTransactions{
            _id
            typeTransaction
            number
            numberRecording
            restOvk
            total
            priceOvkTransaction
            ovkStock {
              _id
              name
              ovk { 
                  _id
                  name
                  qtyUnit
                  ovkUnit{
                    name
                  }
                  ovkUnitRecording{
                    name
                  }
              }
            }
        }
      }
    }`
  )
}

export const getDetailRecordOvk = (_id) => {
  return (
    `query{
      ovkTransaction(_id: "${_id}"){
        _id
        number
        numberRecording
        priceOvkTransaction
        total
        storeTime
        typeTransaction
        ovkStock {
              value : _id
        }
      }
    }`
  )
}

export const updateRecordOvk = (_id, value, dateRearing) => {
  return {
    query: `
    mutation{
        updateOvkTransaction( _id:"${_id}",updateOvkTransactionInput: {
            number: ${value.jumlah},
            storeTime: "${dateRearing}",
            ovkStock : "${value.ovk.value}"
            priceOvkTransaction: ${value.price}
            typeTransaction : "Out"
        }){
            _id
        }
    }
    `
  }
}

export const deleteRecordOvk = (_id) => {
  return (
    `mutation{
        deleteOvkTransaction(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}

export const getPriceOvks = (keyword, limit, skip, month, year, warehouse) => {
  return (
    `query{
          monthlyStockOvks(keyword:"${keyword}",limit:${limit},skip:${skip}, month:"${month}", year:"${year}", ovkWarehouse: "${warehouse}"){
          locked
          calculateMonthlyStocks{
              idOvk
              namaOvk
              satuan
              hargaPengeluaran
          }
        }
      }
    `
  )
}

/**Record Overhead */
export const createRecordOverhead = (value, idRearingRecord) => {
  return {
    query: `mutation{
      createOverheading(overheadingInput: {
                  overhead : "${value.overhead.value}"
                  quantity: ${value.jumlah},
                  price: ${value.price},
                  rearingRecord: "${idRearingRecord}"
              }){
                  _id
              }
          }
        `
  }
}

export const getRecordOverhead = (keyword, limit, skip, record) => {
  return (
    `query{
      overheadings(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
        totalCount
        houseActive
        overheadings{
            _id
            quantity
            price
            overhead {
              _id
              name
            }
        }
      }
    }`
  )
}

export const getDetailRecordOverhead = (_id) => {
  return (
    `query{
      overheading(_id: "${_id}"){
         _id
         quantity
         price
         overhead {
              value: _id
              name
         }
       }
     }`
  )
}

export const updateRecordOverhead = (_id, value) => {
  return {
    query: `mutation{
      updateOverheading( _id:"${_id}" ,updateOverheadingInput: {
        overhead : "${value.overhead.value}"
        quantity: ${value.jumlah},
        price: ${value.price},
            }){
            _id
        }
    }`
  }
}

export const deleteRecordOverhead = (_id) => {
  return (
    `mutation{
      deleteOverheading(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}
/**Record Penyakit */
export const createRecordPenyakit = (value, idRearingRecord) => {
  return {
    query: `
      mutation{
          createDiseaseRecord(diseaseRecordInput: {
              number: ${value.jumlahAyam},
              disease: "${value.penyakit.value}",
              rearingRecord: "${idRearingRecord}"
          }){
              _id
          }
      }
    `
  }
}

export const getRecordPenyakit = (keyword, limit, skip, record) => {
  return (`
      query{
        diseaseRecords(keyword: "${keyword}", limit:${limit}, skip:${skip}, rearingRecord : "${record}"){
          totalCount
          houseActive
          diseaseRecords{
              _id
              disease{
                _id
                name
              }
              number

          }
        }
      }`
  )
}

export const getDetailRecordPenyakit = (_id) => {
  return (
    `query{
      diseaseRecord(_id: "${_id}"){
        _id
        number
        disease {
              value : _id
        }
        pictureDisease{
          _id
          linkPicture
          originalName
        }
      }
    }`
  )
}

export const getDetailRecordPenyakitEdit = (_id) => {
  return (
    `query{
        diseaseRecord(_id: "${_id}"){
          _id
          number
          disease {
                value : _id
          }
          pictureDisease{
            url :   linkPicture
            name : originalName
          }
        }
      }`
  )
}

export const updateRecordPenyakit = (_id, value) => {
  return {
    query: `
      mutation{
          updateDiseaseRecord( _id:"${_id}",updateDiseaseRecordInput: {
              disease: "${value.penyakit.value}"
              number: ${value.jumlahAyam},
          }){
              _id
          }
      }
    `
  }
}

export const deleteRecordPenyakit = (_id) => {
  return (
    `mutation{
      deleteDiseaseRecord(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}

export const deletePicPenyakit = (_id) => {
  return (
    `mutation{
      deletePicDisease(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}