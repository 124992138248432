import React from 'react'
import { Form, Row, Col, Select, InputNumber, Card, DatePicker, Divider, Button, Skeleton } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


import Loading from '../../../common/component/loading/loading-container'

function RecordingBatch(props) {
    const { handleChangeKandang, setDate, form, t,
        loading, loadingPriceFeed, loadingPriceOvk,
        postData, onChangeOvk, onChangeFeed,
        idKandang, kandang, stokPakan, stokOvk } = props
    const { Option } = Select

    return (
        <Card>
            <Loading loading={loading}>
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    autoComplete="off"
                    initialValues={{
                        mati: 0, culling: 0
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} xs={24} sm={24}>
                            <span className="font-semi-bold font-sub">Kandang</span>
                            <Form.Item
                                name="house" rules={[{ required: true, message: `${t('pages.rearing.msgHouse')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder='Pilih kandang'
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori"
                                    onChange={e => handleChangeKandang(e)}
                                    value={{ key: idKandang }}
                                >
                                    {kandang && kandang.map(data =>
                                        <Option
                                            key={data.name}
                                            value={data._id}
                                        >{data.name}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24} sm={24}>
                            <span className="font-semi-bold font-sub">Tanggal</span>
                            <Form.Item
                                name="date" rules={[{ required: true, message: `${t('pages.recording.msgDate')}` }]}
                            >
                                <DatePicker
                                    placeholder={t('pages.recording.phDate')}
                                    className="select-kategori"
                                    onChange={e => setDate(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <span className="font-semi-bold font-sub">Culling</span>
                            <Form.Item name='culling' rules={[{ required: true, message: `${t('pages.recording.depletion.msgQty')} culling` }]}>
                                <InputNumber
                                    placeholder="1000..."
                                    className="input-number"
                                    addonAfter='ekor' min={0}
                                    type='number'
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xs={24}>
                            <span className="font-semi-bold font-sub">Mati</span>
                            <Form.Item name='mati' rules={[{ required: true, message: `${t('pages.recording.depletion.msgQty')} mati` }]}>
                                <InputNumber
                                    placeholder="1000..."
                                    className="input-number"
                                    addonAfter='ekor' min={0}
                                    type='number'
                                />
                            </Form.Item>
                        </Col>

                        <Divider className='m-0' orientation="left" plain orientationMargin='0'>Sampling</Divider>
                        <Form.List name="growings"
                            rules={[
                                {
                                    validator: async (_, names) => {
                                        let sum = 0
                                        names && names.forEach(e => {
                                            if (e) sum += e.procentage
                                        })
                                        if (sum > 100) {
                                            return Promise.reject(new Error('Jumlah prosentase tidak bisa lebih dari 100% !'))
                                        }
                                    },
                                },
                            ]}>
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <>
                                            <Col lg={8} xs={24}>
                                                <span className="font-semi-bold font-sub">Prosentase ayam</span><br />
                                                <Form.Item
                                                    className='mb-0'
                                                    {...restField}
                                                    name={[name, 'procentage']}
                                                    rules={[
                                                        { required: fields && fields.length > 0 ? true : false, message: `Masukkan nilai prosentase` },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        placeholder="100..."
                                                        className="input-number"
                                                        type='number'
                                                        addonAfter='%'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={8} xs={24}>
                                                <span className="font-semi-bold font-sub">Jenis ayam</span>
                                                <Form.Item className='mb-0'
                                                    {...restField}
                                                    name={[name, 'type']}
                                                    rules={[{ required: fields && fields.length > 0 ? true : false, message: `Masukkan jenis ayam` }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        placeholder='Jenis ayam'
                                                        optionFilterProp="children"
                                                        style={{ width: '100%' }}
                                                        className="select-kategori black font-regular"
                                                    >
                                                        <Option value='rooster'>Jantan</Option>
                                                        <Option value='hen'>Betina</Option>
                                                        <Option value='big'>Besar</Option>
                                                        <Option value='medium'>Sedang</Option>
                                                        <Option value='small'>Kecil</Option>
                                                        <Option value='tiny'>Sangat Kecil</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={7} xs={22}>
                                                <span className="font-semi-bold font-sub">Berat ayam</span><br />
                                                <Form.Item
                                                    className='mb-10'
                                                    {...restField}
                                                    name={[name, 'bw']}
                                                    rules={[
                                                        { required: fields && fields.length > 0 ? true : false, message: `${t('pages.recording.bw.msgWeight')}` },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        placeholder="100..."
                                                        className="input-number mr-10"
                                                        type='number'
                                                        addonAfter='gram'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={1} xs={2} style={{ alignSelf: 'center' }}>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ color: 'red' }} />
                                            </Col>
                                        </>
                                    ))}
                                    <Form.Item>
                                        <Form.ErrorList errors={errors} />
                                        <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                            Tambah Sampling Ayam
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                        <Divider className='m-0' orientation="left" plain orientationMargin='0'>Pakan</Divider>
                        <Form.List name="feeds">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <>
                                            <Col lg={12} xs={24}>
                                                <span className="font-semi-bold font-sub">{t('pages.recording.feed.title')}</span>
                                                <Form.Item className='mb-0'
                                                    {...restField}
                                                    name={[name, 'pakan']}
                                                    rules={[{ required: fields && fields.length > 0 ? true : false, message: `${t('pages.recording.feed.msgFeed')}` }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        labelInValue
                                                        placeholder={t('pages.recording.feed.phFeed')}
                                                        optionFilterProp="children"
                                                        onChange={value => { onChangeFeed(value, name) }}
                                                        style={{ width: '100%' }}
                                                        className="select-kategori black font-regular"
                                                    >
                                                        {stokPakan && stokPakan.map(data =>
                                                            <Option
                                                                value={data._id}
                                                            ><span hidden>{data.feed._id}</span> {data.feed.name} - {data.nameStock}</Option>
                                                        )
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={11} xs={22}>
                                                <span className="font-semi-bold font-sub">Jumlah</span><br />
                                                <Form.Item
                                                    className='mb-10'
                                                    {...restField}
                                                    name={[name, 'qty']}
                                                    rules={[
                                                        { required: fields && fields.length > 0 ? true : false, message: `${t('pages.recording.feed.msgQty')}` },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        placeholder="1000..."
                                                        className="input-number mr-10"
                                                        type='number'
                                                        addonAfter='karung'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={1} xs={2} style={{ alignSelf: 'center' }}>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ color: 'red' }} />
                                            </Col>


                                            <Col hidden>
                                                <Form.Item shouldUpdate name={[name, 'price']}  {...restField}
                                                >
                                                    <InputNumber
                                                        placeholder="2500..."
                                                        className="input-number"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    ))}
                                    <Form.Item>
                                        <Skeleton paragraph={{ rows: 0, width: 10 }} loading={loadingPriceFeed} active size={'lg'} >
                                            <Button hidden={loadingPriceFeed} type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                Tambah Recording Pakan
                                            </Button>
                                        </Skeleton>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>

                        <Divider className='m-0' orientation="left" plain orientationMargin='0'>OVK</Divider>
                        <Form.List name="ovks">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <>
                                            <Col lg={12} xs={24}>
                                                <span className="font-semi-bold font-sub">{t('pages.recording.ovk.title')}</span>
                                                <Form.Item className='mb-0'
                                                    {...restField}
                                                    name={[name, 'ovk']}
                                                    rules={[{ required: fields && fields.length > 0 ? true : false, message: `${t('pages.recording.ovk.msgOvk')}` }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        labelInValue
                                                        placeholder={t('pages.recording.ovk.phOvk')}
                                                        optionFilterProp="children"
                                                        style={{ width: '100%' }}
                                                        className="select-kategori black font-regular"
                                                        onChange={(e) => onChangeOvk(e, name)}
                                                    >
                                                        {
                                                            stokOvk && stokOvk.map(data =>
                                                                <Option
                                                                    value={data._id}
                                                                ><span hidden>{data.ovk._id}</span>{data.ovk.name} ({data.ovk.ovkUnit.name})</Option>
                                                            )
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={11} xs={22}>
                                                <span className="font-semi-bold font-sub">{t('pages.recording.ovk.qty')}</span><br />
                                                <Form.Item
                                                    className='mb-10'
                                                    {...restField}
                                                    name={[name, 'qty']}
                                                    rules={[
                                                        { required: fields && fields.length > 0 ? true : false, message: `${t('pages.recording.ovk.msgQty')}` },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        placeholder="1000..."
                                                        className="input-number mr-10"
                                                        type='number'
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={1} xs={2} style={{ alignSelf: 'center' }}>
                                                <MinusCircleOutlined onClick={() => remove(name)} style={{ color: 'red' }} />
                                            </Col>

                                            <Col hidden>
                                                <Form.Item shouldUpdate name={[name, 'price']}  {...restField}>
                                                    <InputNumber
                                                        placeholder="2500..."
                                                        className="input-number"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </>
                                    ))}
                                    <Form.Item>
                                        <Skeleton paragraph={{ rows: 0, width: 10 }} loading={loadingPriceOvk} active size={'lg'} >
                                            <Button hidden={loadingPriceOvk} type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                Tambah Recording OVK
                                            </Button>
                                        </Skeleton>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Row>
                    <Button
                        onClick={() =>
                            form
                                .validateFields()
                                .then((values) => {
                                    let stringGrowings = null
                                    let stringFeeds = null
                                    let stringOvks = null

                                    if (values.growings && values.growings.length > 0) {
                                        let growings = []
                                        for (let i = 0; i < values.growings.length; i++) {
                                            growings.push({
                                                procentage: values.growings[i].procentage,
                                                type: values.growings[i].type,
                                                bw: values.growings[i].bw,
                                            })
                                        }
                                        values.growings = growings
                                        stringGrowings = JSON.stringify(values.growings)
                                        stringGrowings = stringGrowings.replace(/"([^"]+)":/g, '$1:')
                                    }

                                    if (values.feeds && values.feeds.length > 0) {
                                        let feeds = []
                                        for (let i = 0; i < values.feeds.length; i++) {
                                            feeds.push({
                                                feed: values.feeds[i].pakan.value,
                                                qty: values.feeds[i].qty,
                                                price: values.feeds[i].price
                                            })
                                        }
                                        values.feeds = feeds
                                        stringFeeds = JSON.stringify(values.feeds)
                                        stringFeeds = stringFeeds.replace(/"([^"]+)":/g, '$1:')
                                    }

                                    if (values.ovks && values.ovks.length > 0) {
                                        let ovks = []
                                        for (let i = 0; i < values.ovks.length; i++) {
                                            ovks.push({
                                                ovk: values.ovks[i].ovk.value,
                                                qty: values.ovks[i].qty,
                                                price: values.ovks[i].price
                                            })
                                        }
                                        values.ovks = ovks
                                        stringOvks = JSON.stringify(values.ovks)
                                        stringOvks = stringOvks.replace(/"([^"]+)":/g, '$1:')
                                    }

                                    postData(values, stringGrowings, stringFeeds, stringOvks)
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info)
                                })}
                        block type='primary' size='large'
                        htmlType="submit"
                    ><FontAwesomeIcon icon={faPlusSquare} className='mr-10' />
                        {t('pages.recording.add')}
                    </Button>
                </Form>
            </Loading>
        </Card>
    )
}

export default RecordingBatch