import React, { useState } from 'react'
import NumberFormat from 'react-number-format'
import { Layout, Breadcrumb, Row, Col, Table, DatePicker, Typography, Button } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import { FileExcelOutlined } from '@ant-design/icons'
import { validationMessage } from '../../../../common/component/notification/notification'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { blobToDataURL } from '../../../../common/utils/Download'

function LiveBirdComponent(props) {
  const { Content } = Layout
  const { Text } = Typography
  const { columns, data, loading, disabledDate, handleTanggalRange, month, year, idStockflow, t } = props
  const [load, setLoad] = useState(false)
  const cookies = new Cookies()

  const exportData = async (idStockflow) => {
    setLoad(true)
    let token = cookies.get('token')
    await axios
      .get(`${process.env.REACT_APP_API_URL}/excel-livebird-stockflow/${idStockflow}`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        responseType: 'blob'
      })
      .then(res => {
        if (typeof (res.data === 'blob')) {
          setLoad(false)
          const fileName = `Stockflow Live Bird ${month} ${year}.xlsx`
          blobToDataURL(res.data, fileName)
          validationMessage('success', `Berhasil export stockflow live bird ke file Excel`)
        }
      }).catch((error) => {
        console.log('err', error)
        validationMessage('error', `Gagal export stockflow live bird ke file Excel`)
        setLoad(false)
      })
  }

  const handleExport = () => {
    if (idStockflow) {
      exportData(idStockflow)
    }
  }

  return (
    <Layout>
      <Content className="dashboard-container">
        <Row gutter={[16, 16]}>
          <Col lg={12} xs={24}>
            <Breadcrumb separator=">">
              <Breadcrumb.Item><span>{t('pages.stockflow.livebird')} - {month} {year}</span></Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col lg={{ span: 4, offset: 4 }} xs={24}>
            <Button style={{
              float: 'right', backgroundColor: '#00a266', color: 'white',
              borderRadius: 5, boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
            }}
              loading={load} onClick={() => handleExport()}>
              <FileExcelOutlined />Export Excel</Button>
          </Col>
          <Col span={4} lg={4} md={4} xs={24}>
            <DatePicker
              allowClear={false}
              picker='month'
              format={'MMMM'}
              onChange={handleTanggalRange}
              disabledDate={disabledDate}
            />
          </Col>
          <Col lg={24} md={24} sm={24}>
            <Loading loading={loading}>
              <Table
                columns={columns}
                dataSource={data}
                bordered
                pagination={false}
                className="table-dashboard"
                scroll={{ x: 1000, y: '70vh' }}
                summary={pageData => {
                  let totalStokAwal = 0
                  let nilaiAwal = 0
                  let totalIn = 0
                  let totalNilaiIn = 0

                  let totalCulling = 0

                  let totalMati = 0

                  let totalPanen = 0
                  let totalNilaiPanen = 0

                  let totalSaldoAkhir = 0
                  let totalNilaiSaldoAkhir = 0
                  pageData.forEach(({
                    stokAwal, hargaTotalAwalBulan,
                    pengadaan, totalHargaPengadaan,
                    culling, totalHargaCulling,
                    mati, totalHargaMati,
                    panen, totalHargaPanen,
                    stokAkhir, totalHargaStokAkhir
                  }) => {
                    totalStokAwal += parseFloat(stokAwal)
                    nilaiAwal += parseFloat(hargaTotalAwalBulan)

                    totalIn += parseFloat(pengadaan)
                    totalNilaiIn += parseFloat(totalHargaPengadaan)

                    totalCulling += parseFloat(culling)

                    totalMati += parseFloat(mati)

                    totalPanen += parseFloat(panen)
                    totalNilaiPanen += parseFloat(totalHargaPanen)

                    totalSaldoAkhir += parseFloat(stokAkhir)
                    totalNilaiSaldoAkhir += parseFloat(totalHargaStokAkhir)
                  })
                  return (
                    <>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalStokAwal < 0 ? Math.abs(totalStokAwal) : totalStokAwal}
                            prefix={totalStokAwal < 0 ? '(' : ''} suffix={totalStokAwal < 0 ? ')' : ''}
                            displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right" >
                          <Text><NumberFormat value={(nilaiAwal / totalStokAwal) < 0 ?
                            isNaN(Math.abs((nilaiAwal / totalStokAwal).toFixed(0))) ? 0 : Math.abs((nilaiAwal / totalStokAwal).toFixed(0))
                            : isNaN((nilaiAwal / totalStokAwal).toFixed(0)) ? 0 : (nilaiAwal / totalStokAwal).toFixed(0)}
                            displayType={'text'} thousandSeparator={true} suffix={(nilaiAwal / totalStokAwal) < 0 ? ")" : ''} prefix={(nilaiAwal / totalStokAwal) < 0 ? "(" : ''} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={nilaiAwal < 0 ? Math.abs(nilaiAwal.toFixed(0)) : nilaiAwal.toFixed(0)} displayType={'text'} thousandSeparator={true}
                            suffix={nilaiAwal < 0 ? ")" : ''} prefix={nilaiAwal < 0 ? "(" : ''} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalIn} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={(totalNilaiIn / totalIn) < 0 ? Math.abs((totalNilaiIn / totalIn).toFixed(0)) : (totalNilaiIn / totalIn).toFixed(0)}
                            displayType={'text'} thousandSeparator={true} suffix={(totalNilaiIn / totalIn) < 0 ? ")" : ''} prefix={(totalNilaiIn / totalIn) < 0 ? "(" : ''} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalNilaiIn < 0 ? Math.abs(totalNilaiIn).toFixed(0) : totalNilaiIn.toFixed(0)} displayType={'text'} thousandSeparator={true}
                            suffix={totalNilaiIn < 0 ? ")" : ''} prefix={totalNilaiIn < 0 ? "(" : ''} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalMati} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalCulling} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalPanen} displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={(totalNilaiPanen / totalPanen) < 0 ? Math.abs((totalNilaiPanen / totalPanen).toFixed(0)) : (totalNilaiPanen / totalPanen).toFixed(0)}
                            displayType={'text'} thousandSeparator={true} suffix={(totalNilaiPanen / totalPanen) < 0 ? ")" : ''} prefix={(totalNilaiPanen / totalPanen) < 0 ? "(" : ''} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalNilaiPanen < 0 ? Math.abs(totalNilaiPanen.toFixed(0)) : totalNilaiPanen.toFixed(0)} displayType={'text'} thousandSeparator={true}
                            suffix={totalNilaiPanen < 0 ? ")" : ''} prefix={totalNilaiPanen < 0 ? "(" : ''} /></Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalSaldoAkhir < 0 ? Math.abs(totalSaldoAkhir) : totalSaldoAkhir}
                            prefix={totalSaldoAkhir < 0 ? '(' : ''} suffix={totalSaldoAkhir < 0 ? ')' : ''}
                            displayType={'text'} thousandSeparator={true} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={(totalNilaiSaldoAkhir / totalSaldoAkhir) < 0 ? Math.abs((totalNilaiSaldoAkhir / totalSaldoAkhir).toFixed(0)) : (totalNilaiSaldoAkhir / totalSaldoAkhir).toFixed(0)}
                            displayType={'text'} thousandSeparator={true} suffix={(totalNilaiSaldoAkhir / totalSaldoAkhir) < 0 ? ")" : ''} prefix={(totalNilaiSaldoAkhir / totalSaldoAkhir) < 0 ? "(" : ''} /></Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell className="right">
                          <Text><NumberFormat value={totalNilaiSaldoAkhir < 0 ? Math.abs(totalNilaiSaldoAkhir.toFixed(0)) : totalNilaiSaldoAkhir.toFixed(0)}
                            displayType={'text'} thousandSeparator={true} suffix={totalNilaiSaldoAkhir < 0 ? ")" : ''} prefix={totalNilaiSaldoAkhir < 0 ? "(" : ''} /></Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    </>
                  )
                }}
              />
            </Loading>
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

export default LiveBirdComponent