import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import { Space, Tooltip, Button, Modal, Input, Layout, Breadcrumb, Row, Col, Table, Typography } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getListDisease } from './store/penyakit-action'
import { getPenyakit, deletePenyakit, detailPenyakit, updatePenyakit } from './penyakit-aksi-page/query-penyakit'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import Loading from '../../../../common/component/loading/loading-container'
import ModalCreatePenyakit from './penyakit-aksi-page/create-penyakit-page'
import ModalEditPenyakit from './penyakit-aksi-page/edit-penyakit-page'
import { useTranslation } from 'react-i18next'
import { isMobileOnly } from 'react-device-detect'

function PenyakitPage(props) {
    const { Content } = Layout
    const { confirm } = Modal
    const { getListDisease, role, profil } = props
    const [, setSearchText] = useState('')
    const [, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState('')
    const [idData, setIdData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = () => {
        setLoading(true)
        API.get(getPenyakit('', 0, 0))
            .then(res => {
                setObject(res.data.data.diseases.diseases)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deletePenyakit(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.master.disease.resDelFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.deleteDisease.deleted === true) {
                    fetchData()
                    getListDisease(getPenyakit('', 0, 0))
                    validationMessage('success', `${t('pages.master.disease.resDelSuccess')}`)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.master.disease.resDelFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.master.disease.delConfirm')} ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(detailPenyakit(idData))
                    .then(res => {
                        setDetail(res.data.data.disease)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = (values) => {
        API.post(updatePenyakit(idData, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.master.disease.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.updateDisease._id) {
                    fetchData()
                    getListDisease(getPenyakit('', 0, 0))
                    validationMessage('success', `${t('pages.master.disease.resEditSuccess')}`)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.master.disease.resEditFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setLoading(true)
        setIdData(null)
    }

    const columns = [
        {
            title: `${t('pages.master.disease.name')}`,
            dataIndex: 'nama',
            key: 'nama',
            ...getColumnSearchProps('nama', `${t('pages.master.disease.name')}`),
        },
        {
            title: `${t('pages.master.disease.symptom')}`,
            dataIndex: 'symptom',
            key: 'symptom',
            ...getColumnSearchProps('gejala', `${t('pages.master.disease.symptom')}`),
        },
        profil.permission.writeMaster ? {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 150,
            render: (value, row, index) => (
                row.variety !== 'primary' || role === 'superadmin' ?
                    <Space size="middle">
                        <Tooltip placement="top" title={t('general.edit')}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FFA903',
                                borderRadius: '5px'
                            }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('general.del')}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FF0303',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </Tooltip>
                    </Space> : <></>
            )
        } : { width: 1 }
    ]
    const data = object.map(({ _id, name, symptom, variety }, index) => ({
        key: _id,
        nama: name,
        gejala: symptom,
        symptom: <Typography.Paragraph
            ellipsis={{
                rows: 2,
                expandable: true,
                symbol: 'more',
            }}> {symptom}</Typography.Paragraph >,
        variety: variety
    }))

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col lg={12} sm={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.disease.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={12} sm={24} hidden={!profil.permission.writeMaster}>
                        <ModalCreatePenyakit fetchData={fetchData} setLoading={setLoading} t={t} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                    <ModalEditPenyakit visible={visible} idData={idData} detail={detail}
                        handleCancel={handleCancel} handleSubmit={handleSubmit} t={t} />
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    role: state.profil.dataProfil.type,
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListDisease
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(PenyakitPage)
export default page