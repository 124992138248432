export const dashboard = {
    title: 'Dashboard Record Peternak',
    phHouse: 'Pilih Kandang',
    phPeriod: 'Pilih Periode',
    phFeedWh: 'Pilih Gudang Pakan',
    phMaterialWh: 'Pilih Gudang Material',
    phOvkWh: 'Pilih Gudang OVK',
    report: {
        livebird: 'Jumlah Ayam Hidup',
        culling: 'Jumlah Ayam Culling',
        dead: 'Jumlah Ayam Mati',
        feed: 'Total Pemberian Pakan',
        chicken: ' Ekor',
    },
    performance: {
        title: 'Performa',
        chartTitle: 'Grafik Performa Kandang',
        age: 'Umur',
        day: 'hari',
        chicken: 'ekor',
        population: 'Populasi',
        bw: 'BW',
        depletion: 'Deplesi',
        adg: 'ADG',
        fcr: 'FCR',
        value: 'Nilai',
        standarBw: 'Standar Body Weight',
        standarDepletion: 'Standar Deplesi',
        standarFcr: 'Standar FCR',
        standarFi: "Standar Feed Intake"
    },
    iot: {
        title: 'Iklim',
        chartTitle: 'Grafik Keadaan Lingkungan Kandang',
        thiChartTitle: 'Grafik THI',
        phParameter: 'Pilih Parameter',
        hour: 'Jam',
        day: 'Hari',
        phTool: 'Pilih Alat',
        date: 'Tanggal',
        humidity: 'Kelembapan',
        temperature: 'Suhu (°C)',
        airPressure: 'Tekanan Udara',
        value: 'Nilai',
        updatedAt: 'Terakhir diperbarui:',
        nodevice: 'Anda belum punya device IoT BroilerX yang terdaftar',
        notelemetry: 'Silakan pilih alat Anda dahulu untuk melihat informasi selengkapnya',
        selfHumidity: 'Penskalaan kelembapan sendiri'
    },
    price: {
        title: 'Info Harga',
        soon: 'Fitur Info Harga Ayam akan segera hadir',
        livebird: 'Livebird',
        carcass: 'Karkas',
        prediction: 'Prediksi',
        phSearch: 'Cari kota',
        titlePage: 'Info Harga Unggas Indonesia',
        source: 'Sumber',
        city: 'Kota',
        chartTitle: 'Linimasa Harga',
        price: 'Harga (Rp)',
        nearby: 'Harga di Kota Terdekat'
    },
    feed: {
        title: 'Penggunaan Pakan',
        chart: 'Grafik Penggunaan Pakan',
        age: 'Umur (hari)',
        qty: 'Jumlah (kg)',
        feed: 'Pakan',
        total: 'Total',
        day: 'Hari',
        days: 'hari'
    }
}