import * as actionType from './strain-action-type';

const setIdStrain = payload => ({
    type : actionType.SET_ID_STRAIN,
    payload,
})

const setDataStrain = payload => ({
    type : actionType.SET_DATA_STRAIN,
    payload,
})

const setStrain = payload => ({
    type : actionType.SET_STRAIN,
    payload,
})

export const getIdStrain = (data) => (dispatch) => {
    dispatch(setIdStrain(data))
}

export const getDataStrain = (data) => (dispatch) => {
    dispatch(setDataStrain(data));
}

export const getNameStrain = (data) => (dispatch) => {
    dispatch(setStrain(data))
}




