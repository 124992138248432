import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Button, Layout, Row, Col, Card, Divider, Badge } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import CreateInvoice from './create-invoice-page'
import { useTranslation } from "react-i18next"

function PricingPage(props) {
    const [visibleMonth, setVisibleMonth] = useState(false)
    const [visibleAnnual, setVisibleAnnual] = useState(false)
    const [total, setTotal] = useState(0)
    const { t } = useTranslation()

    const showModalMonth = useCallback(() => {
        setVisibleMonth(true)
        setTotal(300000)
    }, [])

    const showModalAnnual = useCallback(() => {
        setVisibleAnnual(true)
        setTotal(2400000)
    }, [])

    return (
        <Layout>
            <Layout.Content className="dashboard-container">
                <p className='font-semi-bold font-title-login text-center mb-0'>{t("pages.subscription.pricingTitle")}</p>
                <p className='font-regular text-center mb-20'>{t("pages.subscription.pricingDesc")}</p>
                <Row gutter={[16, 16]}>
                    <Col lg={6} sm={24}>
                        <Card hoverable className='text-center' style={{ height: '755px' }}>
                            <p className='font-title-dashboard font-semi-bold mb-0'>{t("pages.subscription.free")}</p>
                            <p>{t("pages.subscription.freeDesc")}</p>
                            <span className='font-bold font-title'><sup className='font-light font-content'>Rp</sup>0</span>
                            <p className='white'>.</p>
                            <Divider style={{ borderColor: 'black' }} />
                            <Row gutter={[16, 8]} className='text-left'>
                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.free1")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.free2")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.free3")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.free4")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.free5")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.free6")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.free7")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.free8")}</p></Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col lg={6} sm={24}>
                        <Card hoverable className='text-center white' style={{
                            backgroundImage: 'linear-gradient(135deg, rgb(0, 100, 80) 0%, rgb(25, 230, 140) 100%)',
                            height: '755px'
                        }}>
                            <p className='font-title-dashboard font-semi-bold mb-0'>Premium</p>
                            <p>{t("pages.subscription.monthly")}</p>
                            <span className='font-bold font-title'><sup className='font-light font-content'>Rp</sup>300</span>
                            <span className='font-content font-semi-bold'>{t("pages.subscription.k")}</span>
                            <p>{t("pages.subscription.perHouse")}</p>
                            <Divider style={{ borderColor: 'white' }} />
                            <Row gutter={[16, 8]} className='text-left' style={{ marginBottom: '175px' }}>
                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.monthly1")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.monthly2")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.monthly3")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.monthly4")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.monthly5")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.monthly6")}</p></Col>
                                <Col className='mt-5'><p>{t("pages.subscription.maxHouse")}</p></Col>
                            </Row>
                            <CreateInvoice type='monthly' showModal={showModalMonth} setVisible={setVisibleMonth} visible={visibleMonth} total={total} setTotal={setTotal} t={t} />
                        </Card>
                    </Col>
                    <Col lg={6} sm={24}>
                        <Card hoverable className='text-center white' style={{
                            backgroundImage: 'linear-gradient(135deg, rgb(84, 141, 208) 0%, rgb(54, 0, 201) 100%)',
                            height: '755px'
                        }}>
                            <p className='font-title-dashboard font-semi-bold mb-0'>Premium</p>
                            <p>{t("pages.subscription.annual")}</p>
                            <Row>
                                <Col span={20}>
                                    <sup className='font-light font-content'>Rp</sup><span className='font-medium font-title-dashboard' style={{ textDecoration: 'line-through' }}>3600</span><span className='font-content font-semi-bold'>{t("pages.subscription.k")}</span>
                                    <span className='font-bold font-title ml-20'><sup className='font-light font-content'>Rp</sup>2400</span><span className='font-content font-semi-bold'>{t("pages.subscription.k")}</span>
                                </Col>
                                <Col span={4}>
                                    <Badge.Ribbon text="34% off" color="magenta" style={{ marginTop: '-15px' }}>
                                    </Badge.Ribbon>
                                </Col>
                            </Row>
                            <p>{t("pages.subscription.perHouse")}</p>
                            <Divider style={{ borderColor: 'white' }} />
                            <Row gutter={[16, 8]} className='text-left'>
                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.annual1")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.annual2")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.annual3")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.annual4")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.annual5")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.annual6")}</p></Col>
                                <Col span={24} className='font-semi-bold'>{t("pages.subscription.annual7")}</Col>
                                <Col span={20}>
                                    <sup className='font-light font-content'>Rp</sup><span className='font-medium font-title-dashboard' style={{ textDecoration: 'line-through' }}>3600</span><span className='font-content font-semi-bold'>{t("pages.subscription.k")}</span>
                                    <span className='font-bold font-title ml-20'><sup className='font-light font-content'>Rp</sup>1800</span><span className='font-content font-semi-bold'>{t("pages.subscription.k")}</span>
                                </Col>
                                <Col span={4}>
                                    <Badge.Ribbon text="50% off" color="magenta" style={{ marginTop: '-15px' }}>
                                    </Badge.Ribbon>
                                </Col>
                                <Col className='mt-5'>{t("pages.subscription.maxHouse")}</Col>
                            </Row>
                            <CreateInvoice type='annual' showModal={showModalAnnual} setVisible={setVisibleAnnual} visible={visibleAnnual} total={total} setTotal={setTotal} t={t} />
                        </Card>

                    </Col>
                    <Col lg={6} sm={24}>
                        <Card hoverable className='text-center white' style={{
                            backgroundImage: 'linear-gradient(135deg, rgb(200, 125, 85) 0%, rgb(245, 155, 35) 100%)',
                            height: '755px'
                        }}>
                            <p className='font-title-dashboard font-semi-bold mb-0'>Platinum</p>
                            <p>{t("pages.subscription.platinum")}</p>
                            <span className='font-bold font-title-login'>{t("pages.subscription.contact")}</span>
                            <p onClick={() => window.open('https://wa.me/6285155050769')}>+62851 5505 0769</p>
                            <Divider style={{ borderColor: 'white' }} className='mt-40' />
                            <Row gutter={[16, 8]} className='text-left'>
                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.platinum1")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.platinum2")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.platinum3")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.platinum4")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.platinum5")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.platinum6")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} size='lg' /></Col>
                                <Col span={22}><p className='mb-0 font-medium'>{t("pages.subscription.platinum7")}</p></Col>
                            </Row>
                            <Button block className='button-bottom'
                                onClick={() => window.open('https://wa.me/6285155050769')}>{t("pages.subscription.getStarted")}</Button>
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
})
const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(PricingPage)
export default page