import React, { useState } from 'react'
import { Modal, Form, Row, Col, InputNumber, Input, Select, Button, Tooltip } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function CreatePanenComponent(props) {
    const { visible, handleSubmit, setVisible, customer, houseActive, t } = props
    const [form] = Form.useForm()
    const [berat, setBerat] = useState(0)
    const [price, setPrice] = useState(0)
    const [total, setTotal] = useState(0)

    const onChangeWeight = (val) => {
        form.setFieldsValue({
            price: parseFloat((total / val).toFixed(2)),
            total: val * price,
        })
        setBerat(val)
        setPrice(parseFloat((total / val).toFixed(2)))
        setTotal(val * price)
    }
    const onChangePrice = (val) => {
        form.setFieldsValue({
            total: val * berat
        })
        setPrice(val)
        setTotal(val * berat)
    }
    const onChangeTotal = val => {
        form.setFieldsValue({
            price: parseFloat((val / berat).toFixed(2))
        })
        setTotal(val)
        setPrice(parseFloat((val / berat).toFixed(2)))
    }

    return (
        <>
            <Tooltip title={houseActive ? '' : t("error.inactiveHouse")}>
                <Button
                    disabled={houseActive ? false : true}
                    style={{
                        color: 'white',
                        backgroundColor: houseActive ? '#008ad4' : 'whitesmoke',
                        borderRadius: '5px',
                        float: "right",
                        border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
                    }} onClick={() => setVisible(true)}
                ><FontAwesomeIcon icon={faPlusSquare} className='mr-10' />
                    {t('pages.recording.harvest.add')}
                </Button>
            </Tooltip>

            <Modal
                visible={visible}
                title={t('pages.recording.harvest.add')}
                okText={t('general.add')}
                width={800} centered
                cancelText={t('general.cancel')}
                onCancel={() => setVisible(false)}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            handleSubmit(values)
                            form.resetFields()
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{ keterangan: '', ayam: null, price: null, berat: null, total: null, tonase: 0, }}
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >Plat Nomor</span>
                            <Form.Item
                                name="plat" rules={[{ required: false, message: `Mohon masukkan plat nomor` }]}
                            >
                                <Input
                                    placeholder="AB ..."
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">Tonase DO</span>
                            <Form.Item
                                name="tonase" rules={[{ required: false, message: `Mohon masukkan tonase DO` }]}
                            >
                                <InputNumber
                                    placeholder="1000..."
                                    className="input-number"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                    addonAfter='kg'
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.recording.harvest.qty')}</span>
                            <Form.Item
                                name="ayam" rules={[{ required: false, message: `${t('pages.recording.harvest.msgQty')}` }]}
                            >
                                <InputNumber
                                    placeholder="1000..."
                                    className="input-number"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.harvest.weight')}</span>
                            <Form.Item
                                name="berat" rules={[{ required: false, message: `${t('pages.recording.harvest.msgWeight')}` }]}
                            >
                                <InputNumber
                                    onChange={(value) => onChangeWeight(value)}
                                    placeholder="1000..."
                                    className="input-number"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.recording.harvest.price')}</span>
                            <Form.Item
                                name="price" rules={[{ required: false, message: `${t('pages.recording.harvest.msgPrice')}` }]}
                            >
                                <InputNumber
                                    onChange={(value) => onChangePrice(value)}
                                    placeholder="1000..."
                                    className="input-number mt-5"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.harvest.total')}</span>
                            <Form.Item
                                name="total" rules={[{ required: false, message: `${t('pages.recording.harvest.msgTotal')}` }]}
                            >
                                <InputNumber
                                    onChange={(value) => onChangeTotal(value)}
                                    placeholder="1000..."
                                    className="input-number mt-5"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.recording.harvest.customer')}</span>
                            <Form.Item
                                name="customer" rules={[{ required: true, message: `${t('pages.recording.harvest.msgCustomer')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.recording.harvest.phCustomer')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                >
                                    {
                                        customer.map(data =>
                                            <Select.Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.recording.harvest.desc")}</span>
                            <Form.Item
                                name="keterangan"
                            >
                                <Input.TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={t("pages.recording.harvest.phDesc")}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

export default CreatePanenComponent