export const auth = {
    loginDesc: "Login with Your Account",
    password: 'Password',
    phEmailLogin: 'Input your email or username',
    phPassword: 'Input your password',
    msgEmailLogin: 'Please input your email or username!',
    msgPassword: 'Please input your password at least 6 characters!',
    login: 'Login',
    verif: 'Resend Verification Link',
    forgetMsg: 'Forgot your password?',
    forgetDesc: 'Enter your email address and we’ll send you a recovery link to create new password.',
    resetPassword: 'Reset your Password',
    resetPasswordDesc: 'Enter your new password to change your password.',
    loginOpt: 'Or login using',
    registerMsg: "Don't have account?",
    registerCta: 'Register Now',
    register: 'Register',
    registerDesc: 'Or Fill this Form to Register',
    name: 'Fullname',
    phName: "Input your fullname",
    msgName: 'Please input your fullname!',
    email: 'Email',
    phEmail: "Input your email",
    msgEmail: 'Please input your email!',
    username: 'Username',
    phUsername: "Input your username",
    msgUsername: 'Please input your username at least 4 characters!',
    phone: 'Phone number',
    phPhone: "Input your phone number",
    msgPhone: 'Please input your phone number!',
    address: 'Address',
    phAddress: "Input your address",
    msgAddress: 'Please input your address!',
    company: 'Company / Farming Name',
    phCompany: "Input your company name",
    msgCompany: 'Please input your company name!',
    companyType: 'Farming type',
    phCompanyType: "Select your farming type",
    msgCompanyType: 'Please select your farming type!',
    kemitraan: 'Partnership',
    mandiri: 'Personal',
    riset: 'Research/Research Farm',
    house: 'House Name',
    msgHouse: 'Please input your house name!',
    phHouse: "Input your house name",
    capacity: 'Capacity',
    msgCapacity: 'Please input your house capacity!',
    houseAddr: 'House Address',
    phHouseAddr: 'Input your house address',
    msgHouseAddr: 'Please input your house address!',
    msgIdAddr: 'Please select one from the suggested addresses',
    agree: 'I have read and agree to ',
    term: 'terms and policy',
    regisOpt: 'Register using',
    loginMsg: "Have an account?",
    loginCta: 'Login Now',
    fillForm: 'Successfully get the account info. Please complete other data',
    captcha: 'Recapcha must be checked!',
    msgTerm: 'Term and policy must be checked!',
    resLoginSuccess: "Login successful",
    resLoginFailed: "Login failed",
    resRegisSuccess: "Registration successful. Please check email to verify your account!",
    resRegisOauthSuccess: "Registration successful. Please login with your account!",
    resRegisFailed: "Registration failed",
    resResendSuccess: 'Successfully resend verification link to your email. Please check your inbox or spam',
    resResetSuccess: 'Successfully changed your password',
    resForgotSuccess: 'Successfully send recovery link. Please check your email'
}