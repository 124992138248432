export const auth = {
    loginDesc: "Masuk menggunakan akun Anda",
    password: 'Kata sandi',
    phEmailLogin: 'Masukkan email atau username Anda',
    phPassword: 'Masukkan sandi Anda',
    msgEmailLogin: 'Mohon masukkan email atau username Anda!',
    msgPassword: 'Mohon masukkan sandi Anda minimal 6 karakter!',
    login: 'Masuk',
    verif: 'Kirim Ulang Link Verifikasi',
    forgetMsg: 'Lupa kata sandi Anda?',
    forgetDesc: 'Masukkan email Anda dan kami akan mengirimkan link recovery untuk membuat kata sandi baru.',
    resetPassword: 'Reset Kata Sandi Anda',
    resetPasswordDesc: 'Masukkan kata sandi baru Anda untuk mengubah kata sandi Anda.',
    loginOpt: 'Atau masuk menggunakan',
    registerMsg: "Belum punya akun? ",
    registerCta: 'Daftar Sekarang',
    register: 'Daftar',
    registerDesc: 'Atau Isi Form di bawah untuk Registrasi Akun Anda',
    name: 'Nama Lengkap',
    phName: "Masukkan nama lengkap Anda",
    msgName: 'Mohon masukkan nama lengkap Anda!',
    email: 'Email',
    phEmail: "Masukkan email Anda",
    msgEmail: 'Mohon masukkan email Anda!',
    username: 'Username',
    phUsername: "Masukkan username Anda",
    msgUsername: 'Mohon masukkan username Anda minimal 4 karakter!',
    phone: 'Nomor Telepon',
    phPhone: "Masukkan nomor telepon Anda",
    msgPhone: 'Mohon masukkan nomor telepon Anda!',
    address: 'Alamat',
    phAddress: "Masukkan alamat lengkap Anda",
    msgAddress: 'Mohon masukkan alamat lengkap Anda!',
    company: 'Nama Usaha Peternakan',
    phCompany: "Masukkan nama usaha Anda",
    msgCompany: 'Mohon masukkan nama usaha Anda!',
    companyType: 'Jenis Usaha Peternakan',
    phCompanyType: "Pilih jenis usaha Anda",
    msgCompanyType: 'Mohon pilih jenis usaha Anda!',
    kemitraan: 'Kemitraan',
    mandiri: 'Mandiri',
    riset: 'Peternakan Riset/Penelitian',
    house: 'Nama Kandang',
    msgHouse: 'Mohon masukkan nama kandang Anda!',
    phHouse: "Masukkan nama kandang Anda",
    capacity: 'Kapasitas',
    msgCapacity: 'Mohon masukkan kapasitas kandang anda!',
    houseAddr: 'Alamat Kandang',
    phHouseAddr: 'Masukkan alamat kandang Anda',
    msgHouseAddr: 'Mohon masukan alamat kandang Anda!',
    msgIdAddr: 'Mohon pilih salah satu alamat yang disarankan',
    agree: 'Saya sudah membaca dan menyetujui ',
    term: 'Syarat dan Ketentuan',
    regisOpt: 'Registrasi menggunakan',
    loginMsg: "Sudah punya akun?",
    loginCta: 'Masuk Sekarang',
    fillForm: 'Berhasil mendapatkan informasi akun. Silakan lengkapi data yang lain',
    captcha: 'Recapcha wajib dicentang!',
    msgTerm: 'Syarat dan ketentuan wajib dicentang!',
    resLoginSuccess: "Login berhasil",
    resLoginFailed: "Login gagal",
    resRegisSuccess: "Registrasi berhasil. Mohon cek email Anda untuk verifikasi akun!",
    resRegisOauthSuccess: "Registrasi berhasil. Silakan login dengan akun Anda",
    resRegisFailed: "Registrasi gagal",
    resResendSuccess: 'Berhasil mengirimkan link verifikasi ke email Anda. Silakan cek kotak masuk atau spam',
    resResetSuccess: 'Berhasil mengubah sandi Anda',
    resForgotSuccess: 'Berhasil mengirimkan link recovery. Silakan cek email Anda'
}