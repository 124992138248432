import React from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, DatePicker, InputNumber, Select, Alert } from 'antd'
import { getPriceFeeds } from '../../../rearing-record-page/rearing-record-aksi-page/query-rearing-record'
import { API } from '../../../../../common/api'
import moment from 'moment'

function TambahMutasi(props) {
    const { visible, handleCancel, handleSubmit, gudangPakan, idGudangPakan, idFeed, profil, t } = props
    const [form] = Form.useForm()

    const onChangeFeed = async (value) => {
        const month = moment(value).format('MMMM')
        const year = moment(value).format('YYYY')
        let monthStock = []

        await API.get(getPriceFeeds('', 0, 0, month, year, idGudangPakan))
            .then(res => {
                monthStock = res.data.data.monthlyStockFeeds.calculateMonthlyStocks
            }).catch(error => {
                monthStock = []
            })
        const price = monthStock.find(d => d.idFeed === idFeed)
        form.setFieldsValue({
            harga: price?.hargaPerKarung
        })
    }

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        return (
            <Modal
                visible={visible}
                title={t('pages.feedStock.addMutation')}
                okText={t('general.add')}
                width={500}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                okButtonProps={!profil.permission.nominalRecording &&
                    (!form.getFieldValue('harga') || form.getFieldValue('harga') < 1) ?
                    { disabled: true } : { disabled: false }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                >
                    {(!profil.permission.nominalRecording && (!form.getFieldValue('harga') || form.getFieldValue('harga') < 1)) &&
                        <Alert message="Tidak ditemukan harga untuk pakan ini. Mohon hubungi manajemen Anda untuk memperbarui stockflow!"
                            showIcon type="error" className='mb-20' />}
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.feedStock.dateMutation')}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, type: 'object', message: `${t('pages.feedStock.msgMutationDate')}` }]}
                            >
                                <DatePicker
                                    placeholder={t('pages.feedStock.phMutationDate')}
                                    className="select-input-date"
                                    onChange={e => onChangeFeed(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.feedStock.qtyStock')}</span>
                            <Form.Item
                                name="jumlahStok" rules={[{ required: true, message: `${t('pages.feedStock.msgQtyStock')}` }]}
                            >
                                <InputNumber
                                    placeholder="50..."
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                    className="input-number"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} hidden={!profil.permission.nominalRecording}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.feedStock.priceFeed')}</span>
                            <Form.Item
                                name="harga" rules={[{ required: true, message: `${t('pages.feedStock.msgPrice')}` }]}
                            >
                                <InputNumber
                                    placeholder="1000"
                                    className="input-number"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.feedStock.mutationWh')}</span>
                            <Form.Item
                                name="warehouse" rules={[
                                    { required: true, message: `${t('pages.feedStock.msgMutationWh')}` }
                                ]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.feedStock.phMutationWh')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        gudangPakan.filter(d => d._id !== idGudangPakan).map(data =>
                                            <Select.Option
                                                value={data._id}
                                            >{data.name}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TambahMutasi)
export default page