import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { navigate } from '../../../../../common/store/action'
import { Modal, Form, Row, Col, Input, Select, InputNumber } from 'antd'
import Autocomplete from "react-google-autocomplete"
import InputMaps from '../../../../../common/component/input/input-maps'

function EditKandangPage(props) {
    const { visible, handleCancel, detail, handleSubmit, gudangOvk, gudangPakan, t } = props
    const { TextArea } = Input
    const { Option } = Select
    const [form] = Form.useForm();

    const [state, setState] = useState({
        isMap: false,
        position: {},
        zoom: 10,
        center: {},
    })

    useEffect(() => {
        form.setFieldsValue({
            namaKandang: detail.name,
            alamat: detail.address,
            alamatMaps: detail.address,
            selectGudangPakan: detail.feedWarehouse,
            selectGudangOvk: detail.ovkWarehouse,
            kapasistasKandang: detail.capacity,
        })
        const position = { lat: Number(detail.lat), lng: Number(detail.lng) }
        setState((prev) => ({
            ...prev,
            position: position,
            center: position,
        }))
    }, [detail, form, setState])

    return (
        <Modal
            visible={visible}
            title={t('pages.master.house.edit')}
            okText={t('general.edit')}
            width={800}
            cancelText={t('general.cancel')}
            onCancel={() => handleCancel()}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields()
                        handleSubmit(values)
                        setState((prev) => ({
                            ...prev,
                            isMap: false,
                            position: {},
                            zoom: 10,
                            center: {},
                        }))
                    })
                    .catch((info) => {
                        console.log('Validate Failed:', info)
                    })
            }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                    namaKandang: detail.name,
                    alamat: detail.address,
                    alamatMaps: detail.address,
                    selectGudangPakan: detail.feedWarehouse,
                    selectGudangOvk: detail.ovkWarehouse,
                    kapasistasKandang: detail.capacity,
                    position: state.position,
                }}
            >
                <Row gutter={[16, 8]}>
                    <Col span={12}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.house.name')}</span>
                        <Form.Item
                            name="namaKandang" rules={[{ required: true, message: `${t('pages.master.house.msgName')}` }]}
                        >
                            <Input
                                name='namaKandang'
                                placeholder="Kandang 1 Lantai 1..."
                                className="input-form"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <span className="font-semi-bold black font-title-medium">{t('pages.master.house.capacity')}</span>
                        <Form.Item
                            name="kapasistasKandang" rules={[{ required: true, message: `${t('pages.master.house.capacity')}` }]}
                        >
                            <InputNumber
                                name='kapasistasKandang'
                                placeholder="1000..."
                                className="input-number"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <span className="black font-semi-bold font-title-content">{t('pages.master.house.feedWh')}</span>
                        <Form.Item
                            name="selectGudangPakan" rules={[{ required: true, message: `${t('pages.master.house.msgFeedWh')}` }]}
                        >
                            <Select
                                showSearch
                                labelInValue
                                placeholder={t('pages.master.house.phFeedWh')}
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                className="select-kategori black font-regular"
                            >
                                {
                                    gudangPakan.map(data =>
                                        <Option
                                            key={data._id}
                                            value={data._id}
                                        >{data.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <span className="black font-semi-bold font-title-content">{t('pages.master.house.ovkWh')}</span>
                        <Form.Item
                            name="selectGudangOvk" rules={[{ required: true, message: `${t('pages.master.house.msgOvkWh')}` }]}
                        >
                            <Select
                                showSearch
                                labelInValue
                                placeholder={t('pages.master.house.phOvkWh')}
                                optionFilterProp="children"
                                style={{ width: '100%' }}
                                className="select-kategori black font-regular"
                            >
                                {
                                    gudangOvk.map(data =>
                                        <Option
                                            key={data._id}
                                            value={data._id}
                                        >{data.name}</Option>
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <span className="black font-semi-bold font-title-content ml-10" >{t('pages.master.house.address')}</span>
                    {!state.isMap &&
                        <Col span={24}>
                            <Form.Item className='mb-0'
                                name="alamat" rules={[{ required: !state.isMap, message: `${t('pages.master.house.msgAddress')}` }]}
                            >
                                <Autocomplete
                                    placeholder={t('pages.master.house.phAddress')}
                                    className='ant-input input-register'
                                    apiKey={process.env.REACT_APP_GOOGLE_TOKEN}
                                    onPlaceSelected={place => {
                                        form.setFieldsValue({ position: place.geometry.location.toJSON(), alamat: place.formatted_address })
                                    }}
                                    options={{
                                        types: ["geocode", "establishment"],
                                    }}
                                />
                            </Form.Item>
                        </Col>}
                    {state.isMap &&
                        <Col span={24}>
                            <Form.Item className='mb-0'
                                name="alamatMaps" rules={[{ required: state.isMap, message: `${t('pages.master.house.msgAddress')}` }]}
                            >
                                <InputMaps
                                    center={state.center} zoom={state.zoom}
                                    onClick={(e) => {
                                        setState((prev) => ({
                                            ...prev,
                                            position: e.latLng,
                                        }))
                                        form.setFieldsValue({ position: e.latLng.toJSON() })
                                    }}
                                    onIdle={e =>
                                        setState((prev) => ({
                                            ...prev,
                                            zoom: e.getZoom(),
                                            center: e.getCenter().toJSON()
                                        }))
                                    }
                                    position={state.position}
                                    onChange={(e) => form.setFieldsValue({ alamat: e.target.value })}
                                    placeholder={t('pages.master.house.phAddress')}
                                    value={form.getFieldValue('alamat')}
                                />
                            </Form.Item>
                        </Col>}
                    <Link className='ml-10 mb-10'
                        onClick={() => {
                            setState((prev) => ({
                                ...prev,
                                isMap: !state.isMap,
                                // position: null
                            }))
                            // form.resetFields(['position', 'alamat'])
                        }}>
                        {state.isMap ?
                            'Cari menggunakan pencarian alamat' :
                            'Tidak menemukan alamat yang dicari? Coba pilih lokasi menggunakan Maps'
                        }
                    </Link>
                    <Col span={24}>
                        <span className="black font-semi-bold font-title-content" >{t('pages.master.house.desc')}</span>
                        <TextArea
                            style={{ borderColor: "#BE375F" }}
                            placeholder={t('pages.master.house.phDesc')}
                            autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                    </Col>
                    <Col hidden>
                        <Form.Item name="position" >
                            <Input className="input-register" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
    gudangPakan: state.gudangPakan.dataGudangPakan,
    gudangOvk: state.gudangOvk.dataGudangOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditKandangPage)
export default page