import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input } from 'antd'
import NumberFormat from 'react-number-format'
import { SearchOutlined } from '@ant-design/icons'
import { API } from '../../../../common/api'
import LiveBirdComponent from '../../../../modules/dashboard-peternak/stock-flow-component/live-bird-component/live-bird-component'
import { getMonthlyStockLiveBirds } from './live-bird-aksi-page/query-stock-flow-live-bird'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

import { useTranslation } from 'react-i18next'
import { isMobileOnly } from 'react-device-detect'

function LiveBirdPage(props) {
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const [idStockflow, setId] = useState(null)
    const [month, setMonth] = useState(moment().locale('id').format('MMMM'))
    const [year, setYear] = useState(moment().locale('id').format('YYYY'))

    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    /**Handle Get Data */
    const fetchData = (month, year) => {
        setLoading(true)
        API.get(getMonthlyStockLiveBirds('', 0, 0, month, year))
            .then(res => {
                setObject(res.data.data.monthlyStockLiveBirds.calculateMonthlyStocks)
                setId(res.data.data.monthlyStockLiveBirds.idMonthlyStock)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setId(null)
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData(month, year)
    }, [month, year])

    const columns = [
        {
            title: `${t('pages.stockflow.house')}`,
            dataIndex: 'kandang',
            key: 'kandang',
            fixed: !isMobileOnly && 'left',
            sorter: (a, b) => a.kandang.localeCompare(b.kandang, 'en', { numeric: true }),
            defaultSortOrder: 'ascend',
            width: 220,
            ...getColumnSearchProps('kandang', `${t('pages.stockflow.house')}`),
        },
        {
            title: `${t('pages.stockflow.saw')}`,
            children: [
                {
                    title: `${t('pages.stockflow.qtyLivebird')}`,
                    className: 'right',
                    dataIndex: 'qtyAwal',
                    key: 'qtyAwal',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.hsLivebird')}`,
                    className: 'right',
                    dataIndex: 'hargaSatuanAwal',
                    key: 'hargaSatuanAwal',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.total')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalAwal',
                    key: 'hargaTotalAwal',
                    width: 150,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.in')}`,
            children: [
                {
                    title: `${t('pages.stockflow.qtyLivebird')}`,
                    className: 'right',
                    dataIndex: 'qtyPengadaan',
                    key: 'qtyPengadaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.hsLivebird')}`,
                    className: 'right',
                    dataIndex: 'hsPengadaan',
                    key: 'hsPengadaan',
                    width: 100,
                },
                {
                    title: `${t('pages.stockflow.total')}`,
                    className: 'right',
                    dataIndex: 'totalPengadaan',
                    key: 'totalPengadaan',
                    width: 150,
                },
            ],
        },
        {
            title: `${t('pages.stockflow.depletion')}`,
            children: [
                {
                    title: `${t('pages.stockflow.qtyLivebird')}`,
                    className: 'right',
                    dataIndex: 'totalDeplesi',
                    key: 'totalDeplesi',
                    width: 110,
                    ...getColumnSearchProps('totalDeplesi', `${t('pages.stockflow.qtyLivebird')}`),
                }
            ],
        },
        {
            title: `${t('pages.stockflow.culling')}`,
            children: [
                {
                    title: `${t('pages.stockflow.qtyLivebird')}`,
                    className: 'right',
                    dataIndex: 'totalCulling',
                    key: 'totalCulling',
                    width: 110,
                    ...getColumnSearchProps('totalCulling', `${t('pages.stockflow.qtyLivebird')}`),
                }
            ]
        },
        {
            title: `${t('pages.stockflow.harvest')}`,
            children: [
                {
                    title: `${t('pages.stockflow.qtyLivebird')}`,
                    className: 'right',
                    dataIndex: 'totalPanen',
                    key: 'totalPanen',
                    width: 110,
                    ...getColumnSearchProps('totalPanen', `${t('pages.stockflow.qtyLivebird')}`),
                },
                {
                    title: `${t('pages.stockflow.hsLivebird')}`,
                    className: 'right',
                    dataIndex: 'hsPanen',
                    key: 'hsPanen',
                    width: 140,
                    ...getColumnSearchProps('hsPanen', `${t('pages.stockflow.hsLivebird')}`),
                },
                {
                    title: `${t('pages.stockflow.total')}`,
                    className: 'right',
                    dataIndex: 'totalNilaiPanen',
                    key: 'totalNilaiPanen',
                    width: 150,
                    ...getColumnSearchProps('totalNilaiPanen', `${t('pages.stockflow.total')}`),
                },
            ],
        },
        {
            title: `${t('pages.stockflow.sak')}`,
            children: [
                {
                    title: `${t('pages.stockflow.qtyLivebird')}`,
                    className: 'right',
                    dataIndex: 'qtyStokAkhir',
                    key: 'qtyStokAkhir',
                    width: 110,
                    ...getColumnSearchProps('qtyStokAkhir', `${t('pages.stockflow.qtyLivebird')}`),
                },
                {
                    title: `${t('pages.stockflow.hsLivebird')}`,
                    className: 'right',
                    dataIndex: 'hsStokAkhir',
                    key: 'hsStokAkhir',
                    width: 140,
                    ...getColumnSearchProps('hsStokAkhir', `${t('pages.stockflow.hsLivebird')}`),
                },
                {
                    title: `${t('pages.stockflow.total')}`,
                    className: 'right',
                    dataIndex: 'hargaTotalStokAkhir',
                    key: 'hargaTotalStokAkhir',
                    width: 150,
                    ...getColumnSearchProps('hargaTotalStokAkhir', `${t('pages.stockflow.total')}`),
                }
            ]
        }
    ]
    const filter1 = object.filter(a => a !== null)
    const filter2 = filter1.filter(a => a.stockAwalBulan !== 0 || a.hargaAwalBulan !== 0 || a.pembelian !== 0 || a.hsPembelian !== 0
        || a.hpp !== 0 || a.hppPanen !== 0 || a.totalCulling !== 0 || a.totalMati !== 0 || a.totalPanen !== 0)

    const data = filter2.map(({ _id, kandang, periode, stockAwalBulan, hargaAwalBulan, totalHargaAwal, pembelian, hsPembelian, nilaiPembelian,
        hpp, hppPanen, totalCulling, totalMati, totalPanen, nilaiPanen, sisaStok, nilaiStokAkhir }, index) => ({
            key: _id,
            kandang: kandang + ' ' + (periode === null ? ' ' : periode),
            periode: periode === null ? ' ' : periode,

            stokAwal: stockAwalBulan,
            hargaAwalBulan: hargaAwalBulan,
            hargaTotalAwalBulan: totalHargaAwal,
            qtyAwal: stockAwalBulan < 0 ? <NumberFormat suffix={")"} prefix={"("} value={Math.abs(stockAwalBulan)} displayType={'text'} thousandSeparator={true} /> :
                <NumberFormat value={stockAwalBulan} displayType={'text'} thousandSeparator={true} />,
            hargaSatuanAwal: <NumberFormat value={hargaAwalBulan < 0 ? Math.abs(hargaAwalBulan.toFixed(0)) : hargaAwalBulan.toFixed(0)} displayType={'text'} thousandSeparator={true}
                suffix={hargaAwalBulan < 0 ? ")" : ''} prefix={hargaAwalBulan < 0 ? "(" : ''} />,
            hargaTotalAwal: totalHargaAwal < 0 ? <NumberFormat suffix={")"} prefix={"("} value={Math.abs(totalHargaAwal.toFixed(0))} displayType={'text'} thousandSeparator={true} /> :
                <NumberFormat value={totalHargaAwal.toFixed(0)} displayType={'text'} thousandSeparator={true} />,

            pengadaan: pembelian,
            hargaPengadaan: hsPembelian,
            totalHargaPengadaan: nilaiPembelian,
            qtyPengadaan: <NumberFormat value={pembelian} displayType={'text'} thousandSeparator={true} />,
            hsPengadaan: <NumberFormat value={hsPembelian < 0 ? Math.abs(hsPembelian.toFixed(0)) : hsPembelian.toFixed(0)} displayType={'text'} thousandSeparator={true}
                suffix={hsPembelian < 0 ? ")" : ''} prefix={hsPembelian < 0 ? "(" : ''} />,
            totalPengadaan: <NumberFormat value={nilaiPembelian < 0 ? Math.abs(nilaiPembelian.toFixed(0)) : nilaiPembelian.toFixed(0)} displayType={'text'} thousandSeparator={true}
                suffix={nilaiPembelian < 0 ? ")" : ''} prefix={nilaiPembelian < 0 ? "(" : ''} />,

            culling: totalCulling,
            totalCulling: <NumberFormat value={totalCulling} displayType={'text'} thousandSeparator={true} />,

            mati: totalMati,
            totalDeplesi: <NumberFormat value={totalMati} displayType={'text'} thousandSeparator={true} />,

            panen: totalPanen || 0,
            hargaPanen: hppPanen || 0,
            totalHargaPanen: nilaiPanen || 0,
            totalPanen: <NumberFormat value={totalPanen} displayType={'text'} thousandSeparator={true} />,
            hsPanen: <NumberFormat value={hppPanen < 0 ? Math.abs(hppPanen?.toFixed(0)) : hppPanen?.toFixed(0)} displayType={'text'} thousandSeparator={true}
                suffix={hppPanen < 0 ? ")" : ''} prefix={hppPanen < 0 ? "(" : ''} />,
            totalNilaiPanen: <NumberFormat value={nilaiPanen < 0 ? Math.abs(nilaiPanen?.toFixed(0)) : nilaiPanen?.toFixed(0)} displayType={'text'} thousandSeparator={true}
                suffix={nilaiPanen < 0 ? ")" : ''} prefix={nilaiPanen < 0 ? "(" : ''} />,

            stokAkhir: sisaStok || 0,
            hargaStokAkhir: hpp || 0,
            totalHargaStokAkhir: nilaiStokAkhir || 0,
            qtyStokAkhir: sisaStok < 0 ? <NumberFormat suffix={")"} prefix={"("} value={Math.abs(sisaStok)} displayType={'text'} thousandSeparator={true} /> :
                <NumberFormat value={sisaStok} displayType={'text'} thousandSeparator={true} />,
            hsStokAkhir: <NumberFormat value={hpp < 0 ? Math.abs(hpp?.toFixed(0)) : hpp.toFixed(0)} displayType={'text'} thousandSeparator={true}
                suffix={hpp < 0 ? ")" : ''} prefix={hpp < 0 ? "(" : ''} />,
            hargaTotalStokAkhir: nilaiStokAkhir < 0 ? <NumberFormat suffix={")"} prefix={"("} value={Math.abs((nilaiStokAkhir || 0).toFixed(0))} displayType={'text'} thousandSeparator={true} /> :
                <NumberFormat value={(nilaiStokAkhir || 0).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
        }))

    const handleTanggalRange = (dateStrings) => {
        setMonth(moment(dateStrings).locale('id').format('MMMM'))
        setYear(moment(dateStrings).locale('id').format('YYYY'))
    }

    const disabledDate = (current) => {
        return current > moment().locale('id').endOf('day')
    }

    return (
        <LiveBirdComponent
            navigate={props.navigate}
            loading={loading}
            setLoading={setLoading}
            data={data}
            columns={columns}
            month={month} year={year}
            idStockflow={idStockflow}
            handleTanggalRange={handleTanggalRange}
            disabledDate={disabledDate}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(LiveBirdPage)
export default page