export const general = {
    search: "Cari",
    del: "Hapus",
    add: "Tambah",
    edit: "Edit",
    reset: "Reset",
    send: 'Kirim',
    detail: "Detail",
    open: "Buka",
    close: "Tutup",
    lock: "Kunci",
    unlock: "Buka Kunci",
    save: "Simpan",
    next: "Lanjut",
    back: "Kembali",
    fin: "Selesai",
    cancel: "Batal",
    action: "Pilihan",
    print: "Cetak",
    yes: 'Ya',
    no: 'Tidak',
    upgrade: "Tingkatkan akun Anda",
    limitedUser: "Mohon maaf, untuk menambah lebih dari 1 petugas hanya berlaku untuk akun BroilerX Premium, silakan tingkatkan akun Anda.",
    limitedHouse: "Mohon maaf, untuk menambah lebih dari 1 kandang hanya berlaku untuk akun BroilerX Premium, silakan tingkatkan akun Anda.",
    limitedRearing: "Mohon maaf, untuk menambah lebih dari 1 periode aktif hanya berlaku untuk akun BroilerX Premium, silakan tingkatkan akun Anda.",
    limitedFeedWh: "Mohon maaf, untuk menambah lebih dari 1 gudang pakan hanya berlaku untuk akun BroilerX Premium, silakan tingkatkan akun Anda.",
    limitedOvkWh: "Mohon maaf, untuk menambah lebih dari 1 gudang OVK hanya berlaku untuk akun BroilerX Premium, silakan tingkatkan akun Anda.",
    limitedMaterialWh: "Mohon maaf, untuk menambah lebih dari 1 gudang material hanya berlaku untuk akun BroilerX Premium, silakan tingkatkan akun Anda.",
}