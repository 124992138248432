import React from 'react';
import { Modal, Form, Row, Col, Select, InputNumber } from 'antd';

function EditDeplesi(props) {
    const { Option } = Select;
    const { visible, handleSubmit, data, handleCancel, t } = props;
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.recording.depletion.edit')}
                okText={t('general.edit')}
                width={800} centered
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            handleSubmit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        jenis: data,
                        jumlah: data.number,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.recording.depletion.type')}</span>
                            <Form.Item
                                name="jenis" rules={[{ required: true, message: `${t('pages.recording.depletion.msgType')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.recording.depletion.phType')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                >
                                    <Option key="Culling" value="Culling">{t('pages.recording.depletion.culling')}</Option>
                                    <Option key="Mati" value="Mati">{t('pages.recording.depletion.dead')}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.depletion.qty')}</span>
                            <Form.Item
                                name="jumlah" rules={[{ required: true, message: `${t('pages.recording.depletion.msgQty')}` }]}
                            >
                                <InputNumber
                                    name='jumlah'
                                    placeholder="100..."
                                    className="input-number mt-5"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g,'')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };
    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}

export default EditDeplesi;