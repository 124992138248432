import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../common/store/action';
import EditDeplesiComponent from '../../../../modules/dashboard-peternak/rearing-record-component/edit-detail-rearing-component/edit-deplesi-component';

function EditDeplesiPage(props) {
    const { visible, handleCancel, detail, handleSubmit, t } = props;
    const [data, setData] = useState(detail)

    useEffect(() => {
        function setDetailData() {
            if (detail === "") {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData();
    }, [detail]);

    return (
        <EditDeplesiComponent
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            detail={detail}
            data={data}
            t={t}
        />
    );
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(EditDeplesiPage);
export default page