export const monitor = {
    title: "Alat",
    serial: "Serial Number",
    house: "Kandang",
    tool: "Alat",
    position: "Posisi",
    add: "Tambah Sensor Alat",
    battery: "Baterai",
    type: "Tipe Alat",
    phHouse: "Pilih kandang",
    phType: "Pilih tipe alat",
    phPosition: 'Tengah..',
    ews: 'Sistem Peringatan Dini',
    envName: 'Nama Standar Lingkungan',
    std: 'Standar Lingkungan',
    calibrate: 'Kalibrasi',
    day: 'Hari',
    maxTemp: 'Suhu Maksimal',
    minTemp: 'Suhu Minimal',
    maxHumidity: 'Kelembapan Maksimal',
    minHumidity: 'Kelembapan Minimal',
    maxCo2: 'CO₂ Maksimal',
    msgDay: 'Mohon masukkan hari',
    msgEnvName: 'Mohon masukkan nama standar lingkungan!',
    msgSerial: "Mohon masukkan nomor serial!",
    msgHouse: "Mohon pilih kandang!",
    msgType: "Mohon pilih tipe alat!",
    msgPosition: "Mohon masukkan posisi!",
    delConfirm: "Apakah anda yakin ingin menghapus alat?",
    delStdConfirm: "Apakah anda yakin ingin menghapus standar lingkungan",
    delStdDayConfirm: "Apakah anda yakin ingin menghapus standar lingkungan hari ke-",
    edit: "Edit Sensor Alat",
    addStandardEnv: 'Tambah Standar Lingkungan',
    editStandardEnv: 'Edit Standar Lingkungan',
    addTare: 'Tambah Tara Alat',
    latest: 'Terbaru',
    manual: 'Manual',
    tareTime: 'Waktu Kalibrasi',
    current: 'Terkini',
    tare: 'Tara',
    temp: 'Suhu',
    humidity: 'Kelembapan',
    windSpeed: 'Kecepatan Angin',
    weight: 'Berat',
    desc: 'Keterangan',
    phDesc: 'Tambahkan keterangan..',
    resAddSuccess: "Berhasil menambahkan alat",
    resEditSuccess: "Berhasil mengubah alat",
    resDelSuccess: "Berhasil menghapus alat",
    resAddStdSuccess: "Berhasil menambahkan standar lingkungan",
    resEditStdSuccess: "Berhasil mengubah standar lingkungan",
    resDelStdSuccess: "Berhasil menghapus standar lingkungan",
    resAddTareSuccess: "Berhasil menambahkan tara dan kalibrasi alat",
    resAddFailed: "Gagal menambahkan alat",
    resEditFailed: "Gagal mengubah alat",
    resDelFailed: "Gagal menghapus alat",
    resAddStdFailed: "Gagal menambahkan standar lingkungan",
    resEditStdFailed: "Gagal mengubah standar lingkungan",
    resDelStdFailed: "Gagal menghapus standar lingkungan",
    resAddTareFailed: "Gagal menambahkan tara dan kalibrasi alat",
}