import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { navigate } from '../../../../../common/store/action'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { createStrain } from './query-strain'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { Form, Row, Col, Input } from 'antd'

function CreateStrain(props) {
    const { fetchData, setLoading } = props
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values) => {
        API.post(createStrain(values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `Gagal menambahkan strain`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createStrain._id) {
                    validationMessage('success', `Sukses menambahkan strain`)
                    fetchData()
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `Gagal menambahkan strain`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <>
            <Form form={form} onFinish={handleSubmit}
                className=""
                hidden={!visible}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="name" rules={[{ required: true, message: `Mohon masukkan nama strain!` }]}
                        >
                            <Input
                                placeholder='Nama Strain'
                                className="input-form"
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <ButtonDashboard
                            htmlType="submit"
                            text='Tambah'
                            height={20}
                            backgroundColor="#008ad4"
                            borderRadius="5px" marginLeft={10}
                            className="font-semi-medium font-content-title button-add"
                            textColor="white"
                        />
                    </Col>
                    <Col>
                        <ButtonDashboard
                            text='Batal'
                            height={20}
                            borderRadius="5px" marginLeft={10}
                            className="font-semi-medium font-content-title button-add"
                            onClick={() => setVisible(false)}
                        />
                    </Col>
                </Row>
            </Form>
            <ButtonDashboard
                text='Tambah Strain'
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                hidden={visible}
                onClick={() => showModal()}
            />
        </>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStrain)
export default page