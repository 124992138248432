import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../common/store/action'
import { validationMessage } from '../../common/component/notification/notification'
import CONSTANTS from '../../common/utils/Constants'
import Loading from '../../common/component/loading/loading-container'
import Cookies from 'js-cookie'
import { useTranslation } from "react-i18next"
import { API } from '../../common/api'
import { Link } from 'react-router-dom'
import { getIdPeriode, setPeriodeAktif } from '../../modules/dashboard-peternak/layout-component/store/layout-action'
import { getIdGudangPakan, setDataGudangPakan } from '../../modules/dashboard-peternak/master-component/gudang-pakan-component/store/gudang-pakan-action'
import { getIdGudangOvk, setDataGudangOvk } from '../../modules/dashboard-peternak/master-component/gudang-ovk-component/store/gudang-ovk-action'

function LogoutPage(props) {
    const { t } = useTranslation()

    useEffect(() => {
        handleLogout()
        // eslint-disable-next-line
    }, []);

    const handleLogout = useCallback((value) => {
        const query = {
            query:
                `query{
            logout {
                signedOut
            }
        }`,
        }
        API.post(query)
            .then(res => {
                if (res === undefined) { validationMessage('error', `${t('error.connection')}`) }
                else if (res.data.data.logout.signedOut === true) {
                    Cookies.remove('token', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                    Cookies.remove('loginTimes', { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
                    props.getIdPeriode(null)
                    props.getIdGudangPakan(null)
                    props.getIdGudangOvk(null)
                    props.setPeriodeAktif([])
                    props.navigate(CONSTANTS.HOME_MENU_KEY)
                }
            })
    }, [props, t])

    return (<Link to='/'>
        <Loading />
    </Link>
    )
}
const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate, getIdPeriode, getIdGudangPakan, setPeriodeAktif,
    getIdGudangOvk, setDataGudangPakan, setDataGudangOvk
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(LogoutPage)
export default page