export const getSummaryReport = (rearing) => {
  return (
    `query{
        dashboardCalculate(rearing:"${rearing}", keyword:"",limit:10,skip:0){
          liveBird
          culling
          mati
          totalPakan
          company{
            name
            custom
          }
        }
      }`
  );
}

export const getReportRecord = (keyword, limit, skip, periode) => {
  return (
    `query{
            calculatedRearingRecords(rearing:"${periode}", keyword:"${keyword}",limit:${limit},skip:${skip}){
              calculatedArr{
                  _id
                  tanggal
                  umur
                  total
                  saldo
                  feedIntake
                  persenKematian
                  fcr
                  bw
                  adg
                  indeksPerformance
                  standardFi
                  standardFcr
                  standardDp
                  standardBw
              }
            }
          }
        `
  )
}

export const getDashboardRecord = (keyword, limit, skip, periode) => {
  return (
    `
    query{
      calculateDashboardRecords(rearing:"${periode}", keyword:"${keyword}",limit:${limit},skip:${skip}){
        totalCount
        calculatedArr{
            day
            fcr
            bw  
            feedIntake
            depletion
            standardFcr
            standardBw 
            standardDp
            standardFi
            saldoPerHari
            saldo
        }
      }
    }
        `
  )
}
export const getFeedUsage = (periode) => {
  return (
    `query{
        feedUsage(rearing:"${periode}"){
          feedCount
          ages
          feedUsage{
                _id
                feed
                firstAge
                lastAge
                total
                feeding{
                    age
                    number
                    fi
                }
              }
              chart{
                label
                lineTension
                borderColor
                backgroundColor
                borderDash
                yAxisID
                data{
                  x
                  y
                }
              }
          }
        }
      `
  )
}

export const getThiDevice = (deviceId, start, end) => (
  `
  query{
    thiDevice(deviceId: "${deviceId}", start: "${start}", end: "${end}"){
        thiDevice{
          ts
          value
          timestamp
          status
        }
    }
  }
  `
)

export const getEnvDevice = (deviceId, start, end) => (
  `
  query{
    envDevice(deviceId: "${deviceId}", start: "${start}", end: "${end}"){
      type
      envDevice{
        ts
        timestamp
        temperature
        humidity
        amonia
        voc
        co2
        kph
        ms
      }
      envReverse{
        ts
        timestamp
        temperature
        humidity
        amonia
        voc
        co2
        kph
        ms
      }
    }
  }
  `
)


export const getTaredDevice = (deviceId, start, end) => (
  `
  query{
    taredDevice(deviceId: "${deviceId}", start: "${start}", end: "${end}"){
      type
      envDevice{
        ts
        timestamp
        temperature
        humidity
        amonia
        voc
        co2
        kph
        ms
      }
      envReverse{
        ts
        timestamp
        temperature
        humidity
        amonia
        voc
        co2
        kph
        ms
      }
    }
  }
  `
)

export const getRetention = (keyword, limit, skip) => (
  `
  query{
    retention(keyword:"${keyword}",limit:${limit},skip:${skip}){
      totalCount
      companies{
        _id
        retention
        name
        type
        typeUser
        updatedAt
      }
    }
  }
  `
)

export const getUsers = (keyword, limit, skip) => (
  `
  query{
    users(keyword:"${keyword}",limit:${limit},skip:${skip}){
      totalCount
      users{
        _id
        name
        email
        username
      }
    }
  }
  `
)

export const updateSubs = (id, values) => {
  return {
    query: `
      mutation{
        updateSubscription(_id: "${id}",updateCompanyInput:{
          typeUser: "${values.typeUser}"
        }){
          name
          _id
        }
      }
      `
  }
}

export const getCities = () => {
  return (
    `query{
          getProfile{
              cities
              isVoucher
          }
      }`
  )
}

export const addCity = (city) => {
  return {
    query: `
      mutation {
        addCity(city: "${city}" ){
         _id
         name
         cities
        }
      }
      `
  }
}

export const removeCity = (city) => {
  return {
    query: `
      mutation {
        removeCity(city: "${city}" ){
         _id
         name
         cities
        }
      }
      `
  }
}

export const sendVoucher = (values) => {
  return {
    query: `
    mutation{
      sendVoucher(ids:"${values.users}", duration: ${values.duration},  house: ${values.house}){
          totalCount
        users{
          _id
          name
          email
          username
          address
          isVoucher
        }
      }
    }`
  }
}

export const claimVoucher = () => (
  `query{
      claimVoucher{
          _id
          name
          lastPayment
          subsType
          totalPayment
          expiryDate
      }
    }`
)

export const getNotifications = (keyword, limit, skip) => (
  `
  query{
    notifications(keyword:"${keyword}",limit:${limit},skip:${skip}){
      totalAll
      totalCount
      totalUnread
      notifications{
        _id
        title
        message
        read
        createdAt
      }
    }
  }
  `
)

export const clearUnread = (limit) => (
  `
  query{
    clearUnread(limit:${limit}){
      totalCount
      notifications{
        _id
        title
        message
        read
        createdAt
      }
    }
  }
  `
)