import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useLocation } from "react-router-dom"
import { connect } from 'react-redux'
import { API } from '../../../common/api'
import { navigate } from '../../../common/store/action'
import CONSTANS from '../../../common/utils/Constants'
import { errMessage } from '../../../common/component/notification/notification'
import LayoutComponent from '../../../modules/dashboard-peternak/layout-component/layout-component'
import { notification, Form } from 'antd'
import { getPeriodeKandang, queryPeriodeAktif } from '../rearing-page/rearing-aksi-page/query-rearing'
import {
    getListKandang, getIdPeriode, getListPeriodeKandang,
    getIdKandang, setIsTour, getKandang, setCreateRecording, getPeriodeAktif
} from '../../../modules/dashboard-peternak/layout-component/store/layout-action'
import { queryGudangPakan } from '../master-page/gudang-pakan-page/gudang-pakan-aksi-page/query-gudang-pakan'
import { getGudangOvk } from '../master-page/gudang-ovk-page/gudang-ovk-aksi-page/query-gudang-ovk'
import { getListGudangOvk, getIdGudangOvk } from '../../../modules/dashboard-peternak/master-component/gudang-ovk-component/store/gudang-ovk-action'
import { getListGudangPakan, getIdGudangPakan } from '../../../modules/dashboard-peternak/master-component/gudang-pakan-component/store/gudang-pakan-action'
import { getProfile, updateNotifReceive } from '../../../app/dashboard-peternak/profil-page/query-profil'
import { getListProfil } from '../../../modules/dashboard-peternak/profil-component/store/profil-action'
import { useTranslation } from "react-i18next"
import socketIOClient from 'socket.io-client'
import moment from 'moment'
import i18n from '../../../translations/i18n'
import { clearUnread, getNotifications } from '../dashboard-page/query-dashboard-page'
import { isMobileOnly } from 'react-device-detect'
import { createRearingRecordBatch, getPriceFeeds, getPriceOvks } from '../rearing-record-page/rearing-record-aksi-page/query-rearing-record'
import { getStokGudangPakan } from '../stok-page/stok-pakan-page/stok-pakan-aksi-page/query-stok-pakan'
import { getStokGudangOvk } from '../stok-page/stok-ovk-page/stok-ovk-aksi-page/query-stok-ovk'
import { getListPakan } from '../../../modules/dashboard-peternak/stok-component/stok-pakan-component/store/stok-pakan-action'
import { getListOvk } from '../../../modules/dashboard-peternak/stok-component/stok-ovk-component/store/stok-ovk-action'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

function LayoutPage(props) {
    const {
        getIdPeriode, getListPeriodeKandang, getIdKandang, getPeriodeAktif,
        setIsTour, getListGudangOvk, getListGudangPakan, photo, profil,
        getIdGudangOvk, getIdGudangPakan, getListProfil, role, getKandang,
        getListPakan, idGudangPakan, getListOvk, idGudangOvk, setCreateRecording
    } = props
    const history = useHistory()

    const [current, setCurrent] = useState('')
    const [loading, setLoading] = useState(false)
    const [collapsed, setCollapsed] = useState(isMobileOnly ? true : false)
    const [showSidebar, setShowSidebar] = useState(false)
    const [pathName] = useState('')
    const [width, setWidth] = useState(null)
    const [visible, setVisible] = useState(false)
    const location = useLocation()
    const { t } = useTranslation()
    const [lang] = useState(i18n.language)
    const [isNotif, setIsNotif] = useState(false)
    const [notifications, setNotification] = useState([])
    const [notifLoading, setNotifLoading] = useState(false)
    const [limitNotif, setLimitNotif] = useState(10)
    const [notifCount, setNotifCount] = useState(0)
    const [unreadNotif, setUnreadNotif] = useState(0)
    const [loadReceiveNotif, setLoadReceiveNotif] = useState(false)
    const [widthSize, setWidthSize] = useState(false)
    const endpoint = process.env.REACT_APP_SOCKET_HOST
    const isDeviceReady = false

    const [form] = Form.useForm()
    const [isRecording, showRecording] = useState(false)
    const [loadingRecording, setLoadingRecording] = useState(false)
    const [month, setMonth] = useState(moment().format('MMMM'))
    const [year, setYear] = useState(moment().format('YYYY'))
    const [monthStockFeed, setStockFeed] = useState([])
    const [monthStockOvk, setStockOvk] = useState([])
    const [loadingPriceFeed, setLoadingPriceFeed] = useState(false)
    const [loadingPriceOvk, setLoadingPriceOvk] = useState(false)
    let pathArray = location.pathname.split('/')

    const ref = useRef(null)

    useEffect(() => {
        let pathName = pathArray[2]
        pathName === '' ? setCurrent('/peternak') : setCurrent(pathName)
    }, [props, pathArray])

    const fetchNotification = async (limit) => {
        setNotifLoading(true)
        await API.get(getNotifications('', limit, 0))
            .then(res => {
                if (res.data.data.notifications !== null) {
                    setNotification(res.data.data.notifications.notifications)
                    setLimitNotif(res.data.data.notifications.totalCount)
                    setNotifCount(res.data.data.notifications.totalAll)
                    setUnreadNotif(res.data.data.notifications.totalUnread)
                    setNotifLoading(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setNotification([])
                setNotifLoading(false)
            })
    }

    const clearUnreadNotif = async (limit) => {
        await API.get(clearUnread(limit))
            .then(res => {
                if (res.data.data.clearUnread !== null) {
                    setNotification(res.data.data.clearUnread.notifications)
                    setUnreadNotif(0)
                    setNotifLoading(false)
                }
            }).catch((error) => {
                console.log(error.message)
                setNotification([])
                setNotifLoading(false)
            })
    }

    const loadMoreNotif = () => {
        if (notifLoading) {
            return
        }
        setNotifLoading(true)
        fetchNotification(limitNotif + 10)
    }

    const changeReceiveNotif = async (e) => {
        setLoadReceiveNotif(true)
        await API.post(updateNotifReceive(e))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', res.data.errors[0].message)
                    setLoadReceiveNotif(false)
                } else if (res.data.data.updateNotifReceive !== null) {
                    getListProfil(getProfile())
                    setLoadReceiveNotif(false)
                }
            }).catch((error) => {
                console.log(error.message)
            })
    }

    const openNotifTemp = useCallback((data) => {
        if (data.id !== ref.current) {
            notification.info({
                message: <b>{data.title}</b>,
                description: data.message
            })
        }
        ref.current = data.id
    }, [])

    const openNotifHum = useCallback((data) => {
        if (data.id !== ref.current) {
            notification.info({
                message: <b>{data.title}</b>,
                description: data.message
            })
        }
        ref.current = data.id
    }, [])

    const openNotifCo2 = useCallback((data) => {
        if (data.id !== ref.current) {
            notification.info({
                message: <b>{data.title}</b>,
                description: data.message
            })
        }
        ref.current = data.id
    }, [])


    useEffect(() => {
        if (isDeviceReady) {

            const socket = socketIOClient(endpoint)

            socket.on(`ews-${profil._id}`, data => {
                fetchNotification(limitNotif)
            })

            socket.on(`temp-${profil._id}`, data => {
                openNotifTemp(data)
            })

            socket.on(`hum-${profil._id}`, data => {
                openNotifHum(data)
            })

            socket.on(`co2-${profil._id}`, data => {
                openNotifCo2(data)
            })

            return () => socket.disconnect()
        }
    }, [profil, endpoint, openNotifTemp, openNotifHum, openNotifCo2, limitNotif, isDeviceReady])

    useEffect(() => {
        if (isDeviceReady) {
            fetchNotification(limitNotif)
        }
    }, [limitNotif, isDeviceReady])

    useEffect(() => {
        getListProfil(getProfile())
    }, [getListProfil])

    useEffect(() => {
        if (!props.idPeriode && isEmpty(props.activeRearing) && !props.loadActiveRearing) {
            getPeriodeAktif(queryPeriodeAktif())
        }
    }, [props.idPeriode, props.activeRearing, props.loadActiveRearing, getPeriodeAktif])

    useEffect(() => {
        if (!props.idGudangPakan) {
            getListGudangPakan(queryGudangPakan('', 0, 0))
        }
    }, [props.idGudangPakan, getListGudangPakan])

    useEffect(() => {
        if (!props.idGudangOvk) {
            getListGudangOvk(getGudangOvk('', 0, 0))
        }
    }, [props.idGudangOvk, getListGudangOvk])

    useEffect(() => {
        if (!props.idPeriode && !isEmpty(props.activeRearing) && !props.loadActiveRearing) {
            getIdPeriode(props.activeRearing[0].rearingId)
            getIdGudangPakan(props.activeRearing[0].feedWarehouseId)
            getIdGudangOvk(props.activeRearing[0].ovkWarehouseId)
            const pathname = location.pathname.includes('recording') ? '/peternak/recording/' : '/peternak/dashboard/'
            history.replace(pathname + props.activeRearing[0]?.rearingId)
        }
    }, [props.idPeriode, getIdPeriode, getIdGudangPakan, getIdGudangOvk, props.activeRearing, props.loadActiveRearing, location, history])

    useEffect(() => {
        if (window.innerWidth === 360) {
            setWidth(0)
        } else if (collapsed === true) {
            setWidth(80)
        } else if (collapsed === false) {
            setWidth(260)
        }
    }, [collapsed])

    const handleProfil = useCallback(() => {
        setCurrent('profil')
        props.navigate(CONSTANS.PROFILE_MENU_KEY)
    }, [props])

    const handleSubscription = useCallback(() => {
        setCurrent('subscription')
        props.navigate(CONSTANS.SUBSCRIPTION_MENU_KEY)
    }, [props])

    const handleChangeKandang = useCallback((value) => {
        const data = JSON.parse(value.label[0].props.children)
        getIdPeriode(value.value)
        getIdGudangPakan(data.feedWarehouseId)
        getIdGudangOvk(data.ovkWarehouseId)
        const pathname = location.pathname.includes('recording') ? '/peternak/recording/' : '/peternak/dashboard/'
        history.replace(pathname + value.value)
    }, [getIdPeriode, getIdGudangPakan, getIdGudangOvk, location, history])

    const handleChangeGudangPakan = useCallback((value) => {
        getIdGudangPakan(value.value)
        const pathname = pathArray[4] ?
            `/peternak/${pathArray[2]}/${value.value}/${pathArray[4]}` :
            `/peternak/${pathArray[2]}/${value.value}`
        history.replace(pathname)
    }, [getIdGudangPakan, history, pathArray])

    const handleChangeGudangOvk = useCallback((value) => {
        getIdGudangOvk(value.value)
        const pathname = pathArray[4] ?
            `/peternak/${pathArray[2]}/${value.value}/${pathArray[4]}` :
            `/peternak/${pathArray[2]}/${value.value}`
        history.replace(pathname)
    }, [getIdGudangOvk, history, pathArray])

    const clickedMenu = useCallback((e) => {
        setCurrent(e.key)
        if (isMobileOnly) {
            setCollapsed(!collapsed)
        }
    }, [collapsed])

    const toggle = useCallback(() => {
        setCollapsed(!collapsed)
        setShowSidebar(!collapsed)
    }, [collapsed])

    const handleAdd = () => {
        setIsTour()
        setVisible(true)
    }
    const fetchDataActive = async () => {
        props.navigate(CONSTANS.REARING_MENU_KEY)
        setLoading(false)
    }
    const handleLocales = useCallback((e) => {
        if (i18n.language !== e) {
            i18n.changeLanguage(e)
        }
    }, [])

    const handleWindowResize = useCallback(event => {
        setWidthSize(window.innerWidth);
    }, []);


    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    useEffect(() => {
        if (isRecording && idGudangPakan) {
            getListPakan(getStokGudangPakan(idGudangPakan))
        }
    }, [getListPakan, idGudangPakan, isRecording])

    useEffect(() => {
        if (isRecording && idGudangOvk) {
            getListOvk(getStokGudangOvk(idGudangOvk))
        }
    }, [getListOvk, idGudangOvk, isRecording])

    useEffect(() => {
        if (isRecording && idGudangPakan) {
            getPriceFeed(month, year, idGudangPakan)
        }
    }, [month, year, idGudangPakan, isRecording])

    useEffect(() => {
        if (isRecording && idGudangOvk) {
            getPriceOvk(month, year, idGudangOvk)
        }
    }, [month, year, idGudangOvk, isRecording])

    const changeKandangRecording = useCallback((value) => {
        getIdKandang(value.value)
        getKandang(value.label)
        getListPeriodeKandang(getPeriodeKandang(value.value))
        form.resetFields(['feeds', 'ovks'])
    }, [getListPeriodeKandang, getIdKandang, getKandang, form])

    const setDate = e => {
        setMonth(moment(e).locale('id').format('MMMM'))
        setYear(moment(e).locale('id').format('YYYY'))
    }

    const getPriceFeed = async (month, year, idGudangPakan) => {
        setLoadingPriceFeed(true)
        await API.get(getPriceFeeds('', 0, 0, month, year, idGudangPakan))
            .then(res => {
                setStockFeed(res.data.data.monthlyStockFeeds.calculateMonthlyStocks)
                setLoadingPriceFeed(false)
            }).catch(error => {
                setStockFeed([])
                setLoadingPriceFeed(false)
            })
    }

    const getPriceOvk = async (month, year, idGudangOvk) => {
        setLoadingPriceOvk(true)
        await API.get(getPriceOvks('', 0, 0, month, year, idGudangOvk))
            .then(res => {
                setStockOvk(res.data.data.monthlyStockOvks.calculateMonthlyStocks)
                setLoadingPriceOvk(false)
            }).catch(error => {
                setStockOvk([])
                setLoadingPriceOvk(false)
            })
    }

    const onChangeOvk = async (value, key) => {
        const priceOvk = monthStockOvk.find(d => d?.idOvk === value.label[0].props.children)
        const fields = form.getFieldsValue()
        const { ovks } = fields
        Object.assign(ovks[key], { price: priceOvk?.hargaPengeluaran || 0 })
        form.setFieldsValue({ ovks })
    }

    const onChangeFeed = (value, key) => {
        const priceFeed = monthStockFeed.find(d => d?.idFeed === value.label[0].props.children)
        const fields = form.getFieldsValue()
        const { feeds } = fields
        Object.assign(feeds[key], { price: priceFeed?.hargaPerKarung || 0 })
        form.setFieldsValue({ feeds })
    }

    const postRecording = async (value, growings, feeds, ovks) => {
        setLoadingRecording(true)
        await API.post(createRearingRecordBatch(value, growings, feeds, ovks))
            .then(res => {
                try {
                    if (res.data.errors) {
                        errMessage('error', `${t('pages.recording.resAddFailed')}`, res.data.errors[0].message)
                        setLoadingRecording(false)
                    }
                    else if (res.data.data.createRearingRecordBatch._id) {
                        errMessage('success', `${t('pages.recording.resAddSuccess')}`)
                        form.resetFields()
                        setLoadingRecording(false)
                        showRecording(false)
                        setCreateRecording(true)
                    }
                } catch (error) {
                    errMessage('error', `${t('pages.recording.resAddFailed')}`, error.message)
                    setLoadingRecording(false)
                }
            })
    }
    return (
        <LayoutComponent
            navigate={props.navigate} pathArray={pathArray}
            toggle={toggle} collapsed={collapsed}
            setCollapsed={setCollapsed} showSidebar={showSidebar}
            current={current} setCurrent={setCurrent}
            clickedMenu={clickedMenu}
            pathName={pathName} width={width} widthSize={widthSize}
            visible={visible} setVisible={setVisible}
            loading={loading} setLoading={setLoading}
            isTourOpen={props.isTourOpen} setIsTour={setIsTour}
            handleAdd={handleAdd}
            profil={profil}
            dataUser={props.dataUser}
            kandang={props.kandang}
            periode={props.periode}
            idPeriode={props.idPeriode}
            idKandang={props.idKandang}
            handleChangeKandang={handleChangeKandang}
            gudangPakan={props.gudangPakan} gudangOvk={props.gudangOvk}
            handleChangeGudangPakan={handleChangeGudangPakan}
            handleChangeGudangOvk={handleChangeGudangOvk}
            idGudangPakan={props.idGudangPakan} namaGudangPakan={props.namaGudangPakan}
            idGudangOvk={props.idGudangOvk}
            namaGudangOvk={props.namaGudangOvk}
            handleSubscription={handleSubscription}
            handleProfil={handleProfil} role={role}
            typeUser={profil?.company?.typeUser}
            location={location}
            fetchDataActive={fetchDataActive}
            t={t} handleLocales={handleLocales} lang={lang} photo={photo}
            isNotif={isNotif} setIsNotif={setIsNotif} notifications={notifications}
            loadMoreNotif={loadMoreNotif} notifCount={notifCount} unreadNotif={unreadNotif}
            clearUnreadNotif={clearUnreadNotif} limitNotif={limitNotif} notifLoading={notifLoading}
            changeReceiveNotif={changeReceiveNotif} loadReceiveNotif={loadReceiveNotif}
            form={form} isRecording={isRecording} showRecording={showRecording}
            loadingRecording={loadingRecording}
            loadingPriceFeed={loadingPriceFeed}
            loadingPriceOvk={loadingPriceOvk}
            onChangeOvk={onChangeOvk} onChangeFeed={onChangeFeed}
            changeKandangRecording={changeKandangRecording}
            postRecording={postRecording} setDate={setDate}
            stokPakan={props.stokPakan} stokOvk={props.stokOvk}
            activeRearing={props.activeRearing}
            loadingRearing={props.loadingRearing}
            loadingFeedWh={props.loadingFeedWh}
            loadingOvkWh={props.loadingOvkWh}
        />
    )
}

const mapStateToProps = state => ({
    idPeriode: state.layout.idPeriode,
    idKandang: state.layout.idKandang,
    kandang: state.layout.dataKandang,
    periode: state.layout.dataPeriode,
    totalKandang: state.layout.totalKandang,
    isTourOpen: state.layout.isTourOpen,
    gudangPakan: state.gudangPakan.dataGudangPakan,
    idGudangPakan: state.gudangPakan.idGudangPakan,
    namaGudangPakan: state.gudangPakan.namaGudangPakan,
    gudangOvk: state.gudangOvk.dataGudangOvk,
    idGudangOvk: state.gudangOvk.idGudangOvk,
    namaGudangOvk: state.gudangOvk.namaGudangOvk,
    stokPakan: state.stokPakan.dataStokPakan,
    stokOvk: state.stokOvk.dataStokOvk,
    role: state.profil.dataProfil.type,
    photo: state.profil.dataProfil.foto,
    profil: state.profil.dataProfil,
    dataUser: state.auth.data_user,
    activeRearing: state.layout.dataPeriodeAktif,
    loadActiveRearing: state.layout.loadPeriodeAktif,
    loadingRearing: state.layout.loadingRearing,
    loadingFeedWh: state.layout.loadingFeedWh,
    loadingOvkWh: state.layout.loadingOvkWh,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListKandang,
    getListPeriodeKandang,
    getIdPeriode,
    getIdKandang,
    setIsTour,
    getListGudangOvk,
    getListGudangPakan,
    getIdGudangOvk,
    getIdGudangPakan,
    getListProfil,
    getKandang,
    getListPakan,
    getListOvk,
    setCreateRecording,
    getPeriodeAktif
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(LayoutPage)
export default page