export const recording = {
    title: "Rearing Record",
    add: "Add Recording",
    detail: "Details of Recording Day-",
    age: "Age",
    date: "Date",
    desc: "Description",
    phDate: "Input recording date",
    msgDate: "Please input recording date!",
    resAddSuccess: "Successfully added recording",
    resDelSuccess: "Successfully deleted recording",
    resAddFailed: "Failed to add recording",
    resDelFailed: "Failed to delete recording",
    delConfirm: "Are you sure you want to delete this recording?",
    addConfirm: "Are you sure you want to add new recording automatically?",
    norearing: "No recording has been inputted yet in house {{house}} period {{rearing}}. Please add recording first!",
    feed: {
        title: "Feed",
        feedDay: "Feed/Day (Kg)",
        cumFeed: "CUM (kg)",
        fiAct: "FI Act (Gr/Chicken)",
        fiStd: "Fi Std (Gr/Chicken)",
        actCum: "Act Cum (Gr/Chicken)",
        stdFiCum: "STD FI Cum (Gr/Chicken)",
        deviationCum: "Deviation CUM (%)",
        stock: "Feed Stock",
        qty: "Quantity (sacks)",
        qtyWeight: "Quantity (kg)",
        price: "Price/sack (Rp/sack)",
        msgPrice: "Please input price!",
        sack: ' sacks',
        delConfirm: "Are you sure you want to delete the recording feed data?",
        add: "Add Feed Recording",
        edit: "Edit Feed Recording",
        msgFeed: "Plese select feed!",
        phFeed: "Select feed",
        msgQty: "Please input amount of feed usage!",
        resAddSuccess: "Successfully Adding Feed Recording",
        resEditSuccess: "Successfully Changing Feed Recording",
        resDelSuccess: "Successfully Deleting Feed Recording",
        resAddFailed: "Failed to Add Feed Recording",
        resEditFailed: "Failed to Change Feed Recording",
        resDelFailed: "Failed to Delete Feed Recording",
    },
    ovk: {
        title: "Medicine",
        stock: "Medicine Stock",
        qty: "Quantity",
        qtyProcurement: "Amount of Procurement",
        add: "Add Medicine Recording",
        delConfirm: "Are you sure you want to delete the medicine recording data?",
        edit: "Edit Recording Medicine",
        msgOvk: "Please select medicine!",
        phOvk: "Select medicine",
        msgQty: "Please input amount of medicine usage!",
        resAddSuccess: "Successfully added medicine recording",
        resEditSuccess: "Successfully changed medicine recording",
        resDelSuccess: "Successfully deleted medicine recording",
        resAddFailed: "Failed to add medicine recording",
        resEditFailed: "Failed to change medicine recording",
        resDelFailed: "Failed to delete medicine recording",
    },
    material: {
        title: "Material",
        stock: "Material Stock",
        qty: "Quantity",
        add: "Add Material Recording",
        edit: "Edit Material Recording",
        delConfirm: "Are you sure you want to delete the recording material data?",
        msgMaterial: "Please select material!",
        phMaterial: "Select material",
        msgQty: "Please input amount of material usage!",
        resAddSuccess: "Successfully Adding Material Recording Data",
        resEditSuccess: "Successfully Changing Material Recording Data",
        resDelSuccess: "Successfully Deleting Material Recording Data",
        resAddFailed: "Failed to Add Material Recording Data",
        resEditFailed: "Failed to Change Material Recording Data",
        resDelFailed: "Failed to Delete Material Recording Data",
    },
    disease: {
        title: "Disease",
        qty: "Total (chickens)",
        add: "Add Disease Recording",
        pic: "Pictures",
        delConfirm: "Are you sure you want to delete the disease recording data?",
        delPicConfirm: "Are you sure you want to delete the disease recording picture?",
        edit: "Edit Disease Recording",
        desc: "Description",
        msgQty: "Please input amount of diseased chicken!",
        msgDisease: "Please input disease!",
        msgPic: "Please choose at least 1 picture!",
        phDisease: "Input disease",
        resAddSuccess: "Successfully added disease recording",
        resEditSuccess: "Successfully changed disease recording",
        resDelSuccess: "Successfully deleted disease recording",
        resAddFailed: "Failed to add disease recording",
        resEditFailed: "Failed to change disease recording",
        resDelFailed: "Failed to delete disease recording",
        resAddPicSuccess: "Successfully added  disease pics recording",
        resDelPicSuccess: "Successfully deleted disease pics recording",
        resAddPicFailed: "Failed to add disease pics recording",
        resDelPicFailed: "Failed to delete disease pics recording",
    },
    overhead: {
        title: "Overhead",
        qty: "Quantity",
        price: "Price (Rp)",
        add: "Add Overhead Recording",
        delConfirm: "Are you sure you want to delete the recording overhead data?",
        edit: "Edit Overhead Recording",
        phOverhead: "Select overhead",
        msgOverhead: "Plese select overhead!",
        msgPrice: "Plese input price!",
        msgQty: "Please input quantity of overhead usage!",
        resAddSuccess: "Successfully Adding overhead Recording Data",
        resEditSuccess: "Successfully Changing overhead Recording Data",
        resDelSuccess: "Successfully Deleting overhead Recording Data",
        resAddFailed: "Failed to Add Overhead Recording Data",
        resEditFailed: "Failed to Change Overhead Recording Data",
        resDelFailed: "Failed to Delete Overhead Recording Data",
    },
    depletion: {
        title: "Depletion",
        culling: "Culling (chickens)",
        dead: "Dead (chickens)",
        qty: "Quantity (chickens)",
        cum: "CUM (chickens)",
        percentDay: "Day (%)",
        percentCum: "CUM (%)",
        stdDepletion: "STD (%)",
        type: "Type",
        add: "Add Depletion Recording",
        edit: "Edit Depletion Recording",
        delConfirm: "Are you sure you want to delete depletion data?",
        msgType: "Please select depletion type!",
        phType: "Select depletion type",
        msgQty: "Please input amount of depletion!",
        resAddSuccess: "Successfully Adding Depletion",
        resEditSuccess: "Successfully changed the depletion",
        resDelSuccess: "Successfully deleted depletion",
        resAddFailed: "Failed to Add Depletion",
        resEditFailed: "Failed to change depletion",
        resDelFailed: "Failed to clear depletion",
    },
    harvest: {
        title: "Harvest",
        add: "Add Harvest Recording",
        edit: "Edit Harvest Recording",
        qty: "Quantity (chickens)",
        weight: "Weight Total (kg)",
        restChicken: "Rest of Chicken (chickens)",
        avg: "Average Chicken Weight (kg/Chicken)",
        price: "Price/kg",
        total: "Total",
        customer: "Pelanggan",
        delConfirm: "Are you sure you want to delete harvest recording?",
        desc: "Description",
        phCustomer: "Select customer",
        phDesc: "Input description",
        msgQty: "Please input amount of harvested chicken!",
        msgWeight: "Please input weight!",
        msgPrice: "Please input price by kg!",
        msgTotal: "Please input total!",
        msgCustomer: "Please select customer!",
        resAddSuccess: "Successfully Adding Harvest",
        resEditSuccess: "Successfully Changing Harvest",
        resDelSuccess: "Successfully Deleting Harvest",
        resAddFailed: "Failed to Add Harvest",
        resEditFailed: "Failed to Change Harvest",
        resDelFailed: "Failed to Delete Harvest",
    },
    bw: {
        add: "Add Growth Recording",
        edit: "Edit Growth Recording",
        sampling: "Sampling (Gr/chicken)",
        stdBw: "STD (Gr/chicken)",
        deviation: "Deviation (%)",
        adg: "Adg (Gr)",
        totalWeight: "Total Weight (Kg)",
        abw: "ABW (Gr)",
        growing: "Growing",
        parameter: "Parameter",
        value: "Value (Gram)",
        weight: "Weight of Sampling Chickens (Gram)",
        msgWeight: "Please input weight of sampling chickens!",
        delConfirm: "Are you sure you want to delete growth recording?",
        resAddSuccess: "Successfully Adding Growth Data",
        resEditSuccess: "Successfully Changing Growth Data",
        resDelSuccess: "Successfully Deleting Growth Data",
        resAddFailed: "Failed to Add Growth Data",
        resEditFailed: "Failed to Change Growth Data",
        resDelFailed: "Failed to Delete Growth Data"
    },
    fcr: {
        act: "Act Cum(Gr)",
        stdFcr: "STD (Gr)",
    },
    feedTotal: "Feed (Rp)",
    ovkTotal: "Medicine (Rp)",
    materialTotal: "Material (Rp)",
    shrinkage: "Shrinkage (Rp)",
    hppTotal: "Total Hpp (Rp)",

}