import React, { useState, useCallback, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { navigate } from '../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input, Layout, Row, Col, Table, Typography, Breadcrumb } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { faDownload, faInfoCircle, faLockOpen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getIdPeriode, getListKandang, getPeriode, getPeriodeAktif } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'
import { deletePeriode, getPeriodeCloseAll, openPeriode, queryPeriodeAktif } from './rearing-aksi-page/query-rearing'
import { API } from '../../../common/api'
import CONSTANTS from '../../../common/utils/Constants'
import { validationMessage, errMessage } from '../../../common/component/notification/notification'
import Loading from '../../../common/component/loading/loading-container'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { useTranslation } from "react-i18next"

import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
import { isMobileOnly } from 'react-device-detect'
import { blobToDataURL } from '../../../common/utils/Download'
import { queryListKandang } from '../master-page/kandang-page/kandang-aksi-page/query-kandang'

const { Text } = Typography

function RearingClosePage(props) {
    const { getIdPeriode, getListKandang, getPeriode, profil, getPeriodeAktif } = props
    const { confirm } = Modal
    // const { fetchDataClose, rearingClose, loading, setLoading, role, isRecording, t } = props
    const [rearingClose, setRearingClose] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [loadingBudidaya, setLoadBudidaya] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [total, setTotal] = useState(null)
    const [tableParams, setTableParams] = useState({
        keyword: '',
        current: 1,
        pageSize: 10,
    })
    const { t } = useTranslation()
    const cookies = new Cookies()

    const role = profil.permission.writeRearing
    const isRecording = profil.permission.readRecording

    const fetchDataClose = async () => {
        const { keyword, current, pageSize } = tableParams
        setLoading(true)
        await API.get(getPeriodeCloseAll(keyword, pageSize, current))
            .then(res => {
                setRearingClose(res.data.data.rearingClose.rearings)
                setTotal(res.data.data.rearingClose.totalCount)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setRearingClose([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchDataClose()
        // eslint-disable-next-line
    }, [tableParams])

    const handleTableChange = (pagination, filters) => {
        setTableParams({
            ...pagination,
            keyword: filters.kandang ? filters.kandang[0] : ''
        })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }
    const openRearing = (_id) => {
        API.post(openPeriode(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t("pages.rearing.resOpenFailed")}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.openRearing._id) {
                    validationMessage('success', `${t("pages.rearing.resOpenSuccess")}`)
                    fetchDataClose()
                    getListKandang(queryListKandang('', 0, 0))
                    getPeriodeAktif(queryPeriodeAktif())
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.rearing.resOpenFailed")}`, error.message)
                setLoading(false)
            })
    }

    const openConfirm = (_id) => {
        confirm({
            title: `${t("pages.rearing.openConfirm")}`,
            icon: <ExclamationCircleOutlined />,
            centered: true,
            maskClosable: true,
            cancelText: `${t("general.cancel")}`,
            onOk: () => {
                openRearing(_id)
            },
        })
    }

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deletePeriode(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.rearing.resDelFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteRearing.deleted === true) {
                    validationMessage('success', `${t('pages.rearing.resDelSuccess')}`)
                    fetchDataClose()
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.rearing.resDelFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.rearing.delConfirm')} ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            centered: true,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const exportBudidaya = async (idPeriode, periode, house) => {
        setLoadBudidaya(idPeriode)
        let token = cookies.get('token')
        await axios
            .get(`${process.env.REACT_APP_API_URL}/pdf-budidaya/${idPeriode}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                responseType: 'blob'
            })
            .then(res => {
                if (typeof (res.data === 'blob')) {
                    setLoadBudidaya('')
                    const fileName = `Laporan Budidaya ${periode} ${house}.pdf`
                    blobToDataURL(res.data, fileName)
                    errMessage('success', `Berhasil export Laporan Budidaya ke file PDF`)
                }
            }).catch((error) => {
                console.log('err', error)
                errMessage('error', `Gagal export Laporan Budidaya ke file PDF`)
                setLoadBudidaya('')
            })
    }

    const handleExport = (idPeriode, periode, house) => {
        exportBudidaya(idPeriode, periode, house)
    }

    const detailRearing = useCallback((value, period) => {
        getIdPeriode(value)
        getPeriode(period)
        props.navigate(CONSTANTS.DETAIL_REARING_CLOSE_MENU_KEY)
    }, [getIdPeriode, getPeriode, props])

    const columns = [
        {
            title: `Ranking Kandang`,
            dataIndex: 'rank',
            key: 'rank',
            width: 60,
            align: 'center',
            sorter: (a, b) => a.rank - b.rank,
        },
        {
            title: `${t('pages.rearing.date')}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            width: 120,
        },
        {
            title: `${t('pages.rearing.house')}`,
            dataIndex: 'kandang',
            key: 'kandang',
            width: 120,
            ...getColumnSearchProps('kandang'),
        },
        {
            title: `${t('pages.rearing.name')}`,
            dataIndex: 'nama',
            key: 'nama',
            width: 100,
        },
        {
            title: `${t('pages.rearing.population')}`,
            dataIndex: 'populasi',
            key: 'populasi',
            className: 'right',
            width: 100,
        },
        {
            title: `${t('pages.rearing.weight')}`,
            dataIndex: 'bobot',
            key: 'bobot',
            className: 'right',
            width: 60,
        },
        {
            title: `${t('pages.rearing.doc')}`,
            dataIndex: 'doc',
            className: 'center',
            key: 'doc',
            width: 100,
        },
        {
            title: `FCR`,
            dataIndex: 'fcr',
            className: 'right',
            key: 'fcr',
            sorter: (a, b) => a.fcr - b.fcr,
            width: 60,
        },
        {
            title: `Dif FCR`,
            dataIndex: 'difFcr',
            className: 'right',
            key: 'difFcr',
            sorter: (a, b) => a.difFcr - b.difFcr,
            width: 60,
        },
        {
            title: `IP`,
            dataIndex: 'indexPerformance',
            className: 'right',
            key: 'indexPerformance',
            sorter: (a, b) => a.ip - b.ip,
            width: 60,
        },
        {
            title: `${t('pages.rearing.depletion')}`,
            dataIndex: 'deplesi',
            className: 'right',
            key: 'deplesi',
            sorter: (a, b) => a.deplesi - b.deplesi,
            width: 60,
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            fixed: !isMobileOnly && 'right',
            align: 'center',
            width: '15rem',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('general.detail')}>
                        <Button
                            hidden={!isRecording} style={{
                                color: 'white',
                                backgroundColor: '#00923F',
                                borderRadius: '5px'
                            }} onClick={() => detailRearing(row.key, row.period)}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.unlock')}>
                        <Button
                            hidden={!role} style={{
                                color: 'white',
                                backgroundColor: 'deepskyblue',
                                borderRadius: '5px'
                            }} onClick={() => openConfirm(row.key)}><FontAwesomeIcon icon={faLockOpen} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title='Export Laporan Budidaya'>
                        <Button id={row.key} style={{
                            color: 'white',
                            backgroundColor: 'coral',
                            borderRadius: '5px'
                        }} loading={loadingBudidaya === row.key}
                            onClick={() => handleExport(row.key, row.nama, row.kandang)}
                        ><FontAwesomeIcon icon={faDownload} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button
                            hidden={!role} style={{
                                color: 'white',
                                backgroundColor: '#FF0303',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const rankingSort = (a, b) => {
        var Item1 = a['ip'];
        var Item2 = b['ip'];
        if (Item1 !== Item2) {
            return (Item2 - Item1);
        }
        else {
            return (a.difFcr - b.difFcr);
        }
    }

    const rearingRanked = rearingClose.sort(rankingSort)
    for (let i = 0; i < rearingRanked.length; i++) {
        rearingRanked[i].rank = i + 1
    }

    const data = rearingRanked.map(({ _id, name, rank, chickInDate, doc, population, chickInWeight, house, fcr, difFcr, ip, deplesi, restPopulation }, index) => ({
        key: _id,
        id: _id,
        rank: rank,
        nama: name,
        fcr: fcr,
        difFcr: difFcr,
        indexPerformance: <NumberFormat value={ip} displayType={'text'} thousandSeparator={true} />,
        ip: ip,
        deplesi: deplesi,
        period: house.warehouseName + ' ' + name,
        doc: doc?.name,
        tanggal: moment(new Date(parseInt(chickInDate))).format("DD MMMM YYYY"),
        kandang: house.warehouseName,
        populasi: <NumberFormat value={population} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.rearing.chicken')}`} />,
        bobot: chickInWeight + ' gram',
        weight: chickInWeight,
        population: population,
        restPopulation: restPopulation
    }))

    return (
        <Layout>
            <Layout.Content className="dashboard-container">
                <Row>
                    <Col lg={12} sm={24} className='mb-5'>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.rearing.rearingClose')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={{ ...tableParams, total }}
                                onChange={handleTableChange}
                                className="table-dashboard"
                                scroll={{ x: 1000, y: '70vh' }}
                                summary={pageData => {
                                    let totalPop = 0
                                    let avgWeight = 0
                                    let sigmaWeight = 0

                                    let sigmaRestPop = 0

                                    let sigmaIp = 0
                                    let sigmaFcr = 0
                                    let sigmaDep = 0

                                    let avgIp = 0
                                    let avgFcr = 0
                                    let avgDep = 0
                                    pageData.forEach(({
                                        population,
                                        weight,
                                        restPopulation,
                                        ip,
                                        fcr,
                                        deplesi
                                    }) => {
                                        totalPop += parseInt(population)
                                        sigmaWeight += (weight * population)
                                        sigmaRestPop += restPopulation
                                        sigmaFcr += (fcr * restPopulation)
                                        sigmaIp += (ip * restPopulation)
                                        sigmaDep += (deplesi * restPopulation)
                                    })
                                    avgWeight = parseFloat((sigmaWeight / totalPop).toFixed(2))
                                    avgIp = parseInt(sigmaIp / sigmaRestPop)
                                    avgFcr = parseFloat((sigmaFcr / sigmaRestPop).toFixed(2))
                                    avgDep = parseFloat((sigmaDep / sigmaRestPop).toFixed(2))

                                    return (
                                        <>
                                            <Table.Summary.Row >
                                                <Table.Summary.Cell index={0} colSpan={3}>Total</Table.Summary.Cell>

                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={totalPop} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.rearing.chicken')}`} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={avgWeight} displayType={'text'} thousandSeparator={true} suffix={` gram`} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={avgFcr} displayType={'text'} thousandSeparator={true} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={avgIp} displayType={'text'} thousandSeparator={true} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={avgDep} displayType={'text'} thousandSeparator={true} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right"></Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    )
                                }}
                            />
                        </Loading>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdPeriode,
    getPeriode,
    getListKandang,
    getPeriodeAktif,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(RearingClosePage)
export default page