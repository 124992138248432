import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import NumberFormat from 'react-number-format'
import { Space, Button, Tooltip, Modal } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import DetailPertumbuhanComponent from '../../../../modules/dashboard-peternak/rearing-record-component/detail-rearing-record-component/detail-pertumbuhan-component'
import { getListGrowingParams } from '../../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action'
import { getGrowingParams, getDetailRecordPertumbuhan, updateRecordPertumbuhan, deleteRecordPertumbuhan } from '../rearing-record-aksi-page/query-rearing-record'
import { validationMessage } from '../../../../common/component/notification/notification'
import { API } from '../../../../common/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

function DetailPertumbuhanPage(props) {
    const { confirm } = Modal
    const { getListGrowingParams, pertumbuhan, fetchDataPertumbuhan, setLoading, loading, idRearingRecord, houseActive, profil, t } = props
    const [visible, setVisible] = useState(false)
    const [idData, setIdData] = useState(null)
    const [detail, setDetail] = useState("")


    useEffect(() => {
        getListGrowingParams(getGrowingParams("", 0, 0))
    }, [getListGrowingParams])

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(getDetailRecordPertumbuhan(idData))
                    .then(res => {
                        setDetail(res.data.data.growing)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showEdit = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = async (values) => {
        await API.post(updateRecordPertumbuhan(idData, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.recording.bw.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.updateGrowing._id) {
                    validationMessage('success', `${t('pages.recording.bw.resEditSuccess')}`)
                    fetchDataPertumbuhan(idRearingRecord)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.recording.bw.resEditFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deleteRecordPertumbuhan(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.recording.bw.resDelFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteGrowing.deleted === true) {
                    validationMessage('success', `${t('pages.recording.bw.resDelSuccess')}`)
                    fetchDataPertumbuhan(idRearingRecord);
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.recording.bw.resDelFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t('pages.recording.bw.delConfirm')}`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const columns = [
        {
            title: `Prosentase`,
            dataIndex: 'procentage',
            key: 'procentage',
            align: 'center'
        },
        {
            title: `Jenis Ayam`,
            dataIndex: 'type',
            key: 'type',
            align: 'center'
        },
        {
            title: `${t('pages.recording.bw.value')}`,
            dataIndex: 'nilai',
            key: 'nilai',
            align: 'center'
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 130,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button disabled={houseActive ? false : true}
                            hidden={profil.permission.editRecording ? false : true}
                            style={{
                                color: 'white',
                                backgroundColor: houseActive ? '#FFA903' : 'whitesmoke',
                                borderRadius: '5px'
                            }} onClick={() => showEdit(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button disabled={houseActive ? false : true}
                            hidden={profil.permission.writeRecording ? false : true}
                            style={{
                                color: 'white',
                                backgroundColor: houseActive ? '#FF0303' : 'whitesmoke',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const data = pertumbuhan.map(({ _id, value, procentage, type }, index) => ({
        key: _id,
        procentage: procentage && procentage + ' %',
        persen: procentage,
        value: value,
        type: type === 'rooster' ? 'Jantan' : type === 'hen' ? 'Betina' :
            type === 'big' ? 'Besar' : type === 'medium' ? 'Sedang' :
                type === 'small' ? 'Kecil' : type === 'tiny' ? 'Sangat Kecil' : null,
        nilai: <NumberFormat value={value?.toFixed(2)} displayType={'text'} thousandSeparator={true} />,
    }))

    return (
        <DetailPertumbuhanComponent
            navigate={props.navigate}
            loading={loading}
            columns={columns}
            data={data}
            setLoading={setLoading}
            showEdit={showEdit}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            visible={visible}
            detail={detail}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord,
    dateRearing: state.rearingRecord.dateRearingRecord,
});

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListGrowingParams,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(DetailPertumbuhanPage);
export default page