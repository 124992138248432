import React, { useState } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../common/api'
import { errMessage } from '../../../common/component/notification/notification'
import { navigate } from '../../../common/store/action'
import { Modal, Form, Row, Col, Input, Radio, Upload, Button, Switch, InputNumber } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import Loading from '../../../common/component/loading/loading-container'
import { getIdInvoice } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'
import CONSTANTS from '../../../common/utils/Constants'

function CreateInvoicePage(props) {
    const { showModal, type, total, setTotal, setVisible, visible, getIdInvoice, t } = props
    const [form] = Form.useForm()
    const [isMember, setIsMember] = useState(false)
    const [duration, setDur] = useState(1)
    const [item, setIt] = useState(1)
    const [fileList, setFileList] = useState([])
    const [fileUpload, setFileUpload] = useState([])
    const [loading, setLoading] = useState(false)

    const setDuration = (e) => {
        setDur(e.target.value)
        let price = type === 'annual' && isMember ? 1800000 : type === 'monthly' ? 300000 : 2400000
        setTotal(e.target.value * price * item)
    }

    const setItem = (e) => {
        setIt(e)
        let price = type === 'annual' && isMember ? 1800000 : type === 'monthly' ? 300000 : 2400000
        setTotal(e * price * duration)
    }

    const setMember = (e) => {
        setIsMember(e)
        let price = type === 'annual' && e === true ? 1800000 : type === 'monthly' ? 300000 : 2400000
        setTotal(item * price * duration)
    }

    const postData = (values, type, fileUpload) => {
        const params = new FormData()
        params.set('duration', values.duration)
        params.set('itemNumber', values.itemNumber)
        params.set('type', type)
        if (values.membership && values.membership !== 'undefined') {
            params.set('membership', values.membership)
        }
        if (fileUpload) {
            for (let i = 0; i < fileUpload.length; i++) {
                params.append(`file[${i}]`, fileUpload[i])
            }
        }
        setLoading(true)
        API.postRest('/create-invoice', params)
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    if (res.data.success === true) {
                        getIdInvoice(res.data.data._id)
                        props.navigate(CONSTANTS.INVOICE_KEY)
                        errMessage('success', `${t("pages.subscription.resAddInvSuccess")}`)
                        setLoading(false)
                        setVisible(false)
                    } else {
                        errMessage('error', res.data.message)
                        setLoading(false)
                    }
                } else {
                    errMessage('error', res.data.message)
                    setLoading(false)
                }
                setLoading(false)
            }).catch((error) => {
                errMessage('error', `${t("pages.subscription.resAddInvFailed")}`)
                setLoading(false)
            })
    }

    const propsPicture = {
        name: 'file',
        onChange(info) {
            if (info.file.size > 3000000) {
                errMessage('error', 'Ukuran file harus lebih kecil dari 3 MB!')
            } else
            if (info.file.status === 'done') {
                let arrayPicture = []
                for (let i = 0; i < info.fileList.length; i++) {
                    arrayPicture.push(info.fileList[i].originFileObj)
                }
                setFileList(info.fileList)
                setFileUpload(arrayPicture)
            } else if (info.file.status === 'error') {
                console.log('err', info.file, info.fileList)
            }
        }
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok")
        }, 0)
    }
    const onPreview = async file => {
        let src = file.url
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }
        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow.document.write(image.outerHTML)
    }

    const handleSubmit = (values) => {
        postData(values, type, fileUpload)
    }
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )

    return (
        <>
            <Button block className='button-bottom' onClick={() => showModal()}>{t("pages.subscription.getStarted")}</Button>
            <Modal
                visible={visible}
                title={t("pages.subscription.subsTitle")} centered
                okText={t('general.next')}
                width={500}
                cancelText={t('general.cancel')}
                onCancel={() => setVisible(false)}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{ duration: 1, itemNumber: 1 }}
                >
                    <Loading loading={loading}>
                        <Row gutter={[16, 8]}>
                            <Col span={24}>
                                <span className="font-semi-bold black font-title-medium">{t("pages.subscription.duration")}</span>
                                <Form.Item
                                    name="duration" rules={[{ required: true, message: `${t('pages.subscription.msgDuration')}` }]}
                                >
                                    <Input type='number'
                                        style={{ width: '100%' }}
                                        placeholder="3..."
                                        className="input-suffix"
                                        suffix={type === 'monthly' ? t('pages.subscription.month') : t('pages.subscription.year')}
                                        onChange={(value) => setDuration(value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <span className="font-semi-bold black font-title-medium">{t("pages.subscription.houseTotal")}</span>
                                <Form.Item
                                    name="itemNumber" rules={[{ required: true, message: `${t('pages.subscription.msgItem')}` }]}
                                >
                                    <InputNumber
                                        min={1} max={5}
                                        style={{ width: '100%' }}
                                        placeholder="3..."
                                        className="input-suffix"
                                        addonAfter={t('pages.subscription.house')}
                                        onChange={(value) => setItem(value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <span hidden={type === 'annual' ? false : true}>{t('pages.subscription.isMember')} <Switch className='ml-10' checkedChildren={t('general.yes')} unCheckedChildren={t('general.no')}
                                    onChange={(e) => setMember(e)} />
                                </span>
                            </Col>
                            <Col span={24} hidden={type === 'annual' && isMember ? false : true}>
                                <span className="black font-semi-bold font-title-content">{t('pages.subscription.membership')}</span>
                                <Form.Item
                                    name="membership"
                                    rules={[{ required: isMember ? true : false, message: `${t('pages.subscription.msgMembership')}` }]}
                                >
                                    <Radio.Group buttonStyle="solid">
                                        <Radio.Button value="Pinsar">Pinsar</Radio.Button>
                                        <Radio.Button value="Himpuli">Himpuli</Radio.Button>
                                        <Radio.Button value="Gopan">Gopan</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={24} hidden={type === 'annual' && isMember ? false : true}>
                                <span className="black font-semi-bold font-title-content">{t('pages.subscription.upload')}</span>
                                <Form.Item
                                    name="file"
                                    rules={[{ required: isMember ? true : false, message: `${t('pages.subscription.msgUpload')}` }]}
                                >
                                    <Upload
                                        {...propsPicture}
                                        maxCount={1}
                                        customRequest={dummyRequest}
                                        onRemove={() => setFileList([])}
                                        listType="picture-card"
                                        onPreview={onPreview}
                                    >
                                        {fileList.length < 1 ? uploadButton : <img alt='Upload' src={fileList[0].thumbUrl} style={{ width: '100%' }} />}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col>
                                <span className="black font-semi-bold font-title-content">Total: <NumberFormat value={total} prefix='Rp' displayType={'text'} thousandSeparator={true} /></span>
                            </Col>
                        </Row>
                    </Loading>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate, getIdInvoice
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateInvoicePage)
export default page