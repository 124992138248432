import React from 'react';
import { Layout, Breadcrumb, Row, Col, Table, } from 'antd';
import Loading from '../../../../common/component/loading/loading-container';
import ModalCreateOvk from '../../../../app/dashboard-peternak/master-page/ovk-page/ovk-aksi-page/create-ovk-page';
import ModalEditOvk from '../../../../app/dashboard-peternak/master-page/ovk-page/ovk-aksi-page/edit-ovk-page';
import { isMobileOnly } from 'react-device-detect';

function OvkComponent(props) {
    const { Content } = Layout;
    const { columns, data, fetchData, handleOk, handleCancel, visible, idData, loading, handleSubmit, detail, setLoading, profil, t } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col lg={12} xs={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.ovk.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={12} xs={24} hidden={!profil.permission.writeMaster}>
                        <ModalCreateOvk fetchData={fetchData} setLoading={setLoading} t={t} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                className="table-dashboard"
                                scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                            />
                        </Loading>
                    </Col>
                    <ModalEditOvk visible={visible} handleOk={handleOk} idData={idData}
                        handleCancel={handleCancel} handleSubmit={handleSubmit} detail={detail} t={t} />
                </Row>
            </Content>
        </Layout>
    )
}

export default OvkComponent