import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Card, Divider, Button, Space, Empty, Tag } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { navigate } from '../../../common/store/action'
import Loading from '../../../common/component/loading/loading-container'
import CONSTANTS from '../../../common/utils/Constants'
import { API } from '../../../common/api'

import { getRecordPanenDay } from './rearing-record-aksi-page/query-rearing-record'
import { getIdHarvest } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'

function RecordingHarvest(props) {
    const { navigate, getIdHarvest } = props
    const [loading, setLoading] = useState(false)
    const [panen, setPanen] = useState([])

    const fetchDataPanen = async () => {
        setLoading(true)
        await API.get(getRecordPanenDay())
            .then(res => {
                setPanen(res.data.data.harvestDay.mutations)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setPanen([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchDataPanen()
    }, [])

    return (
        <Layout>
            <Layout.Content className="dashboard-container">
                <Space align='start'>
                    <Link to="/peternak/dashboard">
                        <Button shape="circle" style={{ border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 5%)' }}
                            icon={<FontAwesomeIcon icon={faArrowLeft} />} size='default' className='mr-10'
                        />
                    </Link>
                    <div className='font-title-dashboard font-semi-bold mb-10'>Recording Panen</div>
                </Space>
                <Loading loading={loading}>
                    {panen.length < 1 &&
                        <Empty className='center'
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>Belum ada input panen. Mohon hubungi management Anda untuk menginput panen terlebih dahulu</span>
                            }
                        />
                    }
                    <Row gutter={[16, 8]}>
                        {panen && panen.map(d =>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <Card hoverable
                                    onClick={() => {
                                        getIdHarvest(d._id)
                                        navigate(CONSTANTS.REARING_RECORD_HARVEST)
                                    }}>
                                    <h3 className='font-semi-bold mb-0'>{d.house}</h3>
                                    <CalendarOutlined /> <span className='font-light font-content ml-5'>
                                        {moment(new Date(parseInt(d.date))).format('DD MMMM YYYY')}
                                    </span>
                                    <Divider />
                                    <div>Plat nomor: {d.plat}</div>
                                    <div>Tonase DO: {d.tonase} kg</div>
                                    <Tag className='float-right'
                                        color={d.isLocked ? '#ff4a3d' : '#03a5fc'}>
                                        {d.isLocked === true ? 'Terkunci' : 'Belum Terkunci'}
                                    </Tag>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Loading>
            </Layout.Content >
        </Layout >
    )
}

const mapStateToProps = state => ({
    profil: state.profil.dataProfil,
    stokPakan: state.stokPakan.dataStokPakan,
    dateRearing: state.rearingRecord.dateRearingRecord,
    idRearingRecord: state.rearingRecord.idRearingRecord,
    idGudangPakan: state.gudangPakan.idGudangPakan,
})

const mapDispatchToProps = (dispatch => ({
    navigate, getIdHarvest
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(RecordingHarvest)
export default page