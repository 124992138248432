export const getPetugas = (keyword, limit, skip) => {
    return (
        `query{
            staffs(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              staffs{
                    _id
                    name
                    email
                    username
                    address
                    phone
                    type
                    accessshouse{
                      _id
                      name
                    }
                    permission{
                      _id
                    }
                }
            }
          }
        `
    );
}

export const deletePetugas = (_id) => {
  return (
    `mutation{
        deleteUser(_id : "${_id}"){
            deleted
      }
    }
  ` 
  );
} 

export const createPetugas = (value) => {
  return {
    query: `mutation{
            createUser(userInput: {
                name : "${value.namaPetugas}"
                username : "${value.username}"
                password :"${value.password}"
                email : "${value.email}"
                phone : "${value.nomorTelefon}"
                address : "${value.alamat}"
                type : "${value.type.value}"
                idAddr: "${value.idAddr}"
          },
          accessInput: {
            house : "${value.house}"
          }
          ){
            _id
          }
        }
      `
    }
}

export const detailPetugas = (_id) => {
  return (
    `query{
        user(_id : "${_id}"){
            _id
            name
            email
            username
            password
            foto
            address
            phone
            value: type
            accessshouse{
              _id
              name
            }
            permission{
              _id
              readDashboard
              readRearing
              writeRearing
              readRecording
              writeRecording
              editRecording
              nominalRecording
              staffMode
              readIot
              writeIot
              readStock
              writeStock
              readStockflow
              writeStockflow
              readMaster
              writeMaster
              readUsers
              writeUsers
              createSubscription
            }
        }
      }
    `
  )
}

export const updatePetugas = (_id, value) => {
    return {
    query: `mutation{
                    updateStaff(_id : "${_id}", updateUserInput : {
                        name : "${value.namaPetugas}"
                        username : "${value.username}"
                        email : "${value.email}"
                        phone : "${value.nomorTelefon}"
                        address : "${value.alamat}"
                        type : "${value.type.value}"
                        house : "${value.house}"
                        idAddr: "${value.idAddr}"
                  }){
                      _id
                  }
                }
        `
    }
}

export const updatePermission = (_id, value) => {
  return {
  query: `mutation{
          updatePermission(user: "${_id}",
            updatePermissionInput: {
                readMaster: ${value.readMaster}
                writeMaster: ${value.writeMaster}
                readUsers: ${value.readUsers}
                writeUsers: ${value.writeUsers}
                readStock: ${value.readStock}
                writeStock: ${value.writeStock}
                readStockflow: ${value.readStockflow}
                writeStockflow: ${value.writeStockflow}
                readRearing: ${value.readRearing}
                writeRearing: ${value.writeRearing}
                readRecording: ${value.readRecording}
                editRecording: ${value.editRecording}
                writeRecording: ${value.writeRecording}
                nominalRecording: ${value.nominalRecording}
                staffMode: ${value.staffMode}
                readIot: ${value.readIot}
                writeIot: ${value.writeIot}
                readDashboard:  ${value.readDashboard}
                createSubscription: ${value.createSubscription}
            }){
              _id
              readDashboard
              readRearing
              writeRearing
              readRecording
              writeRecording
              editRecording
              nominalRecording
              staffMode
              readIot
              writeIot
              readStock
              writeStock
              readStockflow
              writeStockflow
              readMaster
              writeMaster
              readUsers
              writeUsers
              createSubscription
          }
        }
      `
  }
}