export const getDoc = (keyword, limit, skip) => {
    return (
        `query{
            docs(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              docs {
                _id
                name
                type
                corporation
                strain{
                    name
                }
              }
            }
          }
      `
    )
}

export const createDoc = (value, type) => {
  return {
    query: `mutation{
              createDoc(docInput:{
                name: "${value.name}"
                type : "${type}"
                corporation: "${value.corporation}"
                strain : "${value.strain.value}"
              }){
                _id
                name
                type
                strain{
                    name
                }
              }
            }
        `
    }
}

export const detailDoc = (_id) => {
  return (
    `query{
          doc(_id : "${_id}"){
            _id
            name
            value: type
            strain{
                _id
                value: name
            }
            corporation
          }
        }
    `
  )
}

export const updateDoc = (_id, value, type) => {
    return {
      query: `mutation{
              updateDoc(_id: "${_id}",updateDocInput:{
                name: "${value.name}"
                type : "${type}"
                corporation: "${value.corporation}"
                strain : "${value.strain.value}"
              }){
                _id
                name
                type
                corporation
                strain{
                    _id
                    name
                }
              }
            }
      `
    }   
}

export const deleteDoc = (_id) => {
  return (
    `mutation{
        deleteDoc(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}