import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../common/api'
import { errMessage } from '../../../common/component/notification/notification'
import { navigate } from '../../../common/store/action'
import { Modal, Form, Row, Col, Input, Radio, Upload, Button, Card, Divider, Image, Badge, InputNumber } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import Loading from '../../../common/component/loading/loading-container'
import { getIdInvoice } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'
import CONSTANTS from '../../../common/utils/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'

function ChangePlan(props) {
    const { type, getIdInvoice, company, role, t } = props
    const [form] = Form.useForm()
    const [duration, setDur] = useState(1)
    const [item, setIt] = useState(0)
    const [fileList, setFileList] = useState([])
    const [fileUpload, setFileUpload] = useState([])
    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [visible, setVisible] = useState(false)
    const [hidden, setHidden] = useState(true)
    const [tipe, setTipe] = useState('monthly')
    let price = tipe === 'annualMember' ? 1800000 : tipe === 'monthly' ? 300000 : 2400000
    const [itemNumber, setItemNumber] = useState(false)
    const logo = require(`../../../assets/images/logo-white.png`)

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    useEffect(() => {
        setTotal(price * company.house_limit * 1)
    }, [price, company])


    const setDuration = (e) => {
        setDur(parseInt(e.target.value))
        setTotal(parseInt(e.target.value) * price * (company.house_limit + item))
    }

    const setItem = (e) => {
        setIt(parseInt(e))
        setTotal((parseInt(e) + company.house_limit) * price * duration)
    }

    const postData = (values, type, fileUpload) => {
        const params = new FormData()
        params.set('duration', values.duration)
        params.set('type', tipe)
        if (values.itemNumber && values.itemNumber !== 'undefined') {
            params.set('itemNumber', values.itemNumber)
        }
        if (values.membership && values.membership !== 'undefined') {
            params.set('membership', values.membership)
        }
        if (fileUpload) {
            for (let i = 0; i < fileUpload.length; i++) {
                params.append(`file[${i}]`, fileUpload[i])
            }
        }
        setLoading(true)
        API.postRest('/change-plan', params)
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    if (res.data.success === true) {
                        getIdInvoice(res.data.data._id)
                        props.navigate(CONSTANTS.INVOICE_KEY)
                        errMessage('success', `${t("pages.subscription.resAddInvSuccess")}`)
                        setLoading(false)
                        setVisible(false)
                    } else {
                        errMessage('error', res.data.message)
                        setLoading(false)
                    }
                } else {
                    errMessage('error', res.data.message)
                    setLoading(false)
                }
                setLoading(false)
            }).catch((error) => {
                errMessage('error',  `${t("pages.subscription.resAddInvFailed")}`)
                setLoading(false)
            })
    }

    const propsPicture = {
        name: 'file',
        onChange(info) {
            if (info.file.size > 3000000) {
                errMessage('error', 'Ukuran file harus lebih kecil dari 3 MB!')
            } else
            if (info.file.status === 'done') {
                let arrayPicture = []
                for (let i = 0; i < info.fileList.length; i++) {
                    arrayPicture.push(info.fileList[i].originFileObj)
                }
                setFileList(info.fileList)
                setFileUpload(arrayPicture)
            } else if (info.file.status === 'error') {
                console.log('err', info.file, info.fileList)
            }
        }
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok")
        }, 0)
    }
    const onPreview = async file => {
        let src = file.url
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }
        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow.document.write(image.outerHTML)
    }

    const handleSubmit = (values) => {
        postData(values, type, fileUpload)
    }
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )

    return (
        <>
            <Button style={{ color: 'white', backgroundColor: '#FFA903' }}
                onClick={() => showModal()} hidden={role !== 'owner' ? true : false}
            >{t("pages.subscription.changePlan")}</Button>

            <Modal
                visible={visible}
                title={t("pages.subscription.changePlan")}
                onCancel={() => setVisible(false)}
                centered
                width={1000}
                footer={null}
            >
                <Row gutter={[16, 16]}>
                    <Col span={7}>
                        <Card style={{ backgroundColor: '#f5ead7', height: '100%', borderRadius: 20 }}>
                            <div className='font-bold font-title-dashboard'>{t("pages.subscription.platinum")}</div>
                            <Row gutter={[16, 8]} className='text-left mt-30' style={{ marginBottom: '220px' }}>
                                <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                <Col span={22}><p className='mb-0 '>{t("pages.subscription.platinum1")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                <Col span={22}><p className='mb-0 '>{t("pages.subscription.platinum2")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                <Col span={22}><p className='mb-0 '>{t("pages.subscription.platinum3")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                <Col span={22}><p className='mb-0 '>{t("pages.subscription.platinum4")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                <Col span={22}><p className='mb-0 '>{t("pages.subscription.platinum5")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                <Col span={22}><p className='mb-0 '>{t("pages.subscription.platinum6")}</p></Col>

                                <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                <Col span={22}><p className='mb-0 '>{t("pages.subscription.platinum7")}</p></Col>
                            </Row>

                            <Card className='white' hoverable style={{
                                backgroundImage: 'linear-gradient(135deg, rgb(200, 125, 85) 0%, rgb(245, 155, 35) 100%)'
                            }}
                                onClick={() => window.open('https://wa.me/6285155050769')}
                            >
                                <Image src={logo} preview={false} width={25} />
                                <span className='font-bold font-title-dashboard ml-10'>Platinum</span>
                                <Divider style={{ borderColor: 'white', margin: 0 }} />
                                <p className='font-regular mt-10 mb-0'>{t("pages.subscription.specialPrice")}</p>
                                <p className='font-semi-bold mb-0'>{t("pages.subscription.contact")}</p>
                            </Card>
                        </Card>
                    </Col>
                    <Col span={17}>
                        <div className='font-title-dashboard font-bold mt-20 mb-20'>{t("pages.subscription.pickPlan")}</div>
                        <Row gutter={[16, 16]}>
                            <Col span={12} hidden={type === 'monthly' || !hidden ? true : false}>
                                <Card bordered hoverable style={{ borderWidth: 2 }}>
                                    <div className='font-semi-bold font-title-medium'>Premium {t("pages.subscription.monthly")}</div>
                                    <span className='font-bold font-title'><sup className='font-light font-content'>Rp</sup>300</span><span className='font-content font-semi-bold'>{t("pages.subscription.k")}</span>
                                    <p>{t("pages.subscription.perHouse")}</p>
                                    <Row gutter={[16, 8]} className='text-left mt-30 mb-60'
                                    >
                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.monthly1")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.monthly2")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.monthly3")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.monthly4")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.monthly5")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.monthly6")}</p></Col>
                                        <Col className='mt-5'><p style={{ fontSize: 11 }}>{t("pages.subscription.maxHouse")}</p></Col>
                                    </Row>
                                    <Button block className='p-10 mt-80' style={{ backgroundColor: '#f2f6fc', height: 50 }}
                                        onClick={() => {
                                            setHidden(false)
                                            setTipe('monthly')
                                        }}
                                    >{t("pages.subscription.getStarted")}</Button>
                                </Card>
                            </Col>
                            <Col span={12} hidden={type === 'annualMember' || !hidden ? true : false}>
                                <Card bordered hoverable style={{ borderWidth: 2 }}>
                                    <div className='font-semi-bold font-title-medium'>Premium {t("pages.subscription.annual")}</div>
                                    <Row>
                                        <Col span={20}>
                                            <sup className='font-light font-content'>Rp</sup><span className='font-medium font-title-dashboard' style={{ textDecoration: 'line-through' }}>3600</span><span className='font-content font-semi-bold'>{t("pages.subscription.k")}</span>
                                            <br /><span className='font-bold font-title'><sup className='font-light font-content'>Rp</sup>1800</span><span className='font-content font-semi-bold'>{t("pages.subscription.k")}</span>
                                        </Col>
                                        <Col span={4}>
                                            <Badge.Ribbon text="50% off" color="orange" className='mt-30'>
                                            </Badge.Ribbon>
                                        </Col>
                                    </Row>
                                    <p>{t("pages.subscription.perHouse")}</p>
                                    <p>{t("pages.subscription.annual8")}</p>
                                    <Row gutter={[16, 8]} className='text-left mt-30 mb-30'>
                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual1")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual2")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual3")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual4")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual5")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual6")}</p></Col>
                                        <Col className='mt-5'><p style={{ fontSize: 11 }}>{t("pages.subscription.maxHouse")}</p></Col>
                                    </Row>
                                    <Button block className='p-10' style={{ backgroundColor: '#f2f6fc', height: 50 }}
                                        onClick={() => {
                                            setHidden(false)
                                            setTipe('annualMember')
                                        }}
                                    >{t("pages.subscription.getStarted")}</Button>
                                </Card>
                            </Col>
                            <Col span={12} hidden={type === 'annual' || !hidden ? true : false}>
                                <Card bordered hoverable style={{ borderWidth: 2 }}>
                                    <div className='font-semi-bold font-title-medium'>Premium {t("pages.subscription.annual")}</div>
                                    <Row>
                                        <Col span={20}>
                                            <sup className='font-light font-content'>Rp</sup><span className='font-medium font-title-dashboard' style={{ textDecoration: 'line-through' }}>3600</span><span className='font-content font-semi-bold'>{t("pages.subscription.k")}</span>
                                            <br /><span className='font-bold font-title'><sup className='font-light font-content'>Rp</sup>2400</span><span className='font-content font-semi-bold'>{t("pages.subscription.k")}</span>
                                        </Col>
                                        <Col span={4}>
                                            <Badge.Ribbon text="34% off" color="orange" className='mt-30'>
                                            </Badge.Ribbon>
                                        </Col>
                                    </Row>
                                    <p>per kandang</p>
                                    <Row gutter={[16, 8]} className='text-left mt-30 mb-30'>
                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual1")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual2")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual3")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual4")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual5")}</p></Col>

                                        <Col span={2}><FontAwesomeIcon icon={faCheck} /></Col>
                                        <Col span={22}><p className='mb-0'>{t("pages.subscription.annual6")}</p></Col>
                                        <Col className='mt-5'><p style={{ fontSize: 11 }}>{t("pages.subscription.maxHouse")}</p></Col>
                                    </Row>
                                    <Button block className='p-10 mt-80' style={{ backgroundColor: '#f2f6fc', height: 50 }}
                                        onClick={() => {
                                            setHidden(false)
                                            setTipe('annual')
                                        }}
                                    >{t("pages.subscription.getStarted")}</Button>
                                </Card>
                            </Col>
                        </Row>
                        <Card bordered style={{ borderWidth: 2 }} hidden={hidden}>
                            <p className='font-semi-bold'>{tipe === 'monthly' ? `Premium ${t("pages.subscription.monthly")}` : tipe === 'annual' ? `Premium ${t("pages.subscription.annual")}` : `Premium T${t("pages.subscription.annualMember")}`}</p>
                            <Form
                                form={form}
                                layout="vertical"
                                name="form_in_modal"
                                initialValues={{
                                    duration: 1
                                }}
                            >
                                <Loading loading={loading}>
                                    <Row gutter={[16, 8]}>
                                        <Col span={24}>
                                            <span className="font-semi-bold black font-title-medium">{t("pages.subscription.duration")}</span>
                                            <Form.Item
                                                name="duration" rules={[{ required: true, message: `${t('pages.subscription.msgDuration')}` }]}
                                            >
                                                <Input type='number'
                                                    style={{ width: '100%' }}
                                                    placeholder="3..."
                                                    className="input-suffix"
                                                    suffix={tipe === 'monthly' ? t('pages.subscription.month') : t('pages.subscription.year')}
                                                    onChange={(value) => setDuration(value)}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <span className="font-semi-bold black font-title-medium">{company.house_limit} {t('pages.subscription.house')} {itemNumber && ' +'}</span>
                                            <Button hidden={itemNumber || company.house_limit >= 5}
                                                style={{ backgroundColor: '#f2f6fc' }}
                                                onClick={() => setItemNumber(true)}
                                                className='price-plus ml-10'
                                            >
                                                <FontAwesomeIcon icon={faPlus} className='mr-10' />{t('pages.subscription.addHouse')}
                                            </Button>
                                            <Row>
                                                <Col>
                                                    <Form.Item
                                                        hidden={!itemNumber}
                                                        name="itemNumber"
                                                    >
                                                        <InputNumber min={1} max={5 - company.house_limit}
                                                            placeholder={t('pages.subscription.addHouse')}
                                                            className="input-suffix" style={{width: '100%'}}
                                                            onChange={(value) => setItem(value)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col>
                                                    <Button hidden={!itemNumber}
                                                        style={{ backgroundColor: '#f2f6fc' }}
                                                        onClick={() => {
                                                            setItemNumber(false)
                                                            setIt(0)
                                                            setTotal(price * duration * company.house_limit)
                                                            form.setFieldsValue({ itemNumber: null })
                                                        }}
                                                        icon={<FontAwesomeIcon icon={faTimes} />}
                                                        className='price-plus ml-10'
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={24} hidden={tipe === 'annualMember' ? false : true}>
                                            <span className="black font-semi-bold font-title-content">{t('pages.subscription.membership')}</span>
                                            <Form.Item
                                                name="membership"
                                                rules={[{ required: tipe === 'annualMember' ? true : false, message: `${t('pages.subscription.msgMembership')}` }]}
                                            >
                                                <Radio.Group buttonStyle="solid">
                                                    <Radio.Button value="Pinsar">Pinsar</Radio.Button>
                                                    <Radio.Button value="Himpuli">Himpuli</Radio.Button>
                                                    <Radio.Button value="Gopan">Gopan</Radio.Button>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} hidden={tipe === 'annualMember' ? false : true}>
                                            <span className="black font-semi-bold font-title-content">{t('pages.subscription.upload')}</span>
                                            <Form.Item
                                                name="file"
                                                rules={[{ required: tipe === 'annualMember' ? true : false, message: `${t('pages.subscription.msgUpload')}` }]}
                                            >
                                                <Upload
                                                    {...propsPicture}
                                                    maxCount={1}
                                                    customRequest={dummyRequest}
                                                    onRemove={() => setFileList([])}
                                                    listType="picture-card"
                                                    onPreview={onPreview}
                                                >
                                                    {fileList.length < 1 ? uploadButton : <img alt='Upload' src={fileList[0].thumbUrl} style={{ width: '100%' }} />}
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                        <Col className='mt-30'>
                                            <p className='font-regular mb-0'>Total</p>
                                            <span className="black font-semi-bold font-title-content"><NumberFormat value={total} prefix='Rp' displayType={'text'} thousandSeparator={true} /></span>
                                        </Col>
                                    </Row>
                                </Loading>
                                <div className='mt-20'>
                                    <Button className='mr-10'
                                        onClick={() => {
                                            setHidden(true)
                                            setTotal(0)
                                            form.resetFields()
                                            setFileList([])
                                        }
                                        }>{t("general.cancel")}</Button>
                                    <Button type='primary' htmlType='submit'
                                        onClick={() => {
                                            form
                                                .validateFields()
                                                .then((values) => {
                                                    form.resetFields()
                                                    handleSubmit(values)
                                                })
                                                .catch((info) => {
                                                    console.log('Validate Failed:', info)
                                                })
                                        }}>{t("general.next")}</Button>
                                </div>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Modal >
        </>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate, getIdInvoice
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(ChangePlan)
export default page