export const queryKandang = (keyword, limit, skip) => {
  return (
    `query{
            houses(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              totalActive
              houses {
                  _id
                  name
                  capacity
                  address
                  type
                  isActive
                  company{
                    house_limit
                  }
                  feedWarehouse{
                    _id
                    name
                  }
                  ovkWarehouse{
                    _id
                    name
                  }
              }
            }
          }
        `
  );
}

export const queryListKandang = (keyword, limit, skip) => {
  return (
    `query{
          housesByRole(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
            totalActive
            houses {
                _id
                name
                type
                isActive
                rearing{
                  _id
                  name
                  chickInDate
                  house{
                    feedWarehouse{
                      _id
                    }
                    ovkWarehouse{
                      _id
                    }
                  }
                }
            }
          }
        }
      `
  );
}

export const deleteKandang = (_id) => {
  return (
    `mutation{
      deleteHouse(_id : "${_id}"){
        deleted
      }
    }
  `
  );
}

export const createKandang = (value) => {
  return {
    query: `mutation{
          createHouse(houseInput: {
            name : "${value.namaKandang}"
            capacity : ${parseInt(value.kapasistasKandang)}
            address: "${value.alamat}"
            lat: "${value.position.lat}"
            lng: "${value.position.lng}"
            feedWarehouse: "${value.selectGudangPakan.value}"
            ovkWarehouse: "${value.selectGudangOvk.value}"
            otherInformation : "${value.keterangan}"
          }){
            _id
          }
        }
      `
  }
}

export const detailKandang = (_id) => {
  return (
    `query{
        house(_id : "${_id}"){
            _id
            name
            capacity
            address
            lat
            lng
            type
            isActive
            ovkWarehouse {
                value : _id
            }
            feedWarehouse {
                value : _id
            }
        }
      }
    `
  )
}

export const updateKandang = (_id, value) => {
  return {
    query: `mutation{
                  updateHouse(_id : "${_id}", updateHouseInput : {
                      name : "${value.namaKandang}"
                      capacity : ${value.kapasistasKandang}
                      address : "${value.alamat || value.alamatMaps}"
                      lat: "${value.position.lat}"
                      lng: "${value.position.lng}"
                      feedWarehouse : "${value.selectGudangPakan.value}"
                      ovkWarehouse : "${value.selectGudangOvk.value}"
                      otherInformation : "${value.keterangan}"
                  }){
                      _id
                  }
                }
        `
  }
}

export const activateHouse = (_id) => {
  return (
    `mutation{
        activateHouse(_id : "${_id}"){
            _id
            name
            capacity
            address
            type
            isActive
            ovkWarehouse {
                value : _id
            }
            feedWarehouse {
                value : _id
            }
        }
      }
    `
  )
}

export const deactivateHouse = (_id) => {
  return (
    `mutation{
        deactivateHouse(_id : "${_id}"){
            _id
            name
            capacity
            address
            type
            isActive
            ovkWarehouse {
                value : _id
            }
            feedWarehouse {
                value : _id
            }
        }
      }
    `
  )
}