export const ovkStock = {
    title: "Medicine Stock",
    name: "Details",
    supplier: "Supplier",
    balance: "Rest Medicine",
    add: "Add New Medicine Stock",
    addStock: "Add Medicine Stock",
    ovkWarehouse: "Medicine Warehouse",
    date: "Procurement Date",
    addDate: "Addition Date",
    qtyAdd: "Amount of Addition",
    qtyStock: "Amount of Stock",
    ovk: "Medicine",
    price: "Medicine Price per unit",
    addMutation: "Add Mutation",
    mutation: 'Mutation',
    dateMutation: 'Mutation Date',
    phMutationDate: "Select mutation date",
    msgMutationDate: "Please select mutation date!",
    mutationWh: 'Destination Medicine Warehouse',
    phMutationWh: "Select destination medicine warehouse!",
    msgMutationWh: "Please select destination medicine warehouse",
    phOvkWarehouse: "Select Medicine Warehouse",
    docNumber: 'Document Number',
    phOvk: "Select Medicine",
    phName: "Add description ...",
    phDate: "Select procurement date",
    phAddDate: "Select addition date",
    phPeriodDate: "Select period date",
    msgOvkWarehouse: "Please select warehouse!",
    msgOvk: "Please select medicine!",
    msgName: "Please add description!",
    msgDate: "Please select procurement date!",
    msgAddDate: "Please select addition date!",
    msgPeriodDate: "Please select period date!",
    msgPrice: "Please input medicine price!",
    msgQty: "Please input amount of procurement!",
    msgQtyStock: "Please input amount of stock!",
    edit: "Edit Medicine Stock",
    resAddSuccess: "Successfully added medicine stock",
    resAddMutationSuccess: "Successfully added medicine mutation",
    resEditSuccess: "Successfully changed medicine stock",
    resEditTrxSuccess: "Successfully changed medicine transaction",
    resDelSuccess: "Successfully deleted medicine stock",
    resDelTrxSuccess: "Successfully deleted medicine transaction",
    resAddFailed: "Failed to add medicine stock",
    resAddMutationFailed: "Failed to add medicine mutation",
    resEditFailed: "Failed to change medicine stock",
    resEditTrxFailed: "Failed to change medicine transaction",
    resDelFailed: "Failed to delete medicine stock",
    resDelTrxFailed: "Failed to delete medicine transaction",
    delConfirm: "Are you sure you want to remove the medicine stock",
    detail: "Medicine Stock Transaction Details",
    addition: "Addition",
    trxDate: "Transaction date",
    delTrxConfirm: "Are you sure you want to delete this medicine transaction?",
    titleEditAdd: "Edit Medicine Stock Addition Transaction",
    dateStock: "Stock Date",
    usage: "Usage",
    qty: "Amount",
    titleEditUse: "Edit Medicine Stock Usage Transactions",
}