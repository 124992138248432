import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import EditOvkComponent from '../../../../../modules/dashboard-peternak/master-component/ovk-component/ovk-aksi-component/edit-ovk-component'

function EditOvkPage(props) {
    const { visible, handleCancel, detail, handleSubmit, t } = props
    const [suffix, setSuffix] = useState(false)

    return (
        <EditOvkComponent
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            detail={detail}
            ovkUnit={props.ovkUnit}
            ovkUnitRecording={props.ovkUnitRecording}
            setSuffix={setSuffix}
            suffix={suffix}
            t={t}
        />
    );
}

const mapStateToProps = state => ({
    ovkUnit: state.ovk.dataOvkUnit,
    ovkUnitRecording: state.ovk.dataOvkUnitRecording,
});

const mapDispatchToProps = (dispatch => ({
    navigate,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(EditOvkPage);
export default page