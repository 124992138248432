import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Drawer, Form, Row, Col, Input } from 'antd'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'

function EditStandarPage(props) {
    const { visible, detail, handleSubmit, setVisible } = props;
    const [data, setData] = useState(detail)
    const [form] = Form.useForm()

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
                form.setFieldsValue({
                    hari: detail.day,
                    bw: detail.bw,
                    dg: detail.dg,
                    adg: detail.adg,
                    deplesi: detail.dep,
                    fcr: detail.fcr,
                    fi: detail.fi,
                    wi: detail.wi,
                })
            }
        }
        setDetailData()
    }, [detail, form])

    return (
        <div>
            <Drawer
                title={false}
                height={250}
                placement="bottom"
                closable={false}
                onClose={() => setVisible(false)}
                visible={visible} hidden={!visible}
                getContainer={false}
                style={{ position: 'absolute', opacity: visible ? 100 : 0 }}
            >
                <Form
                    form={form} onFinish={handleSubmit}
                    layout="vertical"
                    initialValues={{
                        hari: data.day,
                        bw: data?.bw,
                        dg: data?.dg,
                        adg: data?.adg,
                        fcr: data?.fcr,
                        deplesi: data?.dep
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={6}>
                            <span className="font-semi-bold black font-title-medium">Hari ke-</span>
                            <Form.Item
                                name="hari" rules={[{ required: true, message: 'Silahkan masukan hari ke-!' }]}
                            >
                                <Input type='number'
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="hari"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <span className="font-semi-bold black font-title-medium">Body Weight</span>
                            <Form.Item name="bw">
                                <Input type='number'
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="Gram"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <span className="font-semi-bold black font-title-medium">Daily Gain</span>
                            <Form.Item name="dg">
                                <Input type='number'
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="Gram"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <span className="font-semi-bold black font-title-medium">Average Daily Gain</span>
                            <Form.Item name="adg">
                                <Input type='number'
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="Gram"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <span className="font-semi-bold black font-title-medium">Food Consumtion Rate</span>
                            <Form.Item name="fcr">
                                <Input type='number'
                                    placeholder="5..."
                                    className="input-suffix"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <span className="font-semi-bold black font-title-medium">Deplesi</span>
                            <Form.Item name="deplesi">
                                <Input type='number'
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="Ekor"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <span className="font-semi-bold black font-title-medium">Feed Intake</span>
                            <Form.Item name="fi">
                                <Input type='number'
                                    placeholder="5..."
                                    className="input-suffix"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <span className="font-semi-bold black font-title-medium">Water Intake</span>
                            <Form.Item name="wi">
                                <Input type='number'
                                    placeholder="5..."
                                    className="input-suffix"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Col>
                        <ButtonDashboard
                            htmlType="submit"
                            text='Edit'
                            height={20} size='small'
                            backgroundColor="#008ad4"
                            borderRadius="5px" marginLeft={10}
                            className="font-semi-medium font-content-title button-add"
                            textColor="white"
                        />
                    </Col>
                    <Col>
                        <ButtonDashboard
                            text='Batal'
                            height={20} size='small'
                            borderRadius="5px" marginLeft={5}
                            className="font-semi-medium font-content-title button-add"
                            onClick={() => setVisible(false)}
                        />
                    </Col>
                </Form>
            </Drawer>
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditStandarPage)
export default page