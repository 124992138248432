import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { Layout, Row, Col, Form, Carousel, Select } from 'antd'
import ReCAPTCHA from "react-google-recaptcha"
import i18n from '../../translations/i18n'

import { API } from '../../common/api'
import { navigate } from '../../common/store/action'
import { errMessage } from '../../common/component/notification/notification'
import CONSTANTS from '../../common/utils/Constants'
import Loading from '../../common/component/loading/loading-container'
import ButtonLogin from '../../common/component/button/button-not-icon'
import InputPassword from '../../common/component/input/input-password'

function ChangePasswordPage(props) {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState('')
    const [recaptchaRef, setRecaptchaRef] = useState(null)
    const { t } = useTranslation()
    const { Content } = Layout
    const [lang] = useState(i18n.language)
    const logo = require(`../../assets/images/logo.png`)
    const slide1 = require(`../../assets/svg/chicken-1.svg`)
    const slide2 = require(`../../assets/svg/chicken-2.svg`)
    const slide3 = require(`../../assets/svg/chicken-3.svg`)

    const handleLocales = useCallback((e) => {
        if (i18n.language !== e) {
            i18n.changeLanguage(e)
        }
    }, [])

    useEffect(() => {
        let pathArray = window.location.pathname.split('/')
        let pathName = pathArray[2]
        pathName === '' ? setCurrent('/password-reset') : setCurrent(pathName)
    }, [props])

    const handleSubmit = (value) => {
        const query = {
            query:
                `mutation{
                resetPassword(resetPasswordToken : "${current}",resetPasswordInput:{
                    newPassword : "${value.kataSandi}"
                    token_recaptcha: "${recaptchaRef}"
                 }){
                    name
                    password
              }
            }
            `,
        }
        if (recaptchaRef === null) {
            errMessage('error', `${t('pages.auth.captcha')}`)
        } else {
            const postData = async () => {
                setLoading(true)
                await API.post(query)
                    .then(res => {
                        if (res.data.errors) {
                            errMessage('error', res.data.errors[0].message)
                            setLoading(false)
                        } else if (res.data.data.resetPassword.name) {
                            errMessage('success', `${t('pages.auth.resResetSuccess')}`)
                            props.navigate(CONSTANTS.HOME_MENU_KEY)
                            setLoading(false)
                        }
                    }).catch(error => {
                        console.log(error)
                        errMessage('error', error)
                        setLoading(false)
                    })
            }
            postData()
        }
    }

    return (
        <Layout>
            <Content className="landing-container">
                <Loading loading={loading}>
                    <div className="section-header-page-avatar" style={{ marginTop: '.5rem', marginRight: '.5rem' }}>
                        <Select defaultValue={lang} bordered={false} onChange={(e) => handleLocales(e)}>
                            <Select.Option value="id">ID</Select.Option>
                            <Select.Option value="en">EN</Select.Option>
                        </Select>
                    </div>
                    <Row>
                        <Col lg={12} md={24} sm={24}>
                            <div className="section-picture-password">
                                <div className="section-picture-center">
                                    <Carousel autoplay style={{ padding: '30px' }}>
                                        <div>
                                            <h3 className='caraousel'><img src={slide1} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                        <div>
                                            <h3 className='caraousel'><img src={slide2} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                        <div>
                                            <h3 className='caraousel'><img src={slide3} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={24} sm={24}>
                            <div className="section-content-password">
                                <Row>
                                    <Col span={24}>
                                        <div className="section-logo-password">
                                            <Link to="/"><img src={logo} alt="BroilerX logo" width="40%" /></Link>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="section-title-password">
                                            <span className="font-title-password black font-bold">{t('pages.auth.resetPassword')}</span>
                                            <br />
                                            <span className="font-title-medium black font-light">{t('pages.auth.resetPasswordDesc')}</span>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="section-form-password">
                                            <Form onFinish={handleSubmit} name="basic" initialValues={{ remember: true }}>
                                                <Row>
                                                    <Col span={24}>
                                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.password')}</span>
                                                        <Form.Item
                                                            name="kataSandi" rules={[{ required: true, message: `${t('pages.auth.msgPassword')}` }]}
                                                        >
                                                            <InputPassword
                                                                name='kataSandi'
                                                                placeholder={t('pages.auth.phPassword')}
                                                                onChange={setEmail}
                                                                value={email}
                                                                className="input-auth mt-5"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <ReCAPTCHA
                                                            style={{ marginTop: "10px", marginBottom: "10px" }}
                                                            sitekey="6Ld9yt8UAAAAAAp9_NQFZwt18O5x0aDVYpmQtV-m"
                                                            onChange={(e) => setRecaptchaRef(e)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={24}>
                                                        <div className="section-form-button-password">
                                                            <Form.Item>
                                                                <ButtonLogin
                                                                    text={t('general.send')}
                                                                    height={10}
                                                                    backgroundColor="#008ad4"
                                                                    borderRadius="10px"
                                                                    className="font-semi-bold font-title-medium button-login"
                                                                    textColor="white"
                                                                    htmlType="submit"
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordPage)
export default page