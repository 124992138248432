export const ovkStock = {
    title: "Stok OVK",
    name: "Deskripsi",
    supplier: "Supplier",
    balance: "Saldo",
    add: "Tambah Stok OVK Baru",
    addStock: "Tambah Stok OVK",
    ovkWarehouse: "Gudang OVK",
    date: "Tanggal Pengadaan",
    addDate: "Tanggal Penambahan",
    qtyAdd: "Jumlah Penambahan",
    qtyStock: "Jumlah Stok",
    ovk: "OVK",
    price: "Harga Satuan OVK",
    phOvkWarehouse: "Pilih gudang OVK",
    phName: "Tambahkan deskripsi ...",
    addMutation: "Tambah Mutasi",
    mutation: 'Mutasi',
    dateMutation: 'Tanggal Mutasi',
    phMutationDate: "Pilih tanggal mutasi",
    msgMutationDate: "Mohon pilih tanggal mutasi!",
    mutationWh: 'Gudang OVK Tujuan',
    phMutationWh: "Pilih gudang OVK tujuan!",
    msgMutationWh: "Mohon pilih gudang OVK tujuan!",
    docNumber: 'Nomor Dokumen',
    phOvk: "Pilih OVK",
    phDate: "Pilih tanggal pengadaan",
    phAddDate: "Pilih tanggal penambahan",
    phPeriodDate: "Pilih tanggal periode",
    msgOvkWarehouse: "Mohon pilih gudang!",
    msgOvk: "Mohon pilih OVK!",
    msgName: "Mohon masukkan deskripsi!",
    msgDate: "Mohon pilih tanggal pengadaan!",
    msgAddDate: "Mohon pilih tanggal penambahan!",
    msgPeriodDate: "Mohon pilih tanggal periode!",
    msgPrice: "Mohon masukkan harga OVK!",
    msgQty: "Mohon masukkan jumlah pengadaan!",
    msgQtyStock: "Mohon masukkan jumlah stok!",
    edit: "Edit Stok OVK",
    resAddSuccess: "Berhasil menambahkan stok OVK",
    resAddMutationSuccess: "Berhasil menambahkan mutasi OVK",
    resEditSuccess: "Berhasil mengubah stok OVK",
    resEditTrxSuccess: "Berhasil mengubah transaksi OVK",
    resDelSuccess: "Berhasil menghapus stok OVK",
    resDelTrxSuccess: "Berhasil menghapus transaksi OVK",
    resAddFailed: "Gagal menambahkan stok OVK",
    resAddMutationFailed: "Gagal menambahkan mutasi OVK",
    resEditFailed: "Gagal mengubah stok OVK",
    resEditTrxFailed: "Gagal mengubah transaksi OVK",
    resDelFailed: "Gagal menghapus stok OVK",
    resDelTrxFailed: "Gagal menghapus transaksi OVK",
    delConfirm: "Apakah anda yakin ingin menghapus stok OVK",
    detail: "Detail Transaksi Stok OVK",
    addition: "Penambahan",
    trxDate: "Tanggal Transaksi",
    delTrxConfirm: "Apakah anda yakin ingin menghapus transaksi OVK?",
    titleEditAdd: "Edit Transaksi Penambahan Stok OVK",
    dateStock: "Tanggal Stok",
    usage: "Penggunaan",
    qty: "Jumlah",
    titleEditUse: "Edit Transaksi Penggunaan Stok OVK",
}