import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Layout, Breadcrumb, Row, Col, Tabs, Button } from 'antd'
import { useTranslation } from "react-i18next"
import { faArrowLeft, faPlusSquare } from '@fortawesome/free-solid-svg-icons'

import { API } from '../../../../../common/api'
import { addStokOvk, addMutationOvk, getDetailTransaksiStok } from '../stok-ovk-aksi-page/query-stok-ovk'
import { errMessage } from '../../../../../common/component/notification/notification'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'

import PenambahanOvk from './penambahan-stok-ovk-page'
import PenggunaanOvk from './penggunaan-stok-ovk-page'
import Add from '../stok-ovk-aksi-page/tambah-stok-ovk-page'
import AddMutation from '../stok-ovk-aksi-page/tambah-mutasi-ovk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TransaksiStokOvkPage(props) {
    const { gudangOvk, profil } = props
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [visibleMutation, setVisibleMutation] = useState(false)
    const [suffix, setSuffix] = useState('')
    const [activeKey, setActiveKey] = useState("1")
    const [penambahan, setPenambahan] = useState([])
    const [penggunaan, setPenggunaan] = useState([])
    const [ovk, setOvk] = useState('')
    const [idOvk, setIdOvk] = useState('')
    const { Content } = Layout
    const { TabPane } = Tabs
    const { t } = useTranslation()
    const idStock = props.match.params.ovkStockId
    const idGudangOvk = props.match.params.ovkWarehouseId

    const fetchDataPenambahan = async (idStock) => {
        setLoading(true)
        await API.get(getDetailTransaksiStok('', 0, 0, idStock, "In"))
            .then(res => {
                setPenambahan(res.data.data.ovkTransactions.ovkTransactions)
                setIdOvk(res.data.data.ovkTransactions.ovk._id)
                setOvk(res.data.data.ovkTransactions.ovk.name)
                setSuffix(res.data.data.ovkTransactions.ovk.ovkUnit.name)
                setLoading(false)
            }).catch((error) => {
                setPenambahan([])
                setLoading(false)
            })
    }

    const fetchDataPenggunaan = async (idStock) => {
        setLoading(true)
        await API.get(getDetailTransaksiStok('', 0, 0, idStock, "Out"))
            .then(res => {
                setPenggunaan(res.data.data.ovkTransactions.ovkTransactions)
                setLoading(false)
            }).catch((error) => {
                setPenggunaan([])
                setLoading(false)
            })
    }

    useEffect(() => {
        if (activeKey === "1") {
            fetchDataPenambahan(idStock)
        } else {
            fetchDataPenggunaan(idStock)
        }
    }, [activeKey, idStock])

    const changeKey = useCallback((value) => {
        setActiveKey(value)
    }, [])

    const postData = async (values, year) => {
        await API.post(addStokOvk(values, year, idStock))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.feedStock.resAddFailed')}`, res.data.errors[0].message)
                } else if (res.data.data.createOvkTransaction._id) {
                    errMessage('success', `${t("pages.ovkStock.resAddSuccess")}`)
                    fetchDataPenambahan(idStock)
                }
            }).catch((error) => {
                errMessage('error', `${t("pages.ovkStock.resAddFailed")}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, values['date_picker'].format('MM/DD/YYYY'))
        setVisible(false)
        setLoading(true)
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const postDataMutation = async (values, year) => {
        await API.post(addMutationOvk(values, year, idStock))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.feedStock.resAddMutationFailed')}`, res.data.errors[0].message)
                } else if (res.data.data.createOvkTransaction._id) {
                    errMessage('success', `${t('pages.feedStock.resAddMutationSuccess')}`)
                    fetchDataPenggunaan(idStock)
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.feedStock.resAddMutationFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmitMutation = (values) => {
        postDataMutation(values, values['date_picker'].format('MM/DD/YYYY'))
        setVisibleMutation(false)
        setLoading(true)
    }

    const handleCancelMutation = () => {
        setVisibleMutation(false)
    }

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col lg={12} sm={24}>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item><Link to={`/peternak/stok-ovk/${idGudangOvk}`}>
                                <Button shape="circle" style={{ border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 5%)' }}
                                    icon={<FontAwesomeIcon icon={faArrowLeft} />} size='default' className='mr-10' />
                            </Link></Breadcrumb.Item>
                            <Breadcrumb.Item><span>{t('pages.ovkStock.detail')} {ovk}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={12} sm={24}>
                        <ButtonDashboard
                            hidden={profil.permission.writeStock ? false : true}
                            text={t('pages.ovkStock.addStock')}
                            height={20}
                            backgroundColor="#008ad4"
                            borderRadius="5px"
                            className="font-semi-medium font-content-title button-add mb-10 ml-10"
                            textColor="white"
                            icon={faPlusSquare}
                            onClick={() => setVisible(true)}
                        />
                        <ButtonDashboard
                            hidden={profil.permission.writeStock ? false : true}
                            text={t('pages.ovkStock.addMutation')}
                            height={20}
                            backgroundColor="#00a266"
                            borderRadius="5px"
                            className="font-semi-medium font-content-title button-add"
                            textColor="white"
                            icon={faPlusSquare}
                            onClick={() => setVisibleMutation(true)}
                        />
                    </Col>
                    <Add visible={visible} handleCancel={handleCancel} handleSubmit={handleSubmit} suffix={suffix} t={t} />
                    <AddMutation visible={visibleMutation} handleCancel={handleCancelMutation} suffix={suffix} idGudangOvk={idGudangOvk}
                        handleSubmit={handleSubmitMutation} gudangOvk={gudangOvk} idOvk={idOvk} profil={profil} t={t} />
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                <TabPane tab={t('pages.ovkStock.addition')} key="1">
                                    <PenambahanOvk activeKey={activeKey} loading={loading} setLoading={setLoading} penambahan={penambahan}
                                        fetchDataPenambahan={fetchDataPenambahan} t={t} profil={profil} idStock={idStock} />
                                </TabPane>
                                <TabPane tab={t('pages.ovkStock.usage')} key="2">
                                    <PenggunaanOvk activeKey={activeKey} loading={loading} setLoading={setLoading} penggunaan={penggunaan}
                                        fetchDataPenggunaan={fetchDataPenggunaan} t={t} profil={profil} idStock={idStock} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    idStock: state.stokOvk.idStokOvk,
    gudangOvk: state.gudangOvk.dataGudangOvk,
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TransaksiStokOvkPage)
export default page