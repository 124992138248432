import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { Layout, Breadcrumb, Row, Col, Tabs, Button } from 'antd'
import { Link } from 'react-router-dom'
import PenambahanPakan from './penambahan-stok-pakan-page'
import PenggunaanPakan from './penggunaan-stok-pakan-page'
import Add from '../stok-pakan-aksi-page/tambah-stok-pakan-page'
import AddMutation from '../stok-pakan-aksi-page/tambah-mutasi-pakan'

import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import { API } from '../../../../../common/api'
import { errMessage, validationMessage } from '../../../../../common/component/notification/notification'

import { addStokPakan, addMutationPakan, getDetailTransaksiStok } from '../stok-pakan-aksi-page/query-stok-pakan'
import { faArrowLeft, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function TransaksiStokPakanPage(props) {
    const { gudangPakan, profil } = props
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [visibleMutation, setVisibleMutation] = useState(false)
    const [activeKey, setActiveKey] = useState("1")
    const [penambahan, setPenambahan] = useState([])
    const [penggunaan, setPenggunaan] = useState([])
    const [feed, setFeed] = useState('')
    const [idFeed, setIdFeed] = useState('')
    const { Content } = Layout
    const { TabPane } = Tabs
    const { t } = useTranslation()
    const idStock = props.match.params.feedStockId
    const idGudangPakan = props.match.params.feedWarehouseId

    const fetchDataPenambahan = async (idStock) => {
        setLoading(true)
        await API.get(getDetailTransaksiStok('', 0, 0, idStock, "In"))
            .then(res => {
                setPenambahan(res.data.data.feedings.feedings)
                setFeed(res.data.data.feedings.feed.name)
                setIdFeed(res.data.data.feedings.feed._id)
                setLoading(false)
            }).catch((error) => {
                setPenambahan([])
                setLoading(false)
            })
    }

    const fetchDataPenggunaan = async (idStock) => {
        setLoading(true)
        await API.get(getDetailTransaksiStok('', 0, 0, idStock, "Out"))
            .then(res => {
                setPenggunaan(res.data.data.feedings.feedings)
                setFeed(res.data.data.feedings.feed.name)
                setIdFeed(res.data.data.feedings.feed._id)
                setLoading(false)
            }).catch((error) => {
                setPenggunaan([])
                setLoading(false)
            })
    }

    useEffect(() => {
        if (activeKey === "1") {
            fetchDataPenambahan(idStock)
        } else {
            fetchDataPenggunaan(idStock)
        }
    }, [activeKey, idStock])

    const changeKey = useCallback((value) => {
        setActiveKey(value)
    }, [])

    const handleCancel = () => {
        setVisible(false)
    }

    const handleCancelMutation = () => {
        setVisibleMutation(false)
    }

    const postData = async (values, year) => {
        await API.post(addStokPakan(values, year, idStock))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.feedStock.resAddFailed')}`, res.data.errors[0].message)
                } else if (res.data.data.createFeeding._id) {
                    validationMessage('success', `${t('pages.feedStock.resAddSuccess')}`)
                    fetchDataPenambahan(idStock)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.feedStock.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, values['date_picker'].format('MM/DD/YYYY'))
        setVisible(false)
        setLoading(true)
    }

    const postDataMutation = async (values, year) => {
        await API.post(addMutationPakan(values, year, idStock))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.feedStock.resAddMutationFailed')}`, res.data.errors[0].message)
                } else if (res.data.data.createFeeding._id) {
                    validationMessage('success', `${t('pages.feedStock.resAddMutationSuccess')}`)
                    fetchDataPenggunaan(idStock)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.feedStock.resAddMutationFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmitMutation = (values) => {
        postDataMutation(values, values['date_picker'].format('MM/DD/YYYY'))
        setVisibleMutation(false)
        setLoading(true)
    }

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col lg={12} sm={24}>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item><Link to={`/peternak/stok-pakan/${props.match.params.feedWarehouseId}`}>
                                <Button shape="circle" style={{ border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 5%)' }}
                                    icon={<FontAwesomeIcon icon={faArrowLeft} />} size='default' className='mr-10' />
                            </Link></Breadcrumb.Item>
                            <Breadcrumb.Item><span>{t('pages.feedStock.detail')} {feed}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={12} sm={24}>
                        <ButtonDashboard
                            hidden={profil.permission.writeStock ? false : true}
                            text={t('pages.feedStock.addStock')}
                            height={20}
                            backgroundColor="#008ad4"
                            borderRadius="5px"
                            className="font-semi-medium font-content-title button-add mb-10 ml-10"
                            textColor="white"
                            icon={faPlusSquare}
                            onClick={() => setVisible(true)}
                        />
                        <ButtonDashboard
                            hidden={profil.permission.writeStock ? false : true}
                            text={t('pages.feedStock.addMutation')}
                            height={20}
                            backgroundColor="#00a266"
                            borderRadius="5px"
                            className="font-semi-medium font-content-title button-add"
                            textColor="white"
                            icon={faPlusSquare}
                            onClick={() => setVisibleMutation(true)}
                        />
                    </Col>
                </Row>
                <Add visible={visible} handleCancel={handleCancel} handleSubmit={handleSubmit} t={t} />
                <AddMutation visible={visibleMutation} handleCancel={handleCancelMutation} profil={profil}
                    handleSubmit={handleSubmitMutation} gudangPakan={gudangPakan} idGudangPakan={idGudangPakan} idFeed={idFeed} t={t} />
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                <TabPane tab={t('pages.feedStock.addition')} key="1">
                                    <PenambahanPakan activeKey={activeKey} loading={loading} setLoading={setLoading} idStock={idStock}
                                        penambahan={penambahan} fetchDataPenambahan={fetchDataPenambahan} t={t} profil={profil} />
                                </TabPane>
                                <TabPane tab={t('pages.feedStock.usage')} key="2">
                                    <PenggunaanPakan activeKey={activeKey} loading={loading} setLoading={setLoading} idStock={idStock}
                                        penggunaan={penggunaan} fetchDataPenggunaan={fetchDataPenggunaan} t={t} profil={profil} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    gudangPakan: state.gudangPakan.dataGudangPakan,
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TransaksiStokPakanPage)
export default page