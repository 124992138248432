export const subscription = {
    title: 'My Subscription',
    table: 'Transaction',
    pay: 'Checkout',
    print: 'Print Invoice',
    date: 'Transaction Date',
    totalTrx: 'Transaction Total',
    number: 'Invoice Number',
    qty: 'Quantity',
    price: 'Price',
    customer: 'Customer',
    status: 'Status',
    house: 'house',
    desc: 'Description',
    updated: 'Last updated',
    valid: 'This invoice is valid and processed by computer',
    payment: 'Payment',
    lastPayDesc: 'Your last payment amount {{total}} on {{date}}.',
    nextPayDesc: 'Your next payment is on {{date}}.',
    seeInv: 'See Invoice',
    noPayment: 'You have no payment history.',
    subsDesc: '{{house}} houses until {{date}}',
    resUpgradeSuccess: 'Successfully upgrade Premium house',
    resUpgradeFailed: 'Failed to mupgrade Premium house',
    addHouse: 'Add House',
    addHouseTitle: 'Add Premium House',
    houseTotal: 'House Total',
    msgItem: 'Please input house total',
    startDate: 'Start Date',
    msgStartDate: 'Please input start date',
    unpaid: 'Waiting to purchase',
    validating: 'Validating',
    expired: 'Expired',
    paid: 'Paid',
    confirmPayment: 'Payment Confirmation',
    waitingPayment: 'We are still waiting your payment',
    successPayment: 'Thank you, your payment was confirmed',
    finishPayment: 'Please finish your payment before {{date}}.',
    expiredPayment: 'Your invoice has expired.',
    amount: 'amount',
    pricingTitle: 'Pick your Best Premium',
    pricingDesc: ' Manage your house more freely with additional features.',
    free: 'Free',
    freeDesc: 'Free Forever',
    free1: 'Record daily Recording.',
    free2: 'Open Cultivation 1 house.',
    free3: 'Monitor Stock and feed and OVK usage.',
    free4: 'Support IoT Connect.',
    free5: 'Information about Livebird Price and Carcass.',
    free6: 'Monitor ADG ,Depletion, FCR, IP, and HPP.',
    free7: 'Report Cultivation Result.',
    free8: 'One Admin Account.',
    k: 'thousands',
    perHouse: 'per house',
    monthly: 'Monthly',
    monthly1: 'All features in free account.',
    monthly2: 'Custom the number of cages and warehouses as needed*',
    monthly3: 'Stockflow Management.',
    monthly4: 'Eksport Excel recording.',
    monthly5: 'Analyze comparison of production performance with other cages and the previous period.',
    monthly6: 'Unlimited admin account.',
    maxHouse: '* maximal 5 houses',
    annual: 'Annual',
    annualMember: 'Annual with Membership',
    annual1: 'All features in free account.',
    annual2: 'Custom the number of cages and warehouses as needed*',
    annual3: 'Stockflow Management.',
    annual4: 'Eksport Excel recording.',
    annual5: 'Analyze comparison of production performance with other cages and the previous period.',
    annual6: 'Unlimited admin account.',
    annual7: 'Discounts for members of Peternak Rakyat* by showing a Pinsar, Himpuli, or Gopan membership card amount:',
    annual8: 'Discounts for members of Peternak Rakyat* by showing a Pinsar, Himpuli, or Gopan membership card.',
    platinum: 'Customize your App',
    contact: 'Contact Us',
    specialPrice: 'Special Price',
    platinum1: 'All features in free account.',
    platinum2: 'All features in Premium Account.',
    platinum3: 'Special Subdomain.',
    platinum4: 'Support Extra Protection Cloudflare.',
    platinum5: 'Special price offer for app.',
    platinum6: 'Special price offer for IoT device.',
    platinum7: 'Can use own server.',
    getStarted: 'Get Started',
    resAddInvSuccess: 'Successfully created payment invoice',
    resAddInvFailed: 'Failed to create payment invoice',
    subsTitle: 'Premium Subscription',
    duration: 'Subscription Duration',
    msgDuration: 'Please input subscription duration',
    month: 'month',
    year: 'year',
    isMember: 'Are you a member of the Peternak Rakyat?',
    membership: 'Membership Type',
    msgMembership: 'Please select your membership',
    upload: 'Upload Member Card Photo',
    msgUpload: 'Please upload your card member photo',
    pickPlan: 'Choose your Plan',
    changePlan: 'Change Plan'
}