export const getStrain = () => {
  return (`
    query{
        strains(keyword:"",limit:0,skip:0){
          totalCount,
          strains{
            _id
            name
            type
          }
        }
      }
    `)
}
export const createStrain = (value) => {
  return {
    query: `mutation{
          createStrain(strainInput: {
              name : "${value.name}"
            }){
              _id
              name
            }
          }`
  }
}

export const deleteStrain = (_id) => {
  return (
    `mutation{
          deleteStrain(_id : "${_id}"){
            deleted
          }
        }
    `
  );
}

export const detailStrain = (_id) => {
  return (
    `query{
          strain(_id : "${_id}"){
                  _id
                  name
              }
          }
      `
  );
}

export const updateStrain = (_id, value) => {
  return {
    query: `mutation{
          updateStrain(_id : "${_id}", updateStrainInput : {
              name : "${value.name}"
          }){
            _id
            name
          }
        }
      `
  }
}