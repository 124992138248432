import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Input, Select } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { API } from '../../../../common/api'
import { errMessage } from '../../../../common/component/notification/notification'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'
import { createStandardEnv } from './query-iot'

function CreateStandardEnv(props) {
    const { fetchData, setLoading, kandang, role, t } = props
    const [visible, setVisible] = useState(false)

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values) => {
        API.post(createStandardEnv(values, role))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.monitor.resAddStdFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createStandardEnvironment._id) {
                    errMessage('success', `${t('pages.monitor.resAddStdSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.monitor.resAddStdFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.monitor.addStandardEnv')}
                okText={t('general.add')}
                width={500}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.monitor.envName')}</span>
                            <Form.Item
                                name="name" rules={[{ required: true, message: `${t('pages.monitor.msgEnvName')}` }]}
                            >
                                <Input
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} hidden={props.role === 'superadmin' ? true : false}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.monitor.house')}</span>
                            <Form.Item
                                name="kandang" rules={[{ required: role === 'superadmin' ? false : true, message: `${t('pages.monitor.msgHouse')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t("pages.monitor.phHouse")}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        kandang.map(data =>
                                            <Select.Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <ButtonDashboard
                text={t('pages.monitor.addStandardEnv')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStandardEnv)
export default page