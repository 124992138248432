import React, { useCallback, useState, useEffect } from 'react'
import { connect, } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import { API } from '../../../../common/api'
import { useTranslation } from "react-i18next"
import { Layout, Breadcrumb, Row, Col, Tabs, Button, Tooltip } from 'antd'
import { faArrowLeft, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useParams, Link } from 'react-router-dom'

import { getListPakan } from '../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/store/stok-pakan-action'
import { getStokGudangPakan } from '../../../../app/dashboard-peternak/stok-page/stok-pakan-page/stok-pakan-aksi-page/query-stok-pakan'
import { getListGrowingParams } from '../../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action'
import { getListDisease } from '../../master-page/penyakit-page/store/penyakit-action'

import CreateDeplesi from '../create-detail-rearing-page/create-deplesi-page'
import CreatePanen from '../create-detail-rearing-page/create-panen-page'
import CreateOvk from '../create-detail-rearing-page/create-ovk-page'
import CreatePakan from '../create-detail-rearing-page/create-pakan-page'
import CreatePertumbuhan from '../create-detail-rearing-page/create-pertumbuhan-page'
import CreateOverhead from '../create-detail-rearing-page/create-overhead-page'

import DetailDeplesi from '../detail-rearing-record-page/detail-deplesi-page'
import DetailPanen from '../detail-rearing-record-page/detail-panen-page'
import DetailOvk from '../detail-rearing-record-page/detail-ovk-page'
import DetailPakan from '../detail-rearing-record-page/detail-pakan-page'
import DetailPertumbuhan from '../detail-rearing-record-page/detail-pertumbuhan-page'
import DetailPenyakit from '../detail-rearing-record-page/detail-penyakit-page'
import DetailOverhead from '../detail-rearing-record-page/detail-overhead-page'

import {
    getRecordDeplesi, getRecordOvk, getRecordPakan, getRecordPanen, getRecordPertumbuhan,
    getRecordPenyakit, getRecordOverhead, getGrowingParams
} from '../rearing-record-aksi-page/query-rearing-record'
import { getOverhead } from '../../master-page/overhead-page/overhead-aksi-page/query-overhead'
import { getPenyakit } from '../../../../app/dashboard-peternak/master-page/penyakit-page/penyakit-aksi-page/query-penyakit'
import { isMobileOnly } from 'react-device-detect'

function DetailRearingRecordPage(props) {
    const { getListPakan, getListGrowingParams, periode, kandang,
        getListDisease, idGudangPakan, namaGudangPakan, age, profil } = props
    const [loading, setLoading] = useState(false)
    const [deplesi, setDeplesi] = useState([])
    const [ovk, setOvk] = useState([])
    const [pakan, setPakan] = useState([])
    const [panen, setPanen] = useState([])
    const [pertumbuhan, setPertumbuhan] = useState([])
    const [penyakit, setPenyakit] = useState([])
    const [overhead, setOverhead] = useState([])
    const [overheading, setOverheading] = useState([])
    const [houseActive, setActive] = useState(true)
    const { Content } = Layout
    const { TabPane } = Tabs
    let searchParams = new URLSearchParams(window.location.search);

    const { t } = useTranslation()
    const history = useHistory()
    const params = useParams()
    const idRearingRecord = params.recordingId
    const [activeKey, setActiveKey] = useState(searchParams.get("section") || 'depletion')

    const changeKey = useCallback((value) => {
        setActiveKey(value)
        searchParams.set('section', value)
        history.push({
            pathname: window.location.pathname,
            search: searchParams.toString()
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (idGudangPakan) {
            getListPakan(getStokGudangPakan(idGudangPakan))
        }
    }, [getListPakan, idGudangPakan])

    useEffect(() => {
        getListGrowingParams(getGrowingParams("", 0, 0))
    }, [getListGrowingParams])

    const fetchDataDeplesi = async (idRearingRecord) => {
        setLoading(true)
        await API.get(getRecordDeplesi('', 0, 0, idRearingRecord))
            .then(res => {
                setDeplesi(res.data.data.mutations.mutations)
                setActive(res.data.data.mutations.houseActive)
                setLoading(false)
            }).catch((error) => {
                setDeplesi([])
                setLoading(false)
            })
    }

    const fetchDataOvk = async (idRearingRecord) => {
        setLoading(true)
        await API.get(getRecordOvk('', 0, 0, idRearingRecord))
            .then(res => {
                setOvk(res.data.data.ovkTransactionsRecord.ovkTransactions)
                setActive(res.data.data.ovkTransactionsRecord.houseActive)
                setLoading(false)
            }).catch((error) => {
                setOvk([])
                setLoading(false)
            })
    }

    const fetchDataPakan = async (idRearingRecord) => {
        setLoading(true)
        await API.get(getRecordPakan('', 0, 0, idRearingRecord))
            .then(res => {
                setPakan(res.data.data.feedingRecords.feedings)
                setActive(res.data.data.feedingRecords.houseActive)
                setLoading(false)
            }).catch((error) => {
                setPakan([])
                setLoading(false)
            })
    }

    const fetchDataPanen = async (idRearingRecord) => {
        setLoading(true)
        await API.get(getRecordPanen('', 0, 0, idRearingRecord))
            .then(res => {
                setPanen(res.data.data.harvests.mutations)
                setActive(res.data.data.harvests.houseActive)
                setLoading(false)
            }).catch((error) => {
                setPanen([])
                setLoading(false)
            })
    }

    const fetchDataPertumbuhan = async (idRearingRecord) => {
        setLoading(true)
        await API.get(getRecordPertumbuhan('', 0, 0, idRearingRecord))
            .then(res => {
                setPertumbuhan(res.data.data.growings.growings)
                setActive(res.data.data.growings.houseActive)
                setLoading(false)
            }).catch((error) => {
                setPertumbuhan([])
                setLoading(false)
            })
    }

    const fetchDataPenyakit = (idRearingRecord) => {
        setLoading(true)
        API.get(getRecordPenyakit('', 0, 0, idRearingRecord))
            .then(res => {
                setPenyakit(res.data.data.diseaseRecords.diseaseRecords)
                setActive(res.data.data.diseaseRecords.houseActive)
                setLoading(false)
            }).catch((error) => {
                setPenyakit([])
                setLoading(false)
            })
    }
    const fetchOverhead = () => {
        setLoading(true)
        API.get(getOverhead('', 0, 0))
            .then(res => {
                setOverhead(res.data.data.overheads.overheads)
                setLoading(false)
            }).catch((error) => {
                setOverhead([])
                setLoading(false)
            })
    }
    const fetchDataOverhead = (idRearingRecord) => {
        setLoading(true)
        API.get(getRecordOverhead('', 0, 0, idRearingRecord))
            .then(res => {
                setOverheading(res.data.data.overheadings.overheadings)
                setActive(res.data.data.overheadings.houseActive)
                setLoading(false)
            }).catch((error) => {
                setOverheading([])
                setLoading(false)
            })
    }

    useEffect(() => {
        if (idRearingRecord) {
            if (activeKey === "depletion") {
                fetchDataDeplesi(idRearingRecord)
            } else if (activeKey === "ovk") {
                fetchDataOvk(idRearingRecord)
            } else if (activeKey === "feed") {
                fetchDataPakan(idRearingRecord)
            } else if (activeKey === "harvest") {
                fetchDataPanen(idRearingRecord)
            } else if (activeKey === "growing") {
                fetchDataPertumbuhan(idRearingRecord)
            } else if (activeKey === "disease") {
                fetchDataPenyakit(idRearingRecord)
                getListDisease(getPenyakit("", 0, 0))
            } else if (activeKey === "overhead") {
                fetchOverhead()
                fetchDataOverhead(idRearingRecord)
            }
        }
    }, [idRearingRecord, activeKey, getListDisease])

    const showCreate = (key) => {
        if (key === "depletion") {
            return <CreateDeplesi loading={loading} profil={profil} setLoading={setLoading}
                fetchDataDeplesi={fetchDataDeplesi} houseActive={houseActive} t={t} />
        } else if (key === "harvest") {
            return <CreatePanen loading={loading} profil={profil} houseActive={houseActive} setLoading={setLoading}
                fetchDataPanen={fetchDataPanen} t={t} />
        } else if (key === "growing") {
            return <CreatePertumbuhan loading={loading} profil={profil} houseActive={houseActive} setLoading={setLoading}
                fetchDataPertumbuhan={fetchDataPertumbuhan} t={t} />
        } else if (key === "feed") {
            return <CreatePakan loading={loading} profil={profil} houseActive={houseActive} setLoading={setLoading}
                fetchDataPakan={fetchDataPakan} t={t} namaGudangPakan={namaGudangPakan} idGudangPakan={idGudangPakan} />
        } else if (key === "ovk") {
            return <CreateOvk loading={loading} profil={profil} houseActive={houseActive} setLoading={setLoading}
                fetchDataOvk={fetchDataOvk} t={t} />
        } else if (key === "disease") {
            return <Tooltip title={houseActive ? '' : t("error.inactiveHouse")}>
                <Button
                    disabled={houseActive ? false : true}
                    style={{
                        color: 'white',
                        backgroundColor: houseActive ? '#008ad4' : 'white smoke',
                        borderRadius: '5px',
                        float: "right",
                    }} onClick={() => history.push(`/peternak/recording/${params.rearingId}/${idRearingRecord}/tambah-penyakit`)}
                ><FontAwesomeIcon icon={faPlusSquare} className='mr-10' />{t("pages.recording.disease.add")}</Button>
            </Tooltip>
        } else if (key === "overhead") {
            return <CreateOverhead loading={loading} houseActive={houseActive} setLoading={setLoading}
                overhead={overhead} fetchDataOverhead={fetchDataOverhead} t={t} />
        }
    }

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col lg={19} md={24} sm={24}>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item><Link to={`/peternak/recording/${params.rearingId}`}>
                                <Button shape="circle" style={{ border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 5%)' }}
                                    icon={<FontAwesomeIcon icon={faArrowLeft} />} size='default' className='mr-10' />
                            </Link></Breadcrumb.Item>
                            <Breadcrumb.Item><span>{t('pages.recording.detail')}{age} {kandang} {periode}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col hidden={!isMobileOnly} span={24} className='mb-10'>
                        {profil.permission.writeRecording ? showCreate(activeKey) : <></>}
                    </Col>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey={activeKey} onTabClick={(key) => changeKey(key)}
                                tabBarExtraContent={!isMobileOnly && profil.permission.writeRecording ? showCreate(activeKey) : <></>}
                            >
                                <TabPane tab={t('pages.recording.depletion.title')} key="depletion">
                                    <DetailDeplesi activeKey={activeKey} profil={profil} loading={loading} setLoading={setLoading}
                                        deplesi={deplesi} fetchDataDeplesi={fetchDataDeplesi} houseActive={houseActive} t={t} />
                                </TabPane>
                                <TabPane tab={t('pages.recording.harvest.title')} key="harvest">
                                    <DetailPanen activeKey={activeKey} profil={profil} loading={loading} setLoading={setLoading}
                                        panen={panen} fetchDataPanen={fetchDataPanen} houseActive={houseActive} t={t} />
                                </TabPane>
                                <TabPane tab={t('pages.recording.bw.growing')} key="growing">
                                    <DetailPertumbuhan activeKey={activeKey} profil={profil} loading={loading} setLoading={setLoading}
                                        pertumbuhan={pertumbuhan} fetchDataPertumbuhan={fetchDataPertumbuhan} houseActive={houseActive} t={t} />
                                </TabPane>
                                <TabPane tab={t('pages.recording.feed.title')} key="feed">
                                    <DetailPakan activeKey={activeKey} profil={profil} loading={loading} setLoading={setLoading}
                                        pakan={pakan} fetchDataPakan={fetchDataPakan} houseActive={houseActive} t={t} />
                                </TabPane>
                                <TabPane tab={t('pages.recording.ovk.title')} key="ovk">
                                    <DetailOvk activeKey={activeKey} profil={profil} loading={loading} setLoading={setLoading}
                                        ovk={ovk} fetchDataOvk={fetchDataOvk} houseActive={houseActive} t={t} />
                                </TabPane>
                                <TabPane tab={t("pages.recording.overhead.title")} key="overhead">
                                    <DetailOverhead activeKey={activeKey} profil={profil} loading={loading} setLoading={setLoading} houseActive={houseActive}
                                        overhead={overhead} overheading={overheading} fetchDataOverhead={fetchDataOverhead} t={t} />
                                </TabPane>
                                <TabPane tab={t("pages.recording.disease.title")} key="disease">
                                    <DetailPenyakit activeKey={activeKey} profil={profil} loading={loading} setLoading={setLoading}
                                        penyakit={penyakit} fetchDataPenyakit={fetchDataPenyakit} houseActive={houseActive} t={t} />
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord,
    kandang: state.layout.kandang,
    periode: state.layout.periode,
    age: state.rearingRecord.age,
    idGudangPakan: state.gudangPakan.idGudangPakan,
    namaGudangPakan: state.gudangPakan.namaGudangPakan,
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListPakan,
    getListGrowingParams,
    getListDisease
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailRearingRecordPage)
export default page