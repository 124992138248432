import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Button, Input, Tooltip, Modal } from 'antd'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import axios from 'axios'
import Cookies from 'universal-cookie'

import DetailPanenComponent from '../../../../modules/dashboard-peternak/rearing-record-component/detail-rearing-record-component/detail-panen-component'
import { deleteRecordDeplesi, getDetailRecordPanen, lockingPanen, updateRecordPanen } from '../rearing-record-aksi-page/query-rearing-record'
import { API } from '../../../../common/api'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faEdit, faInfoCircle, faLock, faLockOpen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getIdHarvest } from '../../../../modules/dashboard-peternak/layout-component/store/layout-action'
import CONSTANTS from '../../../../common/utils/Constants'
import { navigate } from '../../../../common/store/action'
import { blobToDataURL } from '../../../../common/utils/Download';

function DetailPanenPage(props) {
    const { panen, fetchDataPanen, setLoading, loading, idRearingRecord, houseActive, profil, getIdHarvest, t } = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [visible, setVisible] = useState(false)
    const [idData, setIdData] = useState(null)
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [detail, setDetail] = useState("")
    const [loadingExport, setLoadingExport] = useState(false)
    const cookies = new Cookies()

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deleteRecordDeplesi(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.recording.harvest.resDelFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteMutation.deleted === true) {
                    validationMessage('success', `${t('pages.recording.harvest.resDelSuccess')}`)
                    fetchDataPanen(idRearingRecord)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.recording.harvest.resDelFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t('pages.recording.harvest.delConfirm')}`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(getDetailRecordPanen(idData))
                    .then(res => {
                        setDetail(res.data.data.mutation)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showEdit = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = async (values) => {
        await API.post(updateRecordPanen(idData, values))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    validationMessage('success', `${t('pages.recording.harvest.resEditSuccess')}`)
                    fetchDataPanen(idRearingRecord)
                } else if (res.data.errors) {
                    validationMessage('error', `${t('pages.recording.harvest.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.recording.harvest.resEditFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const lockingData = async (id, value) => {
        await API.post(lockingPanen(id, value))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `Gagal mengunci panen`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.lockingHarvest._id) {
                    validationMessage('success', res.data.data.lockingHarvest.isLocked ? `Berhasil mengunci panen` : `Berhasil membuka kunci panen`)
                    fetchDataPanen(idRearingRecord)
                }
            }).catch((error) => {
                validationMessage('error', `Gagal mengunci panen`, error.message)
                setLoading(false)
            })
    }

    const lockingConfirm = (id, value) => {
        confirm({
            title: value ? `Apakah Anda yakin mengunci panen ini?` : `Apakah Anda yakin akan membuka kunci panen ini?`,
            onOk: () => {
                lockingData(id, value)
            },
            maskClosable: true,
            cancelText: t('general.cancel'),
        })
    }

    const exportHarvest = async (idHarvest, periode, house) => {
        setLoadingExport(true)
        let token = cookies.get('token')
        await axios
            .get(`${process.env.REACT_APP_API_URL}/pdf-timbangan/${idHarvest}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                responseType: 'blob'
            })
            .then(res => {
                if (typeof (res.data === 'blob')) {
                    setLoadingExport(false)
                    const fileName = `Laporan Timbangan Ayam ${periode} ${house}.pdf`
                    blobToDataURL(res.data, fileName)
                    errMessage('success', `Berhasil export Laporan Panen ke file PDF`)
                }
            }).catch((error) => {
                console.log('err', error)
                errMessage('error', `Gagal export Laporan Panen ke file PDF`)
                setLoadingExport(false)
            })
    }

    const handleExport = (idHarvest, periode, house) => {
        exportHarvest(idHarvest, periode, house)
    }

    const columns = [
        {
            title: `Plat Nomor`,
            dataIndex: 'plat',
            key: 'plat',
            ...getColumnSearchProps('plat'),
        },
        {
            title: `Tonase DO`,
            dataIndex: 'tonase',
            key: 'tonase',
            align: 'right',
            ...getColumnSearchProps('tonase'),
        },
        {
            title: `${t('pages.recording.harvest.qty')}`,
            dataIndex: 'ayam',
            key: 'ayam',
            align: 'right',
            ...getColumnSearchProps('ayam'),
        },
        {
            title: `${t('pages.recording.harvest.weight')}`,
            dataIndex: 'berat',
            key: 'berat',
            align: 'right',
            ...getColumnSearchProps('berat'),
        },
        {
            title: `${t('pages.recording.harvest.avg')}`,
            dataIndex: 'rata',
            key: 'rata',
            align: 'right',
            ...getColumnSearchProps('rata'),
        },
        {
            title: `${t('pages.recording.harvest.customer')}`,
            dataIndex: 'customer',
            key: 'customer',
            ...getColumnSearchProps('customer'),
        },
        {
            title: `${t('pages.recording.harvest.desc')}`,
            dataIndex: 'desc',
            key: 'desc',
            ...getColumnSearchProps('desc'),
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 100,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('general.detail')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }} onClick={() => {
                            getIdHarvest(row.key)
                            props.navigate(CONSTANTS.DETAIL_RECORD_HARVEST)
                        }}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button
                            hidden={profil.permission.editRecording ? false : true}
                            disabled={houseActive ? false : true} style={{
                                color: 'white',
                                backgroundColor: houseActive ? '#FFA903' : 'whitesmoke',
                                borderRadius: '5px'
                            }} onClick={() => showEdit(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={!row.locked ?
                        `${t('general.lock')}` :
                        `${t('general.unlock')}`}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: !row.locked ? '#ff4a3d' : '#03a5fc',
                            borderRadius: '5px',
                        }} onClick={() => lockingConfirm(row.key, !row.locked ? true : false)} >
                            <FontAwesomeIcon icon={!row.locked ? faLock : faLockOpen} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title='Export Panen'>
                        <Button style={{
                            color: 'white',
                            backgroundColor: 'coral',
                            borderRadius: '5px'
                        }}
                            loading={loadingExport}
                            onClick={() => handleExport(row.key, row.periode, row.kandang)}
                        ><FontAwesomeIcon icon={faDownload} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button
                            hidden={profil.permission.writeRecording ? false : true}
                            disabled={houseActive ? false : true} style={{
                                color: 'white',
                                backgroundColor: houseActive ? '#FF0303' : 'whitesmoke',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]
    const data = panen.map(({ _id, number, totalWeight, averageWeight, customer, otherInformation, plat, tonase, isLocked, rearingRecord }, index) => ({
        key: _id,
        ayam: <NumberFormat value={number} displayType={'text'} thousandSeparator={true} />,
        berat: <NumberFormat value={totalWeight} displayType={'text'} thousandSeparator={true} />,
        rata: <NumberFormat value={averageWeight?.toFixed(2)} displayType={'text'} thousandSeparator={true} />,
        customer: customer?.name,
        plat: plat,
        tonase: <NumberFormat value={tonase} displayType={'text'} thousandSeparator={true} suffix=' kg' />,
        desc: otherInformation,
        locked: isLocked,
        kandang: rearingRecord?.rearing?.house?.name,
        periode: rearingRecord?.rearing?.name,
    }))

    return (
        <DetailPanenComponent
            navigate={props.navigate}
            loading={loading}
            columns={columns}
            data={data}
            setLoading={setLoading}
            showEdit={showEdit}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            visible={visible}
            detail={detail}
            t={t}
            lockingData={lockingData}
        />
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord
})

const mapDispatchToProps = (dispatch => ({
    navigate, getIdHarvest
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailPanenPage)
export default page