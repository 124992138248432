import { API } from '../../../../common/api'
import { errMessage } from '../../../../common/component/notification/notification'
import * as actionType from './profil-action-type'
import i18next from 'i18next'

const startGetDataProfils = () => ({
    type: actionType.START_GET_DATA
})

const finishGetDataProfils = () => ({
    type: actionType.FINISH_GET_DATA
})

const setDataProfil = payload => ({
    type: actionType.SET_DATA_PROFIL,
    payload
})

export const getListProfil = (params) => (dispatch) => {
    dispatch(startGetDataProfils())
    API.get(params)
        .then(res => {
            if (res === undefined) { errMessage('error', `${i18next.t('error.connection')}`) }
            else if (res.data.data.getProfile) {
                dispatch(setDataProfil(res.data.data.getProfile))
            }  else if (res.data.data.updateNotifReceive) {
                dispatch(setDataProfil(res.data.data.updateNotifReceive))
            } else {
                errMessage('error', 'Data Error')
            }
            dispatch(finishGetDataProfils())
        }).catch(e => {
            errMessage('error', e.message)
        })
}

export const updateProfil = (params) => (dispatch) => {
    dispatch(startGetDataProfils())
    API.post(params)
        .then(res => {
            if (res === undefined) { errMessage('error', `${i18next.t('error.connection')}`) }
            else if (res.data.errors) {
                errMessage('error', res.data.errors[0].message)
            } else if (res.data.data.updateUser._id) {
                errMessage('success', `${i18next.t('pages.profile.resEditProfileSuccess')}`)
                dispatch(setDataProfil(res.data.data.updateUser))
            }
            dispatch(finishGetDataProfils())
        }).catch(error => {
            errMessage('error', error.message)
        })
}


export const updatePassword = (params, setPassChange) => (dispatch) => {
    dispatch(startGetDataProfils())
    API.post(params)
        .then(res => {
            if (res === undefined) { errMessage('error', `${i18next.t('error.connection')}`) }
            else if (res.data.errors) {
                errMessage('error', res.data.errors[0].message)
            } else if (res.data.data.changePassword) {
                errMessage('success', `${i18next.t('pages.profile.resEditPassSuccess')}`)
                setPassChange(false)
            }
            dispatch(finishGetDataProfils())
        }).catch(error => {
            errMessage('error', error.message)
        })
}