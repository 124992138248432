import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input } from 'antd'
import NumberFormat from 'react-number-format'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { API } from '../../../../common/api'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import OvkComponent from '../../../../modules/dashboard-peternak/adjustment-stock-component/ovk-component/ovk-component'
import { getListOvk } from '../../../../modules/dashboard-peternak/master-component/ovk-component/store/ovk-action'
import { getOvk } from '../../../../app/dashboard-peternak/master-page/ovk-page/ovk-aksi-page/query-ovk'
import { getAdjustmentOvk, detailAdjustmentOvk, deleteAdjustmentOvk, updateAdjustmentOvk } from './okv-aksi-page/query-adjustment-ovk-page'
import { getMonthlyStockOvks } from '../../stock-flow-page/ovk-page/ovk-aksi-page/query-stock-flow-ovk'
import { useTranslation } from 'react-i18next'
import { isMobileOnly } from 'react-device-detect'

function OvkPage(props) {
    const { confirm } = Modal
    const { getListOvk, idMonthlyStockOvk, monthPeriode, yearPeriode, idGudangOvk, profil } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [pagination, setPagination] = useState(10)
    const [loading, setLoading] = useState(false)
    const [idData, setIdData] = useState(null)
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState('')
    const [object, setObject] = useState([])
    const [satuan, setSatuan] = useState('')
    const [ovk, setOvk] = useState([])
    const [locked, setLocked] = useState(false)

    const { t } = useTranslation()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t("general.search")} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    /**Handle get data */
    const fetchData = (idMonthlyStockOvk, idGudangOvk) => {
        setLoading(true);
        API.get(getAdjustmentOvk('', 0, 0, idMonthlyStockOvk, idGudangOvk))
            .then(res => {
                setObject(res.data.data.adjustmentOvks.adjustmentOvks)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        getListOvk(getOvk('', 0, 0))
    }, [getListOvk])

    useEffect(() => {
        fetchData(idMonthlyStockOvk, idGudangOvk)
    }, [idMonthlyStockOvk, idGudangOvk])

    const fetchDataOvk = (month, year, idGudangOvk) => {
        setLoading(true)
        API.get(getMonthlyStockOvks('', 0, 0, month, year, idGudangOvk))
            .then(res => {
                setOvk(res.data.data.monthlyStockOvks.calculateMonthlyStocks)
                setLocked(res.data.data.monthlyStockOvks.locked)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setOvk([])
                setLoading(false)
            })
    }
    useEffect(() => {
        fetchDataOvk(monthPeriode, yearPeriode, idGudangOvk)
        // eslint-disable-next-line
    }, [monthPeriode, yearPeriode, idGudangOvk])

    /**Handle updated data */
    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(detailAdjustmentOvk(idData))
                    .then(res => {
                        setDetail(res.data.data.adjustmentOvk)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(false)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])
    const showModal = useCallback((value, satuan) => {
        if (value === idData) {
            setVisible(true)
            setSatuan(satuan)
        }
        else {
            setIdData(value)
            setSatuan(satuan)
        }
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = (values) => {
        API.post(updateAdjustmentOvk(idData, values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.stockflow.resEditAdjOvkFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.updateAdjustmentOvk._id) {
                    validationMessage('success', `${t('pages.stockflow.resEditAdjOvkSuccess')}`)
                    fetchData(idMonthlyStockOvk, idGudangOvk)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.stockflow.resEditAdjOvkFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setLoading(true)
    }

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteAdjustmentOvk(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.stockflow.resDelAdjOvkFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.deleteAdjustmentOvk.deleted === true) {
                    validationMessage('success', `${t('pages.stockflow.resDelAdjOvkSuccess')}`)
                    fetchData(idMonthlyStockOvk, idGudangOvk)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.stockflow.resDelAdjOvkFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t('pages.stockflow.delAdjOvkConfirm')}`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const columns = [
        {
            title: 'OVK',
            dataIndex: 'ovk',
            key: 'ovk',
            fixed: 'left',
            width: 160,
            ...getColumnSearchProps('ovk', 'ovk'),
        },
        {
            title: `${t('pages.stockflow.quantity')}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            fixed: 'left',
            width: 160,
            ...getColumnSearchProps('jumlah', `${t('pages.stockflow.quantity')}`),
        },
        profil.permission.writeStockflow ? {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 110,
            align: 'center',
            fixed: !isMobileOnly && 'right',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: locked ? 'white smoke' : '#FFA903',
                            borderRadius: '5px'
                        }} onClick={() => showModal(row.key, row.satuan)}
                            disabled={locked ? true : false}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: locked ? 'white smoke' : '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key)}
                            disabled={locked ? true : false}
                        ><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        } : { width: 1 }
    ]

    const data = object.map(({ _id, ovk, qty }, index) => ({
        key: _id,
        id: _id,
        ovk: ovk?.name,
        satuan: ovk?.ovkUnit?.name,
        jumlah: <NumberFormat value={qty} displayType={'text'} thousandSeparator={true} suffix={' ' + ovk?.ovkUnit?.name} />,
    }))

    return (
        <OvkComponent
            navigate={props.navigate}
            setLoading={setLoading}
            pagination={pagination}
            setPagination={setPagination}
            data={data}
            object={ovk}
            locked={locked}
            columns={columns}
            loading={loading}
            visible={visible}
            detail={detail}
            month={monthPeriode}
            year={yearPeriode}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            idData={idData} idGudangOvk={idGudangOvk}
            fetchData={fetchData}
            satuan={satuan}
            t={t} profil={profil}
        />
    )
}

const mapStateToProps = state => ({
    idMonthlyStockOvk: state.adjustmentOvk.idMonthlyStockOvk,
    monthPeriode: state.rearingRecord.monthPeriode,
    yearPeriode: state.rearingRecord.yearPeriode,
    idGudangOvk: state.gudangOvk.idGudangOvk,
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListOvk,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(OvkPage)
export default page