import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input, Layout, Breadcrumb, Row, Col, Table, Tooltip, Modal, Form } from 'antd'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { getStandar, deleteStandar, detailStandar, updateStandar } from './standar-aksi-page/query-standar-page'
import CreateStandar from './standar-aksi-page/create-standar-page'
import { API } from '../../../../common/api'
import { useTranslation } from 'react-i18next'
import Loading from '../../../../common/component/loading/loading-container'
import { Link } from 'react-router-dom'
import { faTrash, faPlusSquare, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { validationMessage } from '../../../../common/component/notification/notification'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'
import EditStandar from './standar-aksi-page/edit-standar-page'
import { isMobileOnly } from 'react-device-detect'

function StandarPage(props) {
    const { strain, role, name } = props
    const { Content } = Layout
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [object, setObject] = useState([])
    const [dataStrain, setDataStrain] = useState({})
    const [idData, setIdData] = useState(null)
    const [detail, setDetail] = useState([])
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    const [form] = Form.useForm()

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = () => {
        setLoading(true)
        API.get(getStandar("", 0, 0, strain))
            .then(res => {
                if (res.data.data.standards.standards) {
                    setObject(res.data.data.standards.standards)
                    setDataStrain(res.data.data.standards.strain)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }


    useEffect(() => {
        fetchData(strain)
        // eslint-disable-next-line
    }, [strain])

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteStandar(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `Gagal menghapus standar strain`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteStandar.deleted === true) {
                    validationMessage('success', `Berhasil menghapus standar strain`)
                    fetchData(strain)
                }
            }).catch((error) => {
                validationMessage('error', `Gagal menghapus standar strain`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        Modal.confirm({
            title: `Apakah Anda yakin ingin menghapus standar hari ke-${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const onOpen = useCallback((id) => {
        setOpen(true)
        setIdData(id)
    }, [])

    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(detailStandar(idData))
                    .then(res => {
                        setDetail(res.data.data.standard)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setOpen(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const updateData = (values) => {
        API.post(updateStandar(idData, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `Gagal mengubah standar strain`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.updateStandar._id) {
                    validationMessage('success', `Berhasil mengubah standar strain`)
                    fetchData(strain)
                }
            }).catch((error) => {
                validationMessage('error', `Gagal mengubah standar strain`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updateData(values)
        setOpen(false)
        setLoading(true)
        setIdData(null)
    }
    const data = object.map(({ _id, day, bw, dg, adg, fcr, dep, fi, wi }, index) => ({
        key: _id,
        id: _id,
        hari: day,
        bw: bw,
        dg: dg,
        adg: adg,
        fcr: fcr?.toFixed(2),
        deplesi: dep,
        fi: fi,
        wi: wi
    }))

    const columns = [
        {
            title: `${t('pages.master.strain.day')}`,
            dataIndex: 'hari',
            key: 'hari',
            ...getColumnSearchProps('hari', `${t('pages.master.strain.day')}`),
        },
        {
            title: 'BW (gr)',
            dataIndex: 'bw',
            key: 'bw',
            align: 'right',
            ...getColumnSearchProps('bw', 'BW (gr)'),
        },
        {
            title: 'DG (gr)',
            dataIndex: 'dg',
            key: 'dg',
            align: 'right',
            ...getColumnSearchProps('dg', 'DG (gr)'),
        },
        {
            title: 'ADG (gr)',
            dataIndex: 'adg',
            key: 'adg',
            align: 'right',
            ...getColumnSearchProps('adg', 'ADG (gr)'),
        },
        {
            title: 'FCR',
            dataIndex: 'fcr',
            key: 'fcr',
            align: 'right',
            ...getColumnSearchProps('fcr', 'FCR'),
        },
        {
            title: `${t('pages.master.strain.depletion')}`,
            dataIndex: 'deplesi',
            key: 'deplesi',
            align: 'right',
            ...getColumnSearchProps('deplesi', `${t('pages.master.strain.depletion')}`),
        },
        {
            title: `FI (gr/ekor)`,
            dataIndex: 'fi',
            key: 'fi',
            align: 'right',
        },
        {
            title: `Water Intake`,
            dataIndex: 'wi',
            key: 'wi',
            align: 'right',
        },
        dataStrain?.type === 'secondary' || role === 'superadmin' ?
            {
                title: `${t('general.action')}`,
                dataIndex: 'pilihan',
                key: 'pilihan',
                width: 150,
                align: 'center',
                render: (value, row, index) => (
                    <Space size="middle">
                        <Tooltip>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FFA903',
                                borderRadius: '5px'
                            }}
                                onClick={() => onOpen(row.id)}
                            ><FontAwesomeIcon icon={faEdit} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('general.del')}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FF0303',
                                borderRadius: '5px'
                            }}
                                onClick={() => hapusConfirm(row.id, row.hari)}
                            ><FontAwesomeIcon icon={faTrash} /></Button>
                        </Tooltip>
                    </Space >
                )
            }
            : { width: 1 }
    ]

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col lg={18} sm={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><Link to="/peternak/master-strain" ><span>{t('pages.master.strain.title')}</span></Link></Breadcrumb.Item>
                            <Breadcrumb.Item><span>{t('pages.master.strain.std')} {name}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={6} sm={24}>
                        {dataStrain?.type === 'secondary' || role === 'superadmin' ?
                            <ButtonDashboard
                                text=" Tambah Standar Strain"
                                height={20}
                                backgroundColor="#008ad4"
                                borderRadius="5px"
                                className="font-semi-medium font-content-title button-add"
                                textColor="white"
                                icon={faPlusSquare}
                                onClick={() => setVisible(true)}
                            />
                            : <></>}
                    </Col>
                </Row>
                <CreateStandar strain={strain} visible={visible} setVisible={setVisible}
                    fetchData={fetchData} setLoading={setLoading} form={form} t={t} />
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                className="table-dashboard"
                                scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                            />
                        </Loading>
                        <EditStandar handleSubmit={handleSubmit} visible={open} setVisible={setOpen} detail={detail} />
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    strain: state.strain.idStrain,
    role: state.profil.dataProfil.type,
    name: state.strain.strain
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(StandarPage)
export default page