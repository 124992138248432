import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { Modal, Form, Row, Col, Select, Input, InputNumber, Button, Tooltip } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import { createRecordOvk, getPriceOvks } from '../rearing-record-aksi-page/query-rearing-record'
import { getListOvk } from '../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/store/stok-ovk-action'
import { getStokGudangOvk } from '../../../../app/dashboard-peternak/stok-page/stok-ovk-page/stok-ovk-aksi-page/query-stok-ovk'
import moment from 'moment'

function CreateOvkPage(props) {
    const { dateRearing, idRearingRecord, setLoading, stokOvk, fetchDataOvk, getListOvk, idGudangOvk, houseActive, profil, t } = props
    const [visible, setVisible] = useState(false)
    const { Option } = Select
    const [unit, setUnit] = useState('')
    const [form] = Form.useForm()
    const [month] = useState(moment(parseInt(dateRearing)).format('MMMM'))
    const [year] = useState(moment(parseInt(dateRearing)).format('YYYY'))
    const [monthStock, setStock] = useState([])

    useEffect(() => {
        getListOvk(getStokGudangOvk(idGudangOvk))
        getPrice(month, year, idGudangOvk)
    }, [getListOvk, month, year, idGudangOvk])

    const postData = async (values) => {
        await API.post(createRecordOvk(values, dateRearing, idRearingRecord))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.recording.ovk.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createOvkTransaction._id) {
                    validationMessage('success', `${t('pages.recording.ovk.resAddSuccess')}`)
                    fetchDataOvk(idRearingRecord)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.recording.ovk.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }


    const getPrice = async (month, year, idGudangOvk) => {
        await API.get(getPriceOvks('', 0, 0, month, year, idGudangOvk))
            .then(res => {
                setStock(res.data.data.monthlyStockOvks.calculateMonthlyStocks)
            }).catch(error => {
                console.log(error)
                setStock([])
            })
    }

    const onChangeOvk = async (value) => {
        const price = monthStock.find(d => d.idOvk === value.label[0].props.children)
        form.setFieldsValue({
            price: price?.hargaPengeluaran
        })
        setUnit(price?.satuan)
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }
    return (
        <>
            <Tooltip title={houseActive ? '' : t("error.inactiveHouse")}>
                <Button
                    disabled={houseActive ? false : true}
                    style={{
                        color: 'white',
                        backgroundColor: houseActive ? '#008ad4' : 'whitesmoke',
                        borderRadius: '5px',
                        float: "right",
                        border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
                    }} onClick={() => setVisible(true)}
                ><FontAwesomeIcon icon={faPlusSquare} className='mr-10' />
                    {t('pages.recording.ovk.add')}
                </Button>
            </Tooltip>

            <Modal
                visible={visible}
                title={t('pages.recording.ovk.add')}
                okText={t('general.add')}
                width={500} centered
                cancelText={t('general.cancel')}
                onCancel={() => {
                    setVisible(false)
                }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.recording.ovk.title')}</span>
                            <Form.Item
                                name="ovk" rules={[{ required: true, message: `${t('pages.recording.ovk.msgOvk')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.recording.ovk.phOvk')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                    onChange={(value) => onChangeOvk(value)}
                                >
                                    {
                                        stokOvk.map(data =>
                                            <Option
                                                value={data._id}
                                            ><span hidden>{data.ovk._id}</span> {data.ovk.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.ovk.qty')}</span>
                            <Form.Item
                                name="jumlah" rules={[
                                    { required: true, message: `${t('pages.recording.ovk.msgQty')}` },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!getFieldValue('price') || getFieldValue('price') < 1) {
                                                return Promise.reject(new Error('Tidak ditemukan harga untuk OVK ini. Mohon hubungi manajemen Anda untuk memperbarui stockflow!'))
                                            }
                                            return Promise.resolve()

                                        },
                                    })
                                ]}
                                dependencies={['price', 'ovk']}
                            >
                                <Input type='number'
                                    placeholder="1000..."
                                    className="input-form"
                                    suffix={unit}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} shouldUpdate
                            hidden={profil.permission.nominalRecording ? false : true}
                        >
                            <span className="font-semi-bold black font-title-medium">Harga (Rp)</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `Mohon masukkan harga` }]}
                            >
                                <InputNumber
                                    placeholder="2500..."
                                    className="input-number"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    stokOvk: state.stokOvk.dataStokOvk,
    dateRearing: state.rearingRecord.dateRearingRecord,
    idRearingRecord: state.rearingRecord.idRearingRecord,
    idGudangOvk: state.gudangOvk.idGudangOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate, getListOvk
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateOvkPage)
export default page