import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { errMessage, validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import CONSTANTS from '../../../../../common/utils/Constants'
import { createGudangPakan } from './query-gudang-pakan'
import { Modal, Form, Row, Col, Input } from 'antd'

function CreateGudangPakanPage(props) {
    const { fetchData, total, setLoading, typeUser, t } = props
    const [visible, setVisible] = useState(false)

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = async (values) => {
        await API.post(createGudangPakan(values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.warehouse.resAddFeedFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.createFeedWarehouse._id) {
                    validationMessage('success', `${t('pages.master.warehouse.resAddFeedSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.master.warehouse.resAddFeedFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values.gudangPakan)
        setVisible(false)
        setLoading(true)
    }

    const free = () => {
        Modal.info({
            title: `${t('general.upgrade')}`,
            centered: true,
            content: (
                <p>{t('general.limitedFeedWh')}</p>
            ),
            okText: 'Upgrade',
            onOk() { props.navigate(CONSTANTS.PRICING_KEY) },
        })
    }

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.master.warehouse.addFeed')}
                okText={t('general.add')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.warehouse.addFeed')}</span>
                            <Form.Item
                                name="gudangPakan" rules={[{ required: true, message: `${t('pages.master.warehouse.msgFeed')}` }]}
                            >
                                <Input
                                    name='gudangPakan'
                                    placeholder={t('pages.master.warehouse.phFeed')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    return (
        <div>
            {typeUser === 'free' && total !== 0 ? (
                <span onClick={() => free()}>{t('pages.master.warehouse.addFeed')}</span>

            ) : (
                <span onClick={() => showModal()}>{t('pages.master.warehouse.addFeed')}</span>
            )}
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    typeUser: state.profil.dataProfil.company.typeUser,
})

const mapDispatchToProps = (dispatch => ({
    navigate
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateGudangPakanPage)
export default page