import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { errMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import { Modal, Form, Row, Col, Input, DatePicker, Select, InputNumber } from 'antd'
import { createPeriode, queryPeriodeAktif } from './query-rearing'
import { getListKandang, getPeriodeAktif } from '../../../../modules/dashboard-peternak/layout-component/store/layout-action'
import { getDoc } from '../../master-page/doc-page/doc-aksi-page/query-doc'
import { queryListKandang } from '../../master-page/kandang-page/kandang-aksi-page/query-kandang'

function CreateRearingPage(props) {
    const { fetchDataActive, getListKandang, getPeriodeAktif, setLoading, kandang, visible, setVisible, t } = props
    const { TextArea } = Input
    const { Option } = Select
    const [doc, setDoc] = useState([])

    const fetchDataDoc = () => {
        API.get(getDoc("", 0, 0))
            .then(res => {
                setDoc(res.data.data.docs.docs)
            }).catch((error) => {
                console.log(error)
                setDoc(null)
            })
    }

    useEffect(() => {
        fetchDataDoc()
        getListKandang(queryListKandang('', 0, 0))
        // eslint-disable-next-line
    }, [])

    const postData = async (values, year) => {
        await API.post(createPeriode(values, year))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t("pages.rearing.resAddFailed")}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.createRearing._id) {
                    errMessage('success', `${t("pages.rearing.resAddSuccess")}`)
                    fetchDataActive()
                    getPeriodeAktif(queryPeriodeAktif())
                }
            }).catch((error) => {
                errMessage('error', `${t("pages.rearing.resAddFailed")}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, values['date_picker'].format('MM/DD/YYYY'))
        setVisible(false)
        setLoading(true)
    }
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t("pages.rearing.add")}
                okText={t("general.add")}
                width={800} centered
                cancelText={t("general.cancel")}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        keterangan: '',
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.name")}</span>
                            <Form.Item
                                name="nama_periode" rules={[{ required: true, message: `${t("pages.rearing.msgName")}` }]}
                            >
                                <Input
                                    placeholder={t("pages.rearing.phName")}
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.date")}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, type: 'object', message: `${t("pages.rearing.msgDate")}` }]}
                            >
                                <DatePicker
                                    placeholder={t("pages.rearing.phDate")}
                                    className="select-input-date"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.weight")}</span>
                            <Form.Item
                                name="bobot" rules={[{ required: true, message: `${t("pages.rearing.msgWeight")}` }]}
                            >
                                <Input type='number'
                                    style={{ width: '100%' }}
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="gram"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >{t("pages.rearing.house")}</span>
                            <Form.Item
                                name="kandang" rules={[{ required: true, message: `${t("pages.rearing.msgHouse")}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t("pages.rearing.phHouse")}
                                    optionFilterProp="children"
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        kandang.map(data =>
                                            <Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{t("pages.rearing.doc")}</span>
                            <Form.Item
                                name="doc" rules={[{ required: true, message: `${t("pages.rearing.msgDoc")}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t("pages.rearing.phDoc")}
                                    optionFilterProp="children"
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        doc.map(data =>
                                            <Option
                                                value={data._id}
                                            >{data.name} - {data.strain.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.price")}</span>
                            <Form.Item
                                name="harga" rules={[{ required: true, type: 'number', message: `${t("pages.rearing.msgPrice")}` }]}
                            >
                                <InputNumber
                                    placeholder="25000..."
                                    className="input-number"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.population")}</span>
                            <Form.Item
                                name="populasiAwal" rules={[{ required: true, message: `${t("pages.rearing.msgPopulation")}` }]}
                            >
                                <Input type='number'
                                    style={{ width: '100%' }}
                                    placeholder="1000..."
                                    className="input-suffix"
                                    suffix={t('pages.rearing.chicken')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.population")} HPP</span>
                            <Form.Item
                                name="populasiHpp" rules={[{ required: true, message: `${t("pages.rearing.msgPopulation")}` }]}
                            >
                                <Input
                                    placeholder="1000..." type='number'
                                    className="input-suffix"
                                    suffix={t("pages.rearing.chicken")}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.ppl")}</span>
                            <Form.Item
                                name="ppl" rules={[{ required: true, message: `${t("pages.rearing.msgPpl")}` }]}
                            >
                                <Input
                                    placeholder="Irfan..."
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >{t("pages.rearing.info")}</span>
                            <Form.Item
                                name="keterangan"
                            >
                                <TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={t("pages.rearing.phInfo")}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    return (
        <CollectionCreateForm
            visible={visible}
            handleSubmit={handleSubmit}
            kandang={kandang}
            onCancel={() => {
                setVisible(false)
            }}
        />
    )
}

const mapStateToProps = state => ({
    kandang: state.layout.dataKandang,
    idKandang: state.layout.idKandang,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListKandang,
    getPeriodeAktif,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateRearingPage)
export default page