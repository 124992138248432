/**Query Pakan */
export const getPeriodeStockFlow = (keyword, limit, skip, year) => {
  return (
    `query{
          listMonthlyStocks(keyword:"${keyword}",limit:${limit},skip:${skip}, year:"${year}"){
            totalCount
            monthlyStocks{
              _id
              month
              year
              locked
              createdAt
            }
          }
        }
      `
  )
}

export const createStockflow = () => {
  return (
    `mutation{
        createMonthlyStock{
            _id
            month
            year
            createdAt
            locked
        }
      }`
  )
}
export const newStockflow = (date) => {
  return (
    `mutation{
        newMonthlyStock(newInput: {
          date: "${date}"
      }){
            _id
            month
            year
            createdAt
            locked
        }
      }`
  )
}
export const lockPeriodeStok = (_id) => {
  return {
    query: `                      
          mutation{
            lockMonthlyStock(_id: "${_id}"){
                ok
              _id
            }
          }
      `
  }
}

export const unlockPeriodeStok = (_id) => {
  return {
    query: `                      
          mutation{
            unlockMonthlyStock(_id: "${_id}"){
                ok
              _id
            }
          }
      `
  }
}

export const getListYear = (keyword, limit, skip) => {
  return (
    `query{
      listYearStockFlows(keyword:"${keyword}",limit:${limit},skip:${skip}){
        totalCount
        listYearStockFlows
      }
    }
  `
  )
}

export const delStockflow = (_id) => {
  return (
    `mutation{
            deleteMonthlyStock(_id: "${_id}"){
                deleted
            }
          }`
  )
}

//feed
export const getDetailPeriodeStockFeed = (_id) => {
  return (
    `query{
        listMonthlyStock(_id: "${_id}"){
            _id
            month
            year
            locked
            initialBalanceFeed{
              _id
              bgnQty
              bgnPrice
              feed{
                  _id
                  name
              }
            }
          }
        }
    `
  )
}
export const updateInitialBalanceFeed = (_id, bgnQty, bgnPrice) => {
  return (
    `mutation{
        updateInitialBalanceFeed(_id: "${_id}", updatedInitialBalanceFeed: {
          bgnQty:${bgnQty}, bgnPrice:${bgnPrice}
        }){
          _id
          bgnQty
          bgnPrice
          bgnTotal
          storeTime
            }
          }`
  )
}

/**Query Live Bird */
export const createInitialBalanceLiveBird = (value, monthlyStockLiveBird) => {
  return (
    `mutation{
      createInitialBalanceLiveBird( createdInitialBalanceLiveBird: {
        bgnQty:${value.bgnQty}, bgnPrice:${value.bgnPrice},
        rearing:  "${value.rearing.value}",
        monthlyStockLiveBird:  "${monthlyStockLiveBird}"
        })
        {
            _id
            bgnQty
            bgnPrice
            bgnTotal
            storeTime
            company{
                _id
            }
            monthlyStockLiveBird{
                _id
                month
            }
        }
    }`
  )
}
export const updateInitialBalanceLiveBird = (_id, bgnQty, bgnPrice) => {
  return (
    `mutation{
        updateInitialBalanceLiveBird(_id: "${_id}", updatedInitialBalanceLiveBird: {
          bgnQty:${bgnQty}, bgnPrice:${bgnPrice}
        }){
          _id
          bgnQty
          bgnPrice
          bgnTotal
          storeTime
            }
          }`
  )
}
export const getDetailPeriodeStockLiveBird = (_id) => {
  return (
    `query{
        listMonthlyStock(_id: "${_id}"){
            _id
            month
            year
            locked
            initialBalanceLivebird{
              _id
              bgnQty
              bgnPrice
              rearing{
                  name
                  house{
                      name
                  }
              }
            }
          }
        }
    `
  )
}

/**Query Ovk */

export const getDetailPeriodeStockOvk = (_id) => {
  return (
    `query{
      listMonthlyStock(_id: "${_id}"){
          _id
          month
          year
          locked
          initialBalanceOvk{
            _id
            bgnQty
            bgnPrice
            ovk{
                name
              }
            }
          }
        }
      }
    `
  )
}
export const createInitialBalanceOvk = (value, monthlyStockOvk, warehouse) => {
  return (
    `mutation{
      createInitialBalanceOvk( createdInitialBalanceOvk: {
        bgnQty:${value.bgnQty}, bgnPrice:${value.bgnPrice},
        ovk:  "${value.ovk.value}",
        monthlyStockOvk:  "${monthlyStockOvk}"
        ovkWarehouse:  "${warehouse}"
        })
        {
            _id
            bgnQty
            bgnPrice
            bgnTotal
            storeTime
            ovk{
                _id
                name
            }
            company{
                _id
            }
            monthlyStockOvk{
                _id
                month
            }
        }
    }`
  )
}
export const updateInitialBalanceOvk = (_id, bgnQty, bgnPrice) => {
  return (
    `mutation{
        updateInitialBalanceOvk(_id: "${_id}", updatedInitialBalanceOvk: {
          bgnQty:${bgnQty}, bgnPrice:${bgnPrice}
        }){
          _id
          bgnQty
          bgnPrice
          bgnTotal
          storeTime
            }
        }`
  )
}


