import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { errMessage, validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import CreateAdjustmentPakanComponent from '../../../../../modules/dashboard-peternak/adjustment-stock-component/pakan-component/pakan-aksi-component/create-adjustment-pakan-component'
import { createAdjustmentPakan } from './query-adjustment-pakan-page'

function CreateStokPakanPage(props) {
    const { setLoading, idMonthlyStockFeed, fetchData, object, locked, idGudangPakan, t } = props
    const [visible, setVisible] = useState(false)

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values, idMonthlyStockFeed) => {
        API.post(createAdjustmentPakan(values, idMonthlyStockFeed, idGudangPakan))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.stockflow.resAddAdjFeedFailed')}`, res.data.errors[0].errMessage)
                    setLoading(false)
                }
                else if (res.data.data.createAdjustmentFeed._id) {
                    validationMessage('success', `${t('pages.stockflow.resAddAdjFeedSuccess')}`)
                    fetchData(idMonthlyStockFeed, idGudangPakan)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.stockflow.resAddAdjFeedFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, idMonthlyStockFeed)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreateAdjustmentPakanComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            pakan={props.pakan}
            locked={locked}
            object={object}
            t={t}

        />
    )
}

const mapStateToProps = state => ({
    pakan: state.pakan.dataPakan,
    idMonthlyStockFeed: state.adjustmentPakan.idMonthlyStockFeed,
    idGudangPakan: state.gudangPakan.idGudangPakan,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStokPakanPage)
export default page