import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { validationMessage } from '../../common/component/notification/notification'
import { navigate } from '../../common/store/action'
import RegisterComponent from '../../modules/auth/component/register-component'
import { handleRegister } from '../../modules/auth/store/auth-action'
import { useTranslation } from "react-i18next"
import i18n from '../../translations/i18n'

function RegisterPage(props) {
    const [email, setEmail] = useState('')
    const [nama, setNama] = useState('')
    const [token, setToken] = useState(null)
    const [foto, setFoto] = useState(null)
    const [password, setPassword] = useState('')
    const [checked, setChecked] = useState(false)
    const [recaptchaRef, setRecaptchaRef] = useState(null)
    const [oauth, setOauth] = useState(false)
    const [oauthType, setOauthType] = useState(null)
    const [loginType, setLoginType] = useState("email")
    const { t } = useTranslation()
    const [lang] = useState(i18n.language)

    const handleLocales = useCallback((e) => {
        if (i18n.language !== e) {
            i18n.changeLanguage(e)
        }
    }, [])

    const responseGoogle = (response) => {
        let email = response.profileObj.email
        let nama = response.profileObj.name

        if (email !== null && nama !== null) {
            fillForms(nama, email)
            setOauth(true)
            setOauthType("google")
            setLoginType("google")
            setToken(response.accessToken)
            setFoto(response.profileObj.imageUrl)
        } else {
            validationMessage("error", "Silahkan coba beberapa saat lagi")
        }
    }

    const fillForms = (nama, email) => {
        setNama(nama)
        setEmail(email)
        setPassword("empty")
        validationMessage("success", `${t('pages.auth.fillForm')}`)
    }

    const responseFb = async (response) => {
        let email = response.email
        let name = response.name

        if (email !== null && name !== null) {
            setNama(name)
            fillForms(name, email)
            setOauth(true)
            setOauthType("facebook")
            setLoginType("facebook")
            setToken(response.accessToken)
            setFoto(response.picture.data.url)
        } else {
            validationMessage("error", "Silahkan coba beberapa saat lagi")
        }
    }

    const responseApple = async (response) => {
        console.log(response)
        let email = response.email
        let name = response.name

        if (email !== null && name !== null) {
            setNama(name)
            fillForms(name, email)
            setOauth(true)
            setOauthType("apple")
            setLoginType("apple")
            // setToken(response.accessToken)
            // setFoto(response.picture.data.url)
        } else {
            validationMessage("error", "Silahkan coba beberapa saat lagi")
        }
    }

    const handleSubmit = (value) => {
        const queryRegisterEmail = {
            query: `mutation{
                      register(registerInput: {
                        name : "${value.namaLengkap}"
                        email : "${value.email}"
                        username : "${value.username}"
                        password : "${value.password}"
                        address : "${value.alamat}"
                        lat: "${value.position.lat}"
                        lng: "${value.position.lng}"
                        phone: "${value.nomorTelefon}"
                        loginType: "${loginType}"
                        companyName : "${value.perusahaan}"
                        companyType : "${value.jenisUsaha}"
                        house : "${value.house}"
                        capacity : ${value.capacity}
                        houseAddr: "${value.houseAddr}"
                        latHouse: "${value.positionHouse.lat}"
                        lngHouse: "${value.positionHouse.lng}"
                        token_recaptcha: "${recaptchaRef}"
                      }){
                        name
                        email
                      }
                    }
                `,
        }

        const queryRegisterOauth = {
            query: `mutation{
                        register(registerInput: {
                            name : "${nama}"
                            email : "${email}"
                            username : "${value.username}"
                            password : "${password}"
                            foto: "${foto}"
                            address : "${value.alamat}"
                            lat: "${value.position.lat}"
                            lng: "${value.position.lng}"
                            phone: "${value.nomorTelefon}"
                            companyName : "${value.perusahaan}"
                            companyType : "${value.jenisUsaha}"
                            house : "${value.house}"
                            capacity : ${value.capacity}
                            houseAddr: "${value.houseAddr}"
                            latHouse: "${value.positionHouse.lat}"
                            lngHouse: "${value.positionHouse.lng}"
                            oauthType : "${oauthType}"
                            loginType: "${loginType}"
                            token : "${token}"
                            token_recaptcha: "${recaptchaRef}"
                      }){
                        name
                        email
                      }
                    }
                `,
        }
        console.log(queryRegisterEmail)
        if (recaptchaRef === null) {
            validationMessage('error', `${t('pages.auth.captcha')}`)
        } else if (checked === false) {
            validationMessage('error', `${t('pages.auth.msgTerm')}`)
        } else {
            if (oauth) {
                props.handleRegister(queryRegisterOauth, 'oauth')
            }
            else
                props.handleRegister(queryRegisterEmail, 'email')
        }
    }

    const onChange = useCallback((value) => {
        setChecked(!checked)
    }, [checked])

    return (
        <RegisterComponent
            navigate={props.navigate}
            email={email}
            nama={nama}
            password={password}
            recaptchaRef={recaptchaRef}
            oauth={oauth}
            setRecaptchaRef={setRecaptchaRef}
            handleSubmit={handleSubmit}
            responseGoogle={responseGoogle}
            responseFb={responseFb}
            responseApple={responseApple}
            loading={props.loading}
            onChange={onChange}
            t={t} lang={lang} handleLocales={handleLocales}
        />
    )
}

const mapStateToProps = state => ({
    loading: state.auth.loading,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    handleRegister
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
export default page