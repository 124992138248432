import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input, Layout, Breadcrumb, Row, Col, Table, Tooltip, Modal, Form } from 'antd'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlusSquare, faEdit, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import { API } from '../../../../common/api'
import Loading from '../../../../common/component/loading/loading-container'
import { navigate } from '../../../../common/store/action'
import { validationMessage } from '../../../../common/component/notification/notification'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'

import CreateStandar from '../iot-aksi-page/create-standar-ews'
import EditStandar from '../iot-aksi-page/edit-standar-ews'
import { deleteStandardEnvDetail, getDetailStandardsEnv, showDetailStandardEnv, updateStandardEnvironmentDetail } from '../iot-aksi-page/query-iot'

function DetailStandardEnv(props) {
  const { idStdEnv, nameStdEnv, profil } = props
  const { Content } = Layout
  const [searchText, setSearchText] = useState('')
  const [seacrhedColumn, setSeacrhedColumn] = useState('')
  const [data, setObject] = useState([])
  const [idData, setIdData] = useState(null)
  const [detail, setDetail] = useState([])
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`${t('general.search')} ${title}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            {t('general.search')}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {t('general.reset')}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: text =>
      seacrhedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSeacrhedColumn(dataIndex)
  }

  const handleReset = clearFilters => {
    clearFilters()
    setSearchText('')
  }

  const fetchData = (idStdEnv) => {
    setLoading(true)
    API.get(getDetailStandardsEnv(idStdEnv))
      .then(res => {
        if (res.data.data.standardEnvironmentDetails.standardEnvironmentDetails) {
          setObject(res.data.data.standardEnvironmentDetails.standardEnvironmentDetails)
          setLoading(false)
        }
      }).catch((error) => {
        console.log(error.message)
        setObject([])
        setLoading(false)
      })
  }


  useEffect(() => {
    fetchData(idStdEnv)
    // eslint-disable-next-line
  }, [idStdEnv])

  const deleteData = (_id) => {
    setLoading(true)
    API.delete(deleteStandardEnvDetail(_id))
      .then(res => {
        if (res.data.errors) {
          validationMessage('error', `${t('pages.monitor.resDelStdFailed')}`, res.data.errors[0].message)
          setLoading(false)
        }
        else if (res.data.data.deleteStandardEnvironmentDetail.deleted === true) {
          validationMessage('success', `${t('pages.monitor.resDelStdSuccess')}`)
          fetchData(idStdEnv)
        }
      }).catch((error) => {
        validationMessage('error', `${t('pages.monitor.resDelStdFailed')}`, error.message)
        setLoading(false)
      })
  }

  const hapusConfirm = (_id, day) => {
    Modal.confirm({
      title: `Apakah Anda yakin ingin menghapus standar hari ke-${day}?`,
      onOk: () => {
        deleteData(_id)
      },
      icon: <DeleteOutlined style={{ color: 'red' }} />,
      maskClosable: true,
      okText: t('general.del'),
      cancelText: t('general.cancel'),
      okButtonProps: { danger: true }
    })
  }

  const onOpen = useCallback((id) => {
    setOpen(true)
    setIdData(id)
  }, [])

  useEffect(() => {
    function getDetailData() {
      if (idData) {
        API.get(showDetailStandardEnv(idData))
          .then(res => {
            setDetail(res.data.data.standardEnvironmentDetail)
          }).catch((error) => {
            console.log(error)
            setDetail(null)
            setOpen(false)
          })
      }
    }
    getDetailData()
  }, [idData])

  const updateData = (values) => {
    API.post(updateStandardEnvironmentDetail(idData, values))
      .then(res => {
        if (res.data.errors) {
          validationMessage('error', `${t('pages.monitor.resEditStdFailed')}`, res.data.errors[0].message)
          setLoading(false)
        }
        else if (res.data.data.updateStandardEnvironmentDetail._id) {
          validationMessage('success', `${t('pages.monitor.resEditStdSuccess')}`)
          fetchData(idStdEnv)
        }
      }).catch((error) => {
        console.log(error)
        validationMessage('error', `${t('pages.monitor.resEditStdFailed')}`, error.message)
        setLoading(false)
      })
  }

  const handleSubmit = (values) => {
    updateData(values)
    setOpen(false)
    setLoading(true)
    setIdData(null)
  }

  const columns = [
    {
      title: `${t('pages.monitor.day')}`,
      dataIndex: 'day',
      key: 'day',
      align: 'center',
      ...getColumnSearchProps('day', `${t('pages.monitor.day')}`),
    },
    {
      title: `${t('pages.monitor.maxTemp')}`,
      dataIndex: 'max_temperature',
      key: 'max_temperature',
      align: 'right',
      ...getColumnSearchProps('max_temperature', `${t('pages.monitor.maxTemp')}`),
      render: (value, row, index) => (value + ' °C')
    },
    {
      title: `${t('pages.monitor.minTemp')}`,
      dataIndex: 'min_temperature',
      key: 'min_temperature',
      align: 'right',
      ...getColumnSearchProps('min_temperature', `${t('pages.monitor.minTemp')}`),
      render: (value, row, index) => (value + ' °C')
    },
    {
      title: `${t('pages.monitor.maxHumidity')}`,
      dataIndex: 'max_humidity',
      key: 'max_humidity',
      align: 'right',
      ...getColumnSearchProps('max_humidity', `${t('pages.monitor.maxHumidity')}`),
      render: (value, row, index) => (value + ' %')
    },
    {
      title: `${t('pages.monitor.minHumidity')}`,
      dataIndex: 'min_humidity',
      key: 'min_humidity',
      align: 'right',
      ...getColumnSearchProps('min_humidity', `${t('pages.monitor.minHumidity')}`),
      render: (value, row, index) => (value + ' %')
    },
    {
      title: `${t('pages.monitor.maxCo2')}`,
      dataIndex: 'max_co2',
      key: 'max_co2',
      align: 'right',
      ...getColumnSearchProps('max_co2', `${t('pages.monitor.maxCo2')}`),
      render: (value, row, index) => (value + ' ppm')
    },
    profil.permission.writeIot ?
      {
        title: `${t('general.action')}`,
        dataIndex: 'pilihan',
        key: 'pilihan',
        width: 150,
        align: 'center',
        render: (value, row, index) => (
          <Space size="middle">
            <Tooltip>
              <Button style={{
                color: 'white',
                backgroundColor: '#FFA903',
                borderRadius: '5px'
              }}
                onClick={() => onOpen(row._id)}
              ><FontAwesomeIcon icon={faEdit} /></Button>
            </Tooltip>
            <Tooltip placement="top" title={t('general.del')}>
              <Button style={{
                color: 'white',
                backgroundColor: '#FF0303',
                borderRadius: '5px'
              }}
                onClick={() => hapusConfirm(row._id, row.day)}
              ><FontAwesomeIcon icon={faTrash} /></Button>
            </Tooltip>
          </Space >
        )
      }
      : { width: 1 }
  ]

  return (
    <Layout>
      <Content className="dashboard-container">
        <Row className="dashboard-section">
          <Col span={18}>
            <Breadcrumb separator="">
              <Breadcrumb.Item><Link to="/peternak/ews" >
                <Button shape="circle" style={{ border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 5%)' }}
                  icon={<FontAwesomeIcon icon={faArrowLeft} />} size='default' className='mr-10' />
              </Link></Breadcrumb.Item>
              <Breadcrumb.Item><span>{t('pages.monitor.std')} {nameStdEnv}</span></Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col span={6}>
            {profil.permission.writeIot ?
              <ButtonDashboard
                text={t('pages.monitor.addStandardEnv')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => setVisible(true)}
              />
              : <></>}
          </Col>
        </Row>
        <CreateStandar idStdEnv={props.idStdEnv} visible={visible} setVisible={setVisible}
          fetchData={fetchData} setLoading={setLoading} form={form} t={t} />
        <Row>
          <Col lg={24} md={24} sm={24}>
            <Loading loading={loading}>
              <Table
                columns={columns}
                dataSource={data}
                bordered
                pagination={false}
                className="table-dashboard"
                scroll={{ y: '70vh' }}
              />
            </Loading>
            <EditStandar handleSubmit={handleSubmit} visible={open} setVisible={setOpen} detail={detail} t={t} />
          </Col>
        </Row>
      </Content>
    </Layout>
  )
}

const mapStateToProps = state => ({
  role: state.profil.dataProfil.type,
  idStdEnv: state.iot.idStdEnv,
  nameStdEnv: state.iot.nameStdEnv,
  profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
  navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailStandardEnv)
export default page