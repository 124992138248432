export const getIot = (keyword, limit, skip) => {
    return (
        `query{
            devices(keyword:"${keyword}",limit:${limit},skip:${skip}){
                totalCount,
                devices{
                    _id
                    serialNumber
                    deviceId
                    deviceToken
                    position
                    house{
                        _id
                        name
                    }
                    deviceType
                    latestTelemetry{
                        humidity
                        temperature
                        amonia
                        voc
                        co2
                        thi
                        kph
                        ms
                        status
                        ts
                        timestamp
                    }
                }
            }
        }
      `
    )
}

export const getIotHouse = (keyword, limit, skip, house) => {
    return (
        `query{
            deviceHouses(keyword:"${keyword}",limit:${limit},skip:${skip}, house:"${house}"){
                totalCount,
                devices{
                    _id
                    serialNumber
                    position
                    deviceId
                    deviceToken
                    house{
                        _id
                        name
                    }
                    deviceType
                    latestTelemetry{
                        humidity
                        temperature
                        amonia
                        voc
                        co2
                        thi
                        kph
                        ms
                        status
                        ts
                        timestamp
                    }
                }
            }
        }
      `
    )
}

export const getTypeIot = () => {
    return (
        `query{
            deviceTypes(keyword:"",limit:10,skip:0){
              totalCount,
              deviceTypes
            }
          }`
    )
}

export const detailIot = (_id) => {
    return (
        `query{
            device(_id:"${_id}"){
                _id
                  serialNumber
                  position
                  house{
                     value : _id
                      name
                  }
                  deviceType
              }
            }
        `
    )
}

export const updateIot = (_id, value) => {
    return {
        query: `
            mutation{
                    updateDevice(_id: "${_id}", updateDeviceInput: {
                    serialNumber: "${value.nomor_serial}"
                    house : "${value.kandang.value}"
                    deviceType : "${value.tipe}"
                    position : "${value.posisi}"
                }){
                    _id
                }
          }
        `
    }
}

export const deleteIot = (_id) => {
    return (
        `mutation{
            deleteDevice(_id:"${_id}"){
              deleted
            }
          }`
    )
}

export const createIot = (value) => {
    return {
        query: `
        mutation{
            createDevice(deviceInput:{
              serialNumber: "${value.nomor_serial}"
              house : "${value.kandang.value}"
              deviceType : "${value.tipe.value}"
              position : "${value.posisi}"
            }){
              _id
            }
          }
          `
    }
}

//STANDARD ENV
export const getStandardsEnv = () => {
    return (
        `query{
            standardEnvironments(keyword:"",limit:0,skip:0){
              totalCount,
              standardEnvironments{
                _id
                name
                type
                house{
                    name
                }
              }
            }
          }`
    )
}

export const detailStandardEnv = (_id) => {
    return (
        `query{
            standardEnvironment(_id:"${_id}"){
                _id
                name
                type
                house{
                    value: _id
                    name
                }
            }
        }
        `
    )
}

export const createStandardEnv = (value, role) => {
    return {
        query: `
        mutation{
            createStandardEnvironment(standardEnvironmentInput:{
              name: "${value.name}"
              house : "${role === "superadmin" ? null : value.kandang.value}"
            }){
              _id
            }
          }
          `
    }
}

export const updateStandardEnv = (_id, value, role) => {
    return {
        query: `
            mutation{
                updateStandardEnvironment(_id: "${_id}", updateStandardEnvironmentInput: {
                    name: "${value.name}"
                    house : "${role === "superadmin" ? null : value.kandang.value}"
                }){
                    _id
                }
          }
        `
    }
}

export const deleteStandardEnv = (_id) => {
    return (
        `mutation{
            deleteStandardEnvironment(_id:"${_id}"){
              deleted
            }
          }`
    )
}

//DETAIL STANDARD ENV
export const getDetailStandardsEnv = (id) => {
    return (
        `query{
            standardEnvironmentDetails(keyword:"",limit:0,skip:0, standardEnvironment:"${id}"){
              totalCount,
              standardEnvironmentDetails{
                _id
                day
                max_temperature
                min_temperature
                max_humidity
                min_humidity
                max_co2
              }
            }
          }`
    )
}

export const showDetailStandardEnv = (_id) => {
    return (
        `query{
            standardEnvironmentDetail(_id:"${_id}"){
                _id
                day
                max_temperature
                min_temperature
                max_humidity
                min_humidity
                max_co2
            }
        }
        `
    )
}

export const createStandardEnvDetail = (value, id) => {
    return {
        query: `
        mutation{
            createStandardEnvironmentDetail(standardEnvironmentDetailInput:{
                day :${value.day}
                max_temperature: ${value.max_temperature}
                min_temperature: ${value.min_temperature}
                max_humidity: ${value.max_humidity}
                min_humidity: ${value.min_humidity}
                max_co2: ${value.max_co2}
                standardEnvironment: "${id}"
            }){
              _id
            }
          }
          `
    }
}

export const updateStandardEnvironmentDetail = (_id, value) => {
    return {
        query: `
            mutation{
                updateStandardEnvironmentDetail(_id: "${_id}", updateStandardEnvironmentDetailInput: {
                    day :${value.day}
                    max_temperature: ${value.max_temperature}
                    min_temperature: ${value.min_temperature}
                    max_humidity: ${value.max_humidity}
                    min_humidity: ${value.min_humidity}
                    max_co2: ${value.max_co2}
                }){
                    _id
                }
          }
        `
    }
}

export const deleteStandardEnvDetail = (_id) => {
    return (
        `mutation{
            deleteStandardEnvironmentDetail(_id:"${_id}"){
              deleted
            }
          }`
    )
}

//TARE
export const detailTare = (_id) => {
    return (
        `query{
            tare(_id:"${_id}"){
                _id
                time
                temperatureDeviation
                humidityDeviation
                co2Deviation
                windSpeedDeviation
                weightDeviation
                device
                otherInformation
            }
        }
        `
    )
}

export const createTare = (value, id) => {
    return {
        query: `
        mutation{
            createTare(tareInput:{
                currentTemperature: ${value.currentTemperature}
                currentHumidity: ${value.currentHumidity}
                currentCo2: ${value.currentCo2}
                currentWindSpeed: ${value.currentWindSpeed}
                currentWeight: ${value.currentWeight}
                time: "${value.time}"
                temperatureFromComparedDevice: ${value.temperatureFromComparedDevice}
                humidityFromComparedDevice: ${value.humidityFromComparedDevice}
                co2FromComparedDevice: ${value.co2FromComparedDevice}
                windSpeedFromComparedDevice: ${value.windSpeedFromComparedDevice}
                weightFromComparedDevice: ${value.weightFromComparedDevice}
                device:"${id}"
                otherInformation: "${value.otherInformation}"
            }){
              _id
            }
          }
          `
    }
}


export const latestEnv = (_id) => {
    return (
        `query{
            latestEnvDevice(deviceId:"${_id}"){
                ts
                temperature
                humidity
                co2
                kph
                ms
                timestamp
            }
        }
        `
    )
}