export const getMonthlyStockFeeds = (keyword,limit,skip,month,year, warehouse) => {
    return (
        `query{
            monthlyStockFeeds(keyword:"${keyword}",limit:${limit},skip:${skip}, month:"${month}", year:"${year}", feedWarehouse: "${warehouse}"){
            totalCount
            locked
            idMonthlyStock
            calculateMonthlyStocks{
                _id
                idFeed
                idMonthlyStock
                bulan
                tahun
                namaPakan
        
                stockAwalBulan
                hargaAwalBulan
                hargaTotalAwalBulan
        
                addStock
                hargaPengadaan
                nilaiPengadaan
        
                outStock
                hargaPengeluaran
                nilaiPengeluaran
        
                qty
                price
                hargaTotalPenyesuaian

                sisaStok
                nilaiSisaStok
              }
          }
        }
      `
    )
}