export const dashboard = {
    title: 'Farmer Record Dashboard',
    phHouse: 'Select House',
    phPeriod: 'Select Period',
    phFeedWh: 'Select Feed Warehouse',
    phMaterialWh: 'Select Material Warehouse',
    phOvkWh: 'Select Medicine Warehouse',
    report: {
        livebird: 'Number of Live Birds',
        culling: 'Number of Culling',
        dead: 'Number of Dead Chickens',
        feed: 'Number of Feeding',
        chicken: ' Chickens',
    },
    performance: {
        title: 'Performance',
        chartTitle: 'House Performance Chart',
        age: 'Age',
        day: 'days',
        chicken: 'chickens',
        population: 'Population',
        bw: 'BW',
        depletion: 'Depletion',
        adg: 'ADG',
        fcr: 'FCR',
        value: 'Value',
        standarBw: ' Body Weight Standard',
        standarDepletion: 'Depletion Standard',
        standarFcr: 'FCR Standard',
        standarFi: " Feed Intake Standard"
    },
    iot: {
        title: 'Climate',
        chartTitle: 'House Environment Condition Chart',
        thiChartTitle: 'THI Chart',
        phParameter: 'Select Parameter',
        hour: 'Hour',
        day: 'Day',
        phTool: 'Select Tool',
        date: 'Date',
        humidity: 'Humidity',
        temperature: 'Temperature (°C)',
        airPressure: 'Air Pressure',
        value: 'Value',
        updatedAt: 'Updated at:',
        nodevice: 'You have no registered BroilerX IoT device',
        notelemetry: 'Please select your device first to see detail information',
        selfHumidity: 'Self-scaling humidity'
    },
    price: {
        title: 'Price Info',
        soon: 'Chicken Price feature is coming soon',
        livebird: 'Livebird',
        carcass: 'Carcass',
        prediction: 'Prediction',
        phSearch: 'Search city',
        titlePage: 'Indonesia Poultry Price Information',
        source: 'Source',
        city: 'City',
        chartTitle: 'Price Timeline',
        price: 'Price (Rp)',
        nearby: 'Nearby City Price'
    },
    feed: {
        title: 'Feed Usage',
        chart: 'Feed Usage Chart',
        age: 'Age (day)',
        qty: 'Quantity (kg)',
        feed: 'Feed',
        total: 'Total',
        day: 'Days',
        days: 'days'
    }
}