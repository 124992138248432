export const getMonthlyStockOvks = (keyword,limit,skip,month,year, warehouse) => {
    return (
        `query{
            monthlyStockOvks(keyword:"${keyword}",limit:${limit},skip:${skip}, month:"${month}", year:"${year}", ovkWarehouse:"${warehouse}" ){
            totalCount
            locked
            calculateMonthlyStocks{
                _id
                idOvk
                idMonthlyStock
                bulan
                tahun
                namaOvk
                satuan
        
                stockAwalBulan
                hargaAwalBulan
                hargaTotalAwalBulan
        
                addStock
                hargaPengadaan
                nilaiPengadaan
        
                outStock
                hargaPengeluaran
                nilaiPengeluaran
                
                qty
                price
                hargaTotalPenyesuaian
        
                sisaStok
                nilaiSisaStok
            }
          }
        }
      `
    )
}