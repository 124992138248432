export const getStokOvk = (keyword, limit, skip) => {
    return (
        `query{
            ovkStocks(keyword: "${keyword}", limit:${limit}, skip:${skip}){
                totalCount
                ovkStocks{
                    _id
                    dateStock
                    sisaStok
                    stockName : name
                    ovk{
                        _id
                        name
                        producer
                        qtyUnit
                        ovkUnit{
                            name
                        }
                        ovkUnitRecording{
                            name
                        }
                    }
                        ovkWarehouse{
                            name
                        }
                    }
                }
            }
        
    `
    );
}

export const getStokGudangOvk = (id) => {
    return (
        `query{
            ovkStocksWarehouse(warehouseId: "${id}"){
                _id
                stockName : name
                sisaStok
                ovk{
                    _id
                    name
                    producer
                    qtyUnit
                    ovkUnitRecording{
                        name
                    }
                    ovkUnit{
                        name
                    }
                    }
                    ovkWarehouse{
                        name
                    }
                }
            }
    `
    )
}

export const getHistoryGudangOvk = (idGudangOvk, type) => {
    return (
        `query{
            ovkTransactionWarehouse(ovkWarehouse: "${idGudangOvk}", type: "${type}"){
                totalCount
                ovkTransactions{
                    _id
                    typeTransaction
                    priceOvkTransaction
                    ttb
                    number
                    numberRecording
                    storeTime
                    house{
                        _id
                        name
                    }
                    ovkStock{
                        _id
                        ovk{
                            _id
                            name
                            ovkUnit{
                                name
                            }
                            ovkUnitRecording{
                                name
                            }
                        }
                        ovkWarehouse{
                            name
                            houses{
                                _id
                                name
                            }
                        }
                    }
                    mutationOvkStock{
                        name
                        ovkWarehouse{
                            name
                            houses{
                                _id
                                name
                            }
                        }
                    }
                }
            }
        }
    `
    )
}

export const getSatuanOvk = (_id) => {
    return (
        `query{
            ovk(_id : "${_id}"){
                _id
                name
                ovkUnit{
                    satuan : name
                }
              }
            }
        `
    );
}

export const getSatuanStockOvk = (_id) => {
    return (
        `query{
            ovkStock(_id: "${_id}"){
            _id
                ovk{
                    value:  _id
                    ovkUnit{
                        satuan : name
                    }
                }
            }
        }
        `
    );
}

export const createStokOvk = (idWarehouse, value) => {
    return {
        query: `mutation{
                createOvkStock(ovkStockInput: {
                    name : "${value.namaPengadaan}"
                    ovk: "${value.ovk.value}"
                    ovkWarehouse: "${idWarehouse}"
                }){
                    _id
              }
            }
        `
    }
}

export const addStokOvk = (value, year, stockId) => {
    return {
        query: `mutation{
                    createOvkTransaction(ovkTransactionInput:{
                        number: ${parseInt(value.jumlahStok)}
                        storeTime: "${year}"
                        ovkStock : "${stockId}"
                        priceOvkTransaction : ${parseFloat(value.harga)}
                        typeTransaction : "In"
                    }){
                        _id
                    }
                }
            `
    }
}


export const addMutationOvk = (value, year, stockId) => {
    return {
        query: `mutation{
                    createOvkTransaction(ovkTransactionInput: {
                        number: ${value.jumlahStok}
                        priceOvkTransaction: ${value.harga}
                        storeTime:"${year}"
                        ovkStock : "${stockId}"
                        ovkWarehouse: "${value.warehouse.value}"
                        typeTransaction: "Mutation",
                    }){
                        _id
                        number
                        priceOvkTransaction
                        storeTime
                        typeTransaction
                        ovkStock{
                            _id
                            ovkWarehouse{
                                name
                            }
                        }
                        mutationOvkStock{
                            _id
                            ovkWarehouse{
                                name
                            }
                        }
                    }
                }
            `
    }
}

export const deleteTransaksiStokOvk = (_id) => {
    return (
        `mutation{
            deleteOvkTransaction(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const detailStokOvk = (_id) => {
    return (`
        query{
            ovkStock(_id: "${_id}"){
            _id
            name
            countOvkTransaction
            ovk{
                value:  _id
            }
            ovkWarehouse{
                value: _id
                name
            }
            }
        }
    `);
}

export const ovkTransaction = (_id) => {
    return (
        `query{
            ovkStock(_id: "${_id}"){
              _id
              ovkTransaction{
                  _id
              }
            }
          }`
    );
}

export const updateStokOvk = (_id, idWarehouse, value) => {
    return {
        query: `mutation{
            updateOvkStock(_id:"${_id}",updateOvkStockInput: {
                name: "${value.namaPengadaan}"
                ovk: "${value.ovk.value}"
                ovkWarehouse: "${idWarehouse}"
              }){
              _id
              }
            }
        `
    }
}

export const deleteStokOvk = (_id) => {
    return (
        `mutation{
            deleteOvkStock(_id : "${_id}"){
              deleted
            }
          }
      `
    );
}

export const getDetailTransaksiStok = (keyword, limit, skip, ovkStok, type) => {
    return (
        `query{
            ovkTransactions(keyword: "${keyword}", limit:${limit}, skip:${skip}, ovkStock : "${ovkStok}", typeTransaction : "${type}"){
              totalCount
              ovk{
                  _id
                  name
                  ovkUnit{
                      name
                  }
              }
              ovkTransactions{
                  _id
                  typeTransaction
                  number
                  storeTime
                  priceOvkTransaction
                  numberRecording
                  ttb
                  house{
                    _id
                    name
                  }
                  ovkStock{
                      ovk{
                          _id
                          name
                          ovkUnit{
                              name
                          }
                      }
                      ovkWarehouse{
                        name
                        houses{
                            name
                        }
                    }
                }
                    mutationOvkStock{
                        _id
                        ovkWarehouse{
                            name
                            houses{
                                name
                            }
                        }
                    }
              }
            }
          }`
    );
}

export const detailTransaksi = (_id) => {
    return (
        `query{
            ovkTransaction(_id: "${_id}"){
               _id
               number
               numberRecording
               storeTime
               ttb
               value : typeTransaction
               priceOvkTransaction
               ovkStock {
                   _id
               }
             }
           }`
    );
}

export const updateTransaksiPenggunaan = (_id, value, ovkStock) => {
    return {
        query:
            `mutation{
            updateOvkTransaction( _id:"${_id}" ,updateOvkTransactionInput: {
                number: ${parseFloat(value.jumlahStok)},
                storeTime: "${value.date_picker}",
                ovkStock : "${ovkStock._id}",
                typeTransaction: "Out",
                }){
                _id
            }
        }`
    }
}

export const updateTransaksiPenambahan = (_id, value, year, ovkStock, stok) => {
    return {
        query:
            `mutation{
            updateOvkTransaction( _id:"${_id}" ,updateOvkTransactionInput: {
                number: ${parseFloat(stok)},
                storeTime: "${year}",
                ovkStock : "${ovkStock._id}",
                ttb : "${value.ttb}",
                typeTransaction: "In",
                priceOvkTransaction : ${parseFloat(value.harga)}
                }){
                _id
            }
        }`
    }
}

