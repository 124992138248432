import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, InputNumber, Select } from 'antd'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { createInitialBalanceLiveBird } from './query-periode'
import { getPeriodeAll } from '../../../rearing-page/rearing-aksi-page/query-rearing'

function CreateInitialBalanceLiveBirdPage(props) {
    const { idMonthlyStockLiveBird, fetchData, setLoading, month, year, locked, t } = props
    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [rearing, setRearing] = useState([])
    const { Option } = Select

    const fetchDataActive = () => {
        API.get(getPeriodeAll('', 0, 0))
            .then(res => {
                setRearing(res.data.data.rearingAll.rearings)
            }).catch((error) => {
                console.log(error)
                setRearing([])
            })
    }
    useEffect(() => {
        fetchDataActive()
    }, [])

    const createData = (values, idMonthlyStockLiveBird) => {
        setLoading(true)
        API.get(createInitialBalanceLiveBird(values, idMonthlyStockLiveBird))
            .then(res => {
                console.log(res)
                if (res.data.data.createInitialBalanceLiveBird._id !== null) {
                    validationMessage('success', `Berhasil menambahkan saldo awal livebird`)
                    fetchData(month, year)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `Gagal menambahkan saldo awal livebird`)
                setLoading(false)
            })
    }
    const handleSubmit = (values) => {
        createData(values, idMonthlyStockLiveBird)
        setVisible(false)
        setLoading(true)
    }
    return (
        <>
            <ButtonDashboard
                text={t('general.add')}
                disabled={locked ? true : false}
                height={20}
                backgroundColor={locked ? '#f5f5f5' : "#008ad4"}
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => setVisible(true)}
            />
            <Modal
                visible={visible}
                title={t('general.add')}
                okText={t('general.add')}
                width={800} centered
                cancelText={t('general.cancel')}
                onCancel={() => setVisible(false)}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content">{t('pages.stockflow.period')}</span>
                            <Form.Item style={{ marginBottom: 0 }}
                                name="rearing" rules={[{ required: true, message: `${t('pages.stockflow.phPeriod')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.stockflow.phPeriod')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                >
                                    {
                                        rearing.map(data =>
                                            <Option
                                                key={data.house.warehouseName + ' ' + data.name.toString()}
                                                value={data._id}
                                            >{data.house.warehouseName} {data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stockflow.qty')}</span>
                            <Form.Item
                                name="bgnQty" rules={[{ required: true, message: `${t('pages.stockflow.msgQty')}` }]}
                            >
                                <InputNumber
                                    placeholder="5000..."
                                    className="input-number mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stockflow.price')}</span>
                            <Form.Item
                                name="bgnPrice" rules={[{ required: true, message: `${t('pages.stockflow.msgPrice')}` }]}
                            >
                                <InputNumber
                                    placeholder="7000..."
                                    className="input-number mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateInitialBalanceLiveBirdPage)
export default page