export const START_GET_DATA = '@ovk/start-get-data';
export const FINISH_GET_DATA = '@ovk/start-finish-data';
export const SET_DATA_OVK_TYPE = '@ovk/set_data_ovk_type';
export const SET_DATA_OVK_SHAPE = '@ovk/set_data_ovk_shape';
export const SET_DATA_OVK_MEDIA = '@ovk/set_data_ovk_media';
export const SET_DATA_OVK_UNIT = '@ovk/set_data_ovk_unit';
export const SET_DATA_OVK = '@ovk/set_data_ovk';
export const SET_DETAIL_OVK = '@ovk/set_detail_ovk';
export const SET_ID_OVK = '@ovk/set_id_ovk';
export const SET_DATA_OVK_UNIT_RECORDING = '@ovk/set_data_ovk_unit_recording';
