import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Button, Input, Tooltip, Modal } from 'antd'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import DetailPakanComponent from '../../../../modules/dashboard-peternak/rearing-record-component/detail-rearing-record-component/detail-pakan-component'
import { updateRecordPakan, getDetailRecordPakan, deleteRecordPakan } from '../rearing-record-aksi-page/query-rearing-record'
import { API } from '../../../../common/api'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

function DetailPakanPage(props) {
    const { dateRearing, pakan, fetchDataPakan, setLoading, loading, idRearingRecord, houseActive, profil, t } = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [idData, setIdData] = useState(null)
    const [detail, setDetail] = useState("")

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(getDetailRecordPakan(idData))
                    .then(res => {
                        setDetail(res.data.data.feeding)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showEdit = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = async (values) => {
        await API.post(updateRecordPakan(idData, values, dateRearing))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.recording.feed.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.updateFeeding._id) {
                    errMessage('success', `${t('pages.recording.feed.resEditSuccess')}`)
                    fetchDataPakan(idRearingRecord)
                }
            }).catch((error) => {
                console.log(error)
                errMessage('error', `${t('pages.recording.feed.resEditFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deleteRecordPakan(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteFeeding.deleted === true) {
                    errMessage('success', `${t('pages.recording.feed.resDelSuccess')}`)
                    fetchDataPakan(idRearingRecord);
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.recording.feed.resDelFailed')}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t('pages.recording.feed.delConfirm')}`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const columns = [
        {
            title: `${t('pages.recording.feed.stock')}`,
            dataIndex: 'stok',
            key: 'stok',
            ...getColumnSearchProps('stok'),
        },
        {
            title: `${t('pages.recording.feed.title')}`,
            dataIndex: 'pakan',
            key: 'pakan',
            ...getColumnSearchProps('pakan'),
        },
        {
            title: `${t('pages.recording.feed.qty')}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            align: 'right',
        },
        {
            title: `${t('pages.recording.feed.qtyWeight')}`,
            dataIndex: 'berat',
            key: 'berat',
            align: 'right',
        },
        profil.permission.nominalRecording ? {
            title: `${t('pages.recording.feed.price')}`,
            dataIndex: 'price',
            key: 'price',
            align: 'right',
        } : { width: 1 },
        profil.permission.nominalRecording ? {
            title: `Total`,
            dataIndex: 'nilai',
            key: 'nilai',
            align: 'right',
        } : { width: 1 },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 130,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button disabled={houseActive ? false : true}
                            hidden={profil.permission.editRecording ? false : true}
                            style={{
                                color: 'white',
                                backgroundColor: houseActive ? '#FFA903' : 'whitesmoke',
                                borderRadius: '5px'
                            }} onClick={() => showEdit(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button disabled={houseActive ? false : true}
                            hidden={profil.permission.writeRecording ? false : true}
                            style={{
                                color: 'white',
                                backgroundColor: houseActive ? '#FF0303' : 'whitesmoke',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = pakan.map(({ _id, number, numberWeight, priceFeeding, total, feedStock }, index) => ({
        key: _id,
        stok: feedStock.name,
        pakan: feedStock.feed.name,
        total: total,
        berat: <NumberFormat value={numberWeight} displayType={'text'} thousandSeparator={true} suffix={` kg`} />,
        jumlah: <NumberFormat value={number} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.recording.feed.sack')}`} />,
        price: <NumberFormat value={priceFeeding} displayType={'text'} thousandSeparator={true} prefix={`Rp`} />,
        nilai: <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={`Rp`} />,
    }))

    return (
        <DetailPakanComponent
            columns={columns}
            loading={loading}
            data={data}
            setLoading={setLoading}
            showEdit={showEdit}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            visible={visible}
            detail={detail}
            t={t} profil={profil}
        />
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord,
    dateRearing: state.rearingRecord.dateRearingRecord,
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailPakanPage)
export default page