import * as actionType from './rearing-record-action-type'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'

const startGetDataRearingRecords = () => ({
    type: actionType.START_GET_DATA
});

const finishGetDataRearingRecords = () => ({
    type: actionType.FINISH_GET_DATA
})

const setIdRearingRecord = payload => ({
    type: actionType.SET_ID_REARING_RECORD,
    payload,
})
const setMonthPeriode = payload => ({
    type: actionType.SET_MONTH_PERIODE,
    payload,
})
const setYearPeriode = payload => ({
    type: actionType.SET_YEAR_PERIODE,
    payload,
})
const setStartPeriode = payload => ({
    type: actionType.SET_START_PERIODE,
    payload,
})
const setEndPeriode = payload => ({
    type: actionType.SET_END_PERIODE,
    payload,
})

const setDateRearingRecord = payload => ({
    type: actionType.SET_DATE_REARING_RECORD,
    payload,
})
const setDataAgeRearingRecord = payload => ({
    type: actionType.SET_AGE_REARING_RECORD,
    payload,
})
const setDataGrowingParams = payload => ({
    type: actionType.SET_GROWING_PARAMS,
    payload,
})
const setDetailDiseaseRecord = payload => ({
    type: actionType.SET_DETAIL_DISEASE,
    payload
})
const setIdDiseaseRecord = payload => ({
    type: actionType.SET_ID_DISEASE,
    payload
})
const setDataLiveBirdPrice = payload => ({
    type : actionType.SET_LIVE_BIRD_PRICE,
    payload,
})
export const getIdRearingRecord = (id_stok) => (dispatch) => {
    dispatch(setIdRearingRecord(id_stok))
}
export const getMonthPeriode = (id) => (dispatch) => {
    dispatch(setMonthPeriode(id))
}
export const getYearPeriode = (id) => (dispatch) => {
    dispatch(setYearPeriode(id))
}
export const getStartPeriode = (id) => (dispatch) => {
    dispatch(setStartPeriode(id))
}
export const getEndPeriode = (id) => (dispatch) => {
    dispatch(setEndPeriode(id))
}
export const getDateRearingRecord = (id_stok) => (dispatch) => {
    dispatch(setDateRearingRecord(id_stok));
}

export const getLiveBirdPrice = (price) => (dispatch) => {
    dispatch(setDataLiveBirdPrice(price))
}
export const getAgeRearingRecord = (age) => (dispatch) => {
    dispatch(setDataAgeRearingRecord(age))
}
export const getIdDiseaseRecord = (id) => (dispatch) => {
    dispatch(setIdDiseaseRecord(id))
}
export const getDetailDiseaseRecord = (params) => (dispatch) => {
    dispatch(startGetDataRearingRecords())
    API.get(params)
        .then(res => {
            if (res === undefined) { validationMessage('error', 'Connection error') }
            else if (res.data.data.diseaseRecord) {
                dispatch(setDetailDiseaseRecord(res.data.data.diseaseRecord))
            } else {
                validationMessage('error', 'Login Gagal')
            }
            dispatch(finishGetDataRearingRecords())
        }).catch(e => {
            validationMessage('error', e.message)
        })
}
export const getListGrowingParams = (params) => (dispatch) => {
    dispatch(startGetDataRearingRecords());
    API.get(params)
        .then(res => {
            if (res === undefined) { validationMessage('error', 'Connection error') }
            else if (res.data.data.growingParams.growingParams) {
                dispatch(setDataGrowingParams(res.data.data.growingParams.growingParams))
            } else {
                validationMessage('error', 'Login Gagal')
            }
            dispatch(finishGetDataRearingRecords())
        }).catch(e => {
            validationMessage('error', e.message)
        })
}