import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../common/store/action'
import LoginComponent from '../../modules/auth/component/login-component'
import { validationMessage } from '../../common/component/notification/notification'
import { handleLogin, handleLoginOauth } from '../../modules/auth/store/auth-action'
import { useTranslation } from "react-i18next"
import i18n from '../../translations/i18n'

function LoginPage(props) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const { t } = useTranslation()
    const [lang] = useState(i18n.language)

    const handleLocales = useCallback((e) => {
        if (i18n.language !== e) {
            i18n.changeLanguage(e)
        }
    }, [])

    const responseGoogle = (value) => {
        const reqBody = {
            query: `query{
                    loginOAuth( type: "google",token: "${value.accessToken}"){
                        userId
                        email
                        registered
                        token
                        type
                        loginType
                        typeUser
                        tokenExpiration
                        foto
                        cities
                        createdAt
                    }
                }`
        }
        if (value.accessToken) {
            props.handleLoginOauth(reqBody)
        } 
    }

    const responseFb = (value) => {
        const reqBody = {
            query: `query{
                    loginOAuth( type: "facebook",token: "${value.accessToken}"){
                        userId
                        email
                        registered
                        token
                        type
                        loginType
                        typeUser
                        tokenExpiration
                        foto
                        cities
                        createdAt
                    }
                }`,
        }
        if (value.accessToken) {
            props.handleLoginOauth(reqBody)
        } 
    }

    const responseApple = (value) => {
        console.log(value)
        const reqBody = {
            query: `query{
                    loginOAuth( type: "apple"){
                        userId
                        email
                        registered
                        token
                        type
                        loginType
                        typeUser
                        tokenExpiration
                        foto
                        cities
                        createdAt
                    }
                }`,
        }
            props.handleLoginOauth(reqBody)
    }

    const handleSubmit = (value) => {
        const query = {
            query:
                `query{
                  login(email:"${value.username}",password:"${value.password}"){
                    token
                    name
                    userId
                    loginType
                    typeUser
                    type
                    foto
                    cities
                    tokenExpiration
                    permission{
                        readUsers
                        writeUsers
                        readMaster
                        writeMaster
                        readStock
                        writeStock
                        readStockflow
                        writeStockflow
                        readRearing
                        writeRearing
                        readRecording
                        writeRecording
                        nominalRecording
                        staffMode
                        editRecording
                        readDashboard
                        readIot
                        writeIot
                        createSubscription
                    }
                  }
                }
            `,
        }
        if (username !== null) {
            props.handleLogin(query)
        } else {
            validationMessage('error', 'Data Masih Kosong!')
        }
    }

    return (
        <LoginComponent
            navigate={props.navigate}
            loading={props.loading}
            username={username}
            password={password}
            setUsername={setUsername}
            setPassword={setPassword}
            handleSubmit={handleSubmit}
            responseFb={responseFb}
            responseGoogle={responseGoogle}
            responseApple={responseApple}
            t={t} lang={lang}
            handleLocales={handleLocales}
        />
    )
}

const mapStateToProps = state => ({
    loading: state.auth.loading,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    handleLogin,
    handleLoginOauth,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(LoginPage)
export default page