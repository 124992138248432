import React, { useState, useEffect } from 'react'
import { API } from '../../../../common/api'
import { connect } from 'react-redux'
import { Modal, Tooltip, Typography, Row, Col, Card, Form, Button, List, Divider, Upload, Breadcrumb, Layout } from 'antd'
import { Link } from 'react-router-dom'
import { navigate } from '../../../../common/store/action'
import { DeleteOutlined } from '@ant-design/icons'
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import { deletePicPenyakit, getDetailRecordPenyakit } from '../rearing-record-aksi-page/query-rearing-record'
import CONSTANTS from '../../../../common/utils/Constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next"
import Loading from '../../../../common/component/loading/loading-container'

function CreatePenyakitPage(props) {
    const { idDiseaseRecord, age, navigate } = props
    const [fileList, setFileList] = useState([])
    const [fileUpload, setFileUpload] = useState([])
    const [detailFoto, setDetailFoto] = useState([])
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const { confirm } = Modal
    const { Paragraph } = Typography
    const { Content } = Layout

    const postData = (_id, fileUpload) => {
        const params = new FormData()
        if (fileUpload) {
            for (let i = 0; i < fileUpload.length; i++) {
                params.append(`file[${i}]`, fileUpload[i])
            }
        }
        setLoading(true)
        API.updateRest(`/pic-penyakit/${_id}`, params)
            .then(res => {
                if (res.status === 200) {
                    if (res.data.success === true) {
                        validationMessage('success', `${t("pages.recording.disease.resAddPicSuccess")}`)
                        fetchDetailPenyakit(idDiseaseRecord)
                        setFileList([])
                        setFileUpload([])
                        setLoading(false)
                    } else {
                        validationMessage('error', `${t("pages.recording.disease.resAddPicFailed")}`)
                        setLoading(false)
                    }
                } else {
                    validationMessage('error', `${t("pages.recording.disease.resAddPicFailed")}`)
                    setLoading(false)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.recording.disease.resAddPicFailed")}`, error.message)
                setLoading(false)
            })
    }
    const handleSubmit = () => {
        postData(idDiseaseRecord, fileUpload)
    }
    const propsPicture = {
        name: 'file',
        multiple: true,
        onChange(info) {
            if (info.file.size > 3000000) {
                errMessage('error', 'Ukuran file harus lebih kecil dari 3 MB!')
            } else
            if (info.file.status === 'done') {
                let arrayPicture = []
                for (let i = 0; i < info.fileList.length; i++) {
                    arrayPicture.push(info.fileList[i].originFileObj)
                }
                setFileList(info.fileList)
                setFileUpload(arrayPicture)
            } else if (info.file.status === 'error') {
                console.log('err', info.file, info.fileList)
            }
        }
    }
    const onPreview = async file => {
        let src = file.url
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }
        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow.document.write(image.outerHTML)
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok")
        }, 0)
    }
    const fetchDetailPenyakit = (idDiseaseRecord) => {
        setLoading(true)
        API.get(getDetailRecordPenyakit(idDiseaseRecord))
            .then(res => {
                setDetailFoto(res.data.data.diseaseRecord.pictureDisease)
                setLoading(false)
            }).catch((error) => {
                setDetailFoto([])
                setLoading(false)
            })
    }

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deletePicPenyakit(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t("pages.recording.disease.resDelPicFailed")}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.deletePicDisease.deleted === true) {
                    validationMessage('success', `${t("pages.recording.disease.resDelPicSuccess")}`)
                    fetchDetailPenyakit(idDiseaseRecord)
                    setFileList([])
                    setFileUpload([])
                    setLoading(false)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.disease.resDelPicFailed")}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t("pages.recording.disease.delPicConfirm")}`,
            onOk: () => { deleteData(_id) },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    useEffect(() => {
        if (idDiseaseRecord) {
            fetchDetailPenyakit(idDiseaseRecord)
        }
        // eslint-disable-next-line
    }, [idDiseaseRecord])

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to="/peternak/recording"><span>{t("pages.recording.title")}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/peternak/recording/detail"><span>{t("pages.recording.detail")}{age}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{t("pages.recording.disease.edit")}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Loading loading={loading}>
                    <Card>
                        <List
                            dataSource={detailFoto}
                            grid={{ gutter: 16, column: 4 }}
                            renderItem={item => (
                                <List.Item>
                                    <div className='ant-card ant-card-bordered'>
                                        <div className='ant-card-body' style={{ padding: 0 }}>
                                            <div className='ant-image'>
                                                <img className='ant-image-img'
                                                    alt={item.originalName}
                                                    src={item.linkPicture}
                                                    style={{ objectFit: "cover", height: 150 }}
                                                />
                                            </div>
                                        </div>
                                        <Divider style={{ margin: 0 }} />
                                        <Row gutter={16} style={{ padding: 5 }}>
                                            <Col span={12}>
                                                <Tooltip placement="top" title={item.originalName}>
                                                    <Paragraph ellipsis={{ rows: 1, expandable: false }}>{item.originalName}</Paragraph>
                                                </Tooltip>
                                            </Col>
                                            <Col span={12}>
                                                <Button style={{
                                                    color: 'white',
                                                    backgroundColor: '#30c758',
                                                    borderRadius: '5px'
                                                }}
                                                    onClick={() => window.open(item.linkPicture, '_blank')}
                                                ><FontAwesomeIcon icon={faEye} /></Button>
                                                <Tooltip placement="top" title={t("general.del")}>
                                                    <Button style={{
                                                        color: 'white',
                                                        backgroundColor: '#FF0303',
                                                        borderRadius: '5px'
                                                    }}
                                                        onClick={() => hapusConfirm(item._id)}
                                                    ><FontAwesomeIcon icon={faTrash} /></Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </div>
                                </List.Item>
                            )}
                        />
                        <Divider />
                        <Form onFinish={handleSubmit}>
                            <Form.Item name="fotoPenyakit" style={{ marginBottom: 0 }}
                                rules={[{ required: true, message: `${t("pages.recording.disease.msgPic")}` }]}>
                                <Upload {...propsPicture}
                                    customRequest={dummyRequest}
                                    fileList={fileList} multiple
                                    listType="picture-card"
                                    onPreview={onPreview}
                                >
                                    {fileList.length < 5 && '+ Upload'}
                                </Upload>
                            </Form.Item>
                            <Button
                                onClick={() => navigate(CONSTANTS.RECORDING_MENU_KEY)}
                            >
                                {t('general.cancel')}
                            </Button>
                            <Button
                                style={{ marginLeft: 8, marginTop: 0 }}
                                type="primary"
                                htmlType="submit"
                            >
                                {t('general.add')}
                            </Button>
                        </Form>
                    </Card>
                </Loading>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord,
    idDiseaseRecord: state.rearingRecord.idDiseaseRecord,
    penyakit: state.penyakit.dataPenyakit,
    age: state.rearingRecord.age
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePenyakitPage)
export default page