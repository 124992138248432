import React from 'react'
import { Modal, Form, Row, Col, InputNumber, Select } from 'antd'

function EditPakan(props) {
    const { visible, handleSubmit, data, handleCancel, t } = props
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.recording.bw.edit')}
                okText={t('general.edit')}
                width={500}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields();
                            handleSubmit(values);
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info);
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        _id: data._id,
                        nilai: data.value,
                        procentage: data.procentage,
                        type: data.type,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">Prosentase ayam</span><br />
                            <Form.Item
                                name='procentage'
                                rules={[
                                    { required: true, message: `Masukkan nilai prosentase` },
                                ]}
                            >
                                <InputNumber
                                    placeholder="100..."
                                    className="input-number"
                                    type='number'
                                    precision={0}
                                    addonAfter='%'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">Jenis ayam</span>
                            <Form.Item className=''
                                name='type'
                                rules={[{ required: true, message: `Masukkan jenis ayam` }]}
                            >
                                <Select
                                    showSearch
                                    placeholder='Jenis ayam'
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    <Select.Option value='rooster'>Jantan</Select.Option>
                                    <Select.Option value='hen'>Betina</Select.Option>
                                    <Select.Option value='big'>Besar</Select.Option>
                                    <Select.Option value='medium'>Sedang</Select.Option>
                                    <Select.Option value='small'>Kecil</Select.Option>
                                    <Select.Option value='tiny'>Sangat Kecil</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.bw.weight')}</span>
                            <Form.Item
                                name="nilai" rules={[{ required: true, message: `${t('pages.recording.bw.msgWeight')}` }]}
                            >
                                <InputNumber
                                    placeholder="1000..."
                                    className="input-number"
                                    addonAfter='gr'
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    };
    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}

export default EditPakan;