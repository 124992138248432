import React from 'react'
import { Layout, Row, Col, Form, Input, Select, Checkbox } from 'antd'
import InputAuth from '../../../common/component/input/input-auth'
import InputPassword from '../../../common/component/input/input-password'
import InputMaps from '../../../common/component/input/input-maps'
import '../../../assets/scss/auth/register.scss'
import Loading from '../../../common/component/loading/loading-container'
import ReCAPTCHA from "react-google-recaptcha"
import ButtonText from '../../../common/component/button/button-text'
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { GoogleLogin } from "react-google-login"
// import AppleLogin from 'react-apple-login'
import { Link } from 'react-router-dom'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import Autocomplete from "react-google-autocomplete"



function RegisterComponent(props) {
    const { Content } = Layout
    const { Option } = Select
    const { handleSubmit, handleFailed, setRecaptchaRef, onChange, responseFb, responseGoogle,
        loading, nama, email, password, oauth, t, lang, handleLocales } = props
    const logo = require(`../../../assets/images/logo.png`)
    const [form] = Form.useForm()

    const [state, setState] = React.useState({
        isMap: false,
        position: null,
        zoom: 10,
        center: {
            lat: -7.788319728345056,
            lng: 110.36681604046649,
        },
        isMapHouse: false,
        positionHouse: null,
        zoomHouse: 10,
        centerHouse: {
            lat: -7.788319728345056,
            lng: 110.36681604046649,
        },
    })

    return (
        <Layout style={{ width: "100%" }}>
            <Content className="landing-container" >
                <Loading loading={loading}>
                    <Row>
                        <Col span={20} offset={2}>
                            <div className="section-logo-register">
                                <img src={logo} alt="BroilerX logo" className="section-picture" />
                            </div>
                        </Col>
                        <Col span={2}>
                            <span className="float-right" style={{ marginTop: '.5rem', marginRight: '.5rem' }}>
                                <Select defaultValue={lang} bordered={false} onChange={(e) => handleLocales(e)}>
                                    <Option value="id">ID</Option>
                                    <Option value="en">EN</Option>
                                </Select>
                            </span>
                        </Col>
                        <Col span={24} className="section-title-register">
                            <span className="font-title-login black font-bold">{t('pages.auth.register')}</span>
                            <br />
                        </Col>
                        <Col span={24}>
                            <div className="mb-10 mt-10 section-title-register">
                                <span className="font-medium font-title-content black">{t('pages.auth.regisOpt')}</span>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ overflow: "hidden" }}>
                        <Row gutter={[16, 16]}>
                            <Col lg={{ span: 4, offset: 8 }} xs={{ span: 10, offset: 2 }} >
                                <FacebookLogin
                                    appId="2784727991754309"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={responseFb}
                                    render={(renderProps) => (
                                        <ButtonText
                                            text="Facebook"
                                            height={10}
                                            icon={faFacebook}
                                            backgroundColor="#3b5998"
                                            borderRadius="5px"
                                            className="font-semi-regular font-title-small button-facebook"
                                            textColor="white"
                                            onClick={renderProps.onClick}
                                        />
                                    )}
                                />
                            </Col>
                            <Col lg={{ span: 4 }} xs={{ span: 10 }}>
                                <GoogleLogin
                                    clientId="1007665415987-nvc7uki0qsn7atup3k14tjpfnuhucq2l.apps.googleusercontent.com"
                                    render={(renderProps) => (
                                        <ButtonText
                                            text="Google"
                                            height={10}
                                            icon={faGoogle}
                                            backgroundColor="#D44638"
                                            borderRadius="5px"
                                            className="font-semi-regular font-title-small button-facebook"
                                            textColor="white"
                                            onClick={renderProps.onClick}
                                        />
                                    )}
                                    buttonText={t('pages.auth.login')}
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                    cookiePolicy={"single_host_origin"}
                                />
                            </Col>
                            {/* <Col lg={6} xs={7} hidden>
                                <AppleLogin
                                    clientId="com.broilerx.dev"
                                    redirectURI="https://saas.broilerx.com/apple/response"
                                    callback={responseApple}
                                    responseType='code id_token'
                                    responseMode="query"
                                    scope="email name"
                                    usePopup={true}
                                    render={(renderProps) => (
                                        <ButtonText
                                            text="Apple"
                                            height={10}
                                            icon={faApple}
                                            backgroundColor="#1d1d1f"
                                            borderRadius="5px"
                                            className="font-semi-regular font-title-small button-facebook"
                                            textColor="white"
                                            onClick={renderProps.onClick}
                                        />
                                    )}
                                />
                            </Col> */}
                        </Row>
                    </div>
                    <Row>
                        <Col span={24} className="section-content-register">
                            <p className="font-title-medium black font-light mt-10" style={{ textAlign: 'center' }}>{t('pages.auth.registerDesc')}</p>
                            <Form onFinishFailed={handleFailed} name="basic"
                                form={form}
                                onFinish={handleSubmit}
                                initialValues={{ remember: true, namaLengkap: nama, email: email, password: password }}>
                                <Row gutter={[16]} >
                                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.name')}</span>
                                        {oauth ? (
                                            <Input
                                                name='namaLengkap'
                                                placeholder={t('pages.auth.phName')}
                                                className="input-register"
                                                value={nama}
                                                disabled={true}
                                                cyName="name"
                                            />
                                        ) : (
                                            <Form.Item
                                                name="namaLengkap" hasFeedback
                                                rules={[{ required: true, message: `${t('pages.auth.msgName')}` }]}
                                            >
                                                <InputAuth
                                                    name='namaLengkap'
                                                    placeholder={t('pages.auth.phName')}
                                                    className="input-register"
                                                    cyName="name"
                                                />
                                            </Form.Item>
                                        )}
                                    </Col>
                                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.email')}</span>
                                        {oauth ? (
                                            <Input
                                                name='email' type='email'
                                                placeholder={t('pages.auth.phName')}
                                                className="input-register"
                                                value={email}
                                                disabled={true}
                                                cyName="email"
                                            />
                                        ) : (
                                            <Form.Item
                                                name="email" hasFeedback
                                                rules={[{ required: true, type: 'email', message: `${t('pages.auth.msgEmail')}` }]}
                                            >
                                                <InputAuth
                                                    placeholder={t('pages.auth.phEmail')}
                                                    className="input-register"
                                                    cyName="email"
                                                />
                                            </Form.Item>
                                        )}
                                    </Col>
                                </Row>
                                <Row gutter={[16]} className={oauth ? 'mt-20' : ''}>
                                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.username')}</span>
                                        <Form.Item
                                            name="username" hasFeedback
                                            rules={[{ required: true, min: 4, message: `${t('pages.auth.msgUsername')}` }]}
                                        >
                                            <InputAuth
                                                name='username'
                                                placeholder={t('pages.auth.phUsername')}
                                                className="input-register"
                                                cyName="username"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.password')}</span>
                                        {oauth ? (
                                            <InputPassword
                                                name='password'
                                                placeholder={t('pages.auth.phPassword')}
                                                className="input-register"
                                                value={password}
                                                disabled={true}
                                                cyName="password"
                                            />
                                        ) : (
                                            <Form.Item hasFeedback
                                                name="password" rules={[{ required: true, min: 6, message: `${t('pages.auth.msgPassword')}` }]}
                                            >
                                                <InputPassword
                                                    placeholder={t('pages.auth.phPassword')}
                                                    className="input-register"
                                                    cyName="password"
                                                />
                                            </Form.Item>
                                        )}
                                    </Col>
                                </Row>
                                <Row gutter={[16]}>
                                    <Col span={24}>
                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.phone')}</span>
                                        <Form.Item
                                            name="nomorTelefon" hasFeedback
                                            rules={[{ required: true, message: `${t('pages.auth.msgPhone')}` }]}
                                        >
                                            <InputAuth
                                                name='nomorTelefon'
                                                placeholder={t('pages.auth.phPhone')}
                                                className="input-register"
                                                cyName="phone"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.address')}</span>
                                        <Form.Item
                                            name="alamat"
                                            rules={[
                                                {
                                                    required: true, message: `${t('pages.auth.msgAddress')}`,
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {

                                                        if (getFieldValue('position') !== undefined) {
                                                            return Promise.resolve()
                                                        }

                                                        return Promise.reject(new Error(state.isMap ? 'Mohon pilih lokasi di Maps!' : t('pages.auth.msgIdAddr')))
                                                    },
                                                })
                                            ]}
                                            dependencies={['position']}
                                            hasFeedback
                                        >
                                            {!state.isMap && <Autocomplete
                                                placeholder={t('pages.auth.phAddress')}
                                                className='ant-input input-register'
                                                cyName="address"
                                                apiKey={process.env.REACT_APP_GOOGLE_TOKEN}
                                                onPlaceSelected={place => {
                                                    form.setFieldsValue({ position: place.geometry.location.toJSON(), alamat: place.formatted_address })
                                                }}
                                                language={lang}
                                                options={{
                                                    types: ["geocode", "establishment"],
                                                }}
                                            />}
                                            {state.isMap &&
                                                <InputMaps
                                                    center={state.center} zoom={state.zoom}
                                                    onClick={(e) => {
                                                        setState((prev) => ({
                                                            ...prev,
                                                            position: e.latLng,
                                                        }))
                                                        form.setFieldsValue({ position: e.latLng.toJSON() })
                                                    }}
                                                    onIdle={e =>
                                                        setState((prev) => ({
                                                            ...prev,
                                                            zoom: e.getZoom(),
                                                            center: e.getCenter().toJSON()
                                                        }))
                                                    }
                                                    position={state.position}
                                                    onChange={(e) => form.setFieldsValue({ alamat: e.target.value })}
                                                    placeholder={t('pages.auth.phAddress')}
                                                />}
                                            <Link className='mt-5'
                                                onClick={() => {
                                                    setState((prev) => ({
                                                        ...prev,
                                                        isMap: !state.isMap,
                                                        position: null
                                                    }))
                                                    form.resetFields(['position', 'alamat'])
                                                }}>
                                                {state.isMap ?
                                                    'Cari menggunakan pencarian alamat' :
                                                    'Tidak menemukan alamat yang dicari? Coba pilih lokasi menggunakan Maps'
                                                }
                                            </Link>
                                        </Form.Item>
                                    </Col>

                                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.company')}</span>
                                        <Form.Item hasFeedback
                                            name="perusahaan" rules={[{ required: true, message: `${t('pages.auth.msgCompany')}` }]}
                                        >
                                            <InputAuth
                                                cyName="company"
                                                placeholder={t('pages.auth.phCompany')}
                                                className="input-register"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.companyType')}</span>
                                        <Form.Item hasFeedback
                                            name="jenisUsaha" rules={[{ required: true, message: `${t('pages.auth.msgCompanyType')}` }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder={t('pages.auth.phCompanyType')}
                                                optionFilterProp="children"
                                                size="large"
                                                style={{ width: '100%' }}
                                                className="select-register black font-regular"
                                                cyName="companyType"
                                            >
                                                <Option value="kemitraan">{t('pages.auth.kemitraan')}</Option>
                                                <Option value="mandiri">{t('pages.auth.mandiri')}</Option>
                                                <Option value="riset">{t('pages.auth.riset')}</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.house')}</span>
                                        <Form.Item hasFeedback
                                            name="house" rules={[{ required: true, message: `${t('pages.auth.msgHouse')}` }]}
                                        >
                                            <InputAuth
                                                placeholder={t('pages.auth.phHouse')}
                                                className="input-register"
                                                cyName="house"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }} xs={{ span: 24 }}>
                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.capacity')}</span>
                                        <Form.Item hasFeedback
                                            name="capacity" rules={[{ required: true, message: `${t('pages.auth.msgCapacity')}` }]}
                                        >
                                            <Input
                                                placeholder="2500.." type='number'
                                                className="input-register" suffix={t('pages.rearing.chicken')}
                                                style={{ height: '39px' }}
                                                cyName="capacity"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.houseAddr')}</span>
                                        <Form.Item
                                            name="houseAddr"
                                            rules={[
                                                { required: true, message: `${t('pages.auth.msgHouseAddr')}` },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (getFieldValue('positionHouse') !== undefined) {
                                                            return Promise.resolve()
                                                        }

                                                        return Promise.reject(new Error(t('pages.auth.msgIdAddr')))
                                                    },
                                                })
                                            ]}
                                            dependencies={['positionHouse']}
                                            hasFeedback
                                        >
                                            {!state.isMapHouse && <Autocomplete
                                                placeholder={t('pages.auth.phHouseAddr')}
                                                className='ant-input input-register'
                                                apiKey={process.env.REACT_APP_GOOGLE_TOKEN}
                                                cyName="houseAddress"
                                                onPlaceSelected={place => {
                                                    form.setFieldsValue({ positionHouse: place.geometry.location.toJSON(), houseAddr: place.formatted_address })
                                                }}
                                                options={{
                                                    types: ["geocode", "establishment"],
                                                }}
                                            />}
                                            {state.isMapHouse &&
                                                <InputMaps
                                                    center={state.centerHouse} zoom={state.zoomHouse}
                                                    onClick={(e) => {
                                                        setState((prev) => ({
                                                            ...prev,
                                                            positionHouse: e.latLng,
                                                        }))
                                                        form.setFieldsValue({ positionHouse: e.latLng.toJSON() })
                                                    }}
                                                    onIdle={e =>
                                                        setState((prev) => ({
                                                            ...prev,
                                                            zoomHouse: e.getZoom(),
                                                            centerHouse: e.getCenter().toJSON()
                                                        }))
                                                    }
                                                    position={state.positionHouse}
                                                    onChange={(e) => form.setFieldsValue({ houseAddr: e.target.value })}
                                                    placeholder={t('pages.auth.phAddress')}
                                                />}
                                            <Link className='mt-5'
                                                onClick={() => {
                                                    setState((prev) => ({
                                                        ...prev,
                                                        isMapHouse: !state.isMapHouse,
                                                        positionHouse: null
                                                    }))
                                                    form.resetFields(['positionHouse', 'houseAddr'])
                                                }}>
                                                {state.isMapHouse ?
                                                    'Cari menggunakan pencarian alamat' :
                                                    'Tidak menemukan alamat yang dicari? Coba pilih lokasi menggunakan Maps'
                                                }
                                            </Link>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }} xs={{ span: 24 }} hidden>
                                        <Form.Item name="position" hasFeedback>
                                            <Input className="input-register" />
                                        </Form.Item>
                                        <Form.Item name="positionHouse" hasFeedback>
                                            <Input className="input-register" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[16]}>
                                    <Col span={24}>
                                        <ReCAPTCHA
                                            style={{ marginTop: "10px", marginBottom: "10px" }}
                                            sitekey="6Ld9yt8UAAAAAAp9_NQFZwt18O5x0aDVYpmQtV-m"
                                            onChange={(e) => setRecaptchaRef(e)}
                                            cyName="recaptcha"
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[16]}>
                                    <Col span={24}>
                                        <Checkbox className="font-medium black font-title-content" onChange={onChange}>
                                            <span className="font-medium black font-title-content">{t('pages.auth.agree')}
                                                <a href="https://broilerx.com/term" target='_blank' rel="noopener noreferrer">{t('pages.auth.term')}</a></span></Checkbox>
                                    </Col>
                                </Row>

                                <Row gutter={[16]}>
                                    <Col span={24}>
                                        <Form.Item>
                                            <ButtonText
                                                text={t('pages.auth.register')}
                                                height={20}
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-bold font-title-medium button-register"
                                                textColor="white"
                                                htmlType="submit"
                                                cyName="btnRegister"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16]}>
                                    <Col span={24}>
                                        <div className="section-login-register">
                                            <span className="font-medium font-title-content black">
                                                {t('pages.auth.loginMsg')}
                                                <Link to="/"> {t('pages.auth.loginCta')}</Link></span>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    )
}

export default RegisterComponent