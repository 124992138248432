import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import { Modal, Form, Row, Col, Input, Select } from 'antd'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { createDoc } from './query-doc'
import { getStrain } from '../../strain-page/strain-aksi-page/query-strain'

function CreateDocPage(props) {
    const { fetchData, setLoading, role, t } = props
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()
    const { Option } = Select
    const [strain, setStrain] = useState([])

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = async (values, type) => {
        await API.post(createDoc(values, type))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.master.doc.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.createDoc._id) {
                    validationMessage('success', `${t('pages.master.doc.resAddSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.master.doc.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const fetchDataStrain = () => {
        API.get(getStrain())
            .then(res => {
                setStrain(res.data.data.strains.strains)
            }).catch((error) => {
                console.log(error)
                setStrain(null)
            })
    }
    useEffect(() => {
        fetchDataStrain()
    }, [])

    const handleSubmit = (values) => {
        let type = role === 'superadmin' ? 'primary' : 'secondary'
        postData(values, type)
        setVisible(false)
        setLoading(true)
    };

    return (
        <>
            <ButtonDashboard
                text={t('pages.master.doc.add')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <Modal
                visible={visible}
                title={t('pages.master.doc.add')}
                okText={t('general.add')}
                width={500}
                cancelText={t('general.cancel')}
                onCancel={() => setVisible(false)}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.doc.name')}</span>
                            <Form.Item
                                name="name" rules={[{ required: true, message: `${t('pages.master.doc.msgName')}` }]}
                            >
                                <Input
                                    placeholder="DOC 1..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.doc.corporation')}</span>
                            <Form.Item
                                name="corporation" rules={[{ required: true, message: `${t('pages.master.doc.msgCorporation')}` }]}
                            >
                                <Input
                                    placeholder="PT ..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.doc.strain')}</span>
                            <Form.Item
                                name="strain" rules={[{ required: true, message: `${t('pages.master.doc.msgStrain')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.master.doc.phStrain')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        strain.map(data =>
                                            <Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateDocPage)
export default page