import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import _ from 'lodash'
import './styles.scss'
import { withTranslation } from 'react-i18next'

class PerformanceChart extends Component {
    static propTypes = {
        chartId: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(
            PropTypes.shape({
            }).isRequired,
        ).isRequired,
        rtl: PropTypes.bool,
    }

    static defaultProps = {
        rtl: false
    }

    componentDidMount() {
        this.initChart()
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.data, this.props.data)) {
            if (this.chart._super) {
                this.chart.dispose()
            }
            this.initChart()
        }
    }

    componentWillUnmount() {
        if (this.chart._super) {
            this.chart.dispose()
        }
    }

    bwChart = (chart) => {
        const { params, t } = this.props

        if (params === 'bw' || params === 'all') {
            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

            var series = chart.series.push(new am4charts.LineSeries())
            series.smoothing = "monotoneX"
            series.dataFields.valueY = "bw"
            series.dataFields.categoryX = "umur"
            series.name = "Body Weight"
            series.tooltipText = "{name}: [bold]{valueY}[/]"
            series.strokeWidth = 3
            series.stroke = am4core.color("#0091ff")
            series.tooltip.getFillFromObject = false
            series.tooltip.background.fill = am4core.color("#0091ff")

            var series4 = chart.series.push(new am4charts.LineSeries())
            series4.smoothing = "monotoneX"
            series4.dataFields.valueY = "standardBw"
            series4.dataFields.categoryX = "umur"
            series4.name = `${t('pages.dashboard.performance.standarBw')}`
            series4.tooltipText = "{name}: [bold]{valueY}[/]"
            series4.strokeWidth = 3
            series4.stroke = am4core.color("#0091ff")
            series4.tooltip.getFillFromObject = false
            series4.tooltip.background.fill = am4core.color("#0091ff")
            series4.strokeDasharray = "3,3"

            valueAxis.renderer.line.strokeOpacity = 1
            valueAxis.renderer.line.strokeWidth = 2
            valueAxis.renderer.opposite = this.props.params === 'bw'
            valueAxis.renderer.line.stroke = series.stroke
            valueAxis.renderer.labels.template.fill = series.stroke
        }

    }

    depChart = (chart) => {
        const { params, t } = this.props
        if (params === 'dep' || params === 'all') {

            var valueAxis2 = chart.yAxes.push(new am4charts.ValueAxis())

            var series3 = chart.series.push(new am4charts.LineSeries())
            series3.smoothing = "monotoneX"
            series3.dataFields.valueY = "deplesi"
            series3.dataFields.categoryX = "umur"
            series3.name = `${t('pages.dashboard.performance.depletion')}`
            series3.tooltipText = "{name}: [bold]{valueY}[/]"
            series3.strokeWidth = 3
            series3.yAxis = valueAxis2
            series3.stroke = am4core.color("#ed0537")
            series3.tooltip.getFillFromObject = false
            series3.tooltip.background.fill = am4core.color("#ed0537")

            var series6 = chart.series.push(new am4charts.LineSeries())
            series6.smoothing = "monotoneX"
            series6.dataFields.valueY = "standardDp"
            series6.dataFields.categoryX = "umur"
            series6.name = `${t('pages.dashboard.performance.standarDepletion')}`
            series6.tooltipText = "{name}: [bold]{valueY}[/]"
            series6.strokeWidth = 3
            series6.yAxis = valueAxis2
            series6.stroke = am4core.color("#ed0537")
            series6.tooltip.getFillFromObject = false
            series6.tooltip.background.fill = am4core.color("#ed0537")
            series6.strokeDasharray = "3,3"

            valueAxis2.renderer.line.strokeOpacity = 1
            valueAxis2.renderer.line.strokeWidth = 2
            valueAxis2.renderer.opposite = true
            valueAxis2.renderer.line.stroke = series3.stroke
            valueAxis2.renderer.labels.template.fill = series3.stroke
        }
    }

    fcrChart = (chart) => {
        const { t, params } = this.props
        if (params === 'fcr' || params === 'all') {

            var valueAxis3 = chart.yAxes.push(new am4charts.ValueAxis())

            var series2 = chart.series.push(new am4charts.LineSeries())
            series2.smoothing = "monotoneX"
            series2.dataFields.valueY = "fcr"
            series2.dataFields.categoryX = "umur"
            series2.name = "FCR"
            series2.tooltipText = "{name}: [bold]{valueY}[/]"
            series2.strokeWidth = 3
            series2.yAxis = valueAxis3
            series2.stroke = am4core.color("#0bb830")
            series2.tooltip.getFillFromObject = false
            series2.tooltip.background.fill = am4core.color("#0bb830")

            var series5 = chart.series.push(new am4charts.LineSeries())
            series5.smoothing = "monotoneX"
            series5.dataFields.valueY = "standardFcr"
            series5.dataFields.categoryX = "umur"
            series5.name = `${t('pages.dashboard.performance.standarFcr')}`
            series5.tooltipText = "{name}: [bold]{valueY}[/]"
            series5.strokeWidth = 3
            series5.yAxis = valueAxis3
            series5.stroke = am4core.color("#a4d49f")
            series5.tooltip.getFillFromObject = false
            series5.tooltip.background.fill = am4core.color("#a4d49f")
            series5.strokeDasharray = "3,3"

            valueAxis3.renderer.line.strokeOpacity = 1
            valueAxis3.renderer.line.strokeWidth = 2
            valueAxis3.renderer.opposite = true
            valueAxis3.renderer.line.stroke = series2.stroke
            valueAxis3.renderer.labels.template.fill = series2.stroke
        }
    }

    fiChart = (chart) => {
        const { t, params } = this.props

        if (params === 'fi' || params === 'all') {

            var valueAxis4 = chart.yAxes.push(new am4charts.ValueAxis())

            var series7 = chart.series.push(new am4charts.LineSeries())
            series7.smoothing = "monotoneX"
            series7.dataFields.valueY = "feedIntake"
            series7.dataFields.categoryX = "umur"
            series7.name = "Feed Intake"
            series7.tooltipText = "{name}: [bold]{valueY}[/]"
            series7.strokeWidth = 3
            series7.yAxis = valueAxis4
            series7.stroke = am4core.color("orange")
            series7.tooltip.getFillFromObject = false
            series7.tooltip.background.fill = am4core.color("orange")

            var series8 = chart.series.push(new am4charts.LineSeries())
            series8.smoothing = "monotoneX"
            series8.dataFields.valueY = "standardFi"
            series8.dataFields.categoryX = "umur"
            series8.name = `${t('pages.dashboard.performance.standarFi')}`
            series8.tooltipText = "{name}: [bold]{valueY}[/]"
            series8.strokeWidth = 3
            series8.yAxis = valueAxis4
            series8.stroke = am4core.color("orange")
            series8.tooltip.getFillFromObject = false
            series8.tooltip.background.fill = am4core.color("orange")
            series8.strokeDasharray = "3,3"

            valueAxis4.renderer.line.strokeOpacity = 1
            valueAxis4.renderer.line.strokeWidth = 2
            valueAxis4.renderer.opposite = params === 'fi'
            valueAxis4.renderer.line.stroke = series7.stroke
            valueAxis4.renderer.labels.template.fill = series7.stroke
        }
    }

    initChart() {
        const { chartId, t } = this.props
        this.chart = am4core.create(chartId, am4charts.XYChart)
        this.chart.exporting.menu = new am4core.ExportMenu()
        this.chart.scrollbarX = new am4core.Scrollbar()
        this.chart.exporting.menu.align = "left"
        this.chart.exporting.menu.verticalAlign = "top"

        // Create axes
        var categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis())
        categoryAxis.dataFields.category = "umur"
        categoryAxis.title.text = `${t('pages.dashboard.performance.age')}`
        categoryAxis.renderer.grid.template.location = 0
        categoryAxis.renderer.minGridDistance = 40

        // var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis())
        // valueAxis.title.text = `${t('pages.dashboard.performance.value')}`

        // Create series
        this.bwChart(this.chart)
        this.depChart(this.chart)
        this.fcrChart(this.chart)
        this.fiChart(this.chart)

        // add data
        this.chart.data = this.props.data

        // Add cursor
        this.chart.cursor = new am4charts.XYCursor()

        // Add legend
        this.chart.legend = new am4charts.Legend()
    }

    render() {
        return <div id={this.props.chartId} className={this.props.className} />
    }
}

export default withTranslation()(PerformanceChart)