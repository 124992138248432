export const START_GET_DATA = '@layout/start-get-data'
export const FINISH_GET_DATA = '@layout/start-finish-data'
export const SET_DATA_KANDANG = '@layout/set_data_kandang'
export const SET_KANDANG = '@layout/set_kandang'
export const SET_DATA_PERIODE = '@layout/set_data_periode'
export const SET_ALL_PERIODE = '@layout/set_all_periode'
export const SET_PERIODE_AKTIF = '@layout/set_periode_aktif'
export const SUCCESS_SET_PERIODE_AKTIF = '@layout/success_set_periode_aktif'
export const SET_ID_PERIODE = '@layout/set_id_periode'
export const SET_ID_DEVICE = '@layout/set_id_device'
export const SET_DEVICE_TYPE = '@layout/set_device_type'
export const SET_PERIODE = '@layout/set_periode'
export const SET_ID_KANDANG = '@layout/set_id_kandang'
export const SET_DATE_PERIODE = '@layout/set_date_periode'
export const SET_TOTAL_KANDANG = '@layout/total_kandang'
export const SET_IS_TOUR = '@layout/set_is_tour'
export const SET_IMAGE_LOAD = '@layout/set_image_load'
export const SET_ID_INVOICE = '@layout/set_id_invoice'
export const SET_ID_HARVEST = '@layout/set_id_harvest'
export const SET_INDEX_HARVEST = '@layout/set_index_harvest'
export const SET_CREATE_RECORDING = '@layout/set_create_recording'
export const SET_LOADING_REARING = '@layout/set_loading_rearing'
export const SET_LOADING_FEED_WH = '@layout/set_loading_feed_wh'
export const SET_LOADING_OVK_WH = '@layout/set_loading_ovk_wh'
