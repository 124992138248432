import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, DatePicker, InputNumber, Input } from 'antd'
import moment from 'moment'

function EditPenambahanStokPage(props) {
    const { visible, handleCancel, detail, handleSubmit, satuanOvk, t } = props
    const [data, setData] = useState(detail)

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.feedStock.titleEditAdd')}
                okText={t('general.edit')}
                width={500} centered
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        jumlahStok: data.number,
                        date_picker: moment(new Date(parseInt(data.storeTime))),
                        keterangan: data.otherInformation,
                        harga: data.priceFeeding,
                        ttb: data.ttb,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.feedStock.trxDate')}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, type: 'object', message: `${t('pages.feedStock.msgAddDate')}` }]}
                            >
                                <DatePicker
                                    placeholder={t('pages.feedStock.phAddDate')}
                                    className="select-input-date mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.feedStock.qtyStock')}</span>
                            <Form.Item
                                name="jumlahStok" rules={[{ required: true, message: `${t('pages.feedStock.msgQtyStock')}` }]}
                            >
                                <InputNumber
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                    name='jumlahStok'
                                    placeholder="1000..."
                                    className="input-number mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.feedStock.priceFeed')}</span>
                            <Form.Item
                                name="harga" rules={[{ required: true, type: 'number', message: `${t('pages.feedStock.msgPrice')}` }]}
                            >
                                <InputNumber
                                    placeholder="15000..."
                                    className="input-number mt-5"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.feedStock.docNumber')}</span>
                            <Form.Item
                                name="ttb"
                            >
                                <Input
                                    placeholder="TTB..."
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                satuanOvk={satuanOvk}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPenambahanStokPage)
export default page