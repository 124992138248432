import React from 'react'
import { connect } from 'react-redux'
import { Button, Layout, Breadcrumb, Row, Col, Image } from 'antd'
import { getProfile } from '../profil-page/query-profil'
import { claimVoucher } from '../dashboard-page/query-dashboard-page'
import { getListProfil } from '../../../modules/dashboard-peternak/profil-component/store/profil-action'
import { API } from '../../../common/api'
import { errMessage } from '../../../common/component/notification/notification'
import moment from 'moment'

function VoucherPage(props) {
    const logo = require(`../../../assets/images/logo_only.png`)

    const claim = async () => {
        await API.get(claimVoucher())
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', res.data.errors[0].message)
                } else if (res.data.data.claimVoucher !== null) {
                    props.getListProfil(getProfile())
                    errMessage('success', 'Berhasil mengklaim voucher', 'Anda berhasil mendapatkan akun Premium BroilerX 2 bulan.')
                }
            }).catch((error) => {
                errMessage('error', error)
            })
    }

    return (
        <Layout>
            <Layout.Content className="dashboard-container">
                <Breadcrumb separator="">
                    <Breadcrumb.Item><span>Voucher Saya</span></Breadcrumb.Item>
                </Breadcrumb>
                <Row gutter={[10, 8]} justify="space-around" align="middle" className='card'
                 hidden={!props.profil.isVoucher}
                >
                    <Col span={7} className=''><Image src={logo} preview={false} width={100} /></Col>
                    <Col span={1} className=''>
                        <div className='vertical'></div>
                    </Col>

                    <Col span={16} className=''>
                        <h3 className='font-bold'>VOUCHER PREMIUM</h3>
                        <div className='font-semi-bold mb-0'>{props.profil.voucherHouse} kandang selama {props.profil.voucherDuration} bulan senilai</div>
                        <span className='font-title-login font-bold mt-0'>{props.profil.voucherDuration * props.profil.voucherHouse * 300}rb</span>
                    </Col>
                    <Col span={24}>
                        <Row className="copy-button" justify="space-around" align="middle">
                            <Col span={18}>
                                <div className='font-regular'>Valid sampai {moment(parseInt(props.profil.voucherExpiry)).format('DD MMMM YYYY')}</div>
                            </Col>
                            <Col>
                                <Button type='primary' className='' onClick={() => claim()}>KLAIM</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div
                    hidden={props.profil.isVoucher}
                >
                    Maaf, Anda belum memiliki voucher apapun.
                </div>
            </Layout.Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    typeUser: state.profil.dataProfil.company.typeUser,
    profil: state.profil.dataProfil
})
const mapDispatchToProps = (dispatch => ({
    getListProfil
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(VoucherPage)
export default page