export const getPeriode = (keyword, limit, skip) => {
  return (
    `query{
            rearings(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
              totalCount
              pagination{
                totalPage
              }
                rearings{
                  _id
                  name
                  chickInDate
                  chickInWeight
                  population
                  populationHpp
                  otherInformation
                  ppl
                  price
                  house{
                      houseId: _id
                      warehouseName: name
                      capacity
                      address
                  }
                  doc{
                    name
                  }
                  age
                  lastDate
                  adg
                  deplesi
                  feedIntake
                  fcr
                  totalBw
                  ip
                  restPopulation
                }
              }
          }
      `
  )
}

export const getPeriodeKandang = (house) => {
  return (
    `query{
      rearingHouses(house : "${house}"){
        totalCount

        house{
          feedWarehouse{
            _id
            name
          }
          ovkWarehouse{
            _id
            name
          }
        }

        rearings{
          _id
          name
          chickInDate
        }
      }
    }
  `
  )
}

export const getPeriodeAll = () => {
  return (
    `query{
          rearingAll{
            totalCount
              rearings{
                  _id
                  name
                  house{
                      houseId: _id
                      warehouseName: name
                  }
              }
            }
        }
    `
  )
}

export const getPeriodeCloseAll = (keyword, limit, skip) => {
  return (
    `query{
        rearingClose(keyword : "${keyword}", limit : ${limit}, skip : ${skip}){
            totalCount
              rearings{
                  _id
                  name
                  chickInDate
                  chickInWeight
                  population
                  populationHpp
                  otherInformation
                  ppl
                  fcr
                  ip
                  difFcr
                  deplesi
                  restPopulation
                  price
                  house{
                      houseId: _id
                      warehouseName: name
                      capacity
                      address
                  }
                  doc{
                    name
                  }
              }
            }
        }
    `
  )
}

export const queryPeriodeAktif = () => {
  return (
    `query{
      rearingLatest{
        totalCount
        rearings{
          _id
          houseName
          rearingId
          rearingName
          feedWarehouseId
          feedWarehouseName
          ovkWarehouseId
          ovkWarehouseName
        }
      }
    }      
  `
  )
}

export const createPeriode = (value, year) => {
  return {
    query: `mutation{
              createRearing(rearingInput:{
                chickInDate: "${year}"
                name: "${value.nama_periode}"
                chickInWeight: ${parseInt(value.bobot)}
                population: ${parseInt(value.populasiAwal)}
                populationHpp: ${parseInt(value.populasiHpp)}
                otherInformation: "${value.keterangan}"
                ppl : "${value.ppl}"
                price : ${parseFloat(value.harga)}
                house: "${value.kandang.value}"
                doc : "${value.doc.value}"
              }){
                _id
              }
            }
        `
  }
}

export const closePeriode = (_id, value) => {
  return {
    query: `                      
            mutation{
              closeRearing(_id: "${_id}",updateRearingInput:{
                chickCloseDate: "${value}"
                }){
                  _id
                }
            }
        `
  }
}
export const openPeriode = (_id) => {
  return {
    query: `                      
          mutation{
            openRearing(_id: "${_id}",updateRearingInput:{
              }){
                _id
              }
          }
      `
  }
}
export const detailPeriode = (_id) => {
  return (
    `query{
        rearing(_id: "${_id}"){
            _id
            name
            chickInDate
            chickInWeight
            population
            populationHpp
            otherInformation
            house{
              value: _id
              key: name
            }
            doc{
              value: _id
              key: name
            }
            price
            ppl
            lastDate
          }
        }
    `
  )
}

export const updatePeriode = (_id, value, year) => {
  return {
    query: `                      
          mutation{
              updateRearing(_id: "${_id}",updateRearingInput:{
                          name: "${value.nama_periode}"
                          chickInDate: "${year}"
                          chickInWeight: ${parseInt(value.bobot)}
                          population: ${parseInt(value.populasiAwal)}
                          populationHpp: ${parseInt(value.populasiHpp)}
                          price : ${parseFloat(value.harga)}
                          ppl : "${value.ppl}"
                          otherInformation: "${value.keterangan}"
                          house  :"${value.kandang.value}"
                          doc  :"${value.doc.value}"
              }){
                _id
              }
          }
      `
  }
}

export const deletePeriode = (_id) => {
  return (
    `mutation{
        deleteRearing(_id : "${_id}"){
          deleted
        }
      }
    `
  )
}