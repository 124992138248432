export const master = {
    house: {
        title: "Master Kandang",
        name: "Nama Kandang",
        capacity: "Kapasitas (ekor)",
        address: "Alamat Kandang",
        add: "Tambah Kandang",
        edit: "Edit Kandang",
        feedWh: "Gudang Pakan",
        ovkWh: "Gudang OVK",
        materialWh: "Gudang Material",
        desc: "Keterangan",
        phFeedWh: "Pilih gudang pakan",
        phOvkWh: "Pilih gudang OVK",
        phMaterialWh: "Pilih gudang material",
        phAddress: "Masukkan alamat kandang",
        phDesc: "Masukkan keterangan",
        msgName: "Mohon masukkan nama kandang!",
        msgCapacity: "Mohon masukkan kapasitas kandang!",
        msgFeedWh: "Mohon pilih gudang pakan!",
        msgOvkWh: "Mohon pilih gudang OVK!",
        msgMaterialWh: "Mohon pilih gudang material!",
        msgAddress: "Mohon masukkan alamat kandang!",
        delConfirm: "Apakah anda yakin ingin menghapus kandang ",
        delConfirmContent: "Anda akan kehilangan semua data periode pemeliharaan dan recording yang ada di dalam kandang ini juga.",
        resAddSuccess: "Berhasil menambahkan kandang",
        resEditSuccess: "Berhasil mengubah kandang",
        resActivateSuccess: "Berhasil mengaktivasi kandang",
        resDeactivateSuccess: "Berhasil menonaktifkan kandang",
        resDelSuccess: "Berhasil menghapus kandang",
        resAddFailed: "Gagal menambahkan kandang",
        resEditFailed: "Gagal mengubah kandang",
        resActivateFailed: "Gagal mengaktivasi kandang",
        resDeactivateFailed: "Gagal menonaktifkan kandang",
        resDelFailed: "Gagal menghapus kandang",
    },
    strain: {
        title: "Master Strain",
        name: "Nama Strain",
        std: "Standar Strain",
        day: "Hari",
        depletion: "Deplesi"
    },
    doc: {
        title: "Master DOC",
        name: "Nama DOC",
        corporation: "Perusahaan DOC",
        type: "Jenis DOC",
        strain: "Strain",
        add: "Tambah Master DOC",
        edit: "Edit Master DOC",
        phType: "Pilih jenis DOC",
        phStrain: "Pilih strain",
        msgName: "Mohon masukkan nama DOC!",
        msgCorporation: "Mohon masukkan perusahaan DOC!",
        msgType: "Mohon pilih jenis DOC!",
        msgStrain: "Mohon pilih strain DOC!",
        resAddSuccess: "Berhasil menambahkan DOC!",
        resAddFailed: "Gagal menambahkan DOC!",
        resEditSuccess: "Berhasil mengubah DOC!",
        resEditFailed: "Gagal mengubah DOC!",
        resDelSuccess: "Berhasil menghapus DOC!",
        resDelFailed: "Gagal menghapus DOC!",
        delConfirm: "Apakah Anda yakin ingin menghapus",
    },
    warehouse: {
        title: "Master Gudang",
        name: "Nama Gudang",
        feed: "Pakan",
        ovk: "OVK",
        material: "Material",
        add: "Tambah Gudang",
        delConfirm: "Apakah Anda yakin ingin menghapus gudang",
        addFeed: "Tambah Gudang Pakan",
        addOvk: "Tambah Gudang OVK",
        addMaterial: "Tambah Gudang Material",
        editFeed: "Edit Gudang Pakan",
        editOvk: "Edit Gudang OVK",
        editMaterial: "Edit Gudang Material",
        feedWh: "Nama Gudang Pakan",
        phFeed: "Masukkan nama gudang pakan Anda",
        msgFeed: "Mohon masukkan nama gudang pakan!",
        resAddFeedSuccess: "Berhasil menambahkan gudang pakan",
        resEditFeedSuccess: "Berhasil mengubah gudang pakan",
        resDelFeedSuccess: "Berhasil menghapus gudang pakan",
        resAddFeedFailed: "Gagal menambahkan gudang pakan",
        resEditFeedFailed: "Gagal mengubah gudang pakan",
        resDelFeedFailed: "Gagal menghapus gudang pakan",
        ovkWh: "Nama Gudang OVK",
        phOvk: "Masukkan nama gudang OVK Anda",
        msgOvk: "Mohon masukkan nama gudang OVK!",
        resAddOvkSuccess: "Berhasil menambahkan gudang OVK",
        resEditOvkSuccess: "Berhasil mengubah gudang OVK",
        resDelOvkSuccess: "Berhasil menghapus gudang OVK",
        resAddOvkFailed: "Gagal menambahkan gudang OVK",
        resEditOvkFailed: "Gagal mengubah gudang OVK",
        resDelOvkFailed: "Gagal menghapus gudang OVK",
        materialWh: "Nam Gudang Material",
        phMaterial: "Masukkan nama gudang material Anda",
        msgMaterial: "Mohon masukkan nama gudang material!",
        resAddMaterialSuccess: "Berhasil menambahkan gudang material",
        resEditMaterialSuccess: "Berhasil mengubah gudang material",
        resDelMaterialSuccess: "Berhasil menghapus gudang material",
        resAddMaterialFailed: "Gagal menambahkan gudang material",
        resEditMaterialFailed: "Gagal mengubah gudang material",
        resDelMaterialFailed: "Gagal menghapus gudang material",
    },
    feed: {
        title: "Master Pakan",
        name: "Nama Pakan",
        company: "Perusahaan",
        type: "Jenis Pakan",
        add: "Tambah Master Pakan",
        edit: "Edit Master Pakan",
        desc: "Keterangan",
        phType: "Pilih jenis pakan",
        phDesc: "Masukkan keterangan",
        msgName: "Mohon masukkan nama pakan!",
        msgType: "Mohon pilih jenis pakan!",
        msgCompany: "Mohon masukkan perusahaan pakan!",
        delConfirm: "Apakah anda yakin ingin menghapus pakan",
        resAddSuccess: "Berhasil menambahkan pakan",
        resEditSuccess: "Berhasil mengubah pakan",
        resDelSuccess: "Berhasil menghapus pakan",
        resAddFailed: "Gagal menambahkan pakan",
        resEditFailed: "Gagal mengubah pakan",
        resDelFailed: "Gagal menghapus pakan"
    },
    ovk: {
        title: "Master OVK",
        name: "Nama OVK",
        category: "Golongan",
        procuUnit: "Satuan Pengadaan",
        shape: "Bentuk",
        capacity: "Kapasitas",
        dose: "Dosis",
        usage: "Rekomendasi Penggunaan",
        ingredient: "Kandungan",
        supplier: "Supplier",
        expired: "Kedaluwarsa",
        add: "Tambah Master OVK",
        delConfirm: "Apakah anda yakin ingin menghapus OVK",
        edit: 'Edit Master OVK',
        type: "Jenis OVK",
        media: "Media OVK",
        usageUnit: "Satuan Penggunaan",
        qty: "Kuantitas Satuan",
        qtyUsage: "Konversi",
        botol: 'Botol',
        karung: 'Karung',
        vial: 'Vial',
        pil: 'Pil',
        sachet: 'Sachet',
        jerigen: 'Jerigen',
        jar: 'Jar',
        spray: 'Spray',
        pakan: 'Pakan',
        air: 'Air Minum',
        tebar: 'Tebar',
        phShape: "Pilih bentuk",
        phType: "Pilih tipe",
        phMedia: "Pilih media",
        phIngredient: "Masukkan kandungan",
        phUsageUnit: "Pilih satuan penggunaan",
        phProcuUnit: "Pilih satuan pengadaan",
        phExpired: "Pilih tanggal kedaluwarsa",
        msgShape: "Mohon pilih bentuk OVK!",
        msgType: "Mohon pilih tipe OVK!",
        msgUsageUnit: "Mohon pilih satuan penggunaan OVK!",
        msgProcuUnit: "Mohon pilih satuan pengadaan OVK!",
        msgMedia: "Mohon pilih media OVK!",
        msgName: "Mohon masukkan nama OVK!",
        msgDose: "Mohon masukkan dosis OVK!",
        msgUsage: "Mohon masukkan penggunaan OVK!",
        msgIngredient: "Mohon masukkan kandungan OVK!",
        msgSupplier: "Mohon masukkan supplier OVK!",
        msgQty: "Mohon masukkan kuantitas OVK!",
        msgExpired: "Mohon pilih tanggal kedaluwarsa!",
        resAddSuccess: "Berhasil menambahkan OVK",
        resEditSuccess: "Berhasil mengubah OVK",
        resDelSuccess: "Berhasil menghapus OVK",
        resAddFailed: "Gagal menambahkan OVK",
        resEditFailed: "Gagal mengubah OVK",
        resDelFailed: "Gagal menghapus OVK",
    },
    material: {
        title: "Master Material",
        name: "Nama Material",
        company: "Perusahaan Material",
        add: "Tambah Master Material",
        delConfirm: "Apakah anda yakin ingin menghapus material ",
        edit: "Edit Master Material",
        phName: "Masukkan nama material",
        msgName: "Mohon masukkan nama material",
        msgCompany: "Mohon masukkan perusahaan produksi",
        resAddSuccess: "Berhasil menambahkan material",
        resEditSuccess: "Berhasil mengubah material",
        resDelSuccess: "Berhasil menghapus material",
        resAddFailed: "Gagal menambahkan material",
        resEditFailed: "Gagal mengubah material",
        resDelFailed: "Gagal menghapus material",
    },
    overhead: {
        title: "Master Overhead",
        name: "Nama Overhead",
        price: "Harga (Rp)",
        add: "Tambah Master Overhead",
        edit: "Edit Master Overhead",
        delConfirm: "Apakah Anda yakin akan menghapus overhead",
        msgName: "Mohon masukkan nama overhead!",
        msgPrice: "Mohon masukkan harga overhead!",
        resAddSuccess: "Berhasil menambahkan overhead",
        resEditSuccess: "Berhasil mengubah overhead",
        resDelSuccess: "Berhasil menghapus overhead",
        resAddFailed: "Gagal menambahkan overhead",
        resEditFailed: "Gagal mengubah overhead",
        resDelFailed: "Gagal menghapus overhead"
    },
    disease: {
        title: "Master Penyakit",
        name: "Nama Penyakit",
        symptom: "Gejala Umum",
        add: "Tambah Master Penyakit",
        edit: "Edit Master Penyakit",
        delConfirm: "Apakah Anda yakin akan menghapus overhead",
        phSymptom: "Masukkan gejala umum...",
        msgName: "Mohon masukkan nama overhead!",
        resAddSuccess: "Berhasil menambahkan penyakit",
        resEditSuccess: "Berhasil mengubah penyakit",
        resDelSuccess: "Berhasil menghapus penyakit",
        resAddFailed: "Gagal menambahkan penyakit",
        resEditFailed: "Gagal mengubah penyakit",
        resDelFailed: "Gagal menghapus penyakit"
    },
    customer: {
        title: "Master Pelanggan",
        name: "Nama",
        address: "Alamat",
        contact: "Kontak",
        info: "Informasi Lain",
        delConfirm: "Apakah anda yakin ingin menghapus customer ",
        add: "Tambah Master Pelanggan",
        edit: "Edit Master Pelanggan",
        phName: "Masukkan nama pelanggan",
        phAddress: "Masukkan alamat pelanggan",
        phInfo: "Masukkan informasi lain",
        msgName: "Mohon masukkan nama pelanggan!",
        msgContact: "Mohon masukkan kontak pelanggan!",
        msgAddress: "Mohon masukkan alamat pelanggan!",
        msgInfo: "Mohon masukkan informasi lain!",
        resAddSuccess: "Berhasil menambahkan pelanggan",
        resEditSuccess: "Berhasil mengubah pelanggan",
        resDelSuccess: "Berhasil menghapus pelanggan",
        resAddFailed: "Gagal menambahkan pelanggan",
        resEditFailed: "Gagal mengubah pelanggan",
        resDelFailed: "Gagal menghapus pelanggan"
    },
    supplier: {
        title: "Master Kontak Supplier",
        code: "Kode Supplier",
        name: "Nama",
        address: "Alamat",
        contact: "Kontak",
        type: "Tipe Supplier",
        info: "Informasi Lain",
        delConfirm: "Apakah anda yakin ingin menghapus supplier ",
        add: "Tambah Master Supplier",
        edit: "Edit Master Supplier",
        phName: "Masukkan nama supplier",
        phAddress: "Masukkan alamat supplier",
        phCode: "Masukkan kode supplier",
        phType: "Pilih tipe supplier",
        phInfo: "Jika tidak ada informasi tambahan, masukkan -",
        msgName: "Mohon masukkan nama supplier!",
        msgAddress: "Mohon masukkan alamat supplier!",
        msgType: "Mohon pilih tipe supplier!",
        msgCode: "Mohon masukkan kode supplier!",
        msgContact: "Mohon masukkan kontak supplier!",
        msgInfo: "Mohon masukkan informasi tambahan!",
        resAddSuccess: "Berhasil menambahkan supplier",
        resEditSuccess: "Berhasil mengubah supplier",
        resDelSuccess: "Berhasil menghapus supplier",
        resAddFailed: "Gagal menambahkan supplier",
        resEditFailed: "Gagal mengubah supplier",
        resDelFailed: "Gagal menghapus supplier"
    },
    staff: {
        title: "Master Petugas",
        staff: "Petugas Kandang",
        management: "Manajemen",
        add: "Tambah Petugas",
        edit: "Edit Petugas",
        name: "Nama",
        type: 'Jenis Petugas',
        house: "Kandang",
        phone: "Nomor Telepon",
        address: "Alamat",
        phName: "Masukkan nama lengkap..",
        phHouse: "Pilih kandang",
        phAddress: "Masukkan alamat...",
        phEmail: "Masukkan email",
        phUsername: "Masukkan username",
        phPassword: "Masukkan password",
        phType: ' Pilih jenis petugas!',
        msgName: "Mohon masukkan nama!",
        msgAddress: "Mohon masukkan alamat!",
        msgHouse: "Mohon pilih kandang!",
        msgPhone: "Mohon masukkan nomor telepon!",
        msgEmail: "Mohon masukkan email!",
        msgUsername: "Mohon masukkan username minimal 4 karakter!",
        msgPassword: "Mohon masukkan kata sandi minimal 6 karakter!",
        msgType: "Mohon pilih jenis petugas!",
        delConfirm: "Apakah anda yakin ingin menghapus petugas",
        resAddSuccess: "Berhasil menambahkan petugas",
        resEditSuccess: "Berhasil mengubah petugas",
        resDelSuccess: "Berhasil menghapus petugas",
        resAddFailed: "Gagal menambahkan petugas",
        resEditFailed: "Gagal mengubah petugas",
        resDelFailed: "Gagal menghapus petugas",
    },
}