import React, { useState } from 'react'
import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input, Table, Tooltip, Tag } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { faCreditCard, faFileInvoice } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getIdInvoice } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'
import CONSTANTS from '../../../common/utils/Constants'
import { navigate } from '../../../common/store/action'
import { isMobileOnly } from 'react-device-detect'

function TableInvoice(props) {
    const { data, Loading, loading, moment, NumberFormat, getIdInvoice, t } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')

    const getColumnSearchProps = (dataIndex, value) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${value}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const columns = [
        {
            title: `${t("pages.subscription.date")}`,
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            render: (value, row, index) => (
                <span>{moment(value).format('LLL')}</span>
            )
        },
        {
            title: `ID`,
            dataIndex: 'externalId',
            key: 'externalId',
            align: 'center',
            ...getColumnSearchProps('externalId', `Invoice ID`),
        },
        {
            title: `${t("pages.subscription.totalTrx")}`,
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix='Rp' />
            )
        },
        {
            title: `${t("pages.subscription.status")}`,
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (value, row, index) => (
                <Tag color={value === 'unpaid' || value === 'validating' ? 'gold' : value === 'expired' ? "red" : 'green'}>
                    {value === 'unpaid' ? 'Menunggu Pembayaran' : value === 'validating' ? 'Sedang Divalidasi' : value === 'expired' ? 'Kedaluwarsa' : 'Lunas'}
                    </Tag>
            )
        },
        {
            title: `${t("pages.subscription.desc")}`,
            dataIndex: 'description',
            key: 'description',
            align: 'center',
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'left',
            width: 120,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("pages.subscription.print")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#008ad4',
                            borderRadius: '5px'
                        }}
                            onClick={() => {
                                getIdInvoice(row._id)
                                props.navigate(CONSTANTS.INVOICE_KEY)
                            }
                            }
                        ><FontAwesomeIcon icon={faFileInvoice} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("pages.subscription.pay")}>
                        <Button hidden={row.status === 'unpaid' ? false : true} style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                            borderRadius: '5px'
                        }}
                            onClick={() => window.open(row.link)}
                        ><FontAwesomeIcon icon={faCreditCard} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    return (
        <Loading loading={loading}>
            <Table
                columns={columns} bordered
                dataSource={data}
                pagination={false}
                className="table-dashboard"
                scroll={{ y: 500, x: isMobileOnly && 1000 }}
            />
        </Loading>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    getIdInvoice, navigate
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TableInvoice)
export default page