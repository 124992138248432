import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input, Layout, Breadcrumb, Row, Col, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next"

import { API } from '../../../../common/api'
import Loading from '../../../../common/component/loading/loading-container'
import { errMessage } from '../../../../common/component/notification/notification'

import { getPakan, deletePakan, detailPakan, updatePakan } from './pakan-aksi-page/query-pakan'
import ModalCreatePakan from './pakan-aksi-page/create-pakan-page'
import ModalEditPakan from './pakan-aksi-page/edit-pakan-page'
import { isMobileOnly } from 'react-device-detect'


function PakanPage(props) {
    const { role, profil } = props
    const { Content } = Layout
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState('')
    const [idData, setIdData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const { t } = useTranslation()

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = async () => {
        setLoading(true)
        await API.get(getPakan('', 0, 0))
            .then(res => {
                setObject(res.data.data.feeds.feeds)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deletePakan(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.feed.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.deleteFeed.deleted === true) {
                    errMessage('success', `${t('pages.master.feed.resDelSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.master.feed.resDelFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.master.feed.delConfirm')} ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(detailPakan(idData))
                    .then(res => {
                        setDetail(res.data.data.feed)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = async (values) => {
        await API.post(updatePakan(idData, values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.feed.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.updateFeed._id) {
                    errMessage('success', `${t('pages.master.feed.resEditSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.master.feed.resEditFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setLoading(true)
        setIdData(null)
    }

    const columns = [
        {
            title: `${t('pages.master.feed.name')}`,
            dataIndex: 'nama',
            key: 'nama',
            ...getColumnSearchProps('nama'),
        },
        {
            title: `${t('pages.master.feed.type')}`,
            dataIndex: 'jenis',
            key: 'jenis',
            ...getColumnSearchProps('jenis'),
        },
        {
            title: `${t('pages.master.feed.company')}`,
            dataIndex: 'perusahaan',
            key: 'perusahaan',
            ...getColumnSearchProps('perusahaan'),
        },
        {
            title: `${t('pages.master.feed.desc')}`,
            dataIndex: 'desc',
            key: 'desc',
            ...getColumnSearchProps('desc'),
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 130,
            render: (value, row, index) => (
                row.variety === 'secondary' || role === 'superadmin' ?
                    <Space size="middle">
                        <Tooltip placement="top" title={t('general.edit')}>
                            <Button
                                hidden={!profil.permission.writeMaster}
                                style={{
                                    color: 'white',
                                    backgroundColor: '#FFA903',
                                    borderRadius: '5px'
                                }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('general.del')}>
                            <Button
                                hidden={!profil.permission.writeMaster}
                                style={{
                                    color: 'white',
                                    backgroundColor: '#FF0303',
                                    borderRadius: '5px'
                                }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </Tooltip>
                    </Space>
                    : <></>
            )
        }
    ]

    const data = object.map(({ _id, name, producer, type, variety, otherInformation }, index) => ({
        key: _id,
        nama: name,
        perusahaan: producer,
        jenis: type,
        variety: variety,
        desc: otherInformation
    }))

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col lg={12} sm={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.feed.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={12} sm={24} hidden={!profil.permission.writeMaster}>
                        <ModalCreatePakan fetchData={fetchData} setLoading={setLoading} t={t} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                                className="table-dashboard"
                                />
                        </Loading>
                    </Col>
                    <ModalEditPakan visible={visible} idData={idData}
                        handleCancel={handleCancel} detail={detail} handleSubmit={handleSubmit} t={t} />
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    role: state.profil.dataProfil.type,
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(PakanPage)
export default page