export const getInvoices = () => {
    return (
        `query{
            invoices(keyword:"",limit:0,skip:0){
              totalCount,
              invoices{
                  _id
                  createdAt
                  externalId
                  invoiceId
                  amount
                  link
                  status
                  description
                  company{
                      name
                  }
              }
            }
        }`
    )
}

export const getPendingInvoices = () => {
    return (
        `query{
            pendingInvoices(keyword:"",limit:0,skip:0){
              totalCount,
              invoices{
                  _id
                  type
                  membership
                  expiry_date
                  createdAt
                  externalId
                  invoiceId
                  amount
                  link
                  status
                  description
                  company{
                      name
                      picMembership
                  }
              }
            }
        }`
    )
}

export const detailInvoice = (id) => {
    return (
        `query{
            invoice(_id:"${id}"){
              _id
              externalId
              status
              amount
              description
              expiry_date
              createdAt
              updated
              link
              items{
                  name
                  price
                  quantity
              }
              company{
                  name
              }
            }
          }`
    )
}

export const checkSubs = (id) => {
    return (
        `query{
            checkSubscription(_id:"${id}"){
                _id
              externalId
              status
              amount
              description
              expiry_date
              createdAt
              updated
              link
              items{
                  name
                  price
                  quantity
              }
              company{
                  name
              }
            }
          }`
    )
}
export const createInvoice = (value, type) => {
    return {
        query: `mutation{
            createInvoice(invoiceInput:{
              duration: ${value.duration}
              itemNumber : ${value.itemNumber}
              type: "${type}"
            }){
              _id
              externalId
              invoiceId
              link
              amount
              createdAt
              company{
                  name
              }
            }
          }`
    }
}
export const upgradeHouse = (value, date) => {
    return {
        query:`mutation{
            upgradeHouse(invoiceInput:{
                itemNumber : ${value.itemNumber}
                date: "${date}"
            }){
              _id
              externalId
              invoiceId
              link
              amount
              createdAt
              company{
                  name
              }
            }
          }`
    }
}

export const validateMembership = (id) => {
    return {
        query: `mutation{
            validateMembership(_id:"${id}"){
              _id
              externalId
              status
              amount
              description
              expiry_date
              createdAt
              updated
              link
              items{
                  name
                  price
                  quantity
              }
              company{
                  name
              }
            }
          }`
        }
}
