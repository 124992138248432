import React, { useCallback, useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { connect } from 'react-redux'
import { navigate } from '../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Input, Modal, Layout, Breadcrumb, Row, Col, Table, Image, DatePicker, Form, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faEdit, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import ModalCreateRecordingPemeliharaan from './rearing-record-aksi-page/create-rearing-record-aksi-page'
import { API } from '../../../common/api'
import { useTranslation } from "react-i18next"
import Loading from '../../../common/component/loading/loading-container'

import SelectDeviceImage from '../../../assets/svg/select-device.svg'

import { getRearingRecord, deleteRearingRecord, updateRearingRecord } from './rearing-record-aksi-page/query-rearing-record'
import { getIdRearingRecord, getDateRearingRecord, getAgeRearingRecord } from '../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action'
import { errMessage, validationMessage } from '../../../common/component/notification/notification'
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
import { isMobileOnly } from 'react-device-detect'
import { setLoadingRearing } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'

function RearingRecordPage(props) {
    const { confirm } = Modal
    const { Content } = Layout
    const { idRearingRecord, getIdRearingRecord, getDateRearingRecord, getAgeRearingRecord, setLoadingRearing, loading, profil } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [rearing, setRearing] = useState([])
    const [count, setCount] = useState(1)
    const [periode, setPeriode] = useState('')
    const [house, setHouse] = useState('')
    const [houseActive, setActive] = useState(true)
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const [visibleEdit, setVisibleEdit] = useState(false)

    const history = useHistory()
    const params = useParams()
    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = async (idPeriode) => {
        setLoadingRearing(true)
        await API.get(getRearingRecord('', 0, 0, idPeriode))
            .then(res => {
                if (!idPeriode || res.data.errors) {
                    setRearing([])
                    setLoadingRearing(false)
                    setCount(0)
                }
                else if (res.data.data.calculatedRearingRecords) {
                    setRearing(res.data.data.calculatedRearingRecords.calculatedArr)
                    setPeriode(res.data.data.calculatedRearingRecords.periode)
                    setHouse(res.data.data.calculatedRearingRecords.house)
                    setCount(res.data.data.calculatedRearingRecords.totalCount)
                    setActive(res.data.data.calculatedRearingRecords.isActiveHouse)
                    setLoadingRearing(false)
                }
            }).catch((error) => {
                setRearing([])
                setCount(0)
                setLoadingRearing(false)
            })
    }

    useEffect(() => {
        if (params.rearingId) {
            fetchData(params.rearingId)
        }
        // eslint-disable-next-line
    }, [params.rearingId])
    
    const deleteData = async (_id) => {
        setLoadingRearing(true)
        await API.delete(deleteRearingRecord(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.recording.resDelFailed')}`, res.data.errors[0].message)
                    setLoadingRearing(false)
                }
                else if (res.data.data.deleteRearingRecord.deleted === true) {
                    validationMessage('success', `${t('pages.recording.resDelSuccess')}`)
                    fetchData(params.rearingId)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.recording.resDelFailed')}`, error.message)
                setLoadingRearing(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t('pages.recording.delConfirm')}`,
            centered: true,
            maskClosable: true,
            onOk: () => {
                deleteData(_id)
            },
            okText: `${t('general.del')}`,
            cancelText: `${t('general.cancel')}`,
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            okButtonProps: { danger: true }
        })
    }

    const detailRearing = useCallback((value, tanggal, age) => {
        getIdRearingRecord(value)
        getAgeRearingRecord(age)
        getDateRearingRecord(tanggal)
        history.replace(`/peternak/recording/${params.rearingId}/${value}`)
        // eslint-disable-next-line
    }, [props, getIdRearingRecord, getDateRearingRecord, getAgeRearingRecord])

    const showEdit = useCallback((id, date) => {
        getIdRearingRecord(id)
        getDateRearingRecord(date)
        setVisibleEdit(true)
        form.setFieldsValue({ date_picker: moment(new Date(parseInt(date))) })
    }, [form, getIdRearingRecord, getDateRearingRecord])

    const updateRecord = async (date) => {
        await API.post(updateRearingRecord(idRearingRecord, date))
            .then(res => {
                try {
                    if (res.data.errors) {
                        errMessage('error', `Gagal mengubah tanggal recording`, res.data.errors[0].message)
                        setLoadingRearing(false)
                    } else if (res.data.data.updateRearingRecord._id) {
                        errMessage('success', `Berhasil mengubah tanggal recording`)
                        fetchData(params.rearingId)
                        setVisibleEdit(false)
                    }
                } catch (error) {
                    errMessage('error', `Gagal mengubah tanggal recording`, error.message)
                    setLoadingRearing(false)
                }
            })
    }

    const columns = [
        history.location.pathname.includes('/peternak/recording') ?
            {
                title: `${t('general.action')}`,
                dataIndex: 'pilihan',
                key: 'pilihan',
                fixed: !isMobileOnly && !isMobileOnly && 'left',
                align: 'center',
                width: 180,
                render: (value, row, index) => (
                    <Space size="middle">
                        <Tooltip placement="top" title={t('general.detail')}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#00923F',
                                borderRadius: '5px'
                            }} onClick={() => detailRearing(row._id, row.tanggal, row.umur)}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('general.edit')}>
                            <Button disabled={houseActive ? false : true}
                                hidden={profil.permission.editRecording ? false : true} style={{
                                    color: 'white',
                                    backgroundColor: houseActive ? '#FFA903' : 'whitesmoke',
                                    borderRadius: '5px'
                                }} onClick={() => showEdit(row._id, row.tanggal)}><FontAwesomeIcon icon={faEdit} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('general.del')}>
                            <Button
                                hidden={profil.permission.writeRecording ? false : true}
                                disabled={houseActive ? false : true} style={{
                                    color: 'white',
                                    backgroundColor: houseActive ? '#FF0303' : 'whitesmoke',
                                    borderRadius: '5px'
                                }} onClick={() => hapusConfirm(row._id)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </Tooltip>
                    </Space>
                ),
            } : { width: 1 },
        {
            title: `${t('pages.recording.age')}`,
            dataIndex: 'umur',
            key: 'umur',
            fixed: !isMobileOnly && 'left',
            width: 70,
            className: 'center',
        },
        {
            title: `${t('pages.recording.date')}`,
            dataIndex: 'tanggal',
            fixed: !isMobileOnly && 'left',
            width: 140,
            key: 'tanggal',
            className: 'center',
            ...getColumnSearchProps('tanggal'),
            render: (value, row, index) => (
                moment(new Date(parseInt(value))).format("DD MMMM YYYY")
            )
        },
        {
            title: `${t('pages.recording.feed.title')}`,
            className: 'pakan',
            children: [
                {
                    title: `${t('pages.recording.feed.feedDay')}`,
                    dataIndex: 'pakanPerHari',
                    key: 'pakanPerHari',
                    className: 'center-pakan',
                    width: 90,
                    render: (value, row, index) => (
                        <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `Pakan/hari (sak)`,
                    dataIndex: 'pakanPerSak',
                    key: 'pakanPerSak',
                    className: 'center-pakan',
                    width: 90,
                    render: (value, row, index) => (
                        <NumberFormat value={row.pakanPerHari / 50} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `${t('pages.recording.feed.cumFeed')}`,
                    dataIndex: 'pakanTotal',
                    key: 'pakanTotal',
                    className: 'center-pakan',
                    width: 100,
                    render: (value, row, index) => (
                        <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `${t('pages.recording.feed.fiAct')}`,
                    dataIndex: 'feedIntake',
                    key: 'feedIntake',
                    className: 'center-pakan',
                    width: 90,
                    render: (value, row, index) => (
                        <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `Standard FI`,
                    dataIndex: 'standardFi',
                    key: 'standardFi',
                    className: 'center-pakan',
                    width: 90,
                    render: (value, row, index) => (
                        <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `Ket. FI`,
                    dataIndex: 'ketFi',
                    key: 'ketFi',
                    className: 'center-pakan',
                    align: 'center',
                    width: 90,
                    render: (value, row, index) => (
                        row.feedIntake < row.standardFi ? <Tag color="#ff5100">Warning</Tag> : <Tag color="#027d17">Aman</Tag>
                    )
                },
                {
                    title: `${t('pages.recording.feed.actCum')}`,
                    dataIndex: 'fiCum',
                    key: 'fiCum',
                    className: 'center-pakan',
                    width: 90,
                    render: (value, row, index) => (
                        <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    )
                },
            ],
        },
        {
            title: `${t('pages.recording.depletion.title')}`,
            className: 'deplesi',
            children: [
                {
                    title: `${t('pages.recording.depletion.culling')}`,
                    dataIndex: 'culling',
                    key: 'culling',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: `${t('pages.recording.depletion.dead')}`,
                    dataIndex: 'mati',
                    key: 'mati',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: `${t('pages.recording.depletion.qty')}`,
                    dataIndex: 'deplesiPerHari',
                    key: 'deplesiPerHari',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: `${t('pages.recording.depletion.cum')}`,
                    dataIndex: 'total',
                    key: 'total',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: `${t('pages.recording.depletion.percentDay')}`,
                    dataIndex: 'persenDeplesiHarian',
                    key: 'persenDeplesiHarian',
                    className: 'center-deplesi',
                    width: 60,
                },
                {
                    title: `${t('pages.recording.depletion.percentCum')}`,
                    dataIndex: 'persenKematian',
                    key: 'persenKematian',
                    className: 'center-deplesi',
                    width: 70,
                    render: (value, row, index) => (
                        <NumberFormat value={value.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `Standar Deplesi`,
                    dataIndex: 'standardDp',
                    key: 'standardDp',
                    className: 'center-deplesi',
                    width: 70,
                },
                {
                    title: `Ket. Deplesi`,
                    dataIndex: 'ketDeplesi',
                    key: 'ketDeplesi',
                    className: 'center-deplesi',
                    align: 'center',
                    width: 90,
                    render: (value, row, index) => (
                        row.standardDp < row.persenKematian ? <Tag color="#ff5100">Warning</Tag> : <Tag color="#027d17">Aman</Tag>
                    )
                },
            ],
        },
        {
            title: `${t('pages.recording.harvest.title')}`,
            className: 'panen',
            children: [
                {
                    title: `${t('pages.recording.harvest.qty')}`,
                    dataIndex: 'panen',
                    key: 'panen',
                    className: 'center-panen',
                    width: 70,
                    render: (value, row, index) => (
                        <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `${t('pages.recording.harvest.weight')}`,
                    dataIndex: 'totalBeratPanen',
                    key: 'totalBeratPanen',
                    className: 'center-panen',
                    width: 60,
                    render: (value, row, index) => (
                        <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `${t('pages.recording.harvest.restChicken')}`,
                    dataIndex: 'saldo',
                    key: 'saldo',
                    className: 'center-panen',
                    width: 70,
                    render: (value, row, index) => (
                        <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    )
                },
            ],
        },
        {
            title: 'BW',
            className: 'bw',
            children: [
                {
                    title: `${t('pages.recording.bw.sampling')}`,
                    dataIndex: 'bw',
                    key: 'bw',
                    className: 'center-bw',
                    width: 90,
                    render: (value, row, index) => (
                        <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `Standard BW`,
                    dataIndex: 'standardBw',
                    key: 'standardBw',
                    className: 'center-bw',
                    width: 90,
                    render: (value, row, index) => (
                        <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `${t('pages.recording.bw.adg')}`,
                    dataIndex: 'adg',
                    key: 'adg',
                    className: 'center-bw',
                    width: 60,
                    render: (value, row, index) => (
                        <NumberFormat value={value.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `${t('pages.recording.bw.totalWeight')}`,
                    dataIndex: 'totalBerat',
                    key: 'totalBerat',
                    className: 'center-bw',
                    width: 90,
                    render: (value, row, index) => (
                        <NumberFormat value={value.toFixed(2)} displayType={'text'} thousandSeparator={true} />
                    )
                },
                {
                    title: `${t('pages.recording.bw.abw')}`,
                    dataIndex: 'abw',
                    key: 'abw',
                    className: 'center-bw',
                    width: 60,
                    render: (value, row, index) => (
                        <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
                    )
                },
            ],
        },
        {
            title: 'FCR',
            dataIndex: 'fcr',
            key: 'fcr',
            className: 'fcr',
            width: 70,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} />
            )
        },
        {
            title: 'Standard FCR',
            dataIndex: 'standardFcr',
            key: 'standardFcr',
            className: 'fcr',
            width: 70,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} />
            )
        },
        {
            title: 'Dif FCR',
            dataIndex: 'difFcr',
            key: 'difFcr',
            className: 'fcr',
            width: 70,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} />
            )
        },
        {
            title: 'Index Performance',
            dataIndex: 'indeksPerformance',
            key: 'indeksPerformance',
            className: 'center',
            width: 140,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
            )
        },
        profil.permission.nominalRecording ? {
            title: 'DOC (Rp)',
            dataIndex: 'totalDoc',
            key: 'totalDoc',
            className: 'center',
            width: 140,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
            )
        } : { width: 1 },
        profil.permission.nominalRecording ? {
            title: `Pakan (Rp)`,
            dataIndex: 'cumFeed',
            key: 'cumFeed',
            className: 'center',
            width: 140,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
            )
        } : { width: 1 },
        profil.permission.nominalRecording ? {
            title: `OVK (Rp)`,
            dataIndex: 'ovkTotal',
            key: 'ovkTotal',
            className: 'center',
            width: 140,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
            )
        } : { width: 1 },
        profil.permission.nominalRecording ? {
            title: 'Overhead (Rp)',
            dataIndex: 'cumOverhead',
            key: 'cumOverhead',
            className: 'center',
            width: 140,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
            )
        } : { width: 1 },
        profil.permission.nominalRecording ? {
            title: `Total HPP (Rp)`,
            dataIndex: 'totalBiaya',
            key: 'totalBiaya',
            className: 'center',
            width: 140,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
            )
        } : { width: 1 },
        profil.permission.nominalRecording ? {
            title: 'Hpp/Ekor (Rp)',
            dataIndex: 'hppPerEkor',
            key: 'hppPerEkor',
            className: 'center',
            width: 140,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
            )
        } : { width: 1 },
        profil.permission.nominalRecording ? {
            title: 'Hpp Stok/Ekor (Rp)',
            dataIndex: 'hppStok',
            key: 'hppStok',
            className: 'center',
            width: 140,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
            )
        } : { width: 1 },
        profil.permission.nominalRecording ? {
            title: 'Hpp/Kg (Rp)',
            dataIndex: 'hppPerKg',
            key: 'hppPerKg',
            className: 'center',
            width: 140,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
            )
        } : { width: 1 },
    ]

    return (
        <>
            {
                history.location.pathname.includes('/peternak/recording') ?
                    <Layout>
                        <Content className="dashboard-container">
                            <Row className="dashboard-section">
                                <Col lg={12} sm={24}>
                                    <Breadcrumb separator="">
                                        <Breadcrumb.Item><span>{t('pages.recording.title')}</span></Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                                <Col lg={12} sm={24} hidden={params.rearingId ? false : true}>
                                    <ModalCreateRecordingPemeliharaan fetchData={fetchData} detailRearing={detailRearing} profil={profil}
                                        periode={periode} house={house} houseActive={houseActive} count={count} setLoading={setLoadingRearing} t={t} />
                                </Col>
                            </Row>
                            <div>
                                <Loading loading={loading}>
                                    <div className='center-object text-center' hidden={loading || rearing.length > 0 ? true : false}>
                                        <Image src={SelectDeviceImage} preview={false} />
                                        <div className='font-semi-medium'>{t('pages.recording.norearing', { house: house, rearing: periode })}</div>
                                    </div>
                                    <Row hidden={rearing.length > 0 && params.rearingId ? false : true}>
                                        <Col lg={24} md={24} sm={24}>
                                            <Table
                                                columns={columns}
                                                dataSource={rearing}
                                                bordered
                                                pagination={false}
                                                className="table-rearing-record"
                                                scroll={{ x: 1800, y: '70vh' }}
                                            />
                                        </Col>
                                    </Row>
                                </Loading>
                            </div>

                            <Modal
                                visible={visibleEdit}
                                title='Edit Recording'
                                okText={t('general.edit')}
                                width={800}
                                cancelText={t('general.cancel')}
                                onCancel={() => setVisibleEdit(false)}
                                onOk={() => {
                                    form
                                        .validateFields()
                                        .then((values) => {
                                            form.resetFields()
                                            updateRecord(values.date_picker)
                                        })
                                        .catch((info) => {
                                            console.log('Validate Failed:', info)
                                        })
                                }}
                            >
                                <Form
                                    form={form}
                                    layout="vertical"
                                >
                                    <Row gutter={[16, 8]}>
                                        <Col span={24}>
                                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.date')}</span>
                                            <Form.Item
                                                name="date_picker" rules={[{ required: true, message: `${t('pages.recording.msgDate')}` }]}
                                            >
                                                <DatePicker
                                                    placeholder={t('pages.recording.phDate')}
                                                    className="select-input-date mt-5"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Modal>
                        </Content>
                    </Layout>
                    :
                    <Loading loading={loading}>
                        <Table
                            columns={columns}
                            dataSource={rearing}
                            bordered
                            pagination={false}
                            className="table-rearing-record"
                            scroll={{ x: 1800, y: '70vh' }}
                        />
                    </Loading>
            }
        </>
    )
}

const mapStateToProps = state => ({
    idRearing: state.layout.idPeriode,
    idGudangPakan: state.gudangPakan.idGudangPakan,
    idGudangOvk: state.gudangOvk.idGudangOvk,
    profil: state.profil.dataProfil,
    idRearingRecord: state.rearingRecord.idRearingRecord,
    dateRearingRecord: state.rearingRecord.dateRearingRecord,
    idKandang: state.layout.idKandang,
    loading: state.layout.loadingRearing,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdRearingRecord,
    getDateRearingRecord,
    getAgeRearingRecord,
    setLoadingRearing,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(RearingRecordPage)
export default page