import React from 'react'
import { Layout, Breadcrumb, Row, Col, Table, Button } from 'antd'
import Loading from '../../../../common/component/loading/loading-container'
import { Link } from 'react-router-dom'
import ModalCreateAdjusmentPakan from '../../../../app/dashboard-peternak/adjustment-stock-page/pakan-page/pakan-aksi-page/create-adjustment-pakan-page'
import ModalEditAdjustmentPakan from '../../../../app/dashboard-peternak/adjustment-stock-page/pakan-page/pakan-aksi-page/edit-adjustment-pakan-page'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isMobileOnly } from 'react-device-detect'

function PakanComponent(props) {
    const { Content } = Layout
    const { columns, data, loading, fetchData, setLoading, visible, handleCancel, handleSubmit,
        object, locked, idData, detail, t, month, year, profil } = props
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section"  gutter={[16, 16]}>
                    <Col lg={16} xs={24}>
                        <Breadcrumb separator="">
                            <Breadcrumb.Item><Link to='/peternak/stock-flow-periode'>
                                <Button shape="circle" style={{ border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 5%)' }}
                                    icon={<FontAwesomeIcon icon={faArrowLeft} />} size='default' className='mr-10' />
                            </Link></Breadcrumb.Item>
                            <Breadcrumb.Item><span>{t('pages.stockflow.adjFeed')} - {month} {year}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={8} xs={24} hidden={!profil.permission.writeStockflow}>
                        <ModalCreateAdjusmentPakan fetchData={fetchData} object={object} locked={locked} setLoading={setLoading} t={t} />
                    </Col>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                                bordered
                                className="table-dashboard"
                                scroll={{ y: '70vh', x: isMobileOnly && 800 }}
                            />
                        </Loading>
                    </Col>
                </Row>
            </Content>
            <ModalEditAdjustmentPakan visible={visible} idData={idData} handleCancel={handleCancel}
                detail={detail} handleSubmit={handleSubmit} fetchData={fetchData} object={object} t={t} />
        </Layout>
    )
}

export default PakanComponent