import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { errMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import CreateOvkComponent from '../../../../../modules/dashboard-peternak/master-component/ovk-component/ovk-aksi-component/create-ovk-component'
import { createOvk } from './query-ovk'

function CreateOvkPage(props) {
    const { fetchData, setLoading, t } = props
    const [visible, setVisible] = useState(false)
    const [suffix, setSuffix] = useState(false)
    const [unit, setUnit] = useState(true)

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])
    const postData = async (values, year, unit) => {
        await API.post(createOvk(values, year, unit))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.ovk.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createOvk._id) {
                    errMessage('success', `${t('pages.master.ovk.resAddSuccess')}`)
                    setLoading(false)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.master.ovk.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, values['year-picker'].format('MM/DD/YYYY'), unit)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreateOvkComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            ovkType={props.ovkType}
            ovkMedia={props.ovkMedia}
            ovkUnit={props.ovkUnit}
            ovkShape={props.ovkShape}
            ovkUnitRecording={props.ovkUnitRecording}
            setSuffix={setSuffix}
            suffix={suffix}
            setLoading={setLoading}
            unit={unit}
            setUnit={setUnit}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    ovkUnit: state.ovk.dataOvkUnit,
    ovkUnitRecording: state.ovk.dataOvkUnitRecording,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateOvkPage)
export default page