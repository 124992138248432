import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { errMessage, validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import CreateStokOvkComponent from '../../../../../modules/dashboard-peternak/adjustment-stock-component/ovk-component/ovk-aksi-component/create-adjustment-ovk-component'
import { createAdjustmentOvk } from './query-adjustment-ovk-page'

function CreateAdjustmentOvkPage(props) {
    const { setLoading, fetchData, idMonthlyStockOvk, object, locked, idGudangOvk, t } = props
    const [visible, setVisible] = useState(false)

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values, idMonthlyStockOvk) => {
        API.post(createAdjustmentOvk(values, idMonthlyStockOvk, idGudangOvk))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.stockflow.resAddAdjOvkFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.createAdjustmentOvk._id) {
                    validationMessage('success', `${t('pages.stockflow.resAddAdjOvkSuccess')}`)
                    fetchData(idMonthlyStockOvk, idGudangOvk)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.stockflow.resAddAdjOvkFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, idMonthlyStockOvk)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreateStokOvkComponent
            showModal={showModal}
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            setVisible={setVisible}
            ovk={props.ovk}
            locked={locked}
            object={object}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    ovk: state.ovk.dataOvk,
    idMonthlyStockOvk: state.adjustmentOvk.idMonthlyStockOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateAdjustmentOvkPage)
export default page