import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { Form, Row, Col, InputNumber, Select, Button, Card, Breadcrumb, Layout } from 'antd'
import { validationMessage } from '../../../../common/component/notification/notification'
import { updateRecordPenyakit } from '../rearing-record-aksi-page/query-rearing-record'
import { navigate } from '../../../../common/store/action'
import CONSTANTS from '../../../../common/utils/Constants'
import Loading from '../../../../common/component/loading/loading-container'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function EditPenyakitPage(props) {
    const { age, penyakit, detailPenyakit, navigate } = props
    const [loading, setLoading] = useState(false)
    const { Option } = Select
    const { Content } = Layout
    const [form] = Form.useForm()
    const { t } = useTranslation()

    useEffect(() => {
        async function getDetailData() {
            if (props.detailPenyakit) {
                setLoading(true)
                setLoading(false)
            }
        }
        getDetailData()
    }, [props])

    const postData = async (values) => {
        setLoading(true)
        await API.post(updateRecordPenyakit(props.detailPenyakit._id, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.recording.disease.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.updateDiseaseRecord._id) {
                    props.navigate(CONSTANTS.RECORDING_MENU_KEY)
                    validationMessage('success', `${t('pages.recording.disease.resEditSuccess')}`)
                    setLoading(false)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.recording.disease.resEditFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
    }

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><Link to="/peternak/recording"><span>{t("pages.recording.title")}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><Link to="/peternak/recording/detail"><span>{t("pages.recording.detail")}{age}</span></Link></Breadcrumb.Item>
                        <Breadcrumb.Item><span>{t("pages.recording.disease.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Loading loading={loading}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card title={t('pages.recording.disease.edit')} bordered={false}>
                                <Form onFinish={handleSubmit} form={form} name="basic"
                                    initialValues={{
                                        remember: true, keterangan: '', jumlahAyam: detailPenyakit.number,
                                        penyakit: detailPenyakit.disease
                                    }}>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content" >{t("pages.recording.disease.title")}</span>
                                            <Form.Item
                                                name="penyakit" rules={[{ required: true, message: `${t("pages.recording.disease.msgDisease")}` }]}
                                            >
                                                <Select
                                                    showSearch
                                                    labelInValue
                                                    placeholder={t("pages.recording.disease.phDisease")}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular mt-5"
                                                >
                                                    {
                                                        penyakit.map(data =>
                                                            <Option
                                                                key={data._id}
                                                                value={data._id}
                                                            >{data.name}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <span className="black font-semi-bold font-title-content" >{t("pages.recording.disease.qty")}</span>
                                            <Form.Item
                                                name="jumlahAyam" rules={[{ required: true, message: `${t("pages.recording.disease.msgDisease")}` }]}
                                            >
                                                <InputNumber
                                                    name='jumlahAyam'
                                                    placeholder="1000..."
                                                    className="input-number mt-5"
                                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Button
                                        onClick={() => navigate(CONSTANTS.RECORDING_MENU_KEY)}
                                    >
                                        {t('general.cancel')}
                                    </Button>
                                    <Button
                                        style={{ marginLeft: 8, marginTop: 0 }}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        {t('general.edit')}
                                    </Button>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    penyakit: state.penyakit.dataPenyakit,
    age: state.rearingRecord.age,
    idRearingRecord: state.rearingRecord.idRearingRecord,
    detailPenyakit: state.rearingRecord.detailDiseaseRecord,
})
const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPenyakitPage)
export default page