import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { navigate } from '../../../../common/store/action'
import CONSTANTS from '../../../../common/utils/Constants'
import Loading from '../../../../common/component/loading/loading-container'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'

import { getPeriodeStockFlow, getListYear, createStockflow, newStockflow, delStockflow, lockPeriodeStok, unlockPeriodeStok } from './periode-aksi-page/query-periode'
import { getMonthPeriode, getYearPeriode } from '../../../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-action'
import { getIdMonthlyStock as getIdMonthlyStockFeed } from '../../../../modules/dashboard-peternak/adjustment-stock-component/pakan-component/store/adjustment-pakan-action'
import { getIdMonthlyStock as getIdMonthlyStockOvk } from '../../../../modules/dashboard-peternak/adjustment-stock-component/ovk-component/store/adjustment-ovk-action'

import { Layout, Space, Button, Input, Table, Row, Col, Breadcrumb, Tooltip, Select, Modal, Form, DatePicker, Dropdown, Menu } from 'antd'
import { faInfoCircle, faLock, faLockOpen, faSlidersH, faTrash, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { SearchOutlined, ExclamationCircleOutlined, DownOutlined, CalendarOutlined, PlusSquareOutlined, DeleteOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Highlighter from 'react-highlight-words'

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

import { useTranslation } from 'react-i18next'
import { isMobileOnly } from 'react-device-detect'
import { useHistory } from 'react-router-dom'

function PeriodePage(props) {
    const { getIdMonthlyStockFeed, getIdMonthlyStockOvk, getMonthPeriode, getYearPeriode, navigate, profil,
        idGudangPakan, idGudangOvk } = props
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [periode, setPeriode] = useState([])
    const [year, setYear] = useState(moment().format('YYYY'))
    const [yearRange, setYearRange] = useState([])
    const [count, setCount] = useState(1)

    const { confirm } = Modal
    const { Option } = Select
    const { Content } = Layout
    const [form] = Form.useForm()
    const history = useHistory()

    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')

    const { t } = useTranslation()

    /**Handle get data */

    const fetchDataPeriode = (year) => {
        setLoading(true)
        API.get(getPeriodeStockFlow('', 0, 0, year))
            .then(res => {
                setPeriode(res.data.data.listMonthlyStocks.monthlyStocks)
                setCount(res.data.data.listMonthlyStocks.totalCount)
                setLoading(false)
            })
    }

    const fecthListYear = () => {
        API.get(getListYear('', 0, 0))
            .then(res => {
                if (res.data.data.listYearStockFlows.totalCount === 0) {
                    let yearOptions = []
                    let minYears = moment().format('YYYY')

                    let maxYears = moment().format('YYYY')
                    for (; minYears <= maxYears; minYears++) {
                        yearOptions.push(minYears)
                    }
                    setYearRange(yearOptions)
                } else {
                    setYearRange(res.data.data.listYearStockFlows.listYearStockFlows)
                }
            })
    }

    useEffect(() => {
        fetchDataPeriode(year)
        fecthListYear()
    }, [year])

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t("general.search")} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const createData = () => {
        setLoading(true)
        API.get(createStockflow())
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.stockflow.resAddFeedFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createMonthlyStock._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.resAddFeedSuccess')}`)
                    fetchDataPeriode(year)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.stockflow.resAddFeedFailed')}`, error.mesage)
                setLoading(false)
            })
    }
    const createNewData = (date) => {
        setLoading(true)
        API.get(newStockflow(date))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.stockflow.resAddFeedFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.newMonthlyStock._id) {
                    validationMessage('success', `${t('pages.stockflow.resAddFeedSuccess')}`)
                    fetchDataPeriode(year)
                    setVisible(false)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.stockflow.resAddFeedFailed')}`, error.message)
                setLoading(false)
                setVisible(false)
            })
    }
    const createConfirm = () => {
        confirm({
            title: `${t('pages.stockflow.addFeedConfirm')}`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            maskClosable: true,
            onOk: () => {
                createData()
            },
            onCancel() {
                console.log('Cancel')
            }
        })
    }

    const closePeriode = (month, periode) => {
        confirm({
            title: `${t('pages.stockflow.lockFeedConfirm')} ${periode}?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: `${t('general.cancel')}`,
            maskClosable: true,
            onOk: () => {
                close(month)
            },
        })
    }

    const close = (_id) => {
        setLoading(true)
        API.post(lockPeriodeStok(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.stockflow.resLockFeedFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.lockMonthlyStock._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.resLockFeedSuccess')}`)
                    fetchDataPeriode(year)
                } else if (res.data.data.lockMonthlyStock.ok !== null) {
                    validationMessage('success', `${t('pages.stockflow.resRelockFeedSuccess')}`)
                    fetchDataPeriode(year)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.stockflow.resLockFeedFailed')}`, error.message)
                setLoading(false)
            })
    }

    const openPeriode = (_id, periode) => {
        confirm({
            title: `${t('pages.stockflow.unlockFeedConfirm')} ${periode}?`,
            icon: <ExclamationCircleOutlined />,
            maskClosable: true,
            onOk: () => {
                open(_id)
            },
        })
    }

    const open = (_id) => {
        setLoading(true)
        API.post(unlockPeriodeStok(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.stockflow.resUnlockFeedFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.unlockMonthlyStock._id !== null) {
                    validationMessage('success', `${t('pages.stockflow.resUnlockFeedSuccess')}`)
                    fetchDataPeriode(year)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.resUnlockFeedFailed')}`)
                setLoading(false)
            })
    }
    const deleteData = (_id) => {
        setLoading(true)
        API.delete(delStockflow(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.stockflow.resDelFeedFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.deleteMonthlyStock.deleted === true) {
                    validationMessage('success', `${t('pages.stockflow.resDelFeedSuccess')}`)
                    fetchDataPeriode(year)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.stockflow.resDelFeedFailed')}`)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.stockflow.delFeedConfirm')} ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" icon={<CalendarOutlined />}>
                <span onClick={() => setVisible(true)}>Manual</span>

            </Menu.Item>
            <Menu.Item key="2" onClick={() => createConfirm()} icon={<PlusSquareOutlined />}>
                Auto
            </Menu.Item>
        </Menu>
    )

    const detailFeed = useCallback((m, y) => {
        getMonthPeriode(m)
        getYearPeriode(y)
        history.replace(`/peternak/stock-flow-periode/${idGudangPakan}/pakan`)
    }, [history, idGudangPakan, getMonthPeriode, getYearPeriode])

    const adjustmentFeed = useCallback((value, m, y) => {
        getIdMonthlyStockFeed(value)
        getMonthPeriode(m)
        getYearPeriode(y)
        history.replace(`/peternak/stock-flow-periode/${idGudangPakan}/penyesuaian-pakan`)
    }, [history, idGudangPakan, getIdMonthlyStockFeed, getMonthPeriode, getYearPeriode])

    const detailOvk = useCallback((value, m, y) => {
        getIdMonthlyStockOvk(value)
        getMonthPeriode(m)
        getYearPeriode(y)
        history.replace(`/peternak/stock-flow-periode/${idGudangOvk}/ovk`)
    }, [history, idGudangOvk, getIdMonthlyStockOvk, getMonthPeriode, getYearPeriode])

    const adjustmentOvk = useCallback((value, m, y) => {
        getIdMonthlyStockOvk(value)
        getMonthPeriode(m)
        getYearPeriode(y)
        history.replace(`/peternak/stock-flow-periode/${idGudangOvk}/penyesuaian-ovk`)
    }, [history, idGudangOvk, getIdMonthlyStockOvk, getMonthPeriode, getYearPeriode])

    const detailLivebird = useCallback((m, y) => {
        getMonthPeriode(m)
        getYearPeriode(y)
        navigate(CONSTANTS.DETAIL_PERIODE_LIVEBIRD_KEY)
    }, [navigate, getMonthPeriode, getYearPeriode])


    const columns = [
        {
            title: `${t('pages.stockflow.period')}`,
            dataIndex: 'periode',
            key: 'periode',
            ...getColumnSearchProps('periode', `${t('pages.stockflow.period')}`),
        },
        {
            title: `${t('pages.stockflow.status')}`,
            dataIndex: 'status',
            key: 'status',
            ...getColumnSearchProps('status', `${t('pages.stockflow.status')}`),
        },
        {
            title: `${t('pages.stockflow.feed')}`,
            align: 'center',
            width: 130,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("general.detail")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }}
                            onClick={() => detailFeed(row.month, row.year)}
                        ><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('pages.stockflow.adj')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#02d456',
                            borderRadius: '5px'
                        }} onClick={() => adjustmentFeed(row.key, row.month, row.year)}>
                            <FontAwesomeIcon icon={faSlidersH} /></Button>
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: `${t('pages.stockflow.ovk')}`,
            align: 'center',
            width: 130,
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("general.detail")}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }}
                            onClick={() => detailOvk(row.key, row.month, row.year)}
                        ><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('pages.stockflow.adj')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#02d456',
                            borderRadius: '5px'
                        }} onClick={() => adjustmentOvk(row.key, row.month, row.year)}>
                            <FontAwesomeIcon icon={faSlidersH} /></Button>
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: `${t('pages.stockflow.livebird')}`,
            align: 'center',
            width: 80,
            render: (value, row, index) => (
                <Tooltip placement="top" title={t("general.detail")}>
                    <Button style={{
                        color: 'white',
                        backgroundColor: '#00923F',
                        borderRadius: '5px'
                    }}
                        onClick={() => detailLivebird(row.month, row.year)}
                    ><FontAwesomeIcon icon={faInfoCircle} /></Button>
                </Tooltip>
            ),
        },
        profil.permission.writeStockflow ? {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 130,
            align: 'center',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={row.locked === false ?
                        `${t('general.lock')}` :
                        `${t('general.unlock')}`}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: row.locked === false ? '#ff4a3d' : '#03a5fc',
                            borderRadius: '5px',
                        }} onClick={row.locked === false ? () => closePeriode(row.key, row.periode) :
                            () => openPeriode(row.key, row.periode)} >
                            <FontAwesomeIcon icon={row.locked === false ? faLock : faLockOpen} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#FF0303',
                            borderRadius: '5px'
                        }} onClick={() => hapusConfirm(row.key, row.periode)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            ),
        } : { width: 1 },
    ]

    const data = periode.map(({ _id, locked, month, year, createdAt }, index) => ({
        key: _id,
        id: _id,
        periode: month + ' ' + year,
        tahun: year,
        locked: locked,
        month: month,
        year: year,
        status: locked === true ?
            `${t('pages.stockflow.locked')}` :
            `${t('pages.stockflow.unlocked')}`,
        createdAt: createdAt,
    }))

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item><span>{t('pages.stockflow.period')}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <Row>
                    <Col lg={{ span: 4 }} xs={{ span: 24 }}>
                        <Select
                            showSearch
                            labelInValue
                            placeholder="Pilih Tahun"
                            optionFilterProp="children"
                            style={{ width: '100%' }}
                            value={{ value: year }}
                            className="select-kategori black font-regular mt-5 mb-10"
                            onChange={(value) => setYear(value.value)}
                        >
                            <Option key={'Semua'} value={'Semua'}>{t('pages.stockflow.all')}</Option>
                            {yearRange &&
                                yearRange.map(data =>
                                    <Option key={data} value={data}>{data}</Option>
                                )
                            }
                        </Select>
                    </Col>
                    <Col lg={{ span: 4, offset: 16 }} xs={{ span: 24, offset: 0 }}>
                        {count > 0 ?
                            <Dropdown.Button
                                hidden={!profil.permission.writeStockflow}
                                onClick={() => createConfirm()}
                                overlay={menu}
                                icon={<DownOutlined />}
                                className="button-menu mb-10"
                                style={{ backgroundColor: '#008ad4' }}
                            ><FontAwesomeIcon icon={faPlusSquare} className='mr-10' />
                                {t('pages.stockflow.addFeed')}
                            </Dropdown.Button>
                            :
                            <Button
                                hidden={!profil.permission.writeStockflow} style={{
                                    color: 'white',
                                    backgroundColor: '#008ad4',
                                    borderRadius: '5px',
                                    float: "right",
                                    border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
                                }}
                                onClick={() => setVisible(true)}
                            ><FontAwesomeIcon icon={faPlusSquare} className='mr-10' />
                                {t('pages.stockflow.addFeed')}
                            </Button>
                        }
                    </Col>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{ y: '60vh', x: isMobileOnly && 1000 }}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                    <Modal
                        visible={visible}
                        title={`Manual Input ${t('pages.stockflow.dateFeedPeriod')}`}
                        okText={t("general.add")}
                        width={800}
                        cancelText={t('general.cancel')}
                        onCancel={() => setVisible(false)}
                        onOk={() => {
                            form
                                .validateFields()
                                .then((values) => {
                                    form.resetFields()
                                    createNewData(values.date)
                                })
                                .catch((info) => {
                                    console.log('Validate Failed:', info)
                                })
                        }}
                    >
                        <Form
                            form={form}
                            layout="vertical"
                            name="form_in_modal"
                        >
                            <Row gutter={[16, 8]}>
                                <Col span={24}>
                                    <span className="font-semi-bold black font-title-medium">{t("pages.stockflow.dateFeedPeriod")}</span>
                                    <Form.Item
                                        name="date" rules={[{ required: true, message: `${t("pages.stockflow..msgDatePeriod")}` }]}
                                    >
                                        <DatePicker
                                            placeholder={t("pages.stockflow.phDatePeriod")}
                                            format={'MMMM YYYY'}
                                            picker='month'
                                            className="select-input-date mt-5"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    profil: state.profil.dataProfil,
    idGudangPakan: state.gudangPakan.idGudangPakan,
    idGudangOvk: state.gudangOvk.idGudangOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate, getMonthPeriode, getYearPeriode, getIdMonthlyStockFeed, getIdMonthlyStockOvk
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(PeriodePage)
export default page