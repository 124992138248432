import React from 'react'
import { Line } from 'react-chartjs-2'
import { Row, Col } from 'antd'
import './styles.scss'

function FeedUsageChart(props) {
    const { chartData, ages, t } = props
    const data = {
        labels: ages,
        datasets: chartData
    }
    const options = {
        type: 'line',
        animation: false,
        responsive: true,
        data: data,
        options: {
            scales: {
                xAxes: {
                    title: { display: true, text: 'Umur (hari)' },

                },
                yAxes: [
                    {
                        title: { display: true, text: 'Jumlah (kg)' },
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                        gridLines: {
                            drawOnArea: false,
                        },
                    },
                ]
            },
            interaction: {
                mode: 'nearest',
                intersect: false,
                axis: 'x'
            },
        },
    }

    return (
        <>
            <Row>
                <Col span={1}>
                    <h5
                        style={{ transform: 'rotate(270deg)', marginTop: 150, textAlign: 'center' }}>
                        {t('pages.dashboard.feed.qty')}</h5>
                </Col>
                <Col lg={23} sm={23} xs={0} style={{ marginLeft: 0, marginBottom: 30 }}>
                    <Line data={data} options={options} className='mb-5'
                        height={100}
                    />
                    <h5 style={{ textAlign: 'center' }}> {t('pages.dashboard.feed.age')}</h5>
                </Col>
                <Col lg={0} sm={0} xs={23} style={{ marginLeft: 0, marginBottom: 30 }}>
                    <Line data={data} options={options} className='mb-5'
                        height={250}
                    />
                    <h5 style={{ textAlign: 'center' }}> {t('pages.dashboard.feed.age')}</h5>
                </Col>
            </Row>
        </>
    )
}


export default FeedUsageChart