import React, { useState } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import CreateDeplesiComponent from '../../../../modules/dashboard-peternak/rearing-record-component/create-detail-rearing-component/create-deplesi-component'
import { createRecordDeplesi } from '../rearing-record-aksi-page/query-rearing-record'

function CreateDeplesiPage(props) {
    const { idRearingRecord, setLoading, fetchDataDeplesi, houseActive, t } = props
    const [visible, setVisible] = useState(false)

    const postData = async (values) => {
        await API.post(createRecordDeplesi(values, idRearingRecord))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.recording.depletion.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                if (res.data.data.createMutation._id) {
                    validationMessage('success', `${t('pages.recording.depletion.resAddSuccess')}`)
                    fetchDataDeplesi(idRearingRecord)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.recording.depletion.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreateDeplesiComponent
            navigate={props.navigate}
            visible={visible}
            setVisible={setVisible}
            handleSubmit={handleSubmit}
            t={t} houseActive={houseActive}
        />
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateDeplesiPage)
export default page