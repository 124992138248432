import {API} from '../../../../../common/api';
import { validationMessage } from '../../../../../common/component/notification/notification'
import * as actionType from './penyakit-action-type'

const startGetDataDiseases = () => ({
    type : actionType.START_GET_DATA
});

const finishGetDataDiseases = () => ({
    type : actionType.FINISH_GET_DATA
});

const setDataPenyakit = payload => ({
    type : actionType.SET_DATA_PENYAKIT,
    payload
});

export const getListDisease = (params) => (dispatch) => {
    dispatch(startGetDataDiseases());
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.diseases.diseases){
            dispatch(setDataPenyakit(res.data.data.diseases.diseases))
        } else {
            validationMessage('error', 'Login Gagal')
        }
        dispatch(finishGetDataDiseases());
    })
}


