import React, { useEffect, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Tooltip, Button, Modal, Input, Layout, Breadcrumb, Row, Col, Table, Tabs, Tag } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faTrash, faInfoCircle, faPlus, faEdit, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next"
import { useParams } from 'react-router-dom'

import ModalTambahStokOvk from './stok-ovk-aksi-page/tambah-stok-ovk-page'
import ModalEditStokOvk from './stok-ovk-aksi-page/edit-stok-ovk-page'
import { getIdStokOvk, getSatuanOvk, getSatuanRecordingOvk, getNamakOvk } from '../../../../modules/dashboard-peternak/stok-component/stok-ovk-component/store/stok-ovk-action'
import { getStokGudangOvk, addStokOvk, deleteStokOvk, detailStokOvk, updateStokOvk, getHistoryGudangOvk } from './stok-ovk-aksi-page/query-stok-ovk'
import { getNamaGudangOvk } from '../../../../modules/dashboard-peternak/master-component/gudang-ovk-component/store/gudang-ovk-action'
import { getListOvk } from '../../../../modules/dashboard-peternak/master-component/ovk-component/store/ovk-action'
import { getOvkList } from '../../../../app/dashboard-peternak/master-page/ovk-page/ovk-aksi-page/query-ovk'

import { navigate } from '../../../../common/store/action'
import { validationMessage } from '../../../../common/component/notification/notification'
import { API } from '../../../../common/api'
import CONSTANTS from '../../../../common/utils/Constants'
import Loading from '../../../../common/component/loading/loading-container'
import ButtonDashboard from '../../../../common/component/button/button-dashboard'
import { isMobileOnly } from 'react-device-detect'
import { setLoadingOvkWh } from '../../../../modules/dashboard-peternak/layout-component/store/layout-action'
import moment from 'moment'

function StokOvkPage(props) {
    const { confirm } = Modal
    const { getIdStokOvk, getListOvk, getSatuanOvk, getSatuanRecordingOvk,
        getNamakOvk, getNamaGudangOvk, profil, loading, setLoadingOvkWh } = props
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [visibleEdit, setVisibleEdit] = useState(false)
    const [object, setObject] = useState([])
    const [histories, setHistories] = useState([])
    const [activeKey, setActiveKey] = useState("1")
    const [idData, setIdData] = useState(null)
    const [detail, setDetail] = useState(null)
    const [stockId, setStockId] = useState(null)
    const [suffix, setSuffix] = useState('')
    const { Content } = Layout
    const { t } = useTranslation()
    const params = useParams()
    const { TabPane } = Tabs

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = (idGudangOvk) => {
        setLoadingOvkWh(true)
        API.get(getStokGudangOvk(idGudangOvk))
            .then(res => {
                setObject(res.data.data.ovkStocksWarehouse)
                setLoadingOvkWh(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoadingOvkWh(false)
            })
    }

    const fetchDataHistory = async (idGudangOvk, type) => {
        // if (idGudangOvk === 'Semua') {
        //     setHistories([])
        // }
        setLoadingOvkWh(true)
        await API.get(getHistoryGudangOvk(idGudangOvk, type))
            .then(res => {
                setHistories(res.data.data.ovkTransactionWarehouse.ovkTransactions)
                setLoadingOvkWh(false)
            }).catch((error) => {
                setHistories([])
                setLoadingOvkWh(false)
            })
    }

    const changeKey = useCallback((value) => {
        setActiveKey(value)
    }, [])

    useEffect(() => {
        if (params.ovkWarehouseId) {
            if (activeKey === "1") {
                fetchData(params.ovkWarehouseId)
            } else if (activeKey === '2') {
                fetchDataHistory(params.ovkWarehouseId, 'In')
            } else {
                fetchDataHistory(params.ovkWarehouseId, 'Out')
            }
        }
        // eslint-disable-next-line
    }, [activeKey, params.ovkWarehouseId])

    useEffect(() => {
        getListOvk(getOvkList('', 0, 0))
    }, [getListOvk])

    const deleteData = async (_id) => {
        setLoadingOvkWh(true)
        await API.delete(deleteStokOvk(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t("pages.ovkStock.resDelFailed")}`, res.data.errors[0].message)
                    setLoadingOvkWh(false)
                }
                else if (res.data.data.deleteOvkStock.deleted === true) {
                    validationMessage('success', `${t("pages.ovkStock.resDelSuccess")}`)
                    fetchData(params.ovkWarehouseId)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.ovkStock.resDelFailed")}`, error.message)
                setLoadingOvkWh(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t("pages.ovkStock.delConfirm")} ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const createStokBaru = () => {
        props.navigate(CONSTANTS.CREATE_STOK_OVK_MENU_KEY, params.ovkWarehouseId)
    }

    const showModal = useCallback((stockId, satuan) => {
        setStockId(stockId)
        setSuffix(satuan)
        setVisible(true)
    }, [])

    const postData = async (values, year) => {
        await API.post(addStokOvk(values, year, stockId))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t("pages.ovkStock.resAddFailed")}`, res.data.errors[0].message)
                } else if (res.data.data.createOvkTransaction._id) {
                    validationMessage('success', `${t("pages.ovkStock.resAddSuccess")}`)
                    fetchData(params.ovkWarehouseId)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.ovkStock.resAddFailed")}`, error.message)
                setLoadingOvkWh(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values, values['date_picker'].format('MM/DD/YYYY'))
        setVisible(false)
    }

    const handleCancel = useCallback((value) => {
        setVisible(false)
    }, [])

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(detailStokOvk(idData))
                    .then(res => {
                        setDetail(res.data.data.ovkStock)
                        setVisibleEdit(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisibleEdit(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showEditStok = useCallback((value) => {
        if (value === idData)
            setVisibleEdit(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancelEdit = useCallback(() => {
        setVisibleEdit(false)
    }, [])

    const updatedData = async (values) => {
        await API.post(updateStokOvk(idData, params.ovkWarehouseId, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t("pages.ovkStock.resEditFailed")}`, res.data.errors[0].message)
                    setLoadingOvkWh(false)
                } else if (res.data.data.updateOvkStock._id) {
                    validationMessage('success', `${t("pages.ovkStock.resEditSuccess")}`)
                    fetchData(params.ovkWarehouseId)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.ovkStock.resEditFailed")}`, error.message)
                setLoadingOvkWh(false)
            })
    }

    const handleSubmitEdit = (values) => {
        updatedData(values)
        setVisibleEdit(false)
        setIdData(null)
        setLoadingOvkWh(true)
    }

    const detailTranskasi = useCallback((value, satuan, satuanRecording, nama, gudang) => {
        getSatuanOvk(satuan)
        getIdStokOvk(value)
        getSatuanRecordingOvk(satuanRecording)
        getNamakOvk(nama)
        getNamaGudangOvk(gudang)
        props.navigate(CONSTANTS.STOK_OVK_MENU_KEY, `${params.ovkWarehouseId}/transaksi/${value}`)
    }, [props, getIdStokOvk, getSatuanOvk, getSatuanRecordingOvk, getNamakOvk, getNamaGudangOvk, params.ovkWarehouseId])

    const columns = [
        {
            title: `${t('pages.ovkStock.ovk')}`,
            dataIndex: 'ovk',
            key: 'ovk',
            width: 100,
            ...getColumnSearchProps('ovk'),
        },
        {
            title: `${t('pages.ovkStock.name')}`,
            dataIndex: 'nama',
            key: 'nama',
            width: 80,
        },
        {
            title: `${t('pages.ovkStock.supplier')}`,
            dataIndex: 'perusahaan',
            key: 'perusahaan',
            width: 80,
        },
        {
            title: `${t('pages.ovkStock.balance')}`,
            dataIndex: 'total',
            key: 'total',
            width: 70,
            align: 'right'
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            fixed: !isMobileOnly && 'right',
            align: 'center',
            width: '15rem',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('pages.ovkStock.addStock')}>
                        <Button
                            hidden={profil.permission.writeStock ? false : true}
                            style={{
                                color: 'white',
                                backgroundColor: '#0f6cff',
                                borderRadius: '5px'
                            }} onClick={() => showModal(row.key, row.satuanOvk)}><FontAwesomeIcon icon={faPlus} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button
                            hidden={profil.permission.writeStock ? false : true}
                            style={{
                                color: 'white',
                                backgroundColor: '#FFA903',
                                borderRadius: '5px'
                            }} onClick={() => showEditStok(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.detail')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }} onClick={() => detailTranskasi(row.key, row.satuanOvk, row.satuanRecordingOvk, row.ovk, row.gudang)}><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button
                            hidden={profil.permission.writeStock ? false : true}
                            style={{
                                color: 'white',
                                backgroundColor: '#FF0303',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]

    const data = object.map(({ _id, stockName, ovkWarehouse, ovk, sisaStok }, index) => ({
        key: _id,
        ovk: ovk.name,
        nama: stockName,
        perusahaan: ovk.producer,
        gudang: ovkWarehouse.name,
        total: <NumberFormat value={sisaStok} displayType={'text'} thousandSeparator={true} suffix={' ' + ovk.ovkUnit.name} />,
        satuanOvk: ovk.ovkUnit.name,
        satuanRecordingOvk: ovk.ovkUnitRecording?.name || ovk.ovkUnit.name
    }))

    const columnsHistory = [
        {
            title: `${t('pages.ovkStock.trxDate')}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            width: 300,
            ...getColumnSearchProps('tanggal'),
            render: (value, row, index) => (
                <>{value} {row.type === 'Mutation' && <Tag color='green'>{t('pages.ovkStock.mutation')}</Tag>}</>
            )
        },
        {
            title: `${t('pages.ovkStock.ovk')}`,
            dataIndex: 'ovk',
            key: 'ovk',
            width: 200,
            align: 'center',
            ...getColumnSearchProps('ovk'),
        },
        {
            title: `${t('pages.ovkStock.price')}`,
            dataIndex: 'harga',
            key: 'harga',
            align: 'right',
            ...getColumnSearchProps('price'),
        },
        {
            title: `${t('pages.ovkStock.qty')}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            align: 'right',
            ...getColumnSearchProps('total'),
        },
        {
            title: `${t('pages.ovkStock.docNumber')}`,
            dataIndex: 'ttb',
            key: 'ttb',
            align: 'left',
            ...getColumnSearchProps('ttb'),
        },
        {
            title: `Kandang`,
            dataIndex: 'house',
            key: 'house',
            width: 250,
            ...getColumnSearchProps('house'),
        },
    ]

    const dataHistory = histories.map(({ _id, number, storeTime, priceOvkTransaction, ttb, typeTransaction, ovkStock, mutationOvkStock, ovk }, index) => ({
        key: _id,
        ovk: ovkStock.ovk.name,
        price: priceOvkTransaction,
        total: number,
        jumlah: <NumberFormat value={number} displayType={'text'} thousandSeparator={true} suffix={' ' + ovkStock.ovk.ovkUnit.name} />,
        tanggal: moment(new Date(parseInt(storeTime))).format("DD MMMM YYYY"),
        harga: <NumberFormat value={priceOvkTransaction} displayType={'text'} thousandSeparator={true} prefix={'Rp '} />,
        ttb: ttb,
        type: typeTransaction,
        house: typeTransaction === 'Mutation' ?
            <span>{mutationOvkStock?.ovkWarehouse?.houses[0]?.name}
                <br />Asal: {ovkStock?.ovkWarehouse?.name}</span>
            :
            ovkStock?.ovkWarehouse?.houses[0]?.name,
    }))
    const columnsOut = [
        {
            title: `${t('pages.ovkStock.trxDate')}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            ...getColumnSearchProps('tanggal'),
            render: (value, row, index) => (
                <>{value} {row.type === 'Mutation' && <Tag color='green'>{t('pages.ovkStock.mutation')}</Tag>}</>
            )
        },
        {
            title: `${t('pages.ovkStock.ovk')}`,
            dataIndex: 'ovk',
            key: 'ovk',
            width: 200,
            align: 'center',
            ...getColumnSearchProps('ovk'),
        },
        {
            title: `${t('pages.ovkStock.qty')}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            align: 'right',
            ...getColumnSearchProps('jumlah'),
        },
        {
            title: `Kandang`,
            dataIndex: 'house',
            key: 'house',
            align: 'center',
            ...getColumnSearchProps('house'),
        },
    ]

    const dataOut = histories.map(({ _id, numberRecording, storeTime, typeTransaction, ovkStock, mutationOvkStock, ovk, house }, index) => ({
        key: _id,
        ovk: ovkStock.ovk.name,
        total: numberRecording,
        jumlah: <NumberFormat value={numberRecording} displayType={'text'} thousandSeparator={true} suffix={' ' + ovkStock.ovk.ovkUnitRecording.name} />,
        tanggal: moment(new Date(parseInt(storeTime))).format("DD MMMM YYYY"),
        type: typeTransaction,
        house: typeTransaction === 'Mutation' ?
            <span>{house?.name}
                <br />Destinasi: {mutationOvkStock?.ovkWarehouse?.name}</span>
            : house?.name,
    }))

    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section" gutter={[16, 16]}>
                    <Col lg={12} xs={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.ovkStock.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={12} xs={24}>
                        {profil.permission.writeStock &&
                            <div>
                                <ButtonDashboard
                                    text={t('pages.ovkStock.add')}
                                    height={20}
                                    backgroundColor="#008ad4"
                                    borderRadius="5px"
                                    className="font-semi-medium font-content-title button-add"
                                    textColor="white"
                                    icon={faPlusSquare}
                                    onClick={() => createStokBaru()}
                                />
                            </div>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <div className="dashboard-section-tabs-recording">
                            <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                <TabPane tab={'Jenis OVK'} key="1">
                                    <Loading loading={loading}>
                                        <Table
                                            columns={columns}
                                            dataSource={data}
                                            bordered
                                            pagination={false}
                                            className="table-dashboard"
                                            scroll={{ y: '75vh', x: isMobileOnly && 1000 }}
                                        />
                                    </Loading>
                                </TabPane>
                                <TabPane tab={'History Penambahan'} key="2">
                                    <Loading loading={loading}>
                                        <Table
                                            columns={columnsHistory}
                                            dataSource={dataHistory}
                                            bordered
                                            pagination={false}
                                            className="table-dashboard"
                                            scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                                        />
                                    </Loading>
                                </TabPane>
                                <TabPane tab={'History Penggunaan'} key="3">
                                    <Loading loading={loading}>
                                        <Table
                                            columns={columnsOut}
                                            dataSource={dataOut}
                                            bordered
                                            pagination={false}
                                            className="table-dashboard"
                                            scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                                        />
                                    </Loading>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                    <ModalTambahStokOvk visible={visible} handleSubmit={handleSubmit}
                        handleCancel={handleCancel} suffix={suffix} t={t} />
                    <ModalEditStokOvk visibleEdit={visibleEdit} handleSubmit={handleSubmitEdit}
                        handleCancel={handleCancelEdit} detail={detail} t={t} />
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    profil: state.profil.dataProfil,
    loading: state.layout.loadingOvkWh,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdStokOvk,
    getListOvk,
    getSatuanOvk,
    getSatuanRecordingOvk,
    getNamakOvk,
    getNamaGudangOvk,
    setLoadingOvkWh,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(StokOvkPage)
export default page