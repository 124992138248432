import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { navigate } from '../../../../common/store/action';
import { getDoc } from '../../master-page/doc-page/doc-aksi-page/query-doc'
import { API } from '../../../../common/api'
import { Modal, Form, Row, Col, Input, DatePicker, Select, InputNumber } from 'antd'
import moment from 'moment'

function EditRearingPage(props) {
    const { visible, handleCancel, detail, handleSubmit, kandang, t } = props
    const { TextArea } = Input
    const { Option } = Select
    const [data, setData] = useState(detail)
    const [doc, setDoc] = useState([])

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    const fetchDataDoc = () => {
        API.get(getDoc("", 0, 0))
            .then(res => {
                setDoc(res.data.data.docs.docs)
            }).catch((error) => {
                setDoc(null)
            })
    }

    useEffect(() => {
        if (detail !== '') { fetchDataDoc() }
    }, [detail])
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t("pages.rearing.edit")}
                okText={t("general.edit")}
                width={800}
                centered
                cancelText={t("general.cancel")}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        nama_periode: data.name,
                        bobot: data.chickInWeight,
                        populasiAwal: data.population,
                        populasiHpp: data.populationHpp,
                        date_picker: moment(new Date(parseInt(data.chickInDate))),
                        kandang: data.house,
                        doc: data.doc ? data.doc : '',
                        harga: data.price,
                        ppl: data.ppl,
                        keterangan: data.otherInformation,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.name")}</span>
                            <Form.Item
                                name="nama_periode" rules={[{ required: true, message: `${t("pages.rearing.msgName")}` }]}
                            >
                                <Input
                                    placeholder={t("pages.rearing.phName")}
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.date")}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, type: 'object', message: `${t("pages.rearing.msgDate")}` }]}
                            >
                                <DatePicker
                                    placeholder={t("pages.rearing.phDate")}
                                    className="select-input-date"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{t("pages.rearing.house")}</span>
                            <Form.Item
                                name="kandang" rules={[{ required: true, message: `${t("pages.rearing.msgHouse")}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t("pages.rearing.phHouse")}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        kandang.map(data =>
                                            <Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{t("pages.rearing.doc")}</span>
                            <Form.Item
                                name="doc" rules={[{ required: true, message: `${t("pages.rearing.msgDoc")}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t("pages.rearing.phDoc")}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        doc.map(data =>
                                            <Option
                                                value={data._id}
                                            >{data.name} - {data.strain.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.weight")}</span>
                            <Form.Item
                                name="bobot" rules={[{ required: true, message: `${t("pages.rearing.msgWeight")}` }]}
                            >
                                <Input
                                    placeholder="5..."
                                    className="input-suffix"
                                    suffix="gram"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.price")}</span>
                            <Form.Item
                                name="harga" rules={[{ required: true, type: 'number', message: `${t("pages.rearing.msgPrice")}` }]}
                            >
                                <InputNumber
                                    placeholder="25000..."
                                    className="input-number"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.population")}</span>
                            <Form.Item
                                name="populasiAwal" rules={[{ required: true, message: `${t("pages.rearing.msgPopulation")}` }]}
                            >
                                <Input
                                    placeholder="1000..."
                                    className="input-suffix"
                                    suffix={t("pages.rearing.chicken")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.population")} HPP</span>
                            <Form.Item
                                name="populasiHpp" rules={[{ required: true, message: `${t("pages.rearing.msgPopulation")}` }]}
                            >
                                <Input
                                    placeholder="1000..."
                                    className="input-suffix"
                                    suffix={t("pages.rearing.chicken")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.rearing.ppl")}</span>
                            <Form.Item
                                name="ppl" rules={[{ required: true, message: `${t("pages.rearing.msgPpl")}` }]}
                            >
                                <Input
                                    placeholder="Irfan..."
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content" >{t("pages.rearing.info")}</span>
                            <Form.Item name="keterangan">
                                <TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={t("pages.rearing.phInfo")}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    kandang: state.layout.dataKandang,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditRearingPage)
export default page