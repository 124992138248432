import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Space, Tooltip, Button, Layout, Breadcrumb, Row, Col, Table, Modal } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DeleteOutlined } from '@ant-design/icons'
import { faEdit, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import { API } from '../../../common/api'
import CONSTANTS from '../../../common/utils/Constants'
import Loading from '../../../common/component/loading/loading-container'
import { navigate } from '../../../common/store/action'
import { errMessage } from '../../../common/component/notification/notification'

import { deleteStandardEnv, detailStandardEnv, getStandardsEnv, updateStandardEnv } from './iot-aksi-page/query-iot'
import CreateStandardEnv from './iot-aksi-page/create-standard-env'
import EditStandardEnv from './iot-aksi-page/edit-standard-env'
import { getIdStdEnv, getNameStdEnv } from './store/iot-action'

function EwsPage(props) {
    const { getIdStdEnv, getNameStdEnv, profil } = props
    const [object, setObject] = useState([])
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const { Content } = Layout
    const [detail, setDetail] = useState('')
    const { confirm } = Modal
    const [idData, setIdData] = useState(null)
    const { t } = useTranslation()

    const fetchData = () => {
        setLoading(true)
        API.get(getStandardsEnv())
            .then(res => {
                setObject(res.data.data.standardEnvironments.standardEnvironments)
                setLoading(false)
            }).catch((error) => {
                console.log(error.message)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    const showDetail = useCallback((id, name) => {
        getIdStdEnv(id)
        getNameStdEnv(name)
        props.navigate(CONSTANTS.STANDAR_ENV_DETAIL_KEY)
    }, [getIdStdEnv, getNameStdEnv, props])

    const data = object.map(({ _id, name, house }, index) => ({
        key: _id,
        id: _id,
        name: name,
        house: house?.name,
    }))

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteStandardEnv(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.monitor.resDelStdFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.deleteStandardEnvironment.deleted === true) {
                    errMessage('success', `${t('pages.monitor.resDelStdSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.monitor.resDelStdFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.monitor.delStdConfirm')} ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(detailStandardEnv(idData))
                    .then(res => {
                        setDetail(res.data.data.standardEnvironment)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = (values) => {
        API.post(updateStandardEnv(idData, values, props.role))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.monitor.resEditStdFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.updateStandardEnvironment._id) {
                    errMessage('success', `${t('pages.monitor.resEditStdSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.monitor.resEditStdFailed')}`, error.message)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }
    const columns = [
        {
            title: `${t('pages.monitor.envName')}`,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: `${t('pages.monitor.house')}`,
            dataIndex: 'house',
            key: 'house',
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            width: 200,
            align: 'center',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t('general.detail')}>
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#00923F',
                            borderRadius: '5px'
                        }}
                            onClick={() => showDetail(row.id, row.name)}
                        ><FontAwesomeIcon icon={faInfoCircle} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.edit')}>
                        <Button
                            hidden={!profil.permission.writeIot}
                            style={{
                                color: 'white',
                                backgroundColor: '#FFA903',
                                borderRadius: '5px'
                            }}
                            onClick={() => showModal(row.id)}
                        ><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t('general.del')}>
                        <Button
                            hidden={!profil.permission.writeIot}
                            style={{
                                color: 'white',
                                backgroundColor: '#FF0303',
                                borderRadius: '5px'
                            }}
                            onClick={() => hapusConfirm(row.id, row.name)}
                        ><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            )
        }
    ]
    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col span={12}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.monitor.ews')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12} hidden={!profil.permission.writeIot}>
                        <CreateStandardEnv fetchData={fetchData} kandang={props.kandang}
                            setLoading={setLoading} t={t} role={props.role} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{ y: '70vh' }}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                </Row>
                <EditStandardEnv visible={visible} idData={idData} t={t} kandang={props.kandang}
                    handleCancel={handleCancel} detail={detail} handleSubmit={handleSubmit} role={props.role} />
            </Content>
        </Layout >
    )
}

const mapStateToProps = state => ({
    role: state.profil.dataProfil.type,
    kandang: state.layout.dataKandang,
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdStdEnv,
    getNameStdEnv
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EwsPage)
export default page