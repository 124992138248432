import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Switch, Row, Col } from 'antd'

function EditPermission(props) {
    const { visible, detail, setVisible, changePermission, loading, t } = props
    const [form] = Form.useForm()
    const [data, setData] = useState(detail)
    const [touched, setTouched] = useState(false)

    const layout = {
        labelCol: {
            span: 16,
        },
    }

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData(null)
            }
            else {
                setData(detail)
            }
        }
        setDetailData()

        data && form.setFieldsValue({
            readDashboard: data.readDashboard,
            readRearing: data.readRearing,
            writeRearing: data.writeRearing,
            readRecording: data.readRecording,
            writeRecording: data.writeRecording,
            editRecording: data.editRecording,
            nominalRecording: data.nominalRecording,
            staffMode: data.staffMode,
            readStock: data.readStock,
            writeStock: data.writeStock,
            readStockflow: data.readStockflow,
            writeStockflow: data.writeStockflow,
            readIot: data.readIot,
            writeIot: data.writeIot,
            readMaster: data.readMaster,
            writeMaster: data.writeMaster,
            readUsers: data.readUsers,
            writeUsers: data.writeUsers,
            createSubscription: data.createSubscription,
        })

    }, [data, detail, form])

    // console.log(data)

    return (
        <Modal
            visible={visible}
            title='Manage Permission'
            width={600} centered
            cancelText={t('general.cancel')}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <Form
                form={form}
                {...layout}
                labelAlign='left'
                colon={false} requiredMark={false}
                onValuesChange={(e, values) => {
                    if (!values.readRearing) {
                        values.writeRearing = false
                    }
                    if (!values.readRecording) {
                        values.writeRecording = false
                        values.editRecording = false
                        values.nominalRecording = false
                    }
                    if (!values.readStock) {
                        values.writeStock = false
                    }
                    if (!values.readStockflow) {
                        values.writeStockflow = false
                    }
                    if (!values.readIot) {
                        values.writeIot = false
                    }
                    if (!values.readMaster) {
                        values.writeMaster = false
                    }
                    if (!values.readUsers) {
                        values.writeUsers = false
                    }
                    if (values.staffMode) {
                        values.writeUsers = false
                        values.editRecording = false
                        values.writeRearing = false
                        values.nominalRecording = false
                        values.writeMaster = false
                        values.readIot = false
                        values.writeIot = false
                        values.writeStockflow = false
                        values.writeStock = false
                        values.createSubscription = false
                    }
                    changePermission(values)
                    setTouched(Object.keys(e)[0])
                }
                }
                initialValues={{
                    readDashboard: detail?.readDashboard,
                    readRearing: detail?.readRearing,
                    writeRearing: detail?.writeRearing,
                    readRecording: detail?.readRecording,
                    writeRecording: detail?.writeRecording,
                    editRecording: detail?.editRecording,
                    nominalRecording: detail?.nominalRecording,
                    staffMode: detail?.staffMode,
                    readStock: detail?.readStock,
                    writeStock: detail?.writeStock,
                    readStockflow: detail?.readStockflow,
                    writeStockflow: detail?.writeStockflow,
                    readIot: detail?.readIot,
                    writeIot: detail?.writeIot,
                    readMaster: detail?.readMaster,
                    writeMaster: detail?.writeMaster,
                    readUsers: detail?.readUsers,
                    writeUsers: detail?.writeUsers,
                    createSubscription: detail?.createSubscription,
                }}

            >
                <Row gutter={[16, 16]}>
                    <Col lg={12} sm={24}>
                        <Form.Item
                            label="Read Dashboard" name="readDashboard" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'readDashboard' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Read Rearing" name="readRearing" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'readRearing' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Write Rearing" name="writeRearing" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'writeRearing' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Read Recording" name="readRecording" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'readRecording' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Write Recording" name="writeRecording" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'writeRecording' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Edit Recording" name="editRecording" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'editRecording' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Show Nominal Recording" name="nominalRecording" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'nominalRecording' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Staff Mode" name="staffMode" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'staffMode' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Read IoT" name="readIot" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'readIot' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Write IoT" name="writeIot" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'writeIot' && loading}
                            />
                        </Form.Item>
                    </Col>
                    <Col lg={12} sm={24}>
                        <Form.Item
                            label="Read Stock" name="readStock" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'readStock' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Write Stock" name="writeStock" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'writeStock' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Read Stockflow" name="readStockflow" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'readStockflow' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Write Stockflow" name="writeStockflow" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'writeStockflow' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Read Master" name="readMaster" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'readMaster' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Write Master" name="writeMaster" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'writeMaster' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Read Users" name="readUsers" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'readUsers' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Write Users" name="writeUsers" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'writeUsers' && loading}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Create Subscription" name="createSubscription" valuePropName="checked"
                            rules={[{ required: true, message: `${t('pages.master.staff.msgName')}` }]}
                        >
                            <Switch
                                loading={touched === 'createSubscription' && loading}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPermission)
export default page