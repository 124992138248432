import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../common/api'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import CreatePanenComponent from '../../../../modules/dashboard-peternak/rearing-record-component/create-detail-rearing-component/create-panen-component'
import { createRecordPanen } from '../rearing-record-aksi-page/query-rearing-record'
import { getCustomer } from '../../master-page/customer-page/customer-aksi-page/query-customer'

function CreatePanenPage(props) {
    const { idRearingRecord, setLoading, fetchDataPanen, houseActive, t } = props
    const [customer, setCustomer] = useState([])
    const [visible, setVisible] = useState(false)

    const fetchData = () => {
        API.get(getCustomer('', 0, 0))
            .then(res => {
                setCustomer(res.data.data.customers.customers)
            }).catch((error) => {
                setCustomer([])
            })
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line
    }, [])

    const postData = async (values) => {
        await API.post(createRecordPanen(values, idRearingRecord))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.recording.harvest.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.createHarvest._id) {
                    validationMessage('success', `${t('pages.recording.harvest.resAddSuccess')}`)
                    fetchDataPanen(idRearingRecord)
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.recording.harvest.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <CreatePanenComponent
            navigate={props.navigate}
            visible={visible}
            setVisible={setVisible}
            handleSubmit={handleSubmit}
            customer={customer}
            t={t} houseActive={houseActive}
        />
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePanenPage)
export default page