import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import OvkComponent from '../../../../modules/dashboard-peternak/master-component/ovk-component/ovk-component'
import NumberFormat from 'react-number-format'
import { useTranslation } from "react-i18next"

/*Import Moment Js */
import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
/*End Import Moment Js */

import { getListOvkUnit, getListOvkUnitRecording } from '../../../../modules/dashboard-peternak/master-component/ovk-component/store/ovk-action'
import { getOvk, getOvkUnit, deleteOvk, detailOvk, udpateOvk, getOvkUnitRecording } from './ovk-aksi-page/query-ovk'
import { API } from '../../../../common/api'
import { errMessage } from '../../../../common/component/notification/notification'
import { isMobileOnly } from 'react-device-detect'

function OvkPage(props) {
    const { getListOvkUnit, getListOvkUnitRecording, role, profil } = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState('')
    const [idData, setIdData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const { t } = useTranslation()

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = async () => {
        setLoading(true)
        await API.get(getOvk('', 0, 0))
            .then(res => {
                setObject(res.data.data.ovks.ovks)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        getListOvkUnit(getOvkUnit('', 0, 0))
        getListOvkUnitRecording(getOvkUnitRecording('', 0, 0))
    }, [getListOvkUnit, getListOvkUnitRecording])

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deleteOvk(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.ovk.resDelFailed')}`, res.data.errors[0].mesage)
                }
                else if (res.data.data.deleteOvk.deleted === true) {
                    errMessage('success', `${t('pages.master.ovk.resDelSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.master.ovk.resDelFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.master.ovk.delConfirm')} ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(detailOvk(idData))
                    .then(res => {
                        setDetail(res.data.data.ovk)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if (value === idData) setVisible(true)
        else setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = async (values, year) => {
        await API.post(udpateOvk(idData, values, year))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.ovk.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.updateOvk._id) {
                    errMessage('success', `${t('pages.master.ovk.resEditSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                console.log(error)
                errMessage('error', `${t('pages.master.ovk.resEditFailed')}`, error.mesage)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values, values['date_picker'].format('MM/DD/YYYY'))
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const columns = [
        {
            title: `${t('pages.master.ovk.name')}`,
            dataIndex: 'nama',
            key: 'nama',
            width: 200,
            ...getColumnSearchProps('nama'),
        },
        {
            title: `${t('pages.master.ovk.type')}`,
            dataIndex: 'golongan',
            key: 'golongan',
            width: 100,
        },
        {
            title: `${t('pages.master.ovk.procuUnit')}`,
            dataIndex: 'satuan',
            key: 'satuan',
            width: 110,
        },
        {
            title: `${t('pages.master.ovk.capacity')}`,
            dataIndex: 'kapasitas',
            key: 'kapasitas',
            width: 100,
        },
        {
            title: `${t('pages.master.ovk.shape')}`,
            dataIndex: 'bentuk',
            key: 'bentuk',
            width: 100,
        },
        {
            title: `${t('pages.master.ovk.dose')}`,
            dataIndex: 'dosis',
            key: 'dosis',
            width: 150,
        },
        {
            title: `${t('pages.master.ovk.usage')}`,
            dataIndex: 'penggunaan',
            key: 'penggunaan',
            width: 120,
        },
        {
            title: `${t('pages.master.ovk.ingredient')}`,
            dataIndex: 'kandungan',
            key: 'kandungan',
            width: 240,
        },
        {
            title: `${t('pages.master.ovk.supplier')}`,
            dataIndex: 'distributor',
            key: 'distributor',
            width: 130,
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            fixed: !isMobileOnly && 'right',
            width: 130,
            render: (value, row, index) => (
                row.variety === 'secondary' || role === 'superadmin' ?
                    <Space size="middle">
                        <Tooltip placement="top" title={t('general.edit')}>
                            <Button
                                hidden={!profil.permission.writeMaster}
                                style={{
                                    color: 'white',
                                    backgroundColor: '#FFA903',
                                    borderRadius: '5px'
                                }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('general.del')}>
                            <Button
                                hidden={!profil.permission.writeMaster}
                                style={{
                                    color: 'white',
                                    backgroundColor: '#FF0303',
                                    borderRadius: '5px'
                                }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </Tooltip>
                    </Space> : <></>
            )
        }
    ]

    const data = object.map(({ _id, name, producer, dosis, expired, ingredient,
        ovkMedia, ovkShape, ovkUnit, recommendedUse, qtyUnit, ovkUnitRecording, variety }, index) => ({
            key: _id,
            nama: name,
            distributor: producer,
            dosis: dosis,
            variety: variety,
            tanggalKedaluarsa: moment(new Date(parseInt(expired))).format("DD MMMM YYYY"),
            kandungan: ingredient,
            penggunaan: recommendedUse,
            golongan: ovkMedia?.join(', '),
            bentuk: ovkShape,
            satuan: ovkUnit?.name,
            kapasitas: <NumberFormat value={qtyUnit} displayType={'text'} thousandSeparator={true}
                suffix={!ovkUnitRecording && qtyUnit === 1 ? ' ' + ovkUnit?.name : ' ' + ovkUnitRecording?.name} />
        }))

    return (
        <OvkComponent
            navigate={props.navigate}
            columns={columns}
            idData={idData}
            data={data}
            loading={loading}
            visible={visible}
            detail={detail}
            fetchData={fetchData}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            setLoading={setLoading}
            t={t} profil={profil}
        />
    )
}

const mapStateToProps = state => ({
    role: state.profil.dataProfil.type,
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListOvkUnit,
    getListOvkUnitRecording,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(OvkPage)
export default page