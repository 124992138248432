export const sidebar = {
    dashboard: "Dashboard",
    rearing: "Periode Budidaya",
    recording: "Recording",
    stock: "Stok",
    subMenustock: {
        feed: "Pakan",
        ovk: "OVK",
        material: "Material"
    },
    monitor: "Monitor",
    subMenuMonitor: {
        device: 'Alat',
        ews: 'EWS'
    },
    generalUse: "Pemakaian Umum",
    stockflow: "Stock Flow",
    subMenuStockflow: {
        period: "Periode",
        livebird: "Live Bird",
        feed: "Pakan",
        ovk: "OVK",
        material: "Material",
        resume: "Rekapitulasi"
    },
    master: "Master",
    subMenuMaster: {
        house: "Kandang",
        strain: "Strain",
        doc: "DOC",
        environment: "Lingkungan",
        feedHouse: "Gudang",
        feed: "Pakan",
        ovkHouse: "Gudang OVK",
        ovk: "OVK",
        materialHouse: "Gudang Material",
        material: "Material",
        overhead: "Overhead",
        disease: "Penyakit",
        customer: "Pelanggan",
        supplier: "Kontak Supplier",
        user: "Pengguna",
    },
    adPremium: 'Tingkatkan Premium',
    adIot: 'Dapatkan BroilerX IoT',
    profile: 'Profil',
    subscription: 'Langganan',
    logout: 'Keluar',
}