import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Form, Row, Col, Select, InputNumber, Card, DatePicker, Divider, Button, Space, Skeleton } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useTranslation } from "react-i18next"
import { faArrowLeft, faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { createRearingRecordBatch } from './rearing-record-aksi-page/query-rearing-record'

import { errMessage } from '../../../common/component/notification/notification'
import Loading from '../../../common/component/loading/loading-container'
import { API } from '../../../common/api'
import { navigate } from '../../../common/store/action'
import CONSTANTS from '../../../common/utils/Constants'
import { getIdKandang, getKandang, getListPeriodeKandang } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'
import { getPeriodeKandang } from '../rearing-page/rearing-aksi-page/query-rearing'
import { getListPakan } from '../../../modules/dashboard-peternak/stok-component/stok-pakan-component/store/stok-pakan-action'
import { getStokGudangPakan } from '../stok-page/stok-pakan-page/stok-pakan-aksi-page/query-stok-pakan'
import { getListOvk } from '../../../modules/dashboard-peternak/stok-component/stok-ovk-component/store/stok-ovk-action'
import { getStokGudangOvk } from '../stok-page/stok-ovk-page/stok-ovk-aksi-page/query-stok-ovk'
import { getPriceFeeds, getPriceOvks } from './rearing-record-aksi-page/query-rearing-record'

function CreateRecordingPpl(props) {
    const { kandang, idKandang, stokPakan, idGudangPakan,
        // namaGudangPakan,
        stokOvk, idGudangOvk,
        getIdKandang, getKandang, getListPeriodeKandang,
        getListPakan, getListOvk } = props
    const { Option } = Select
    const [form] = Form.useForm()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const [month, setMonth] = useState(moment().format('MMMM'))
    const [year, setYear] = useState(moment().format('YYYY'))
    const [monthStockFeed, setStockFeed] = useState([])
    const [monthStockOvk, setStockOvk] = useState([])
    const [loadingPriceFeed, setLoadingPriceFeed] = useState(false)
    const [loadingPriceOvk, setLoadingPriceOvk] = useState(false)

    useEffect(() => {
        if (idGudangPakan) {
            getListPakan(getStokGudangPakan(idGudangPakan))
        }
    }, [getListPakan, idGudangPakan])

    useEffect(() => {
        if (idGudangOvk) {
            getListOvk(getStokGudangOvk(idGudangOvk))
        }
    }, [getListOvk, idGudangOvk])

    const handleChangeKandang = useCallback((value) => {
        getIdKandang(value.value)
        getKandang(value.label)
        getListPeriodeKandang(getPeriodeKandang(value.value))
        form.resetFields(['feeds', 'ovks'])
    }, [getListPeriodeKandang, getIdKandang, getKandang, form])

    const setDate = e => {
        setMonth(moment(e).locale('id').format('MMMM'))
        setYear(moment(e).locale('id').format('YYYY'))
    }

    const getPriceFeed = async (month, year, idGudangPakan) => {
        setLoadingPriceFeed(true)
        await API.get(getPriceFeeds('', 0, 0, month, year, idGudangPakan))
            .then(res => {
                setStockFeed(res.data.data.monthlyStockFeeds.calculateMonthlyStocks)
                setLoadingPriceFeed(false)
            }).catch(error => {
                console.log(error.message)
                setStockFeed([])
                setLoadingPriceFeed(false)
            })
    }

    const getPriceOvk = async (month, year, idGudangOvk) => {
        setLoadingPriceOvk(true)
        await API.get(getPriceOvks('', 0, 0, month, year, idGudangOvk))
            .then(res => {
                setStockOvk(res.data.data.monthlyStockOvks.calculateMonthlyStocks)
                setLoadingPriceOvk(false)
            }).catch(error => {
                console.log(error.message)
                setStockOvk([])
                setLoadingPriceOvk(false)
            })
    }

    useEffect(() => {
        if (idGudangPakan) {
            getPriceFeed(month, year, idGudangPakan)
        }
    }, [month, year, idGudangPakan])

    useEffect(() => {
        if (idGudangOvk) {
            getPriceOvk(month, year, idGudangOvk)
        }
    }, [month, year, idGudangOvk])

    const postData = async (value, growings, feeds, ovks) => {
        setLoading(true)
        await API.post(createRearingRecordBatch(value, growings, feeds, ovks))
            .then(res => {
                try {
                    if (res.data.errors) {
                        errMessage('error', `${t('pages.recording.resAddFailed')}`, res.data.errors[0].message)
                        setLoading(false)
                    }
                    else if (res.data.data.createRearingRecordBatch._id) {
                        errMessage('success', `${t('pages.recording.resAddSuccess')}`)
                        form.resetFields()
                        setLoading(false)
                        props.navigate(CONSTANTS.DASHBOARD_PETERNAK_MENU_KEY)
                    }
                } catch (error) {
                    errMessage('error', `${t('pages.recording.resAddFailed')}`, error.message)
                    setLoading(false)
                }
            })
    }

    const onChangeOvk = async (value, key) => {
        const priceOvk = monthStockOvk.find(d => d?.idOvk === value.label[0].props.children)
        const fields = form.getFieldsValue()
        const { ovks } = fields
        Object.assign(ovks[key], { price: priceOvk?.hargaPengeluaran || 0 })
        form.setFieldsValue({ ovks })
    }

    const onChangeFeed = (value, key) => {
        const priceFeed = monthStockFeed.find(d => d?.idFeed === value.label[0].props.children)
        const fields = form.getFieldsValue()
        const { feeds } = fields
        Object.assign(feeds[key], { price: priceFeed?.hargaPerKarung || 0 })
        form.setFieldsValue({ feeds })
    }

    return (
        <Layout>
            <Layout.Content className="dashboard-container">
                <Space align='start'>
                    <Link to="/peternak/dashboard">
                        <Button shape="circle" style={{ border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 5%)' }}
                            icon={<FontAwesomeIcon icon={faArrowLeft} />} size='default' className='mr-10'
                        />
                    </Link>
                    <div className='font-title-dashboard font-semi-bold mb-10'>{t('pages.recording.title')}</div>
                </Space>
                <Card>
                    <Loading loading={loading}>
                        <Form
                            form={form}
                            layout="vertical"
                            requiredMark={false}
                            autoComplete="off"
                            initialValues={{
                                mati: 0, culling: 0
                            }}
                        >
                            <Row gutter={[16, 8]}>
                                <Col lg={12} xs={24} sm={24}>
                                    <span className="font-semi-bold font-sub">Kandang</span>
                                    <Form.Item
                                        name="house" rules={[{ required: true, message: `${t('pages.rearing.msgHouse')}` }]}
                                    >
                                        <Select
                                            showSearch
                                            labelInValue
                                            placeholder='Pilih kandang'
                                            optionFilterProp="children"
                                            style={{ width: '100%' }}
                                            className="select-kategori"
                                            onChange={e => handleChangeKandang(e)}
                                            value={{ key: idKandang }}
                                        >
                                            {kandang && kandang.map(data =>
                                                <Option
                                                    key={data.name}
                                                    value={data._id}
                                                >{data.name}</Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={12} xs={24} sm={24}>
                                    <span className="font-semi-bold font-sub">Tanggal</span>
                                    <Form.Item
                                        name="date" rules={[{ required: true, message: `${t('pages.recording.msgDate')}` }]}
                                    >
                                        <DatePicker
                                            placeholder={t('pages.recording.phDate')}
                                            className="select-kategori"
                                            onChange={e => setDate(e)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={12} xs={24}>
                                    <span className="font-semi-bold font-sub">Culling</span>
                                    <Form.Item name='culling' rules={[{ required: true, message: `${t('pages.recording.depletion.msgQty')} culling` }]}>
                                        <InputNumber
                                            placeholder="1000..."
                                            className="input-number"
                                            addonAfter='ekor' min={0}
                                            type='number'
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={12} xs={24}>
                                    <span className="font-semi-bold font-sub">Mati</span>
                                    <Form.Item name='mati' rules={[{ required: true, message: `${t('pages.recording.depletion.msgQty')} mati` }]}>
                                        <InputNumber
                                            placeholder="1000..."
                                            className="input-number"
                                            addonAfter='ekor' min={0}
                                            type='number'
                                        />
                                    </Form.Item>
                                </Col>

                                <Divider className='m-0' orientation="left" plain orientationMargin='0'>Sampling</Divider>
                                <Form.List name="growings"
                                    rules={[
                                        {
                                            validator: async (_, names) => {
                                                let sum = 0
                                                names && names.forEach(e => {
                                                    if (e) sum += e.procentage
                                                })
                                                if (sum > 100) {
                                                    return Promise.reject(new Error('Jumlah prosentase tidak bisa lebih dari 100% !'))
                                                }
                                            },
                                        },
                                    ]}>
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <>
                                                    <Col lg={8} xs={24}>
                                                        <span className="font-semi-bold font-sub">Prosentase ayam</span><br />
                                                        <Form.Item
                                                            className='mb-0'
                                                            {...restField}
                                                            name={[name, 'procentage']}
                                                            rules={[
                                                                { required: fields && fields.length > 0 ? true : false, message: `Masukkan nilai prosentase` },
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                placeholder="100..."
                                                                className="input-number"
                                                                type='number'
                                                                addonAfter='%'
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={8} xs={24}>
                                                        <span className="font-semi-bold font-sub">Jenis ayam</span>
                                                        <Form.Item className='mb-0'
                                                            {...restField}
                                                            name={[name, 'type']}
                                                            rules={[{ required: fields && fields.length > 0 ? true : false, message: `Masukkan jenis ayam` }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                placeholder='Jenis ayam'
                                                                optionFilterProp="children"
                                                                style={{ width: '100%' }}
                                                                className="select-kategori black font-regular"
                                                            >
                                                                <Option value='rooster'>Jantan</Option>
                                                                <Option value='hen'>Betina</Option>
                                                                <Option value='big'>Besar</Option>
                                                                <Option value='medium'>Sedang</Option>
                                                                <Option value='small'>Kecil</Option>
                                                                <Option value='tiny'>Sangat Kecil</Option>
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={7} xs={22}>
                                                        <span className="font-semi-bold font-sub">Berat ayam</span><br />
                                                        <Form.Item
                                                            className='mb-10'
                                                            {...restField}
                                                            name={[name, 'bw']}
                                                            rules={[
                                                                { required: fields && fields.length > 0 ? true : false, message: `${t('pages.recording.bw.msgWeight')}` },
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                placeholder="100..."
                                                                className="input-number mr-10"
                                                                type='number'
                                                                addonAfter='gram'
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={1} xs={2} style={{ alignSelf: 'center' }}>
                                                        <MinusCircleOutlined onClick={() => remove(name)} style={{ color: 'red' }} />
                                                    </Col>
                                                </>
                                            ))}
                                            <Form.Item>
                                                <Form.ErrorList errors={errors} />
                                                <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                    Tambah Sampling Ayam
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                                <Divider className='m-0' orientation="left" plain orientationMargin='0'>Pakan</Divider>
                                <Form.List name="feeds">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <>
                                                    <Col lg={12} xs={24}>
                                                        <span className="font-semi-bold font-sub">{t('pages.recording.feed.title')}</span>
                                                        <Form.Item className='mb-0'
                                                            {...restField}
                                                            name={[name, 'pakan']}
                                                            rules={[{ required: fields && fields.length > 0 ? true : false, message: `${t('pages.recording.feed.msgFeed')}` }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                labelInValue
                                                                placeholder={t('pages.recording.feed.phFeed')}
                                                                optionFilterProp="children"
                                                                onChange={value => { onChangeFeed(value, name) }}
                                                                style={{ width: '100%' }}
                                                                className="select-kategori black font-regular"
                                                            >
                                                                {stokPakan && stokPakan.map(data =>
                                                                    <Option
                                                                        value={data._id}
                                                                    ><span hidden>{data.feed._id}</span> {data.feed.name} - {data.nameStock}</Option>
                                                                )
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={11} xs={22}>
                                                        <span className="font-semi-bold font-sub">Jumlah</span><br />
                                                        <Form.Item
                                                            className='mb-10'
                                                            {...restField}
                                                            name={[name, 'qty']}
                                                            rules={[
                                                                { required: fields && fields.length > 0 ? true : false, message: `${t('pages.recording.feed.msgQty')}` },
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                placeholder="1000..."
                                                                className="input-number mr-10"
                                                                type='number'
                                                                addonAfter='karung'
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={1} xs={2} style={{ alignSelf: 'center' }}>
                                                        <MinusCircleOutlined onClick={() => remove(name)} style={{ color: 'red' }} />
                                                    </Col>


                                                    <Col hidden>
                                                        <Form.Item shouldUpdate name={[name, 'price']}  {...restField}
                                                        >
                                                            <InputNumber
                                                                placeholder="2500..."
                                                                className="input-number"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            ))}
                                            <Form.Item>
                                                <Skeleton paragraph={{ rows: 0, width: 10 }} loading={loadingPriceFeed} active size={'lg'} >
                                                    <Button hidden={loadingPriceFeed} type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                        Tambah Recording Pakan
                                                    </Button>
                                                </Skeleton>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>

                                <Divider className='m-0' orientation="left" plain orientationMargin='0'>OVK</Divider>
                                <Form.List name="ovks">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <>
                                                    <Col lg={12} xs={24}>
                                                        <span className="font-semi-bold font-sub">{t('pages.recording.ovk.title')}</span>
                                                        <Form.Item className='mb-0'
                                                            {...restField}
                                                            name={[name, 'ovk']}
                                                            rules={[{ required: fields && fields.length > 0 ? true : false, message: `${t('pages.recording.ovk.msgOvk')}` }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                labelInValue
                                                                placeholder={t('pages.recording.ovk.phOvk')}
                                                                optionFilterProp="children"
                                                                style={{ width: '100%' }}
                                                                className="select-kategori black font-regular"
                                                                onChange={(e) => onChangeOvk(e, name)}
                                                            >
                                                                {
                                                                    stokOvk && stokOvk.map(data =>
                                                                        <Option
                                                                            value={data._id}
                                                                        ><span hidden>{data.ovk._id}</span>{data.ovk.name} ({data.ovk.ovkUnit.name})</Option>
                                                                    )
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={11} xs={22}>
                                                        <span className="font-semi-bold font-sub">{t('pages.recording.ovk.qty')}</span><br />
                                                        <Form.Item
                                                            className='mb-10'
                                                            {...restField}
                                                            name={[name, 'qty']}
                                                            rules={[
                                                                { required: fields && fields.length > 0 ? true : false, message: `${t('pages.recording.ovk.msgQty')}` },
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                placeholder="1000..."
                                                                className="input-number mr-10"
                                                                type='number'
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={1} xs={2} style={{ alignSelf: 'center' }}>
                                                        <MinusCircleOutlined onClick={() => remove(name)} style={{ color: 'red' }} />
                                                    </Col>

                                                    <Col hidden>
                                                        <Form.Item shouldUpdate name={[name, 'price']}  {...restField}>
                                                            <InputNumber
                                                                placeholder="2500..."
                                                                className="input-number"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </>
                                            ))}
                                            <Form.Item>
                                                <Skeleton paragraph={{ rows: 0, width: 10 }} loading={loadingPriceOvk} active size={'lg'} >
                                                    <Button hidden={loadingPriceOvk} type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                        Tambah Recording OVK
                                                    </Button>
                                                </Skeleton>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Row>
                            <Button
                                onClick={() =>
                                    form
                                        .validateFields()
                                        .then((values) => {
                                            let stringGrowings = null
                                            let stringFeeds = null
                                            let stringOvks = null

                                            if (values.growings && values.growings.length > 0) {
                                                let growings = []
                                                for (let i = 0; i < values.growings.length; i++) {
                                                    growings.push({
                                                        procentage: values.growings[i].procentage,
                                                        type: values.growings[i].type,
                                                        bw: values.growings[i].bw,
                                                    })
                                                }
                                                values.growings = growings
                                                stringGrowings = JSON.stringify(values.growings)
                                                stringGrowings = stringGrowings.replace(/"([^"]+)":/g, '$1:')
                                            }

                                            if (values.feeds && values.feeds.length > 0) {
                                                let feeds = []
                                                for (let i = 0; i < values.feeds.length; i++) {
                                                    feeds.push({
                                                        feed: values.feeds[i].pakan.value,
                                                        qty: values.feeds[i].qty,
                                                        price: values.feeds[i].price
                                                    })
                                                }
                                                values.feeds = feeds
                                                stringFeeds = JSON.stringify(values.feeds)
                                                stringFeeds = stringFeeds.replace(/"([^"]+)":/g, '$1:')
                                            }

                                            if (values.ovks && values.ovks.length > 0) {
                                                let ovks = []
                                                for (let i = 0; i < values.ovks.length; i++) {
                                                    ovks.push({
                                                        ovk: values.ovks[i].ovk.value,
                                                        qty: values.ovks[i].qty,
                                                        price: values.ovks[i].price
                                                    })
                                                }
                                                values.ovks = ovks
                                                stringOvks = JSON.stringify(values.ovks)
                                                stringOvks = stringOvks.replace(/"([^"]+)":/g, '$1:')
                                            }

                                            postData(values, stringGrowings, stringFeeds, stringOvks)
                                        })
                                        .catch((info) => {
                                            console.log('Validate Failed:', info)
                                        })}
                                block type='primary' size='large'
                                htmlType="submit"
                            ><FontAwesomeIcon icon={faPlusSquare} className='mr-10' />
                                {t('pages.recording.add')}
                            </Button>
                        </Form>
                    </Loading>
                </Card>
            </Layout.Content>
        </Layout >
    )
}

const mapStateToProps = state => ({
    profil: state.profil.dataProfil,
    kandang: state.layout.dataKandang,
    stokPakan: state.stokPakan.dataStokPakan,
    stokOvk: state.stokOvk.dataStokOvk,
    idKandang: state.layout.idKandang,
    idGudangPakan: state.gudangPakan.idGudangPakan,
    namaGudangPakan: state.gudangPakan.namaGudangPakan,
    idGudangOvk: state.gudangOvk.idGudangOvk,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdKandang, getKandang, getListPeriodeKandang,
    getListPakan, getListOvk
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateRecordingPpl)
export default page