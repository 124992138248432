import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import EditPakanComponent from '../../../../modules/dashboard-peternak/rearing-record-component/edit-detail-rearing-component/edit-pakan-component'

function EditPakanPage(props) {
    const { visible, handleCancel, detail, handleSubmit, profil, t } = props
    const [data, setData] = useState(detail)
    const [berat, setBerat] = useState('')

    useEffect(() => {
        function setDetailData() {
            if (detail === "") {
                setData("")
            }
            else {
                setData(detail)
                setBerat(detail.feedStock.weight)
            }
        }
        setDetailData()
    }, [detail])

    return (
        <EditPakanComponent
            navigate={props.navigate}
            visible={visible}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            detail={detail}
            data={data}
            stokPakan={props.stokPakan}
            berat={berat}
            t={t} profil={profil}
        />
    )
}

const mapStateToProps = state => ({
    stokPakan: state.stokPakan.dataStokPakan,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPakanPage)
export default page