import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Input, Select, InputNumber } from 'antd'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import { navigate } from '../../../../../common/store/action'
import CONSTANTS from '../../../../../common/utils/Constants'
import { createStokPakan } from './query-stok-pakan'
import { getIdStokPakan, getBeratPakan } from '../../../../../modules/dashboard-peternak/stok-component/stok-pakan-component/store/stok-pakan-action'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'

function CreateStokPakanPage(props) {
    const { fetchData, setLoading, idGudangPakan, getIdStokPakan, getBeratPakan, pakan, t } = props
    const [visible, setVisible] = useState(false)
    const { Option } = Select

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = async (values) => {
        await API.post(createStokPakan(idGudangPakan, values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.feedStock.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.createFeedStock._id) {
                    validationMessage('success', `${t('pages.feedStock.resAddSuccess')}`)
                    fetchData(idGudangPakan)
                    getIdStokPakan(res.data.data.createFeedStock._id)
                    getBeratPakan(res.data.data.createFeedStock.weight)
                    props.navigate(CONSTANTS.TRANSAKSI_STOK_PAKAN_MENU_KEY)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.feedStock.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.feedStock.add')}
                okText={t('general.add')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        berat: 50,
                        namaPengadaan: ''
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content">{t('pages.feedStock.feed')}</span>
                            <Form.Item
                                name="pakan" rules={[{ required: true, message: `${t('pages.feedStock.msgFeed')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.feedStock.phFeed')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        pakan.map(data =>
                                            <Option
                                                value={data._id}
                                            >{data.name} - {data.producer}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.feedStock.weight')}</span>
                            <Form.Item
                                name="berat" rules={[{ required: true, message: `${t('pages.feedStock.msgWeight')}` }]}
                            >
                                <InputNumber
                                    placeholder="1000..."
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    className="input-number"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.feedStock.name')}</span>
                            <Form.Item
                                name="namaPengadaan" rules={[{ required: false, message: `${t('pages.feedStock.name')}` }]}
                            >
                                <Input
                                    placeholder={t('pages.feedStock.phName')}
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <ButtonDashboard
                text={t('pages.feedStock.add')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    pakan: state.pakan.dataPakan,
    gudangPakan: state.gudangPakan.dataGudangPakan
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getIdStokPakan,
    getBeratPakan
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateStokPakanPage)
export default page