import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Card, Col, Layout, Row, Skeleton, Divider, Image, Tag, Modal, Result } from 'antd'
import { useTranslation } from "react-i18next"
import { detailInvoice, checkSubs } from './query-subscription'
import { API } from '../../../common/api'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCreditCard, faPrint } from '@fortawesome/free-solid-svg-icons'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import { errMessage } from '../../../common/component/notification/notification'

function DetailInvoicePage(props) {
    const { t } = useTranslation()
    const { Content } = Layout
    const logo = require(`../../../assets/images/logo.png`)
    const { invoiceId } = props
    const [invoice, setInvoice] = useState({})
    const [loading, setLoading] = useState(false)
    const [isConfirm, setConfirm] = useState(false)
    const [visible, setVisible] = useState(false)

    const fetchData = (invoiceId) => {
        setLoading(true)
        API.get(detailInvoice(invoiceId))
            .then(res => {
                setInvoice(res.data.data.invoice)
                setLoading(false)
            }).catch((error) => {
                setInvoice({})
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData(invoiceId)
    }, [invoiceId])

    const onConfirm = () => {
        setConfirm(true)
        setLoading(true)
        API.get(checkSubs(invoiceId))
            .then(res => {
                if (res.data.errors) {
                    setInvoice(invoice)
                    errMessage('error', `Gagal mengonfirmasi pembayaran`, res.data.errors[0].message)
                    setConfirm(false)
                    setLoading(false)
                } else {
                    setInvoice(res.data.data.checkSubscription)
                    setLoading(false)
                    setConfirm(false)
                    setVisible(true)
                }
            }).catch((error) => {
                setInvoice(invoice)
                setLoading(false)
                setConfirm(false)
            })
    }

    return (
        <Layout>
            <Content className="dashboard-container" style={{ height: '100vh' }}>
                <div className='mb-20'>
                    <Link to="/peternak/subscription">
                        <Button shape="circle" style={{ border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 5%)' }}
                            icon={<FontAwesomeIcon icon={faArrowLeft} />} size='default' className='mr-10 back-action' />
                    </Link>
                    <Button style={{
                        color: 'white',
                        backgroundColor: '#008ad4',
                    }} className='float-right print-action'
                        onClick={() => window.print()}
                    ><FontAwesomeIcon icon={faPrint} className='mr-10' />{t('general.print')}</Button>
                    <Button hidden={invoice.status === 'unpaid' ? false : true} loading={isConfirm} style={{
                        color: 'white',
                        backgroundColor: 'rgb(255, 169, 3)',
                    }} className='float-right print-action mr-10'
                        onClick={() => onConfirm()}
                    >{t('pages.subscription.confirmPayment')}</Button>
                </div>
                <div className='card-print'
                    style={{ margin: 'auto', width: '800px' }}
                >
                    <Card bordered={false}>
                        <Image src={logo} preview={false} width={120} />
                        <div className='font-semi-bold float-right' style={{ fontSize: 23 }}>INVOICE</div>
                        <Divider />
                        <Row>
                            <Col span={12}>
                                <span className='font-light'>{t('pages.subscription.number')}</span><br />
                                {loading ? <Skeleton active={loading} paragraph={{ rows: 0 }} /> :
                                    <><span className='font-semi-bold font-title-medium'>{invoice.externalId}</span>
                                        <Tag className='ml-10' color={invoice.status === 'unpaid' || invoice.status === 'validating' ? 'gold' : invoice.status === 'expired' ? "red" : 'green'}>
                                            {invoice.status === 'unpaid' ? t('pages.subscription.unpaid') : invoice.status === 'validating' ?
                                                t('pages.subscription.validating') : invoice.status === 'expired' ? t('pages.subscription.expired') : t('pages.subscription.paid')}
                                        </Tag><br /><br />
                                    </>}
                                <span className='font-light'>{t('pages.subscription.date')}</span><br />
                                {loading ? <Skeleton active={loading} paragraph={{ rows: 0 }} /> :
                                    <span className='font-semi-bold font-title-medium'>{moment(invoice.createdAt).format('LLL')}</span>}
                            </Col>
                            <Col span={6} offset={6}>
                                <span className='font-light'>{t('pages.subscription.customer')}</span><br />
                                {loading ? <Skeleton active={loading} paragraph={{ rows: 1 }} /> :
                                    <span className='font-semi-bold font-title-medium'>{invoice.company && invoice.company.name}</span>
                                }
                            </Col>
                        </Row>
                        <div className='invoice-item font-semi-bold mt-30'>
                            <Row>
                                <Col span={14}>Item</Col>
                                <Col span={5}>{t('pages.subscription.qty')}</Col>
                                <Col span={5}>{t('pages.subscription.price')}</Col>
                            </Row>
                        </div>
                        {invoice.items && invoice.items.map(d => (
                            <div className='font-regular p-10'>
                                <Row>
                                    <Col span={14}>
                                        {loading ? <Skeleton active={loading} paragraph={{ rows: 0 }} /> :
                                            d.name}
                                    </Col>
                                    <Col span={5}>
                                        {loading ? <Skeleton active={loading} paragraph={{ rows: 0 }} /> :
                                            d.quantity + ` ${t('pages.subscription.house')}`}</Col>
                                    <Col span={5}>
                                        {loading ? <Skeleton active={loading} paragraph={{ rows: 0 }} /> :
                                            <NumberFormat value={d.price} displayType={'text'} thousandSeparator={true} prefix='Rp' />}
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Divider className='mt-60 mb-0 mr-0 ml-0' />
                        <div className='mb-60'>
                            <Row justify="space-around" align="middle">
                                <Col span={12}>
                                    {/* <p className='font-semi-bold'>{t('pages.subscription.desc')}:</p> */}
                                    {loading ? <Skeleton active={loading} paragraph={{ rows: 0 }} /> :
                                        <p className='font-regular'>{invoice.description}</p>}
                                </Col>
                                <Col span={5} offset={2} className='font-regular'>Total</Col>
                                <Col span={5} className='font-bold' style={{ fontSize: '22px' }}>
                                    {loading ? <Skeleton active={loading} paragraph={{ rows: 0 }} /> :
                                        <NumberFormat value={invoice.amount} displayType={'text'} thousandSeparator={true} prefix='Rp' />}
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col span={12}>
                                <p style={{ fontSize: 12 }}>{t('pages.subscription.valid')}</p>
                            </Col>
                            <Col span={12}>
                                <p style={{ fontSize: 12 }} className='float-right'><em>{t('pages.subscription.updated')}: {moment(invoice.updated).format('LLL')}</em></p>
                            </Col>
                        </Row>
                    </Card>
                    <Button block
                        hidden={invoice.status === 'unpaid' ? false : true}
                        style={{
                            color: 'white',
                            backgroundColor: '#FFA903',
                        }}
                        className='print-action mt-20'
                        onClick={() => window.open(invoice.link)}
                        size='large'
                    ><FontAwesomeIcon icon={faCreditCard} className='mr-10' />{t('pages.subscription.pay')}</Button>
                </div>
            </Content>
            <Modal
                visible={visible} centered
                okText='OK'
                width={400}
                footer={null}
            >
                <Result
                    status={invoice.status === 'unpaid' ? 'warning' : invoice.status === 'expired' ? 'error' : "success"} style={{ padding: 0 }}
                    title={invoice.status === 'unpaid' ? t('pages.subscription.waitingPayment') :
                        invoice.status === 'expired' ? t('pages.subscription.expiredPayment') : t('pages.subscription.successPayment')}
                    subTitle={
                        <>
                            {invoice.status === 'unpaid' ?
                                t('pages.subscription.finishPayment', { date: moment(invoice.expiry_date).format('LLL') }) :
                                invoice.status === 'expired' ? '' :
                                    <span>
                                        Invoice ID: {invoice.externalId} {t('pages.subscription.amount')} <NumberFormat value={invoice.amount} displayType={'text'} thousandSeparator={true} prefix='Rp' />
                                    </span>
                            }
                        </>
                    }
                    extra={[
                        <Button type='primary' block onClick={() => setVisible(false)}> OK </Button>
                    ]}
                />
            </Modal>
        </Layout>
    )
}

const mapStateToProps = state => ({
    invoiceId: state.layout.invoiceId,
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailInvoicePage)
export default page