import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Input, Select } from 'antd'

function EditStokPage(props) {
    const { Option } = Select
    const { visibleEdit, handleCancel, detail, handleSubmit, ovk, t } = props
    const [data, setData] = useState("")

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.ovkStock.edit')}
                okText={t('general.edit')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        ovk: data.ovk,
                        namaPengadaan: data.name,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24} hidden={data.countOvkTransaction > 0 ? true : false}>
                            <span className="black font-semi-bold font-title-content">{t('pages.ovkStock.ovk')}</span>
                            <Form.Item
                                name="ovk" rules={[{ required: true, message: `${t('pages.ovkStock.msgOvk')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder="Pilih Ovk"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        ovk.map(data =>
                                            <Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.ovkStock.name')}</span>
                            <Form.Item
                                name="namaPengadaan" rules={[{ required: false, message: `${t('pages.ovkStock.msgName')}` }]}
                            >
                                <Input
                                    placeholder={t('pages.ovkStock.phName')}
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    return (
        <div>
            <CollectionCreateForm
                visible={visibleEdit}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    ovk: state.ovk.dataOvk,
    gudang: state.gudangOvk.dataGudangOvk,
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditStokPage)
export default page