export const tour = {
    welcome: 'Welcome to BroilerX',
    step1: 'This is the first view of BroilerX app.',
    step2: 'At the top you can see the menu to select the house and period.',
    step3: 'On the left you can see some of the available menus in BroilerX.',
    step4: 'To start using, go to the Master menu. The master menu contains all basic data such as warehouse, house, feed, medicine, and others.',
    step5: 'Next, there is the Stock menu. This menu contains feed stock management, medicine, material and procurement activities.',
    step6: 'Next there is the Monitor menu. Here the house monitor tool can monitor the current state of the house.',
    step7: 'Next there is the Rearing Period menu. Here, the farmer can make a maintenance/cultivation period.',
    step8: 'Then there is the Recording menu. Here farmers can make maintenance records per period every day.',
    step9: 'Last, there is the Dashboard menu. Here, farmers can see a summary of the existing data, starting from the total live chickens, dead chickens, the performance of each house, and so on.',
    step10: 'Are you ready to use BroilerX? Start cultivating!',
    cta: 'Create Rearing',
}