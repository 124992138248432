/**
 * @author spindyzel
 * @since 28 Desember 2019
*/

/**
 * @modified vickyhermawan
 * @since 25 Juli 2020
*/

import React, { Component } from 'react'
import { Router, Switch, Route,Redirect } from "react-router-dom"
import routeSources from './router-config'
import Cookies from 'js-cookie'

class Routers extends Component {
    render() {
        return (
            <Router history={this.props.history}>
                <Switch>
                    {routeSources.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                    ))}
                </Switch>
            </Router>
        )
    }
}

function RouteWithSubRoutes(route) {
    if(route.path === '/' && Cookies.get('token')){
        return (
        <Redirect
            to={{
                pathname: '/peternak/dashboard',
            }}
        />
        )
    }else{
        return route.private ? (
            <PrivateRoute
               path={route.path}
           >
               <route.component routes={route.routes} />
           </PrivateRoute>
       ) : (
            <Route
                path={route.path}
                render={props => (
                    <route.component {...props} routes={route.routes} />
                )}  
            />
        )
    }
}

function PrivateRoute({ children, ...rest}) {
    let isAuthenticated = false
    if(Cookies.get('token')){
        isAuthenticated = true
    }else{
        isAuthenticated = false
    }
    return (
      <Route
        render={({ location }) =>{
            if(isAuthenticated)
                return children
            else {
                return (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: location }
                        }}
                    />
                    )
                }
            }
        }
      />
    )
}


export default Routers