import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input, Layout, Breadcrumb, Row, Col, Tabs, Menu, Dropdown, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import Loading from '../../../../common/component/loading/loading-container'

import CreateFeed from './gudang-pakan-aksi-page/create-gudang-pakan-page'
import CreateOvk from '../gudang-ovk-page/gudang-ovk-aksi-page/create-gudang-ovk-page'

import EditFeed from './gudang-pakan-aksi-page/edit-gudang-pakan-page'
import OvkWarehouses from '../gudang-ovk-page/gudang-ovk-page'

import { queryGudangPakan, deleteGudangPakan, detailGudangPakan, updateGudangPakan } from './gudang-pakan-aksi-page/query-gudang-pakan'
import { getGudangOvk } from '../gudang-ovk-page/gudang-ovk-aksi-page/query-gudang-ovk'
import { API } from '../../../../common/api'
import { useTranslation } from "react-i18next"
import { isMobileOnly } from 'react-device-detect'

function GudangPakanPage(props) {
    const { profil } = props
    const { confirm } = Modal
    const { Content } = Layout
    const { TabPane } = Tabs
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [activeKey, setActiveKey] = useState("1")
    const [loading, setLoading] = useState(false)
    const [detail, setDetail] = useState('')
    const [idData, setIdData] = useState(null)
    const [feedWh, setFeed] = useState([])
    const [ovkWh, setOvk] = useState([])
    const [feedTotal, setFeedTotal] = useState(null)
    const [ovkTotal, setOvkTotal] = useState(null)
    const { t } = useTranslation()

    const handleMenuClick = useCallback((value) => { }, [])
    const handleButtonClick = useCallback((value) => { }, [])
    const changeKey = useCallback((value) => {
        setActiveKey(value)
    }, [])

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchDataFeed = async () => {
        setLoading(true)
        await API.get(queryGudangPakan('', 0, 0))
            .then(res => {
                setFeedTotal(res.data.data.feedWarehouses.totalCount)
                setFeed(res.data.data.feedWarehouses.feedWarehouses)
                setLoading(false)
            }).catch((error) => {
                setFeed([])
                setFeedTotal(null)
                setLoading(false)
            })
    }
    const fetchDataOvk = async () => {
        setLoading(true);
        await API.get(getGudangOvk('', 0, 0))
            .then(res => {
                setOvkTotal(res.data.data.ovkWarehouses.totalCount)
                setOvk(res.data.data.ovkWarehouses.ovkWarehouses)
                setLoading(false)
            }).catch((error) => {
                setOvk([])
                setLoading(false)
            })
    }

    useEffect(() => {
        if (activeKey === "1") {
            fetchDataFeed()
        }
        else if (activeKey === "2") {
            fetchDataOvk()
        }
    }, [activeKey])

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1">
                <CreateFeed loading={loading} setLoading={setLoading}
                    fetchData={fetchDataFeed} total={feedTotal} t={t} />
            </Menu.Item>
            <Menu.Item key="2">
                <CreateOvk loading={loading} setLoading={setLoading}
                    fetchData={fetchDataOvk} total={ovkTotal} t={t} />
            </Menu.Item>
        </Menu>
    )

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deleteGudangPakan(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.warehouse.resDelFeedFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteFeedWarehouse.deleted === true) {
                    validationMessage('success', `${t('pages.master.warehouse.resDelFeedSuccess')}`)
                    fetchDataFeed()
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.master.warehouse.resDelFeedFailed')}`, error.message)
                setLoading(false)
            })
    }

    const deleteConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.master.warehouse.delConfirm')} ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(detailGudangPakan(idData))
                    .then(res => {
                        setDetail(res.data.data.feedWarehouse)
                        setVisible(true)
                    }).catch((error) => {
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = async (values) => {
        await API.post(updateGudangPakan(idData, values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.warehouse.resEditFeedFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.updateFeedWarehouse._id) {
                    validationMessage('success', `${t('pages.master.warehouse.resEditFeedSuccess')}`)
                    fetchDataFeed()
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.master.warehouse.resEditFeedFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values.gudangPakan)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const data = feedWh.map(({ _id, name }, index) => ({
        key: _id,
        id: _id,
        gudang: name,
    }))

    const columns = [
        {
            title: `${t('pages.master.warehouse.name')}`,
            dataIndex: 'gudang',
            key: 'gudang',
            sorter: (a, b) => a.gudang.localeCompare(b.gudang, 'en', { numeric: true }),
            ...getColumnSearchProps('gudang'),
        },
        !profil.permission.writeMaster ? { width: 1 } :
            {
                title: `${t('general.action')}`,
                dataIndex: 'pilihan',
                key: 'pilihan',
                align: 'center',
                width: 130,
                render: (value, row, index) => (
                    <Space size="middle">
                        <Tooltip placement="top" title={t('general.edit')}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FFA903',
                                borderRadius: '5px'
                            }} onClick={() => showModal(row.id)}><FontAwesomeIcon icon={faEdit} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('general.del')}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FF0303',
                                borderRadius: '5px'
                            }} onClick={() => deleteConfirm(row.id, row.gudang)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </Tooltip>
                    </Space>
                ),
            },
    ]

    return (
        <>
            <Layout>
                <Content className="dashboard-container">
                    <Row className="dashboard-section">
                        <Col lg={12} xs={24}>
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item><span>{t('pages.master.warehouse.title')}</span></Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col lg={12} xs={24}
                            hidden={!profil.permission.writeMaster}
                        >
                            <Dropdown
                                overlay={menu} placement="bottomLeft" trigger={['click']}>
                                <Button style={{
                                    color: 'white',
                                    backgroundColor: '#008ad4',
                                    borderRadius: '5px',
                                    float: !isMobileOnly && "right",
                                }} onClick={() => handleButtonClick()}>
                                    <FontAwesomeIcon icon={faPlusSquare} style={{ marginRight: "10px" }} />
                                    {t('pages.master.warehouse.add')}
                                </Button>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24} md={24} sm={24}>
                            <div className="dashboard-section-tabs-recording">
                                <Tabs type="card" defaultActiveKey="1" onTabClick={(key) => changeKey(key)}>
                                    <TabPane tab={t('pages.master.warehouse.feed')} key="1">
                                        <Loading loading={loading}>
                                            <Table
                                                columns={columns}
                                                dataSource={data}
                                                bordered
                                                pagination={false}
                                                className="table-dashboard"
                                                scroll={{ y: '70vh' }}
                                            />
                                        </Loading>
                                        <EditFeed visible={visible} idData={idData} t={t} feedTotal={feedTotal}
                                            handleCancel={handleCancel} detail={detail} handleSubmit={handleSubmit} />
                                    </TabPane>
                                    <TabPane tab={t('pages.master.warehouse.ovk')} key="2">
                                        <OvkWarehouses activeKey={activeKey} loading={loading} setLoading={setLoading}
                                            ovkWh={ovkWh} ovkTotal={ovkTotal} fetchDataOvk={fetchDataOvk} t={t} role={profil.permission.writeMaster} />
                                    </TabPane>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Content>
            </Layout>

        </>
    )
}

const mapStateToProps = state => ({
    typeUser: state.profil.dataProfil.company.typeUser,
    role: state.profil.dataProfil.type,
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(GudangPakanPage)
export default page