import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Input, InputNumber } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { API } from '../../../../../common/api'
import { errMessage } from '../../../../../common/component/notification/notification'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import { createOverhead } from './query-overhead'

function CreateOverheadPage(props) {
    const { fetchData, setLoading, t } = props
    const [visible, setVisible] = useState(false)

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values) => {
        API.post(createOverhead(values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error',  `${t('pages.master.overhead.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createOverhead._id) {
                    errMessage('success', `${t('pages.master.overhead.resAddSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.master.overhead.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.master.overhead.add')}
                okText={t('general.add')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.overhead.name')}</span>
                            <Form.Item
                                name="name" rules={[{ required: true, message: `${t('pages.master.overhead.msgName')}` }]}
                            >
                                <Input
                                    placeholder="paper chick, BBM genset ..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.overhead.price')}</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `${t('pages.master.overhead.msgPrice')}` }]}
                            >
                                <InputNumber
                                    placeholder="5000..."
                                    className="input-number mt-5"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <ButtonDashboard
                text={t('pages.master.overhead.add')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateOverheadPage)
export default page