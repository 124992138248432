export const START_GET_DATA = '@rearing-record/start-get-data';
export const FINISH_GET_DATA = '@rearing-record/start-finish-data';
export const SET_ID_REARING_RECORD = '@rearing-record/set_id_rearing_record';
export const SET_DATE_REARING_RECORD = '@rearing-record/set_date_rearing_record';
export const SET_AGE_REARING_RECORD = '@rearing-record/set_age_rearing-record';
export const SET_GROWING_PARAMS = '@rearing-record/set_growing_params';
export const SET_ID_DISEASE = '@rearing-record/set_disease_id';
export const SET_DETAIL_DISEASE = '@rearing-record/set_detail_disease';
export const SET_MONTH_PERIODE= '@rearing-record/set_month_periode';
export const SET_YEAR_PERIODE= '@rearing-record/set_year_periode';
export const SET_START_PERIODE= '@rearing-record/set_start_periode';
export const SET_END_PERIODE= '@rearing-record/set_end_periode';
export const SET_LIVE_BIRD_PRICE = '@rearing-record/set_live_bird_price';