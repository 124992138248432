export const master = {
    house: {
        title: "House Master",
        name: "House Name",
        capacity: "Capacity (chickens)",
        address: "House Address",
        add: "Add House",
        edit: "Edit House",
        feedWh: "Feed Warehouse",
        ovkWh: "Medicine Warehouse",
        materialWh: "Material Warehouse",
        desc: "Description",
        phFeedWh: "Select feed warehouse",
        phOvkWh: "Select medicine warehouse",
        phMaterialWh: "Select material warehouse",
        phAddress: "Input house address",
        phDesc: "Input description..",
        msgName: "Please input house name!",
        msgCapacity: "Please input house capacity!",
        msgFeedWh: "Please select feed warehouse!",
        msgOvkWh: "Please select medicine warehouse!",
        msgMaterialWh: "Please select material warehouse!",
        msgAddress: "Please input house address!",
        delConfirm: "Are you sure want to delete house",
        delConfirmContent: "You will lose all data in rearing and recording inside this house.",
        resAddSuccess: "Successfully added house",
        resEditSuccess: "Successfully changed house",
        resActivateSuccess: "Successfully activated house",
        resDeactivateSuccess: "Successfully deactivated house",
        resDelSuccess: "Successfully deleted house",
        resAddFailed: "Failed to add house",
        resEditFailed: "Failed to change house",
        resActivateFailed: "Failed to activate house",
        resDeactivateFailed: "Failed to deactivate house",
        resDelFailed: "Failed to delete house",
    },
    strain: {
        title: "Master Strain",
        name: "Strain Name",
        std: "Strain Standard",
        day: "Day",
        depletion: "Depletion",
    },
    doc: {
        title: "Master DOC",
        name: "DOC Name",
        corporation: "DOC Company",
        type: "DOC type",
        strain: "Strain",
        add: "Add DOC Master",
        edit: "Edit DOC Master",
        phType: "Select DOC type",
        phStrain: "Select strain",
        msgName: "Please input DOC name!",
        msgCorporation: "Please input DOC company!",
        msgType: "Please select DOC type!",
        msgStrain: "Please select DOC strain!",
        resAddSuccess: "Successfully added DOC!",
        resAddFailed: "Failed to add DOC!",
        resEditSuccess: "Successfully changed DOC!",
        resEditFailed: "Failed to change DOC!",
        resDelSuccess: "Successfully deleted DOC!",
        resDelFailed: "Failed to delete DOC!",
        delConfirm: "Are you sure want to delete",
    },
    warehouse: {
        title: "Warehouse Master",
        name: "Warehouse Name",
        feed: "Feed",
        ovk: "Medicine",
        material: "Material",
        add: "Add Warehouse",
        delConfirm: "Are you sure want to delete warehouse",
        addFeed: "Add Feed Warehouse",
        addOvk: "Add Medicine Warehouse",
        addMaterial: "Add Material Warehouse",
        editFeed: "Edit Feed Warehouse",
        editOvk: "Edit medicine Warehouse",
        editMaterial: "Edit Material Warehouse",
        feedWh: "Feed Warehouse Name",
        phFeed: "Input your feed warehouse name",
        msgFeed: "Please input feed warehouse name!",
        resAddFeedSuccess: "Successfully added feed warehouse",
        resEditFeedSuccess: "Successfully changed feed warehouse",
        resDelFeedSuccess: "Successfully deleted feed warehouse",
        resAddFeedFailed: "Failed to add feed warehouse",
        resEditFeedFailed: "Failed to change feed warehouse",
        resDelFeedFailed: "Failed to delete feed warehouse",
        ovkWh: "Medicine Warehouse Name",
        phOvk: "Input your medicine warehouse name",
        msgOvk: "Please input medicine warehouse name!",
        resAddOvkSuccess: "Successfully added medicine warehouse",
        resEditOvkSuccess: "Successfully changed medicine warehouse",
        resDelOvkSuccess: "Successfully deleted medicine warehouse",
        resAddOvkFailed: "Failed to add medicine warehouse",
        resEditOvkFailed: "Failed to change medicine warehouse",
        resDelOvkFailed: "Failed to delete medicine warehouse",
        materialWh: "Material Warehouse Name",
        phMaterial: "Input your material warehouse name",
        msgMaterial: "Please input material warehouse name!",
        resAddMaterialSuccess: "Successfully added material warehouse",
        resEditMaterialSuccess: "Successfully changed material warehouse",
        resDelMaterialSuccess: "Successfully deleted material warehouse",
        resAddMaterialFailed: "Failed to add material warehouse",
        resEditMaterialFailed: "Failed to change material warehouse",
        resDelMaterialFailed: "Failed to delete material warehouse",
    },
    feed: {
        title: "Feed Master",
        name: "Feed Name",
        company: "Company",
        type: "Feed Type",
        add: "Add Feed Master",
        edit: "Edit Feed Master",
        desc: "Description",
        phType: "Select feed type",
        phDesc: "Input additional information",
        msgName: "Please input feed name!",
        msgType: "Please select feed type!",
        msgCompany: "Please input feed company!",
        delConfirm: "Are you sure you want to delete feed",
        resAddSuccess: "Successfully added feed",
        resEditSuccess: "Successfully changed feed",
        resDelSuccess: "Successfully deleted feed",
        resAddFailed: "Failed to add feed",
        resEditFailed: "Failed to change feed",
        resDelFailed: "Failed to delete feed"
    },
    ovk: {
        title: "Medicine Master",
        name: "Medicine Name",
        category: "Category",
        procuUnit: "Procurement Unit",
        shape: "Shape",
        capacity: "Capacity",
        dose: "Dosage",
        usage: "Recommended Usage",
        ingredient: "Ingredient",
        supplier: "Supplier",
        expired: "Expired Date",
        add: "Add Medicine Master",
        delConfirm: "Are you sure you want to delete medicine",
        edit: 'Edit Medicine Master',
        type: "Medicine Type",
        media: "Medicine Media",
        usageUnit: "Usage Unit",
        qty: "Quantity Unit",
        qtyUsage: "Quantity of Usage Unit",
        botol: 'Bottle',
        karung: 'Sack',
        vial: 'Vial',
        pil: 'Tabley',
        sachet: 'Sachet',
        jerigen: 'Jerry can',
        jar: 'Jar',
        spray: 'Spray',
        pakan: 'Feed',
        air: 'Drinking water',
        tebar: 'Spread',
        phShape: "Select shape",
        phType: "Select type",
        phMedia: "Select media",
        phIngredient: "Input content",
        phUsageUnit: "Select usage unit",
        phProcuUnit: "Select procurement unit",
        phExpired: "Select expired date",
        msgShape: "Please select medicine shape!",
        msgType: "Please select medicine type!",
        msgUsageUnit: "Please select medicine usage unit!",
        msgProcuUnit: "Please select medicine procurement unit!",
        msgMedia: "Please select medicine media!",
        msgName: "Please input medicine name!",
        msgDose: "Please input medicine dosage!",
        msgUsage: "Please input medicine usage!",
        msgIngredient: "Please input medicine content!",
        msgSupplier: "Please input medicine supplier!",
        msgQty: "Please input medicine quantity!",
        msgExpired: "Please select expired date!",
        resAddSuccess: "Successfully added medicine",
        resEditSuccess: "Successfully changed medicine",
        resDelSuccess: "Successfully deleted medicine",
        resAddFailed: "Failed to add medicine",
        resEditFailed: "Failed to change medicine",
        resDelFailed: "Failed to delete medicine",
    },
    material: {
        title: "Material Master",
        name: "Material name",
        company: "Material Company",
        add: "Add Material Master",
        delConfirm: "Are you sure you want to remove material ",
        edit: "Edit Material Master",
        phName: "Input material name",
        msgName: "Please input material name",
        msgCompany: "Please input production company",
        resAddSuccess: "Successfully added material",
        resEditSuccess: "Successfully changed material",
        resDelSuccess: "Successfully deleted material",
        resAddFailed: "Failed to add material",
        resEditFailed: "Failed to change material",
        resDelFailed: "Failed to delete material",
    },
    overhead: {
        title: "Overhead Master",
        name: "Overhead Name",
        price: "Price (Rp)",
        add: "Add Overhead Master",
        edit: "Edit Overhead Master",
        delConfirm: "Are you sure you want to remove overhead",
        msgName: "Please input overhead name!",
        msgPrice: "Please input overhead price!",
        resAddSuccess: "Successfully added overhead",
        resEditSuccess: "Successfully changed overhead",
        resDelSuccess: "Successfully deleted overhead",
        resAddFailed: "Failed to add overhead",
        resEditFailed: "Failed to change overhead",
        resDelFailed: "Failed to delete overhead"
    },
    disease: {
        title: "Disease Master",
        name: "Disease Name",
        symptom: "General Symptoms",
        add: "Add Disease Master",
        edit: "Edit Disease Master",
        delConfirm: "Are you sure you want to remove disease",
        phSymptom: "Input general symptom...",
        msgName: "Please input disease name!",
        resAddSuccess: "Successfully added disease",
        resEditSuccess: "Successfully changed disease",
        resDelSuccess: "Successfully deleted disease",
        resAddFailed: "Failed to add disease",
        resEditFailed: "Failed to change disease",
        resDelFailed: "Failed to delete disease"
    },
    customer: {
        title: "Customer Master",
        name: "Name",
        address: "Address",
        contact: "Contact",
        info: "Another Information",
        add: "Add Customer Master",
        edit: "Edit Customer Master",
        delConfirm: "Are you sure you want to delete the customer",
        phName: "Input customer name",
        phAddress: "Input customer address",
        phInfo: "Input another information",
        msgName: "Please input customer name!",
        msgContact: "Please input customer contact!",
        msgAddress: "Please input customer address!",
        msgInfo: "Please input another information!",
        resAddSuccess: "Successfully added customer",
        resEditSuccess: "Successfully changed customer",
        resDelSuccess: "Successfully deleted customer",
        resAddFailed: "Failed to add customer",
        resEditFailed: "Failed to change customer",
        resDelFailed: "Failed to delete customer",
    },
    supplier: {
        title: "Supplier Contact Master",
        code: "Supplier Code",
        name: "Name",
        address: "Address",
        contact: "Contact",
        type: "Supplier Type",
        info: "Another Information",
        add: "Add Master Supplier",
        edit: "Edit Master Supplier",
        delConfirm: "Are you sure you want to delete the supplier",
        phName: "Input supplier name",
        phAddress: "Input supplier address",
        phCode: "Input supplier code",
        phType: "Select supplier type",
        phInfo: "If there is no additional information, input -",
        msgName: "Please input supplier name!",
        msgAddress: "Please input supplier address!",
        msgType: "Please select supplier type!",
        msgCode: "Please input supplier code!",
        msgContact: "Please input supplier contact!",
        msgInfo: "Please input additional information!",
        resAddSuccess: "Successfully added supplier",
        resEditSuccess: "Successfully changed supplier",
        resDelSuccess: "Successfully deleted supplier",
        resAddFailed: "Failed to add supplier",
        resEditFailed: "Failed to change supplier",
        resDelFailed: "Failed to delete supplier",
    },
    staff: {
        title: "Staff Master",
        staff: "House Officer",
        management: "Management",
        add: "Add Staff",
        edit: "Edit Staff",
        name: "Name",
        type: 'Staff Type',
        house: "House",
        phone: "Phone Number",
        address: "Address",
        phName: "Input name..",
        phHouse: "Select house",
        phAddress: "Input address ...",
        phEmail: "Input email",
        phUsername: "Input username",
        phPassword: "Input password",
        phType: 'Select staff type',
        msgName: "Please input name!",
        msgAddress: "Please input address!",
        msgHouse: "Please select house!",
        msgPhone: "Please input phone number!",
        msgEmail: "Please input email!",
        msgUsername: "Please input username at least 4 character!",
        msgPassword: "Please input the password at least 6 characters!",
        msgType: "Please select type of staff!",
        delConfirm: "Are you sure you want to delete staff",
        resAddSuccess: "Successfully added staff",
        resEditSuccess: "Successfully changed staff",
        resDelSuccess: "Successfully deleted staff",
        resAddFailed: "Failed to add staff",
        resEditFailed: "Failed to change staff",
        resDelFailed: "Failed to delete staff",
    },
}