import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import EditAdjustmentComponent from '../../../../../modules/dashboard-peternak/adjustment-stock-component/pakan-component/pakan-aksi-component/edit-adjustment-pakan-component'

function EditAdjustmentPakanPage(props) {
    const {visible,handleCancel,detail,handleSubmit, object, t} = props
    const [data,setData] = useState('')
    const [hs, setHs] = useState(null)
    const [qty, setQty] = useState(null)
    const [dif, setDif] = useState(null)
    const hsSak = object.filter(d => d?.idFeed === data?.feed?.value)[0]?.hargaPengeluaran
    const qtySak = object.filter(d => d?.idFeed === data?.feed?.value)[0]?.sisaStok
    const difference = data?.qty-qty

    
    useEffect(() => {
        function setDetailData() {
            if(detail === null){
                setData("")
            }
            else{
                setData(detail)
                setHs(hsSak)
                setQty(qtySak)
                setDif(difference)
            }
        }   
        setDetailData()
    }, [detail,hsSak,qtySak,difference])
    return ( 
        <EditAdjustmentComponent
            navigate={props.navigate}
            visible = {visible}
            hs={hs}
            qty={qty} 
            dif={dif}
            handleSubmit = {handleSubmit}
            handleCancel = {handleCancel}
            detail = {detail}
            object={object}
            data = {data}
            feeds = {props.pakan}
            t={t}
        />
    )
}

const mapStateToProps = state => ({
    pakan : state.pakan.dataPakan,
    idMonthlyStockFeed : state.adjustmentPakan.idMonthlyStockFeed,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditAdjustmentPakanPage)
export default page