export const recording = {
    title: "Recording Pemeliharaan",
    add: "Tambah Recording",
    detail: "Detail Recording Hari ke-",
    age: "Umur",
    date: "Tanggal",
    desc: "Keterangan",
    phDate: "Masukkan tanggal recording",
    msgDate: "Mohon masukkan tanggal recording!",
    resAddSuccess: "Berhasil menambahkan recording",
    resDelSuccess: "Berhasil menghapus recording",
    resAddFailed: "Gagal menambahkan recording",
    resDelFailed: "Gagal menghapus recording",
    delConfirm: "Apakah Anda yakin akan menghapus recording ini?",
    addConfirm: "Apakah Anda yakin akan membuat recording baru otomatis?",
    norearing: "Belum ada recording yang dimasukkan di kandang {{house}} periode {{rearing}}. Mohon input recording dahulu!",
    feed: {
        title: "Pakan",
        feedDay: "Pakan/hari (kg)",
        cumFeed: "CUM (kg)",
        fiAct: "FI Act (Gr/ekor)",
        fiStd: "Fi Std (Gr/ekor)",
        actCum: "Act Cum (Gr/ekor)",
        stdFiCum: "STD FI Cum (Gr/ekor)",
        deviationCum: "Deviasi CUM (%)",
        stock: "Stok Pakan",
        qty: "Jumlah (karung)",
        qtyWeight: "Jumlah (kg)",
        price: "Harga/karung (Rp/karung)",
        msgPrice: "Mohon masukkan harga",
        sack: 'karung',
        delConfirm: "Apakah anda yakin ingin menghapus data recording pakan?",
        add: "Tambah Recording Pakan",
        edit: "Edit Recording Pakan",
        msgFeed: "Mohon pilih pakan!",
        phFeed: "Pilih pakan",
        msgQty: "Mohon masukkan penggunaan pakan!",
        resAddSuccess: "Berhasil menambahkan recording pakan",
        resEditSuccess: "Berhasil mengubah recording pakan",
        resDelSuccess: "Berhasil menghapus recording pakan",
        resAddFailed: "Gagal menambahkan recording pakan",
        resEditFailed: "Gagal mengubah recording pakan",
        resDelFailed: "Gagal menghapus recording pakan",
    },
    ovk: {
        title: "OVK",
        stock: "Stok OVK",
        qty: "Jumlah",
        qtyProcurement: "Jumlah Pengadaan",
        add: "Tambah Recording OVK",
        delConfirm: "Apakah anda yakin ingin menghapus data recording ovk?",
        edit: "Edit Recording OVK",
        msgOvk: "Mohon pilih OVK!",
        phOvk: "Pilih OVK",
        msgQty: "Mohon masukkan jumlah penggunaan OVK!",
        resAddSuccess: "Berhasil menambahkan recording OVK",
        resEditSuccess: "Berhasil mengubah recording OVK",
        resDelSuccess: "Berhasil menghapus recording OVK",
        resAddFailed: "Gagal menambahkan recording OVK",
        resEditFailed: "Gagal mengubah recording OVK",
        resDelFailed: "Gagal menghapus recording OVK",
    },
    material: {
        title: "Material",
        stock: "Stok Material",
        qty: "Jumlah",
        add: "Tambah Recording Material",
        edit: "Edit Recording Material",
        delConfirm: "Apakah anda yakin ingin menghapus data recording material?",
        msgMaterial: "Mohon pilih material!",
        phMaterial: "Pilih material",
        msgQty: "Mohon masukkan jumlah penggunaan material!",
        resAddSuccess: "Berhasil menambahkan recording material",
        resEditSuccess: "Berhasil mengubah recording material",
        resDelSuccess: "Berhasil menghapus recording material",
        resAddFailed: "Gagal menambahkan recording material",
        resEditFailed: "Gagal mengubah recording material",
        resDelFailed: "Gagal menghapus recording material",
    },
    disease: {
        title: "Penyakit",
        qty: "Total (ekor)",
        add: "Tambah Recording Penyakit",
        pic: "Foto",
        delConfirm: "Apakah anda yakin ingin menghapus data recording penyakit?",
        delPicConfirm: "Apakah anda yakin ingin menghapus gambar recording penyakit?",
        edit: "Edit Recording Penyakit",
        desc: "Keterangan",
        msgQty: "Mohon masukkan jumlah ayam yang sakit!",
        msgDisease: "Mohon pilih penyakit!",
        msgPic: "Mohon pilih minimal 1 gambar!",
        phDisease: "Pilih penyakit",
        resAddSuccess: "Berhasil menambahkan recording penyakit",
        resEditSuccess: "Berhasil mengubah recording penyakit",
        resDelSuccess: "Berhasil menghapus recording penyakit",
        resAddFailed: "Gagal menambahkan recording penyakit",
        resEditFailed: "Gagal mengubah recording penyakit",
        resDelFailed: "Gagal menghapus recording penyakit",
        resAddPicSuccess: "Berhasil menambahkan gambar recording penyakit",
        resDelPicSuccess: "Berhasil menghapus gambar recording penyakit",
        resAddPicFailed: "Gagal menambahkan gambar recording penyakit",
        resDelPicFailed: "Gagal menghapus gambar recording penyakit",
    },
    overhead: {
        title: "Overhead",
        qty: "Jumlah",
        price: "Harga (Rp)",
        add: "Tambah Recording Overhead",
        delConfirm: "Apakah Anda yakin ingin menghapus data recording overhead?",
        edit: "Edit Recording Overhead",
        phOverhead: "Pilih overhead",
        msgOverhead: "Mohon pilih overhead!",
        msgPrice: "Mohon masukkan harga!",
        msgQty: "Mohon masukkan jumlah penggunaan overhead!",
        resAddSuccess: "Berhasil menambahkan recording overhead",
        resEditSuccess: "Berhasil mengubah recording overhead",
        resDelSuccess: "Berhasil menghapus recording overhead",
        resAddFailed: "Gagal menambahkan recording overhead",
        resEditFailed: "Gagal mengubah recording overhead",
        resDelFailed: "Gagal menghapus recording overhead",
    },
    depletion: {
        title: "Deplesi",
        culling: "Culling (ekor)",
        dead: "Mati (ekor)",
        qty: "Jumlah (ekor)",
        cum: "CUM (ekor)",
        percentDay: "Hari (%)",
        percentCum: "CUM (%)",
        stdDepletion: "STD (%)",
        type: "Jenis",
        add: "Tambah Recording Deplesi",
        edit: "Edit Recording Deplesi",
        delConfirm: "Apakah Anda yakin ingin menghapus data recording deplesi?",
        msgType: "Mohon masukkan jenis deplesi!",
        phType: "Pilih jenis deplesi",
        msgQty: "Mohon pilih jumlah deplesi!",
        resAddSuccess: "Berhasil menambahkan recording deplesi",
        resEditSuccess: "Berhasil mengubah recording deplesi",
        resDelSuccess: "Berhasil menghapus recording deplesi",
        resAddFailed: "Gagal menambahkan recording deplesi",
        resEditFailed: "Gagal mengubah recording deplesi",
        resDelFailed: "Gagal menghapus recording deplesi",
    },
    harvest: {
        title: "Panen",
        add: "Tambah Recording Panen",
        edit: "Edit Recording Panen",
        qty: "Jumlah (ekor)",
        weight: "Total Berat (kg)",
        restChicken: "Sisa ayam (ekor)",
        avg: "Rata-rata berat (kg/ekor)",
        price: "Harga/kg",
        total: "Total",
        customer: "Pelanggan",
        delConfirm: "Apakah Anda yakin ingin menghapus data recording panen?",
        desc: "Keterangan",
        phCustomer: "Pilih pelanggan",
        phDesc: "Masukkan keterangan..",
        msgQty: "Mohon masukkan jumlah ayam yang dipanen!",
        msgWeight: "Mohon masukkan berat!",
        msgPrice: "Mohon masukkan harga per kg!",
        msgTotal: "Mohon masukkan total!",
        msgCustomer: "Mohon pilih pelanggan!",
        resAddSuccess: "Berhasil menambahkan recording panen",
        resEditSuccess: "Berhasil mengubah recording panen",
        resDelSuccess: "Berhasil menghapus recording panen",
        resAddFailed: "Gagal menambahkan recording panen",
        resEditFailed: "Gagal mengubah recording panen",
        resDelFailed: "Gagal menghapus recording panen",
    },
    bw: {
        add: "Tambah Recording Pertumbuhan",
        edit: "Edit Recording Pertumbuhan",
        sampling: "Sampling (Gr/ekor)",
        stdBw: "STD (Gr/ekor)",
        deviation: "Deviasi (%)",
        adg: "Adg (Gr)",
        totalWeight: "Total Berat (Kg)",
        abw: "ABW (Gr)",
        growing: "Pertumbuhan",
        parameter: "Parameter",
        value: "Nilai (Gram)",
        weight: "Berat ayam sampling (Gram)",
        msgWeight: "Mohon masukkan berat ayam sampling!",
        delConfirm: "Apakah Anda yakin ingin menghapus data recording pertumbuhan?",
        resAddSuccess: "Berhasil menambahkan recording pertumbuhan",
        resEditSuccess: "Berhasil mengubah recording pertumbuhan",
        resDelSuccess: "Berhasil menghapus recording pertumbuhan",
        resAddFailed: "Gagal menambahkan recording pertumbuhan",
        resEditFailed: "Gagal mengubah recording pertumbuhan",
        resDelFailed: "Gagal menghapus recording pertumbuhan"
    },
    fcr: {
        act: "Act Cum(Gr)",
        stdFcr: "STD (Gr)",
    },
    feedTotal: "Pakan (Rp)",
    ovkTotal: "OVK (Rp)",
    materialTotal: "Material (Rp)",
    shrinkage: "Penyusutan (Rp)",
    hppTotal: "Total HPP (Rp)",
}