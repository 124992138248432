export const feedStock = {
    title: "Feed Stock",
    name: "Description",
    supplier: "Supplier",
    balance: "Rest Feed",
    add: "Add New Feed Stock",
    addMutation: "Add Mutation",
    mutation: 'Mutation',
    dateMutation: 'Mutation Date',
    phMutationDate: "Select mutation date",
    msgMutationDate: "Please select mutation date!",
    mutationWh: 'Destination Feed Warehouse',
    phMutationWh: "Select destination feed warehouse!",
    msgMutationWh: "Please select destination feed warehouse",
    edit: "Edit Feed Stock",
    feedWarehouse: "Feed Warehouse",
    feed: "Feed",
    sack: 'sacks',
    date: "Procurement Date",
    price: "Price per sack",
    qtyStock: "Amount of Stock",
    weight: "Weight (kg)",
    phFeedWarehouse: "Select feed warehouse",
    docNumber: 'Document Number',
    phFeed: "Select feed",
    phName: "Add description ...",
    phDate: "Select procurement date",
    phAddDate: "Select addition date",
    phUsageDate: "Select usage date",
    phPeriodDate: "Select period date",
    msgFeedWarehouse: "Please select warehouse!",
    msgFeed: "Please select feed!",
    msgName: "Please input description!",
    msgDate: "Please select procurement date!",
    msgAddDate: "Please select addition date!",
    msgUsageDate: "Please select usage date!",
    msgPeriodDate: "Please select period date!",
    msgPrice: "Please input feed price!",
    msgQtyStock: "Please input amount of stock!",
    msgWeight: "Please input amount of weight!",
    addStock: "Add Stock",
    titleAddStock: "Add Feed Stock",
    addDate: "Addition Date",
    delConfirm: "Are you sure you want to remove the feed stock",
    detail: "Details Feed Stock Transactions",
    addition: "Addition",
    trxDate: "Transaction Date",
    priceFeed: "Price per sack",
    qty: "Quantity (sacks)",
    qtyWeight: "Quantity (kg)",
    delTrxConfirm: "Are you sure you want to remove this feed transaction?",
    titleEditAdd: "Edit Feed Stock Addition",
    titleEditUsage: "Edit Feed Stock Usage",
    usage: "Usage",
    resAddSuccess: "Successfully added feed stock",
    resAddMutationSuccess: "Successfully added feed mutation",
    resEditSuccess: "Successfully changed feed stock",
    resEditTrxSuccess: "Successfully changed feed transaction",
    resDelSuccess: "Successfully deleted feed stock",
    resDelTrxSuccess: "Successfully deleted feed transaction",
    resAddFailed: "Failed to add feed stock",
    resAddMutationFailed: "Failed to add feed mutation",
    resEditFailed: "Failed to change feed stock",
    resEditTrxFailed: "Failed to change feed transaction",
    resDelFailed: "Failed to delete feed stock",
    resDelTrxFailed: "Failed to delete feed transaction",
}