import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { API } from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import { getListDisease } from '../store/penyakit-action'
import { createPenyakit, getPenyakit } from './query-penyakit'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { Modal, Form, Row, Col, Input } from 'antd'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'

function CreatePenyakitPage(props) {
    const { fetchData, setLoading, getListDisease, t } = props
    const [visible, setVisible] = useState(false)
    const [form] = Form.useForm()

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = (values) => {
        API.post(createPenyakit(values))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t('pages.master.disease.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createDisease._id) {
                    validationMessage('success', `${t('pages.master.disease.resAddSuccess')}`)
                    fetchData()
                    getListDisease(getPenyakit('', 0, 0))
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.master.disease.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <>
            <ButtonDashboard
                text={t('pages.master.disease.add')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <Modal
                visible={visible}
                title={t('pages.master.disease.add')}
                okText={t('general.add')}
                width={400}
                cancelText={t('general.cancel')}
                onCancel={() => setVisible(false)}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{symptom: ''}}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.disease.name')}</span>
                            <Form.Item
                                name="namaPenyakit" rules={[{ required: true, message: `${t('pages.master.disease.msgName')}` }]}
                            >
                                <Input
                                    placeholder="Flu..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.disease.symptom')}</span>
                            <Form.Item
                                name="symptom"
                            >
                                <Input.TextArea
                                    placeholder={t('pages.master.disease.phSymptom')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListDisease
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePenyakitPage)
export default page