
import React from 'react'
import { Wrapper, Status } from "@googlemaps/react-wrapper"
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import InputAuth from './input-auth';


const render = (status) => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return null;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
    React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
    if (
        isLatLngLiteral(a) ||
        a instanceof window.google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof window.google.maps.LatLng
    ) {
        return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    }
    // TODO extend to other types
    // use fast-equals for other objects
    return deepEqual(a, b);
})

function useDeepCompareMemoize(value) {
    const ref = React.useRef();

    if (!deepCompareEqualsForMaps(value, ref.current)) {
        ref.current = value;
    }
    return ref.current;
}

function MyMapComponent({
    center,
    zoom,
    onClick, onIdle, children,
    ...options
}) {
    const ref = React.useRef(null)
    const [map, setMap] = React.useState()

    React.useEffect(() => {
        if (ref.current && !map) {
            const t = setTimeout(() => {
                setMap(new window.google.maps.Map(ref.current, {
                    center, zoom,
                }))
            }, 100)

            return () => {
                window.clearTimeout(t)
            }
        }
        // eslint-disable-next-line
    }, [ref, map])

    useDeepCompareEffectForMaps(() => {
        if (map) {
            map.setOptions(options)
        }
    }, [map, options])

    React.useEffect(() => {
        if (map) {
            ["click", "idle"].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            );
            if (onClick) {
                map.addListener("click", onClick)
            }

            if (onIdle) {
                map.addListener("idle", () => onIdle(map))
            }
        }
    }, [map, onClick, onIdle])

    return (
        <>
            <div ref={ref} style={{ minHeight: '50vh', position: 'relative' }} id="map" />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    // @ts-ignore
                    return React.cloneElement(child, { map })
                }
            })}
        </>
    )
}

const Marker = (options) => {
    const [marker, setMarker] = React.useState();

    React.useEffect(() => {
        if (!marker) {
            setMarker(new window.google.maps.Marker());
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker])

    React.useEffect(() => {
        if (marker) {
            marker.setOptions(options);
        }
    }, [marker, options]);
    return null;
}

function InputMaps({ center, zoom, onClick, onIdle, onChange, position, placeholder, value }) {

    return (
        <div>
            <Wrapper apiKey={process.env.REACT_APP_GOOGLE_TOKEN} render={render}>
                <MyMapComponent
                    center={center} zoom={zoom}
                    onClick={onClick}
                    onIdle={onIdle}
                >
                    <Marker position={position} />
                </MyMapComponent>
            </Wrapper>
            <InputAuth
                placeholder={placeholder}
                className="input-register mt-5"
                onChange={onChange}
                value={value}
            />
        </div>
    )
}

export default InputMaps
