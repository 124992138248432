export const profile = {
    title: "Profil",
    changeProfile: "Ubah Profil",
    changePass: "Ubah Password",
    name: "Nama Lengkap",
    phone: "Nomor Telepon",
    company: "Nama Perusahaan",
    address: "Alamat",
    newPass: "Password Baru",
    oldPass: "Password Lama",
    phName: "Masukkan nama lengkap Anda",
    phEmail: "Masukkan email Anda",
    phUsername: "Masukkan username Anda",
    phPhone: "Masukkan nomor telepon Anda",
    phCompany: "Masukkan nama perusahaan Anda",
    phAddress: "Masukkan alamat Anda",
    phOldPass: "Masukkan password lama Anda",
    phNewPass: "Masukkan password baru Anda",
    msgName: "Mohon masukkan nama lengkap Anda!",
    msgEmail: "Mohon masukkan email Anda!",
    msgUsername: "Mohon masukkan username Anda!",
    msgPhone: "Mohon masukkan nomor telepon Anda!",
    msgCompany: "Mohon masukkan perusahaan Anda!",
    msgAddress: "Mohon masukkan alamat Anda!",
    msgOldPass: "Mohon masukkan password lama Anda!",
    msgNewPass: "Mohon masukkan password baru Anda minimal 6 karakter!",
    resEditProfileSuccess: "Berhasil memperbarui profil Anda",
    resEditPassSuccess: "Berhasil memperbarui password Anda",
    resEditPicSuccess: 'Berhasil memperbarui foto profil Anda',
    resEditProfileFailed: "Gagal memperbarui profil Anda",
    resEditPassFailed: "Gagal memperbarui password Anda",
    resEditPicFailed: 'Gagal memperbarui foto profil Anda'
}