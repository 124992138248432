import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import NumberFormat from 'react-number-format'
import Highlighter from 'react-highlight-words'
import { Space, Button, Input, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Loading from '../../../../common/component/loading/loading-container'
import { API } from '../../../../common/api'
import { getReportRecord } from '../query-dashboard-page'
import { useTranslation } from "react-i18next"

import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
import { useParams } from 'react-router-dom'

function TablePerformancePage(props) {
    const params = useParams()
    const idPeriode = params.rearingId
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const { t } = useTranslation()

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t('general.search')} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }
    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchDataRearingRecord = async () => {
        setLoading(true)
        await API.get(getReportRecord('', 0, 0, idPeriode))
            .then(res => {
                if (res.data.data.calculatedRearingRecords !== null) {
                    setObject(res.data.data.calculatedRearingRecords.calculatedArr)
                    setLoading(false)
                }
                else {
                    setObject([])
                    setLoading(false)
                }
            })
    }

    useEffect(() => {
        if (idPeriode) {
            fetchDataRearingRecord()
        }
        // eslint-disable-next-line
    }, [idPeriode])

    const columns = [
        {
            title: `${t('pages.dashboard.performance.age')}`,
            dataIndex: 'umur',
            key: 'umur',
            ...getColumnSearchProps('umur'),
            align: 'center'
        },
        {
            title: `${t('pages.dashboard.performance.population')}`,
            dataIndex: 'populasi',
            key: 'populasi',
            align: 'center'
        },
        {
            title: `${t('pages.dashboard.performance.bw')}`,
            dataIndex: 'bw',
            key: 'bw',
            align: 'center'
        },
        {
            title: `${t('pages.dashboard.performance.depletion')}`,
            dataIndex: 'deplesi',
            key: 'deplesi',
            align: 'center'
        },
        {
            title: `${t('pages.dashboard.performance.adg')}`,
            dataIndex: 'adg',
            key: 'adg',
            align: 'center'
        },
        {
            title: `${t('pages.dashboard.performance.fcr')}`,
            dataIndex: 'fcr',
            key: 'fcr',
            align: 'center'
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            key: 'ip',
            className: 'center',
            width: 140,
            render: (value, row, index) => (
                <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
            )
        },
    ]

    const data = object.map(({ _id, umur, tanggal, bw, fcr, saldo, total, adg, indeksPerformance }, index) => ({
        key: _id,
        id: _id,
        umur: umur + ` ${t('pages.dashboard.performance.day')}`,
        tanggal: moment(new Date(parseInt(tanggal))).format("DD MMMM YYYY"),
        deplesi: total + ` ${t('pages.dashboard.performance.chicken')}`,
        populasi: <NumberFormat value={saldo} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.dashboard.performance.chicken')}`} />,
        fcr: fcr,
        bw: bw,
        adg: adg.toFixed(2),
        ip: indeksPerformance,
    }))

    return (
        <Loading loading={loading}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                className="table-dashboard"
                scroll={{ x: 1000, y: '50vh' }}
            />
        </Loading>
    )
}

const mapStateToProps = state => ({
    idPeriode: state.layout.idPeriode,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(TablePerformancePage)
export default page