import React from 'react'
import { connect } from 'react-redux'
import { Result, Button } from 'antd'
import CONSTANTS from '../../common/utils/Constants'
import { navigate } from '../../common/store/action'

function LostPage(props) {
    const logo = require(`../../assets/svg/chicken-lost.png`)

    return (
        <Result className='vertical-center'
            style={{ position: 'absolute' }}
            // status="404"
            icon={<img src={logo} alt="BroilerX 404" />
            }
            title="404"
            subTitle="Sorry, you are going to somewhere we don't know."
            extra={<Button type="primary"
                onClick={() => props.navigate(CONSTANTS.HOME_MENU_KEY)}>Back Home</Button>}
        />
    )
}

const mapStateToProps = state => ({
})
const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(LostPage)
export default page