import React from 'react'
import { Modal, Form, Row, Col, InputNumber, Button, Tooltip, Select } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function CreatePertumbuhanComponent(props) {
    const { visible, handleSubmit, setVisible, houseActive, t } = props
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.recording.bw.add')}
                okText={t('general.add')}
                width={500} centered
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                    <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">Prosentase ayam</span><br />
                            <Form.Item
                                name='procentage'
                                rules={[
                                    { required: true, message: `Masukkan nilai prosentase` },
                                ]}
                            >
                                <InputNumber
                                    placeholder="100..."
                                    className="input-number"
                                    type='number'
                                    precision={0}
                                    addonAfter='%'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">Jenis ayam</span>
                            <Form.Item className=''
                                name='type'
                                rules={[{ required: true, message: `Masukkan jenis ayam` }]}
                            >
                                <Select
                                    showSearch
                                    placeholder='Jenis ayam'
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    <Select.Option value='rooster'>Jantan</Select.Option>
                                    <Select.Option value='hen'>Betina</Select.Option>
                                    <Select.Option value='big'>Besar</Select.Option>
                                    <Select.Option value='medium'>Sedang</Select.Option>
                                    <Select.Option value='small'>Kecil</Select.Option>
                                    <Select.Option value='tiny'>Sangat Kecil</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.bw.weight')}</span>
                            <Form.Item
                                name="nilai" rules={[{ required: true, message: `${t('pages.recording.bw.msgWeight')}` }]}
                            >
                                <InputNumber
                                    placeholder="1000..."
                                    className="input-number"
                                    addonAfter='gr'
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <>
            <Tooltip title={houseActive ? '' : t("error.inactiveHouse")}>
                <Button
                    disabled={houseActive ? false : true}
                    style={{
                        color: 'white',
                        backgroundColor: houseActive ? '#008ad4' : 'whitesmoke',
                        borderRadius: '5px',
                        float: "right",
                        border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
                    }} onClick={() => setVisible(true)}
                ><FontAwesomeIcon icon={faPlusSquare} className='mr-10' />
                    {t('pages.recording.bw.add')}
                </Button>
            </Tooltip>

            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </>
    )
}

export default CreatePertumbuhanComponent