import React from 'react'
import Tour from 'reactour'
import '../../../assets/scss/dashboard.scss'
import { Route, NavLink, Redirect, Link, Router, useHistory } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Layout, Menu, Avatar, Row, Col, Dropdown, Select, Button, Modal } from 'antd'
// import InfiniteScroll from 'react-infinite-scroll-component'
import Loading from '../../../common/component/loading/loading-container'
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import {
    faLaptop, faShoppingBasket, faCalculator, faClipboardList,
    faStickyNote, faUser, faSignOutAlt, faIdBadge, faCubes, faMoneyBillWave, faPlusSquare
} from '@fortawesome/free-solid-svg-icons'
import { isIOS, isMobileOnly } from 'react-device-detect';

import CreateRearing from '../../../app/dashboard-peternak/rearing-page/rearing-aksi-page/create-rearing-page'

import DashboardPeternakPage from '../../../app/dashboard-peternak/dashboard-page/dashboard-page'
import RearingPage from '../../../app/dashboard-peternak/rearing-page/rearing-active-page'
import RearingClosePage from '../../../app/dashboard-peternak/rearing-page/rearing-close-page'
import DetailRearingClosePage from '../../../app/dashboard-peternak/rearing-page/rearing-aksi-page/detail-rearing-close-page'

import RearingRecordPage from '../../../app/dashboard-peternak/rearing-record-page/rearing-record-page'
import CreateRecordPpl from '../../../app/dashboard-peternak/rearing-record-page/create-recording-ppl'
import RecordingHarvest from '../../../app/dashboard-peternak/rearing-record-page/recording-harvest'
import CreateRecordHarvest from '../../../app/dashboard-peternak/rearing-record-page/create-recording-harvest'
import DetailRecordHarvest from '../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-recording-harvest'
import DetailRearingRecordPage from '../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-rearing-record-page'
import CreatePenyakitRecordingPage from '../../../app/dashboard-peternak/rearing-record-page/create-detail-rearing-page/create-penyakit-page'
import EditPenyakitRecordingPage from '../../../app/dashboard-peternak/rearing-record-page/edit-detail-rearing-page/edit-penyakit-page'
import FotoPenyakitRecordingPage from '../../../app/dashboard-peternak/rearing-record-page/detail-rearing-record-page/detail-foto-penyakit-page'

import StokPakanPage from '../../../app/dashboard-peternak/stok-page/stok-pakan-page/stok-pakan-page'
import TransaksiStokPakanPage from '../../../app/dashboard-peternak/stok-page/stok-pakan-page/transaksi-stok-pakan-page/transaksi-stok-pakan-page'
import StokOvkPage from '../../../app/dashboard-peternak/stok-page/stok-ovk-page/stok-ovk-page'
import CreateStokOvkPage from '../../../app/dashboard-peternak/stok-page/stok-ovk-page/stok-ovk-aksi-page/create-stok-ovk-page'
import TransaksiStokOvkPage from '../../../app/dashboard-peternak/stok-page/stok-ovk-page/transaksi-stok-ovk-page/transaksi-stok-ovk-page'

import MonitorPage from '../../../app/dashboard-peternak/Iot-page/iot-page'
import EwsPage from '../../../app/dashboard-peternak/Iot-page/ews-page'
import DetailMonitorPage from '../../../app/dashboard-peternak/Iot-page/detail-iot-page/detail-iot-page'
import DetailEwsPage from '../../../app/dashboard-peternak/Iot-page/detail-iot-page/detail-ews-page'

import MasterKandangPage from '../../../app/dashboard-peternak/master-page/kandang-page/kandang-page'
import MasterStrainPage from '../../../app/dashboard-peternak/master-page/strain-page/strain-page'
import StandarPage from '../../../app/dashboard-peternak/master-page/standar-page/standar-page'
import DocPage from '../../../app/dashboard-peternak/master-page/doc-page/doc-page'
import OverheadPage from '../../../app/dashboard-peternak/master-page/overhead-page/overhead-page'
import DiseasePage from '../../../app/dashboard-peternak/master-page/penyakit-page/penyakit-page'

import MasterGudangPakanPage from '../../../app/dashboard-peternak/master-page/gudang-pakan-page/gudang-pakan-page'
import MasterPakanPage from '../../../app/dashboard-peternak/master-page/pakan-page/pakan-page'
import MasterOvkPage from '../../../app/dashboard-peternak/master-page/ovk-page/ovk-page'
import MasterPetugasPage from '../../../app/dashboard-peternak/master-page/petugas-page/petugas-page'
import CustomerPage from '../../../app/dashboard-peternak/master-page/customer-page/customer-page'
import ProfilePage from '../../../app/dashboard-peternak/profil-page/profil-page'
import NotAuthorizePage from '../../../app/error/error-page'
import VoucherPage from '../../../app/dashboard-peternak/ads-page/voucher-page'
import PricingPage from '../../../app/dashboard-peternak/ads-page/pricing-page'
import SubscriptionPage from '../../../app/dashboard-peternak/subscription-page/subscription-page'
import InvoicePage from '../../../app/dashboard-peternak/subscription-page/detail-invoice'

import StockFlowPeriode from '../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-page'
import StockFlowLiveBird from '../../../app/dashboard-peternak/stock-flow-page/live-bird-page/live-bird-page'
import StockFlowPakan from '../../../app/dashboard-peternak/stock-flow-page/pakan-page/pakan-page'
import StockFlowOvk from '../../../app/dashboard-peternak/stock-flow-page/ovk-page/ovk-page'
import StockFlowResume from '../../../app/dashboard-peternak/stock-flow-page/resume-page/resume-page'
import PeriodeFeed from '../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-aksi-page/detail-pakan'
import PeriodeOvk from '../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-aksi-page/detail-ovk'
import PeriodeLiveBird from '../../../app/dashboard-peternak/stock-flow-page/periode-page/periode-aksi-page/detail-livebird'
import AdjustmentFeed from '../../../app/dashboard-peternak/adjustment-stock-page/pakan-page/pakan-page'
import AdjustmentOvk from '../../../app/dashboard-peternak/adjustment-stock-page/ovk-page/ovk-page'
import ButtonDashboard from '../../../common/component/button/button-dashboard'
import RecordingBatch from '../../../app/dashboard-peternak/rearing-record-page/recording-batch'

function onLogout() {
    window.Mobile && window.Mobile.handleLogoutMobile('')
}

function LayoutComponent(props) {
    const { Header, Sider } = Layout
    const { SubMenu } = Menu
    const { Option } = Select
    const history = useHistory()
    const {
        current, loading, setLoading, clickedMenu, collapsed, toggle, handleAdd,
        kandang, width, idKandang, handleChangeKandang, idPeriode, pathArray,
        setIsTour, setVisible, visible, fetchDataActive, typeUser, profil,
        gudangPakan, gudangOvk, idGudangPakan, idGudangOvk,
        handleChangeGudangPakan, handleChangeGudangOvk, setCurrent, handleProfil, setCollapsed,
        location, lang, handleLocales, t, photo, handleSubscription, activeRearing, loadingRearing,
        loadingFeedWh, loadingOvkWh,
        //  dataUser, handleLogout, role, isTourOpen
        // isNotif, setIsNotif, notifications,
        // loadMoreNotif, notifCount, unreadNotif, clearUnreadNotif, limitNotif, changeReceiveNotif, loadReceiveNotif,
        isRecording, showRecording, form, loadingRecording, changeKandangRecording, onChangeOvk, onChangeFeed,
        postRecording, setDate, loadingPriceFeed, loadingPriceOvk, stokPakan, stokOvk,
    } = props

    const readDashboard = profil.permission?.readDashboard
    const logo = typeUser === 'premium' ? require(`../../../assets/images/logo.png`) : require(`../../../assets/images/logo.png`)
    let collapse = collapsed ? 'none' : 'inline'
    let css = collapsed ? 'collapsed-sidebar' : 'not-collapsed-sidebar'

    const profile = (
        <Menu>
            <Menu.Item onClick={() => handleProfil()}>
                <FontAwesomeIcon icon={faUser} />
                <span className="black font-content font-medium ml-10">{t('sidebar.profile')}</span>
            </Menu.Item>
            <Menu.Item onClick={() => handleSubscription()} hidden>
                <FontAwesomeIcon icon={faMoneyBillWave} />
                <span className="black font-content font-medium ml-10">{t('sidebar.subscription')}</span>
            </Menu.Item>
            <NavLink to='/logout'>
                <Menu.Item
                    onClick={() => {
                        onLogout()
                    }}
                >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                    <span className="black font-content font-medium ml-10">{t('sidebar.logout')}</span>
                </Menu.Item>
            </NavLink>
        </Menu >
    )
    const steps = [
        {
            selector: '.dashboard',
            content: () => (
                <div className="font-medium mt-10">
                    <div><b>{t('pages.tour.welcome')}</b></div>
                    {t('pages.tour.step1')}
                </div>
            ),
            style: {
                textAlign: "center"
            }
        },
        {
            selector: '.header',
            content: () => (
                <div className="font-medium mt-10">{t('pages.tour.step2')}</div>
            ),
        },
        {
            selector: '.section-menu-dashboard',
            content: () => (
                <div className="font-medium mt-10">{t('pages.tour.step3')}</div>
            ),
        },
        {
            selector: '.master',
            content: () => (
                <div className="font-medium mt-10">{t('pages.tour.step4')}</div>
            ),
        },
        {
            selector: '.stok',
            content: () => (
                <div className="font-medium mt-10">{t('pages.tour.step5')}</div>
            ),

        },
        {
            selector: '.monitor',
            content: () => (
                <div className="font-medium mt-10">{t('pages.tour.step6')}</div>
            ),

        },
        {
            selector: '.periode',
            content: () => (
                <div className="font-medium mt-10">{t('pages.tour.step7')}</div>
            ),

        },
        {
            selector: '.recording',
            content: () => (
                <div className="font-medium mt-10">{t('pages.tour.step8')}</div>
            ),

        },
        {
            selector: '.dashboards',
            content: () => (
                <div className="font-medium mt-10">{t('pages.tour.step9')}</div>
            ),

        },
        {
            selector: '.dashboard',
            content: () => (
                <div className="font-medium mt-10">
                    {t('pages.tour.step10')}
                    <br />
                    <Button style={{
                        color: 'white',
                        backgroundColor: '#0097c4',
                        borderRadius: '5px'
                    }}
                        onClick={() => handleAdd()}
                        className="mt-10">{t('pages.tour.cta')}</Button>
                </div>
            ),
            style: {
                textAlign: "center"
            }
        }
    ]
    return (
        <Router history={history}>
            <Loading loading={loading}>
                <div hidden={collapsed} className={'sidebar-overlay'} onClick={() => setCollapsed(true)}></div>
                <Tour
                    steps={steps}
                    // isOpen={role === 'superadmin' ? false : isTourOpen}
                    isOpen={false}
                    badgeContent={(curr, tot) => `${curr} of ${tot}`}
                    nextButton={
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#0097c4',
                            borderRadius: '5px'
                        }} className="mt-10">{t('general.next')}</Button>
                    }
                    prevButton={
                        <Button style={{
                            color: 'white',
                            backgroundColor: '#ff6d4d',
                            borderRadius: '5px'
                        }} className="mt-10">{t('general.back')}</Button>
                    }
                    onRequestClose={() => setIsTour()}
                    disableDotsNavigation={true}
                    lastStepNextButton={
                        <Button disabled style={{
                            color: 'white',
                            backgroundColor: '#0097c4',
                            borderRadius: '5px'
                        }} className="mt-10">{t('general.fin')}</Button>
                    }
                />
                <CreateRearing t={t} setVisible={setVisible} visible={visible}
                    setLoading={setLoading} fetchDataActive={fetchDataActive} />
                {profil.permission &&
                    <Layout style={{ minHeight: '100vh' }} className="landing-container">
                        <Sider
                            width={260}
                            theme="light"
                            trigger={null}
                            collapsible
                            hidden={profil.permission?.staffMode}
                            collapsed={collapsed}
                            className={collapsed ? 'sidebar-normal-collapsed' : 'sidebar-normal'}
                            style={!isMobileOnly && {
                                overflow: 'auto',
                                height: '100vh',
                                position: 'fixed',
                                left: 0,
                                transition: 'all 0.2s ease-in-out'
                            }}
                        >
                            <div className="section-dashboard-logo">
                                <img src={logo} alt="BroilerX logo" width="60%" />
                            </div>
                            <div className="section-menu-dashboard">
                                <Menu mode="inline" defaultSelectedKeys={['dashboard']} selectedKeys={[current]}>
                                    <Menu.Item key="dashboard" onClick={clickedMenu} className="dashboards"
                                        hidden={!readDashboard}
                                    >
                                        <NavLink to={`/peternak/dashboard/${idPeriode}`}>
                                            <FontAwesomeIcon icon={faLaptop} />
                                            <span className="section-title-dashboard" style={{ display: collapse }}>{t('sidebar.dashboard')}</span>
                                        </NavLink>
                                    </Menu.Item>
                                    <SubMenu
                                        key="rearing"
                                        className="periode"
                                        hidden={!profil.permission?.readRearing}
                                        title={
                                            <div>
                                                <FontAwesomeIcon icon={faClipboardList} />
                                                <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "14px" }} >{t('sidebar.rearing')}</span>
                                            </div>
                                        }
                                    >
                                        <Menu.Item key="rearing-current" onClick={clickedMenu}
                                            className="periode">
                                            <NavLink to="/peternak/rearing">
                                                <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "17px" }}>{t('pages.rearing.rearingActive')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="rearing-closed" onClick={clickedMenu}
                                            className="periode">
                                            <NavLink to="/peternak/rearing-closed">
                                                <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "17px" }}>{t('pages.rearing.rearingClose')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                    </SubMenu>
                                    <Menu.Item key="recording" onClick={clickedMenu}
                                        hidden={!profil.permission?.readRecording}
                                        className="recording">
                                        <NavLink to={`/peternak/recording/${idPeriode}`}>
                                            <FontAwesomeIcon icon={faCalculator} />
                                            <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "17px" }}>{t('sidebar.recording')}</span>
                                        </NavLink>
                                    </Menu.Item>
                                    <SubMenu
                                        key="stok-menu"
                                        className="stok"
                                        hidden={!profil.permission?.readStock}
                                        title={
                                            <div>
                                                <FontAwesomeIcon icon={faShoppingBasket} />
                                                <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "14px" }} >{t('sidebar.stock')}</span>
                                            </div>
                                        }
                                    >
                                        <Menu.Item key="stok-pakan" onClick={clickedMenu}>
                                            <NavLink to={`/peternak/stok-pakan/${idGudangPakan}`}>
                                                <span className="section-title-dashboard">{t('sidebar.subMenustock.feed')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="stok-ovk" onClick={clickedMenu}>
                                            <NavLink to={`/peternak/stok-ovk/${idGudangOvk}`}>
                                                <span className="section-title-dashboard">{t('sidebar.subMenustock.ovk')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                    </SubMenu>
                                    {/* <SubMenu
                                        key="monitor"
                                        className="monitor"
                                        hidden={!profil.permission?.readIot}
                                        title={
                                            <div>
                                                <FontAwesomeIcon icon={faWifi} />
                                                <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "14px" }} >{t('sidebar.monitor')}</span>
                                            </div>
                                        }
                                    >
                                        <Menu.Item key="device" onClick={clickedMenu}>
                                            <NavLink to="/peternak/device">
                                                <span className="section-title-dashboard">{t('sidebar.subMenuMonitor.device')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="ews" onClick={clickedMenu}>
                                            <NavLink to="/peternak/ews">
                                                <span className="section-title-dashboard">{t('sidebar.subMenuMonitor.ews')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                    </SubMenu> */}

                                    <SubMenu
                                        key="stok-flow-menu"
                                        className="stok"
                                        hidden={!profil.permission?.readStockflow}
                                        title={
                                            <div>
                                                <FontAwesomeIcon icon={faCubes} />
                                                <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "14px" }}>{t("sidebar.stockflow")}</span>
                                            </div>
                                        }
                                    >
                                        <Menu.Item key="stock-flow-periode" onClick={clickedMenu}>
                                            <NavLink to="/peternak/stock-flow-periode">
                                                <span className="section-title-dashboard">{t("sidebar.subMenuStockflow.period")}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="stock-flow-pakan" onClick={clickedMenu}>
                                            <NavLink to={`/peternak/stock-flow-pakan/${idGudangPakan}`}>
                                                <span className="section-title-dashboard">{t("sidebar.subMenuStockflow.feed")}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="stock-flow-ovk" onClick={clickedMenu}>
                                            <NavLink to={`/peternak/stock-flow-ovk/${idGudangOvk}`}>
                                                <span className="section-title-dashboard">{t("sidebar.subMenuStockflow.ovk")}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="stock-flow-live-bird" onClick={clickedMenu}>
                                            <NavLink to="/peternak/stock-flow-live-bird">
                                                <span className="section-title-dashboard">{t("sidebar.subMenuStockflow.livebird")}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="stock-flow-resume" onClick={clickedMenu}>
                                            <NavLink to="/peternak/stock-flow-resume">
                                                <span className="section-title-dashboard">{t("sidebar.subMenuStockflow.resume")}</span>
                                            </NavLink>
                                        </Menu.Item>
                                    </SubMenu>
                                    <SubMenu
                                        hidden={!profil.permission?.readMaster}
                                        key="master-menu"
                                        className="master"
                                        title={
                                            <div>
                                                <FontAwesomeIcon icon={faStickyNote} />
                                                <span className="section-title-dashboard" style={{ display: collapse, marginLeft: "19px" }}>{t('sidebar.master')}</span>
                                            </div>
                                        }
                                    >
                                        <Menu.Item key="master-kandang" onClick={clickedMenu}>
                                            <NavLink to="/peternak/master-kandang">
                                                <span className="section-title-dashboard">{t('sidebar.subMenuMaster.house')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="master-strain" onClick={clickedMenu}>
                                            <NavLink to="/peternak/master-strain">
                                                <span className="section-title-dashboard">{t('sidebar.subMenuMaster.strain')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="master-doc" onClick={clickedMenu}>
                                            <NavLink to="/peternak/master-doc">
                                                <span className="section-title-dashboard">{t('sidebar.subMenuMaster.doc')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="master-gudang-pakan" onClick={clickedMenu}>
                                            <NavLink to="/peternak/master-gudang">
                                                <span className="section-title-dashboard">{t('sidebar.subMenuMaster.feedHouse')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="master-pakan" onClick={clickedMenu}>
                                            <NavLink to="/peternak/master-pakan">
                                                <span className="section-title-dashboard">{t('sidebar.subMenuMaster.feed')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="master-ovk" onClick={clickedMenu}>
                                            <NavLink to="/peternak/master-ovk">
                                                <span className="section-title-dashboard">{t('sidebar.subMenuMaster.ovk')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="master-overhead" onClick={clickedMenu}>
                                            <NavLink to="/peternak/master-overhead">
                                                <span className="section-title-dashboard">{t('sidebar.subMenuMaster.overhead')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="master-disease" onClick={clickedMenu}>
                                            <NavLink to="/peternak/master-disease">
                                                <span className="section-title-dashboard">{t('sidebar.subMenuMaster.disease')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="master-customer" onClick={clickedMenu}>
                                            <NavLink to="/peternak/master-customer">
                                                <span className="section-title-dashboard">{t("sidebar.subMenuMaster.customer")}</span>
                                            </NavLink>
                                        </Menu.Item>
                                        <Menu.Item key="master-petugas" onClick={clickedMenu}>
                                            <NavLink to="/peternak/master-petugas">
                                                <span className="section-title-dashboard">{t('sidebar.subMenuMaster.user')}</span>
                                            </NavLink>
                                        </Menu.Item>
                                    </SubMenu>
                                </Menu>
                                <Menu style={{ position: "relative", bottom: "0", width: "100%" }} mode="inline" selectedKeys={[current]}>
                                    <Menu.Item hidden={typeUser === 'Platinum' || typeUser === 'premium' ||
                                        !profil.permission?.createSubscription ? true : false || isIOS}
                                        key="broilerx-premium"
                                        onClick={clickedMenu}
                                        style={{ backgroundColor: "#fcba03", color: "white", height: 60 }}>
                                        <NavLink to="/peternak/pricing">
                                            <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                                <FontAwesomeIcon icon={faIdBadge} style={{ color: "white" }} />
                                                <span style={{ display: collapse, marginLeft: "15px", color: "white", fontSize: '0.9rem', fontWeight: 500 }}>
                                                    {t('sidebar.adPremium')}</span>
                                            </div>
                                        </NavLink>
                                    </Menu.Item>
                                    {/* <Menu.Item key="produk-broilerx" onClick={clickedMenu} style={{ backgroundColor: "#007fcf", color: "white", height: 60, }}>
                                    <div style={{ paddingTop: "10px", paddingBottom: "10px" }} onClick={() => window.open('https://wa.me/628112648133')}>
                                        <FontAwesomeIcon icon={faShoppingBasket} style={{ color: "white" }} />
                                        <span style={{ display: collapse, marginLeft: "5px", color: "white", fontSize: '0.9rem', fontWeight: 500 }}>
                                            {t('sidebar.adIot')}</span>
                                    </div>
                                </Menu.Item> */}
                                </Menu>
                            </div>
                        </Sider>

                        <Layout
                            className={profil.permission?.staffMode ? '' : css}
                            style={{ width: `${window.innerWidth - width}px` }}
                        // onClick={() => setCollapsed(true)}
                        >
                            <Header
                                style={{
                                    background: '#F0F7F7', position: 'fixed', zIndex: 99,
                                    width: profil.permission?.staffMode ? '100%' : `${window.innerWidth - width}px`
                                }} className="header">
                                <Row gutter={[10, 0]} justify="space-around" align="middle">
                                    {/* toggle */}
                                    <Col hidden={profil.permission?.staffMode} xs={{ span: 2, order: 1 }} lg={{ span: 1, order: 1 }}>
                                        <div className="section-header-page-dashboard">
                                            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                                className: 'trigger',
                                                onClick: toggle,
                                            })}
                                        </div>
                                    </Col>

                                    {/* kandang, gudang pakan, gudang ovk */}
                                    <Col hidden={profil.permission?.staffMode} xs={{ span: 24, order: 5 }} lg={{ span: 9, order: 2 }}>
                                        {current === 'dashboard' || (location.pathname.includes('/peternak/recording') && pathArray.length < 5) ? (
                                            <div className="section-header-page">
                                                <Select
                                                    disabled={loadingRearing}
                                                    showSearch
                                                    labelInValue
                                                    placeholder={t('pages.dashboard.phHouse')}
                                                    optionFilterProp="children"
                                                    style={{ width: '100%' }}
                                                    className="select-kategori black font-regular"
                                                    value={{ key: pathArray[3] }}
                                                    onChange={handleChangeKandang}

                                                >
                                                    {
                                                        activeRearing && activeRearing.map(data =>
                                                            <Option
                                                                key={data.rearingId}
                                                                value={data.rearingId}
                                                            ><span hidden>{JSON.stringify(data)}</span>{data.houseName}</Option>
                                                        )
                                                    }
                                                </Select>
                                            </div>
                                        ) : (
                                            (location.pathname.includes(`/peternak/stok-pakan`) && pathArray.length < 5) || location.pathname.includes('stock-flow-pakan') ||
                                                (location.pathname.includes('/peternak/stock-flow-periode') && pathArray[4]?.includes('pakan')) ? (
                                                <div className="section-header-page">
                                                    <Select
                                                        disabled={loadingFeedWh}
                                                        showSearch
                                                        labelInValue
                                                        placeholder={t('pages.dashboard.phFeedWh')}
                                                        optionFilterProp="children"
                                                        style={{ width: '100%' }}
                                                        className="select-kategori black font-regular"
                                                        value={{ key: pathArray[3] || idGudangPakan }}
                                                        onChange={handleChangeGudangPakan}
                                                    >
                                                        {(location.pathname.includes('/peternak/stock-flow-pakan'))
                                                            && <Option key='Semua' value='Semua'>{t('pages.stockflow.all')}</Option>}
                                                        {
                                                            gudangPakan
                                                                // .filter(d => d.houses.length > 0 && d.houses.every(c => c.isActive === true))
                                                                .map(data =>
                                                                    <Option
                                                                        key={data._id}
                                                                        value={data._id}
                                                                    >{data.name}</Option>
                                                                )
                                                        }
                                                    </Select>
                                                </div>
                                            ) : (
                                                (location.pathname.includes('/peternak/stok-ovk') && pathArray.length < 5) || location.pathname.includes('stock-flow-ovk') ||
                                                    (location.pathname.includes('/peternak/stock-flow-periode') && pathArray[4]?.includes('ovk')) ? (
                                                    <div className="section-header-page">
                                                        <Select
                                                            disabled={loadingOvkWh}
                                                            showSearch
                                                            labelInValue
                                                            placeholder={t('pages.dashboard.phOvkWh')}
                                                            optionFilterProp="children"
                                                            style={{ width: '100%' }}
                                                            className="select-kategori black font-regular"
                                                            value={{ key: pathArray[3] || idGudangOvk }}
                                                            onChange={handleChangeGudangOvk}
                                                        >
                                                            {location.pathname.includes('/peternak/stock-flow-ovk') &&
                                                                <Option key='Semua' value='Semua'>{t('pages.stockflow.all')}</Option>}
                                                            {
                                                                gudangOvk.map(data =>
                                                                    <Option
                                                                        key={data._id}
                                                                        value={data._id}
                                                                    >{data.name}</Option>
                                                                )
                                                            }
                                                        </Select>
                                                    </div>
                                                ) : (location.pathname.includes('/peternak/stok-ovk') && pathArray.length > 4) ?
                                                    <div className='font-title-medium font-bold'>{activeRearing.find(d => pathArray[3] === d.ovkWarehouseId)?.ovkWarehouseName}</div>
                                                    : (location.pathname.includes('/peternak/stok-pakan') && pathArray.length > 4) ?
                                                        <div className='font-title-medium font-bold'>{activeRearing.find(d => pathArray[3] === d.feedWarehouseId)?.feedWarehouseName}</div>
                                                        : (
                                                            <div className="section-header-page">
                                                            </div>
                                                        )))}
                                    </Col>

                                    {/* logo  */}
                                    <Col xs={{ span: 2, order: 2 }} lg={{ span: profil.permission?.staffMode ? 4 : 0, order: 3 }}>
                                        <Link to='/peternak/dashboard'>
                                            <img src={logo} className={'section-dashboard-image-logo'} alt="Broiler logo" width="120px" />
                                        </Link>
                                    </Col>

                                    {/* change lang, notif, profile  */}
                                    <Col
                                        xs={{ span: 14, offset: 6, order: 3 }}
                                        lg={{ span: 2, offset: profil.permission?.staffMode ? 11 : 0, order: 5 }}
                                        style={{ float: "right" }}>
                                        <div className="section-header-page-avatar">
                                            <Select defaultValue={lang} bordered={false} onChange={(e) => handleLocales(e)}>
                                                <Option value="id">ID</Option>
                                                <Option value="en">EN</Option>
                                            </Select>
                                            {/* <Badge size="small" offset={[-15, 15]}
                                            count={unreadNotif} hidden={!profil.permission?.readIot}>
                                            <Avatar size={50}
                                                icon={<BellFilled />}
                                                onClick={() => setIsNotif(!isNotif)}
                                                style={{ backgroundColor: '#f0f7f7', color: 'grey' }}
                                            />
                                        </Badge> */}
                                            <Dropdown overlay={profile} trigger={['click']}>
                                                <Avatar size={40} src={profil.foto ? profil.foto : photo ? photo : ''} icon={<UserOutlined />} className="avatar-header-dashboard" />
                                            </Dropdown>
                                        </div>
                                        {/* <Modal className='notif-card' visible={isNotif}
                                        onCancel={() => {
                                            setIsNotif(false)
                                            clearUnreadNotif(limitNotif)
                                        }}
                                        width={400}
                                        title={
                                            <>
                                                <span className=''>Notifikasi</span>
                                                <Switch className='float-right'
                                                    checked={profil.receivedNotification}
                                                    loading={loadReceiveNotif}
                                                    onChange={(e) => changeReceiveNotif(e)} />
                                            </>
                                        }
                                        closable={false}
                                        maskClosable={true} mask={false} footer={null}
                                    >
                                        <div style={{ height: '80vh', overflow: 'auto', }} id='scrollableDiv'>
                                            <InfiniteScroll
                                                dataLength={limitNotif}
                                                next={loadMoreNotif}
                                                hasMore={limitNotif < notifCount}
                                                loader={<Skeleton paragraph={{ rows: 1 }} active />}
                                                endMessage={<Divider plain>Tidak ada pemberitahuan lain</Divider>}
                                                scrollableTarget="scrollableDiv"
                                            >
                                                <List
                                                    dataSource={notifications}
                                                    locale={{ emptyText: 'Tidak ada pemberitahuan terbaru' }}
                                                    renderItem={item => (
                                                        <List.Item key={item._id}>
                                                            <List.Item.Meta
                                                                title={<p className='font-semi-bold mb-0'>{item.title}</p>}
                                                                description={<>
                                                                    <p className='font-regular black mb-5'>{item.message}</p>
                                                                    <p className='font-sub mb-0'>{item.createdAt}</p>
                                                                </>
                                                                }
                                                            />
                                                            {item.read === false && <Badge status="error" />}
                                                        </List.Item>
                                                    )}
                                                />
                                            </InfiniteScroll>
                                        </div>
                                    </Modal> */}
                                    </Col>
                                    <Col hidden={profil.permission?.staffMode}
                                        xs={{ span: 14, offset: 10, order: 4 }}
                                        lg={{ span: 4, offset: 8, order: 4 }}>
                                        <ButtonDashboard
                                            text='Buat Recording'
                                            height={5}
                                            backgroundColor="#008ad4"
                                            borderRadius="5px"
                                            className="font-semi-medium font-content-title button-add float-right"
                                            textColor="white"
                                            icon={faPlusSquare}
                                            onClick={() => showRecording(true)}
                                        />
                                        <Modal className='recording-card'
                                            visible={isRecording}
                                            onCancel={() => {
                                                showRecording(false)
                                            }}
                                            width={800}
                                            title='Buat Recording'
                                            closable={true}
                                            maskClosable={false}
                                            footer={null}
                                        >
                                            <RecordingBatch
                                                handleChangeKandang={changeKandangRecording}
                                                setDate={setDate}
                                                form={form} t={t}
                                                loading={loadingRecording}
                                                idKandang={idKandang}
                                                kandang={kandang}
                                                loadingPriceFeed={loadingPriceFeed}
                                                loadingPriceOvk={loadingPriceOvk}
                                                postData={postRecording}
                                                onChangeOvk={onChangeOvk}
                                                onChangeFeed={onChangeFeed}
                                                stokPakan={stokPakan}
                                                stokOvk={stokOvk}
                                            />
                                        </Modal>
                                    </Col>
                                </Row>
                            </Header>
                            <>
                                <Route
                                    path='/peternak/dashboard/:rearingId?'
                                    exact
                                    render={(props) => !readDashboard ?
                                        <Redirect to={`/peternak/recording/:rearingId`} /> : <DashboardPeternakPage {...props} />
                                    }
                                />
                                <Route
                                    path='/peternak/rearing'
                                    exact
                                    render={(props) => profil.permission?.readRearing ? <RearingPage {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/rearing-closed'
                                    exact
                                    render={(props) => profil.permission?.readRearing ? <RearingClosePage {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/rearing/detail-rearing'
                                    exact
                                    render={(props) => profil.permission?.readRearing ? <DetailRearingClosePage {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/recording/:rearingId'
                                    exact
                                    render={(props) => profil.permission?.readRecording ?
                                        <RearingRecordPage {...props} setCurrent={setCurrent} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/create-record-daily'
                                    exact
                                    render={(props) =>
                                        // profil.permission?.staffMode ?
                                        <CreateRecordPpl {...props} setCurrent={setCurrent} />
                                        // : <Redirect to='/peternak/403' />
                                    } />
                                <Route
                                    path='/peternak/harvest-recording'
                                    exact
                                    render={(props) =>
                                        <RecordingHarvest {...props} setCurrent={setCurrent} />
                                    } />
                                <Route
                                    path='/peternak/create-record-harvest'
                                    exact
                                    render={(props) => profil.permission?.staffMode ?
                                        <CreateRecordHarvest {...props} setCurrent={setCurrent} /> :
                                        <Redirect to='/peternak/403' />
                                    } />
                                <Route
                                    path='/peternak/recording/detail/record-harvest'
                                    exact
                                    render={(props) => !profil.permission?.staffMode ?
                                        <DetailRecordHarvest {...props} setCurrent={setCurrent} /> :
                                        <Redirect to='/peternak/403' />
                                    } />

                                <Route
                                    path='/peternak/recording/:rearingId/:recordingId'
                                    exact
                                    render={(props) => profil.permission?.readRecording ?
                                        <DetailRearingRecordPage {...props} setCurrent={setCurrent} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/recording/:rearingId/:recordingId/tambah-penyakit'
                                    exact
                                    render={(props) => profil.permission?.readRecording ?
                                        <CreatePenyakitRecordingPage {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/recording/:rearingId/:recordingId/edit-penyakit'
                                    exact
                                    render={(props) => profil.permission?.readRecording ?
                                        <EditPenyakitRecordingPage {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/recording/:rearingId/:recordingId/foto-penyakit'
                                    exact
                                    render={(props) => profil.permission?.readRecording ?
                                        <FotoPenyakitRecordingPage {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stok-pakan/:feedWarehouseId'
                                    exact
                                    render={(props) => profil.permission?.readStock ?
                                        <StokPakanPage {...props} setCurrent={setCurrent} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stok-pakan/:feedWarehouseId/transaksi/:feedStockId'
                                    exact
                                    render={(props) => profil.permission?.readStock ?
                                        <TransaksiStokPakanPage {...props} setCurrent={setCurrent} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stok-ovk/:ovkWarehouseId'
                                    exact
                                    render={(props) => profil.permission?.readStock ?
                                        <StokOvkPage {...props} setCurrent={setCurrent} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/buat-stok/:ovkWarehouseId'
                                    exact
                                    render={(props) => profil.permission?.readStock ?
                                        <CreateStokOvkPage {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stok-ovk/:ovkWarehouseId/transaksi/:ovkStockId'
                                    exact
                                    render={(props) => profil.permission?.readStock ?
                                        <TransaksiStokOvkPage {...props} setCurrent={setCurrent} /> :
                                        <Redirect to='/peternak/403' />}
                                />

                                <Route
                                    path='/peternak/device'
                                    exact
                                    render={(props) => profil.permission?.readIot ?
                                        <MonitorPage {...props} /> :
                                        <Redirect to='/peternak/403' />
                                    }
                                />
                                <Route
                                    path='/peternak/ews'
                                    exact
                                    render={(props) => profil.permission?.readIot ?
                                        <EwsPage {...props} /> :
                                        <Redirect to='/peternak/403' />
                                    }
                                />
                                <Route
                                    path='/peternak/device/detail-device'
                                    exact
                                    render={(props) => profil.permission?.readIot ? <DetailMonitorPage {...props} />
                                        : <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/ews/detail-ews'
                                    exact
                                    render={(props) => profil.permission?.readIot ?
                                        <DetailEwsPage {...props} /> :
                                        <Redirect to='/peternak/403' />
                                    }
                                />
                                <Route
                                    path='/peternak/stock-flow-periode'
                                    exact
                                    render={(props) => profil.permission?.readStockflow ? <StockFlowPeriode {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stock-flow-live-bird'
                                    exact
                                    render={(props) => profil.permission?.readStockflow ? <StockFlowLiveBird {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stock-flow-pakan/:feedWarehouseId'
                                    exact
                                    render={(props) => profil.permission?.readStockflow ? <StockFlowPakan {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stock-flow-ovk/:ovkWarehouseId'
                                    exact
                                    render={(props) => profil.permission?.readStockflow ? <StockFlowOvk {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stock-flow-resume'
                                    exact
                                    render={(props) => profil.permission?.readStockflow ? <StockFlowResume {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stock-flow-periode/:feedWarehouseId/pakan'
                                    exact
                                    render={(props) => profil.permission?.readStockflow ? <PeriodeFeed {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stock-flow-periode/:ovkWarehouseId/ovk'
                                    exact
                                    render={(props) => profil.permission?.readStockflow ? <PeriodeOvk {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stock-flow-periode/livebird'
                                    exact
                                    render={(props) => profil.permission?.readStockflow ? <PeriodeLiveBird {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stock-flow-periode/:feedWarehouseId/penyesuaian-pakan'
                                    exact
                                    render={(props) => profil.permission?.readStockflow ? <AdjustmentFeed {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/stock-flow-periode/:ovkWarehouseId/penyesuaian-ovk'
                                    exact
                                    render={(props) => profil.permission?.readStockflow ? <AdjustmentOvk {...props} /> :
                                        <Redirect to='/peternak/403' />}
                                />
                                <Route
                                    path='/peternak/master-kandang'
                                    exact
                                    render={(props) => !profil.permission?.readMaster ? <Redirect to='/peternak/403' /> :
                                        <MasterKandangPage {...props} />}
                                />
                                <Route
                                    path='/peternak/master-strain'
                                    exact
                                    render={(props) => !profil.permission?.readMaster ? <Redirect to='/peternak/403' /> :
                                        <MasterStrainPage {...props} />}
                                />
                                <Route
                                    path='/peternak/master-strain/standar-strain'
                                    exact
                                    render={(props) => !profil.permission?.readMaster ? <Redirect to='/peternak/403' /> :
                                        <StandarPage {...props} />}
                                />
                                <Route
                                    path='/peternak/master-doc'
                                    exact
                                    render={(props) => !profil.permission?.readMaster ? <Redirect to='/peternak/403' /> :
                                        <DocPage {...props} />}
                                />
                                <Route
                                    path='/peternak/master-gudang'
                                    exact
                                    render={(props) => !profil.permission?.readMaster ? <Redirect to='/peternak/403' /> :
                                        <MasterGudangPakanPage {...props} />}
                                />
                                <Route
                                    path='/peternak/master-ovk'
                                    exact
                                    render={(props) => !profil.permission?.readMaster ? <Redirect to='/peternak/403' /> :
                                        <MasterOvkPage {...props} />}
                                />
                                <Route
                                    path='/peternak/master-pakan'
                                    exact
                                    render={(props) => !profil.permission?.readMaster ? <Redirect to='/peternak/403' /> :
                                        <MasterPakanPage {...props} />}
                                />
                                <Route
                                    path='/peternak/master-overhead'
                                    exact
                                    render={(props) => !profil.permission?.readMaster ? <Redirect to='/peternak/403' /> :
                                        <OverheadPage {...props} />}
                                />
                                <Route
                                    path='/peternak/master-disease'
                                    exact
                                    render={(props) => !profil.permission?.readMaster ? <Redirect to='/peternak/403' /> :
                                        <DiseasePage {...props} />}
                                />
                                <Route
                                    path='/peternak/master-customer'
                                    exact
                                    render={(props) => !profil.permission?.readMaster ? <Redirect to='/peternak/403' /> :
                                        <CustomerPage {...props} />}
                                />
                                <Route
                                    path='/peternak/master-petugas'
                                    exact
                                    render={(props) => !profil.permission?.readUsers ? <Redirect to='/peternak/403' /> :
                                        <MasterPetugasPage {...props} />}
                                />
                                <Route
                                    path='/peternak/profil'
                                    exact
                                    render={(props) => <ProfilePage {...props} />}
                                />
                                <Route
                                    path='/peternak/subscription'
                                    exact
                                    render={(props) => !profil.permission?.createSubscription ? <Redirect to='/peternak/403' /> :
                                        <SubscriptionPage {...props} />}
                                />
                                <Route
                                    path='/peternak/invoice'
                                    exact
                                    render={(props) => <InvoicePage {...props} />}
                                />
                                <Route
                                    path='/peternak/voucher'
                                    exact
                                    render={(props) => <VoucherPage {...props} />}
                                />
                                <Route
                                    path='/peternak/pricing'
                                    exact
                                    render={(props) => !profil.permission?.createSubscription ?
                                        <Redirect to='/peternak/403' /> : <PricingPage {...props} />}
                                />
                                <Route
                                    path='/peternak/403'
                                    exact
                                    render={(props) => <NotAuthorizePage {...props} />}
                                />
                            </>

                        </Layout>
                    </Layout>
                }
            </Loading>
        </Router>
    )
}

export default LayoutComponent