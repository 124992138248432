/**
 * @author spindyzel
 * @since 28 Desember 2019
*/

import { createStore, combineReducers, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createHistory from 'history/createHashHistory'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'

// store app
import homeReducer from '../../modules/home/store/home-reducer'
import authReducer from '../../modules/auth/store/auth-reducer'
import ovkReducer from '../../modules/dashboard-peternak/master-component/ovk-component/store/ovk-reducer'
import pakanReducer from '../../modules/dashboard-peternak/master-component/pakan-component/store/pakan-reducer'
import gudangPakanReducer from '../../modules/dashboard-peternak/master-component/gudang-pakan-component/store/gudang-pakan-reducer'
import gudangOvkReducer from '../../modules/dashboard-peternak/master-component/gudang-ovk-component/store/gudang-ovk-reducer'
import stokPakanReducer from '../../modules/dashboard-peternak/stok-component/stok-pakan-component/store/stok-pakan-reducer'
import stokOvkReducer from '../../modules/dashboard-peternak/stok-component/stok-ovk-component/store/stok-ovk-reducer'
import adjustmentPakanReducer from '../../modules/dashboard-peternak/adjustment-stock-component/pakan-component/store/adjustment-pakan-reducer'
import adjustmentOvkReducer from '../../modules/dashboard-peternak/adjustment-stock-component/ovk-component/store/adjustment-ovk-reducer'
import layoutReducer from '../../modules/dashboard-peternak/layout-component/store/layout-reducer'
import rearingRecordReducer from '../../modules/dashboard-peternak/rearing-record-component/store/rearing-record-reducer'
import strainReducer from '../../app/dashboard-peternak/master-page/strain-page/store/strain-reducer'
import IotReducer from '../../app/dashboard-peternak/Iot-page/store/iot-reducer'
import profileReducer from '../../modules/dashboard-peternak/profil-component/store/profil-reducer'
import penyakitReducer from '../../app/dashboard-peternak/master-page/penyakit-page/store/penyakit-reducer'

const history = createHistory()
const browserHistory = createBrowserHistory()

const persistConfig = {
    key: 'your-apps',
    storage,
    stateReconciler: autoMergeLevel1,
    whitelist: ['home', 'auth', 'gudangOvk', 'gudangPakan', 'ovk', 'pakan', 'strain', 'penyakit',
        'adjustmentPakan', 'adjustmentOvk', 'layout', 'iot', 'stokPakan', 'stokOvk', 'rearingRecord', 'profil'],
}

const reducers = combineReducers({
    router: connectRouter(browserHistory),
    home: homeReducer,
    auth: authReducer,
    ovk: ovkReducer,
    pakan: pakanReducer,
    gudangOvk: gudangOvkReducer,
    gudangPakan: gudangPakanReducer,
    stokPakan: stokPakanReducer,
    stokOvk: stokOvkReducer,
    layout: layoutReducer,
    rearingRecord: rearingRecordReducer,
    iot: IotReducer,
    strain: strainReducer,
    penyakit: penyakitReducer,
    profil: profileReducer,
    adjustmentPakan : adjustmentPakanReducer,
    adjustmentOvk  :adjustmentOvkReducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

const middleware = routerMiddleware(browserHistory)
const store = createStore(persistedReducer, applyMiddleware(middleware, thunk))

const persistor = persistStore(store)

export { history, store, persistor, browserHistory }