import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Card, Button, Space, InputNumber, Divider, Upload, Input, TimePicker, Badge, Image, message, Tag, Modal, Form, Typography, Popconfirm, Table } from 'antd'
import {
    CalendarOutlined, UserOutlined,
    // PlusOutlined,
    EditOutlined
} from '@ant-design/icons'
import { faArrowLeft, faDownload, faEdit, faLock, faTrash, faUpload, } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import axios from 'axios'
import Cookies from 'universal-cookie'

import {
    addPanenDetail,
    deleteHarvestDetail,
    getDetailRecordPanen, getPanenDetails, lockingPanen, updatePanenDetail,
    //  updatePanenDetailQty, updatePanenDetailWeight, 
    updatePanenDriver, updatePanenEndTime,
    updatePanenQtySwap, updatePanenQtySwapper, updatePanenSignDriver, updatePanenSignWeigher, updatePanenStartTime,
    updatePanenWeigher, updatePanenWeightSwap, updatePanenWeightSwapper, updatePanenFarmer, updatePanenSignFarmer
} from './rearing-record-aksi-page/query-rearing-record'
import { getIndexHarvest } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'

import { API } from '../../../common/api'
import Loading from '../../../common/component/loading/loading-container'
import ButtonDashboard from '../../../common/component/button/button-dashboard'
import { errMessage } from '../../../common/component/notification/notification'
import { blobToDataURL } from '../../../common/utils/Download'

function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay)

        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
            clearTimeout(handler);
        };
    },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

function CreateRecordingHarvest(props) {
    const { idHarvest, indexHarvest, getIndexHarvest } = props
    const [loading, setLoading] = useState(false)
    const [panen, setPanen] = useState(null)
    const [panenDetail, setPanenDetail] = useState([])
    const [start, setStart] = useState(null)
    const [end, setEnd] = useState(null)
    const [driver, setDriver] = useState(null)
    const [weigher, setWeigher] = useState(null)
    const [farmer, setFarmer] = useState(null)
    const [qtySwap, setQtySwap] = useState(null)
    const [weightSwap, setWeightSwap] = useState(null)
    const [qtySwapper, setQtySwapper] = useState(null)
    const [weightSwapper, setWeightSwapper] = useState(null)

    // const [arrTimbang, setArrTimbang] = useState(60)
    // const [updateQty2, setUpdateQty2] = useState({ i: 0, e: 0 })
    // const [updateWeight2, setUpdateWeight2] = useState({ i: 0, e: 0 })

    const [form] = Form.useForm()
    const [formTable] = Form.useForm()
    const [editingKey, setEditingKey] = useState('')
    const isEditing = (record) => record._id === editingKey

    const [totalQty, setTotalQty] = useState(null)
    const [totalWeight, setTotalWeight] = useState(null)
    const [avg, setAvg] = useState(null)

    const [fileList, setFileList] = useState([])
    const [fileUpload, setFileUpload] = useState([])
    const [loadingPhoto, setLoadingPhoto] = useState(false)

    const [ttd, setTtd] = useState(null)
    const [ttd2, setTtd2] = useState(null)
    const [ttd3, setTtd3] = useState(null)
    const [loadingTtd, setLoadingTtd] = useState(false)
    const [loadingTtd2, setLoadingTtd2] = useState(false)
    const [loadingTtd3, setLoadingTtd3] = useState(false)

    let sigPad = {}
    let sigPad2 = {}
    let sigPad3 = {}
    const { confirm } = Modal

    const debouncedDriver = useDebounce(driver, 1000)
    const debouncedWeigher = useDebounce(weigher, 1000)
    const debouncedFarmer = useDebounce(farmer, 1000)
    const debouncedQtySwap = useDebounce(qtySwap, 1000)
    const debouncedWeightSwap = useDebounce(weightSwap, 1000)
    const debouncedQtySwapper = useDebounce(qtySwapper, 1000)
    const debouncedWeightSwapper = useDebounce(weightSwapper, 1000)

    // const debouncedIndexQty = useDebounce(updateQty2.i, 1000)
    // const debouncedIndexWeight = useDebounce(updateWeight2.i, 1000)
    // const debouncedQty = useDebounce(updateQty2.e, 1000)
    // const debouncedWeight = useDebounce(updateWeight2.e, 1000)

    const [loadingExport, setLoadingExport] = useState(false)
    const cookies = new Cookies()

    const [disabled, setDisabled] = useState(true)

    useEffect(() => {
        if (debouncedDriver) {
            updateDriver(debouncedDriver)
        }
        // eslint-disable-next-line
    }, [debouncedDriver])
    useEffect(() => {
        if (debouncedWeigher) {
            updateWeigher(debouncedWeigher)
        }
        // eslint-disable-next-line
    }, [debouncedWeigher])
    useEffect(() => {
        if (debouncedFarmer) {
            updateFarmer(debouncedFarmer)
        }
        // eslint-disable-next-line
    }, [debouncedFarmer])

    useEffect(() => {
        if (debouncedQtySwap) {
            updateQtySwap(debouncedQtySwap)
        }
        // eslint-disable-next-line
    }, [debouncedQtySwap])

    useEffect(() => {
        if (debouncedWeightSwap) {
            updateWeightSwap(debouncedWeightSwap)
        }
        // eslint-disable-next-line
    }, [debouncedWeightSwap])

    useEffect(() => {
        if (debouncedQtySwapper) {
            updateQtySwapper(debouncedQtySwapper)
        }
        // eslint-disable-next-line
    }, [debouncedQtySwapper])

    useEffect(() => {
        if (debouncedWeightSwapper) {
            updateWeightSwapper(debouncedWeightSwapper)
        }
        // eslint-disable-next-line
    }, [debouncedWeightSwapper])

    // useEffect(() => {
    //     if (debouncedQty) {
    //         updateQty(debouncedIndexQty, debouncedQty)
    //     }
    // }, [debouncedQty])

    // useEffect(() => {
    //     if (debouncedWeight) {
    //         updateWeight(debouncedIndexWeight, debouncedWeight)
    //     }
    // }, [debouncedWeight])

    const fetchDataPanen = async (idHarvest) => {
        setLoading(true)
        await API.get(getDetailRecordPanen(idHarvest))
            .then(res => {
                setPanen(res.data.data.mutation)
                res.data.data.mutation.startTime ? setStart(moment(new Date(parseInt(res.data.data.mutation.startTime)))) : setStart(null)
                res.data.data.mutation.endTime ? setEnd(moment(new Date(parseInt(res.data.data.mutation.endTime)))) : setEnd(null)

                setDriver(res.data.data.mutation.driver)
                setWeigher(res.data.data.mutation.weigher)
                setFarmer(res.data.data.mutation.farmer)

                setQtySwap(res.data.data.mutation.qtySwap)
                setWeightSwap(res.data.data.mutation.weightSwap)
                setQtySwapper(res.data.data.mutation.qtySwapper)
                setWeightSwapper(res.data.data.mutation.weightSwapper)

                setTtd(res.data.data.mutation.signDriver)
                setTtd2(res.data.data.mutation.signWeigher)
                setTtd3(res.data.data.mutation.signFarmer)

                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }

    const fetchDataPanenDetail = async (idHarvest) => {
        await API.get(getPanenDetails(idHarvest))
            .then(res => {
                setPanenDetail(res.data.data.harvestDetails.harvests)
                setTotalQty(res.data.data.harvestDetails.totalQty)
                setTotalWeight(res.data.data.harvestDetails.totalWeight)
                setAvg(res.data.data.harvestDetails.avg)

                // const totalCount = res.data.data.harvestDetails.totalCount
                // if (totalCount > 60) {
                //     setArrTimbang(totalCount)
                // }
            }).catch((error) => {
                console.log(error)
                setPanenDetail([])
            })
    }

    useEffect(() => {
        fetchDataPanen(idHarvest)
        fetchDataPanenDetail(idHarvest)
    }, [idHarvest])

    // const add = () => { setArrTimbang(arrTimbang + 1) }

    const updateStart = async (value) => {
        await API.post(updatePanenStartTime(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setStart(moment(new Date(parseInt(res.data.data.updateHarvest.startTime))))
                }
            }).catch((error) => {
                console.log(error)
                setStart(start)
            })
    }

    const updateEnd = async (value) => {
        await API.post(updatePanenEndTime(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setEnd(moment(new Date(parseInt(res.data.data.updateHarvest.endTime))))
                }
            }).catch((error) => {
                console.log(error)
                setEnd(end)
            })
    }

    const updateDriver = async (value) => {
        await API.post(updatePanenDriver(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setDriver(res.data.data.updateHarvest.driver)
                }
            }).catch((error) => {
                console.log(error)
                setDriver(driver)
            })
    }

    const updateWeigher = async (value) => {
        await API.post(updatePanenWeigher(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setWeigher(res.data.data.updateHarvest.weigher)
                }
            }).catch((error) => {
                console.log(error)
                setWeigher(weigher)
            })
    }

    const updateFarmer = async (value) => {
        await API.post(updatePanenFarmer(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setFarmer(res.data.data.updateHarvest.farmer)
                }
            }).catch((error) => {
                console.log(error)
                setFarmer(farmer)
            })
    }

    const updateQtySwap = async (value) => {
        await API.post(updatePanenQtySwap(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setQtySwap(res.data.data.updateHarvest.qtySwap)
                }
            }).catch((error) => {
                console.log(error)
                setQtySwap(qtySwap)
            })
    }
    const updateWeightSwap = async (value) => {
        await API.post(updatePanenWeightSwap(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setWeightSwap(res.data.data.updateHarvest.weightSwap)
                }
            }).catch((error) => {
                console.log(error)
                setWeightSwap(weightSwap)
            })
    }
    const updateQtySwapper = async (value) => {
        await API.post(updatePanenQtySwapper(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setQtySwapper(res.data.data.updateHarvest.qtySwapper)
                }
            }).catch((error) => {
                console.log(error)
                setQtySwapper(qtySwapper)
            })
    }
    const updateWeightSwapper = async (value) => {
        await API.post(updatePanenWeightSwapper(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setWeightSwapper(res.data.data.updateHarvest.weightSwapper)
                }
            }).catch((error) => {
                console.log(error)
                setWeightSwapper(weightSwapper)
            })
    }

    // const updateQty = (index, value) => {
    //     API.post(updatePanenDetailQty(idHarvest, index, value))
    //         .then(res => {
    //             if (res.data.data.changeHarvestDetail._id) {
    //                 fetchDataPanenDetail(idHarvest)
    //             }
    //         }).catch((error) => {
    //             console.log(error)
    //         })
    // }

    // const updateWeight = (index, value) => {
    //     API.post(updatePanenDetailWeight(idHarvest, index, value))
    //         .then(res => {
    //             if (res.data.data.changeHarvestDetail._id) {
    //                 fetchDataPanenDetail(idHarvest)
    //             }
    //         }).catch((error) => {
    //             console.log(error)
    //         })
    // }

    //photo
    const uploadPhoto = () => {
        setLoadingPhoto(true)
        const params = new FormData()
        if (fileUpload) {
            for (let i = 0; i < fileUpload.length; i++) {
                params.append(`file[${i}]`, fileUpload[i])
            }
        }
        API.updateRest(`/pic-harvest/${idHarvest}`, params)
            .then(res => {
                if (res.status === 500) {
                    setLoadingPhoto(false)
                    message.error(res.data.message)
                } else if (res.data.success === true) {
                    setLoadingPhoto(false)
                    setFileList([])
                    message.success('Berhasil mengupload foto')
                    fetchDataPanen(idHarvest)
                }
            }).catch((error) => {
                console.log(error)
                setLoadingPhoto(false)
                message.error('Gagal mengupload foto')
            })
    }


    const propsPicture = {
        name: 'file',
        onChange(info) {
            if (info.file.size > 3000000) {
                errMessage('error', 'Ukuran file harus lebih kecil dari 3 MB!')
            } else
            if (info.file.status === 'done') {
                let arrayPicture = []
                for (let i = 0; i < info.fileList.length; i++) {
                    arrayPicture.push(info.fileList[i].originFileObj)
                }
                setFileList(info.fileList)
                setFileUpload(arrayPicture)
            } else if (info.file.status === 'error') {
                console.log('err', info.file, info.fileList)
            }
        }
    }

    const onPreview = async file => {
        let src = file.url
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader()
                reader.readAsDataURL(file.originFileObj)
                reader.onload = () => resolve(reader.result)
            })
        }
        const image = new Image()
        image.src = src
        const imgWindow = window.open(src)
        imgWindow.document.write(image.outerHTML)
    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok")
        }, 0)
    }

    //TTD
    const updateSignDriver = async (value) => {
        setLoadingTtd(true)
        await API.post(updatePanenSignDriver(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setTtd(res.data.data.updateHarvest.signDriver)
                    message.success('Berhasil menyimpan Ttd driver')
                    setLoadingTtd(false)
                }
            }).catch((error) => {
                console.log(error)
                setTtd(ttd)
                setLoadingTtd(false)
                message.error('Gagal menyimpan Ttd driver')
            })
    }
    const clear = () => {
        sigPad.clear()
        setTtd(null)
    }
    const submitTtd = () => {
        if (!sigPad.isEmpty()) {
            updateSignDriver(sigPad.getCanvas().toDataURL())
        }
    }

    const setSign = (value) => {
        sigPad.fromDataURL(value, { width: 250, height: 200 })
    }

    const updateSignWeigher = async (value) => {
        setLoadingTtd2(true)
        await API.post(updatePanenSignWeigher(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setTtd2(res.data.data.updateHarvest.signWeigher)
                    message.success('Berhasil menyimpan Ttd Penimbang')
                    setLoadingTtd2(false)
                }
            }).catch((error) => {
                console.log(error)
                setTtd2(ttd2)
                setLoadingTtd2(false)
                message.error('Gagal menyimpan Ttd driver')
            })
    }

    const clear2 = () => {
        sigPad2.clear()
    }
    const submitTtd2 = () => {
        if (!sigPad2.isEmpty()) {
            updateSignWeigher(sigPad2.getCanvas().toDataURL())
        }
    }

    const setSign2 = (value) => {
        sigPad2.fromDataURL(value, { width: 250, height: 200 })
    }

    const updateSignFarmer = async (value) => {
        setLoadingTtd3(true)
        await API.post(updatePanenSignFarmer(idHarvest, value))
            .then(res => {
                if (res.data.data.updateHarvest._id) {
                    setTtd3(res.data.data.updateHarvest.signFarmer)
                    message.success('Berhasil menyimpan Ttd Peternak')
                    setLoadingTtd3(false)
                }
            }).catch((error) => {
                console.log(error)
                setTtd3(ttd3)
                setLoadingTtd3(false)
                message.error('Gagal menyimpan Ttd peternak')
            })
    }

    const clear3 = () => {
        sigPad3.clear()
    }
    const submitTtd3 = () => {
        if (!sigPad3.isEmpty()) {
            updateSignFarmer(sigPad3.getCanvas().toDataURL())
        }
    }

    const setSign3 = (value) => {
        sigPad3.fromDataURL(value, { width: 250, height: 200 })
    }

    useEffect(() => {
        if (ttd && !panen.isLocked) { setSign(ttd) }
        if (ttd2 && !panen.isLocked) { setSign2(ttd2) }
        if (ttd3 && !panen.isLocked) { setSign3(ttd3) }
        // eslint-disable-next-line
    }, [ttd, ttd2, ttd3, panen])

    useEffect(() => {
        if (
            !start || !end || !driver || !weigher || !farmer || panenDetail.length < 1 ||
            !ttd || !ttd2 || !ttd3 || !qtySwap || !weightSwap || !qtySwapper || !weightSwapper
        ) {
            setDisabled(true)
        } else setDisabled(false)
        // eslint-disable-next-line
    }, [panenDetail, start, end, driver, ttd, ttd2, ttd3, weigher, driver, farmer, qtySwap, weightSwap, qtySwapper, weightSwapper])

    //LOCK
    const lockingData = async () => {
        await API.post(lockingPanen(idHarvest, true))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `Gagal mengunci panen`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.lockingHarvest._id) {
                    errMessage('success', `Berhasil mengunci panen`)
                    fetchDataPanen(idHarvest)
                }
            }).catch((error) => {
                errMessage('error', `Gagal mengunci panen`, error.message)
                setLoading(false)
            })
    }

    const lockingConfirm = () => {
        confirm({
            title: `Apakah Anda yakin akan mengunci panen ini? Jika ya, Anda tidak akan bisa mengedit data panen ini lagi.`,
            onOk: () => { lockingData() },
            maskClosable: true,
            cancelText: 'Batal',
        })
    }

    //EXPORT PDF
    const exportHarvest = async () => {
        setLoadingExport(true)
        let token = cookies.get('token')
        await axios
            .get(`${process.env.REACT_APP_API_URL}/pdf-timbangan/${idHarvest}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                responseType: 'blob'
            })
            .then(res => {
                if (typeof (res.data === 'blob')) {
                    setLoadingExport(false)
                    const fileName = `Laporan  Timbangan Ayam ${panen?.rearingRecord.rearing.name} ${panen.rearingRecord.rearing.house.name}.pdf`
                    blobToDataURL(res.data, fileName)
                    errMessage('success', `Berhasil export Laporan Panen ke file PDF`)
                }
            }).catch((error) => {
                console.log('err', error)
                errMessage('error', `Gagal export Laporan Panen ke file PDF`, error.message)
                setLoadingExport(false)
            })
    }

    //TABLE
    useEffect(() => {
        if (panenDetail.length < 1) {
            getIndexHarvest(1)
        } else {
            getIndexHarvest(panenDetail.at(-1).index + 1)
        }
    }, [panenDetail, getIndexHarvest])

    const addDetail = (value) => {
        API.post(addPanenDetail(idHarvest, value))
            .then(res => {
                if (res.data.data.changeHarvestDetail._id) {
                    fetchDataPanenDetail(idHarvest)
                    getIndexHarvest(indexHarvest + 1)
                    form.setFieldsValue({ index: indexHarvest + 1 })
                }
            }).catch((error) => {
                console.log(error)
            })
    }

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {dataIndex === 'qty' ? <InputNumber size="small" precision={0} /> : <InputNumber size="small" />}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        )
    }

    const columns = [
        {
            title: `No.`,
            dataIndex: 'index',
            key: 'index',
            align: 'center'
        },
        {
            title: `Jumlah`,
            dataIndex: 'qty',
            key: 'qty',
            editable: true,
            align: 'right',
            render: (value, row, index) => (
                <>{value} ekor</>
            )
        },
        {
            title: `Berat`,
            dataIndex: 'weight',
            key: 'weight',
            editable: true,
            align: 'right',
            render: (value, row, index) => (
                <>{value} kg</>
            )
        },
        panen?.isLocked ? { width: 1 } : {
            // title: '',
            dataIndex: 'operation',
            align: 'center',
            render: (_, record) => {
                const editable = isEditing(record)
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record._id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Typography.Link
                            onClick={() => cancel()}
                        >
                            Cancel
                        </Typography.Link>

                    </span>
                ) : (
                    <>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)} className='mr-10'>
                            <FontAwesomeIcon icon={faEdit} />
                        </Typography.Link>
                        <Popconfirm title={`Yakin mau menghapus input nomor ${record.index}?`} onConfirm={() => deleteData(record._id)}>
                            <FontAwesomeIcon style={{ color: 'red', cursor: 'pointer' }} icon={faTrash} />
                        </Popconfirm>
                    </>
                )
            }
        },
    ]

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'number',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            })
        }
    })

    const edit = (record) => {
        formTable.setFieldsValue({
            qty: '',
            weight: '',
            ...record,
        })
        setEditingKey(record._id)
    }

    const cancel = () => {
        setEditingKey('')
    }

    const save = async (_id) => {
        const value = await formTable.validateFields()
        API.post(updatePanenDetail(_id, value))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', 'Gagal mengubah input timbangan', res.data.errors[0].message)
                } else
                    if (res.data.data.updateHarvestDetail._id) {
                        errMessage('success', 'Berhasil mengubah input timbangan')
                        setEditingKey('')
                        fetchDataPanenDetail(idHarvest)
                    }
            }).catch((error) => {
                errMessage('error', 'Gagal mengubah input timbangan', error.message)
            })
    }

    const deleteData = async (_id) => {
        await API.delete(deleteHarvestDetail(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', 'Gagal menghapus input timbangan', res.data.errors[0].message)
                }
                else if (res.data.data.deleteHarvestDetail.deleted === true) {
                    errMessage('success', `Berhasil menghapus input timbangan`)
                    fetchDataPanenDetail(idHarvest)
                }
            }).catch((error) => {
                errMessage('error', 'Gagal menghapus input timbangan', error.message)
            })
    }

    return (
        <Layout>
            <Layout.Content className="dashboard-container">
                <Row>
                    <Col lg={{ span: 16, offset: 4 }}>
                        <Space align='start'>
                            <Link to="/peternak/harvest-recording">
                                <Button shape="circle" style={{ border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 5%)' }}
                                    icon={<FontAwesomeIcon icon={faArrowLeft} />} size='default' className='mr-10'
                                />
                            </Link>
                            <div className='font-title-dashboard font-semi-bold mb-10'>Recording Panen</div>
                        </Space>

                        <Card>
                            <Loading loading={loading}>
                                {panen &&
                                    <>
                                        {panen.isLocked && <Tag className='float-right' color='#ff4a3d'><FontAwesomeIcon icon={faLock} className='mr-10' />Terkunci</Tag>}
                                        <div className='font-semi-bold font-title-content'>{panen.rearingRecord.rearing.house.name}</div>
                                        <CalendarOutlined /> <span className='font-light font-content ml-5'>
                                            {moment(new Date(parseInt(panen.rearingRecord.date))).format('DD MMMM YYYY')}
                                        </span>
                                        <Card style={{ backgroundColor: '#fafafa' }} className='mt-20 mb-20'>
                                            <Row gutter={[16, 16]}>
                                                <Col span={8}>
                                                    <div className='font-semi-bold'>Plat Nomor</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className='font-semi-bold'>Tonase DO</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className='font-semi-bold'>Customer</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={8}>
                                                    <div>{panen.plat}</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div>{panen.tonase} kg</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div>{panen.customer.name}</div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <div className='font-semi-bold font-title-content'>Input Penimbangan Panen</div>
                                        <Divider className='mb-10 mt-0' />
                                        <div>Jam Mulai Penimbangan</div>
                                        <TimePicker showTime
                                            disabled={panen.isLocked ? true : false}
                                            value={start} allowClear={false}
                                            onChange={(e) => updateStart(e)}
                                        />
                                        {/* <Row className='mb-0 mt-20'>
                                            <Col span={4}>
                                                <div className='font-semi-bold'>No.</div>
                                            </Col>
                                            <Col span={10}>
                                                <div className='font-semi-bold center'>Ekor</div>
                                            </Col>
                                            <Col span={10}>
                                                <div className='font-semi-bold center'>kg</div>
                                            </Col>
                                        </Row> */}

                                        <Form form={formTable} component={false}>
                                            <Row gutter={[4, 4]}>
                                                <Col span={24}>
                                                    <Table
                                                        components={{
                                                            body: {
                                                                cell: EditableCell,
                                                            },
                                                        }}
                                                        columns={mergedColumns}
                                                        dataSource={panenDetail}
                                                        bordered
                                                        pagination={false}
                                                        className=" mt-10 mb-10"
                                                    />
                                                </Col>
                                            </Row>
                                        </Form>

                                        <Form
                                            hidden={panen.isLocked}
                                            form={form}
                                            layout="horizontal"
                                            requiredMark={false}
                                            autoComplete="off"
                                            initialValues={{ index: indexHarvest }}
                                        >
                                            <Row>
                                                <Col span={2}>
                                                    <Form.Item style={{ marginBottom: 0 }}
                                                        name="index" rules={[{ required: true }]}
                                                    >
                                                        <InputNumber value={indexHarvest} readOnly bordered={false} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10}>
                                                    <Form.Item style={{ marginBottom: 0 }}
                                                        name="qty" rules={[{ required: true }]}
                                                    >
                                                        <InputNumber
                                                            readOnly={panen.isLocked ? true : false}
                                                            type='number'
                                                            addonAfter='ekor'
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={10} offset={2}>
                                                    <Form.Item style={{ marginBottom: 0 }}
                                                        name="weight" rules={[{ required: true }]}
                                                    >
                                                        <InputNumber
                                                            readOnly={panen.isLocked ? true : false}
                                                            type='number'
                                                            addonAfter='kg'
                                                            style={{ width: '100%' }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Button size='small' block className='float-right mt-10 mb-20'
                                                style={{ backgroundColor: '#25a18e', color: 'white' }}
                                                onClick={() => form
                                                    .validateFields()
                                                    .then((values) => {
                                                        form.resetFields()
                                                        addDetail(values)
                                                    })
                                                    .catch((info) => {
                                                        console.log('Validate Failed:', info)
                                                    })}
                                            >Tambah Penimbangan</Button>
                                        </Form>
                                        {/* {Array.from(Array(arrTimbang).keys()).map((e, i) =>
                                            <Row>
                                                <Col span={4} >
                                                    <InputNumber value={i + 1} readOnly bordered={false} />
                                                </Col>
                                                <Col span={10}>
                                                    <InputNumber
                                                        readOnly={panen.isLocked ? true : false}
                                                        type='number'
                                                        value={panenDetail.find(ii => ii.index === i + 1)?.qty}
                                                        style={{ width: '100%' }}
                                                        onChange={e => setUpdateQty2({ i: i + 1, e: e })}
                                                    />
                                                </Col>
                                                <Col span={10}>
                                                    <InputNumber
                                                        readOnly={panen.isLocked ? true : false}
                                                        type='number'
                                                        value={panenDetail.find(ii => ii.index === i + 1)?.weight}
                                                        style={{ width: '100%' }}
                                                        onChange={e => setUpdateWeight2({ i: i + 1, e: e })}
                                                    />
                                                </Col>
                                            </Row>
                                        )} */}
                                        {/* <Button
                                            hidden={panen.isLocked ? true : false}
                                            className='mt-10 center' type="dashed" block onClick={() => add()} icon={<PlusOutlined />}>
                                            Tambah Penimbangan
                                        </Button> */}
                                        <Divider />
                                        <Row gutter={[16, 16]}>
                                            <Col lg={12} xs={24}>
                                                <span className="font-semi-bold font-sub">Jumlah Ayam Tukar</span>
                                                <InputNumber
                                                    readOnly={panen.isLocked ? true : false}
                                                    value={qtySwap}
                                                    onChange={e => setQtySwap(e)}
                                                    placeholder="1000..."
                                                    className="input-number"
                                                    type='number'
                                                    addonAfter='ekor'
                                                />
                                            </Col>
                                            <Col lg={12} xs={24}>
                                                <span className="font-semi-bold font-sub">Berat Ayam Tukar</span>
                                                <InputNumber
                                                    readOnly={panen.isLocked ? true : false}
                                                    value={weightSwap}
                                                    onChange={e => setWeightSwap(e)}
                                                    placeholder="1000..."
                                                    className="input-number"
                                                    type='number'
                                                    addonAfter='kg'
                                                />
                                            </Col>
                                            <Col lg={12} xs={24}>
                                                <span className="font-semi-bold font-sub">Jumlah Ayam Penukar</span>
                                                <InputNumber
                                                    readOnly={panen.isLocked ? true : false}
                                                    value={qtySwapper}
                                                    onChange={e => setQtySwapper(e)}
                                                    placeholder="1000..."
                                                    className="input-number"
                                                    type='number'
                                                    addonAfter='ekor'
                                                />
                                            </Col>
                                            <Col lg={12} xs={24}>
                                                <span className="font-semi-bold font-sub">Berat Ayam Penukar</span>
                                                <InputNumber
                                                    readOnly={panen.isLocked ? true : false}
                                                    value={weightSwapper}
                                                    onChange={e => setWeightSwapper(e)}
                                                    placeholder="1000..."
                                                    className="input-number"
                                                    type='number'
                                                    addonAfter='kg'
                                                />
                                            </Col>
                                        </Row>
                                        <Card style={{ backgroundColor: '#fafafa' }} className='mt-20 mb-20'>
                                            <Row gutter={[16, 16]}>
                                                <Col span={8}>
                                                    <div className='font-semi-bold'>Total Ekor Panen</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className='font-semi-bold'>Total kg Panen</div>
                                                </Col>
                                                <Col span={8}>
                                                    <div className='font-semi-bold'>Berat Rata² Panen</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={8}>
                                                    <div>
                                                        <NumberFormat value={totalQty} suffix=' ekor' displayType={'text'} thousandSeparator={true} />
                                                    </div>
                                                </Col>
                                                <Col span={8}>
                                                    <div>
                                                        <NumberFormat value={totalWeight} suffix=' kg' displayType={'text'} thousandSeparator={true} />
                                                    </div>
                                                </Col>
                                                <Col span={8}>
                                                    <div>
                                                        <NumberFormat value={avg} suffix=' kg/ekor' displayType={'text'} thousandSeparator={true} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                        <div>Jam Selesai Penimbangan</div>
                                        <TimePicker showTime
                                            disabled={panen.isLocked ? true : false}
                                            value={end} allowClear={false}
                                            onChange={e => updateEnd(e)}
                                        />
                                        <div className='mt-20'>Upload Foto</div>
                                        <Upload
                                            {...propsPicture}
                                            maxCount={1}
                                            customRequest={dummyRequest}
                                            onPreview={onPreview}
                                            onRemove={() => setFileList([])}
                                            listType={fileList.length > 0 ? "picture-card" : false}
                                            showUploadList={fileList.length < 1 ? false : true}
                                        >
                                            {fileList.length > 0 ? '' :
                                                <Badge count={<Button shape="circle" size='small' icon={<EditOutlined />} />}
                                                    hidden={fileList.length < 1 && !panen.isLocked ? false : true}>
                                                    {panen.photo ? <img src={panen.photo} alt="avatar" style={{ width: '100%' }} /> :
                                                        ''
                                                    }
                                                </Badge>
                                            }
                                        </Upload>
                                        {fileList.length > 0 &&
                                            <ButtonDashboard
                                                text='Upload'
                                                height={20} size='small'
                                                backgroundColor="#008ad4"
                                                borderRadius="5px"
                                                className="font-semi-medium font-content-title button-edit"
                                                textColor="white"
                                                icon={faUpload}
                                                onClick={uploadPhoto}
                                                loading={loadingPhoto}
                                            />
                                        }
                                        <Row gutter={[24, 24]} className='mt-20'>
                                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                                <Row>
                                                    <Col span={16}>
                                                        <span className='font-regular'>Nama & Ttd Sopir</span>
                                                    </Col>
                                                    <Col span={4} offset={4}>
                                                        <Button
                                                            hidden={panen.isLocked ? true : false}
                                                            className='float-right' type='primary' danger size='small' onClick={() => clear()}>Clear</Button>
                                                    </Col>
                                                </Row>
                                                {panen.isLocked ?
                                                    <Image className='center' preview={false} width={250} height={200} src={panen.signDriver} /> :
                                                    <div style={{ backgroundColor: '#fafafa' }}>
                                                        <SignatureCanvas
                                                            ref={(ref) => { sigPad = ref }}
                                                            canvasProps={{ width: 250, height: 200 }}
                                                        />
                                                        <Button size='small' block
                                                            style={{ backgroundColor: '#25a18e', color: 'white' }}
                                                            loading={loadingTtd}
                                                            onClick={submitTtd}
                                                        >Simpan Ttd</Button>
                                                    </div>
                                                }
                                                <Input prefix={<UserOutlined />}
                                                    value={driver}
                                                    // onChange={e => {
                                                    //     updateDriver(e.target.value)
                                                    // }}
                                                    onChange={(e) => setDriver(e.target.value)}
                                                    placeholder='Nama driver..'
                                                    readOnly={panen.isLocked ? true : false}
                                                />
                                            </Col>
                                            <Col lg={{ span: 3 }}></Col>
                                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                                <Row>
                                                    <Col span={16}>
                                                        <span className='font-regular'>Nama & Ttd Penimbang</span>
                                                    </Col>
                                                    <Col span={4} offset={4}>
                                                        <Button
                                                            hidden={panen.isLocked ? true : false}
                                                            className='float-right' type='primary' danger size='small' onClick={() => clear2()}>Clear</Button>
                                                    </Col>
                                                </Row>
                                                {panen.isLocked ?
                                                    <Image className='center' preview={false} width={250} height={200} src={panen.signWeigher} /> :
                                                    <div style={{ backgroundColor: '#fafafa' }}>
                                                        <SignatureCanvas
                                                            ref={(ref) => { sigPad2 = ref }}
                                                            canvasProps={{ width: 250, height: 200 }}
                                                        />
                                                        <Button size='small' block
                                                            style={{ backgroundColor: '#25a18e', color: 'white' }}
                                                            loading={loadingTtd2}
                                                            onClick={submitTtd2}
                                                        >Simpan Ttd</Button>
                                                    </div>
                                                }
                                                <Input prefix={<UserOutlined />} value={weigher}
                                                    readOnly={panen.isLocked ? true : false}
                                                    placeholder='Nama penimbang..'
                                                    onChange={e => setWeigher(e.target.value)}
                                                />
                                            </Col>
                                            <Col lg={{ span: 2 }}></Col>
                                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                                <Row>
                                                    <Col span={16}>
                                                        <span className='font-regular'>Nama & Ttd Peternak</span>
                                                    </Col>
                                                    <Col span={4} offset={4}>
                                                        <Button
                                                            hidden={panen.isLocked ? true : false}
                                                            className='float-right' type='primary' danger size='small' onClick={() => clear3()}>Clear</Button>
                                                    </Col>
                                                </Row>
                                                {panen.isLocked ?
                                                    <Image className='center' preview={false} width={250} height={200} src={panen.signFarmer} /> :
                                                    <div style={{ backgroundColor: '#fafafa' }}>
                                                        <SignatureCanvas
                                                            ref={(ref) => { sigPad3 = ref }}
                                                            canvasProps={{ width: 250, height: 200 }}
                                                        />
                                                        <Button size='small' block
                                                            style={{ backgroundColor: '#25a18e', color: 'white' }}
                                                            loading={loadingTtd3}
                                                            onClick={submitTtd3}
                                                        >Simpan Ttd</Button>
                                                    </div>
                                                }
                                                <Input prefix={<UserOutlined />} value={farmer}
                                                    readOnly={panen.isLocked ? true : false}
                                                    placeholder='Nama peternak..'
                                                    onChange={e => setFarmer(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 8]} className='mt-20'>
                                            <Col lg={{ span: 12 }} xs={{ span: 24 }} hidden={panen.isLocked}>
                                                <Button block type='primary'
                                                    disabled={disabled}
                                                    onClick={() => lockingConfirm()}
                                                ><FontAwesomeIcon icon={faLock} className='mr-10' />Kunci Panen</Button>
                                            </Col>
                                            <Col lg={{ span: panen.isLocked ? 24 : 12 }} xs={{ span: 24 }}>
                                                <Button block
                                                    style={{
                                                        color: disabled ? 'rgba(0, 0, 0, 0.25)' : 'white',
                                                        backgroundColor: disabled ? '#f5f5f5' : 'coral',
                                                        borderRadius: '5px'
                                                    }}
                                                    disabled={disabled}
                                                    loading={loadingExport}
                                                    onClick={() => exportHarvest()}
                                                ><FontAwesomeIcon icon={faDownload} className='mr-10' />Export PDF Panen</Button>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Loading>
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout >
    )
}

const mapStateToProps = state => ({
    idHarvest: state.layout.idHarvest,
    indexHarvest: state.layout.indexHarvest,
})

const mapDispatchToProps = (dispatch => ({
    getIndexHarvest
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateRecordingHarvest)
export default page