import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { Modal, Form, Row, Col, Input, Select } from 'antd'
import { API } from '../../../../../common/api'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import { errMessage } from '../../../../../common/component/notification/notification'
import { createPakan } from './query-pakan'

function CreatePakanPage(props) {
    const { fetchData, setLoading, t } = props
    const [visible, setVisible] = useState(false)
    const { TextArea } = Input
    const { Option } = Select

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = async (values) => {
        await API.post(createPakan(values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.feed.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createFeed._id) {
                    errMessage('success', `${t('pages.master.feed.resAddSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.master.feed.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.master.feed.add')}
                okText={t('general.add')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{ keterangan: '' }}
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.feed.name')}</span>
                            <Form.Item
                                name="namaPakan" rules={[{ required: true, message: `${t('pages.master.feed.msgName')}` }]}
                            >
                                <Input
                                    placeholder="Bro AB..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.feed.type')}</span>
                            <Form.Item
                                name="jenisPakan" rules={[{ required: true, message: `${t('pages.master.feed.msgType')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.master.feed.phType')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    <Option key="Prestarter" value="prestarter">Prestarter</Option>
                                    <Option key="Starter" value="starter">Starter</Option>
                                    <Option key="Grower" value="grower">Grower</Option>
                                    <Option key="Finisher" value="finisher">Finisher</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.feed.company')}</span>
                            <Form.Item
                                name="perusahaan" rules={[{ required: true, message: `${t('pages.master.feed.msgCompany')}` }]}
                            >
                                <Input
                                    placeholder="PT Farming ..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <Form.Item
                                name="keterangan"
                            >
                                <span className="black font-semi-bold font-title-content" >{t('pages.master.feed.desc')}</span>
                                <TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={t('pages.master.feed.phDesc')}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }

    return (
        <div>
            <ButtonDashboard
                text={t('pages.master.feed.add')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => showModal()}
            />
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePakanPage)
export default page