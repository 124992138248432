import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, Input, Select } from 'antd'

function EditOvk(props) {
    const { Option } = Select
    const { visible, handleSubmit, data, handleCancel, stokOvk, suffix, profil, t } = props
    const [form] = Form.useForm()
    const [unit, setUnit] = useState(suffix)

    useEffect(() => {
        function setDetailData() {
            if (suffix === null) { setUnit('') } else { setUnit(suffix) }
        }
        setDetailData()
        form.setFieldsValue({
            ovk: data.ovkStock,
            jumlah: data.numberRecording,
        })
    }, [suffix, data, form])


    const onChangeOvk = (value, e) => {
        const unitOvk = stokOvk.filter(d => d.ovk.name === value)[0].ovk.ovkUnitRecording?.name ||
            stokOvk.filter(d => d.ovk.name === value)[0].ovk.ovkUnit?.name
        setUnit(unitOvk)
    }
    return (
        <div>
            <Modal
                visible={visible}
                title={t('pages.recording.ovk.edit')}
                okText={t('general.edit')}
                width={500}
                cancelText={t('general.cancel')}
                onCancel={() => handleCancel()}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        ovk: data.ovkStock,
                        jumlah: data.numberRecording,
                        price: data.priceOvkTransaction
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.recording.ovk.title')}</span>
                            <Form.Item
                                name="ovk" rules={[{ required: true, message: `${t('pages.recording.ovk.msgOvk')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.recording.ovk.phOvk')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular mt-5"
                                    onChange={(value) => onChangeOvk(value.label, value)}
                                >
                                    {
                                        stokOvk.map(data =>
                                            <Option
                                                value={data._id}
                                            >{data.ovk.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.ovk.qty')}</span>
                            <Form.Item
                                name="jumlah" rules={[{ required: true, message: `${t('pages.recording.ovk.msgQty')}` }]}
                            >
                                <Input
                                    type='number'
                                    placeholder="100..."
                                    className="input-form mt-5"
                                    suffix={unit}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">Harga</span>
                            <Form.Item
                                hidden={!profil.permission.nominalRecording}
                                name="price" rules={[{ required: true, message: `Mohon masukkan harga!` }]}
                            >
                                <Input type='number'
                                    placeholder="100..."
                                    prefix='Rp'
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default EditOvk