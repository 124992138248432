import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import NumberFormat from 'react-number-format'
import { Space, Button, Input, Tooltip, Modal, Layout, Row, Col, Table, Typography } from 'antd'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { updateRecordOverhead, getDetailRecordOverhead, deleteRecordOverhead } from '../rearing-record-aksi-page/query-rearing-record'
import { API } from '../../../../common/api'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import Loading from '../../../../common/component/loading/loading-container'
import EditOverhead from '../edit-detail-rearing-page/edit-overhead-page'
import { isMobileOnly } from 'react-device-detect'

function DetailOverheadPage(props) {
    const { fetchDataOverhead, overheading, overhead, idRearingRecord, setLoading, loading, houseActive, profil, t } = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [idData, setIdData] = useState(null)
    const [detail, setDetail] = useState("")
    const { Text } = Typography
    const { Content } = Layout

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`${t("general.search")} ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t("general.search")}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t("general.reset")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    useEffect(() => {
        function getDetailData() {
            if (idData) {
                API.get(getDetailRecordOverhead(idData))
                    .then(res => {
                        setDetail(res.data.data.overheading)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showEdit = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = (values) => {
        API.post(updateRecordOverhead(idData, values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.recording.bw.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.updateOverheading._id) {
                    validationMessage('success', `${t("pages.recording.overhead.resEditSuccess")}`)
                    fetchDataOverhead(idRearingRecord)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.overhead.resEditFailed")}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values)
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const deleteData = (_id) => {
        setLoading(true)
        API.delete(deleteRecordOverhead(_id))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', `${t("pages.recording.overhead.resDelFailed")}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteOverheading.deleted === true) {
                    validationMessage('success', `${t("pages.recording.overhead.resDelSuccess")}`)
                    fetchDataOverhead(idRearingRecord)
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t("pages.recording.overhead.resDelFailed")}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id) => {
        confirm({
            title: `${t("pages.recording.overhead.delConfirm")}`,
            onOk: () => { deleteData(_id) },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    const columns = [
        {
            title: `${t("pages.recording.overhead.title")}`,
            dataIndex: 'overhead',
            key: 'overhead',
            align: 'center',
            ...getColumnSearchProps('overhead', `${t("pages.recording.overhead.title")}`),
        },
        {
            title: `${t("pages.recording.overhead.qty")}`,
            dataIndex: 'jumlah',
            key: 'jumlah',
            align: 'center',
        },
        {
            title: `${t("pages.recording.overhead.price")}`,
            dataIndex: 'price',
            key: 'price',
            align: 'right',
        },
        {
            title: `Total`,
            dataIndex: 'total',
            key: 'total',
            align: 'right',
        },
        {
            title: `${t("general.action")}`,
            dataIndex: 'pilihan',
            align: 'center',
            key: 'pilihan',
            render: (value, row, index) => (
                <Space size="middle">
                    <Tooltip placement="top" title={t("general.edit")}>
                        <Button disabled={houseActive ? false : true}
                            hidden={profil.permission.editRecording ? false : true} style={{
                                color: 'white',
                                backgroundColor: houseActive ? '#FFA903' : 'whitesmoke',
                                borderRadius: '5px'
                            }} onClick={() => showEdit(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                    </Tooltip>
                    <Tooltip placement="top" title={t("general.del")}>
                        <Button disabled={houseActive ? false : true}
                            hidden={profil.permission.writeRecording ? false : true} style={{
                                color: 'white',
                                backgroundColor: houseActive ? '#FF0303' : 'whitesmoke',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.key)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </Tooltip>
                </Space>
            ),
        },
    ]
    const data = overheading.map(({ _id, quantity, overhead, price }, index) => ({
        key: _id,
        overhead: overhead.name,
        jumlah: <NumberFormat value={quantity} displayType={'text'} thousandSeparator={true} />,
        price: <NumberFormat value={price} displayType={'text'} thousandSeparator={true} />,
        nilai: price * quantity,
        total: <NumberFormat value={price * quantity} displayType={'text'} thousandSeparator={true} />,
    }))


    return (
        <Layout>
            <Content className="dashboard-section-content background-white">
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <div className="dashboard-section-table-dashboard">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    className="table-dashboard"
                                    bordered
                                    scroll={{ y: '70vh', x: isMobileOnly && 500 }}
                                    pagination={false}
                                    summary={pageData => {
                                        let totalNilai = 0
                                        pageData.forEach(({
                                            nilai,
                                        }) => {
                                            totalNilai += parseFloat(nilai)
                                        });
                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0} colSpan={3}>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell className="right">
                                                        <Text><NumberFormat value={totalNilai.toFixed(0)} displayType={'text'} thousandSeparator={true} /></Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell className="right">
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>
                                        )
                                    }}
                                />
                            </div>
                        </Loading>
                    </Col>
                </Row>
                <EditOverhead visible={visible} handleSubmit={handleSubmit} overhead={overhead} t={t} detail={detail} handleCancel={handleCancel} />
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord,
    dateRearing: state.rearingRecord.dateRearingRecord,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailOverheadPage)
export default page