import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Layout, Row, Col, Card, Button, Space, Divider, Image, Tag, Modal, Table, Popconfirm, Typography, Form, InputNumber } from 'antd'
import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { faArrowLeft, faDownload, faEdit, faLock, faTrash, } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import axios from 'axios'
import Cookies from 'universal-cookie'

import Loading from '../../../../common/component/loading/loading-container'
import { API } from '../../../../common/api'
import { errMessage } from '../../../../common/component/notification/notification'
import { getDetailRecordPanen, getPanenDetails, lockingPanen, deleteHarvestDetail, updatePanenDetail } from '../rearing-record-aksi-page/query-rearing-record'
import { isMobileOnly } from 'react-device-detect'
import { blobToDataURL } from '../../../../common/utils/Download'

function DetailRecordingHarvest(props) {
    const { idHarvest } = props
    const [loading, setLoading] = useState(false)
    const [panen, setPanen] = useState(null)
    const [panenDetail, setPanenDetail] = useState([])

    const [totalQty, setTotalQty] = useState(null)
    const [totalWeight, setTotalWeight] = useState(null)
    const [avg, setAvg] = useState(null)
    const { confirm } = Modal

    const [loadingExport, setLoadingExport] = useState(false)
    const cookies = new Cookies()

    const [form] = Form.useForm()
    const [editingKey, setEditingKey] = useState('')

    const isEditing = (record) => record._id === editingKey

    const edit = (record) => {
        form.setFieldsValue({
            qty: '',
            weight: '',
            ...record,
        })
        setEditingKey(record._id)
    }

    const cancel = () => {
        setEditingKey('')
    }

    const save = async (_id) => {
        const value = await form.validateFields()
        API.post(updatePanenDetail(_id, value))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', 'Gagal mengubah input timbangan', res.data.errors[0].message)
                } else
                    if (res.data.data.updateHarvestDetail._id) {
                        errMessage('success', 'Berhasil mengubah input timbangan')
                        setEditingKey('')
                        fetchDataPanenDetail(idHarvest)
                    }
            }).catch((error) => {
                errMessage('error', 'Gagal mengubah input timbangan', error.message)
            })
    }
    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {dataIndex === 'qty' ? <InputNumber size="small" precision={0} /> : <InputNumber size="small" />}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        )
    }

    const fetchDataPanen = async (idHarvest) => {
        setLoading(true)
        await API.get(getDetailRecordPanen(idHarvest))
            .then(res => {
                setPanen(res.data.data.mutation)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setLoading(false)
            })
    }

    const fetchDataPanenDetail = async (idHarvest) => {
        await API.get(getPanenDetails(idHarvest))
            .then(res => {
                setPanenDetail(res.data.data.harvestDetails.harvests)
                setTotalQty(res.data.data.harvestDetails.totalQty)
                setTotalWeight(res.data.data.harvestDetails.totalWeight)
                setAvg(res.data.data.harvestDetails.avg)
            }).catch((error) => {
                console.log(error)
                setPanenDetail([])
            })
    }

    useEffect(() => {
        fetchDataPanen(idHarvest)
        fetchDataPanenDetail(idHarvest)
    }, [idHarvest])

    const middleIndex = Math.ceil(panenDetail.length / 2)
    const panenTable1 = panenDetail.slice(0, middleIndex)
    const panenTable2 = panenDetail.slice(middleIndex, panenDetail.length)

    const columns = [
        {
            title: `No.`,
            dataIndex: 'index',
            key: 'index',
            align: 'center'
        },
        {
            title: `Jumlah`,
            dataIndex: 'qty',
            key: 'qty',
            editable: true,
            align: 'right',
            render: (value, row, index) => (
                <>{value} ekor</>
            )
        },
        {
            title: `Berat`,
            dataIndex: 'weight',
            key: 'weight',
            editable: true,
            align: 'right',
            render: (value, row, index) => (
                <>{value} kg</>
            )
        },
        panen?.isLocked ? { width: 1 } : {
            title: '',
            dataIndex: 'operation',
            align: 'center',
            render: (_, record) => {
                const editable = isEditing(record)
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record._id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Typography.Link
                            onClick={() => cancel()}
                        >
                            Cancel
                        </Typography.Link>

                    </span>
                ) : (
                    <>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)} className='mr-10'>
                            <FontAwesomeIcon icon={faEdit} />
                        </Typography.Link>
                        <Popconfirm title={`Yakin mau menghapus input nomor ${record.index}?`} onConfirm={() => deleteData(record._id)}>
                            <FontAwesomeIcon style={{ color: 'red', cursor: 'pointer' }} icon={faTrash} />
                        </Popconfirm>
                    </>
                )
            }
        },
    ]

    const columns2 = [
        {
            title: `No.`,
            dataIndex: 'index',
            key: 'index',
            align: 'center'
        },
        {
            title: `Jumlah`,
            dataIndex: 'qty',
            key: 'qty',
            align: 'right',
            editable: true,
            render: (value, row, index) => (
                <>{value} ekor</>
            )
        },
        {
            title: `Berat`,
            dataIndex: 'weight',
            key: 'weight',
            align: 'right',
            editable: true,
            render: (value, row, index) => (
                <>{value} kg</>
            )
        },
        panen?.isLocked ? { width: 1 } : {
            title: '',
            dataIndex: 'operation',
            align: 'center',
            render: (_, record) => {
                const editable = isEditing(record)
                return editable ? (
                    <span>
                        <Typography.Link
                            onClick={() => save(record._id)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Save
                        </Typography.Link>
                        <Typography.Link
                            onClick={() => cancel()}
                        >
                            Cancel
                        </Typography.Link>

                    </span>
                ) : (
                    <>
                        <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)} className='mr-10'>
                            <FontAwesomeIcon icon={faEdit} />
                        </Typography.Link>
                        <Popconfirm title={`Yakin mau menghapus input nomor ${record.index}?`} onConfirm={() => deleteData(record._id)}>
                            <FontAwesomeIcon style={{ color: 'red', cursor: 'pointer' }} icon={faTrash} />
                        </Popconfirm>
                    </>
                )
            }
        },
    ]
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'number',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            })
        }
    })

    const mergedColumns2 = columns2.map((col) => {
        if (!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'number',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            })
        }
    })

    //LOCK
    const lockingData = async () => {
        await API.post(lockingPanen(idHarvest, true))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `Gagal mengunci panen`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.lockingHarvest._id) {
                    errMessage('success', `Berhasil mengunci panen`)
                    fetchDataPanen(idHarvest)
                }
            }).catch((error) => {
                errMessage('error', `Gagal mengunci panen`, error.message)
                setLoading(false)
            })
    }

    const lockingConfirm = () => {
        confirm({
            title: `Apakah Anda yakin akan mengunci panen ini?`,
            onOk: () => { lockingData() },
            maskClosable: true,
            cancelText: 'Batal',
        })
    }

    //EXPORT PDF
    const exportHarvest = async () => {
        setLoadingExport(true)
        let token = cookies.get('token')
        await axios
            .get(`${process.env.REACT_APP_API_URL}/pdf-timbangan/${idHarvest}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                responseType: 'blob'
            })
            .then(res => {
                if (typeof (res.data === 'blob')) {
                    setLoadingExport(false)
                    const fileName = `Laporan Timbangan Ayam ${panen?.rearingRecord.rearing.name} ${panen?.rearingRecord.rearing.house.name}.pdf`
                    blobToDataURL(res.data, fileName)
                    errMessage('success', `Berhasil export Laporan Panen ke file PDF`)
                }
            }).catch((error) => {
                console.log('err', error)
                errMessage('error', `Gagal export Laporan Panen ke file PDF`)
                setLoadingExport(false)
            })
    }

    const deleteData = async (_id) => {
        await API.delete(deleteHarvestDetail(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', 'Gagal menghapus input timbangan', res.data.errors[0].message)
                }
                else if (res.data.data.deleteHarvestDetail.deleted === true) {
                    errMessage('success', `Berhasil menghapus input timbangan`)
                    fetchDataPanenDetail(idHarvest)
                }
            }).catch((error) => {
                errMessage('error', 'Gagal menghapus input timbangan', error.message)
            })
    }

    return (
        <Layout>
            <Layout.Content className="dashboard-container">
                <Row>
                    <Col lg={{ span: 16, offset: 4 }}>
                        <Space align='start'>
                            <Link to="/peternak/recording/detail">
                                <Button shape="circle" style={{ border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 5%)' }}
                                    icon={<FontAwesomeIcon icon={faArrowLeft} />} size='default' className='mr-10'
                                />
                            </Link>
                            <div className='font-title-dashboard font-semi-bold mb-10'>Detail Recording Panen</div>
                        </Space>

                        <Card>
                            <Loading loading={loading}>
                                {panen &&
                                    <>
                                        {panen.isLocked && <Tag className='float-right' color='#ff4a3d'><FontAwesomeIcon icon={faLock} className='mr-10' />Terkunci</Tag>}
                                        <div className='font-semi-bold font-title-content'>{panen.rearingRecord.rearing.house.name}</div>
                                        <CalendarOutlined /> <span className='font-light font-content ml-5'>
                                            {moment(new Date(parseInt(panen.rearingRecord.date))).format('DD MMMM YYYY')}
                                        </span>
                                        <Card style={{ backgroundColor: '#fafafa' }} className={`mt-20 mb-20 ${!isMobileOnly && `text-center`}`}>
                                            <Row gutter={[16, 0]}>
                                                <Col lg={{ span: 8, order: 1 }} xs={{ span: 12, order: 1 }}>
                                                    <div className='font-semi-bold'>Plat Nomor</div>
                                                </Col>
                                                <Col lg={{ span: 8, order: 2 }} xs={{ span: 12, order: 3 }}>
                                                    <div className='font-semi-bold'>Tonase DO</div>
                                                </Col>
                                                <Col lg={{ span: 8, order: 3 }} xs={{ span: 12, order: 5 }}>
                                                    <div className='font-semi-bold'>Customer</div>
                                                </Col>
                                                <Col lg={{ span: 8, order: 4 }} xs={{ span: 12, order: 2 }}>
                                                    <div>{panen.plat}</div>
                                                </Col>
                                                <Col lg={{ span: 8, order: 5 }} xs={{ span: 12, order: 4 }}>
                                                    <div>{panen.tonase} kg</div>
                                                </Col>
                                                <Col lg={{ span: 8, order: 6 }} xs={{ span: 12, order: 6 }}>
                                                    <div>{panen.customer.name}</div>
                                                </Col>
                                            </Row>
                                        </Card>

                                        <div className='font-semi-bold font-title-content'>Input Penimbangan Panen</div>
                                        <Divider className='mb-10 mt-0' />
                                        <Row gutter={[4, 4]} justify="start" align="middle">
                                            <Col span={2}>
                                                <ClockCircleOutlined style={{ fontSize: '40px' }} />
                                            </Col>
                                            <Col lg={6} sm={24} xs={24}>
                                                <div className='font-semi-bold'>Jam Mulai Penimbangan</div>
                                                {panen.startTime ? moment(new Date(parseInt(panen.startTime))).format('LLL') : 'Belum ada input'}
                                            </Col>
                                            <Col lg={6} sm={24} xs={24}>
                                                <div className='font-semi-bold'>Jam Selesai Penimbangan</div>
                                                {panen.endTime ? moment(new Date(parseInt(panen.endTime))).format('LLL') : 'Belum ada input'}
                                            </Col>
                                        </Row>
                                        <Form form={form} component={false}>
                                            <Row gutter={[16, 16]}>
                                                <Col lg={12} sm={24} xs={24}>
                                                    <Table
                                                        components={{
                                                            body: {
                                                                cell: EditableCell,
                                                            },
                                                        }}
                                                        columns={mergedColumns}
                                                        dataSource={panenTable1}
                                                        bordered
                                                        pagination={false}
                                                        className="table-dashboard mt-10"
                                                    />
                                                </Col>
                                                <Col lg={12} sm={24} xs={24}>
                                                    <Table
                                                        components={{
                                                            body: {
                                                                cell: EditableCell,
                                                            },
                                                        }}
                                                        columns={mergedColumns2}
                                                        dataSource={panenTable2}
                                                        bordered
                                                        pagination={false}
                                                        className="table-dashboard mt-10"
                                                    />
                                                </Col>
                                            </Row>
                                        </Form>
                                        <Divider />
                                        <Row gutter={[16, 16]} className={`${!isMobileOnly && `text-center`}`}>
                                            <Col lg={6} xs={24}>
                                                <div className="font-semi-bold font-sub">Jumlah Ayam Tukar</div>
                                                <NumberFormat value={panen.qtySwap ? panen.qtySwap : 0} suffix=' ekor' displayType={'text'} thousandSeparator={true} />
                                            </Col>
                                            <Col lg={6} xs={24}>
                                                <div className="font-semi-bold font-sub">Berat Ayam Tukar</div>
                                                <NumberFormat value={panen.weightSwap ? panen.weightSwap : 0} suffix=' kg' displayType={'text'} thousandSeparator={true} />
                                            </Col>
                                            <Col lg={6} xs={24}>
                                                <div className="font-semi-bold font-sub">Jumlah Ayam Penukar</div>
                                                <NumberFormat value={panen.qtySwapper ? panen.qtySwapper : 0} suffix=' ekor' displayType={'text'} thousandSeparator={true} />
                                            </Col>
                                            <Col lg={6} xs={24}>
                                                <div className="font-semi-bold font-sub">Berat Ayam Penukar</div>
                                                <NumberFormat value={panen.weightSwapper ? panen.weightSwapper : 0} suffix=' kg' displayType={'text'} thousandSeparator={true} />
                                            </Col>
                                        </Row>
                                        <Card style={{ backgroundColor: '#fafafa' }} className={`mt-20 mb-20 ${!isMobileOnly && `text-center`}`}>
                                            <Row gutter={[16, 0]}>
                                                <Col lg={{ span: 8, order: 1 }} xs={{ span: 16, order: 1 }}>
                                                    <div className='font-semi-bold'>Total Ekor Panen</div>
                                                </Col>
                                                <Col lg={{ span: 8, order: 2 }} xs={{ span: 16, order: 3 }}>
                                                    <div className='font-semi-bold'>Total kg Panen</div>
                                                </Col>
                                                <Col lg={{ span: 8, order: 3 }} xs={{ span: 16, order: 5 }}>
                                                    <div className='font-semi-bold'>Berat Rata² Panen</div>
                                                </Col>
                                                <Col lg={{ span: 8, order: 4 }} xs={{ span: 8, order: 2 }}>
                                                    <div>
                                                        <NumberFormat value={totalQty} suffix=' ekor' displayType={'text'} thousandSeparator={true} />
                                                    </div>
                                                </Col>
                                                <Col lg={{ span: 8, order: 5 }} xs={{ span: 8, order: 4 }}>
                                                    <div>
                                                        <NumberFormat value={totalWeight} suffix=' kg' displayType={'text'} thousandSeparator={true} />
                                                    </div>
                                                </Col>
                                                <Col lg={{ span: 8, order: 6 }} xs={{ span: 8, order: 6 }}>
                                                    <div>
                                                        <NumberFormat value={avg || '-'} suffix=' kg/ekor' displayType={'text'} thousandSeparator={true} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                        <div className='mt-20 font-semi-bold'>Foto</div>
                                        {panen.photo ? <Image src={panen.photo} width={250} /> : 'Tidak ada foto yang diupload'}
                                        <Row gutter={[24, 24]} className='mt-20'>
                                            <Col xs={{ span: 24 }} lg={{ span: 6 }} className='text-center'>
                                                <div className='font-regular'>Sopir</div>
                                                <Image className='center' preview={false} width={250} height={200} src={panen.signDriver} />
                                                <div>{panen.driver}</div>
                                            </Col>
                                            <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} className='text-center'>
                                                <div className='font-regular'>Penimbang</div>
                                                <Image className='center' preview={false} width={250} height={200} src={panen.signWeigher} />
                                                <div>{panen.weigher}</div>
                                            </Col>
                                            <Col xs={{ span: 24 }} lg={{ span: 6, offset: 2 }} className='text-center'>
                                                <div className='font-regular'>Peternak</div>
                                                <Image className='center' preview={false} width={250} height={200} src={panen.signFarmer} />
                                                <div>{panen.farmer}</div>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 8]} className='mt-20'>
                                            <Col lg={12} sm={24} xs={24} hidden={panen.isLocked}>
                                                <Button block type='primary'
                                                    onClick={() => lockingConfirm()}
                                                ><FontAwesomeIcon icon={faLock} className='mr-10' />Kunci Panen</Button>
                                            </Col>
                                            <Col lg={panen.isLocked ? 24 : 12} sm={24} xs={24}>
                                                <Button block style={{
                                                    color: 'white',
                                                    backgroundColor: 'coral',
                                                    borderRadius: '5px'
                                                }} loading={loadingExport}
                                                    onClick={() => exportHarvest()}
                                                ><FontAwesomeIcon icon={faDownload} className='mr-10' />Export PDF Panen</Button>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            </Loading>
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout >
    )
}

const mapStateToProps = state => ({
    idHarvest: state.layout.idHarvest,
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DetailRecordingHarvest)
export default page