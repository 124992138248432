import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Select, InputNumber, Button, Tooltip } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
import { navigate } from '../../../../common/store/action'
import { createRecordOverhead } from '../rearing-record-aksi-page/query-rearing-record'

function CreatePakanPage(props) {
    const { idRearingRecord, setLoading, fetchDataOverhead, overhead, houseActive, t } = props
    const { Option } = Select
    const [visible, setVisible] = useState(false)

    const [form] = Form.useForm()

    const setPrice = (value) => {
        const price = overhead.filter(d => d._id === value)[0].defaultPrice
        form.setFieldsValue({ price: price })
    }
    const postData = (values) => {
        API.post(createRecordOverhead(values, idRearingRecord))
            .then(res => {
                if (res.data.errors) {
                    validationMessage('error', res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createOverheading._id) {
                    validationMessage('success', `${t("pages.recording.overhead.resAddSuccess")}`)
                    fetchDataOverhead(idRearingRecord)
                    setVisible(false)
                }
            }).catch((error) => {
                validationMessage('error', `${t("pages.recording.overhead.resAddFailed")}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
    }
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        return (
            <Modal
                visible={visible}
                title={t("pages.recording.overhead.add")}
                okText={t('general.add')}
                width={500} centered
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{t("pages.recording.overhead.title")}</span>
                            <Form.Item
                                name="overhead" rules={[{ required: true, message: `${t("pages.recording.overhead.msgOverhead")}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t("pages.recording.overhead.phOverhead")}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    onChange={(value) => setPrice(value.value)}
                                    className="select-kategori black font-regular mt-5"
                                >
                                    {
                                        overhead.map(data =>
                                            <Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.recording.overhead.qty")}</span>
                            <Form.Item
                                name="jumlah" rules={[{ required: true, message: `${t("pages.recording.overhead.msgQty")}` }]}
                            >
                                <InputNumber
                                    name='jumlah'
                                    placeholder="1000..."
                                    className="input-number mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.recording.overhead.price")}</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `${t("pages.recording.overhead.msgPrice")}` }]}
                            >
                                <InputNumber
                                    name='price'
                                    placeholder="5000..."
                                    className="input-number mt-5"
                                    formatter={value => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/Rp\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    return (
        <>
            <Tooltip title={houseActive ? '' : t("error.inactiveHouse")}>
                <Button
                    disabled={houseActive ? false : true}
                    style={{
                        color: 'white',
                        backgroundColor: houseActive ? '#008ad4' : 'whitesmoke',
                        borderRadius: '5px',
                        float: "right",
                        border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
                    }} onClick={() => setVisible(true)}
                ><FontAwesomeIcon icon={faPlusSquare} className='mr-10' />
                    {t("pages.recording.overhead.add")}
                </Button>
            </Tooltip>

            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />
        </>
    )
}

const mapStateToProps = state => ({
    idRearingRecord: state.rearingRecord.idRearingRecord,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePakanPage)
export default page