import React from 'react'
import { Layout, Row, Col, Table, Typography } from 'antd'
import NumberFormat from 'react-number-format'
import Loading from '../../../../common/component/loading/loading-container'
import EditOvk from '../../../../app/dashboard-peternak/rearing-record-page/edit-detail-rearing-page/edit-ovk-page'
import { isMobileOnly } from 'react-device-detect'

function DetailOvkComponent(props) {
    const { Content } = Layout
    const { data, columns, loading, visible, handleSubmit, handleCancel, detail, suffix, profil, t } = props
    return (
        <Layout>
            <Content className="dashboard-section-content background-white">
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <div className="dashboard-section-table-dashboard">
                                <Table
                                    columns={columns}
                                    dataSource={data}
                                    bordered
                                    pagination={false}
                                    scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                                    className="table-dashboard"
                                    summary={profil.permission.nominalRecording ? pageData => {
                                        let totalNilai = 0
                                        pageData.forEach(({
                                            nilai,
                                        }) => {
                                            totalNilai += parseFloat(nilai)
                                        });
                                        return (
                                            <>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0} colSpan={3}>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell className="right">
                                                        <Typography.Text><NumberFormat value={totalNilai.toFixed(0)} displayType={'text'} thousandSeparator={true} prefix='Rp' /></Typography.Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell className="right">
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </>
                                        )
                                    } : null}
                                />
                            </div>
                        </Loading>
                    </Col>
                </Row>
                <EditOvk visible={visible} handleSubmit={handleSubmit} profil={profil}
                    detail={detail} handleCancel={handleCancel} suffix={suffix} t={t}/>
            </Content>
        </Layout>
    )
}

export default DetailOvkComponent