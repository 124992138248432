import { API } from '../../../../common/api'
import { validationMessage } from '../../../../common/component/notification/notification'
// import { queryPeriodeAktif } from '../../../../app/dashboard-peternak/rearing-page/rearing-aksi-page/query-rearing'
import * as actionType from './layout-action-type'

const setDataKandang = payload => ({
    type: actionType.SET_DATA_KANDANG,
    payload,
})
const setDataPeriode = payload => ({
    type: actionType.SET_DATA_PERIODE,
    payload,
})
const setTotalKandang = payload => ({
    type: actionType.SET_TOTAL_KANDANG,
    payload
})
export const setCreateRecording = payload => ({
    type: actionType.SET_CREATE_RECORDING,
    payload,
})
export const getKandang = (kandang) => (dispatch) => {
    dispatch({
        type: actionType.SET_KANDANG,
        payload: kandang,
    })
}
export const getIdPeriode = (id_periode) => (dispatch) => {
    dispatch({
        type: actionType.SET_ID_PERIODE,
        payload: id_periode,
    })
}
export const getIdDevice = (id_device) => (dispatch) => {
    dispatch({
        type: actionType.SET_ID_DEVICE,
        payload: id_device
    })
}
export const getDeviceType = (type) => (dispatch) => {
    dispatch({
        type: actionType.SET_DEVICE_TYPE,
        payload: type,
    })
}
export const getPeriode = (periode) => (dispatch) => {
    dispatch({
        type: actionType.SET_PERIODE,
        payload: periode,
    })
}
export const getIdKandang = (id_kandang) => (dispatch) => {
    dispatch({
        type: actionType.SET_ID_KANDANG,
        payload: id_kandang
    })
}

export const getDatePeriode = (date_periode) => (dispatch) => {
    dispatch({
        type: actionType.SET_DATE_PERIODE,
        payload: date_periode,
    })
}

export const setIsTour = () => (dispatch) => {
    dispatch({
        type: actionType.SET_IS_TOUR,
        payload: false
    })
}

export const getImageLoad = (imageLoad) => (dispatch) => {
    dispatch({
        type: actionType.SET_IMAGE_LOAD,
        payload: imageLoad
    })
}

export const getIdInvoice = (id) => (dispatch) => {
    dispatch({
        type: actionType.SET_ID_INVOICE,
        payload: id,
    })
}

export const getIdHarvest = (id) => (dispatch) => {
    dispatch({
        type: actionType.SET_ID_HARVEST,
        payload: id
    })
}

export const getIndexHarvest = (id) => (dispatch) => {
    dispatch({
        type: actionType.SET_INDEX_HARVEST,
        payload: id,
    })
}

export const setLoadingRearing = (payload) => (dispatch) => {
    dispatch({
        type: actionType.SET_LOADING_REARING,
        payload
    })
}

export const setLoadingFeedWh = (payload) => (dispatch) => {
    dispatch({
        type: actionType.SET_LOADING_FEED_WH,
        payload
    })
}

export const setLoadingOvkWh = (payload) => (dispatch) => {
    dispatch({
        type: actionType.SET_LOADING_OVK_WH,
        payload
    })
}

export const setPeriodeAktif = (payload) => (dispatch) => {
    dispatch({
        type: actionType.SET_PERIODE_AKTIF,
        payload,
    })
}

export const getListKandang = (params) => (dispatch) => {
    dispatch({ type: actionType.START_GET_DATA })
    API.get(params)
        .then(res => {
            if (res === undefined) { validationMessage('error', 'Connection error') }
            else if (res.data.data.housesByRole.totalCount !== 0) {
                dispatch(setDataKandang(res.data.data.housesByRole.houses))
                dispatch(getIdKandang(res.data.data.housesByRole.houses[0]._id))
                dispatch(getKandang(res.data.data.housesByRole.houses[0].name))
                dispatch(setTotalKandang(res.data.data.housesByRole.totalCount))
            } else if (res.data.data.housesByRole.totalCount === 0) {
                dispatch(setTotalKandang(res.data.data.housesByRole.totalCount))
                dispatch(setDataKandang([]))
                dispatch(setDataPeriode([]))
            } else {
                validationMessage('error', 'Koneksi Gagal')
                dispatch(setDataKandang([]))
                dispatch(setDataPeriode([]))
            }
            dispatch({
                type: actionType.FINISH_GET_DATA
            })
        }).catch(e => {
            validationMessage('error', e.message)
        })
}

export const getListPeriodeKandang = (params, location) => (dispatch) => {
    dispatch({ type: actionType.START_GET_DATA })
    API.get(params)
        .then(res => {
            if (res === undefined) { validationMessage('error', 'Connection error') }

            dispatch({
                type: actionType.FINISH_GET_DATA
            })
        }).catch(e => {
            validationMessage('error', e.message)
        })
}

export const getListPeriode = (params) => (dispatch) => {
    dispatch({ type: actionType.START_GET_DATA })
    API.get(params)
        .then(res => {
            if (res === undefined) {
                validationMessage('error', 'Connection error')
            }
            else if (res.data.data.rearings.rearings) {
                dispatch(setDataPeriode(res.data.data.rearings.rearings))
            } else {
                validationMessage('error', 'Koneksi Gagal')
            }
            dispatch({
                type: actionType.FINISH_GET_DATA
            })
        }).catch(e => {
            validationMessage('error', e.message)
        })
}

export const getAllPeriode = (params) => (dispatch) => {
    dispatch({ type: actionType.START_GET_DATA })
    API.get(params)
        .then(res => {
            if (res === undefined) {
                validationMessage('error', 'Connection error')
                dispatch({
                    type: actionType.SET_IS_TOUR,
                    payload: true
                })
            } else if (res.data.data.rearingAll.totalCount === 0) {
                dispatch({
                    type: actionType.SET_IS_TOUR,
                    payload: true
                })
            }
            else if (res.data.data.rearingAll.rearings) {
                dispatch({
                    type: actionType.SET_ALL_PERIODE,
                    payload: res.data.data.rearingAll.rearings
                })
                dispatch({
                    type: actionType.SET_IS_TOUR,
                    payload: false
                })
            } else {
                validationMessage('error', 'Koneksi Gagal')
                dispatch({
                    type: actionType.SET_IS_TOUR,
                    payload: true
                })
            }
            dispatch({
                type: actionType.FINISH_GET_DATA
            })
        }).catch(e => {
            validationMessage('error', e.message)
        })
}

export const getPeriodeAktif = (params) => (dispatch) => {
    dispatch({ type: actionType.START_GET_DATA })
    dispatch({ type: actionType.SET_LOADING_REARING, payload: true })
    API.get(params)
        .then(res => {
            if (res === undefined) {
                validationMessage('error', 'Connection error')
            }
            else if (res.data.data) {
                const payload = res.data.data.rearingLatest?.rearings
                dispatch({
                    type: actionType.SET_PERIODE_AKTIF,
                    payload,
                })
                dispatch({ type: actionType.SUCCESS_SET_PERIODE_AKTIF })
            }
            dispatch({ type: actionType.SET_LOADING_REARING, payload: false })
            dispatch({ type: actionType.FINISH_GET_DATA })
        }).catch(e => {
            validationMessage('error', e.message)
        })
}