export const materialStock = {
    title: "Material Stock",
    name: "Procurement Name",
    supplier: "Supplier",
    balance: "Rest Material",
    tambahBaru: "Add New Material Stock",
    addStock: "Add Stock",
    add: "Add Material Stock",
    materialWh: "Material Warehouse",
    date: "Procurement Date",
    addDate: "Addition Date",
    usageDate: "Usage Date",
    qtyStock: "Amount of Stock",
    price: "Material Price",
    phWh: "Select material warehouse",
    phMaterial: "Select material",
    phName: "Pengadaan Stater",
    phDate: "Select procurement date",
    phAddDate: "Select addition date",
    phUsageDate: "Select usage date",
    msgWh: "Please select material warehouse!",
    msgMaterial: "Please select material!",
    msgName: "Please input procurement name!",
    msgDate: "Please select procurement date!",
    msgAddDate: "Please select addition date!",
    msgUsageDate: "Please select usage date!",
    msgPrice: "Please input material price!",
    msgQtyStock: "Please input amount of stock!",
    edit: "Edit Material Stock",
    delConfirm: "Are you sure you want to clear the stock material ?",
    detail: "Material Stock Transaction Details ",
    addition: "Addition",
    trxDate: "Transaction date",
    qty: "Amount",
    delTrxConfirm: "Are you sure you want to delete material transaction ?",
    titleEditAdd: "Edit Addition Transaction of Material Stock",
    dateStock: "Stock Date",
    usage: "Usage",
    titleEditUsage: "Edit Usage Transaction of Material Stock",
    resAddSuccess: "Successfully added material stock",
    resEditSuccess: "Successfully changed material stock",
    resEditTrxSuccess: "Successfully changed material transaction",
    resDelSuccess: "Successfully deleted material stock",
    resDelTrxSuccess: "Successfully deleted material transaction",
    resAddFailed: "Failed to add material stock",
    resEditFailed: "Failed to change material stock",
    resEditTrxFailed: "Failed to change material transaction",
    resDelFailed: "Failed to delete material stock",
    resDelTrxFailed: "Failed to delete material transaction",
}