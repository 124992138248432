import React from 'react'
import { Button, Form, Layout, Row, Col, Card, Skeleton, Tabs, DatePicker, Divider, Image, Empty, Tag, Select, Typography, Alert, Input } from 'antd'
import moment from 'moment'
import ButtonDashboard from '../../../common/component/button/button-dashboard'

import '../../../assets/scss/dashboard-peternak/dashboard-peternak.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FileExcelOutlined, HistoryOutlined } from '@ant-design/icons'
import { faPlus, faChartArea, faChartLine, faCheckCircle, faDollarSign, faExclamationTriangle, faInfoCircle, faTemperatureLow, faThermometerEmpty, faCalendarAlt, faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import deviceImage from '../../../assets/images/device.png'
import anemoImage from '../../../assets/images/anemo.png'
import humidityIcon from '../../../assets/svg/carbon_humidity-alt.svg'
import nh3Icon from '../../../assets/svg/NH3.svg'
import co2Icon from '../../../assets/svg/CO2.svg'
import vocIcon from '../../../assets/svg/voc.svg'
import kphIcon from '../../../assets/svg/kph.svg'
import msIcon from '../../../assets/svg/ms.svg'
import SelectDeviceImage from '../../../assets/svg/select-device.svg'
import PriceImage from '../../../assets/svg/pricing-analysis.svg'
import LivebirdImage from '../../../assets/svg/livebird-icon.svg'
import CarcassImage from '../../../assets/svg/carcass-icon.svg'
import PinImage from '../../../assets/svg/pin.svg'
import PinWhiteImage from '../../../assets/svg/pin-white.svg'
import recordingImage from '../../../assets/svg/chicken-1.svg'
import harvestImage from '../../../assets/svg/chicken-3.svg'
import Loading from '../../../common/component/loading/loading-container'

/**import chart */
import SuhuChart from '../../../common/component/chart/chart-suhu'
import VocChart from '../../../common/component/chart/chart-voc'
import AnemoChart from '../../../common/component/chart/chart-anemo'
import PerformanceChart from '../../../common/component/chart/chart-performance'
import FeedUsageChart from '../../../common/component/chart/chart-feed-usage'
import ThiChart from '../../../common/component/chart/chart-thi'
import GaugeSuhu from '../../../common/component/chart/gauge-suhu'
import PriceChart from '../../../common/component/chart/chart-price'

/**import table */
import TablePerformance from '../../../app/dashboard-peternak/dashboard-page/dashboard-content-page/table-performance-page'
import TableFeedUsage from '../../../app/dashboard-peternak/dashboard-page/dashboard-content-page/table-feed-usage'
import TableEnv from '../../../app/dashboard-peternak/dashboard-page/dashboard-content-page/table-env'
import TableRetention from '../../../app/dashboard-peternak/dashboard-page/dashboard-content-page/table-retention'
import TableInvoices from '../../../app/dashboard-peternak/dashboard-page/dashboard-content-page/table-pending-invoices'
import TablePrice from '../../../app/dashboard-peternak/dashboard-page/dashboard-content-page/table-price'
import { isMobileOnly } from 'react-device-detect'
/**end import table */

function DashboardComponent(props) {
    const { report, active, setRange, suhu, feedUsage, chartData, ages, thi, range, retention, role,
        typeUser, handleSubmit, visible, showEdit, form, form2, setVisible, companyName, imageLoad, setImageLoad,
        performaChart, suhuReverse, env, loading, load, handleExport, idDevice, devices, onDevice, isVoucher,
        onAddCity, setSearch, isSearch, setRangePrice, rangePrice, arrCity, savedCity, nearby, fetchInvoices,
        onCity, onSelectCity, onRemoveCity, cityString, priceRange, NumberFormat, deviceType, invoices, setLoading,
        setLastDatePrice, lastPrice, lastDatePrice, setIsDate, isDate, users, navigate, CONSTANTS,
        giveVoucher, onClaim, form3, profil,
        t } = props
    const { Content } = Layout
    const { Link } = Typography
    const { Option } = Select

    const chartParams = ['fcr', 'dep', 'bw', 'fi']
    return (
        <Layout>
            <Content className="dashboard-container">
                <div hidden={!isVoucher}>
                    <Alert className='mb-20'
                        message={<span className='font-semi-bold'>Klaim Voucher Premium</span>}
                        description="Anda punya kesempatan mendapatkan voucher akun premium BroilerX."
                        type="info"
                        closable
                        showIcon
                        icon={<FontAwesomeIcon icon={faTicketAlt} />}
                        action={
                            <Button size="large" type='primary'
                                onClick={() => onClaim()}
                            >
                                Cek Voucher
                            </Button>
                        }
                    />
                </div>
                <div hidden={!profil.permission?.staffMode}>
                    <div className='font-bold text-center font-card-title mt-30 mb-20'>Pilih Jenis Recording</div>
                    <Row gutter={[16, 16]}>
                        <Col lg={{ offset: 6, span: 6 }} xs={{ span: 12 }}>
                            <Card hoverable className='center'
                                onClick={() => navigate(CONSTANTS.REARING_RECORD_DAILY_KEY)}>
                                <Image src={recordingImage} preview={false} width='70%' />
                                <div className='font-light font-title-content'>Recording Harian</div>
                            </Card>
                        </Col>
                        <Col lg={{ span: 6 }} xs={{ span: 12 }}>
                            <Card hoverable className='center'
                                onClick={() => navigate(CONSTANTS.HARVEST_RECORDING)}>
                                <Image src={harvestImage} preview={false} width='70%' />
                                <div className='font-light font-title-content'>Recording Panen</div>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Tabs hidden={profil.permission?.staffMode} defaultActiveKey="1" tabBarStyle={{ boxShadow: '0px 0.5px 3px #BE375F' }}>
                    <Tabs.TabPane
                        tab={
                            <span>
                                <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 5 }} />
                                <span>{t('pages.dashboard.performance.title')}</span>
                            </span>
                        }
                        key="1"
                    >
                        {role === 'superadmin' &&
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <Card title='Voucher' bordered={false} className='mb-20'>
                                        <Form form={form3}>
                                            <Row
                                                justify='space-around'
                                                align='middle'
                                                gutter={[16, 16]}>
                                                <Col span={12}>
                                                    <Form.Item name='users'
                                                        rules={[{ required: true }]}>
                                                        <Select
                                                            mode="multiple"
                                                            allowClear
                                                            placeholder="Please select email"
                                                            showSearch
                                                            optionFilterProp="children"
                                                        >
                                                            {users && users?.sort((a, b) => a.email.localeCompare(b.email))?.map(d =>
                                                                <Option value={d._id.toString()} key={d.email}>{d.email}</Option>
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item name='duration'
                                                        rules={[{ required: true }]}>
                                                        <Input type='number'
                                                            className='input-form'
                                                            style={{ width: '100%' }}
                                                            suffix="month" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item name='house'
                                                        rules={[{ required: true }]}>
                                                        <Input type='number'
                                                            className='input-form'
                                                            style={{ width: '100%' }}
                                                            suffix="houses" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item>
                                                        <Button type='primary' htmlType='submit' onClick={() => giveVoucher()}>Give them voucher</Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card title='Pending Invoices' bordered={false}>
                                        <TableInvoices data={invoices} fetchInvoices={fetchInvoices} setLoading={setLoading} loading={loading} t={t} />
                                    </Card>
                                </Col>
                                <Col span={24}>
                                    <Card title='Retention' bordered={false}>
                                        <TableRetention data={retention} loading={loading} t={t} form={form} setVisible={setVisible}
                                            companyName={companyName} typeUser={typeUser} handleSubmit={handleSubmit} visible={visible} showEdit={showEdit} />
                                    </Card>
                                </Col>
                            </Row>
                        }
                        {role !== 'superadmin' &&
                            <>
                                <Row className="card-report-section" gutter={[16, 16]}>
                                    <Col sm={{ span: 12 }} xs={24} lg={{ span: 6 }}>
                                        <Card title={t('pages.dashboard.report.livebird')} bordered={false} className="card-bird-live">
                                            {active === true ? <Skeleton active={active} paragraph={{ rows: 0 }} /> : <NumberFormat value={report.liveBird} displayType={'text'} thousandSeparator={true} suffix={t('pages.dashboard.report.chicken')} />}
                                        </Card>
                                    </Col>
                                    <Col sm={{ span: 12 }} xs={24} lg={{ span: 6 }}>
                                        <Card title={t('pages.dashboard.report.culling')} bordered={false} className="card-bird-culling">
                                            {active === true ? <Skeleton active={active} paragraph={{ rows: 0 }} /> : <NumberFormat value={report.culling} displayType={'text'} thousandSeparator={true} suffix={t('pages.dashboard.report.chicken')} />}
                                        </Card>
                                    </Col>
                                    <Col sm={{ span: 12 }} xs={24} lg={{ span: 6 }}>
                                        <Card title={t('pages.dashboard.report.dead')} bordered={false} className="card-bird-dead">
                                            {active === true ? <Skeleton active={active} paragraph={{ rows: 0 }} /> : <NumberFormat value={report.mati} displayType={'text'} thousandSeparator={true} suffix={t('pages.dashboard.report.chicken')} />}
                                        </Card>
                                    </Col>
                                    <Col sm={{ span: 12 }} xs={24} lg={{ span: 6 }}>
                                        <Card title={t('pages.dashboard.report.feed')} bordered={false} className="card-bird-feed">
                                            {active === true ? <Skeleton active={active} paragraph={{ rows: 0 }} /> : <NumberFormat value={report.totalPakan} displayType={'text'} thousandSeparator={true} suffix={' kilogram'} />}
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className="chart-die-section" gutter={[16, 16]}>
                                    <Col span={24}>
                                        <Card title={t('pages.dashboard.performance.chartTitle')} className='mt-20' bordered={false}>
                                            {loading ?
                                                <>
                                                    <Skeleton.Image active={loading} className='mb-5' />
                                                    <Skeleton.Button active={loading} size='large' block />
                                                </> :
                                                <>
                                                    {isMobileOnly ? chartParams.map(data =>
                                                        <div className='mb-30'>
                                                            <PerformanceChart
                                                                data={performaChart}
                                                                chartId={`${data}-chart`}
                                                                t={t}
                                                                params={data}
                                                            />
                                                        </div>
                                                    ) :
                                                        <PerformanceChart
                                                            data={performaChart}
                                                            chartId="berat-chart" t={t}
                                                            params={'all'}
                                                        />
                                                    }
                                                    <TablePerformance t={t} />
                                                </>}
                                        </Card>
                                    </Col>
                                </Row>
                            </>
                        }
                    </Tabs.TabPane>

                    {/* FEED USAGE */}
                    <Tabs.TabPane
                        tab={
                            <span>
                                <FontAwesomeIcon icon={faChartArea} style={{ marginRight: 5 }} />
                                <span>{t('pages.dashboard.feed.title')}</span>
                            </span>
                        }
                        key="2"
                    >
                        <Row className="chart-die-section" gutter={[16, 16]}>
                            <Col span={24}>
                                <Card title={t('pages.dashboard.feed.chart')} bordered={false}>
                                    <FeedUsageChart
                                        chartData={chartData} t={t} ages={ages}
                                    />
                                    <TableFeedUsage t={t} feedUsage={feedUsage} loading={loading} />
                                </Card>
                            </Col>
                        </Row>
                    </Tabs.TabPane>

                    {/* CLIMATE */}
                    <Tabs.TabPane
                        tab={
                            <span>
                                <FontAwesomeIcon icon={faTemperatureLow} style={{ marginRight: 5 }} />
                                <span>{t('pages.dashboard.iot.title')}</span>
                            </span>
                        }
                        key="3"
                    >
                        <Row gutter={[8, 8]}>
                            {devices.length < 1 &&
                                <Col span={12} offset={6}>
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description={
                                            <span>{t('pages.dashboard.iot.nodevice')}</span>
                                        }
                                    >
                                        <ButtonDashboard
                                            text={t("sidebar.adIot")}
                                            height={20}
                                            backgroundColor="#008ad4"
                                            borderRadius="5px"
                                            className="font-semi-medium"
                                            textColor="white"
                                            href='https://wa.me/6285155050769' target='blank'
                                        />
                                    </Empty>
                                </Col>
                            }

                            {devices && devices.map((d) =>

                                <Col span={8}>
                                    <Card hoverable={idDevice === d.deviceId ? false : true} className={idDevice === d.deviceId ? 'selected' : ''}
                                        onClick={() => onDevice(d.deviceId, d.deviceType)}>
                                        <Row gutter={[10, 8]} justify="space-around" align="middle">
                                            <Col span={8}>
                                                <Image src={d.deviceType === 'Anemometer' ? anemoImage : deviceImage} preview={false} />
                                                <div className='font-light text-center'>{d.deviceType}</div>
                                            </Col>
                                            <Col span={16}>
                                                <Row justify="space-around" align="middle">
                                                    <Col span={18}>
                                                        <h3 className='font-semi-bold mb-0'>{d.house.name} - {d.position}</h3>
                                                        <h5 className='font-regular'>SN: {d.serialNumber}</h5>
                                                        <HistoryOutlined /> <span className='font-light font-content mr-5'>
                                                            {d.latestTelemetry.ts ? moment(new Date(d.latestTelemetry.ts)).calendar() : ''}</span>
                                                    </Col>
                                                    <Col span={6}>
                                                        <FontAwesomeIcon
                                                            icon={d.latestTelemetry.status === 'danger' || d.latestTelemetry.status === 'emergency' ? faExclamationTriangle : d.latestTelemetry.status === 'alert' ?
                                                                faInfoCircle : faCheckCircle}
                                                            color={d.latestTelemetry.status === 'emergency' ? "#fcad35" : d.latestTelemetry.status === 'alert' ? '#f5e942' : d.latestTelemetry.status === 'danger' ? '#fcad35' : '#02db93'}
                                                            className='float-right' size="3x" />
                                                    </Col>
                                                </Row>
                                                <Divider style={{
                                                    marginTop: 15, marginBottom: 15, borderWidth: 2,
                                                    borderColor: d.latestTelemetry.status === 'emergency' ? "#FF0000" : d.latestTelemetry.status === 'alert' ? '#f5e942' : d.latestTelemetry.status === 'danger' ? '#fcad35' : '#02db93',
                                                }} />
                                                {d.deviceType === 'Anemometer' ?
                                                    <Row gutter={[2, 6]} className='font-regular'>
                                                        <Col span={3}><Image src={kphIcon} preview={false} /></Col><Col span={21}>{d.latestTelemetry.kph} km/h</Col>
                                                        <Col span={3}><Image src={msIcon} preview={false} /></Col><Col span={21}>{d.latestTelemetry.ms} m/s</Col>
                                                        <Col span={24}>Status: {d.latestTelemetry.status}</Col>
                                                    </Row>
                                                    :

                                                    <Row gutter={[2, 6]} className='font-regular'>
                                                        <Col span={3}><FontAwesomeIcon icon={faThermometerEmpty} size='lg' /> </Col><Col span={9}>{d.latestTelemetry.temperature} °C</Col>
                                                        <Col span={3}><Image src={humidityIcon} preview={false} /></Col><Col span={9}>{d.latestTelemetry.humidity} %</Col>
                                                        {d.deviceType === 'Ambient 1.3' ?
                                                            <>
                                                                <Col span={3} ><Image src={nh3Icon} preview={false} /></Col><Col span={9} >{d.latestTelemetry.amonia} ppm</Col>
                                                            </>
                                                            :
                                                            <>
                                                                <Col span={3}><Image src={vocIcon} preview={false} /> </Col>
                                                                <Col span={9}><NumberFormat value={d.latestTelemetry.voc} displayType={'text'} thousandSeparator={true} /></Col>
                                                            </>
                                                        }

                                                        <Col span={3}><Image src={co2Icon} preview={false} /></Col><Col span={9}>{d.latestTelemetry.co2} ppm</Col>
                                                        <Col span={24}>Status: {d.latestTelemetry.status}</Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            )}

                        </Row>
                        <Divider />
                        <div className='center-object text-center' hidden={idDevice || devices.length < 1 ? true : false}>
                            <Image src={SelectDeviceImage} preview={false} />
                            <div className='font-semi-medium'>{t('pages.dashboard.iot.notelemetry')}</div>
                        </div>
                        <Row gutter={[8, 8]} hidden={idDevice && devices.length > 0 ? false : true}>
                            <Col span={12}>
                                <Row gutter={[4, 4]}>
                                    <Col span={24}>
                                        <Card className='gauge'>
                                            <div style={{ margin: 10 }}>
                                                <HistoryOutlined /> <span className='font-light' style={{ marginLeft: 4 }}> {t('pages.dashboard.iot.updatedAt')}  {env.timestamp}</span>
                                            </div>
                                        </Card>
                                    </Col>

                                    {/* ambient */}
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? true : false}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#ff7112' }}>{t('pages.dashboard.iot.temperature')}</h3>
                                            <GaugeSuhu t={t} chartId="suhu-gauge" data={env?.temperature} fixed={2}
                                                chartMin={0} chartMax={100} suffix="°C" fontSize="6em" fill1='#ff7112' fill2='#f5a876' />
                                        </Card>
                                    </Col>
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? true : false}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#10b4e6' }}>{t('pages.dashboard.iot.humidity')} </h3>
                                            <GaugeSuhu t={t} chartId="humidity-gauge" data={env?.humidity} fixed={2}
                                                chartMin={0} chartMax={100} suffix="%" fontSize="6em" fill2='#7fd9f5' fill1='#10b4e6' />
                                        </Card>
                                    </Col>
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? true : false}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#9e7402' }}>{deviceType === 'Ambient 1.4' ? 'VOC' : 'NH3'}</h3>
                                            <GaugeSuhu t={t} chartId="amonia-gauge" data={deviceType === 'Ambient 1.4' ? env.voc : env.amonia}
                                                chartMin={0} chartMax={deviceType === 'Ambient 1.4' ? 40000 : 10} suffix={deviceType === 'Ambient 1.4' ? '' : "PPM"}
                                                fixed={0} fontSize="5em" fill2='#cfb570' fill1='#9e7402' />
                                        </Card>
                                    </Col>
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? true : false}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#08cf9f' }}>CO₂</h3>

                                            <GaugeSuhu t={t} chartId="co2-gauge" data={env?.co2} fixed={0}
                                                chartMin={0} chartMax={2000} suffix="PPM" fontSize="5em" fill2='#aafae7' fill1='#08cf9f' />
                                        </Card>
                                    </Col>

                                    {/* anemo */}
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? false : true}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#014f86' }}>Kilometer per hour</h3>
                                            <GaugeSuhu t={t} chartId="kph-gauge" data={env?.kph} fixed={2}
                                                chartMin={0} chartMax={30} suffix="km/h" fontSize="6em" fill1='#014f86' fill2='#2a6f97' />
                                        </Card>
                                    </Col>
                                    <Col span={12} hidden={deviceType === 'Anemometer' ? false : true}>
                                        <Card className='gauge'>
                                            <h3 className='font-semi-bold' style={{ color: '#2c7da0' }}>Meter per second</h3>
                                            <GaugeSuhu t={t} chartId="ms-gauge" data={env?.ms} fixed={2}
                                                chartMin={0} chartMax={5} suffix="m/s" fontSize="6em" fill2='#61a5c2' fill1='#2c7da0' />
                                        </Card>
                                    </Col>
                                </Row>

                            </Col>
                            <Col span={12}>
                                <Card bordered={false} className='card-table-sm'
                                    extra={
                                        <div style={{ width: "100%" }}>
                                            <Row gutter={4}>
                                                <Col span={16}>
                                                    <DatePicker.RangePicker showTime style={{ width: '100%' }}
                                                        onChange={e => setRange(e)} value={range} defaultValue={range} allowClear={false}

                                                    />
                                                </Col>
                                                <Col span={8}>
                                                    <Button style={{
                                                        float: 'right', backgroundColor: '#00a266', color: 'white',
                                                        borderRadius: 5, boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
                                                    }}
                                                        loading={load} onClick={() => handleExport()}>
                                                        <FileExcelOutlined />Export Excel</Button>
                                                </Col>
                                            </Row>
                                        </div>

                                    }
                                >
                                    <TableEnv envDevice={suhu} t={t} loading={loading} deviceType={deviceType} />
                                </Card>
                            </Col>
                            <Col span={24} className="chart-temparature-section">
                                <Card title={t('pages.dashboard.iot.chartTitle')} bordered={false} className='card-thi'
                                    extra={
                                        <div style={{ width: "100%" }}>
                                            <Row gutter={16}>
                                                <Col span={14} offset={10}>
                                                    <DatePicker.RangePicker showTime style={{ width: '100%' }}
                                                        onChange={e => setRange(e)} value={range} defaultValue={range} allowClear={false}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                >
                                    {deviceType === 'Ambient 1.4' ?
                                        <VocChart data={suhuReverse} t={t} chartId="voc-chart" />
                                        : deviceType === 'Ambient 1.3' ?
                                            <SuhuChart data={suhuReverse} t={t} chartId="suhu-chart" />
                                            : <AnemoChart data={suhuReverse} t={t} chartId="anemo-chart" />
                                    }
                                </Card>
                            </Col>
                            <Col span={24} className="chart-temparature-section" hidden={deviceType === 'Anemometer' ? true : false}>
                                <Card title={t('pages.dashboard.iot.thiChartTitle')} bordered={false} className='card-thi'
                                    extra={
                                        <div style={{ width: "100%" }}>
                                            <Row gutter={16}>
                                                <Col span={14} offset={10}>
                                                    <DatePicker.RangePicker showTime style={{ width: '100%' }}
                                                        onChange={e => setRange(e)} value={range} defaultValue={range} allowClear={false}

                                                    />
                                                </Col>
                                            </Row>
                                        </div>

                                    }
                                >
                                    <ThiChart t={t} data={thi} chartId="thi-chart" />
                                </Card>
                            </Col>
                        </Row>
                    </Tabs.TabPane>

                    {/* PRICE */}
                    <Tabs.TabPane
                        tab={
                            <span>
                                <FontAwesomeIcon icon={faDollarSign} style={{ marginRight: 5 }} />
                                <span>{t('pages.dashboard.price.title')}</span>
                            </span>
                        }
                        key="4"
                    >
                        <Row gutter={[16, 16]}>
                            <Col lg={15} sm={24} xs={24}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <div className='font-bold font-title-login mt-40'>{t('pages.dashboard.price.titlePage')}</div>
                                    </Col>
                                    <Col span={24}>
                                        <Form form={form2} layout={!isMobileOnly && 'inline'}>
                                            {savedCity && savedCity.map(data =>
                                                <Tag onClick={() => onCity(data)} onClose={(e) => onRemoveCity(e, data)}
                                                    icon={<Image src={cityString === data ? PinWhiteImage : PinImage} preview={false} />}
                                                    closable={cityString === data ? false : true} className={cityString === data ? 'price-selected' : 'price'}>
                                                    {data}
                                                </Tag>
                                            )}
                                            <Form.Item name='city' className='m-0' hidden={!isSearch}>
                                                <Select
                                                    style={{ width: isMobileOnly ? '100%' : '20rem', borderRadius: '10px', marginRight: 5 }}
                                                    labelInValue
                                                    onChange={(e) => onSelectCity(e)}
                                                    placeholder='Search city' showArrow={false} showSearch
                                                    optionFilterProp="children"
                                                >
                                                    {arrCity && arrCity?.map(data =>
                                                        <Select.Option value={data.city_id}>{data.type} {data.name}</Select.Option>
                                                    )}
                                                </Select>
                                            </Form.Item>
                                            <Button
                                                onClick={() => isSearch && cityString !== null ? onAddCity(cityString) : setSearch(true)}
                                                icon={<FontAwesomeIcon icon={faPlus} />} className='price-plus' />
                                            <Form.Item name='date' hidden={isDate}
                                                style={{ width: '15%', marginLeft: 15, marginTop: 0, marginBottom: 0, marginRight: 0 }}>
                                                <DatePicker
                                                    onChange={e => setLastDatePrice(e)}
                                                    value={lastDatePrice}
                                                    defaultValue={lastDatePrice}
                                                    allowClear={false}
                                                />
                                            </Form.Item>
                                            <Button
                                                onClick={() => setIsDate(!isDate)} hidden
                                                icon={<FontAwesomeIcon icon={faCalendarAlt} />} className='price-plus' style={{ marginLeft: 5 }} />
                                        </Form>
                                    </Col>
                                    <Col lg={12} sm={12} xs={24}>
                                        <Card bordered={false} className="card-livebird">
                                            <Row gutter={[4, 4]} justify="space-around" align="middle">
                                                <Col span={6}>
                                                    {!imageLoad && <Skeleton.Avatar active={!imageLoad} size='large' shape='square' />}
                                                    <Image src={LivebirdImage} preview={false} onLoad={() => setImageLoad(true)} />
                                                </Col>
                                                <Col span={10}>
                                                    <div className='font-semi-bold'>{t('pages.dashboard.price.livebird')}</div>
                                                    <div>{moment().add(-3, 'days').format('DD MMMM YYYY')}</div>
                                                </Col>
                                                <Col span={8}>
                                                    {loading ?
                                                        <Skeleton.Button active={loading} size='large' shape='default' block={true} />
                                                        : <NumberFormat className='font-semi-bold font-card-title float-right' value={lastPrice?.pinsar} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col lg={12} sm={12} xs={24}>
                                        <Card bordered={false} className="card-carcass">
                                            <Row gutter={[4, 4]} justify="space-around" align="middle">
                                                <Col span={6}>
                                                    {!imageLoad && <Skeleton.Avatar active={!imageLoad} size='large' shape='square' />}
                                                    <Image src={CarcassImage} preview={false} />
                                                </Col>
                                                <Col span={10}>
                                                    <div className='font-semi-bold'>{t('pages.dashboard.price.carcass')}</div>
                                                    <div>{moment().add(-3, 'days').format('DD MMMM YYYY')}</div>
                                                </Col>
                                                <Col span={8}>
                                                    {loading ? <Skeleton.Button active={loading} size='large' shape='default' block={true} /> : <NumberFormat className='font-semi-bold font-card-title float-right' value={lastPrice?.hargapangan} displayType={'text'} thousandSeparator={true} prefix={'Rp'} />}
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                    <Col span={24}>
                                        {t('pages.dashboard.price.source')}: <Link link href='https://pinsarindonesia.com' target='_blank'>Pinsar Indonesia</Link> & <Link href='https://hargapangan.id' target='_blank'>PHIPS</Link>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={9} sm={0} xs={0}>
                                {!imageLoad && <Skeleton.Avatar active={!imageLoad} style={{ width: 300, height: 300 }} shape='square' />}
                                <Image className='float-right' src={PriceImage} preview={false} />
                            </Col>
                            <Col span={24} className='mt-10'>
                                <Card title={cityString !== null ? `${t('pages.dashboard.price.chartTitle')} ${cityString}` : `${t('pages.dashboard.price.chartTitle')}`}
                                    bordered={false} className='card-thi'
                                    extra={
                                        <div style={{ width: "100%" }}>
                                            <Row gutter={16}>
                                                <Col lg={{ span: 10, offset: 14 }} xs={{ span: 24 }}>
                                                    <DatePicker.RangePicker style={{ width: '100%' }}
                                                        onChange={e => setRangePrice(e)} value={rangePrice} defaultValue={rangePrice}
                                                        allowClear={false}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                >
                                    <Loading loading={loading}>
                                        <PriceChart data={priceRange} t={t} chartId="price-chart" />
                                    </Loading>
                                </Card>
                            </Col>
                            <Col span={24}>
                                <Card title={t('pages.dashboard.price.nearby')} bordered={false} className='card-thi'
                                    extra={
                                        <div style={{ width: "100%" }}>
                                            <Row gutter={16}>
                                                <Col lg={{ span: 6, offset: 18 }} xs={{ span: 24 }}>
                                                    <DatePicker style={{ width: '100%' }}
                                                        onChange={e => {
                                                            setLastDatePrice(e)
                                                            form2.setFieldsValue({ date: e })
                                                        }}
                                                        value={lastDatePrice}
                                                        defaultValue={lastDatePrice}
                                                        allowClear={false}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                >
                                    <TablePrice data={nearby} NumberFormat={NumberFormat} loading={loading} t={t} />
                                </Card>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </Content>
        </Layout>
    )
}

export default DashboardComponent