export const feedStock = {
    title: "Stok Pakan",
    name: "Keterangan",
    supplier: "Supplier",
    balance: "Saldo",
    add: "Tambah Stok Pakan Baru",
    addMutation: "Tambah Mutasi",
    mutation: 'Mutasi',
    dateMutation: 'Tanggal Mutasi',
    phMutationDate: "Pilih tanggal mutasi",
    msgMutationDate: "Mohon pilih tanggal mutasi!",
    mutationWh: 'Gudang Pakan Tujuan',
    phMutationWh: "Pilih gudang pakan tujuan!",
    msgMutationWh: "Mohon pilih gudang pakan tujuan!",
    edit: "Edit Stok Pakan",
    feedWarehouse: "Gudang Pakan",
    feed: "Pakan",
    sack: 'karung',
    date: "Tanggal Pengadaan",
    price: "Harga per karung",
    qtyStock: "Jumlah Stok (karung)",
    weight: "Ukuran Karung (kg/karung)",
    phFeedWarehouse: "Pilih gudang pakan",
    docNumber: 'Nomor Dokumen',
    phFeed: "Pilih pakan",
    phName: "Tambahkan deskripsi",
    phDate: "Pilih tanggal pengadaan",
    phAddDate: "Pilih tanggal penambahan",
    phUsageDate: "Pilih tanggal penggunaan",
    phPeriodDate: "Pilih tanggal periode",
    msgFeedWarehouse: "Mohon pilih gudang pakan!",
    msgFeed: "Mohon pilih pakan!",
    msgName: "Mohon masukkan keterangan!",
    msgDate: "Mohon pilih tanggal pengadaan!",
    msgAddDate: "Mohon pilih tanggal penambahan!",
    msgUsageDate: "Mohon pilih tanggal penggunaan!",
    msgPeriodDate: "Mohon pilih tanggal periode!",
    msgPrice: "Mohon masukkan harga pakan!",
    msgQtyStock: "Mohon masukkan jumlah stok!",
    msgWeight: "Mohon masukkan berat pakan!",
    addStock: "Tambah Stok",
    titleAddStock: "Tambah Stok Pakan",
    addDate: "Tanggal Penambahan",
    delConfirm: "Apakah anda yakin ingin menghapus stok pakan",
    detail: "Detail Transaksi Stok Pakan",
    addition: "Penambahan",
    trxDate: "Tanggal Transaksi",
    priceFeed: "Harga per karung",
    qty: "Jumlah (karung)",
    qtyWeight: "Jumlah (kg)",
    delTrxConfirm: "Apakah anda yakin ingin menghapus transaksi pakan?",
    titleEditAdd: "Edit Penambahan Stok Pakan",
    titleEditUsage: "Edit Penggunaan Stok Pakan",
    usage: "Penggunaan",
    resAddSuccess: "Berhasil menambahkan stok pakan",
    resAddMutationSuccess: "Berhasil menambahkan mutasi pakan",
    resEditSuccess: "Berhasil mengubah stok pakan",
    resEditTrxSuccess: "Berhasil mengubah transaksi pakan",
    resDelSuccess: "Berhasil menghapus stok pakan",
    resDelTrxSuccess: "Berhasil menghapus transaksi pakan",
    resAddFailed: "Gagal menambahkan stok pakan",
    resAddMutationFailed: "Gagal menambahkan mutasi pakan",
    resEditFailed: "Gagal mengubah stok pakan",
    resEditTrxFailed: "Gagal mengubah transaksi pakan",
    resDelFailed: "Gagal menghapus stok pakan",
    resDelTrxFailed: "Gagal menghapus transaksi pakan",
}