import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Input, Select } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'

import { createPetugas } from './query-petugas'

import { API } from '../../../../../common/api'
import CONSTANTS from '../../../../../common/utils/Constants'
import { errMessage } from '../../../../../common/component/notification/notification'
import { navigate } from '../../../../../common/store/action'
import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import Autocomplete from "react-google-autocomplete"

function CreatePetugasPage(props) {
    const { fetchData, total, setLoading, typeUser, kandang, t } = props
    const [visible, setVisible] = useState(false)
    const { Option } = Select
    const [hidden, setHidden] = useState(true)
    const [form] = Form.useForm()

    const onChangeType = (val) => {
        if (val.value === 'staff') setHidden(false)
        else setHidden(true)
    }
    const free = () => {
        Modal.info({
            title: `${t('general.upgrade')}`,
            centered: true,
            content: (
                <p>{t('general.limitedUser')}</p>
            ),
            okText: 'Upgrade',
            onOk() { props.navigate(CONSTANTS.PRICING_KEY) },
        })
    }

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = async (values) => {
        await API.post(createPetugas(values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.staff.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                } else if (res.data.data.createUser !== null) {
                    errMessage('success', `${t('pages.master.staff.resAddSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                errMessage('error', `${t('pages.master.staff.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    return (
        <div>
            <ButtonDashboard
                text={t('pages.master.staff.add')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => typeUser === 'free' && total !== 0 ?
                    free() : showModal()}
            />
            <Modal
                visible={visible}
                title={t('pages.master.staff.add')}
                okText={t('general.add')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={() => {
                    setVisible(false)
                }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        });
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.staff.name')}</span>
                            <Form.Item
                                name="namaPetugas" rules={[{
                                    required: true,
                                    message: `${t('pages.master.staff.msgName')}`
                                }]}
                            >
                                <Input
                                    placeholder={t('pages.master.staff.phName')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">Username</span>
                            <Form.Item
                                name="username" rules={[{
                                    required: true,
                                    message: `${t('pages.master.staff.phUsername')}`,
                                    min: 4
                                }]}
                            >
                                <Input
                                    placeholder={t('pages.master.staff.phUsername')}
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">Email</span>
                            <Form.Item
                                name="email" rules={[{
                                    required: true, type: 'email',
                                    message: `${t('pages.master.staff.msgEmail')}`
                                }]}
                            >
                                <Input
                                    placeholder={t('pages.master.staff.phEmail')}
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">Password</span>
                            <Form.Item
                                name="password" rules={[{
                                    required: true,
                                    message: `${t('pages.master.staff.msgPassword')}`,
                                    min: 6
                                }]}
                            >
                                <Input.Password
                                    placeholder={t('pages.master.staff.phPassword')}
                                    className="input-password"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.staff.phone')}</span>
                            <Form.Item
                                name="nomorTelefon" rules={[{
                                    required: true,
                                    message: `${t('pages.master.staff.msgPhone')}`
                                }]}
                            >
                                <Input
                                    placeholder="+62..."
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.staff.type')}</span>
                            <Form.Item
                                name="type" rules={[{ required: true, message: `${t('pages.master.staff.msgType')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.master.staff.phType')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                    onChange={(value) => onChangeType(value)}
                                >
                                    <Option value="staff">{t('pages.master.staff.staff')}</Option>
                                    <Option value="management">{t('pages.master.staff.management')}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} hidden={hidden}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.master.staff.house')}</span>
                            <Form.Item
                                name="house"
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    mode="multiple"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    placeholder={t('pages.master.staff.phHouse')}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        kandang.map(data =>
                                            <Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.master.staff.address')}</span>
                            <Form.Item
                                name="alamat" rules={[{ required: true, message: `${t('pages.master.staff.msgAddress')}` },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (getFieldValue('idAddr') !== undefined) {
                                            return Promise.resolve()
                                        }

                                        return Promise.reject(new Error(t('pages.auth.msgIdAddr')))
                                    },
                                })
                                ]}
                                dependencies={['idAddr']}
                                hasFeedback
                            >
                                <Autocomplete
                                    placeholder={t('pages.master.staff.phAddress')}
                                    className='ant-input input-register'
                                    apiKey={process.env.REACT_APP_GOOGLE_TOKEN}
                                    onPlaceSelected={place => {
                                        form.setFieldsValue({ idAddr: place.place_id, alamat: place.formatted_address })
                                    }}
                                    options={{
                                        types: ["geocode", "establishment"],
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col hidden>
                            <Form.Item name="idAddr" >
                                <Input className="input-register" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div >
    )
}

const mapStateToProps = state => ({
    typeUser: state.profil.dataProfil.company.typeUser,
    kandang: state.layout.dataKandang,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreatePetugasPage)
export default page