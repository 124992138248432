export const profile = {
    title: "Profile",
    changeProfile: "Change Profile",
    changePass: "Change Password",
    name: "Fullname",
    phone: "Phone Number",
    company: "Company Name",
    address: "Address",
    newPass: "New Password",
    oldPass: "Old Password",
    phName: "Input your fullname",
    phEmail: "Input your email",
    phUsername: "Input your username",
    phPhone: "Input your phone number",
    phCompany: "Input your company",
    phAddress: "Input your address",
    phOldPass: "Input your old password",
    phNewPass: "Input your new password",
    msgName: "Please input your fullname!",
    msgEmail: "Please input your email!",
    msgUsername: "Please input your username!",
    msgPhone: "Please input your phone number!",
    msgCompany: "Please input your company!",
    msgAddress: "Please input your address!",
    msgOldPass: "Please input your old password!",
    msgNewPass: "Please input your new password at least 6 characters!",
    resEditProfileSuccess: "Successfully update your profile",
    resEditPassSuccess: "Successfully update your password",
    resEditPicSuccess: 'Successfully update your profile picture',
    resEditProfileFailed: "Failed to update your profile",
    resEditPassFailed: "Failed to update your password",
    resEditPicFailed: 'Failed to update your profile picture'
}