import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Input, Select, InputNumber } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import ButtonDashboard from '../../../../../common/component/button/button-dashboard'
import { API } from '../../../../../common/api'
import { navigate } from '../../../../../common/store/action'
import CONSTANTS from '../../../../../common/utils/Constants'
import { errMessage, validationMessage } from '../../../../../common/component/notification/notification'

import { createKandang, queryListKandang } from './query-kandang'
import { getListKandang } from '../../../../../modules/dashboard-peternak/layout-component/store/layout-action'
import Autocomplete from "react-google-autocomplete"
import InputMaps from '../../../../../common/component/input/input-maps'

function CreateKandangPage(props) {
    const { fetchData, getListKandang, total, setLoading, gudangPakan, gudangOvk, typeUser, t } = props
    const { TextArea } = Input
    const { Option } = Select
    const [visible, setVisible] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [state, setState] = React.useState({
        isMap: false,
        position: null,
        zoom: 10,
        center: {
            lat: -7.788319728345056,
            lng: 110.36681604046649,
        },
    })

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const postData = async (values) => {
        await API.post(createKandang(values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.house.resAddFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.createHouse._id) {
                    setConfirmLoading(false)
                    validationMessage('success', `${t('pages.master.house.resAddSuccess')}`)
                    getListKandang(queryListKandang('', 0, 0))
                    fetchData()
                    setState((prev) => ({
                        isMap: false,
                        position: null,
                        zoom: 10,
                        center: {
                            lat: -7.788319728345056,
                            lng: 110.36681604046649,
                        },
                    }))
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.master.house.resAddFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        setConfirmLoading(true)
        postData(values)
        setVisible(false)
        setLoading(true)
    }

    const free = () => {
        Modal.info({
            title: `${t('general.upgrade')}`,
            centered: true,
            content: (
                <p>{t('general.limitedHouse')}</p>
            ),
            okText: 'Upgrade',
            onOk() { props.navigate(CONSTANTS.PRICING_KEY) },
        })
    }
    const [form] = Form.useForm()

    return (
        <div>
            <ButtonDashboard
                text={t('pages.master.house.add')}
                height={20}
                backgroundColor="#008ad4"
                borderRadius="5px"
                className="font-semi-medium font-content-title button-add"
                textColor="white"
                icon={faPlusSquare}
                onClick={() => typeUser === 'free' && total !== 0 ?
                    free() : showModal()}
            />

            <Modal
                visible={visible}
                title={t('pages.master.house.add')}
                okText={t('general.add')}
                width={800}
                confirmLoading={confirmLoading}
                cancelText={t('general.cancel')}
                onCancel={() => {
                    setVisible(false)
                }}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{ keterangan: ' ' }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.house.name')}</span>
                            <Form.Item
                                name="namaKandang" rules={[{ required: true, message: `${t('pages.master.house.msgName')}` }]}
                            >
                                <Input
                                    name='namaKandang'
                                    placeholder="Kandang 1 Lantai 1..."
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.house.capacity')}</span>
                            <Form.Item
                                name="kapasistasKandang" rules={[{ required: true, message: `${t('pages.master.house.capacity')}` }]}
                            >
                                <InputNumber
                                    name='kapasistasKandang'
                                    placeholder="2500..."
                                    className="input-number"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.house.feedWh')}</span>
                            <Form.Item
                                name="selectGudangPakan" rules={[{ required: true, message: `${t('pages.master.house.msgFeedWh')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.master.house.phFeedWh')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        gudangPakan.map(data =>
                                            <Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.house.ovkWh')}</span>
                            <Form.Item
                                name="selectGudangOvk" rules={[{ required: true, message: `${t('pages.master.house.msgOvkWh')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.master.house.phOvkWh')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        gudangOvk.map(data =>
                                            <Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.master.house.address')}</span>
                            <Form.Item
                                name="alamat" rules={[
                                    { required: true, message: `${t('pages.master.house.msgAddress')}` },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (getFieldValue('position') !== undefined) {
                                                return Promise.resolve()
                                            }

                                            return Promise.reject(new Error(t('pages.auth.msgIdAddr')))
                                        },
                                    })
                                ]}
                                dependencies={['position']}
                                hasFeedback
                            >

                                {!state.isMap &&
                                    <Autocomplete
                                        placeholder={t('pages.master.house.phAddress')}
                                        className='ant-input input-register'
                                        apiKey={process.env.REACT_APP_GOOGLE_TOKEN}
                                        onPlaceSelected={place => {
                                            form.setFieldsValue({ position: place.geometry.location.toJSON(), alamat: place.formatted_address })
                                        }}
                                        language={'id'}
                                        options={{
                                            types: ["geocode", "establishment"],
                                        }}
                                    />}
                                {state.isMap &&
                                    <InputMaps
                                        center={state.center} zoom={state.zoom}
                                        onClick={(e) => {
                                            setState((prev) => ({
                                                ...prev,
                                                position: e.latLng,
                                            }))
                                            form.setFieldsValue({ position: e.latLng.toJSON() })
                                        }}
                                        onIdle={e =>
                                            setState((prev) => ({
                                                ...prev,
                                                zoom: e.getZoom(),
                                                center: e.getCenter().toJSON()
                                            }))
                                        }
                                        position={state.position}
                                        onChange={(e) => form.setFieldsValue({ alamat: e.target.value })}
                                        placeholder={t('pages.master.house.phAddress')}
                                    />}
                                <Link className='mt-5'
                                    onClick={() => {
                                        setState((prev) => ({
                                            ...prev,
                                            isMap: !state.isMap,
                                            position: null
                                        }))
                                        form.resetFields(['position', 'alamat'])
                                    }}>
                                    {state.isMap ?
                                        'Cari menggunakan pencarian alamat' :
                                        'Tidak menemukan alamat yang dicari? Coba pilih lokasi menggunakan Maps'
                                    }
                                </Link>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.master.house.desc')}</span>
                            <Form.Item
                                name="keterangan" rules={[{ required: false }]}
                            >
                                <TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={t('pages.master.house.phDesc')}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                        <Col hidden>
                            <Form.Item name="position" >
                                <Input className="input-register" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

const mapStateToProps = state => ({
    gudangPakan: state.gudangPakan.dataGudangPakan,
    gudangOvk: state.gudangOvk.dataGudangOvk,
    typeUser: state.profil.dataProfil.company.typeUser,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
    getListKandang,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateKandangPage)
export default page