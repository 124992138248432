export const general = {
    search: "Search",
    del: "Delete",
    add: "Add",
    edit: "Edit",
    reset: "Reset",
    send: 'Send',
    detail: "Details",
    open: "Open",
    close: "Close",
    lock: "Lock",
    unlock: "Unlock",
    save: "Save",
    next: "Next",
    back: "Back",
    fin: "Finish",
    cancel: "Cancel",
    action: "Action",
    print: "Print",
    yes: 'Yes',
    no: 'No',
    upgrade: "Upgrade your account",
    limitedUser: "Sorry, to add more than 1 staff you need BroilerX Premium account, please upgrade your account.",
    limitedHouse: "Sorry, to add more than 1 house you need BroilerX Premium account, please upgrade your account.",
    limitedRearing: "Sorry, to add more than 1 active rearing you need BroilerX Premium account, please upgrade your account.",
    limitedFeedWh: "Sorry, to add more than 1 feed warehouse you need BroilerX Premium account, please upgrade your account.",
    limitedOvkWh: "Sorry, to add more than 1 medicine warehouse you need BroilerX Premium account, please upgrade your account.",
    limitedMaterialWh: "Sorry, to add more than 1 material warehouse you need BroilerX Premium account, please upgrade your account."
}