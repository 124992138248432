export const materialStock = {
    title: "Stok Material",
    name: "Nama Pengadaan",
    supplier: "Supplier",
    balance: "Saldo",
    tambahBaru: "Tambah Stok Material Baru",
    addStock: "Tambah Stok",
    add: "Tambah Stok Material",
    materialWh: "Gudang Material",
    date: "Tanggal Pengadaan",
    addDate: "Tanggal Penambahan",
    usageDate: "Tanggal Penggunaan",
    qtyStock: "Jumlah Stok",
    price: "Harga Material",
    phWh: "Pilih gudang material",
    phMaterial: "Pilih material",
    phName: "Pengadaan Stater",
    phDate: "Pilih tanggal pengadaan",
    phAddDate: "Pilih tanggal penambahan",
    phUsageDate: "Pilih tanggal penggunaan",
    msgWh: "Mohon pilih gudang material!",
    msgMaterial: "Mohon pilih material!",
    msgName: "Mohon masukkan nama pengadaan!",
    msgDate: "Mohon pilih tanggal pengadaan!",
    msgAddDate: "Mohon pilih tanggal penambahan!!",
    msgUsageDate: "Mohon pilih tanggal penggunaan!",
    msgPrice: "Mohon masukkan harga material!",
    msgQtyStock: "Mohon masukkan jumlah stok",
    edit: "Edit Stok Material",
    delConfirm: "Apakah anda yakin ingin menghapus transaksi material",
    detail: "Detail Material Stok",
    addition: "Penambahan",
    trxDate: "Tanggal Transaksi",
    qty: "Jumlah",
    delTrxConfirm: "Apakah anda yakin ingin menghapus transaksi material ?",
    titleEditAdd: "Edit Transaksi Penambahan Stok Material",
    dateStock: "Tanggal Stok",
    usage: "Penggunaan",
    titleEditUsage: "Edit Transaksi Penggunaan Stok Material",
    resAddSuccess: "Berhasil menambahkan stok material",
    resEditSuccess: "Berhasil mengubah stok material",
    resEditTrxSuccess: "Berhasil mengubah transaksi material",
    resDelSuccess: "Berhasil menghapus stok material",
    resDelTrxSuccess: "Berhasil menghapus transaksi material",
    resAddFailed: "Gagal menambahkan stok material",
    resEditFailed: "Gagal mengubah stok material",
    resEditTrxFailed: "Gagal mengubah transaksi material",
    resDelFailed: "Gagal menghapus stok material",
    resDelTrxFailed: "Gagal menghapus transaksi material",
}