/**
 * @author spindyzel
 * @since 28 Desember 2019
*/

import LoginPage from './../../app/auth/login-page'
import LogoutPage from './../../app/auth/logout-page'
import RegisterPage from './../../app/auth/register-page'
import ForgotPasswordPage from './../../app/forgot-password/forgot-password-page'
import ChangePasswordPage from './../../app/forgot-password/change-password-page'
import VerifPage from './../../app/auth/verify-page'
import LayoutPeternakPage from './../../app/dashboard-peternak/layout-page/layout-page'
// import Error403Page from './../../app/error/error-page'
import Error404Page from './../../app/error/404-page'

const baseUrl = process.env.PUBLIC_URL

const routeSources = [    
    { component: LoginPage, path: `${baseUrl}/`, exact: true},
    { component: RegisterPage, path: `${baseUrl}/register`, exact: true},
    { component: ForgotPasswordPage, path: `${baseUrl}/forgot-password`, exact: true},
    { component: ChangePasswordPage, path: `${baseUrl}/password-reset`, exact: false},
    { component: LogoutPage, path: `${baseUrl}/logout`, exact: false},
    { component: VerifPage, path: `${baseUrl}/resend-verify`, exact: false},
    { component: LayoutPeternakPage, path: `${baseUrl}/peternak`, exact: false, private : true},
    { component: Error404Page, path:"*"},
]

export default routeSources