import * as actionType from './stok-pakan-action-type'
import {API} from '../../../../../common/api'
import { validationMessage } from '../../../../../common/component/notification/notification'

const startGetDataStokPakans = () => ({
    type : actionType.START_GET_DATA
})

const finishGetDataStokPakans = () => ({
    type : actionType.FINISH_GET_DATA
})

const setIdStokPakan = payload => ({
    type : actionType.SET_ID_STOK_PAKAN,
    payload,
})

const setDataStokPakan = payload => ({
    type : actionType.SET_STOK_PAKAN,
    payload,
})

const setDataBeratPakan = payload => ({
    type : actionType.SET_BERAT_PAKAN,
    payload
})

export const getBeratPakan = (berat_pakan) => (dispatch) => {
    dispatch(setDataBeratPakan(berat_pakan))
}

export const getIdStokPakan = (id_stok) => (dispatch) => {
    dispatch(setIdStokPakan(id_stok))
}

export const getListPakan = (params) => (dispatch) => {
    dispatch(startGetDataStokPakans())
    API.get(params)
    .then(res => {
        if(res === undefined){ validationMessage('error', 'Connection error') }
        else if(res.data.data.feedStocksWarehouse){
            dispatch(setDataStokPakan(res.data.data.feedStocksWarehouse))
        } else {
            validationMessage('error', 'Login Gagal')
        }
        dispatch(finishGetDataStokPakans())
    }).catch(e => {
        console.log(e.message)
        // validationMessage('error', e.message)
    })
}