import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { navigate } from '../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input, Layout, Row, Col, Table, Typography, Breadcrumb } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faEdit, faLock, faTrash } from '@fortawesome/free-solid-svg-icons'
import { deletePeriode, updatePeriode, detailPeriode, closePeriode, getPeriode, queryPeriodeAktif } from './rearing-aksi-page/query-rearing'
import { getPeriodeAktif } from '../../../modules/dashboard-peternak/layout-component/store/layout-action'
import { API } from '../../../common/api'
import { errMessage, validationMessage } from '../../../common/component/notification/notification'
import Loading from '../../../common/component/loading/loading-container'

import ModalCreateRearing from './rearing-aksi-page/create-rearing-page'
import ModalEditRearing from './rearing-aksi-page/edit-rearing-page'
import ModalCloseRearing from './rearing-aksi-page/close-rearing-page'

import ButtonDashboard from '../../../common/component/button/button-dashboard'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'

import { useTranslation } from "react-i18next"

import moment from 'moment-timezone'
import 'moment-timezone'
import 'moment/locale/id'
import CONSTANTS from '../../../common/utils/Constants'
import { isMobileOnly } from 'react-device-detect'
const { Text } = Typography

function RearingActivePage(props) {
    const { confirm } = Modal
    const { getPeriodeAktif, profil, typeUser } = props
    const [loading, setLoading] = useState(false)
    const [rearingActive, setRearingActive] = useState([])
    const [tableParams, setTableParams] = useState({
        keyword: '',
        current: 1,
        pageSize: 10,
    })
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [idData, setIdData] = useState(null)
    const [visible, setVisible] = useState(false)
    const [visibleCreate, setVisibleCreate] = useState(false)
    const [detail, setDetail] = useState('')
    const [visibleClose, setVisibleClose] = useState(false)
    const [idClose, setIdClose] = useState(null)
    const [total, setTotal] = useState(null)
    const { t } = useTranslation()
    const role = profil.permission.writeRearing

    const fetchDataActive = async () => {
        setLoading(true)
        const { keyword, current, pageSize } = tableParams
        await API.get(getPeriode(keyword, pageSize, current))
            .then(res => {
                setRearingActive(res.data.data.rearings.rearings)
                setTotal(res.data.data.rearings.totalCount)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setRearingActive([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchDataActive()
        // eslint-disable-next-line
    }, [tableParams])

    const handleTableChange = (pagination, filters) => {
        setTableParams({
            ...pagination,
            keyword: filters.kandang ? filters.kandang[0] : ''
        })
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters, confirm, dataIndex)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = (clearFilters, confirm, dataIndex) => {
        clearFilters()
        setSearchText('')
        confirm()
        setSeacrhedColumn(dataIndex)
    }

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deletePeriode(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.rearing.resDelFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteRearing.deleted === true) {
                    validationMessage('success', `${t('pages.rearing.resDelSuccess')}`)
                    fetchDataActive()
                    getPeriodeAktif(queryPeriodeAktif())
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.rearing.resDelFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, nama) => {
        confirm({
            title: `${t('pages.rearing.delConfirm')} ${nama}?`,
            onOk: () => {
                deleteData(_id)
            },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(detailPeriode(idData))
                    .then(res => {
                        setDetail(res.data.data.rearing)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const showModalCreate = useCallback(() => {
        setVisibleCreate(true)
    }, [])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = async (values, year) => {
        await API.post(updatePeriode(idData, values, year))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.rearing.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.updateRearing._id) {
                    validationMessage('success', `${t('pages.rearing.resEditSuccess')}`)
                    fetchDataActive()
                    getPeriodeAktif(queryPeriodeAktif())
                }
            }).catch((error) => {
                console.log(error)
                validationMessage('error', `${t('pages.rearing.resEditFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        updatedData(values, values['date_picker'].format('MM/DD/YYYY'))
        setVisible(false)
        setIdData(null)
        setLoading(true)
    }

    const showModalClose = useCallback((value) => {
        if (value === idClose)
            setVisibleClose(true)
        else {
            setIdClose(value)
            setVisibleClose(true)
        }
    }, [idClose])

    const handleCancelClose = useCallback(() => {
        setVisibleClose(false)
    }, [])

    const updatedDataCloseRearing = async (values) => {
        await API.post(closePeriode(idClose, values))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.rearing.resCloseFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.closeRearing._id) {
                    validationMessage('success', `${t('pages.rearing.resCloseSuccess')}`)
                    fetchDataActive()
                    getPeriodeAktif(queryPeriodeAktif())
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.rearing.resCloseFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmitClose = (values) => {
        updatedDataCloseRearing(values['date_picker'].format('MM/DD/YYYY'))
        setVisibleClose(false)
        setIdData(null)
        setLoading(true)
    }

    const columns = [
        {
            title: `No.`,
            dataIndex: 'number',
            key: 'number',
            width: 20,
            align: 'center',
            render: (value, row, index) => (<span>{index + 1}</span>)
        },
        {
            title: `${t('pages.rearing.date')}`,
            dataIndex: 'tanggal',
            key: 'tanggal',
            width: 60,
        },
        {
            title: `${t('pages.rearing.house')}`,
            dataIndex: 'kandang',
            key: 'kandang',
            ...getColumnSearchProps('kandang'),
            width: 90,
        },
        {
            title: `${t('pages.rearing.name')}`,
            dataIndex: 'nama',
            key: 'nama',
            width: 80,
        },
        {
            title: `${t('pages.rearing.population')}`,
            dataIndex: 'populasi',
            key: 'populasi',
            className: 'right',
            sorter: (a, b) => a.population - b.population,
            width: 60,
        },
        {
            title: `${t('pages.rearing.population')} HPP`,
            dataIndex: 'populasiHPP',
            key: 'populasiHPP',
            className: 'right',
            sorter: (a, b) => a.population - b.population,
            width: 60,
        },
        {
            title: `Sisa Ekor`,
            dataIndex: 'sisaEkor',
            key: 'sisaEkor',
            className: 'right',
            sorter: (a, b) => a.restPopulation - b.restPopulation,
            width: 60,
        },
        {
            title: `${t('pages.rearing.weight')}`,
            dataIndex: 'bobot',
            key: 'bobot',
            className: 'right',
            sorter: (a, b) => a.weight - b.weight,
            width: 60,
        },
        {
            title: `${t("pages.rearing.price")}`,
            dataIndex: 'harga',
            className: 'right',
            key: 'harga',
            width: 50,
        },
        {
            title: `${t("pages.rearing.age")}`,
            dataIndex: 'umur',
            className: 'right',
            key: 'umur',
            width: 30,
        },
        {
            title: `${t("pages.rearing.doc")}`,
            dataIndex: 'doc',
            key: 'doc',
            className: 'center',
            width: 90,
        },
        {
            title: `${t("pages.rearing.ppl")}`,
            dataIndex: 'ppl',
            key: 'ppl',
            width: 80,
        },
        {
            title: 'Performance',
            children: [
                {
                    title: 'ADG (Gr)',
                    dataIndex: 'adg',
                    key: 'adg',
                    className: 'right',
                    width: 40,
                },
                {
                    title: 'FCR',
                    dataIndex: 'fcr',
                    className: 'right',
                    key: 'fcr',
                    width: 30,
                },
                {
                    title: `${t("pages.rearing.depletion")}`,
                    dataIndex: 'deplesi',
                    className: 'right',
                    key: 'deplesi',
                    width: 40,
                },
                {
                    title: 'FI',
                    dataIndex: 'fi',
                    className: 'right',
                    key: 'fi',
                    width: 40,
                },
                {
                    title: 'IP',
                    dataIndex: 'ip',
                    className: 'right',
                    key: 'ip',
                    width: 40,
                },
            ],
        },
        !role ? { width: 1 } :
            {
                title: `${t('general.action')}`,
                dataIndex: 'pilihan',
                key: 'pilihan',
                align: 'center',
                fixed: !isMobileOnly && 'right',
                width: 100,
                render: (value, row, index) => (
                    <Space size="middle">
                        <Tooltip placement="top" title={t('general.edit')}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FFA903',
                                borderRadius: '5px'
                            }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('general.lock')}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#ff4a3d',
                                borderRadius: '5px'
                            }} onClick={() => showModalClose(row.key)}><FontAwesomeIcon icon={faLock} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title={t('general.del')}>
                            <Button style={{
                                color: 'white',
                                backgroundColor: '#FF0303',
                                borderRadius: '5px'
                            }} onClick={() => hapusConfirm(row.key, row.nama)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </Tooltip>
                    </Space>
                ),
            },
    ];

    const data = rearingActive.map(({ _id, name, chickInDate, population, populationHpp, chickInWeight,
        house, doc, age, ppl, adg, fcr, deplesi, lastDate, feedIntake, ip, price, restPopulation }, index) => ({
            key: _id,
            id: _id,
            nama: name,
            doc: doc?.name,
            umur: age,
            ppl: ppl,
            adg: parseFloat(adg.toFixed(2)),
            fcr: fcr,
            deplesi: deplesi,
            feedIntake: feedIntake,
            restPopulation: restPopulation,
            sisaEkor: <NumberFormat value={restPopulation} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.rearing.chicken')}`} />,
            indexPerformance: ip,
            lastDate: moment(new Date(parseInt(lastDate))),
            fi: <NumberFormat value={feedIntake} displayType={'text'} thousandSeparator={true} />,
            ip: <NumberFormat value={ip} displayType={'text'} thousandSeparator={true} />,
            tanggal: moment(new Date(parseInt(chickInDate))).format("DD MMMM YYYY"),
            kandang: house?.warehouseName,
            harga: <NumberFormat value={price} displayType={'text'} thousandSeparator={true} />,
            population: population,
            populationHpp: populationHpp,
            populasi: <NumberFormat value={population} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.rearing.chicken')}`} />,
            populasiHPP: <NumberFormat value={populationHpp} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.rearing.chicken')}`} />,
            weight: chickInWeight,
            bobot: <NumberFormat value={chickInWeight} displayType={'text'} thousandSeparator={true} suffix={` gram`} />,
        }))

    const free = () => {
        Modal.info({
            title: `${t('general.upgrade')}`,
            centered: true,
            content: (
                <p>{t('general.limitedRearing')}</p>
            ),
            okText: 'Upgrade',
            onOk() { props.navigate(CONSTANTS.PRICING_KEY) },
        })
    }

    return (
        <Layout>
            <Layout.Content className="dashboard-container">
                <Row>
                    <Col lg={12} sm={24} className='mb-10'>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.rearing.rearingActive')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={12} sm={24}>
                        {!profil.permission.writeRearing ? <></> :
                            <ButtonDashboard
                                text={t("pages.rearing.add")}
                                height={20}
                                backgroundColor="#008ad4"
                                borderRadius="5px"
                                className="font-semi-medium font-content-title button-add mb-10"
                                textColor="white"
                                icon={faPlusSquare}
                                onClick={() => typeUser === 'free' && total !== 0 ?
                                    free() : showModalCreate()}
                            />
                        }
                        <ModalCreateRearing fetchDataActive={fetchDataActive} setLoading={setLoading}
                            setVisible={setVisibleCreate} visible={visibleCreate} t={t} />
                    </Col>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={{ ...tableParams, total }}
                                onChange={handleTableChange}
                                scroll={{ x: 2000, y: '70vh' }}
                                className="table-dashboard"
                                summary={pageData => {
                                    let totalPop = 0
                                    let totalPopHpp = 0
                                    let avgWeight = 0
                                    let sigmaWeight = 0

                                    let avgAge = 0
                                    let sigmaAge = 0
                                    let sigmaRestPop = 0

                                    let sigmaIp = 0
                                    let sigmaAdg = 0
                                    let sigmaFcr = 0
                                    let sigmaDep = 0
                                    let sigmaFi = 0

                                    let avgIp = 0
                                    let avgAdg = 0
                                    let avgFcr = 0
                                    let avgDep = 0
                                    let avgFi = 0
                                    pageData.forEach(({
                                        population,
                                        populationHpp,
                                        weight,
                                        restPopulation,
                                        umur,
                                        indexPerformance,
                                        feedIntake,
                                        adg,
                                        fcr,
                                        deplesi
                                    }) => {
                                        totalPop += parseInt(population)
                                        totalPopHpp += parseInt(populationHpp)
                                        sigmaWeight += (weight * population)
                                        sigmaAge += (umur * restPopulation)
                                        sigmaRestPop += restPopulation
                                        sigmaAdg += (adg * restPopulation)
                                        sigmaFcr += (fcr * restPopulation)
                                        sigmaFi += (feedIntake * restPopulation)
                                        sigmaIp += (indexPerformance * restPopulation)
                                        sigmaDep += (deplesi * restPopulation)
                                    })
                                    avgWeight = parseFloat((sigmaWeight / totalPop).toFixed(2))
                                    avgAge = parseFloat((sigmaAge / sigmaRestPop).toFixed(2))
                                    avgIp = parseInt(sigmaIp / sigmaRestPop)
                                    avgFcr = parseFloat((sigmaFcr / sigmaRestPop).toFixed(2))
                                    avgFi = parseFloat((sigmaFi / sigmaRestPop).toFixed(2))
                                    avgDep = parseFloat((sigmaDep / sigmaRestPop).toFixed(2))
                                    avgAdg = parseFloat((sigmaAdg / sigmaRestPop).toFixed(2))

                                    return (
                                        <>
                                            <Table.Summary.Row >
                                                <Table.Summary.Cell index={0} colSpan={4}>Total</Table.Summary.Cell>

                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={totalPop} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.rearing.chicken')}`} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={totalPopHpp} displayType={'text'} thousandSeparator={true} suffix={` ${t('pages.rearing.chicken')}`} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={avgWeight} displayType={'text'} thousandSeparator={true} suffix={` gram`} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right"></Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={avgAge} displayType={'text'} thousandSeparator={true} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right" colSpan={2}></Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={avgAdg} displayType={'text'} thousandSeparator={true} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={avgFcr} displayType={'text'} thousandSeparator={true} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={avgDep} displayType={'text'} thousandSeparator={true} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={avgFi} displayType={'text'} thousandSeparator={true} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right">
                                                    <Text> <NumberFormat value={avgIp} displayType={'text'} thousandSeparator={true} /> </Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell className="right" index={15}></Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    )
                                }}
                            />
                        </Loading>
                    </Col>
                    <ModalCloseRearing visibleClose={visibleClose}
                        handleCancelClose={handleCancelClose} handleSubmitClose={handleSubmitClose} t={t} />
                </Row>
                <ModalEditRearing visible={visible} idData={idData}
                    handleCancel={handleCancel} detail={detail} handleSubmit={handleSubmit} t={t} />
            </Layout.Content>
        </Layout>
    );
}

const mapStateToProps = state => ({
    idKandang: state.layout.idKandang,
    profil: state.profil.dataProfil,
    typeUser: state.profil.dataProfil.company.typeUser,
});

const mapDispatchToProps = (dispatch => ({
    navigate,
    getPeriodeAktif,
}))();

const page = connect(mapStateToProps, mapDispatchToProps)(RearingActivePage);
export default page