import React from 'react'
import { connect } from 'react-redux'
import { Result, Button } from 'antd'
import CONSTANTS from '../../common/utils/Constants'
import { navigate } from '../../common/store/action'
import { useLocation } from "react-router-dom"

function ErrorPage(props) {
    const location = useLocation()

    return (
        <Result className='vertical-center'
            style={location.pathname === '/peternak/403' ? { position: 'relative' } : { position: 'absolute' }}
            status="403"
            title="403"
            subTitle="Sorry, you are not authorized to access this page."
            extra={<Button type="primary"
                onClick={() => props.navigate(CONSTANTS.HOME_MENU_KEY)}>Back Home</Button>}
        />
    )
}

const mapStateToProps = state => ({
})
const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(ErrorPage)
export default page