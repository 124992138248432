import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../common/store/action'
import Highlighter from 'react-highlight-words'
import { Space, Tooltip, Button, Modal, Input, Layout, Breadcrumb, Row, Col, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getDoc, deleteDoc, detailDoc, updateDoc } from './doc-aksi-page/query-doc'
import { API } from '../../../../common/api'
import { errMessage, validationMessage } from '../../../../common/component/notification/notification'
import Loading from '../../../../common/component/loading/loading-container'
import CreateDoc from './doc-aksi-page/create-doc-page'
import EditDoc from './doc-aksi-page/edit-doc-page'
import { useTranslation } from "react-i18next"
import { isMobileOnly } from 'react-device-detect'

function DocPage(props) {
    const { role, profil } = props
    const { confirm } = Modal
    const [searchText, setSearchText] = useState('')
    const [seacrhedColumn, setSeacrhedColumn] = useState('')
    const [visible, setVisible] = useState(false)
    const [detail, setDetail] = useState('')
    const [idData, setIdData] = useState(null)
    const [loading, setLoading] = useState(false)
    const [object, setObject] = useState([])
    const { Content } = Layout
    const { t } = useTranslation()

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('general.search')}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {t('general.reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? 'white' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        render: text =>
            seacrhedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSeacrhedColumn(dataIndex)
    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    const fetchData = async () => {
        setLoading(true);
        await API.get(getDoc('', 0, 0))
            .then(res => {
                setObject(res.data.data.docs.docs)
                setLoading(false)
            }).catch((error) => {
                console.log(error)
                setObject([])
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const deleteData = async (_id) => {
        setLoading(true)
        await API.delete(deleteDoc(_id))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.doc.resDelFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.deleteDoc.deleted === true) {
                    validationMessage('success', `${t('pages.master.doc.resDelSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.master.doc.resDelFailed')}`, error.message)
                setLoading(false)
            })
    }

    const hapusConfirm = (_id, name) => {
        confirm({
            title: `${t('pages.master.doc.delConfirm')} ${name}?`,
            onOk: () => { deleteData(_id) },
            icon: <DeleteOutlined style={{ color: 'red' }} />,
            maskClosable: true,
            okText: t('general.del'),
            cancelText: t('general.cancel'),
            okButtonProps: { danger: true }
        })
    }

    useEffect(() => {
        async function getDetailData() {
            if (idData) {
                await API.get(detailDoc(idData))
                    .then(res => {
                        setDetail(res.data.data.doc)
                        setVisible(true)
                    }).catch((error) => {
                        console.log(error)
                        setDetail(null)
                        setVisible(false)
                    })
            }
        }
        getDetailData()
    }, [idData])

    const showModal = useCallback((value) => {
        if (value === idData)
            setVisible(true)
        else
            setIdData(value)
    }, [idData])

    const handleCancel = useCallback(() => {
        setVisible(false)
    }, [])

    const updatedData = async (values, type) => {
        await API.post(updateDoc(idData, values, type))
            .then(res => {
                if (res.data.errors) {
                    errMessage('error', `${t('pages.master.doc.resEditFailed')}`, res.data.errors[0].message)
                    setLoading(false)
                }
                else if (res.data.data.updateDoc._id) {
                    validationMessage('success', `${t('pages.master.doc.resEditSuccess')}`)
                    fetchData()
                }
            }).catch((error) => {
                validationMessage('error', `${t('pages.master.doc.resEditFailed')}`, error.message)
                setLoading(false)
            })
    }

    const handleSubmit = (values) => {
        let type = role === 'superadmin' ? 'primary' : 'secondary'
        updatedData(values, type)
        setVisible(false)
        setLoading(true)
        setIdData(null)
    }

    const columns = [
        {
            title: `${t('pages.master.doc.name')}`,
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: `${t('pages.master.doc.corporation')}`,
            dataIndex: 'corporation',
            key: 'corporation',
            ...getColumnSearchProps(`${t('pages.master.doc.corporation')}`),
        },
        {
            title: `${t('pages.master.doc.strain')}`,
            dataIndex: 'strain',
            key: 'strain',
            ...getColumnSearchProps(`${t('pages.master.doc.strain')}`),
        },
        {
            title: `${t('general.action')}`,
            dataIndex: 'pilihan',
            key: 'pilihan',
            align: 'center',
            width: 130,
            render: (value, row, index) => (
                row.type === 'secondary' || role === 'superadmin' ?

                    <Space size="middle">
                        <Tooltip placement="top" title='Edit'>
                            <Button
                                hidden={!profil.permission.writeMaster} style={{
                                    color: 'white',
                                    backgroundColor: '#FFA903',
                                    borderRadius: '5px'
                                }} onClick={() => showModal(row.key)}><FontAwesomeIcon icon={faEdit} /></Button>
                        </Tooltip>
                        <Tooltip placement="top" title='Hapus'>
                            <Button
                                hidden={!profil.permission.writeMaster} style={{
                                    color: 'white',
                                    backgroundColor: '#FF0303',
                                    borderRadius: '5px'
                                }} onClick={() => hapusConfirm(row.key, row.name)}><FontAwesomeIcon icon={faTrash} /></Button>
                        </Tooltip>
                    </Space>
                    : <></>
            )
        }
    ]

    const data = object.map(({ _id, name, corporation, type, strain }, index) => ({
        key: _id,
        name: name,
        corporation: corporation,
        type: type,
        strain: strain.name,
    }))


    return (
        <Layout>
            <Content className="dashboard-container">
                <Row className="dashboard-section">
                    <Col lg={12} sm={24}>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item><span>{t('pages.master.doc.title')}</span></Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col lg={12} sm={24}>
                        {!profil.permission.writeMaster ? <></> :
                            <CreateDoc fetchData={fetchData} setLoading={setLoading} t={t} role={role} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} md={24} sm={24}>
                        <Loading loading={loading}>
                            <Table
                                columns={columns}
                                dataSource={data}
                                bordered
                                pagination={false}
                                scroll={{ y: '70vh', x: isMobileOnly && 1000 }}
                                className="table-dashboard"
                            />
                        </Loading>
                    </Col>
                    <EditDoc visible={visible} idData={idData} handleCancel={handleCancel}
                        detail={detail} handleSubmit={handleSubmit} t={t} />
                </Row>
            </Content>
        </Layout>
    )
}

const mapStateToProps = state => ({
    role: state.profil.dataProfil.type,
    profil: state.profil.dataProfil,
})

const mapDispatchToProps = (dispatch => ({
    navigate,
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(DocPage)
export default page