import React from 'react'
import { Modal, Form, Row, Col, InputNumber, Input, Select } from 'antd'

function EditPanenComponent(props) {
    const { visible, handleSubmit, data, handleCancel, customer, t } = props

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()

        return (
            <Modal
                visible={visible}
                title={t('pages.recording.harvest.edit')}
                okText={t('general.edit')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        plat: data?.plat,
                        tonase: data?.tonase,
                        ayam: data?.number,
                        berat: data?.totalWeight,
                        price: data?.price,
                        total: data?.total,
                        customer: data.customer ? data.customer : '',
                        keterangan: data?.otherInformation,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >Plat Nomor</span>
                            <Form.Item
                                name="plat" rules={[{ required: false, message: `Mohon masukkan plat nomor` }]}
                            >
                                <Input
                                    placeholder="1000..."
                                    className="input-form"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">Tonase DO</span>
                            <Form.Item
                                name="tonase" rules={[{ required: false, message: `Mohon masukkan tonase DO` }]}
                            >
                                <InputNumber
                                    placeholder="1000..."
                                    className="input-number"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                    addonAfter='kg'
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.recording.harvest.qty')}</span>
                            <Form.Item
                                name="ayam" rules={[{ required: true, message: `${t('pages.recording.harvest.msgQty')}` }]}
                            >
                                <InputNumber
                                    placeholder="1000..."
                                    className="input-number"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.harvest.weight')}</span>
                            <Form.Item
                                name="berat" rules={[{ required: true, message: `${t('pages.recording.harvest.msgWeight')}` }]}
                            >
                                <InputNumber
                                    name='berat'
                                    className="input-number"
                                    placeholder="50..."
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.recording.harvest.price')}</span>
                            <Form.Item
                                name="price" rules={[{ required: true, message: `${t('pages.recording.harvest.msgPrice')}` }]}
                            >
                                <InputNumber
                                    // onChange={(value) => onChangePrice(value)}
                                    placeholder="1000..."
                                    className="input-number"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.harvest.total')}</span>
                            <Form.Item
                                name="total" rules={[{ required: true, message: `${t('pages.recording.harvest.msgTotal')}` }]}
                            >
                                <InputNumber
                                    // onChange={(value) => onChangeTotal(value)}
                                    placeholder="1000..."
                                    className="input-number"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.recording.harvest.customer')}</span>
                            <Form.Item
                                name="customer" rules={[{ required: true, message: `${t('pages.recording.harvest.msgCustomer')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.recording.harvest.phCustomer')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        customer.map(data =>
                                            <Select.Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Select.Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t("pages.recording.harvest.desc")}</span>
                            <Form.Item
                                name="keterangan"
                            >
                                <Input.TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={t("pages.recording.harvest.phDesc")}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }
    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

export default EditPanenComponent