import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { navigate } from '../../../../../common/store/action'
import { getStrain } from '../../strain-page/strain-aksi-page/query-strain'
import { Modal, Form, Row, Col, Input, Select } from 'antd'
import { API } from '../../../../../common/api'

function EditDocPage(props) {
    const { visible, handleCancel, detail, handleSubmit, t } = props
    const [data, setData] = useState(detail)
    const [strain, setStrain] = useState([])
    const { Option } = Select

    const fetchDataStrain = () => {
        API.get(getStrain())
            .then(res => {
                setStrain(res.data.data.strains.strains)
            }).catch((error) => {
                console.log(error)
                setStrain(null)
            })
    }
    useEffect(() => {
        fetchDataStrain()
    }, [])

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.master.doc.edit')}
                okText={t('general.edit')}
                width={500}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        name: data.name,
                        corporation: data.corporation,
                        strain: data.strain,
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.doc.name')}</span>
                            <Form.Item
                                name="name" rules={[{ required: true, message: `${t('pages.master.doc.msgName')}` }]}
                            >
                                <Input
                                    placeholder="DOC 1..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.doc.corporation')}</span>
                            <Form.Item
                                name="corporation" rules={[{ required: true, message: `${t('pages.master.doc.msgCorporation')}` }]}
                            >
                                <Input
                                    placeholder="PT ..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.doc.strain')}</span>
                            <Form.Item
                                name="strain" rules={[{ required: false, message: 'Silahkan pilih strain' }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder="Pilih strain"
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    {
                                        strain.map(data =>
                                            <Option
                                                key={data._id}
                                                value={data._id}
                                            >{data.name}</Option>
                                        )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
    navigate
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditDocPage)
export default page