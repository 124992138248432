import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, DatePicker, Button, Dropdown, Menu, Tooltip, InputNumber, Checkbox, Select, Typography } from 'antd'
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import { API } from '../../../../common/api'
import { createRearingRecord, autoRearingRecord } from './query-rearing-record'
import { errMessage } from '../../../../common/component/notification/notification'
import { FileExcelFilled, DownOutlined, PlusSquareOutlined, CalendarOutlined, FilePdfFilled } from '@ant-design/icons'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loading from '../../../../common/component/loading/loading-container'
import { setCreateRecording } from '../../../../modules/dashboard-peternak/layout-component/store/layout-action'

function blobToDataURL(blob, fileName) {
    return new Promise(r => {
        let a = new FileReader();
        a.onload = r;
        a.readAsDataURL(blob)
    }).then(e => {
        const link = document.createElement("a")
        link.href = e.target.result
        link.download = fileName
        return link.click()
    }
    );
}

function CreateRearingRecordPage(props) {
    const { fetchData, idPeriode, setLoading, detailRearing, house, houseActive, periode, count, profil, isCreateRecording, setCreateRecording, t } = props
    const [visible, setVisible] = useState(false)
    const [visiblePdf, setVisiblePdf] = useState(false)
    const [loading, setLoad] = useState(false)
    const { confirm } = Modal
    const cookies = new Cookies()
    const [disabled, setDisabled] = useState(false)

    const [form2] = Form.useForm()
    const [options, setOptions] = useState([])
    const [orientation, setOrientation] = useState(null)

    useEffect(() => {
        if ((orientation === 'portrait' && options.length > 6) || (orientation === 'landscape' && options.length > 10)) {
            setDisabled(true)
        }
        else {
            setDisabled(false)
        }
    }, [orientation, options])

    useEffect(() => {
        if (isCreateRecording) {
            fetchData(idPeriode)
            setCreateRecording(false)
        }
        // eslint-disable-next-line
    }, [isCreateRecording])

    const showModal = useCallback(() => {
        setVisible(true)
    }, [])

    const showModalPdf = useCallback(() => {
        setVisiblePdf(true)
    }, [])

    const postData = async (idPeriode, date) => {
        await API.post(createRearingRecord(idPeriode, date))
            .then(res => {
                try {
                    if (res.data.errors) {
                        errMessage('error', `${t('pages.recording.resAddFailed')}`, res.data.errors[0].message)
                        setLoading(false)
                    }
                    else if (res.data.data.createRearingRecord._id) {
                        errMessage('success', `${t('pages.recording.resAddSuccess')}`)
                        detailRearing(res.data.data.createRearingRecord._id, res.data.data.createRearingRecord.date, res.data.data.createRearingRecord.age)
                        fetchData(idPeriode)
                    }
                } catch (error) {
                    errMessage('error', `${t('pages.recording.resAddFailed')}`, error.message)
                    setLoading(false)
                }
            })
    }

    const autoPost = async (idPeriode) => {
        setLoading(true)
        setVisible(false)
        await API.post(autoRearingRecord(idPeriode))
            .then(res => {
                try {
                    if (res.data.errors) {
                        errMessage('error', `${t('pages.recording.resAddFailed')}`, res.data.errors[0].message)
                        setLoading(false)
                    } else if (res.data.data.autoRearingRecord._id) {
                        errMessage('success', `${t('pages.recording.resAddSuccess')}`)
                        detailRearing(res.data.data.autoRearingRecord._id, res.data.data.autoRearingRecord.date, res.data.data.autoRearingRecord.age)
                        fetchData(idPeriode)
                    }
                } catch (error) {
                    errMessage('error', `${t('pages.recording.resAddFailed')}`, error.message)
                    setLoading(false)
                }
            })
    }

    const addConfirm = (idPeriode) => {
        confirm({
            title: `${t('pages.recording.addConfirm')}`,
            centered: true,
            maskClosable: true,
            onOk: () => {
                autoPost(idPeriode)
            },
            onCancel() {
                console.log('Cancel')
            },
            okText: `${t('general.add')}`,
            cancelText: `${t('general.cancel')}`,
        })
    }


    //excel
    const exportData = async (idPeriode) => {
        setLoad(true)
        let token = cookies.get('token')
        await axios
            .get(`${process.env.REACT_APP_API_URL}/excel-rearing/${idPeriode}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                responseType: 'blob'
            })
            .then(res => {
                if (typeof (res.data === 'blob')) {
                    setLoad(false)
                    const fileName = `Recording ${periode} ${house}.xlsx`
                    blobToDataURL(res.data, fileName)
                    errMessage('success', `Berhasil export recording ke file Excel`)
                }
            }).catch((error) => {
                console.log('err', error)
                errMessage('error', `Gagal export recording ke file Excel`)
                setLoad(false)
            })
    }

    const handleExport = () => {
        exportData(idPeriode)
    }

    const handleSubmit = (values) => {
        const date = values['date_picker']
        postData(idPeriode, date)
        setVisible(false)
        setLoading(true)
    }

    //pdf
    const exportPdf = async (value) => {
        setLoad(true)
        let token = cookies.get('token')
        await axios
            .get(`${process.env.REACT_APP_API_URL}/pdf-recording/${idPeriode}?age=${value.age}&orientation=${value.orientation}&options=${value.options}`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
                responseType: 'blob'
            })
            .then(res => {
                if (typeof (res.data === 'blob')) {
                    setLoad(false)
                    setVisiblePdf(false)
                    const fileName = `Laporan Recording ${periode} ${house}.pdf`
                    blobToDataURL(res.data, fileName)
                    // const link = document.createElement("a")
                    // link.href = window.URL.createObjectURL(res.data)
                    // link.download = `Laporan Recording ${periode} ${house}.pdf`
                    // link.click()
                    errMessage('success', `Berhasil export recording ke file PDF`)
                }
            }).catch((error) => {
                console.log('err', error)
                errMessage('error', `Gagal export recording ke file PDF`)
                setLoad(false)
            })
    }

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.recording.add')}
                okText={t('general.add')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                >
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.recording.date')}</span>
                            <Form.Item
                                name="date_picker" rules={[{ required: true, message: `${t('pages.recording.msgDate')}` }]}
                            >
                                <DatePicker
                                    placeholder={t('pages.recording.phDate')}
                                    className="select-input-date mt-5"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => addConfirm(idPeriode)} icon={<PlusSquareOutlined />}>
                Auto
            </Menu.Item>
            <Menu.Item key="2" onClick={() => showModal()} icon={<CalendarOutlined />}>
                Manual
            </Menu.Item>
        </Menu>
    )
    const menu2 = (
        <Menu>
            <Menu.Item key="1" onClick={() => handleExport()} icon={<FileExcelFilled />}>
                Export Excel
            </Menu.Item>
            <Menu.Item key="2" onClick={() => showModalPdf()} icon={<FilePdfFilled />}>
                Export PDF
            </Menu.Item>
        </Menu>
    )
    return (
        <>
            <Tooltip title={houseActive ? "" : t("error.inactiveHouse")}>
                {count > 0 ?
                    <Dropdown.Button
                        hidden={profil.permission.writeRecording ? false : true}
                        height={20} onClick={() => addConfirm(idPeriode)} overlay={menu} icon={<DownOutlined />}
                        className={houseActive ? "button-menu ml-10 mb-10" : 'button-menu-disabled ml-10 mb-10'}
                        disabled={!houseActive}
                    ><FontAwesomeIcon icon={faPlusSquare} className='mr-10' />
                        {t('pages.recording.add')}
                    </Dropdown.Button>
                    :
                    <Button
                        hidden={profil.permission.writeRecording ? false : true}
                        className='ml-10 mb-10'
                        style={{
                            color: 'white',
                            backgroundColor: houseActive ? '#008ad4' : '#c6e5f5',
                            borderRadius: '5px',
                            float: "right",
                            border: 'none', boxShadow: '2px 2px 8px 0 rgb(0 0 0 / 25%)'
                        }} onClick={() => showModal()} disabled={!houseActive}
                    ><FontAwesomeIcon icon={faPlusSquare} className='mr-10' />
                        {t('pages.recording.add')}
                    </Button>
                }
            </Tooltip>

            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => {
                    setVisible(false)
                }}
            />

            <Dropdown overlay={menu2}
                className="button-menu ml-10"
            >
                <Button loading={loading}
                    style={{ backgroundColor: '#00a266', color: 'white', }}
                >
                    Export to File <DownOutlined />
                </Button>
            </Dropdown>

            <Modal
                visible={visiblePdf}
                title='Export PDF Recording'
                okText='Generate PDF'
                width={800} centered
                cancelText={t('general.cancel')}
                onCancel={() => {
                    setVisiblePdf(false)
                }}
                onOk={() => {
                    form2
                        .validateFields()
                        .then((values) => {
                            form2.resetFields()
                            exportPdf(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Loading loading={loading}>
                    <Form
                        form={form2}
                        layout="vertical"
                        onValuesChange={(e, value) => {
                            if (value.options) setOptions(value.options)
                        }}
                    >
                        <Row gutter={[16, 8]}>
                            <Col span={12}>
                                <span className="font-semi-bold black font-title-medium">Umur terakhir</span>
                                <Form.Item
                                    name="age" rules={[{ required: true, message: `Masukkan umur terakhir` }]}
                                >
                                    <InputNumber
                                        placeholder='Masukkan umur..'
                                        style={{ width: '100%' }}
                                        precision={0}
                                        className="input-number"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <span className="font-semi-bold black font-title-medium">Page Orientation</span>
                                <Form.Item
                                    name="orientation" rules={[{ required: true, message: `Pilih orientation page` }]}
                                >
                                    <Select
                                        placeholder='Pilih page orientation'
                                        optionFilterProp="children"
                                        style={{ width: '100%' }}
                                        onChange={e => {
                                            form2.setFieldsValue({ options: [] })
                                            setOptions([])
                                            setOrientation(e)
                                        }}
                                        className="select-kategori black font-regular"
                                    >
                                        <Select.Option value='portrait'>Portrait</Select.Option>
                                        <Select.Option value='landscape'>Landscape</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <div className="font-semi-bold black font-title-medium">Pilihan Export Tabel</div>
                                {orientation &&
                                    <Typography.Text type="warning">Anda bisa memilih di mode {orientation} maksimal {orientation
                                        === 'portrait' ? '7' : '11'} pilihan.</Typography.Text>}
                                <Form.Item name="options" rules={[{ required: true, message: `Pilih minimal 1 opsi` }]}
                                >
                                    <Checkbox.Group style={{ width: '100%' }} disabled={!orientation}>
                                        <Row gutter={[8, 16]}>
                                            <Col lg={8} xs={24}>
                                                <div className='font-semi-bold'>Pakan</div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('pakanPerHari') && disabled} value="pakanPerHari"> {t('pages.recording.feed.feedDay')} </Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('pakanTotal') && disabled} value="pakanTotal"> {t('pages.recording.feed.cumFeed')} </Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('feedIntake') && disabled} value="feedIntake"> {t('pages.recording.feed.fiAct')} </Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('fiCum') && disabled} value="fiCum"> {t('pages.recording.feed.actCum')} </Checkbox>
                                                </div>

                                            </Col>

                                            <Col lg={8} xs={24}>
                                                <div className='font-semi-bold'>Deplesi</div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('culling') && disabled} value="culling">Culling</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('mati') && disabled} value="mati">Mati</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('deplesiPerHari') && disabled} value="deplesiPerHari">Jumlah deplesi per hari</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('total') && disabled} value="total">Kumulatif deplesi per hari</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('persenDeplesiHarian') && disabled} value="persenDeplesiHarian">Prosentase jumlah deplesi</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('persenKematian') && disabled} value="persenKematian">Prosentase kumulatif deplesi</Checkbox>
                                                </div>
                                            </Col>
                                            <Col lg={8} xs={24}>
                                                <div className='font-semi-bold'>Panen</div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('panen') && disabled} value="panen">Jumlah ekor panen</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('totalBeratPanen') && disabled} value="totalBeratPanen">Jumlah berat panen (kg)</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('saldo') && disabled} value="saldo">Sisa ayam</Checkbox>
                                                </div>
                                            </Col>
                                            <Col lg={8} xs={24}>
                                                <div className='font-semi-bold'>BW</div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('bw') && disabled} value="bw">BW</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('adg') && disabled} value="adg">ADG</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('totalBerat') && disabled} value="totalBerat">Total berat (kg) </Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('abw') && disabled} value="abw">ABW (gr)</Checkbox>
                                                </div>

                                            </Col>

                                            <Col lg={8} xs={24}>
                                                <div>
                                                    <Checkbox disabled={!options.includes('fcr') && disabled} value="fcr">FCR</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('indeksPerformance') && disabled} value="indeksPerformance">IP</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('totalDoc') && disabled} value="totalDoc">Total DOC (Rp)</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('cumFeed') && disabled} value="cumFeed">Total Biaya Pakan</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('ovkTotal') && disabled} value="ovkTotal">Total Biaya OVK</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('cumOverhead') && disabled} value="cumOverhead">Total Biaya Overhead</Checkbox>
                                                </div>
                                            </Col>
                                            <Col lg={8} xs={24}>
                                                <div>
                                                    <Checkbox disabled={!options.includes('totalBiaya') && disabled} value="totalBiaya">Total Biaya HPP</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('hppPerEkor') && disabled} value="hppPerEkor">HPP/ekor</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('hppStok') && disabled} value="hppStok">HPP stok/ekor</Checkbox>
                                                </div>
                                                <div>
                                                    <Checkbox disabled={!options.includes('hppPerKg') && disabled} value="hppPerKg">HPP/kg</Checkbox>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Loading>
            </Modal>
        </>
    )
}

const mapStateToProps = state => ({
    idPeriode: state.layout.idPeriode,
    isCreateRecording: state.layout.isCreateRecording,
})

const mapDispatchToProps = (dispatch => ({
    setCreateRecording
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(CreateRearingRecordPage)
export default page