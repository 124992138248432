import React from 'react'
import { Modal,Form,Row,Col,InputNumber } from 'antd'

function EditPeriodeLiveBird(props) {
    const {visible,handleSubmit,data,handleCancel,t} = props
    const CollectionCreateForm = ({ visible, handleSubmit, onCancel}) => {
        const [form] = Form.useForm()
        return (
          <Modal
            visible={visible}
            title={t('pages.stockflow.editSawLivebird')}
            okText="Edit"
            width={800}
            cancelText={t('general.cancel')}
            onCancel={onCancel}
            onOk={() => {
              form
                .validateFields()
                .then((values) => {
                  form.resetFields()
                  handleSubmit(values)
                })
                .catch((info) => {
                  console.log('Validate Failed:', info)
                })
            }}
          >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        bgnQty : data.bgnQty,
                        bgnPrice : data.bgnPrice,
                    }}
                >
                     <Row gutter={[16,8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stockflow.quantity')}</span>
                            <Form.Item
                                name="bgnQty" rules={[{ required: true, message: `${t('pages.stockflow.msgQty')}`}]}
                            > 
                            <InputNumber 
                            name='qtyLivebird'
                            placeholder="5000..."
                            className="input-number mt-5"
                            formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />
                            </Form.Item>
                        </Col>
                        <Col xs={{ order: 1, span: 24 }} sm={{ order: 2, span: 24 }} md={{ order: 3 }} lg={{ order: 4, span: 12 }}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.stockflow.price')}</span>
                            <Form.Item
                                name="bgnPrice" rules={[{ required: true, message: `${t('pages.stockflow.msgPrice')}`}]}
                            >
                                <InputNumber 
                                    name='priceLivebird'
                                    placeholder="7000..."
                                    className="input-number mt-5"
                                    formatter={value => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
          </Modal>
        );
    };
    return ( 
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    );
}
 
export default EditPeriodeLiveBird