import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Row, Col, Input, Select } from 'antd'

function EditPakanPage(props) {
    const { visible, handleCancel, detail, handleSubmit, t } = props
    const [data, setData] = useState(detail)
    const { TextArea } = Input
    const { Option } = Select

    useEffect(() => {
        function setDetailData() {
            if (detail === null) {
                setData("")
            }
            else {
                setData(detail)
            }
        }
        setDetailData()
    }, [detail])

    const CollectionCreateForm = ({ visible, handleSubmit, onCancel }) => {
        const [form] = Form.useForm()
        return (
            <Modal
                visible={visible}
                title={t('pages.master.feed.edit')}
                okText={t('general.edit')}
                width={800}
                cancelText={t('general.cancel')}
                onCancel={onCancel}
                onOk={() => {
                    form
                        .validateFields()
                        .then((values) => {
                            form.resetFields()
                            handleSubmit(values)
                        })
                        .catch((info) => {
                            console.log('Validate Failed:', info)
                        })
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                    initialValues={{
                        namaPakan: data.name,
                        jenisPakan: data,
                        perusahaan: data.producer,
                        keterangan: data.otherInformation
                    }}
                >
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.feed.name')}</span>
                            <Form.Item
                                name="namaPakan" rules={[{ required: true, message: `${t('pages.master.feed.msgName')}` }]}
                            >
                                <Input
                                    name='namaPakan'
                                    placeholder="Bro AB..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content">{t('pages.master.feed.type')}</span>
                            <Form.Item
                                name="jenisPakan" rules={[{ required: true, message: `${t('pages.master.feed.msgType')}` }]}
                            >
                                <Select
                                    showSearch
                                    labelInValue
                                    placeholder={t('pages.master.feed.phType')}
                                    optionFilterProp="children"
                                    style={{ width: '100%' }}
                                    className="select-kategori black font-regular"
                                >
                                    <Option key="Prestarter" value="prestarter">Prestarter</Option>
                                    <Option key="Starter" value="starter">Starter</Option>
                                    <Option key="Grower" value="grower">Grower</Option>
                                    <Option key="Finisher" value="finisher">Finisher</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col lg={12} sm={24}>
                            <span className="font-semi-bold black font-title-medium">{t('pages.master.feed.company')}</span>
                            <Form.Item
                                name="perusahaan" rules={[{ required: true, message: `${t('pages.master.feed.msgCompany')}` }]}
                            >
                                <Input
                                    name='perusahaan'
                                    placeholder="PT Farming ..."
                                    className="input-form mt-5"
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={12} sm={24}>
                            <span className="black font-semi-bold font-title-content" >{t('pages.master.feed.desc')}</span>
                            <Form.Item name="keterangan" >
                                <TextArea
                                    style={{ borderColor: "#BE375F" }}
                                    placeholder={t('pages.master.feed.phDesc')}
                                    autoSize={{ minRows: 3, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        )
    }

    return (
        <div>
            <CollectionCreateForm
                visible={visible}
                handleSubmit={handleSubmit}
                onCancel={() => handleCancel()}
            />
        </div>
    )
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = (dispatch => ({
}))()

const page = connect(mapStateToProps, mapDispatchToProps)(EditPakanPage)
export default page