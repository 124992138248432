import React from 'react';
import { Layout, Row, Col, Form, Carousel, Select } from 'antd'
import InputAuth from '../../../common/component/input/input-auth'
import InputPassword from '../../../common/component/input/input-password'
import '../../../assets/scss/auth/login.scss'
import Loading from '../../../common/component/loading/loading-container'
import ButtonText from '../../../common/component/button/button-text'
import ButtonLogin from '../../../common/component/button/button-not-icon'
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { GoogleLogin } from "react-google-login"
// import AppleLogin from 'react-apple-login'
import { Link } from 'react-router-dom'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'

function LoginComponent(props) {
    const { username, password, handleSubmit, handleFailed, setUsername, setPassword, responseFb, responseGoogle, loading, t, lang, handleLocales } = props
    const { Content } = Layout
    const { Option } = Select
    const logo = require(`../../../assets/images/logo.png`)
    const slide1 = require(`../../../assets/svg/chicken-1.svg`)
    const slide2 = require(`../../../assets/svg/chicken-2.svg`)
    const slide3 = require(`../../../assets/svg/chicken-3.svg`)

    return (
        <Layout>
            <Content className="landing-container">
                <Loading loading={loading}>
                    <Row>
                        <Col lg={12} md={24} sm={24}>
                            <div className="section-picture-login">
                                <div className="section-picture-center">
                                    <Carousel autoplay style={{ padding: '30px' }}>
                                        <div>
                                            <h3 className='caraousel'><img src={slide1} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                        <div>
                                            <h3 className='caraousel'><img src={slide2} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                        <div>
                                            <h3 className='caraousel'><img src={slide3} className="slide" alt="Carousel logo" width="75%" style={{ textAlign: "center" }} /></h3>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={24} sm={24}>
                            <div style={{ overflow: "hidden" }}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24} className='section-top-login'>
                                        <span className="float-right" style={{ marginTop: '.5rem', marginRight: '.5rem' }}>
                                            <Select defaultValue={lang} bordered={false} onChange={(e) => handleLocales(e)}>
                                                <Option value="id">ID</Option>
                                                <Option value="en">EN</Option>
                                            </Select>
                                        </span>
                                        <div className="section-logo-login">
                                            <img src={logo} alt="BroilerX logo" width="40%" />
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="section-title-login">
                                            <span className="font-title-login black font-bold">{t('welcome')}</span>
                                            <br />
                                            <span className="font-title-medium black font-light">{t('pages.auth.loginDesc')}</span>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="section-form-login">
                                            <Form onFinish={handleSubmit} onFinishFailed={handleFailed} name="basic" initialValues={{ remember: true }}>
                                                <Row>
                                                    <Col span={24}>
                                                        <span className="font-semi-bold black font-title-medium">Username/Email</span>
                                                        <Form.Item
                                                            name="username" rules={[{ required: true, message: `${t('pages.auth.msgEmailLogin')}` }]}
                                                        >
                                                            <InputAuth
                                                                placeholder={t('pages.auth.phEmailLogin')}
                                                                onChange={setUsername}
                                                                value={username}
                                                                className="input-auth mt-5"
                                                                cyName={'username'}
                                                            />
                                                        </Form.Item>
                                                        <span className="font-semi-bold black font-title-medium">{t('pages.auth.password')}</span>
                                                        <Form.Item
                                                            name="password" rules={[{ required: true, message: `${t('pages.auth.msgPassword')}` }]}
                                                        >
                                                            <InputPassword
                                                                placeholder={t('pages.auth.phPassword')}
                                                                onChange={setPassword}
                                                                value={password}
                                                                className="input-auth mt-5 "
                                                                cyName={'password'}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16, 16]}>
                                                    <Col lg={{ span: 12 }} xs={{ span: 12 }}>
                                                        <div className="section-form-button-login">
                                                            <Form.Item>
                                                                <ButtonLogin
                                                                    text={t('pages.auth.login')}
                                                                    height={10}
                                                                    backgroundColor="#008ad4"
                                                                    borderRadius="10px"
                                                                    className="font-semi-bold font-title-medium button-login"
                                                                    textColor="white"
                                                                    htmlType="submit"
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                    <Col lg={{ span: 12, offset: 0 }} xs={{ span: 10, offset: 2 }}>
                                                        <div className="section-form-forgot-password">
                                                            <Link to="/forgot-password"><span className="font-semi-bold font-title-content black">{t('pages.auth.forgetMsg')}</span></Link>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                            <div className="section-register-another-login">
                                                <span className="font-medium font-title-content black">{t('pages.auth.loginOpt')}</span>
                                            </div>
                                            <Row gutter={[16, 16]}>
                                                <Col lg={{ span: 8, offset: 4 }} xs={12}>
                                                    <FacebookLogin
                                                        appId={process.env.REACT_APP_FB_OAUTH_APP_ID}
                                                        autoLoad={false}
                                                        fields="name,email,picture"
                                                        callback={responseFb}
                                                        render={(renderProps) => (
                                                            <ButtonText
                                                                text="Facebook"
                                                                height={10}
                                                                icon={faFacebook}
                                                                backgroundColor="#3b5998"
                                                                borderRadius="5px"
                                                                className="font-semi-regular font-title-small button-facebook"
                                                                textColor="white"
                                                                onClick={renderProps.onClick}
                                                            />
                                                        )}
                                                    />
                                                </Col>
                                                <Col lg={{ span: 8, }} xs={12}>
                                                    <GoogleLogin
                                                        clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT}
                                                        render={(renderProps) => (
                                                            <ButtonText
                                                                text="Google"
                                                                height={10}
                                                                icon={faGoogle}
                                                                backgroundColor="#D44638"
                                                                borderRadius="5px"
                                                                className="font-semi-regular font-title-small button-facebook"
                                                                textColor="white"
                                                                onClick={renderProps.onClick}
                                                            />
                                                        )}
                                                        buttonText={t('pages.auth.login')}
                                                        onSuccess={responseGoogle}
                                                        onFailure={responseGoogle}
                                                        cookiePolicy={"single_host_origin"}
                                                    />
                                                </Col>
                                                {/* <Col span={8} hidden>
                                                    <AppleLogin
                                                        clientId="com.broilerx.dev"
                                                        redirectURI="https://saas.broilerx.com"
                                                        usePopup={true}
                                                        callback={responseApple}
                                                        scope="email name"
                                                        responseMode="query"
                                                        render={(renderProps) => (
                                                            <ButtonText
                                                                text="Apple"
                                                                height={10}
                                                                icon={faApple}
                                                                backgroundColor="#1d1d1f"
                                                                borderRadius="5px"
                                                                className="font-semi-regular font-title-small button-facebook"
                                                                textColor="white"
                                                                onClick={renderProps.onClick}
                                                            />
                                                        )}
                                                    />
                                                </Col> */}
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col span={24}>
                                        <div className="section-register-login">
                                            <span className="font-medium font-title-content black">
                                                {t('pages.auth.registerMsg')}
                                                <Link to="/register"> {t('pages.auth.registerCta')}
                                                </Link>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Loading>
            </Content>
        </Layout>
    )
}

export default LoginComponent
