import * as actionType from './strain-action-type';

const initialState = {
    idStrain: null,
    dataStrain: [],
    strain: null,
    loading: false,
}

const handler = (currentState) => {
    const setDataStrain = {
        startGetDataStrains: () => ({
            ...currentState,
            loading: true,
        }),

        finishGetDataStrains: () => ({
            ...currentState,
            loading: false,
        }),

        setIdStrain: data => ({
            ...currentState,
            idStrain: data,
        }),

        getDataStrain: data => ({
            ...currentState,
            dataStrain: data,
        }),

        getNameStrain: data => ({
            ...currentState,
            strain: data,
        }),
    }

    return {
        ...setDataStrain
    };
}

export default (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case actionType.START_GET_DATA:
            return handler(state).startGetDataStrains();
        case actionType.FINISH_GET_DATA:
            return handler(state).finishGetDataStrains();
        case actionType.SET_ID_STRAIN:
            return handler(state).setIdStrain(payload);
        case actionType.SET_DATA_STRAIN:
            return handler(state).getDataStrain(payload);
        case actionType.SET_STRAIN:
            return handler(state).getNameStrain(payload)

        default:
            return state;
    }
}