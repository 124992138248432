import { API } from '../../../common/api'
import Cookies from 'universal-cookie'
import CONSTANTS from '../../../common/utils/Constants'
import { navigate } from '../../../common/store/action'
import { validationMessage, errMessage } from '../../../common/component/notification/notification'
import * as actionType from './auth-action-type'
import i18next from 'i18next'

const cookies = new Cookies()

const startGetDataUsers = () => ({
    type: actionType.START_GET_DATA
})

const finishGetDataUsers = () => ({
    type: actionType.FINISH_GET_DATA
})

const setDataUser = payload => ({
    type: actionType.SET_DATA_USER,
    payload,
})

export const setLoginTime = () => (dispatch) => {
    const currentDateTime = new Date()
    const updateDateTime = new Date()
    const expireDateTime = new Date(updateDateTime.setHours(updateDateTime.getHours() + 9))

    const currentTimestamp = Math.floor(currentDateTime.getTime() / 1000)
    const expireTimeStamp = Math.floor(expireDateTime.getTime() / 1000)

    const initialState = {
        isLogin: true,
        loginTime: currentTimestamp,
        expirationTime: expireTimeStamp,
    };
    cookies.set('loginTimes', btoa(JSON.stringify(initialState)), { path: '/', secure: true, httpOnly: false, sameSite: 'lax' })
}

export const handleLogin = (params) => (dispatch) => {
    dispatch(startGetDataUsers())
    API.post(params)
        .then(res => {
            if (res === undefined) { validationMessage('error', `${i18next.t('error.connection')}`) }
            else if (res.status === 200) {
                if (res.data.data.login === null) {
                    dispatch(finishGetDataUsers())
                    errMessage('error', res.data.errors[0].message)
                } else {
                    dispatch(setLoginTime())
                    cookies.set('token', res.data.data.login.token, {
                        path: '/', secure: true, httpOnly: false, sameSite: 'lax',
                        expires: new Date(new Date().setDate(new Date().getDate() + 14))
                    })
                    dispatch(setDataUser(res.data.data.login))
                    dispatch(navigate(CONSTANTS.DASHBOARD_PETERNAK_MENU_KEY))
                    validationMessage('success', `${i18next.t('pages.auth.resLoginSuccess')}`)
                }
            } else {
                validationMessage('error', `${i18next.t('pages.auth.resLoginFailed')}`)
            }
            dispatch(finishGetDataUsers())
        })
}

export const handleLoginOauth = (params) => (dispatch) => {
    dispatch(startGetDataUsers())
    API.post(params)
        .then(res => {
            if (res === undefined) { validationMessage('error', `${i18next.t('error.connection')}`) }
            else if (res.status === 200) {
                if (res.data.data.loginOAuth === null) {
                    errMessage('error', res.data.errors[0].message)
                    dispatch(finishGetDataUsers())
                } else {
                    dispatch(setLoginTime())
                    cookies.set('token', res.data.data.loginOAuth.token, {
                        path: '/', secure: true, httpOnly: false, sameSite: 'lax',
                        expires: new Date(new Date().setDate(new Date().getDate() + 14))
                    })
                    dispatch(setDataUser(res.data.data.loginOAuth))
                    dispatch(navigate(CONSTANTS.DASHBOARD_PETERNAK_MENU_KEY))
                    validationMessage('success', `${i18next.t('pages.auth.resLoginSuccess')}`)
                }
            } else {
                validationMessage('error', `${i18next.t('pages.auth.resLoginFailed')}`)
            }
            dispatch(finishGetDataUsers())
        })
}

export const handleRegister = (params, oauth) => (dispatch) => {
    dispatch(startGetDataUsers())
    if (oauth === 'oauth') {
        API.post(params)
            .then(res => {
                if (res === undefined) {
                    validationMessage('error', `${i18next.t('error.connection')}`)
                    dispatch(finishGetDataUsers())
                } else if (res.status === 200) {
                    if (res.data.data.register === null) {
                        errMessage('error', res.data.errors[0].message)
                        dispatch(finishGetDataUsers())
                    } else {
                        validationMessage('success', `${i18next.t('pages.auth.resRegisOauthSuccess')}`)
                        dispatch(navigate(CONSTANTS.HOME_MENU_KEY))
                    }
                } else {
                    validationMessage('error', `${i18next.t('pages.auth.resRegisFailed')}`)
                }
                dispatch(finishGetDataUsers())
            })
    } else {
        API.post(params)
            .then(res => {
                if (res === undefined) {
                    validationMessage('error', `${i18next.t('error.connection')}`)
                    dispatch(finishGetDataUsers())
                } else if (res.status === 200) {
                    if (res.data.data.register === null) {
                        errMessage('error', res.data.errors[0].message)
                        dispatch(finishGetDataUsers())
                    } else {
                        validationMessage('success', `${i18next.t('pages.auth.resRegisSuccess')}`)
                        dispatch(navigate(CONSTANTS.HOME_MENU_KEY))
                    }
                } else {
                    validationMessage('error', `${i18next.t('pages.auth.resLoginFailed')}`)
                }
                dispatch(finishGetDataUsers())
            })
    }

}