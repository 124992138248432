export const error = {
    email: "Email atau username Anda salah. Mohon masukkan akun yang terdaftar!",
    password: "Kata sandi Anda salah. Mohon masukkan sandi yang benar!",
    verified: "Akun Anda belum terverifikasi.",
    verifiedDesc: "Mohon klik link verifikasi pada inbox/spam email Anda atau kirim ulang link verifikasi!",
    verifiedLink: "Kirim ulang link verifikasi",
    google: "Akun Google Anda belum terdaftar. Mohon mendaftar dulu menggunakan akun Google Anda!",
    facebook: "Akun Facebook Anda belum terdaftar. Mohon mendaftar dulu menggunakan akun Facebook Anda!",
    oauthEmail: 'Maaf, akun ini terdaftar untuk login menggunakan email/manual!',
    oauthFb: 'Maaf, akun ini terdaftar untuk login menggunakan Facebook!',
    oauthGoogle: 'Maaf, akun ini terdaftar untuk login menggunakan Google!',
    authenticate: "Maaf, Anda tidak terautentikasi!",
    authorize: "Maaf, Anda tidak punya izin akses!",
    user: "Maaf, pengguna ini tidak ditemukan!",
    company: "Maaf, perusahaan ini tidak ditemukan!",
    captcha: "Captcha salah. Mohon coba lagi",
    registeredEmail: 'Email {{email}} sudah terdaftar. Mohon masukkan email lain!',
    registeredUsername: 'Username {{username}} sudah ada dan terdaftar. Mohon masukkan username lain!',
    connection: 'Koneksi bermasalah. Mohon pastikan koneksi internet Anda terhubung!',
    errId: 'Maaf, data tidak dapat ditemukan.',
    dateRecording: 'Maaf, recording pada tanggal ini sudah ditambahkan',
    addCity: 'Maaf, kota ini sudah ditambahkan',
    inactiveHouse: 'Kandang ini tidak aktif',
    outStock: 'Stok tidak cukup! Mohon tambahkan stok lebih dahulu atau masukkan jumlah lebih kecil'
}